import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useAxiosAuthorized } from './hooks/useAxiosAuthorized';

import localForage from 'localforage';
import { Provider } from 'react-redux';
import BackdropPage from '../src/pages/MobileBackdrop/MobileBackdrop';
import AuthenticationProvider from './components/AuthenticationProvider/AuthenticationProvider';
import FetchedDataProvider from './components/FetchedDataProvider/FetchedDataProvider';
import NotificationProvider from './components/NotificationProvider/NotificationProvider';
import PageContainer from './components/PageContainer/PageContainer';
import TopNav from './components/TopNav/TopNav';
import config from './config';
import { useAxiosUnauthorized } from './hooks/useAxiosUnauthorized';
import { initMixPanel } from './mixpanel';
import Analytics from './pages/Analytics/Analytics';
import Cohorts from './pages/Cohorts/Cohorts';
import CohortsResult from './pages/CohortsResult/CohortsResult';
import { Complete } from './pages/Complete/Complete';
import { Dashboard } from './pages/Dashboard';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import GrowthSimulator from './pages/GrowthSimulator/GrowthSimulator';
import GrowthSimulatorV2 from './pages/GrowthSimulatorV2/GrowthSimulatorV2';
import { GrowthSimulatorV2Service } from './pages/GrowthSimulatorV2/services/growthSimulator.service';
import Landing from './pages/Landing/Landing';
import Login from './pages/Landing/Login/Login';
import Register from './pages/Landing/Register/Register';
import OAuthCallback from './pages/OAuthCallback/OAuthCallback';
import PaymentCallback from './pages/PaymentCallback/PaymentCallback';
import Retention from './pages/Retention/Retention';
import ScenarioBuilder from './pages/ScenarioBuilder/ScenarioBuilder';
import Segmentation from './pages/Segmentation/Segmentation';
import SegmentationResult from './pages/SegmentationResult/SegmentationResult';
import SimulationResult from './pages/SimulationResult/SimulationResult';
import SubscriptionV2 from './pages/Subscription/SubscriptionV2';
import UserAcquisition from './pages/UserAcqusition/UserAcqusition';
import VerifyCallback from './pages/VerifyCallback/VerifyCallback';
import { AccountTitlesService } from './services/accountTitles.service';
import { BillingService } from './services/billing.service';
import { CurrencyService } from './services/currency.service';
import { GrowthSimulatorService } from './services/growthSimulator.service';
import { OnboardingService } from './services/onboarding.service';
import { ScenarioBuilderService } from './services/scenarioBuilder.service';
import { SegmentationService } from './services/segmentation.service';
import { SettingsService } from './services/settings.service';
import PrivateRoute from './shared/PrivateRoute/PrivateRoute';
import { store } from './store/app.state';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const AnalyticsPage = () => <PageContainer component={Analytics}></PageContainer>;
const DashboardPage = () => <PageContainer component={Dashboard}></PageContainer>;
const CohortsPage = () => <PageContainer component={Cohorts}></PageContainer>;
const UserAcquisitionPage = () => <PageContainer component={UserAcquisition}></PageContainer>;
const RetentionPage = () => <PageContainer component={Retention}></PageContainer>;
const SegmentationPage = () => <PageContainer component={Segmentation}></PageContainer>;
const ScenarioBuilderPage = () => <PageContainer component={ScenarioBuilder}></PageContainer>;

const SimulationResultPage = () => <PageContainer component={SimulationResult}></PageContainer>;
const SegmentationResultPage = () => <PageContainer component={SegmentationResult}></PageContainer>;
const CohortsResultPage = () => <PageContainer component={CohortsResult}></PageContainer>;
const GrowthSimulatorPage = () => <PageContainer component={GrowthSimulator}></PageContainer>;
const BackdropComponent = () => <BackdropPage />;
// const SubscriptionPage = () => <PageContainer component={Subscription}></PageContainer>
const SubscriptionPage2 = () => <SubscriptionV2 />;

if (config.FEATURE_FLAGS.useMixpanel && !process.env.REACT_APP_API_URL?.includes('stage')) {
  initMixPanel();
}

function App() {
  const axiosAuthorized = useAxiosAuthorized();
  const axiosUnauthorized = useAxiosUnauthorized();

  //TODO(PPavlov): Refactor, figure out a better way to pass axios
  //TODO(PPavlov): Refactor store and move settings in their own module/page
  //TODO(PPavlov): Create a single static axios service and reuse it
  SettingsService.axios = axiosAuthorized;
  CurrencyService.axios = axiosAuthorized;
  AccountTitlesService.axios = axiosAuthorized;
  ScenarioBuilderService.axios = axiosAuthorized;
  SegmentationService.axios = axiosAuthorized;
  GrowthSimulatorService.axios = axiosAuthorized;
  BillingService.axios = axiosAuthorized;

  OnboardingService.axios = axiosUnauthorized;
  OnboardingService.axiosAuthorized = axiosAuthorized;

  GrowthSimulatorV2Service.axios = axiosUnauthorized;
  GrowthSimulatorV2Service.axiosAuthorized = axiosAuthorized;

  //Clears IndexedDb when the session ends
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const handleUnload = (e: any) => {
    localForage.clear();
  };

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <NotificationProvider>
          <FetchedDataProvider>
            <AuthenticationProvider>
              <Router>
                <TopNav></TopNav>
                <Switch>
                  <PrivateRoute path="/" component={DashboardPage} exact />

                  <PrivateRoute path="/cohorts" component={CohortsPage} />

                  <PrivateRoute path="/segmentation" component={SegmentationPage} />

                  <PrivateRoute path="/user-acquisition" component={UserAcquisitionPage} />
                  <PrivateRoute path="/cohort-analysis" component={RetentionPage} />

                  <PrivateRoute path="/daily-dashboard" component={AnalyticsPage} />
                  <PrivateRoute path="/daily-kpis" component={AnalyticsPage} />
                  <PrivateRoute path="/game-analysis" component={AnalyticsPage} />
                  <PrivateRoute path="/view-all" component={AnalyticsPage} />
                  <PrivateRoute path="/monitor" component={AnalyticsPage} />
                  <PrivateRoute path="/kpi-simulator" component={AnalyticsPage} />

                  <PrivateRoute path="/scenario-builder" component={ScenarioBuilderPage} />

                  <PrivateRoute path="/whatif-results" component={SimulationResultPage} />
                  <PrivateRoute path="/segment-results" component={SegmentationResultPage} />
                  <PrivateRoute path="/cohorts-results" component={CohortsResultPage} />
                  <PrivateRoute path="/growth-simulator" component={GrowthSimulatorPage} />
                  <Route path="/subscription" component={SubscriptionPage2} />

                  <Route path="/oauth-callback" component={OAuthCallback} />
                  <Route path="/verify-callback" component={VerifyCallback} />
                  <Route path="/payment-callback" component={PaymentCallback} />

                  <Route path="/growth-simulator-v2" component={GrowthSimulatorV2} />
                  <Route path="/mobile-backdrop" component={BackdropComponent} exact />
                  <Route path="/landing" component={Landing} exact />
                  <Route path="/register" component={Register} exact />
                  <Route path="/login" component={Login} exact />
                  <Route path="/complete" component={Complete} exact />
                  {/* <Route path="/complete-password" component={CompletePasswordPage} exact /> */}
                  <Route path="/forgot-password" component={ForgotPasswordPage} exact />
                </Switch>
              </Router>
            </AuthenticationProvider>
          </FetchedDataProvider>
        </NotificationProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
