import * as React from "react"

const DiceIcon = (props: any) => (
  <svg
    aria-hidden="true"
    data-prefix="fad"
    data-icon="dice-d6"
    className="svg-inline--fa fa-dice-d6 fa-w-14"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    {...props}
  >
    <g className="fa-group" fill="currentColor">
      <path
        className="fa-secondary"
        d="M25.87 124.42a8.54 8.54 0 0 1-.06-14.42l166-100.88a61.72 61.72 0 0 1 64.43 0L422.19 110a8.54 8.54 0 0 1-.05 14.47L224 242.55z"
        opacity={0.4}
      />
      <path
        className="fa-primary"
        d="M0 161.83v197.7c0 23.77 12.11 45.74 31.79 57.7L184 509.71c10.67 6.48 24.05-1.54 24.05-14.44V271.46L12 154.58c-5.36-3.17-12 .85-12 7.25zm436-7.25L240 271.46v223.82c0 12.89 13.39 20.92 24.05 14.43l152.16-92.48c19.68-12 31.79-33.94 31.79-57.7v-197.7c0-6.41-6.64-10.42-12-7.25z"
      />
    </g>
  </svg>
)

export default DiceIcon
