import * as Yup from 'yup';

import { LinearProgress, Link, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Pages, trackPageSource } from '../../../mixpanel';
import {
  userLoginErrorSelector,
  userLoginLoadingSelector,
  userSelector,
} from '../../../store/onboarding/onboarding.selectors';
import {
  StyledLandingButton,
  StyledLandingPageInnerContainer,
  StyledLandingPageWrapper,
  StyledTextField,
} from '../styles';

import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import logo from '../../../assets/logo2.png';
import { OnboardingService } from '../../../services/onboarding.service';
import GoogleLogin from '../../../shared/GoogleLogin/GoogleLogin';
import { loginUser } from '../../../store/onboarding/onboarding.actions';
import StyledPasswordInput from '../StyledPasswordInput';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(userSelector);
  const userLoginError = useSelector(userLoginErrorSelector);
  const userLoginLoading = useSelector(userLoginLoadingSelector);
  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').email('Please enter a valid email'),
    password: Yup.string().required('Password is required'),
  });

  const { search } = useLocation();
  const source = new URLSearchParams(search).get('src') || undefined;
  const redirect = new URLSearchParams(search).get('redirect') || '/';

  useEffect(() => {
    trackPageSource(Pages.Login, source);
  }, []);

  useEffect(() => {
    if (user?.access_token) {
      history.push(redirect);
    }
  }, [user?.access_token]);

  const { values, handleChange, handleSubmit, handleBlur, validateForm, isValid, errors, touched } = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      const { payload: login } = (await dispatch(loginUser(values))) as unknown as { payload: any };

      localStorage.setItem('jwtToken', JSON.stringify(login));
    },
  });

  return (
    <StyledLandingPageWrapper style={{ maxHeight: 'none' }}>
      {userLoginLoading && <LinearProgress></LinearProgress>}
      <StyledLandingPageInnerContainer>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '440px', display: 'flex', justifyContent: 'center' }}>
            <img src={logo} alt="" />
          </Box>
          <Box sx={{ width: '440px', display: 'flex', justifyContent: 'center', marginBottom: '46px' }}>
            <Typography
              variant="h3"
              color={'white'}
              fontFamily={['Open Sans, sans-serif']}
              fontWeight={700}
              fontSize={32}
            >
              Sign In
            </Typography>
          </Box>
          <form action="" onSubmit={handleSubmit}>
            <Box sx={{ width: '440px', marginBottom: '40px' }}>
              <StyledTextField
                id="email"
                label=""
                placeholder="Email"
                autoComplete="off"
                fullWidth
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(errors.email && touched.email)}
                helperText={errors.email}
              />
            </Box>
            <Box sx={{ width: '440px', marginBottom: '40px' }}>
              <StyledPasswordInput
                id="password"
                label=""
                placeholder="Password"
                fullWidth
                name="password"
                error={!!(errors.password && touched.password)}
                helperText={errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>

            {userLoginError && (
              <Box
                sx={{ width: '440px', display: 'flex', justifyContent: 'center', marginBottom: '40px', color: 'red' }}
              >
                {userLoginError?.message}
              </Box>
            )}

            <Box sx={{ width: '440px', display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <StyledLandingButton
                disabled={!isValid}
                variant="contained"
                size="large"
                type="submit"
                style={{ width: '100%', height: '56px' }}
              >
                Login
              </StyledLandingButton>
            </Box>

            <Box sx={{ width: '440px', marginBottom: '10px' }}>
              <Typography
                variant="h6"
                color={'white'}
                textAlign="center"
                fontFamily={['DM Sans, sans-serif']}
                fontWeight={400}
              >
                Or
              </Typography>
            </Box>

            <Box sx={{ width: '440px', marginBottom: '10px' }}>
              <GoogleLogin
                onClick={async () => {
                  const { redirect_uri } = await OnboardingService.googleAuthorize({
                    // redirect: 'growth-simulator-v2'
                    redirect: redirect,
                  });

                  window.location.href = redirect_uri;
                }}
              ></GoogleLogin>
            </Box>
          </form>

          <Box sx={{ width: '440px', display: 'flex', justifyContent: 'flex-end', marginBottom: '25px' }}>
            <Typography variant="subtitle2" color={'rgba(255, 255, 255, 0.7)'} fontFamily={['Open Sans, sans-serif']}>
              <RouterLink to={'forgot-password'} style={{ color: 'white' }}>
                Forgot your Password?
              </RouterLink>
            </Typography>
          </Box>
          <Box sx={{ width: '440px', display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
            <Typography variant="subtitle1" color={'rgba(255, 255, 255, 0.7)'} fontFamily={['Open Sans, sans-serif']}>
              Don't have an account?{' '}
              <RouterLink to={'register'} style={{ color: 'white' }}>
                Register Now
              </RouterLink>
            </Typography>
          </Box>
          <Box sx={{ width: '440px', marginTop: '25px' }}>
            <Typography
              variant="subtitle1"
              color={'rgba(255, 255, 255, 0.7)'}
              fontFamily={['DM Sans, sans-serif']}
              justifyContent={'center'}
              textAlign={'center'}
            >
              <Link href="https://simpool.io/terms-of-use/" color="#FFFFFF" target="_blank">
                Terms & Conditions
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link href="https://simpool.io/privacy-policy/" color="#FFFFFF" target="_blank">
                Privacy Policy
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link href="https://simpool.io/simpool-subscription-and-refund-policy/" color="#FFFFFF" target="_blank">
                Subscription & Refund Policy
              </Link>
            </Typography>
          </Box>
        </Box>
      </StyledLandingPageInnerContainer>
    </StyledLandingPageWrapper>
  );
};

export default Login;
