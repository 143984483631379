import cn from 'classnames';
import { useEffect, useState } from "react";
import { useResizeDetector } from 'react-resize-detector';
import styled from "styled-components";

type TooltipProps = {
  text?: string;
  parentWidth?: any;
  parentWidthTest?: any;
  className?:string;
  shouldCalculate?: boolean;
}

const Tooltip = ({text,parentWidth, className, shouldCalculate = true, ...props}:TooltipProps) => {
  const [ ownHeight, setOwHeight ] = useState(0);
  const { width, height, ref: headerRefs } = useResizeDetector();

  const refHandler = (e:any) => {
    const cellHeight = e.offsetHeight;
    
    return cellHeight;
  }
  
  useEffect(() => {
    if(headerRefs && headerRefs.current) {
      setOwHeight(refHandler(headerRefs.current))
    }
  }, [headerRefs, width, height]);

  return text ? <div className={cn('html-tooltip', className)} style={ { bottom: shouldCalculate ? `-${ownHeight + 5}px` : ''}} ref={headerRefs}><div className="inner-html" dangerouslySetInnerHTML={{ __html: text }} /></div> : null;
}

export default styled(Tooltip)`
  &.html-tooltip {
    left: 0;
    opacity: 0;
    background: #9091A0;
    display: block;
    padding: 8px 8px;
    color: #FFF;
    font-size: 0.8rem;
    position: absolute;
    visibility: hidden;
    z-index: 1;
    border-radius: 15px;
    /* white-space: pre-line; */
   
  }
`;
