import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import { IGetReportDataPayload } from "../queries/useGetReportDataQuery";

const { REACT_APP_API_URL } = process.env;

export const useAxiosUnauthorized = (): AxiosInstance => {
  const client = useAxios();

  const _get = client.get;
  const _post = client.post;

  client.get = function <T = any, R = AxiosResponse<T, any>, D = any>(
    url: string,
    config: AxiosRequestConfig<D>
  ): Promise<R> {
    console.log(url, config);

    return _get(url, config);
  };

  client.post = function <T = any, R = AxiosResponse<T, any>, D = any>(
    url: string,
    data?: D | undefined,
    config?: AxiosRequestConfig<D>
  ): Promise<R> {
    if (data) {
      const report = data as any as IGetReportDataPayload;

      switch(report?.whatif_id) {
        case 1186: {
          const { report_location, topic_id } = report;

          switch (topic_id) {
            case 10: {
              switch (report_location) {
                case 10:
                  return Promise.resolve({ data: [{ total_installs: 474500 }]} as R);
                case 20:
                  return Promise.resolve({ data: [{ paid_installs: 365000 }] }as R);
                case 30:
                  return Promise.resolve({ data: [{ organic_installs: 109500 }] }as R);
                case 40:
                  return Promise.resolve({ data: [{ marketing_spent: 730000 }] }as R);
                case 50:
                  return Promise.resolve({ data: [{ ecpi: 1.99 }] } as R);
                case 60:
                  return Promise.resolve({ data: [{ max_dau: 18473 }] }as R);
                case 70:
                  return Promise.resolve({ data: [{ total_revenues: 1185415 }] } as R);
                case 80:
                  return Promise.resolve({ data: [{ roas_time: 169 }] } as R);
                case 90:
                  return Promise.resolve({ data: [{ cashflow_time: 191 }] } as R);
                default:
                  throw new Error('Not Implemented')
              }
            }
            case 20: {
              switch (report_location) {
                case 10:
                  return Promise.resolve({ data: [{ apds:14.2 }]} as R);
                case 20:
                  return Promise.resolve({ data: [{ arpdau:0.1759324419 }] }as R);
                case 30:
                  return Promise.resolve({ data: [{ roas: 1.25 }] }as R);
                case 40:
                  return Promise.resolve({ data: [{ ltv: 2.4982406749799995 }] }as R);
                default:
                    throw new Error('Not Implemented')
              }
            }
            default:
              break;
          }

          break;
        }
        case 1187: {
          const { report_location, topic_id } = report;

          switch (topic_id) {
            case 10: {
              switch (report_location) {
                case 10:
                  return Promise.resolve({ data: [{ total_leads: 24000 }]} as R);
                case 20:
                  return Promise.resolve({ data: [{ new_customers: 2400.0 }] }as R);
                case 30:
                  return Promise.resolve({ data: [{ marketing_spent: 480000.0 }] }as R);
                case 40:
                  return Promise.resolve({ data: [{ mrr: 45080.0 }] }as R);
                case 50:
                  return Promise.resolve({ data: [{ arr:498005.0 }] } as R);
                case 60:
                  return Promise.resolve({ data: [{ acv:588.0 }] }as R);
                case 70:
                  return Promise.resolve({ data: [{ total_revenues:769090.0 }] } as R);
                case 80:
                  return Promise.resolve({ data: [{ months_to_brake_even: 10 }] } as R);
                case 90:
                  return Promise.resolve({ data: [{ gross_margin: 289090.0 }] } as R);
                default:
                  throw new Error('Not Implemented')
              }
            }
            case 20: {
              switch (report_location) {
                case 10:
                  return Promise.resolve({ data: [{ activity_months: 11.19 }]} as R);
                case 20:
                  return Promise.resolve({ data: [{ cac: 200.0 }] }as R);
                case 30:
                  return Promise.resolve({ data: [{ cltv: 540.3 }] }as R);
                case 40:
                  return Promise.resolve({ data: [{ roas: 2.7015 }] }as R);
                default:
                    throw new Error('Not Implemented')
              }
            }
            default:
              break;
          }

          break;
        }
      }
    }

    return _post(url, data, config);
  };

  return client;
};

export const useAxios = (): AxiosInstance => {
  const client = axios.create({
    baseURL: REACT_APP_API_URL,
  });

  return client;
};
