import styled from "styled-components";

const MobileBackdropPage = () => {
  return (
    <StyledWrapper>
      <h1>The Growth Simulator is currently available only on PC</h1>
    </StyledWrapper>
  );
};

export default MobileBackdropPage;

const StyledWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  h1 {
    color: white;
    text-align: center;
  }
`