import * as fromSharedSelectors from '../../../store/shared/shared.selector';

import { Box, CircularProgress } from '@mui/material';
import { AnimatePresence, motion } from "framer-motion";
import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { SeeItButtonStyled, SimItButtonStyled, SimpoolSecondaryButton, SimpoolSimpleButton } from "../../../components/Buttons/Buttons";
import { money, percent, value } from "../../../helpers/formatters";
import { simulationInfoSelectorByScenarioId, simulationResultByJobIdSelector } from "../../../store/scenarioBuilder/scenarioBuilder.selector";
import { EStatus, IScenario, IWhatif } from "../../../store/scenarioBuilder/scenarioBuilder.state";
import { getDatesFromDaysBack } from "../helpers/helpers";

import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import { IFilterBarPayload } from "../../../components/FilterBar/FilterBar";
import DiceIcon from '../../../components/Icons/DiceIcon';
import ScenarioStatusFilter from "../../../components/ScenarioStatusFilter/ScenarioStatusFilter";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { AuthService } from "../../../services/auth.service";
import { ScenarioBuilderService } from "../../../services/scenarioBuilder.service";
import Sidesheet from "../../../shared/Sidesheet/Sidesheet";
import { publish } from "../helpers/event";
import { ICohort } from "../models/models";
import Arrow from "./Arrow";
import ScenarioItemWithTooltip from "./ScenarioItemWithTooltip";
import SimulationTooltip from "./SimulationTooltip";

const statusToPercentage = {
    [EStatus.COMPLETED]: 100,
    [EStatus.CREATED]: 0,
    [EStatus.STARTED]: 50,
    [EStatus.PARTIAL]: 75,
    [EStatus.FAILED]: 0,
}

const statusToColor = {
    [EStatus.COMPLETED]: '#58C31B',
    [EStatus.CREATED]: '#52A0FA',
    [EStatus.STARTED]: '#FFCC00',
    [EStatus.FAILED]: '#D0312D',
    [EStatus.PARTIAL]: '#FFA500'
}

export const ScenarioItemContainer = styled.div`
  background: #4D5769;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
  width: 100%;
  font: normal normal 1.4rem Segoe UI;
  letter-spacing: 0px;
  color: #52A0FA;
  text-shadow: 1px 1px 3px #00000080;
`

const TextIconContainer = styled.div`
    display: flex;
`

export interface ISimulationProps {
    className?: string;
    whatIf: IWhatif;
    scenario?: IScenario;
    dynamicWidthArray?: (number | undefined)[];
    onChanges: (whatIf: IWhatif) => void;
    realResult: {
        apds: number | null,
        arppu30: number | null,
        conv: number | null,
        ltv: number | null,
        users: number | null
    },
    realResultsLoading: boolean
}

const ExportButton = styled.button`
    background: #8e8e8e;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border: 0;
    border-radius: 15px;
    font-weight: bold;
    padding: 5px 15px;
    cursor: pointer;
    height: 33px;

    &:hover {
      background: rgba(142, 142, 142, 0.8)
    }
    
`

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;

 & > div {
    display: flex;
    align-items: center;
    height: 35px;

    & > input {
      width: 250px;
      height: 100%;
      
      outline: none;
      border: none;
      padding: 0;
      padding-left: 10px;
      border-radius: 10px 0 0 10px;
    }

    & > span {
      background: white;
      padding: 5px;
      height: 100%;
      background: white;
      padding: 0 5px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
    }
 }
`

export const Search = ({ className, onChange }: { className?: string, onChange: (term: string) => void }) => {
    return <section className={className}>
        <StyledForm>
            <div>
                <input id="search" onChange={({ target: { value } }) => onChange(value)} />
                <span>
                    <SearchIcon />
                </span>
            </div>
        </StyledForm>
    </section>
}

const Simulation = ({ className, whatIf, scenario, onChanges, realResult, realResultsLoading }: ISimulationProps) => {
    const result = useSelector(simulationResultByJobIdSelector(whatIf?.id!));

    const scenarioStatusToComponent = (Component: ComponentType<{}>) => {
        if (Number(whatIf?.status) === EStatus?.COMPLETED) {
            if (result) {
                return <Component />
            }
        } else if (Number(whatIf?.status) === EStatus?.FAILED) {
            return <span style={{ color: '#D0312D' }}>N/A</span>
        } else if (Number(whatIf?.status) === EStatus.CREATED) {
            return <span style={{ color: '#52A0FA' }}>N/A</span>
        }

        return <CircularProgress style={{
            color: '#FFCC00'
        }} />
    }

    const realResultsToComponent = (Component: ComponentType<{}>, loading: boolean) => {
        if (loading) {
            return <CircularProgress style={{
                color: '#FFCC00'
            }} />
        }

        return <Component />
    }


    return <ul className={className}>
        <li >
            <SimulationTooltip whatIfObject={whatIf} userCount={scenario?.params?.users} coeff={scenario?.params?.payment_change?.[0]?.coeff} />
        </li>
        <li>
            <div>
                {
                    <b style={{ color: 'white' }}>{whatIf?.id}</b>
                }
            </div>
        </li>
        <li>
            <div>
                {
                    <b style={{ color: 'white' }}>    {Boolean(scenario?.end) ? moment(scenario?.end).format('hh:mm:sa') : 'NA'}</b>
                }
            </div>
        </li>
        <li>
            <span>
                Conversion
            </span>
            <i></i>
            {realResultsToComponent(() => <b>{Boolean(realResult?.conv) ? percent(realResult?.conv! * 100) : percent(scenario?.params?.conv!)}</b>, realResultsLoading)}
            <Arrow value={(Number(result?.conversion_rate!) * 100) > Number(scenario?.params?.conv) ? 100 : 0} />
            {
                scenarioStatusToComponent(() => <b>{percent(result?.conversion_rate! * 100)}</b>)

            }
        </li>
        <li>
            <span>
                Retention
            </span>
            <i></i>
            {realResultsToComponent(() => <b>{Boolean(realResult?.apds) ? value(realResult?.apds!) : value(scenario?.params?.apds!)}</b>, realResultsLoading)}
            <Arrow value={Number(result?.apds) > Number(scenario?.params?.apds) ? 100 : 0} />
            {
                scenarioStatusToComponent(() => <b>{value(result?.apds!)}</b>)
            }
        </li>
        <li>
            <span>
                Monetization
            </span>
            <i></i>
            {realResultsToComponent(() => <b>{Boolean(realResult?.arppu30) ? money(realResult?.arppu30!) : money(scenario?.params?.arppu30!)}</b>, realResultsLoading)}
            <Arrow value={Number(result?.arppu) > (Boolean(realResult?.arppu30!) ? Number(realResult?.arppu30!) : Number(scenario?.params?.arppu30)) ? 100 : 0} />
            {
                scenarioStatusToComponent(() => <b>{money(result?.arppu!)}</b>)
            }
        </li>
        <li>
            <span>
                LTV30
            </span>
            <i></i>
            {realResultsToComponent(() => <b>{Boolean(realResult?.ltv) ? money(realResult?.ltv!) : money(scenario?.params?.ltv!)}</b>, realResultsLoading)}
            <Arrow value={Number(result?.arpu) > (Boolean(realResult?.ltv) ? Number(realResult?.ltv) : Number(scenario?.params?.ltv)) ? 100 : 0} />
            {
                scenarioStatusToComponent(() => <b>{money(result?.arpu!)}</b>)

            }
        </li>
        <li>
            <ExportButton>
                <GetAppIcon></GetAppIcon>
                <CSVLink data={(result && [{ ...result }]) || []}><span>Export</span></CSVLink>
            </ExportButton>
            <SeeItButtonStyled onClick={() => window.open(`/whatif-results?whatIfId=${whatIf?.id}&scenario=${scenario?.name}&scenario_id=${scenario?.id}&titleId=${scenario?.title_id}`, '_blank')}></SeeItButtonStyled>
            <SimpoolSecondaryButton onClick={() => onChanges(whatIf)}>
                <MoreHorizIcon></MoreHorizIcon>
                <span>Details</span>
            </SimpoolSecondaryButton>
        </li>
    </ul>
}

const ScenarioStatus = ({ status, hasSimulationInfo }: { status: EStatus, hasSimulationInfo: boolean }) => {
    const makeStatus = (status: EStatus, hasSimulationInfo: boolean): EStatus => {
        if (status === EStatus.CREATED) {
            if (hasSimulationInfo) {
                return EStatus.STARTED
            }
        }

        return Number(status);
    }

    return <TextIconContainer >
        <DiceIcon style={{ width: '15px', marginRight: '5px', color: statusToColor[makeStatus(status, hasSimulationInfo)] }} />
        {Number(makeStatus(status, hasSimulationInfo)) === EStatus.CREATED && <span style={{ color: statusToColor[makeStatus(status, hasSimulationInfo)] }}>Created</span>}
        {Number(makeStatus(status, hasSimulationInfo)) === EStatus.STARTED && <span style={{ color: statusToColor[makeStatus(status, hasSimulationInfo)] }}>Started</span>}
        {Number(makeStatus(status, hasSimulationInfo)) === EStatus.COMPLETED && <span style={{ color: statusToColor[makeStatus(status, hasSimulationInfo)] }}>Completed</span>}
        {Number(makeStatus(status, hasSimulationInfo)) === EStatus.FAILED && <span style={{ color: statusToColor[makeStatus(status, hasSimulationInfo)] }}>Failed</span>}
        {Number(makeStatus(status, hasSimulationInfo)) === EStatus.PARTIAL && <span style={{ color: statusToColor[makeStatus(status, hasSimulationInfo)] }}>Partial</span>}
    </TextIconContainer>
}

const StyledSimulation = styled(Simulation)`
    list-style-type: none;
    margin: 15px;
    padding: 0;
    display: grid;
    grid-template-columns: ${({ dynamicWidthArray }) => `
        ${(Number(dynamicWidthArray?.[0]) / 2) - 2.5}px
        ${(Number(dynamicWidthArray?.[0]) / 2) - 2.5}px
        ${Number(dynamicWidthArray?.[1])}px
        ${Number(dynamicWidthArray?.[2])}px
        ${Number(dynamicWidthArray?.[3])}px
        ${Number(dynamicWidthArray?.[4])}px
        ${Number(dynamicWidthArray?.[5])}px
        ${Number(dynamicWidthArray?.[6]) + Number(dynamicWidthArray?.[7]) + Number(dynamicWidthArray?.[8]) + 10}px
    `};
    grid-column-gap: 5px;
   

    & > li {
        background: #293853;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 10px;
        
        
        &:nth-of-type(1) {
            div {
                background: #293853;

                display: flex;
                align-items: center;
                justify-content: space-around;
                &.simulation-tooltip {
                    position: relative;
                    white-space: nowrap;
                .html-tooltip {
                    
                    left:48px;
                }
                .info-icon{
                    fill: white;
                }
             }
            }
           
        }

        &:nth-of-type(3), 
        &:nth-of-type(4), 
        &:nth-of-type(5),
        &:nth-of-type(6), 
        &:nth-of-type(7) {
            b {
                color: #fff;
            }

            i {
                width: 1px;
                height: 70%;
                background: #707070
            }

            span:first-of-type {
                color: #DBDDE2;
                font-size: 1.2rem;
                font-weight: 600;
                text-shadow: 1px 1px 3px #000000bf;
            }
        }

        &:nth-of-type(8) {
            border-radius: 0 0 15px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            @media (max-width: 1600px) {
                button {
                    .MuiSvgIcon-root{
                        width: 0.8em;
                        height:  0.8em;
                    }
                    font-size: 0.6rem;
                    span{
                     font-size: 0.6rem;

                    }
                }
            }
            @media (max-width: 1651px) {
                button {
                    .MuiSvgIcon-root{
                        width: 0.9em;
                        height:  0.9em;
                    }
                    font-size: 0.7rem;
                    span{
                     font-size: 0.7rem;

                    }
                }
            }
            > button {
                > a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }
`

const StyledCheckboxInput = styled.input`
    width: 1.5rem;
    height: 1.5rem;

`
export const generateCohortKey = (cohort_key: IFilterBarPayload | undefined) => {
    let result = '';
    if (!cohort_key) {
        return undefined;
    }
    if (cohort_key.platform && cohort_key.platform.length > 0) {
        result = result + 'Platform: ' + cohort_key.platform.join(', ') + '; '
    }
    if (cohort_key.country && cohort_key.country.length > 0) {
        result = result + 'Country: ' + cohort_key.country.join(', ') + '; '
    }
    if (cohort_key.source && cohort_key.source.length > 0) {
        result = result + 'Source: ' + cohort_key.source.join(', ') + '; '
    }
    if (cohort_key.daysBack && cohort_key.daysBack != -1) {
        const [dateFrom, dateTo] = getDatesFromDaysBack(cohort_key.daysBack);
        result = result + 'Dates: ' + dateFrom + ' / ' + dateTo + '; ';
    } else if (cohort_key.dateFrom && cohort_key.dateTo) {
        result = result + 'Dates: ' + cohort_key.dateFrom + ' / ' + cohort_key.dateTo + '; ';

    }
    return result.length > 0 ? result : undefined;
};

const Scenario = ({ className, whatIfs, cohort, scenario, onSelect, onChanges, onEdit }: {
    className?: string,
    scenario?: IScenario,
    whatIfs: IWhatif[],
    cohort: ICohort,
    onSelect?: (selected: boolean) => void,
    onChanges: (whatIf: IWhatif) => void,
    onEdit: (scenario: IScenario) => void
}) => {
    const windowSize = useWindowSize();
    const [expanded, setExpanded] = useState(false);
    const [sectionWidth, setSectionWidth] = useState<(number | undefined)[]>([]);
    const simulationInfo = useSelector(simulationInfoSelectorByScenarioId(scenario?.id!));
    const [realResults, setRealResults] = useState<{
        [key: number]: {
            apds: number | null,
            arppu30: number | null,
            conv: number | null,
            ltv: number | null,
            users: number | null
        }
    }>({})
    const [realResultsLoading, setRealResultsLoading] = useState<{ [key: number]: boolean }>({})

    useEffect(() => {
        if (expanded) {
            whatIfs.forEach(async ({ id, status, job_id }) => {
                publish('whatif-expanded-event', { id, status, job_id });

                if (realResults[id!]) {
                    return;
                }

                setRealResultsLoading((rrl) => {
                    return JSON.parse(JSON.stringify({
                        ...rrl,
                        [id!]: true
                    }));
                });

                try {
                    const response = await ScenarioBuilderService.getScenarioRealResults(
                        scenario?.title_id!,
                        scenario?.cohort_key!,
                        id!
                    )

                    setRealResults((realResults) => {
                        const [result] = response;

                        return JSON.parse(JSON.stringify({
                            ...realResults,
                            [id!]: result
                        }));
                    })
                } finally {
                    setRealResultsLoading((rrl) => {
                        return JSON.parse(JSON.stringify({
                            ...rrl,
                            [id!]: false
                        }));
                    });
                }
            })
        }
    }, [expanded])

    const refs = useMemo(() => Array.from({ length: 9 }, _ => React.createRef<HTMLLIElement>()), []);

    useEffect(() => {
        if (!sectionWidth?.length) {
            setSectionWidth(() => refs?.map(x => x?.current?.getBoundingClientRect().width));
        }
    }, [refs, windowSize])

    const generateCohortKey = (cohort_key: IFilterBarPayload | undefined) => {
        let result = '';
        if (!cohort_key) {
            return undefined;
        }
        if (cohort_key.platform && cohort_key.platform.length > 0) {
            result = result + 'Platform: ' + cohort_key.platform.join(', ') + '; '
        }
        if (cohort_key.country && cohort_key.country.length > 0) {
            result = result + 'Country: ' + cohort_key.country.join(', ') + '; '
        }
        if (cohort_key.source && cohort_key.source.length > 0) {
            result = result + 'Source: ' + cohort_key.source.join(', ') + '; '
        }
        if (cohort_key.days_back && cohort_key.days_back != -1) {
            const [dateFrom, dateTo] = getDatesFromDaysBack(cohort_key.days_back);
            result = result + 'Dates: ' + dateFrom + ' / ' + dateTo + '; ';
        } else if (cohort_key.date_from && cohort_key.date_to) {
            result = result + 'Dates: ' + cohort_key.date_from + ' / ' + cohort_key.date_to + '; ';

        }
        return result.length > 0 ? result : undefined;
    }



    return <section className={className}>
        <ul onClick={(e) => { e.stopPropagation(); setExpanded(v => !v); }}>
            <li onClick={(e) => e.stopPropagation()}
                ref={refs[0]}
            >
                <ScenarioItemContainer>
                    <StyledCheckboxInput type="checkbox"
                        onChange={({ target: { checked } }) => {
                            if (onSelect) {
                                onSelect(checked);
                            }
                        }}
                    />
                </ScenarioItemContainer>
            </li>
            <li ref={refs[1]}>
                <ScenarioItemContainer>
                    <span>{Boolean(scenario?.end) ? moment(scenario?.end).format('YYYY-MM-DD') : 'NA'}</span>
                </ScenarioItemContainer>
            </li>
            <li
                ref={refs[2]}
            >
                <ScenarioItemContainer>
                    <span>{scenario?.name}</span>
                </ScenarioItemContainer>
            </li>
            <li
                ref={refs[3]}
            >
                <ScenarioItemWithTooltip tooltip={generateCohortKey(scenario?.cohort_key)}>
                    <span>
                        {generateCohortKey(scenario?.cohort_key) || 'Default'}
                    </span>
                </ScenarioItemWithTooltip>
            </li>
            <li
                ref={refs[4]}
            >
                <ScenarioItemContainer>
                    <span>
                        LTV30
                        {
                            scenario?.params?.ltv ?
                                <>&nbsp;|&nbsp;{money(scenario?.params?.ltv)}</> :
                                null
                        }
                    </span>
                </ScenarioItemContainer>
            </li>
            <li
                ref={refs[5]}
            >
                <ScenarioItemContainer>
                    <span> {whatIfs.length} What ifs in scenario</span>
                </ScenarioItemContainer>
            </li>
            <li
                ref={refs[6]}
            >
                <ScenarioItemContainer>
                    <ScenarioStatus status={scenario?.status!} hasSimulationInfo={Boolean(simulationInfo)} />
                    <StyledLinearProgressWrapper background={statusToColor[scenario?.status!]}>
                        <LinearProgress value={statusToPercentage[scenario?.status!]} variant={
                            Number(scenario?.status) === EStatus.STARTED ? 'indeterminate' : 'determinate'
                        } />
                    </StyledLinearProgressWrapper>
                </ScenarioItemContainer>
            </li>
            <li
                ref={refs[7]}
            >
                <ScenarioItemContainer onClick={(event) => {
                    event.stopPropagation();

                    onEdit && onEdit(scenario!)
                }}>
                    <EditIcon />
                </ScenarioItemContainer>
            </li>
            <li
                ref={refs[8]}
            >
                <ScenarioItemContainer style={{ padding: 0 }}>
                    <VisibilityIcon />
                </ScenarioItemContainer>
            </li>
        </ul>
        <AnimatePresence>
            {expanded ? <motion.aside
                transition={{ delay: 0.1 }}
                initial={{ height: 0, overflow: 'hidden', opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, overflow: 'hidden', opacity: 0 }}
            >
                {whatIfs.map(w => <StyledSimulation
                    whatIf={w}
                    scenario={scenario}
                    dynamicWidthArray={sectionWidth}
                    onChanges={onChanges}
                    key={uuidv4()}
                    realResult={realResults[w.id!]}
                    realResultsLoading={realResultsLoading[w.id!]}
                />)}
            </motion.aside> : null}
        </AnimatePresence>
    </section>
}

const StyledLinearProgressWrapper = styled.div<{ background: string }>`
    width: 40%;
    .MuiLinearProgress-root  {
        background-color: white !important;

        .MuiLinearProgress-bar  {
            background-color: ${({ background }) => `${background}`} ;
        }
    }
`

const StyledScenario = styled(Scenario)`
     background: #29364F 0% 0% no-repeat padding-box;
      box-shadow: inset 0px 3px 6px #000000;
      display: flex;
      padding: 15px;
      flex-direction: column;
      cursor: pointer;

      & > ul {
        list-style-type: none;
        padding: 0;
        width: 100%;
        display: grid;
        grid-template-columns: 5% 8% 16% 16% 16% 16% 13% 3% 3%;
        margin: 0 15px;
        grid-column-gap: 5px;
    
        li {
            display: flex;
            width: 100%;

            &:first-of-type {
                & > div {
                    margin: 0;
                }
            }
        }
      }

  aside {
    background: #1B2840;
  }
`

export const StyledFooterActions = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    .buttons-wrapper {
        display: flex;
        height: fit-content;
        align-self: center;
    }
`

const StyledChanges = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`

const StyledChangesItem = styled.li`
        background: #29364F 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #000000;
        margin: 15px;
        padding: 10px 0;
        font-size: 1rem;
        color: white;
        
        display: flex;
        align-items: center;
        justify-content: center;

        & > ul {
            margin: 0 10px;
            list-style-type: none;
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            flex-wrap: wrap;

            li {
                background: #4D5769;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0;
                width: 100%;
                font: normal normal 1.1rem Segoe UI;
                letter-spacing: 0px;
                color: #52A0FA;
                text-shadow: 1px 1px 3px #00000080;
                margin: 10px;
                margin-top: 0;

                &:first-of-type {
                    margin-top: 10px;
                }
            }
        }
`

const StyledFooterActionsFilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
`

const ScenarioBuilderFooter = ({
    className,
    cohort,
    onDelete,
    onSimulation,
    onEdit,
    scenarios,
}: {
    className?: string,
    cohort: ICohort,
    onDelete: () => void,
    onSimulation: (selectedScenarios: { [key: string]: IScenario }) => void,
    onEdit: (scenario: IScenario) => void,
    scenarios: IScenario[],
}) => {
    const [selectedScenarios, setSelectedScenarios] = useState<{ [key: string]: IScenario }>({})
    const [sidesheetOpen, setSidesheetOpen] = useState(false);
    const [whatIf, setWhatIf] = useState<IWhatif | undefined>();
    const [jwtToken] = useLocalStorage<string | undefined>(
        "jwtToken",
        undefined
    );
    const [roles, setRoles] = useState<string[]>([]);
    const [term, setTerm] = useState<string | null>(null);

    //Filters
    const scenarioStatuses = useSelector(fromSharedSelectors.sharedStatusFilterSelector);
    const scenarioLimit = useSelector(fromSharedSelectors.sharedLimitFilterSelector);

    useEffect(() => {
        if (jwtToken) {
            const parameters = JSON.parse(jwtToken);
            const user = AuthService.ParseJwtToken(parameters['access_token']);
            setRoles(user.roles);
        }
    }, [jwtToken])

    const filteredScenarios = useMemo(() => {
        let _scenarios = JSON.parse(JSON.stringify(scenarios));

        if (term != null) {
            _scenarios = _scenarios.filter(({ name }: IScenario) => name.includes(term!));
        }

        if (!scenarioStatuses || scenarioStatuses.length < 1) {
            return _scenarios.slice(0, scenarioLimit);
        }

        return _scenarios
            .filter((scenario: IScenario) => scenarioStatuses.includes(scenario.status!))

            .slice(0, scenarioLimit);
    }, [scenarios, scenarioStatuses, scenarioLimit, term]);

    return <>
        <Sidesheet open={sidesheetOpen} title="Details" onClose={() => {
            setSidesheetOpen(false);
            setWhatIf(undefined);
        }} position="right" >
            <StyledChanges>
                {whatIf?.changes?.length && whatIf.changes.map((change, index) =>
                    <StyledChangesItem key={uuidv4()}>
                        <ul>
                            {Object.keys(change).map((k, i) => <li key={i}>
                                <span>{k}:&nbsp;</span>
                                <strong>{change[k]}</strong>
                            </li>)}
                        </ul>
                    </StyledChangesItem>
                )}
            </StyledChanges>
        </Sidesheet>
        <footer className={className}>
            <StyledFooterActions>
                <StyledFooterActionsFilterContainer>
                    <ScenarioStatusFilter />
                    <Search onChange={setTerm} />
                </StyledFooterActionsFilterContainer>

                <div className="buttons-wrapper">
                    <SimItButtonStyled onClick={() => {
                        onSimulation && onSimulation(selectedScenarios);
                    }} disabled={!roles.includes('sysadmin')}></SimItButtonStyled>
                    <Box mr={1} />
                    <SimpoolSimpleButton onClick={() => {
                        onDelete && onDelete();
                    }}>Delete</SimpoolSimpleButton>
                </div>
            </StyledFooterActions>

            {filteredScenarios?.map((scenario: IScenario) => <StyledScenario
                key={scenario.id}
                whatIfs={scenario?.whatifs}
                cohort={cohort}
                scenario={scenario}
                onEdit={onEdit}
                onChanges={(whatIf) => {
                    setSidesheetOpen(true);
                    setWhatIf(whatIf);
                }}
                onSelect={(selected) => {
                    setSelectedScenarios(s => {
                        const state = JSON.parse(JSON.stringify(s));
                        if (selected) {
                            state[scenario?.name] = scenario;
                        } else {
                            delete state[scenario?.name];
                        }

                        return state;
                    })
                }}
            />)}
            <StyledFooterActions>
                <SimItButtonStyled onClick={() => {
                    onSimulation && onSimulation(selectedScenarios);
                }}></SimItButtonStyled>
                <Box mr={1} />
                <SimpoolSimpleButton onClick={() => {
                    onDelete && onDelete();
                }}>Delete</SimpoolSimpleButton>
            </StyledFooterActions>
        </footer>
    </>
}

export default styled(ScenarioBuilderFooter)`
    background: #1A273F 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #000000;
    margin: 0 20px;
    margin-top: 20px;
`