import { AxiosInstance, AxiosResponse } from "axios";

import { GroupByType } from './../components/Dropdown/SettingsDropDown';
import { useAxiosAuthorized } from "../hooks/useAxiosAuthorized";
import { useQuery } from "react-query";

export enum EChartType {
  Info = "info",
  LineTs = "line_ts",
  Table = "table",
  Pie = "pie",
  Line = "line",
  Bar = "bar",
  BarTs = "stack_bar",
  Gauge = "gauge",
  MonitorTable = "monitor_table"
}

export interface IGetWidgetsResponse {
  report_id: number;
  view_name?: string;
  dashboard_id: number;
  chart_type: EChartType;
  x_axis: string;
  y_axis: string;
  series: string;
  headline: string;
  title_id?: any;
  account_id?: any;
  filters?: string;
  width: number;
  q_config?: any;
  eco_topic_id: number;
  status: number;
  x_label?: any;
  y_label?: any;
  eco_topic_name: string;
  tooltip?: string;
  y_axis_format: string;
  query_template?: string;
  report_location: number;
  export_button?: any;
  is_payer?: any;
  tooltip_additional_info?: any;
  preview_rows?: number;
  is_filtered: boolean;
  static_filters?: any;
  data_source: string;
  stack_normelize: boolean;
  wharehouse?: string;
  group_by: GroupByType[] | null;
  use_currency: boolean;
}

export const getWidgets = async (
  axios: AxiosInstance,
  dashboardId?: number
): Promise<IGetWidgetsResponse[]> => {
  const { data } = await axios.get<
    undefined,
    AxiosResponse<IGetWidgetsResponse[]>
  >(`/dashboard/${dashboardId}/widgets`);

  return data;
};

export const useGetWidgetsQuery = (dashboardId?: number) => {
  const axios = useAxiosAuthorized();

  return useQuery<IGetWidgetsResponse[] | undefined, Error>(
    `useGetWidgetsQuery-${dashboardId}`,
    () => {
      if (!dashboardId) {
        return undefined;
      }

      return getWidgets(axios, dashboardId);
    }
  );
}
 