import { EChartType, IGetWidgetsResponse, getWidgets } from '../../queries/useGetWidgetsQuery';

import BarChart from '../../components/Charts/BarChart/BarChart';
import { ChartWidgetWrapperStyled } from '../Analytics/Analytics';
import { CircularProgress } from '@mui/material';
import GaugeChart from '../../components/Charts/GaugeChart/GaugeChart';
import GrowthSegmentsWrapper from './components/GrowthSegmentsWrapper';
import { GrowthSimulatorService } from '../../services/growthSimulator.service';
import Headline from './components/Headline';
import { IGetAccountTitlesResponse } from '../../services/accountTitles.service';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import LineChart from '../../components/Charts/LineChart/LineChart';
import MainLine from './components/MainLine';
import MonitorTableChart from '../../components/Charts/MonitorTableChart/MonitorTableChart';
import PieChart from '../../components/Charts/PieChart/PieChart';
import { SpinnerContainer } from '../Segmentation/components/SegmentationForm/SegmentationForm';
import StackedBarChart from '../../components/Charts/StackedBarChart/StackedBarChart';
import StackedLineChart from '../../components/Charts/StackedLineChart/StackedLineChart';
import TableChart from '../../components/Charts/TableChart/TableChart';
import styled from 'styled-components';
import { useAxiosAuthorized } from '../../hooks/useAxiosAuthorized';
import { useFormik } from 'formik';
import { useState } from 'react';

export interface IGrowthSimulatorProps {
  className?: string;
  title?: IGetAccountTitlesResponse;
  page?: IGetPageResponse;
};

const defaultExtraParams = {
        token_name: "suro",
        token_value: 1,
        token_daily_cap: 100,
        web3_conv: 0.1,
        token_earnings_dist: [
          {
            portion: 0.2,
            value: 0.2
          },
          {
            portion: 0.3,
            value: 0.5
          },
          {
            portion: 0.2,
            value: 0.8
          },
          {
            portion: 0.3,
            value: 1.0
          }
      ]
}
const GrowthSimulator = (props: IGrowthSimulatorProps) => {
  const { className, title, page } = props;
  const axios = useAxiosAuthorized();
  const [ widgets, setWidgets ] =   useState<IGetWidgetsResponse[] | undefined>();
  const [ isLoading, setIsLoading] = useState(false);

  const [ simulation_id, setSimulation_id ] = useState<number>();
 
  const formik = useFormik({
    initialValues: {
      headLine: {
        dailyInstals: '0',
        days: '0',
        web2web3conversion: '0%',
        cpi: 0,
        organic: '0%'
      },
      execute: {
        totalInstalls: 0,
        paidInstalls: 0,
        organicInstalls: 0,
        totalMarketingCost: '$0',
        eCPI: 0,
        totalWeb2Players: '',
        maxWeb2Dau: '',
        totalWeb3Players: '',
        maxWeb3Dau: ''
      },
      apds: {
        web2: '-',
        web3: '-'
      },
      web2_retention_anchors: {
        '1': '40%',
        '7': '30%',
        '14': '20%',
        '30': '15%',
        '60': '10%',
        '90': '10%',
        '120': '8%',
        '150': '5%',
        '180': '3%',
        '365': '1%',
      },
      web3_retention_anchors: {
        '1': '60%',
        '7': '25%',
        '14': '22%',
        '30': '14%',
        '60': '11%',
        '90': '10%',
        '120': '9%',
        '150': '8%',
        '180': '6%',
        '365': '4%',
      },
    },
    onSubmit: values => {
      setWidgets(undefined);
      const payload = {
        title_id: props?.title?.id,
        installs: values.headLine.dailyInstals,
        duration: values.headLine.days,
        retention: 1,
        web2_retention_anchors: values.web2_retention_anchors,
        web3_retention_anchors: values.web3_retention_anchors,
        extra_params: defaultExtraParams,
        web3_conversion: parseFloat(values.headLine.web2web3conversion) / 100,
        organic: values.headLine.organic 
      }

      setIsLoading(true);
      GrowthSimulatorService.executeGrowthSimulation(payload)
        .then(response => {
          if (response.data) {
            const simulationId = response.data?.simulation_id;
            setSimulation_id(simulationId);
            fetchWidgets();
          }
          
        })
        .catch(() => setIsLoading(false));
    },
  });
  
  const fetchWidgets = () => {
    const timer = setTimeout(async () => {
      try {
        const widgetsData = await getWidgets(axios, 900);
        setWidgets(widgetsData);
        setIsLoading(false);
      } catch (error) {
        setWidgets(undefined);
        setIsLoading(false);

      }
      clearTimeout(timer)
    }, 2000);
  };

  const chartFactory = (widget: IGetWidgetsResponse) => {
    switch (widget?.chart_type) {
      case EChartType.MonitorTable:
        return <MonitorTableChart widget={widget} titleId={title?.id} cross_title={page?.cross_title} />
      case EChartType.LineTs:
        return <StackedLineChart widget={widget} titleId={title?.id} cross_title={page?.cross_title}  />
      case EChartType.Line:
        return <LineChart widget={widget} titleId={title?.id}  cross_title={page?.cross_title} whatIfId={simulation_id} simulation_id={simulation_id}/>
      case EChartType.Pie:
        return <PieChart widget={widget} titleId={title?.id} cross_title={page?.cross_title}  />
      case EChartType.Bar:
        return <BarChart widget={widget} titleId={title?.id}  cross_title={page?.cross_title}  />
      case EChartType.BarTs:
        return <StackedBarChart widget={widget} titleId={title?.id}  cross_title={page?.cross_title} />
      case EChartType.Table:
        return <TableChart widget={widget} titleId={title?.id} cross_title={page?.cross_title}  />
      case EChartType.Gauge:
        return <GaugeChart widget={widget} titleId={title?.id} cross_title={page?.cross_title}  />
      default:
        return null
    }
  }
  return (
    <section className={className}>
      <Headline formikHandler={formik.setFieldValue} formikValues={formik.values} />
      <MainLine formikHandler={formik.setFieldValue} formikValues={formik.values} onSubmit={formik.submitForm}/>
      
      { isLoading &&
        <div className={'chart'}>
          <SpinnerContainer>
          <CircularProgress size="8rem" />
          </SpinnerContainer>
      </div>}
        {(!isLoading && widgets && simulation_id) && <div className={'chart'}><ul>
          {widgets?.map((w) => <ChartWidgetWrapperStyled widthRatio={w?.width} key={w?.report_id}>
              {chartFactory(w)}
            </ChartWidgetWrapperStyled>)}
        </ul></div>}
      <GrowthSegmentsWrapper cpiValue={formik.values.headLine.cpi} eCpiValue={formik.values.execute.eCPI}/>
    </section>
    );
};

export default styled(GrowthSimulator)`
  padding: 30px 30px;
  color: #fff;
  .chart{
    color: #fff;
    background-color: #43445F;
    padding: 20px 20px 20px 20px;
    margin-top: 15px;
    border-radius: 15px;
    & > ul {
      padding: 0;
      margin: 0;
      height: 100%;

      list-style-type: none;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
`;
