import React from 'react';
import { scoreColor } from '../../../helpers/colors';
import styled from 'styled-components';

export interface IScoreProps {
  value: number;
  className?: string;
}

const Score = ({ value, className }: IScoreProps) => {
  return <p className={className}>
    <span style={{
      background: scoreColor(value)
    }}>
      <span>
        {value}
      </span>
    </span>
  </p>
};

export default styled(Score)`
    background: #30314680 0% 0% no-repeat padding-box;
    border: 1px solid #939393;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 25px;

    & > span {
      min-width: 1rem;
      max-width: 1rem;
      color: #25273D;
      font-weight: bold;
      font-weight: bold;
      border-radius: 50%;
      font-size: 1rem;
      padding: 0.2rem 0.4rem;
      text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;
    }
`;
