import { IFilter } from '../../../../store/segmentation/segmentation.state';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import styled from 'styled-components';
import SegmentationForm from '../SegmentationForm/SegmentationForm';

export interface ISegmentationDialogProps {
  open: boolean;
  titleId: number;
  onClose: () => void;
  onSubmit: (values: IFilter[]) => void;
}

const StyledDialogBody = styled.div`
    color: #959599;
    background: #3D3E5A;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #303148;
    padding: 15px;
`;

const StyledWhiteLabel = styled.div`
    color: white;
    font-size: 18px;
    font-weight: 300;
`;

const StyledBorderContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #43445F;
    padding: 15px;
    margin: 10px;
    border-radius: 15px;

    &.full {
        width: 100%;
    }

    &.almost-full {
        width: 90%;
    }

    &.half {
        width: 50%;
    }
`;

const StyledControls = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-content: flex-end;
    flex-grow: 1;
    width: 100%;
`;

export const SegmentationDialog = ({ open, onClose, titleId, onSubmit }: ISegmentationDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values: IFilter[]) => {
    onSubmit && onSubmit(values);

    onClose();
  }

  return <Dialog
    PaperProps={{
      style: {
        backgroundColor: '#606077',
        boxShadow: 'none',
        borderRadius: 20,
        border: '2px solid #BBBBBB',
        width: 620,
        maxWidth: 620
      },
    }}
    open={open} onClose={handleClose}>
    <DialogContent>
      <StyledDialogBody className="margin-bottom-small">
        <StyledWhiteLabel>Filters</StyledWhiteLabel>
        <StyledBorderContainer className="almost-full">
          <StyledControls>
            <SegmentationForm titleId={titleId} onSubmit={handleSubmit}></SegmentationForm>
          </StyledControls>
        </StyledBorderContainer>
      </StyledDialogBody>
    </DialogContent>
  </Dialog>
};

export default SegmentationDialog;
