import { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { isDecimal } from "../../ScenarioBuilder/helpers/helpers";
import { StyledArrowButton } from "./ArrowUpSVG";

export enum InputType {
  Integer = 'integer',
  Percent = 'percent',
  Decimal = 'decimal'
}

export interface InputWithArrowsProps {
  className?: string;
  id?: string;
  name?: string;
  type?: InputType;
  formikHandler?: any;
  value?: string | number;
  currencySign?: boolean;
  noNegative?: boolean;
  readonly?: boolean
}
const InputWithArrows = ({ className, id, name, type = InputType.Percent, formikHandler, value: initialValue, currencySign = false, noNegative = true, readonly = false }: InputWithArrowsProps) => {

  const increaseHandler = () => {
    if (typeof initialValue === 'string') {
      if(!isNaN(parseInt(initialValue!))) {
        let currentValue = parseInt(initialValue!);
        formikHandler && formikHandler(name, type === InputType.Percent ? `${currentValue += 1}%` : `${currentValue += 1}`);
      }
    }
    if (typeof initialValue === 'number') {
      formikHandler && formikHandler(name, type === InputType.Percent ? `${initialValue += 1}%` : `${initialValue += 1}`);
    }
  }

  const increaseDecimalHandler = () => {
    if (typeof initialValue === 'number') {
      formikHandler && formikHandler(name, Number((initialValue + 0.01).toFixed(2)));
    }
  }

  const decreaseHandler = () => {
    if (typeof initialValue === 'string') {
      if(!isNaN(parseInt(initialValue!))) {
        let currentValue = parseInt(initialValue!) - 1;
        if(currentValue > -1) {
          formikHandler && formikHandler(name, type === InputType.Percent ? `${currentValue}%` : `${currentValue}`);
        }
        
      }
    }
    if (typeof initialValue === 'number') {
      formikHandler && formikHandler(name, type === InputType.Percent ? `${initialValue -= 1}%` : `${initialValue -= 1}`);
    }
  }

  const decreaseDecimalHandler = () => {
    if (typeof initialValue === 'number') {
      const currentValue = Number((initialValue - 0.01).toFixed(2));
      if(currentValue >= 0.00){
        formikHandler && formikHandler(name, currentValue);
      }
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    if(!isNaN(parseInt(currentValue))) {
      return formikHandler && formikHandler(name, parseInt(currentValue).toString());
    }
    if(currentValue === '') {
      return formikHandler && formikHandler(name, currentValue.toString());
    }
  }

  const handleDecimalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    if (typeof currentValue === 'string') {
      if(!isNaN(Number(currentValue))) {
        return formikHandler && formikHandler(name, Number(Number(currentValue).toFixed(2)));
      }
    }
    if(currentValue === '') {
      return formikHandler && formikHandler(name, 0.00);
    }
  }

  const afterHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      formikHandler && formikHandler(name, type === InputType.Percent ? '0%' : '0');
      return;
    }
    if(!isNaN(parseInt(value))) {
      formikHandler && formikHandler(name, type === InputType.Percent ? `${parseInt(value)}%` : `${parseInt(value)}`);
    }
  };
  const increaseHandlerChecker = type !== InputType.Decimal ? increaseHandler : increaseDecimalHandler;
  const decreaseHandlerChecker = type !== InputType.Decimal ? decreaseHandler : decreaseDecimalHandler;
  
  return (
    <div className={`${className} input-wrapper`}>
      {currencySign && <div className="currency-sign">$</div>}
      { type === InputType.Decimal
        ? <input readOnly={readonly} type="number" step=".01" className="col-input" id={id} name={name} value={initialValue} onChange={handleDecimalChange}/>
        : <input readOnly={readonly} type="text" className="col-input" id={id} name={name} value={initialValue} onChange={handleChange} onBlur={afterHandleChange} />}
      <div className="input-buttons">
        <StyledArrowButton direction="up" onChange={increaseHandlerChecker}/>
        <StyledArrowButton direction="down" onChange={decreaseHandlerChecker}/>
      </div>
    </div>
  );
}

export default styled(InputWithArrows)`
  /* display: flex; */
  display: grid;
  ${props => props.currencySign ? `grid-template-columns: 20px 1fr 20px;` : ' grid-template-columns: 1fr 20px;'}
  /* grid-template-columns: 1fr 20px; */
  /* width: 100%; */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } 
  .col-input {
    ${props => props.currencySign ? `border-radius: none;` : ' border-radius: 10px 0px 0px 10px;'}
    /* min-width: 50%; */
    min-width: 100px;
    /* width: 100%; */
    /* max-width: 200px; */
    border: 0;
    outline: 1px solid grey;
    outline-offset: -1px;
    padding-left: 15px;
    &:read-only {
          background-color: #fffee0;
          cursor: default;
        }
    &:focus {
      outline: 1px solid grey;
    outline-offset: -1px;
    }
  }
  .currency-sign{
    border: 1px solid grey;
    width: 20px !important;
    background-color: #f0f0f0;
    color: black;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-right: 0 !important;

  }
`