import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpoolPrimaryButton, SimpoolWarnButton } from '../../components/Buttons/Buttons';

import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';
import SimpoolDialog from '../../components/Dialog/Dialog';
import { useApplicationContext } from '../../Context';
import { updateSettings } from '../../store/settings/settings.actions';
import { settingsSelector } from '../../store/settings/settings.selector';
import { ISetting } from '../../store/settings/settings.state';

export interface ISettingsProps {
  open: boolean;
  onClose: () => void;

  className?: string;
}

const SettingsTableHeader = styled.header`
  margin-left: 25px;
  margin-bottom: 10px;
`

const SettingsTableHeaderRow = styled.ul`
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 0.2fr;
    grid-column-gap: 10px;
    margin: 0;
    padding: 0;
`

const SettingsTableHeaderRowItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #66677D;
`

const SettingsTableHeaderCell = styled.strong`
  color: #fff;
  font-weight: normal;
  padding: 10px;
`


const SettingsTableContainer = styled.section`
    max-height: 300px;
    overflow-y: scroll;
    direction: rtl;
    padding-left: 10px;

  & > div, ul {
    direction: ltr;
  }

  &::-webkit-scrollbar {
    width: 15px;              
  }

  &::-webkit-scrollbar-track {
    background: #424252;        
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777782;    
    border: 1px solid #424252; 
  }

`

const SettingsTableRowItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4E4F69;

    overflow: auto;

    
  &::-webkit-scrollbar {
    width: 15px;              
  }

  &::-webkit-scrollbar-track {
    background: #424252;        
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777782;    
    border: 1px solid #424252; 
  }
`

const SettingsTableCell = styled.span`
  color: #fff;
  font-weight: normal;
  padding: 10px;
  width: 100%;
  text-align: center;
`

const SettingsEditTableCell = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    color: #fff;
    font-weight: normal;
    padding: 10px;
`


const StyledMotionAside = styled(motion.aside)`
    grid-column: 1 / span 3;
    display: grid;
    grid-template-columns: 1fr 1fr 0.2fr;
    grid-column-gap: 10px;
`

const StyledInput = styled.input`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 2px 2px 3px #00000094;
    border-radius: 11px;
    outline: none;
    border: none;
    padding: 5px 10px;
    max-width: 60%;
    text-align: center;
`

interface ISettingsTableRowProps {
  className?: string;
  parameter_name: string;
  parameter_raw_value: string | number;
  onChange: (value: {
    parameter_name: string;
    parameter_raw_value: string | number
  }) => void;
}

const SettingsTableRow = ({ className, parameter_name, parameter_raw_value, onChange }: ISettingsTableRowProps) => {
  const [expanded, setExpanded] = useState(false);

  const [value, setValue] = useState<string | number | undefined>(parameter_raw_value);
  const [displayValue, setDisplayValue] = useState<string | number | undefined>(parameter_raw_value);

  useEffect(() => {
    if(!displayValue || !value) {
      return;
    }

    if(!parameter_name && !parameter_raw_value) {
      return;
    }
    if (displayValue !== parameter_raw_value) {
      onChange({ parameter_name, parameter_raw_value: displayValue! });
    }

  }, [displayValue, value, onChange, parameter_name, parameter_raw_value])

  return <ul className={className}>
    <SettingsTableRowItem>
      <SettingsTableCell>{parameter_name}</SettingsTableCell>
    </SettingsTableRowItem>
    <SettingsTableRowItem>
      <SettingsTableCell>{displayValue}</SettingsTableCell>
    </SettingsTableRowItem>
    <SettingsTableRowItem>
      <SettingsTableCell onClick={() => setExpanded(p => !p)}>
        {expanded ? <CheckCircleIcon /> : <EditIcon />}
      </SettingsTableCell>
    </SettingsTableRowItem>
    <AnimatePresence>
      {expanded && <StyledMotionAside
        transition={{ delay: 0.1 }}
        initial={{ height: 0, overflow: 'hidden', opacity: 0 }}
        animate={{ height: 'auto', opacity: 1 }}
        exit={{ height: 0, overflow: 'hidden', opacity: 0 }}
      >
        <SettingsTableRowItem style={{ borderRadius: '0 0 0 20px' }} />
        <SettingsTableRowItem>
          <SettingsEditTableCell>
            <StyledInput value={value} onChange={({ target: { value } }) => setValue(value)} />
            <SimpoolPrimaryButton onClick={() => {
              setValue(() => value);
              setDisplayValue(() => value);
              setExpanded(false);
            }}>
              Set
            </SimpoolPrimaryButton>
          </SettingsEditTableCell>
        </SettingsTableRowItem>
        <SettingsTableRowItem style={{ borderRadius: '0 0 20px 0' }} >
          <SettingsTableCell onClick={() => {
            setValue(() => parameter_raw_value);
            setDisplayValue(() => parameter_raw_value);
            setExpanded(false);
          }}>
            <CancelIcon />
          </SettingsTableCell>
        </SettingsTableRowItem>
      </StyledMotionAside>}
    </AnimatePresence>
  </ul>
}

const StyledSettingsTableRow = styled(SettingsTableRow)`
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 0.2fr;
  grid-column-gap: 10px;
  margin: 0;
  padding: 0;
  margin: 10px 0px;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;

 & > div {
    display: flex;
    align-items: center;
    height: 30px;

    & > input {
      width: 300px;
      height: 100%;
      
      outline: none;
      border: none;
      padding: 0;
      padding-left: 10px;
      border-radius: 10px 0 0 10px;
    }

    & > span {
      background: white;
      padding: 5px;
      height: 100%;
      background: white;
      padding: 0 5px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
    }
 }



`

//TODO(PPavlov): Connect to redux
//TODO(PPavlov): Add currency icon and dropdown

export const Search = ({ className, onChange }: { className?: string, onChange: (term: string) => void }) => {
  return <section className={className}>
    <StyledForm>
      <div>
        <input id="search" onChange={({ target: { value } }) => onChange(value)} />
        <span>
          <SearchIcon />
        </span>
      </div>
    </StyledForm>
  </section>
}

const StyledSearch = styled(Search)`

`


export const Settings = ({ className, open, onClose }: ISettingsProps) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [{ title }] = useApplicationContext();

  const settings = useSelector(settingsSelector);
  const dispatch = useDispatch();

  const [settingsToUpdate, setSettingsToUpdate] = useState<{ [key: string]: (string | number) }>({});

  const handleSettingsUpdate = () => {
    let newSettings: ISetting[] = [];

    Object.keys(settingsToUpdate).forEach(parameter_name => {
      const setting = settings?.find(s => s.parameter_name === parameter_name);

      if (!setting) {
        return;
      }

      newSettings.push({
        ...setting,
        parameter_raw_value: settingsToUpdate[parameter_name].toString()
      });

    })

    dispatch(updateSettings({
      titleId: title?.id!,
      settings: newSettings
    }))
  }

  return <SimpoolDialog
    open={open}
    title="Settings"
    onClose={onClose}
    footer={
      <>
        <SimpoolPrimaryButton onClick={() => {
          handleSettingsUpdate();
          onClose();
        }}>
          Done
        </SimpoolPrimaryButton>
        <SimpoolWarnButton onClick={onClose}>
          Cancel
        </SimpoolWarnButton>
      </>
    }
  >
    <>
      <StyledSearch onChange={setSearchTerm} />
      <SettingsTableHeader>
        <SettingsTableHeaderRow>
          <SettingsTableHeaderRowItem>
            <SettingsTableHeaderCell>Parameter's Name</SettingsTableHeaderCell>
          </SettingsTableHeaderRowItem>
          <SettingsTableHeaderRowItem>
            <SettingsTableHeaderCell>Parameter's Value</SettingsTableHeaderCell>
          </SettingsTableHeaderRowItem>
          <SettingsTableHeaderRowItem>
            <SettingsTableHeaderCell>Edit</SettingsTableHeaderCell>
          </SettingsTableHeaderRowItem>
        </SettingsTableHeaderRow>
      </SettingsTableHeader>
      <SettingsTableContainer>
        <div>
          {settings
            ?.filter(({ parameter_name }) => Boolean(searchTerm) ? parameter_name.includes(searchTerm!) : true)
            ?.map(({ parameter_name, parameter_raw_value }) => <StyledSettingsTableRow
              key={uuidv4()}
              parameter_name={parameter_name}
              parameter_raw_value={settingsToUpdate[parameter_name] || parameter_raw_value}
              onChange={({ parameter_name, parameter_raw_value }) => setSettingsToUpdate(s => ({
                ...s,
                [parameter_name]: parameter_raw_value
              }))}
            />)}
        </div>
      </SettingsTableContainer>
    </>
  </SimpoolDialog>;
};

export default styled(Settings)`
  
`;
