import { ApplicationType, BusinessType, RevenueModelType } from './pages/GrowthSimulatorV2/components/GrowthSimulatorSteps/GrowthSimulatorSteps';
import { ISubscription } from './store/billing/billing.state';
import { EGoalType, EPointOfViewType } from './store/growthSimulator/growthSimulator.state';
import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';

import { v4 as uuidv4 } from 'uuid';

const MIXPANEL_GROWTH_SIMULATOR_KEY = '19e6334e9266a303ab717bb70497f8da';
const GROWTH_SIMULATOR_SESSION_ID = 'GROWTH_SIMULATOR_SESSION_ID';

const getSessionId = (): string => {
  const currentSessionId = sessionStorage.getItem(GROWTH_SIMULATOR_SESSION_ID);
  if(!currentSessionId) {
    const newId = uuidv4();
    sessionStorage.setItem(GROWTH_SIMULATOR_SESSION_ID, newId);
    return newId;
  }
  return currentSessionId;
};

export enum MixpanelEvent{
  CharacterSelection = 'character_selection',
  ModelSelection = 'model_selection',
  RetentionNext = 'retention_next',
  SimulationExecute = 'sim_execute',
  AddTab = 'add_tab',
  AddCpi = 'add_cpi',
  SimulationSave = 'sim_save',
  CpiRegister = 'cpi_register',
  CpiCancel = 'cpi_cancel',
  SaveRegister = 'save_register',
  SaveCancel = 'save_cancel',
  TabRegister = 'tab_register',
  TabCancel = 'tab_cancel',
  ARPPUTable = 'arppu_table',
  ARPPURegister = 'arppu_register',
  ARPPUCancel = 'arppu_cancel',
  Source = 'source',
  CancelTourGS = 'cancel_tour_growth_simulator',
  FinishTourGS = 'finish_tour_growth_simulator',
  StartTourGS = 'start_tour_growth_simulator',
  RevenueTypeSelection = 'revenue_type_selection',
  ApplicationTypeSelection = 'application_type_selection',
  BusinessTypeSelection = 'business_type_selection',
  LandingPageButton = 'landing_go_next',
  ViewSubscriptionPage = 'subscription_view',
  SubscriptionPackage = 'subscription_pkg_%name%'
};

export enum Pages {
  Landing = 'landing',
  Login = 'login',
  Register = 'register',
  GrowthSimulator = 'growth_simulator'
};

export type MixpanelDialogEvents = {
  register?: MixpanelEvent,
  cancel?: MixpanelEvent
};

export const initMixPanel = () => {
  mixpanel.init(MIXPANEL_GROWTH_SIMULATOR_KEY);
  mixpanel.register({
    'Session ID': getSessionId()
  })
};

export const trackMixpanelEvent = (event: MixpanelEvent) => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(event);
  } catch (error) {
    console.error(error);
  }
};

export const trackPointOfView = (pov: EPointOfViewType) => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.CharacterSelection, {
      'EPointOfViewType': pov
    });
  } catch (error) {
    console.error(error);
  }
};

export const trackGoal = (goal: EGoalType) => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.ModelSelection, {
      'Goal': goal
    });
  } catch (error) {
    console.error(error);
  }
};

export const trackRetentionNext = () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.RetentionNext);
  } catch (error) {
    console.error(error);
  }
};

export const trackSimulationExecute = () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.SimulationExecute);
  } catch (error) {
    console.error(error);
  }
};

export const trackAddTab = () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.AddTab);
  } catch (error) {
    console.error(error);
  }
};

export const trackAddCpi = () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.AddCpi);
  } catch (error) {
    console.error(error);
  }
};

export const trackCancelTourGS= () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.CancelTourGS);
  } catch (error) {
    console.error(error);
  }
};

export const trackFinishTourGS= () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.FinishTourGS);
  } catch (error) {
    console.error(error);
  }
};

export const trackStartTourGS= () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.StartTourGS);
  } catch (error) {
    console.error(error);
  }
};


export const trackSimulationSave = () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.SimulationSave);
  } catch (error) {
    console.error(error);
  }
};

export const trackSource = (source: string) => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.Source, {
      'source': source
    });
  } catch (error) {
    console.error(error);
  }
};


export const growthSimulatorTrack = ( event_name: string,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback,) => {
  mixpanel.track(event_name, optionsOrCallback, callback)
}

export const trackRevenueType = (revenueModel: RevenueModelType) => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.RevenueTypeSelection, {
      'RevenueTypeModel': revenueModel
    });
  } catch (error) {
    console.error(error);
  }
};

export const trackApplicationType = (applicationType: ApplicationType) => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.ApplicationTypeSelection, {
      'ApplicationType': applicationType
    });
  } catch (error) {
    console.error(error);
  }
};


export const trackBusinessType = (businessType: BusinessType) => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.BusinessTypeSelection, {
      'BusinessType': businessType
    });
  } catch (error) {
    console.error(error);
  }
};

export const trackPageSource = (page: Pages, source: string | undefined = 'unknown') => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(page, {
      source
    });
  } catch (error) {
    console.error(error);
  }
};


export const trackLandingPageGoNext = () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.LandingPageButton);
  } catch (error) {
    console.error(error);
  }
};

export const trackViewSubscriptionPage = () => {
  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.ViewSubscriptionPage);
  } catch (error) {
    console.error(error);
  }
};

export const trackSubscriptionPageNameClick = (subscriptionItem: ISubscription) => {
  console.log(MixpanelEvent.SubscriptionPackage.replace(/%\w+%/g, subscriptionItem.plan_name))

  if(process.env.REACT_APP_API_URL?.includes('stage')) {
    return
  }
  try {
    mixpanel.track(MixpanelEvent.SubscriptionPackage.replace(/{\w+}/g, subscriptionItem.plan_name), {
      packageInfo: {
        id: subscriptionItem.id,
        planLink: subscriptionItem.plan_link
      }
    });
  } catch (error) {
    console.error(error);
  }
};

