import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const getAllSegments = async (titleId: Number): Promise<any[]> => {
  const { data } = await axios.get<any[]>
  (`${REACT_APP_API_URL}/segment/${titleId}`);

  return data;
};

export const getAllFilters = async (title_id: Number): Promise<any[]> => {
  const { data } = await axios.get<any[]>
  (`${REACT_APP_API_URL}/segment/fields/?title_id=${title_id}`);

  return data;
};

export const getSegmentFields = async (titleID: number): Promise<any[]> => {
  const { data } = await axios.get<any[]>
  (`${REACT_APP_API_URL}/segment/?title_id=${titleID}`);

  return data;
};

export const getSampelDataForSegemnt = async (titleID: number, segmentID: number): Promise<any[]> => {
  const { data } = await axios.get<any[]>
  (`${REACT_APP_API_URL}/segment/data/?title_id=${titleID}&segment_id=${segmentID}&sample=20`);

  return data;
};

export const getSampleDataURL = (titleID: number, segmentID: number): string => {
  return `${REACT_APP_API_URL}/segment/data/?title_id=${titleID}&segment_id=${segmentID}&sample=20`;
};

export const changeActiveStatus = async (): Promise<any[]> => {
  const { data } = await axios.get<any[]>
  (`${REACT_APP_API_URL}/segment/`);

  return data;
};

export const addSegment = async (body: any): Promise<any[]> => {
  const { data } = await axios.post<any[]>
  (`${REACT_APP_API_URL}/segment/`, body);
  
  return data;
};

export const editSegment = async (body: any, id: number): Promise<any[]> => {
  const { data } = await axios.patch<any[]>
  (`${REACT_APP_API_URL}/segment/${id}`, body);

  return data;
};

export const deleteSegment = async (body: any): Promise<any[]> => {
  const { data } = await axios.delete<any[]>
  (`${REACT_APP_API_URL}/segment/`, {
    data: body
  });

  return data;
};

export const downloadSegment = async (): Promise<any[]> => {
    const { data } = await axios.get<any[]>
    (`${REACT_APP_API_URL}/segment/`);
  
    return data;
};