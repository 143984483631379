import { z } from "zod";

const shopStringToNumberTransform = (val: any, ctx: any) => {
  if(val === null) {
    return val;
  };
  if(typeof val === 'string') {
    if(val === "" || val === 'null' ) {
      return null;
    }
    const parsed = +val;
    if(isNaN(parsed)){
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Not a number",
      });

      return z.NEVER;
    }
    return parsed;
  }
  return val;
};

const wheelArrayToStringAndReverseTransform = (value: any) => {
  if(Array.isArray(value)) {
    return value.join(', ');
  }
  if(typeof value === 'string') {
    return value.length > 0 ? value.split(',').map((v) => v.trim()) : []
  }
  return null;
};

const ArrayOfStringTypeNumberTransorm = (value: any, ctx: any) => {
  if(Array.isArray(value)) {
    return value.join(', ');
  }
  if(typeof value === 'string') {
    return value.length > 0 ? value.split(',').map((v) => {
      const parsed = +v.trim()
      if(isNaN(parsed)){
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Not a number",
        });
  
        return z.NEVER;
      }
      return parsed.toString();
      }) : []
  }
  return null;
};

const wheelArrayNumberToStringAndReverseTransform = (value: any, ctx: any) => {
  if(Array.isArray(value)) {
    return value.join(', ');
  }
  if(typeof value === 'string') {
    return value.length > 0 ? value.split(',').map((v) => {
      const parsed = +v.trim()
      if(isNaN(parsed)){
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Not a number",
        });
  
        return z.NEVER;
      }
      return parsed;
      }) : []
  }
  return null;
};

export const wheelSchema = {
  segment: z.array(z.string()).or(z.string()).nullable().transform(wheelArrayToStringAndReverseTransform),
  bonus_name: z.string().nullable(),
  platform: z.array(z.string()).or(z.string()).nullable().transform(wheelArrayToStringAndReverseTransform),
  seniority_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  seniority_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  dow_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  dow_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  multiplier: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  probability: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  amount: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  level_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  level_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  from_ltv: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  to_ltv: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  balance_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  balance_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
};


const shopSchema = {
  segment: z.string(),
  platform: z.string(),
  store_name: z.string(),
  store_type: z.string().nullable(),
  price: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  coins: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  V4M: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  multiplier: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  payments_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  payments_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  avg_spent_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  avg_spent_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  seniority_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  seniority_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  largest_purchase: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  last_multiplier: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  last_purchase_amnt: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  days_from_lat_purchase_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  days_from_lat_purchase_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  from_ltv: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  to_ltv: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  level_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  level_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  historical_balance: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  balance_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  balance_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform)
}

const bonusesSchema = {
  segment: z.string().nullable(),
  bonus_type: z.string().nullable(),
  bonus_amount: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform)
};

const levelProgressionSchema = {
  level: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  segment: z.string().nullable(),
  multiplier: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  XP: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  level_up: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  machine: z.string().nullable(),
  hourly_bonus: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  store_bonus: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform)
};

const SpecialFeature = {
  segment: z.array(z.string()).or(z.string()).nullable().transform(wheelArrayToStringAndReverseTransform),
  platform: z.array(z.string()).or(z.string()).nullable().transform(ArrayOfStringTypeNumberTransorm),
  machine: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  seniority_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  seniority_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  dow_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  dow_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  multiplier: z.array(z.number()).or(z.string()).nullable().transform(wheelArrayNumberToStringAndReverseTransform),
  weight: z.array(z.number()).or(z.string()).nullable().transform(wheelArrayNumberToStringAndReverseTransform),
  level_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  level_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  from_ltv: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  to_ltv: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  balance_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  balance_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  ltrtp_from: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
  ltrtp_to: z.number().or(z.string()).nullable().transform(shopStringToNumberTransform),
}

export const zSchemas = {
  'shop': shopSchema,
  'wheel': wheelSchema,
  'bonuses': bonusesSchema,
  "level progression": levelProgressionSchema,
  'special_features': SpecialFeature
};
