import { CurrencyCode } from "../../helpers/formatters";
import { IGetReportDataResponse } from "../../queries/useGetReportDataQuery";
import { IGetWidgetsResponse } from "../../queries/useGetWidgetsQuery";

export interface IDataTableRowCell {
  v?: any;
  f?: string;
  p?: {};
}

export interface IDataTableColumn {
  type: "string" | "number" | "boolean" | "date" | "datetime" | "timeofday";
  label?: string;
  role?: string;
  p?: { html:boolean }
}

export type IDataTableRow = (IDataTableRowCell | undefined)[];

export class DataTable {
  private _rows: IDataTableRow[] = [];
  private _cols: IDataTableColumn[] = [];
  private _legend: IChartLegend[] = [];

  public addRow(row: IDataTableRow): DataTable {
    this._rows.push(row);

    return this;
  }

  public addColumn(column: IDataTableColumn): DataTable {
    this._cols.push(column);

    return this;
  }

  public setLegend(legend: IChartLegend[]): DataTable {
    this._legend = legend;

    return this;
  }

  public make(): [IDataTableRow[], IDataTableColumn[], IChartLegend[]] {
    const length = Math.max(...this._rows.map((r) => r.length));

    const rows = Array(this._rows.length)
      .fill(undefined)
      .map(() => new Array(length).fill(undefined));

    for (let x = 0; x < rows.length; ++x) {
      for (let y = 0; y < rows[x].length; ++y) {
        rows[x][y] = this._rows && this._rows[x] && this._rows[x][y];
      }
    }

    return [rows, this._cols, this._legend];
  }
}

export interface IChartLegend {
  background: string;
  value: string | number;
}

export interface IChartDataAdapter {
  initialize(widget: IGetWidgetsResponse, currencyCode: CurrencyCode): IChartDataAdapter;
  adapt(report: IGetReportDataResponse[], filter?: Partial<IChartLegend>[]): DataTable;
}
