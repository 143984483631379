import { HotTable } from '@handsontable/react';
import { CircularProgress } from '@mui/material';
import { log } from 'console';
import { CellChange } from 'handsontable/common';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { z } from "zod";
import { StyledSpinnerWrapper } from '../../../../components/Dropdown/MultiselectDropdown';
import { enqueueSnackbar } from '../../../../store/notifications/notifications.actions';
import { zSchemas } from './types';

type SpreadSheetWrapperProps = {
  data?: EconomyObjectCOllection;
  onChange?: (eo: EconomyObjectChangesTemp) => void;
  shouldResize?: {width: number; height: number};
  economyObjectKey?: string
};
//"shop" | "bonuses" | "level progression"
registerAllModules();

export type EconomyObject = {
  [key:string]: string | number | string[] | null;
}
export type EconomyObjectCOllection = EconomyObject[];

export type EconomyObjectChanges = {
  [key:number | string]: EconomyObject
}

export type EconomyObjectChangesTemp = {
  changedValue: EconomyObject[]
  changes: EconomyObject[]
}
/**
 * A cell change represented by `[row, column, prevValue, nextValue]`.
 */

const SpreadSheetWrapper = ({ data, onChange, shouldResize, economyObjectKey }: SpreadSheetWrapperProps) => {
  const hotRef = useRef(null);
  const [ modData, setModData ] = useState<any[] | undefined>(undefined);
  const [changes, setChanges ] = useState<EconomyObjectChanges>({})
  const [ isLoading, setIsLoading ] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoading(true)
    if(data && data.length > 0 && economyObjectKey && zSchemas.hasOwnProperty(economyObjectKey)) {
      let deepCopy = JSON.parse(JSON.stringify(data))
      const newCopy: any[] = [];
      deepCopy.forEach((element: EconomyObject) => {
        let newElement: any = {};
        Object.keys(element).forEach((key) => {
          const currentSchema = zSchemas[economyObjectKey as keyof typeof zSchemas];
          const currentSchemaKey = currentSchema[key as keyof typeof currentSchema];

          if(currentSchemaKey) {
            try {
              const currentData = currentSchemaKey.parse(element[key as keyof typeof element]);
              element[key as keyof typeof element] = currentData;
            } catch (error ) {
              if( error instanceof z.ZodError) {
                if(error.issues[0].code === 'invalid_type') {
                  if(error.issues[0].received === 'number' && error.issues[0].expected === 'string') {
                    element[key as keyof typeof element] = (element[key as keyof typeof element] as number).toString();
                  } else {
                    console.log(error.issues[0]);
                    console.log('key', key);
                    console.log('value', element[key as keyof typeof element]);
                    
                  }
                }
              }
            }
          }
          
          newElement[key] = element[key as keyof typeof element];
        });
       
      newCopy.push(newElement);
      });
      
      setModData(deepCopy);
      setIsLoading(false);
    }
    return () => {
    }
  }, [data]);

  useEffect(() => {
    if(changes) {
      const currentZodType = zSchemas[economyObjectKey! as keyof typeof zSchemas];
      const currentZod = z.object({...currentZodType});
      const modified = (modData as EconomyObject[])?.map((mdData) => {
        try {
          const currentResult = currentZod.parse(mdData) as EconomyObject;
          return currentResult;
        } catch (error) {
          if(error) {
            return mdData;
          }
        }
        return mdData;
      });
  
      const currentState: EconomyObjectChangesTemp = {
        changedValue: modified,
        changes: Object.values(changes)
      };
      onChange && onChange(currentState);
    }
    
  }, [changes]);

  
  useEffect(() => {
    if ( shouldResize && hotRef &&  hotRef.current ) {
      // @ts-ignore
      hotRef.current.hotInstance.refreshDimensions();
    }
  }, [shouldResize])

  // const memoizedHeaders = useCallback(() => {
  //   return (modData && modData[0]) && Object.keys(modData[0])
  // }, [modData]);
  const memoizedHeaders = useCallback(() => {
    if( economyObjectKey && schemaMapper.hasOwnProperty(economyObjectKey)) {
      return Object.keys(schemaMapper[economyObjectKey as keyof typeof schemaMapper]);
    }
  }, [economyObjectKey]);
  const memoizedSchema = useCallback(() => {
    if( economyObjectKey && schemaMapper.hasOwnProperty(economyObjectKey)) {
      return schemaMapper[economyObjectKey as keyof typeof schemaMapper];
    }
  }, [economyObjectKey])
  return (
    <StyledSpreadsheetWrapper isLoading={isLoading}>
     {!isLoading ? 
      <HotTable
          data={modData}
          dataSchema={memoizedSchema()}
          ref={hotRef}
          rowHeaders={true}
          colHeaders={memoizedHeaders()}
          dropdownMenu={true}
          filters={true}
          licenseKey="01d38-52859-feec6-0e604-ce502" // for non-commercial use only
          outsideClickDeselects={false}
          contextMenu={['copy', 'cut']}
          beforeRefreshDimensions={() => false}
          stretchH="all"
          afterChange={((changes, source) => {
            const currentZodType = zSchemas[economyObjectKey! as keyof typeof zSchemas];
            if(changes && data && modData){
              const currentChanges: EconomyObjectChanges = {};
              const errors: string[] = [];
              changes.forEach((change) => {
                const [row, column, prevValue, nextValue] = change as CellChange;
                //Fetching a type;
                const currentZodKey  = currentZodType && currentZodType[column as keyof typeof currentZodType];

                const currentModifiedObject = JSON.parse(JSON.stringify(modData[row][column]));
                if(currentZodKey) {
                  try {
                    currentZodKey.parse(currentModifiedObject);
                    currentChanges[row] = modData[row];
                  } catch (error) {
                    modData[row][column] = prevValue;
                    if( error instanceof z.ZodError) {
                      if (changes?.length < 2) {
                        dispatch(enqueueSnackbar({
                          message: `Failed to update ${column} at row ${row + 1}`,
                          options: {
                            variant: 'error',
                          },
                        }));
                      } else {
                        errors.push(`Failed to update ${column} at row ${row + 1}`);
                      }
                    }
                  }
                }
              });
              if(errors.length > 0) {
                dispatch(enqueueSnackbar({
                  message: "Please check your document for consistence",
                  options: {
                    variant: 'error',
                  },
                }));
              }
              const currentZod = z.object({...currentZodType});
              Object.keys(currentChanges).forEach((key) => {
                const currentChangedObject = currentChanges[key];
                if(currentZod) {
                  try {
                    const currentResult = currentZod.parse(currentChangedObject);
                    currentChanges[key] = currentResult;
                  } catch (error) {
                    if(error) {
                      return null;
                    }
                  }
                }
              })
              setChanges(prevState => ({ ...prevState, ...currentChanges}));
            }
          })}
          readOnly={false}
          startRows={8}
          startCols={6}
      /> : <StyledSpinnerWrapper><CircularProgress size="2rem" /></StyledSpinnerWrapper>
    }
    </StyledSpreadsheetWrapper>
  );
};

export default SpreadSheetWrapper;

const StyledSpreadsheetWrapper = styled.div<{ isLoading?: boolean}>`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const bonusesSchema = {
  segment: null,
  bonus_type: null,
  bonus_amount: null
};

const shopShcema = {
  segment: null,
  platform: null,
  store_name: null,
  store_type: null,
  price: null,
  coins: null,
  V4M: null,
  multiplier: null,
  payments_from: null,
  payments_to: null,
  avg_spent_from: null,
  avg_spent_to: null,
  seniority_from: null,
  seniority_to: null,
  largest_purchase: null,
  last_multiplier: null,
  last_purchase_amnt: null,
  days_from_lat_purchase_from: null,
  days_from_lat_purchase_to: null,
  from_ltv: null,
  to_ltv: null,
  level_from: null,
  level_to: null,
  historical_balance: null,
  balance_from: null,
  balance_to: null
}

const levelProgressionSchema = {
  level: null,
  segment: null,
  multiplier: null,
  XP: null,
  level_up: null,
  machine: null,
  hourly_bonus: null,
  store_bonus: null
};

const wheelSchema = {
  segment: null,
  bonus_name: null,
  platform: null,
  seniority_from: null,
  seniority_to: null,
  dow_from: null,
  dow_to: null,
  multiplier: null,
  probability: null,
  amount: null,
  level_from: null,
  level_to: null,
  from_ltv: null,
  to_ltv: null,
  balance_from: null,
  balance_to: null,
};
const special_featuresSchema = {
  segment: null,
  platform: null,
  machine: null,
  seniority_from: null,
  seniority_to: null,
  dow_from: null,
  dow_to: null,
  multiplier: null,
  weight: null,
  level_from: null,
  level_to: null,
  from_ltv: null,
  to_ltv: null,
  balance_from: null,
  balance_to: null,
  ltrtp_from: null,
  ltrtp_to: null,
}
const schemaMapper = {
  "shop": shopShcema,
  "bonuses": bonusesSchema,
  "level progression": levelProgressionSchema,
  "wheel": wheelSchema,
  "special_features":special_featuresSchema
};

export type Wheel = {
  segment: string[],
  bonus_name: string,
  platform: string[],
  seniority_from: number,
  seniority_to: number,
  dow_from: number,
  dow_to: number,
  multiplier: number,
  probability: number,
  amount: number,
  level_from: number,
  level_to: number,
  from_ltv: number,
  to_ltv: number,
  balance_from: number,
  balance_to: number,
};
