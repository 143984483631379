import { AxiosInstance, AxiosResponse } from "axios";
import {
  IScenario,
  IScenarioBuilderConfiguration,
  IScenarioSimulationStatus,
  ISimulationInfo,
  ISimulationResult,
  ISimulationStatus,
  IWhatif,
} from "../store/scenarioBuilder/scenarioBuilder.state";

import { ICohort } from "../pages/ScenarioBuilder/models/models";
import { IFilter } from "../store/segmentation/segmentation.state";
import { IFilterBarPayload } from "../components/FilterBar/FilterBar";
import { Pagination } from "../components/ScenarioPaginationFilter/ScenarioPaginationFilter";

export type Filter = {
  country: string[];
  platform: string[];
  source: string[];
  days_back?: number;
  date_from?: string | Date;
  date_to?: string | Date;
  segments?: IFilter;
};

export class ScenarioBuilderService {
  public static axios: AxiosInstance;

  public static async getScenario(scenarioId: number): Promise<IScenario> {
    const { data } = await ScenarioBuilderService.axios.get<
      undefined,
      AxiosResponse<IScenario>
    >(`simit/scenario/${scenarioId}`);

    return data;
  }

  public static async getConfiguration(
    titleId: number
  ): Promise<IScenarioBuilderConfiguration> {
    const { data } = await ScenarioBuilderService.axios.get<
      undefined,
      AxiosResponse<IScenarioBuilderConfiguration>
    >(`simit/config/?title_id=${titleId}`);

    return data;
  }

  public static async getConfigurationObjects(
    titleId: number
  ): Promise<string[]> {
    const { data } = await ScenarioBuilderService.axios.get<
      undefined,
      AxiosResponse<string[]>
    >(`simit/config/objects?title_id=${titleId}`);

    return data;
  }

  public static async getConfigurationObjectsByPath(
    titleId: number,
    path: string
  ): Promise<{
    path: string;
    data: { [key: string]: string | number }[];
  }> {
    const { data } = await ScenarioBuilderService.axios.get<
      undefined,
      AxiosResponse<{ [key: string]: string | number }[]>
    >(`simit/config/${titleId}/${path}`);

    return {
      path,
      data,
    };
  }

  public static async getDefaultCohort(
    titleId: number,
    filter?: IFilterBarPayload
  ): Promise<ICohort[]> {
    const { data } = await ScenarioBuilderService.axios.post<
      undefined,
      AxiosResponse<ICohort[]>
    >(`/dashboard/data`, {
      topic_id: 20,
      report_location: 20,
      // report_id: 14154,
      dashboard_id: 6000,
      title_id: titleId,
      days_back: filter?.daysBack || 60,
      date_from: filter?.dateFrom || "-1",
      date_to: filter?.dateTo || "-1",
      country: filter?.country,
      platform: filter?.platform,
      source: filter?.source,
      segment_filters: filter?.segments,
    });
    return data;
  }

  public static async createScenario(scenario: IScenario): Promise<IScenario> {
    const { data } = await ScenarioBuilderService.axios.post<
      IScenario,
      AxiosResponse<IScenario>
    >(`/simit/scenario`, scenario);

    return data;
  }

  public static async runScenarioSimulation(
    titleId: number,
    scenarioId: number
  ): Promise<ISimulationInfo> {
    const { data } = await ScenarioBuilderService.axios.post<
      IWhatif[],
      AxiosResponse<ISimulationInfo>
    >(`/simit/scenario/run`, {
      title_id: titleId,
      scenario_id: scenarioId,
    });

    return data;
  }

  public static async getSimulationStatus(
    jobId: string,
    whatIfId: string
  ): Promise<ISimulationStatus> {
    const { data } = await ScenarioBuilderService.axios.get<
      undefined,
      AxiosResponse<ISimulationStatus>
    >(`/simit/job/${jobId}/status?whatif_id=${whatIfId}`);

    return data;
  }

  public static async getScenarioSimulationStatus(
    scenarioId: number
  ): Promise<IScenarioSimulationStatus> {
    const { data } = await ScenarioBuilderService.axios.get<
      undefined,
      AxiosResponse<IScenarioSimulationStatus>
    >(`/simit/scenario/${scenarioId}/status`);

    return data;
  }

  public static async getSimulationResult(
    jobId: string,
    full: boolean = false
  ): Promise<ISimulationResult[] | null> {
    if (!jobId) {
      return null;
    }

    const { data } = await ScenarioBuilderService.axios.get<
      undefined,
      AxiosResponse<ISimulationResult[]>
    >(`/simit/job/${jobId}/result?full=${full}`);

    return data;
  }

  public static async getScenarioRealResults(
    titleId: number,
    filter: IFilterBarPayload,
    whatif_id: number
  ) {
    const { data } = await ScenarioBuilderService.axios.post<
      undefined,
      AxiosResponse<{
        apds: number | null, 
        arppu30: number | null,
        conv: number | null,
        ltv: number | null,
        users: number | null
      }[]>
    >(`/dashboard/data`, {
        dashboard_id: 6000,
        topic_id: 10,
        report_location: 60,
        title_id: titleId,
        days_back: filter?.daysBack || 60,
        date_from: filter?.dateFrom || "-1",
        date_to: filter?.dateTo || "-1",
        country: filter?.country,
        platform: filter?.platform,
        source: filter?.source,
        segment_filters: filter?.segments,
        whatif_id
    });

    return data;
  }

  public static async getScenarios(
    titleId: number,
    statuses?: string[],
    limit?: Pagination
  ): Promise<IScenario[]> {
    const { data } = await ScenarioBuilderService.axios.get<
      undefined,
      AxiosResponse<IScenario[]>
    >(`/simit/title/${titleId}/scenario`, {
      params: {
        status: statuses,
        limit: limit,
      },
      paramsSerializer: (params) => {
        let serializedparams =
          params.status?.map((param: any) => `status=${param}`).join("&") || "";
        if (params.limit) {
          serializedparams = serializedparams + `&limit=${params.limit}`;
        }
        return serializedparams;
      },
    });

    return data;
  }
}
