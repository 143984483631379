import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApplicationContext } from '../Context';

import { StatusCodes } from 'http-status-codes';
import { useContext } from 'react';
import useLocalStorage from './useLocalStorage';

const { REACT_APP_API_URL } = process.env;

export const useAxiosAuthorized = (): AxiosInstance => {
  const [context, setContext] = useContext(ApplicationContext)! || [];
  const [jwtToken, setJwtToken] = useLocalStorage<string | undefined>('jwtToken', undefined);

  const client = useAxios();

  const interceptors = {
    request: (config: AxiosRequestConfig) => {
      const parameters = localStorage.getItem('jwtToken') && JSON.parse(localStorage.getItem('jwtToken')!);

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: parameters && `Bearer ${parameters['access_token']}`,
          ['x-simpool-user']: context?.authentication?.user?.simpool?.simpool_id,
        },
      };
    },
    response: (response: AxiosResponse) => {
      return response;
    },
    error: (error: AxiosError) => {
      if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
        localStorage.removeItem('jwtToken');

        if (Boolean(setContext)) {
          setContext((state) => ({
            ...state,
            authentication: undefined,
          }));
        }
      }

      return Promise.reject(error);
    },
  };

  client.interceptors.request.use(interceptors.request, interceptors.error);
  client.interceptors.response.use(interceptors.response, interceptors.error);

  return client;
};

export const useAxios = (): AxiosInstance => {
  const client = axios.create({
    baseURL: REACT_APP_API_URL,
  });

  return client;
};
