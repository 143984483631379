import { useEffect } from 'react';
import { useHistory } from "react-router-dom";


export interface IPaymentCallbackProps {
}

const PaymentCallback = (props: IPaymentCallbackProps) => {
  const history = useHistory();
  useEffect(() => {
    history.push('growth-simulator-v2');
  }, [])

  //INFO(PPavlov): For now we wont use this logic 
  // const { search } = useLocation();
  // const shopperId = new URLSearchParams(search).get('shopper_id')?.toString();
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (shopperId) {
  //     dispatch(getUserOnboarding({
  //       shopperId: Number(shopperId)
  //     }))
  //   }

  // }, [shopperId])

  return null;
};

export default PaymentCallback;
