import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import {
  menuItemSelector,
  menuSelector
} from "@szhsin/react-menu/style-utils";
import MenuIcon from '@mui/icons-material/Menu';
import { useCallback } from 'react';
import styled from 'styled-components';
import { EGoalType } from '../../store/growthSimulator/growthSimulator.state';
import { v4 as uuidv4 } from 'uuid';

type GrowthSimulatorDropDownProps = {
  icon?: any;
  value: EGoalType;
  handleGoalTypeChange: (goal: EGoalType) => void;
}
const GrowthSimulatorDropDown = ({ icon, value, handleGoalTypeChange }: GrowthSimulatorDropDownProps) => {
  const values = useCallback(() => {
    return Object.values(EGoalType).filter(v => v !== value)
  }, [value])()

  const handleSetGoal = (goal: EGoalType) => () => {
    handleGoalTypeChange(goal);
  };

  return (
    <StyledMenu menuButton={<StyledMenuButon><StyledMenuIcon /></StyledMenuButon>} transition >
      {values.map((val => <MenuItem onClick={handleSetGoal(val)} key={`GrowthSimulatorDropDown--${val}`}>{val}</MenuItem>))}
    </StyledMenu>
  )
}

export default GrowthSimulatorDropDown;

export const StyledMenu = styled(Menu)`
  ${menuSelector.name} {
    font-size: 1rem;
    background: #1c2942;
    color: #E2E2E2;
    border: 1px solid white;
    padding: 5px;
    ${menuItemSelector.hover} {
      background: #5e5f76;
    }
  }
`

const StyledMenuIcon = styled(MenuIcon)`
    display: flex;
    align-self: flex-end;
    color: #c6c9d0;
    /* margin: 0px 0px 5px 15px; */
    align-self: flex-end;
    flex-direction: column;
`

export const StyledMenuButon = styled(MenuButton)`
  color: #E2E2E2;
  font-size: 1.2rem;
  background: none;
  cursor: pointer;
	outline: inherit;
  border:none;
  line-height: 2.28rem;
  display: flex;
  align-self: flex-end;

  &:hover:not(.szh-menu-button--open) {
    &.szh-menu-button {
    svg {
      opacity: 0.8;
    }

  }
  }
  &.szh-menu-button--open {
    svg {
      opacity: 1;
    }

  }
  svg {
    // fill: #918F8F;
    fill: #FFFFFF;
    opacity: 0.5;
    font-size: 2rem;
    line-height: 2rem;
  }
`