import { ISetting } from "./settings.state";
import { SettingsService } from "../../services/settings.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSettings = createAsyncThunk(
  "[settings]/getSettings",
  async (titleId: number) => await SettingsService.getSettings(titleId)
);

export const updateSettings = createAsyncThunk(
  "[settings]/updateSettings",
  async ({ titleId, settings }: { titleId: number; settings: ISetting[] }) =>
    await SettingsService.updateSettings(titleId, settings)
);
