import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const AcqusitionCell = ({ item, className }: { item: any, className?: string }) => {
  const [ isHovered, setIsHovered ] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    if(elementRef && elementRef.current) {
      refHandler(elementRef.current)
    }
  }, [elementRef])

  const refHandler = (e:any) => {
    const cellWidth = e.offsetWidth;
    const cellActualWidth = e.scrollWidth;
    if( cellActualWidth - cellWidth > 0) {
      setIsHovered(true);
    }
  }

  return <StyledWrapper className={`${isHovered ? 'hovered' : ''}`}  >
    <StyledListItem   style={{  display: 'block', } } ref={elementRef} >
      {item}
      </StyledListItem>
      {isHovered && <span className="tooltip">  {item}</span>}
   </StyledWrapper>
}

export default AcqusitionCell;
const StyledWrapper = styled.div`
position: relative;
&.hovered {
  &:hover {
    .tooltip {
      opacity: 1;
      left: -23%;
      top: -92%;
    }
  }
  }
  .tooltip {
    position: absolute !important;
    width: 129px;
    opacity: 0;
    background: #9091A0;
    display: block;
    padding: 8px 8px;
    color: #FFF;
    font-size: 0.8rem;
    position: absolute;
    z-index: 1000;
    border-radius: 15px;
  }
`
const StyledListItem = styled.li`
user-select: none;
  background: #2C2C3E;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  padding: 10px 0;
  color: #E7E7E7;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  position: relative;
  
  /* &.hovered {
    cursor: pointer;
    
  } */


`
