import { ComponentType, useEffect, useState } from "react";

import { EGoalType } from "../../../../../store/growthSimulator/growthSimulator.state";
import { IGrowthSimulatorWidget } from "../../../models/growthSimulator.models";
import { formatter } from "../../../../../components/Charts/StackedLineChart/StackedLineChart";
import { useGetReportDataQuery } from "../../../../../queries/useGetReportDataQuery";

interface Props {
  simulationId: number;
  widget: IGrowthSimulatorWidget;
  component: ComponentType<{}>;
}

const GrowthSimulatorResultLabel = ({ widget, simulationId, component: Component }: Props) => {
  const [reportData, setReportData] = useState<string | number | Date>();

  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      dashboard_id: widget?.dashboard_id,
      title_id: widget?.title_id || 88,
      whatif_id: simulationId,
      simulation_id: simulationId,
      report_location: widget?.report_location,
      topic_id: widget?.eco_topic_id,
      date_from: '-1',
      date_to: '-1'
    }, `GrowthSimulatorResultLabel-${simulationId}-${widget?.report_location}-${widget?.eco_topic_id}`, (payload) => Boolean(payload.simulation_id)
  );

  useEffect(() => {
    if (
      !widget ||
      !getReportDataQueryResponse ||
      !simulationId ||
      getReportDataQueryResponse.isLoading
    ) {
      setReportData(0);
      return;
    }

    const { data, error } = getReportDataQueryResponse;

    if (!data || error) {
      return;
    }
   
    const [item] = data;
    
    const key = widget?.headline?.toLocaleLowerCase()?.split(' ').join('_').replace(new RegExp(/\((.*?)\)/gm),'');

    const value = item?.[key as keyof typeof item];
    
    if (JSON.stringify(reportData) === JSON.stringify(value)) {
      return;
    }
    
    setReportData(value);

  }, [getReportDataQueryResponse, widget, reportData, simulationId])

  return (
    <Component>
      {formatter(reportData || 0, widget?.y_axis_format, 'USD')}
    </Component>
  );
};

export default GrowthSimulatorResultLabel;
