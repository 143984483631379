import { Button, Checkbox, CheckboxProps, TextField, TextFieldProps } from "@mui/material";
import { colorWhite, inputBgColor } from "./constants";

import background from '../../assets/landing_bg.png';
import { device } from "../../helpers/device";
import styled from "styled-components";

export const StyledLandingPageWrapper = styled.div`
  width: 100%;
  max-height: 1100px;
  background:url(${background}) no-repeat center fixed;     
  background-size: 100% 100%;
  box-sizing: border-box;
  position: relative;
  .avatar { 
    position: absolute;
   
    width: 28%;
    /* left: 10%; */
    right: 25%;
    bottom: 0;
    @media ${device.laptop} { 
      width: 38%;
      bottom: 0;
      /* left: 5%; */
      right: 25%;

    }
    z-index: 100;
  }
  .scroll-icon{
    position: absolute;
    left: 50%;
    height: 25px;
    width: 25px;
    animation: pulse 2s infinite;
    bottom: 2%;

    @media ${device.mobileL} { 
      left: calc(50% - (25px / 2))
    }

  }
  @keyframes pulse {
  0% {
      transform: scale(0.9);
  }

  70% {
      transform: scale(1.1);
  }

  100% {
    transform: scale(0.9);
  }
}
`

export const StyledLandingPageInnerContainer = styled.div`
  min-height: 100vh;
    height: fit-content;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1600px;

  @media ${device.mobileL} { 
    min-height: 85vh;
  }


  @media ${device.laptopS} { 
    padding: 20px 83px !important;
  }
  @media ${device.laptop} { 
    padding: 40px 70px !important;
  }
`

export const StyledHeading = styled.h1`
  font-size: 45px;
  font-weight: 800;
  color: ${colorWhite};
  line-height: 60px;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  @media ${device.laptopSL} { 
    font-size: 50.6667px;
    line-height: 70px;
  }
  @media ${device.laptopL} { 
    font-size: 44.3333px;
    line-height: 60px;
  }
  @media ${device.laptopS} { 
    font-size: 38px;
    line-height: 54px;
  }
  @media ${device.laptop} { 
    font-size: 31.6667px;
    line-height: 46px;
  }
  &:hover {
    cursor: pointer;
  }
`

export const StyledH3 = styled.h3`
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  color: ${colorWhite};
  line-height: 35px;
  font-family: 'DM Sans', sans-serif;

  pre {
    font-size: 20px;
    font-weight: 400;
    color: ${colorWhite};
    line-height: 25px;
    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
  }
  @media ${device.laptopL} { 
    font-size: 17.4949px;
    line-height: 29px
  }
  @media ${device.laptopS} { 
    font-size: 14.9957px;
    line-height: 25px;
  }
  @media ${device.laptop} { 
    font-size: 12.4964px;
    line-height: 21px;
  }
`

export const StyledH2 = styled.h2`
  font-size: 24px;
  font-weight: 400;
  color: ${colorWhite};
  line-height: 25px;
`

export const StyledLandingCardContainer = styled.div`
  color: ${colorWhite};
  letter-spacing: 0px;
  margin-top: 24px;
  @media ${device.laptopL} { 
    margin-top: 15px;
  }
  @media ${device.laptopS} { 
    margin-top: 17px;
  }
  @media ${device.laptop} { 
    margin-top: 15px;
  }
  .pre-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 17px;
    @media ${device.laptopS} { 
      font-size: 17px;
      line-height: 17px;
    }
    @media ${device.laptopS} { 
      font-size: 15px;
      line-height: 15px;
    }
    @media ${device.laptop} { 
      font-size: 12px;
      line-height: 12px;
    }
  }
  .big-text{
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    font-family: 'Open Sans', sans-serif;
    @media ${device.laptopL} { 
      font-size: 49px;
      line-height: 49px
    }
    @media ${device.laptopS} { 
      font-size: 42px;
      line-height: 42px;
    }
    @media ${device.laptop} { 
      font-size: 35px;
      line-height: 35px;
    }
  }
  .small-text{
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'DM Sans', sans-serif;
    @media ${device.laptopL} { 
      font-size: 24.5px;
      line-height: 32px
    }
    @media ${device.laptopS} { 
      font-size: 21px;
      line-height: 22px;
    }
    @media ${device.laptop} { 
      font-size: 17.5px;
      line-height: 18px;
    }
  }
`;

export const StyledLandingButton = styled(Button)`
  width: 350px;
  height: 70px;
  font-size: 24px;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  margin-top: 50px;
  &.MuiButtonBase-root{
    background: linear-gradient(90.59deg, #FF9852 -43.16%, #E9681F 104.39%);
    box-shadow: 3px 6px 20px 7px rgba(28, 39, 63, 0.25);
    border-radius: 6px;
    font-size: 23px;
    font-weight: 400;
    text-transform: none;
    font-family: 'DM Sans', sans-serif;
  }
  @media ${device.laptopL} { 
    width:360px;
    height: 72px;
    font-size: 24.5px !important;
    line-height: 25px !important;
  }
  @media ${device.laptopS} { 
    width:310px;
    height: 61px;
    font-size: 21px !important;
    line-height: 22px !important;
  }
  @media ${device.laptop} { 
    width:257px;
    height: 51px;
    font-weight: 500;
    font-size: 17.5px !important;
    line-height: 18px !important;
  }
`

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: 300,
  color: '#212b49 !important',
  '& .MuiFormLabel-root' : {
    color: '#212b49 !important',
    opacity: '0.9',
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'DM Sans'
  },
  '& .MuiInputBase-root': {
    backgroundColor: '#d9d9d9',
    color: '#212b49',
    fontFamily: 'DM Sans',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(103, 125, 161, 0.75)'
    },
    
  },
  '& .MuiFormHelperText-root' : {
    color: 'white !important',
    fontSize: '0.9rem',
    fontFamily: 'DM Sans',
    // fontStyle: 'bold'
  fontWeight: 'bold',
  '&.Mui-error': {
    color: 'red  !important'
  }

  }
}));

export const StyledCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  color: 'white !important',
  // backgroundColor: 'white !important',
}));