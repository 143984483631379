import { useCallback } from "react";
import styled from "styled-components";
import InputWithArrows, { InputType } from "./InputWithArrows";
import { Kpis } from "./types";

interface GrowthKpisProps {
  className?: string;
  formikValues?: Kpis;
  formikHandler?: any;
  averagePayment?: number;
}
const GrowthKpis = ({className, formikValues, formikHandler }: GrowthKpisProps) => {
  return (
    <div className={className}>
      <header>
        F2P(web2) KPIs
      </header>
      <div className="body">
        <div className="labels">
          
        </div>
        <div className="input-row">
          <label htmlFor="">Paying users conversion</label>
          <InputWithArrows value={formikValues!.payingUsersConversion} formikHandler={formikHandler} name={'kpis.payingUsersConversion'} />
          
        </div>
        <div className="input-row">
          <label htmlFor="">Average Payment</label>
          <InputWithArrows value={formikValues!.averagePayment} formikHandler={formikHandler} name={'kpis.averagePayment'} currencySign type={InputType.Decimal} readonly/>
        </div>
        <div className="input-row">
          <label htmlFor="">Payments per payer</label>
          <InputWithArrows value={formikValues!.paymentsPerPlayer} formikHandler={formikHandler} name={'kpis.paymentsPerPlayer'} type={InputType.Decimal} readonly/>
        </div>
      </div>
      <footer>
        <div className="ltv-wrapper">
          <div className="ltv-label">LTV</div>
          <div className="ltv-value">
            <span>
              $ { formikValues?.ltv ||  0.00}
            </span>
          </div>
        </div>
        <div className="input-row">
          <label htmlFor="">ROI</label>
          <input value={formikValues!.roi} readOnly name={'kpis.roi'} type="text"/>
        </div>
        <div className="input-row">
          <label htmlFor="">eROI</label>
          <input value={formikValues!.eroi} readOnly name={'kpis.eroi'} type="text"/>
        </div>
      </footer>
    </div>
  );

};

export default styled(GrowthKpis)`
  height: 100%;
  background-color: #2E2F4B;
  border-radius: 15px;
  /* padding: 10px; */
  header {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2E2F4B;
    height: 48px;
    overflow: hidden;
    border-radius: 15px;
  }
  .body{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    background-color:#212238;
    padding-bottom: 15px;
    .labels{
      display: grid;
      grid-template-columns: 100px 1fr 1fr 1fr;
      padding: 10px;
      justify-content: center;
      div {
        display: grid;
        justify-content: center;
      }
    }

    .input-row{
      display: grid;
      grid-template-columns: 200px minmax(200px, 50%);
      padding-left: 20px;
      min-height: 38px;
      justify-content: space-between;
      /* width: 50%; */
      
      div {
        justify-content: end;
        padding-right: 20px;
      }
      label{
        align-self: center;
      }
    }
  }

  footer{
    padding: 10px 35px 10px 20px;
    display: grid;
    grid-template-columns: minmax(100px, 40%) 1fr 1fr;
    /* align-content: center; */
    grid-gap: 20px;
    .ltv-wrapper{
      display: flex;
      align-items: center;
      .ltv-value {
        display: grid;
        justify-content: center;
        grid-template-columns:  1fr;
  
        span {
          /* width: 50%; */
          background-color: rgba(88, 195, 27, 0.7);
          border-radius: 15px;
          padding: 5px 15px;
          display: grid;
          justify-content: center;
          justify-self: center;
        }
      }
  
      .ltv-label{
        display: grid;
        align-self: center;
        margin-right: 20px;
        
      }

    }

    .input-row{
      display: grid;
      grid-template-columns: auto minmax(100px, 1fr);
      /* grid-gap: 20px; */
      align-items: center;
      input {
        border-radius: 15px;
        padding: 0 0 0 15px ;
        &:read-only {
          background-color: #fffee0;
          cursor: default;
        }
        &:focus {
              outline: none;
            }
      }
      > div {
        height: 100%;
      }
      label {
        margin-right: 15px;
      }
      input {
        height: 100%;
      }
    }
  }
`