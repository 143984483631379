import { AnimatePresence, motion } from 'framer-motion';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { useState } from 'react';

interface Props {
  title?: string;
  children: JSX.Element;
  className?: string;
  component?: JSX.Element;
  defaultExpanded?: boolean;
  disabled?: boolean;
}

const Expandable = ({ title, children, className, component, defaultExpanded = false, disabled, ...rest }: Props) => {
  const [expanded, setExpanded] = useState<Boolean>(defaultExpanded);

  const toggleExpanded = () => {
    if(!disabled) {
      setExpanded(!expanded);
    }
  }

  return (
    <div className={className} {...rest}>
      <AnimatePresence>
        <section onClick={toggleExpanded} >
          <span>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
          {component ? component : <h4>{title}</h4>}
        </section>

        {expanded ? <motion.aside hidden={!expanded}
          transition={{ delay: 0.1 }}
          initial={{ height: 0, overflow: 'hidden', opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, overflow: 'hidden', opacity: 0 }}
          key='expandable-animation'
        >
          {children}
        </motion.aside> : null}
      </AnimatePresence>
    </div>
  );
};

export default styled(Expandable)`
section {
  color: #fff;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  user-select: none;
  cursor: pointer;

  margin: 10px 0;

  h4 {
    margin: 0;
  }
}

`;
