import { ReactNode, useEffect, useState } from 'react';
import SettingsDropDown, { GroupByType } from '../../Dropdown/SettingsDropDown';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CircularProgress } from '@mui/material';
import cn from 'classnames';
import styled from 'styled-components';
import CurrencyDropdown from '../../Dropdown/CurrencyDropdown';
import Tooltip from '../../Tooltip/Tooltip';
import { IChartLegend } from '../models';
import CustomCheckbox from './CustomCheckbox';

export interface IChartContainerProps {
  children: ReactNode,
  className?: string;
  loading?: boolean;
  headline?: string;
  legend?: { background: string, value: string | number }[],
  onChange?: (legend: IChartLegend[]) => void,
  groupBy?: Array<GroupByType>;
  onGroupByChange?: (item: string | undefined) => void;
  disableLegendFilter?: boolean;
  tooltip?: string;
  showCurrency?: boolean;
  onCurrencyChange?: (item: string) => void;
  exportData?: any;
}

const ChartContainerHeaderStyled = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    width: 100%;
`

const ChartContainerItemStyled = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    justify-content: flex-start;
  }

  &:last-of-type {
    justify-content: flex-end;

  }
`

const ChartContainerLegendItem = ({ className, background, value, onClick, selected: _selected, disableLegendFilter }:
  { className?: string, background: string, value: string | number, disableLegendFilter?: boolean, onClick?: (input: { value: (string | number), selected: boolean }) => void, selected?: boolean }
) => {
  const [selected, setSelected] = useState(false);
  
  useEffect(() => {
    setSelected(Boolean(_selected))
  }, [_selected])

  return <li
    className={className}
    onClick={() => {
      if (disableLegendFilter) {
        return;
      }

      setSelected((old) => !old);

      if (onClick) {
        onClick({ selected: !selected, value })
      }
    }}
  >
    <span style={{
      background: selected ? 'none' : background,
      outline: selected ? `2px solid ${background}` : 'none',
      outlineOffset: selected ? '-1px' : 'none',
      opacity: selected ? '0.5' : '1'
    }}></span>
    <span>{value}</span>
  </li>
}

const ChartContainerFooterStyled = styled.footer`
  position: relative;
  border-top: 1px solid rgb(73,74,93);

  background: #2E2F4B 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 6px #00000073;
  border-radius: 0 0px 15px 15px;

  padding: 10px 0;
`

const ChartContainerLegendStyled = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 0;

    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
`

const ChartContainerLegendItemStyled = styled(ChartContainerLegendItem)`
  display: flex;
  align-items: center;
  margin: 10px 0;

  span:first-of-type {
    width: 1.2rem;
    height: 1.2rem;
    display: inline-flex;
    border-radius: 5px;
  }

  span {
    margin: 0 5px;
    font-size: 0.8rem;
  }

  span:last-of-type {
    border-right: 1px solid #9999A4;
    padding-right: 10px;
  }

  &:last-of-type {
    span:last-of-type {
      border-right: none;
      padding-right: 0;
    }
  }

  &:first-of-type {
    span:first-of-type {
    }
  }
`

const IconWrapper = styled.div`
  margin: 0 5px;
`

const ChartContainer = ({ 
  children, 
  className, 
  loading, 
  headline, 
  disableLegendFilter, 
  legend,
   onChange, 
   groupBy = [], 
   onGroupByChange = () => { }, 
   showCurrency = false,
   onCurrencyChange = () => {} ,
   exportData,
   tooltip
}: IChartContainerProps) => {
  const [legendFilters, setLegendFilters] = useState<IChartLegend[]>([]);
  const [toggleFilters, setToggleFilters] = useState(true);

  useEffect(() => {
    if (onChange) {
      onChange(legendFilters!);
    }
  }, [legendFilters])

  useEffect(() => {
    if (toggleFilters) {
      setLegendFilters([]);
    } else {
      setLegendFilters(legend as IChartLegend[]);
    }
  }, [toggleFilters])

  const handleLegendChange = ({ value, selected }: {
    value: (string | number);
    selected: boolean;
  }) => {
    if (selected) {
      setLegendFilters((filters) => [...filters, legend?.find(l => l.value === value)!])
    } else {
      setLegendFilters((filters) => filters.filter(f => f.value !== value))
    }
  }

  return <section className={className}>
    <header>
      <ChartContainerHeaderStyled>
        <ChartContainerItemStyled>
          <SettingsDropDown iconSize={1.6} values={groupBy} onGroupByChange={onGroupByChange} exportData={exportData} headline={headline}/>
          <div className='info-icon-wrapper'>
            <InfoOutlinedIcon className='info-icon' />
            <Tooltip text={tooltip} shouldCalculate={false}/>
          </div>
        </ChartContainerItemStyled>
        <ChartContainerItemStyled>
          <span>{headline}</span>
        </ChartContainerItemStyled>
        <ChartContainerItemStyled>
          {showCurrency && <IconWrapper>
            <CurrencyDropdown onCurrencyChange={onCurrencyChange}/>
          </IconWrapper>}
          <IconWrapper>
            <FavoriteBorderOutlinedIcon />
          </IconWrapper>
        </ChartContainerItemStyled>
      </ChartContainerHeaderStyled>
    </header>
    <div className={cn({ loading }, `${className}-body`)}>
      {loading ? <CircularProgress size="10rem" /> : children}
    </div>
    <ChartContainerFooterStyled>
      <ChartContainerLegendStyled>
        {
          legend?.map(({ value, background }, i) => {
            const selected = legendFilters.find(l => l.value === value);

            return <ChartContainerLegendItemStyled
              disableLegendFilter={disableLegendFilter}
              key={`${legend}--${i}`}
              background={background}
              value={value}
              onClick={handleLegendChange}
              selected={Boolean(selected)}
            />
          })
        }
      </ChartContainerLegendStyled>
      {loading || disableLegendFilter ? null : <CustomCheckbox checked={toggleFilters} onChange={() => setToggleFilters((p) => !p)} />}
    </ChartContainerFooterStyled>
  </section>
};
const iconColor = '#9091A0';
export default styled(ChartContainer)`
    background: #212239 0% 0% no-repeat padding-box;
    box-shadow: inset 3px 3px 6px #00000073;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    .info-icon-wrapper {
      position: relative;
      display: flex;
      .html-tooltip{
        visibility: hidden;
      }
      &:hover {
        cursor: pointer;
        fill: #FFF;
        .html-tooltip{
          opacity: 1;
          visibility: visible;
          min-width: 120px;
          top: 19px;
          left: 9px;
        }
      }
    .info-icon {
      
      fill: ${iconColor};
      position: relative;
      &:hover {
      cursor: pointer;
      fill: #FFF;
    }
    
  }
    }
    & > header {
      background: #2E2F4B;
      border-radius: 15px 15px 0 0;
      padding: 10px;
      display: flex;

      & > svg {
        margin: 0 5px;
      }

      & aside {
        margin-left: auto;
        margin-right: 5px;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      &.loading {
        height: 100%;
      }
    }
`;
