import styled from "styled-components";

export const ScenarioBuilderStyledMain = styled.main`
  margin: 50px 20px;
  background: #1A273F 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #000000;
  border-radius: 0px 26px 0px 0px;

  & > section {
    overflow: auto;
    padding: 35px;
    padding-top: 0;
    position: relative;
    height: 70%;
    max-height: 70%;

    direction: rtl;

    & > section {
      direction: ltr;
    }

    &::-webkit-scrollbar {
      width: 15px;              
    } 
  
    &::-webkit-scrollbar-track {
      background: #424252;        
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #777782;    
      border: 1px solid #424252; 
    }
  }

`