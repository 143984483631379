import { Menu, MenuButton, MenuItem, SubMenu } from '@szhsin/react-menu';
import React, { useEffect, useState } from 'react';
import { menuItemSelector, menuSelector } from '@szhsin/react-menu/style-utils';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IGetWidgetsResponse } from '../../queries/useGetWidgetsQuery';
import styled from 'styled-components';

export interface IFilterByTopicProps {
  className?: string;
  widgets?: IGetWidgetsResponse[],
  onChange?: (topic: { ecoTopicName: string, ecoTopicId: number } | undefined) => void
}

const StyledMenu = styled(Menu)`
 ${menuSelector.name} {
    font-size: 1.2rem;
    background: #3D3E5A;
    border: 1px solid #212239;
    color: #E2E2E2;
    border-radius: 21px;
    box-shadow: inset 0px 0px 9px #000000D9;
    padding: 15px 9px;
    

    ${menuItemSelector.hover} {
      background: #5e5f76;
    }
  }
`;

const StyledMenuButton = styled(MenuButton)`
    background: #3D3E5A 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #0000006e;
    border-radius: 35px;
    margin-left: 5px;
    height: auto;
    padding: 10px 15px;
    border: none;
`;

const StyledMenuButtonContainer = styled.div`
 display: flex;
  align-items: center;
  justify-content: center;

  background: #31334E 0% 0% no-repeat padding-box;
  box-shadow: inset 2px 2px 3px #000000d9;
  border-radius: 21px;
  font-size: 1.2rem;
  color: #918F8F;
  padding: 5px 10px;
  cursor: pointer;

  & > span {
    white-space: nowrap;
  }
`

export const FilterByTopic = ({ className, widgets, onChange }: IFilterByTopicProps) => {
  const [topics, setTopics] = useState<{ ecoTopicName: string, ecoTopicId: number }[]>();
  const [topic, setTopic] = useState<{ ecoTopicName: string, ecoTopicId: number }>();

  useEffect(() => {
    if (!topics) {
      return;
    }

    setTopic(topics[0]);
  }, [topics])

  useEffect(() => onChange && onChange(topic), [topic])

  useEffect(() => {
    if (!widgets) {
      return;
    }

    const filteredTopics = widgets
      .map(w => ({
        ecoTopicName: w.eco_topic_name,
        ecoTopicId: w.eco_topic_id
      }))
      .filter((value, index, self) => index === self
        .findIndex((t) => t.ecoTopicId === value.ecoTopicId && t.ecoTopicName === value.ecoTopicName))
      .sort((a, b) => a.ecoTopicId - b.ecoTopicId);


    setTopics(filteredTopics);
  }, [widgets])

  return <section className={className}>
    <StyledMenu menuButton={
      <StyledMenuButton>
        <StyledMenuButtonContainer>
          <span>
            {topic?.ecoTopicName || '-- topic --'}
          </span>
          <ArrowDropDownIcon />
        </StyledMenuButtonContainer>
      </StyledMenuButton>
    }
    >
      {topics?.map(({ ecoTopicName, ecoTopicId }) => <MenuItem
        key={ecoTopicId}
        onClick={() => setTopic({ ecoTopicName, ecoTopicId })}
      >
        {ecoTopicName}
      </MenuItem>)}
    </StyledMenu>
  </section>
};

export default styled(FilterByTopic)`
`;
