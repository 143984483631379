import * as fromActions from "./growthSimulator.actions";

import {
  ApplicationType,
  BusinessType,
  RevenueModelType,
} from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorSteps/GrowthSimulatorSteps";
import {
  EGoalType,
  EPointOfViewType,
  Genre,
  IGrowthSimulatorState,
  initialState,
} from "./growthSimulator.state";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { GrowthSimulatorDetailsSegmentTableType } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorDetailsSegmentTable/GrowthSimulatorDetailsSegmentTable";
import { IGrowthSimulatorRetentionNumbersForm } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorRetentionNumbers/GrowthSimulatorRetentionNumbers";
import { value } from "./../../helpers/formatters";

export const growthSimulatorSlice = createSlice({
  name: "[growth-simulator]",
  initialState,
  reducers: {
    setPointOfViewType: (
      state: IGrowthSimulatorState,
      { payload: { pov } }: PayloadAction<{ pov: EPointOfViewType }>
    ) => {
      state.pov = pov;
    },
    setActiveTab: ( state: IGrowthSimulatorState,
      { payload: { activeTab } }: PayloadAction<{ activeTab: string }>
    ) => {
      state.activeTab = activeTab;
    },
    setGoalType: (
      state: IGrowthSimulatorState,
      { payload: { goal } }: PayloadAction<{ goal: EGoalType }>
    ) => {
      state.goal = goal;
    },
    setRetentionNumbersForm: (
      state: IGrowthSimulatorState,
      {
        payload: { form },
      }: PayloadAction<{ form: IGrowthSimulatorRetentionNumbersForm[] }>
    ) => {
      state.growthSimulatorRetentionNumbers = form;
    },
    resetGrowthSimulator: () => initialState,
    setARPPUDays: (
      state: IGrowthSimulatorState,
      { payload: { days } }: PayloadAction<{ days: number }>
    ) => {
      state.arppuTable.days = days;
    },
    addARPPUValues: (
      state: IGrowthSimulatorState,
      {
        payload: { value, scenarioName },
      }: PayloadAction<{
        value: GrowthSimulatorDetailsSegmentTableType[];
        scenarioName: string;
      }>
    ) => {
      state.arppuTable.values[scenarioName] = value;
    },
    addARPPUWeightedAverage: (
      state: IGrowthSimulatorState,
      {
        payload: { value, scenarioName },
      }: PayloadAction<{ value: number; scenarioName: string }>
    ) => {
      state.arppuTable.weightedAverages[scenarioName] = value;
    },
    setBusinessType: (
      state: IGrowthSimulatorState,
      {
        payload: { businessType },
      }: PayloadAction<{ businessType: BusinessType }>
    ) => {
      state.business = businessType;
    },
    setApplicationType: (
      state: IGrowthSimulatorState,
      {
        payload: { applicationType },
      }: PayloadAction<{ applicationType: ApplicationType }>
    ) => {
      state.application = applicationType;
    },
    setRevenueModelType: (
      state: IGrowthSimulatorState,
      {
        payload: { revenueModelType },
      }: PayloadAction<{ revenueModelType: RevenueModelType }>
    ) => {
      state.revenue = revenueModelType;
    },
    setBulbState: (
      state: IGrowthSimulatorState,
      {
        payload
      }: PayloadAction<boolean>
    ) => {
      state.bulbState = payload;
    },
    resetGrowthSimulation: ( state: IGrowthSimulatorState) => {
      state.growthSimulation = null;
      delete state.growthSimulationByScenarioId[state?.activeTab!];
    },
    setGenre: (
      state: IGrowthSimulatorState,
      {
        payload
      }: PayloadAction<Genre>
    ) => {
      state.genre = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fromActions.registerUserBasic.fulfilled,
      (state: IGrowthSimulatorState, { payload }) => {
        state.loading.registerUserBasic = false;
      }
    );
    builder.addCase(
      fromActions.registerUserBasic.pending,
      (state: IGrowthSimulatorState) => {
        state.loading.registerUserBasic = true;

        delete state.error.registerUserBasic;
      }
    );
    builder.addCase(
      fromActions.registerUserBasic.rejected,
      (state: IGrowthSimulatorState, { error }) => {
        state.loading.registerUserBasic = false;
        state.error.registerUserBasic = error;
      }
    );

    builder.addCase(
      fromActions.executeGrowthSimulationSaaS.fulfilled,
      (state: IGrowthSimulatorState, { payload }) => {
        state.loading.executeGrowthSimulationSaaS = false;

        const { simulation_config } = payload;

        state.growthSimulationByScenarioId[simulation_config.id] = payload;
      }
    );
    builder.addCase(
      fromActions.executeGrowthSimulationSaaS.pending,
      (state: IGrowthSimulatorState) => {
        state.loading.executeGrowthSimulationSaaS = true;

        delete state.error.executeGrowthSimulationSaaS;
      }
    );
    builder.addCase(
      fromActions.executeGrowthSimulationSaaS.rejected,
      (state: IGrowthSimulatorState, { error }) => {
        state.loading.executeGrowthSimulationSaaS = false;
        state.error.executeGrowthSimulationSaaS = error;
      }
    );

    builder.addCase(
      fromActions.getGrowthSimulatorWidgets.fulfilled,
      (state: IGrowthSimulatorState, { payload }) => {
        state.loading.getGrowthSimulatorWidgets = false;
        state.widgets = payload;
      }
    );
    builder.addCase(
      fromActions.getGrowthSimulatorWidgets.pending,
      (state: IGrowthSimulatorState) => {
        state.loading.getGrowthSimulatorWidgets = true;

        delete state.error.getGrowthSimulatorWidgets;

        state.widgets = [];
      }
    );
    builder.addCase(
      fromActions.getGrowthSimulatorWidgets.rejected,
      (state: IGrowthSimulatorState, { error }) => {
        state.loading.getGrowthSimulatorWidgets = false;
        state.error.getGrowthSimulatorWidgets = error;
      }
    );

    builder.addCase(
      fromActions.executeGrowthSimulation.fulfilled,
      (state: IGrowthSimulatorState, { payload }) => {
        state.loading.executeGrowthSimulation = false;
        state.growthSimulation = payload;

        const { simulation_config } = payload;

        state.growthSimulationByScenarioId[simulation_config.id] = payload;
      }
    );
    builder.addCase(
      fromActions.executeGrowthSimulation.pending,
      (state: IGrowthSimulatorState) => {
        state.loading.executeGrowthSimulation = true;

        delete state.error.executeGrowthSimulation;

        state.growthSimulation = null;
      }
    );
    builder.addCase(
      fromActions.executeGrowthSimulation.rejected,
      (state: IGrowthSimulatorState, { error }) => {
        state.loading.executeGrowthSimulation = false;
        state.error.executeGrowthSimulation = error;
      }
    );

    builder.addCase(
      fromActions.getGrowthSimulatorScenarios.fulfilled,
      (state: IGrowthSimulatorState, { payload }) => {
        state.loading.getGrowthSimulatorScenarios = false;
        state.growthSimulatorScenarios = payload;
      }
    );
    builder.addCase(
      fromActions.getGrowthSimulatorScenarios.pending,
      (state: IGrowthSimulatorState) => {
        state.loading.getGrowthSimulatorScenarios = true;

        delete state.error.getGrowthSimulatorScenarios;

        state.growthSimulatorScenarios = [];
      }
    );
    builder.addCase(
      fromActions.getGrowthSimulatorScenarios.rejected,
      (state: IGrowthSimulatorState, { error }) => {
        state.loading.getGrowthSimulatorScenarios = false;
        state.error.getGrowthSimulatorScenarios = error;
      }
    );

    builder.addCase(
      fromActions.saveGrowthSimulatorScenario.fulfilled,
      (state: IGrowthSimulatorState, { payload }) => {
        state.loading.saveGrowthSimulatorScenario = false;
        state.scenario = payload ||null;
      }
    );
    builder.addCase(
      fromActions.saveGrowthSimulatorScenario.pending,
      (state: IGrowthSimulatorState) => {
        state.loading.saveGrowthSimulatorScenario = true;

        delete state.error.saveGrowthSimulatorScenario;

        state.scenario = null;
      }
    );
    builder.addCase(
      fromActions.saveGrowthSimulatorScenario.rejected,
      (state: IGrowthSimulatorState, { error }) => {
        state.loading.saveGrowthSimulatorScenario = false;
        state.error.saveGrowthSimulatorScenario = error;
      }
    );
    builder.addCase(
      fromActions.getArppu.fulfilled,
      (state: IGrowthSimulatorState, { payload }) => {
        state.arppuTable.arppu = payload.apds;
      }
    );
  },
});

export default growthSimulatorSlice.reducer;
