import { SerializedError } from "@reduxjs/toolkit";

export interface ISetting {
    title_id: number;
    parameter_name: string;
    parameter_value: string;
    parameter_grp: number;
    parameter_raw_value: string;
    parameter_type: number;
  }
  
  export interface ISettingsSlice {
    settings?: ISetting[];
    loading: {
      getSettings?: boolean;
      updateSettings?: boolean;
    };
    error: {
      getSettings?: SerializedError;
      updateSettings?: SerializedError;
    };
  }
  
  export const initialState: ISettingsSlice = {
    error: {},
    loading: {},
  };