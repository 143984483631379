import { DataTable, IChartDataAdapter, IChartLegend } from "../models";

import { CurrencyCode } from "../../../helpers/formatters";
import { IGetReportDataResponse } from "../../../queries/useGetReportDataQuery";
import { IGetWidgetsResponse } from "../../../queries/useGetWidgetsQuery";
import { SERIES_COLORS } from "../../../helpers/colors";
import { customHTML } from "../StackedLineChart/StackedLineChart";
import { groupBy } from "../../../helpers/extensions";

export class PieChartDataAdapter implements IChartDataAdapter {
  private _widget?: IGetWidgetsResponse;
  private _currencyCode?: CurrencyCode;

  public initialize(widget: IGetWidgetsResponse, currencyCode: CurrencyCode): IChartDataAdapter {
    this._widget = widget;
    this._currencyCode = currencyCode;

    return this;
  }

  public adapt(
    report: IGetReportDataResponse[],
    filter?: Partial<IChartLegend>[]
  ): DataTable {
    if (!report || !this._widget) {
      return new DataTable();
    }

    const series = this._widget?.series.toLowerCase();
    const yAxis = this._widget?.y_axis.toLowerCase();

    const dataTable = new DataTable();

    const seriesValues = report
      .map((r) => r[series as keyof typeof r])
      .filter((x, i, a) => a.indexOf(x) === i);

    const reportBySeries = groupBy(
      report,
      (item) => item[series as keyof typeof item] as string
    );
    
    dataTable
      .addColumn({ type: "string" })
      .addColumn({ type: "number" })
      .addColumn({ type: 'string', role: 'tooltip', p: {html: true}});

    seriesValues.forEach((section, index) => {
      if (filter?.find((f) => f.value == section)) {
        return undefined;
      }

      const key = section as keyof typeof reportBySeries;

      dataTable.addRow([
        { v: section },
        {
          v: reportBySeries[key][0][
            yAxis as keyof IGetReportDataResponse
          ],
          f: reportBySeries[key][0][
            yAxis as keyof IGetReportDataResponse
          ]?.toString(),
        },
        {
          v: (this._widget && this._currencyCode)
            && customHTML(section?.toString(), reportBySeries[section as keyof typeof reportBySeries][0][
              yAxis as keyof IGetReportDataResponse
            ],  reportBySeries[section as keyof typeof reportBySeries][0][
              yAxis as keyof IGetReportDataResponse
            ], this._widget?.y_axis_format, this._currencyCode)
        }
      ]);
    });

    const legend: IChartLegend[] = seriesValues.map((value, index) => ({
      background: SERIES_COLORS[index],
      value: value?.toString(),
    })) as IChartLegend[];

    dataTable.setLegend(legend);

    return dataTable;
  }
}
