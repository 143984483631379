import FilterBar, { IFilterBarPayload } from '../FilterBar/FilterBar';
import { IGetReportDataPayload, useGetReportDataQuery } from '../../queries/useGetReportDataQuery';
import { useEffect, useState } from 'react';

import AcqusitionCell from './AcqusitionCell';
import { GetUserAcquisitionReportDataResponse } from '../../queries/useGetUserAcquisitionReportDataQuery';
import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import { LinearProgress } from '@mui/material';
import SettingsDropDown from '../Dropdown/SettingsDropDown';
import SortByHeader from './SortByHeader';
import { Tooltips } from '../../pages/UserAcqusition/UserAcqusition';
import cn from 'classnames';
import { firstCharUpperCase } from '../../helpers/formatters';
import { getReportData } from '../../queries/useGetReportDataQuery';
import styled from 'styled-components';
import { useApplicationContext } from '../../Context';
import { v4 as uuidv4 } from 'uuid';

export type NoFiltersPayload = Omit<IGetReportDataPayload, 'country' | 'group_by' | 'platform' |  'source' | 'days_back' | 'date_from' | 'date_to'>;


export interface IUserAcquisitionTable {
  className?: string;
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
  additionalTooltips?: Tooltips
}

const initialPayload = {
      report_id: 146,
      dashboard_id: 9,
      title_id: 88,
      days_back: 90,
      date_from: '-1',
      date_to: '-1',
      topic_id: 10,
      report_location: 10
}

const UserAcquisitionTable = ({ className, title, page, additionalTooltips }: IUserAcquisitionTable) => {
  const [ payload, setPayload ] = useState<IGetReportDataPayload>(initialPayload);
  const [ data, setData ] = useState<GetUserAcquisitionReportDataResponse[] | undefined>(undefined);
  const [{ title: contextTitle } ] = useApplicationContext();
  const getReportDataQuery = useGetReportDataQuery({
    ...payload,
    title_id: contextTitle?.id
  }, `UserAcquisitionTable-${contextTitle?.id}-${Object.keys(payload).map(x => payload[x as keyof typeof payload]).join('-')}`)

  const [sortBy, setSortBy] = useState<{ key: keyof GetUserAcquisitionReportDataResponse, direction: 'ascending' | 'descending' } | undefined>({key: 'arppu', direction: 'ascending'});

  useEffect(() => {
    if(getReportDataQuery?.isLoading) {
      return;
    }

    const {data} = getReportDataQuery;

    if(!data) {
      return;
    }

    setData(data);
  }, [getReportDataQuery])

  useEffect(() => {
    if(data && sortBy) {
      setData((prevData) => {
        return prevData?.sort((a, b) => {
          if (sortBy.direction === 'descending') {
            if (typeof a[sortBy.key] === 'string') {
              return String(a[sortBy.key]).localeCompare(String(b[sortBy.key]))
            } else {
              return Number(a[sortBy.key]) - Number(b[sortBy.key]);
            }
          } else {
            if (typeof a[sortBy.key] === 'string') {
              return String(b[sortBy.key]).localeCompare(String(a[sortBy.key]));
            } else {
              return Number(b[sortBy.key]) - Number(a[sortBy.key]);
            }
          }
        })
      });
    }
  }, [sortBy]);

  
  const includeArray = ["report_id","dashboard_id", "title_id", " topic_id", "report_location"]
  const handleFilterBar = (payloadFromFilters: IFilterBarPayload) => {
    
    setPayload((prev) => {
      let newResult = {};
      Object.keys(prev).forEach(key => {
        if(includeArray.includes(key)){
          newResult = {
            ...newResult,
            [key]: prev[key as keyof NoFiltersPayload]
          }
        }
        
      })

      const filterToPayload = {
        country: payloadFromFilters?.country,
        group_by: payloadFromFilters?.groupBy,
        platform: payloadFromFilters?.platform,
        source: payloadFromFilters?.source,
        days_back: payloadFromFilters?.daysBack,
        date_from: payloadFromFilters?.dateFrom,
        date_to: payloadFromFilters?.dateTo
      } as any;

      Object.keys(filterToPayload).forEach(k => filterToPayload[k] == undefined && delete filterToPayload[k]);

      return { ...newResult as NoFiltersPayload, ...filterToPayload} as IGetReportDataPayload;
    })
  }
  const lowerCaseKeys = additionalTooltips && Object.fromEntries(Object.entries(additionalTooltips).map(([k, v]) => [k.toLowerCase().split(/[ _]/).join('_'), v]))

  return data ? (<>
  <FilterBar onChange={handleFilterBar} showGroupBy titleId={title?.id}/>
  <HorizontalScrollWrapper>

    {data && data.length > 0 && (<section className={cn('user-acquisition-table', className)}>
      < StyledCohortsTableHeader>
        <div className="SettingsDropDown-wrapper ">
          <SettingsDropDown exportData={data} headline={"User Acquisition Analysis"}/>
        </div>
        <ul>
          <li>
            <StyledCohortsTableItemHeaderContainer style={{gridTemplateColumns: `repeat(${Object.keys(data?.[0] as GetUserAcquisitionReportDataResponse).length}, 1fr)`}}>
              { Object.keys(data?.[0] as GetUserAcquisitionReportDataResponse).map((header, index) => {
                return <SortByHeader key={`acq-header${index}`} header={header.split(/[_| ]/g).map(string => firstCharUpperCase(string)).join(' ')} 
                        onSort={(direction) => setSortBy({ key: header as keyof GetUserAcquisitionReportDataResponse, direction })}
                        tooltip={lowerCaseKeys?.[header]}/>
              })}
            </StyledCohortsTableItemHeaderContainer>
          </li>
        </ul>
      </StyledCohortsTableHeader>
      <StyledCohortsContent>
        <ul>
          {data?.map((d, index) => <StyledCohortsTableItem cohort={d} key={index} />)}
        </ul>
      </StyledCohortsContent>
      </section>)}
  </HorizontalScrollWrapper>
  </>) : null;
  
}

export default styled(UserAcquisitionTable)`
display: flex;
flex-direction: column;
overflow-y: hidden;
margin-top: 30px; 
margin-bottom: 20px;
overflow: auto;
background-color: #212134;
&::-webkit-scrollbar {
  width: 15px;              
}

&::-webkit-scrollbar-track {
  background: #424252;        
}

&::-webkit-scrollbar-thumb {
  background-color: #777782;    
  border: 1px solid #424252; 
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

& > ul {
  display: flex;
  flex-direction: column;
  padding: 10px;
}`;

const HorizontalScrollWrapper = styled.div`
  // height: calc(100% - 38px);
  display: flex;
  overflow: hidden;
`
const StyledCohortsTableHeader = styled.header`
  padding: 10px 30px 10px 20px;
  background: transparent linear-gradient(180deg, #212134 0%, #2C2C41 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000042;
  min-width: 1808px;
  display: flex;
  /* margin-left: 20px; */
  .SettingsDropDown-wrapper {
    display: flex;
    width: 46px !important;
    box-sizing: border-box;
    .szh-menu-button {
      width: 65px;
      /* justify-content: center; */
      padding-left: 0px;
      align-items: center;
    }
  }
`

const StyledCohortsContent = styled.main`
    overflow: auto;
    overflow-y: scroll;
    direction: rtl;
    height: calc(100% - 105px);
    margin: 10px 0 10px 20px;
    padding: 0 30px;
    min-width: 1780px;
    & > ul {
      direction: ltr;

      & > li:first-of-type {
        margin-top: 0;
      }
    }

    &::-webkit-scrollbar {
      width: 15px;              
    }

    &::-webkit-scrollbar-track {
      background: #424252;        
    }

    &::-webkit-scrollbar-thumb {
      background-color: #777782;    
      border: 1px solid #424252; 
    }
`
const StyledCohortsTableItemHeaderContainer = styled.ul`
display: grid;
grid-template-columns: repeat(16, minmax(70px, 1fr));
grid-column-gap: 10px;

& > li {
  background: #404052;
  text-align: center;
  position: relative;

display: flex;
align-items: center;
justify-content: center;

font-size: 1.2rem;
padding: 10px 0;
color: #E7E7E7;
}
`

const CohortsTableItem = ({ cohort, className }: { cohort: GetUserAcquisitionReportDataResponse, className?: string }) => {
  return <li className={cn('cohorts-table-item', className)}>
    <ul style={{gridTemplateColumns: `repeat(${Object.keys(cohort as GetUserAcquisitionReportDataResponse).length}, 1fr)`}}>
      {cohort && Object.keys(cohort).map((item) => {
        return <AcqusitionCell item={cohort[item as keyof GetUserAcquisitionReportDataResponse]} key={uuidv4()}/>
      })}
    </ul>
  </li>
};

const StyledCohortsTableItem = styled(CohortsTableItem)`
  margin: 10px 0;

  & > ul {
    display: grid;
    grid-column-gap: 10px;
    
    & > div {
      background: #2C2C3E;
      text-align: center;
      display: grid;
      /* display: flex; */
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      /* padding: 10px 0; */
      color: #E7E7E7;

      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7) {
        b {
          border-right: 1px solid #A8A8A8;
          padding-right: 10px;
          margin-right: 5px;
        }
      }


      &:nth-of-type(1) {
        p {
          background: #30314680 0% 0% no-repeat padding-box;
          border: 1px solid #939393;
          border-radius: 50%;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;

          & > span {
            min-width: 1rem;
            max-width: 1rem;
            color: #25273D;
            font-weight: bold;
            font-weight: bold;
            border-radius: 50%;
            font-size: 1rem;
            padding: 0.2rem 0.4rem;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
`