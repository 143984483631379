import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {ReactComponent as CurrencyIcon} from '../../assets/coins-solid.svg';
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sharedSelector } from '../../store/shared/shared.selector';
import { StyledMenu } from './Dropdown';
import styled from 'styled-components'
import { StyledMenuButon } from './SettingsDropDown';

const CurrencyDropdown = ({ onCurrencyChange = () => {} }: { onCurrencyChange?: (item:string) => void }) => {
  const currency = useSelector(sharedSelector);
  useEffect(() => {
    if(currency) {
      const initialItem = currency.currency[0].name;
      onCurrencyChange(initialItem)
    }
  }, [currency]);

  return (
  <StyledMenu menuButton={<StyledMenuButon><StyledCurrencyIcon /></StyledMenuButon>} transition >
    {currency && currency.currency.map((items, index) => {
      return <MenuItem key={index} onClick={() => onCurrencyChange(items.name)}>{ items.name }</MenuItem>
    })}
  </StyledMenu>)
}

export default CurrencyDropdown;

const StyledCurrencyIcon = styled(CurrencyIcon)`
  fill: hsla(360, 100%, 100%, 0.47);
  width: 1.1rem;
  height: 1.1rem;
  &:hover { 
    fill: hsla(360, 100%, 100%, 1);
    cursor: pointer;
  }
`
