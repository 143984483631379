import { AnimatePresence, motion } from "framer-motion";
import { ChangeEvent, useEffect, useState } from "react";
import { EAddType, INode } from "../models/models";
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { SimpoolPrimaryButton, SimpoolWarnButton } from '../../../components/Buttons/Buttons';
import SpreadSheetWrapper, { EconomyObjectChangesTemp } from "./SpreadSheetWrapper/SpreadSheetWrapper";
import { compress, decompress } from 'compress-json'
import { configurationObjectsByPathSelector, configurationObjectsSelector, scenarioTabNodesSelector } from '../../../store/scenarioBuilder/scenarioBuilder.selector';
import { useDispatch, useSelector } from "react-redux";

import AddIcon from '@mui/icons-material/Add';
import { AppDispatch } from '../../../store/app.state';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { IScenarioBuilderConfiguration } from "../../../store/scenarioBuilder/scenarioBuilder.state";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SimpoolDialog from '../../../components/Dialog/Dialog';
import config from '../../../config';
import debounce from 'lodash.debounce';
import { getConfigurationObjectsByPath } from "../../../store/scenarioBuilder/scenarioBuilder.actions";
import { sharedTitleSelector } from "../../../store/shared/shared.selector";
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';

const StyledSelect = styled.select`
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
    width: 150px;
    text-align: left;
`

const StyledSpreadSheetContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ObjectContainer = styled.div`
    background: #364257 0% 0% no-repeat padding-box;
    box-shadow: inset 2px 2px 4px #000000;
    border-radius: 30px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > strong {
      margin: 0px 15px;
    }
`

const DuplicateAction = styled.div`
  background: #374763 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #000000B3;
  border-radius: 27px;
  padding: 10px 30px;
  padding-left: 55px;
  height: 25px;
  z-index: 30;
  position: relative;
  cursor: pointer;
`

const DeleteAction = styled.div`
  background: #374763 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #000000B3;
  border-radius: 27px;
  padding: 10px 30px;
  height: 25px;
  padding-left: 55px;
  z-index: 20;
  left: -40px;
  position: relative;
  cursor: pointer;
`

const StyledMenuButton = styled(MenuButton)`
    background: #5C616A;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -15px;
    left: -15px;
`

const WhatIfActions = styled.section`
  display: flex;
  margin-top: 25px;
  color: #fff;
  
  & > div {
      background: #2E3D58 0% 0% no-repeat padding-box;
      box-shadow: 3px 3px 6px #000000b3;
      border-radius: 27px;
      padding: 10px 30px;
      margin-left: 20px;
      display: flex;
      z-index: 30;
      height: 25px;
  }

  & > aside {
    display: flex;
    align-items: center;
    justify-content: center;

    left: -40px;
    position: relative;
    z-index: 20;
  }
`

const StyledInput = styled.input`
    background: #fff;
    box-shadow: inset 2px 2px 3px #00000094;
    border-radius: 11px;
    outline: none;
    border: none;
    padding: 5px 10px;
    text-align: center;
    max-width: 90px;
    height: 15px;

    &:disabled {
        background: #2E3D58 ;
    }
`

const Trigger = () => {
    return <StyledMenuButton>
        <AddIcon />
    </StyledMenuButton>
}

const AddButton = ({ onClick, type, initial }: { onClick: (addType: EAddType) => void, type: EAddType, initial: boolean }) => {
    return <Menu menuButton={Trigger}>
        {
            initial ? <>
                <MenuItem onClick={() => onClick(EAddType.And)}>{EAddType.And}</MenuItem>
                <MenuItem onClick={() => onClick(EAddType.Or)}>{EAddType.Or}</MenuItem>
            </> : type === EAddType.Or ? <>
                <MenuItem onClick={() => onClick(EAddType.And)}>{EAddType.And}</MenuItem>
                <MenuItem onClick={() => onClick(EAddType.Or)}>{EAddType.Or}</MenuItem>
            </>
                : <MenuItem onClick={() => onClick(EAddType.And)}>{EAddType.And}</MenuItem>
        }
    </Menu>
}

export interface IWhatIfProps {
    className?: string;

    node: INode;
    configuration: IScenarioBuilderConfiguration;

    onAdd?: (type: EAddType, node: INode) => void;
    onDuplicate?: (node: INode) => void;
    onDelete?: (node: INode) => void;
    onChange?: (object: string, value: { [key: string]: string | number | string[] }[], changes?: { [key: string]: string | number | string[] }[], description?: string) => void;
    onDescriptionChange?: (text: string) => void;

}

export interface ICell {
    value: string;
}

export interface ICellDataEditorProps {
    row: number;
    column: number;
    cell: ICell;
    exitEditMode: () => void;
    onChange: (cell: ICell) => void;
}

export const DataEditor = (data?: string[]) => ({ onChange, cell }: ICellDataEditorProps) => {
    return <select onChange={({ target: { value } }) => onChange({ ...cell, value })} defaultValue={cell?.value}>
        <option disabled value="">-- Object --</option>
        {data && data?.length && data?.map((v: string) => <option key={uuidv4()} value={v}>{v}</option>)}
    </select>
}

const StyledFilterList = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

const StyledFilterListItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2B2D47 0% 0% no-repeat padding-box;
    box-shadow: inset 2px 2px 3px #000000d9;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 10px;
    gap: 10px;

    input {
        margin-bottom: 5px;
    }

`

function difference<T>(array1: T[], array2: T[]): T[] {
    return array1.filter(object1 => {
        return !array2?.some(object2 => {
            return JSON.stringify(object1) === JSON.stringify(object2);
        });
    });
}

function areEqual<T1, T2>(val1: T1, val2: T2): boolean {
    return JSON.stringify(val1) === JSON.stringify(val2);
}

type PropertyFilterType = { property: string, filter?: string };
type ColumnType = { value: string };
type ValueType = { [key: string]: string | number | string[] };


const WhatIf = ({ className, onAdd, node, onDelete, onDuplicate, onChange, configuration, onDescriptionChange }: IWhatIfProps) => {
    const [__filters, __setFilters] = useState<PropertyFilterType[]>([]);
    const [__changes, __setChanges] = useState<ValueType[]>([]);
    const [__value, __setValue] = useState<ValueType[]>(node?.value || []);
    const [__object, __setObject] = useState<string | undefined>(node.object);
    const [__data, __setData] = useState<{ value: (string | number); DataEditor?: any }[][]>([]);
    const [__pagination, __setPagination] = useState<number>(20);
    const dispatch = useDispatch<AppDispatch>();

    const [_timeout, _setTimeout] = useState<NodeJS.Timeout | undefined>();
    const [hovered, setHovered] = useState(false);
    const [openDialog, setOpenDialog] = useState<boolean | undefined>();

    const configurationObjects = useSelector(configurationObjectsSelector);
    const configurationObjectsByPath = useSelector(configurationObjectsByPathSelector);
    const title = useSelector(sharedTitleSelector);
    const [spreadsheetData, setSpreadSheetData] = useState<any | undefined>(undefined);
    const [changesObject, setChangesObject] = useState<EconomyObjectChangesTemp>({ changedValue: [], changes: [] });

    const currentNodeState = useSelector(scenarioTabNodesSelector())[node.parentTabId!];
    //Todo check why didn;t get obejct from store
    useEffect(() => {
        if (__object && configurationObjectsByPath) {
            const currentNodeInStore = currentNodeState?.find(n => n.id === node.id);
            const currentEconomyOnject = currentNodeInStore?.value;
            const currentKey = currentNodeInStore?.object;
            let initialValue = ((__object === currentKey) && currentEconomyOnject) ? currentEconomyOnject : configurationObjectsByPath[__object];

            setSpreadSheetData(initialValue)
        }
    }, [__object, configurationObjectsByPath]);



    useEffect(() => {
        const initialValue = configurationObjectsByPath?.[__object!];
        // For the merge
        if (!initialValue && __object && title) {
            dispatch(getConfigurationObjectsByPath({ titleId: title?.id!, path: __object! }))
        }
    }, [__object, title])

    useEffect(() => {
        if (__changes.length > 0) {
            onChange && onChange(__object!, __value, __changes);
        }
    }, [__changes])


    const closeDialog = () => {
        __setData([]);
        __setFilters([]);
        setChangesObject({ changedValue: [], changes: [] })
        setOpenDialog(false);
    }

    const SpreadSheetComponent = SpreadSheetWrapper;
    return <>
        <SimpoolDialog resizable width={600} height={600} open={Boolean(openDialog)} title={`WhatIf: ${__object}`} onClose={closeDialog}
            footer={
                <>
                    <SimpoolPrimaryButton onClick={() => {

                        const originalOrModifiedEconomyObject = changesObject.changedValue?.length > 0 ? changesObject.changedValue : spreadsheetData;

                        __setValue(originalOrModifiedEconomyObject);
                        __setChanges(changesObject.changes as ValueType[]);
                        setSpreadSheetData(originalOrModifiedEconomyObject);
                        if (changesObject.changes.length === 0) {
                            onChange && onChange(__object!, originalOrModifiedEconomyObject, changesObject.changes as {
                                [key: string]: string | number | string[];
                            }[] | undefined);
                        }
                        setTimeout(() => {
                            closeDialog();
                        }, 0);
                    }}>
                        Done
                    </SimpoolPrimaryButton>
                    <SimpoolWarnButton onClick={closeDialog}>
                        Cancel
                    </SimpoolWarnButton>
                </>
            }
            render={(shouldResize) => spreadsheetData && <SpreadSheetComponent data={spreadsheetData} shouldResize={shouldResize} onChange={(eo) => setChangesObject(eo)} economyObjectKey={__object} />}
        >
        </SimpoolDialog>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
            <section className={className} id={node?.id}>
                <ul>
                    <li>
                        <ObjectContainer>
                            <strong style={{ color: 'white' }}>What If:</strong>
                            <StyledSelect name="object" id="object" value={__object!} onChange={({ target: { value } }) => {
                                if (!value || value === '-- Object --') {
                                    return;
                                }

                                __setObject(value);

                                setOpenDialog(true);
                            }}>
                                <option value={undefined}>-- Object --</option>
                                {configurationObjects?.map((v: string) => <option key={v} value={v}>{v}</option>)}
                            </StyledSelect>
                        </ObjectContainer>
                    </li>
                    <li>
                        <span>
                            <DragHandleIcon />
                        </span>
                    </li>
                    <li>
                        <StyledInput disabled={!__object}
                            value={__changes?.length ? JSON.stringify(__changes) : ''}
                            onClick={() => {
                                setOpenDialog(true);
                            }} />
                    </li>
                </ul>
                <AddButton onClick={(type) => onAdd && onAdd(type, node)} type={node?.addType!} initial={node?.depth === 0} />
            </section >
            {node?.addType === EAddType?.Or
                && <StyledDescription>
                    <span className="description-title">Description</span>
                    <textarea name="" id="" cols={34} rows={4} maxLength={100} value={node.description}
                        onChange={(e) => {
                            const currentValue = e.target.value;
                            onDescriptionChange && onDescriptionChange(currentValue);

                        }}></textarea>
                    <span className="description-length">{node?.description?.length}/100</span>
                </StyledDescription>
            }
            {node?.depth !== 0 && <AnimatePresence>
                <WhatIfActions
                    onMouseEnter={() => {
                        clearTimeout(_timeout as any);
                        setHovered(true);
                    }}
                    onMouseLeave={() => {
                        _setTimeout(setTimeout(() => {
                            setHovered(false);
                        }, 1 * 1000))
                    }}
                >
                    <div style={{ cursor: 'pointer' }}>
                        <MoreHorizIcon />
                    </div>
                    {hovered && <motion.aside
                        transition={{ delay: 0.1 }}
                        initial={{ width: 0, overflow: 'hidden', opacity: 0 }}
                        animate={{ width: 'auto', opacity: 1 }}
                        exit={{ width: 0, overflow: 'hidden', opacity: 0 }}
                    >
                        <DuplicateAction onClick={() => onDuplicate && onDuplicate(node)}>
                            <span>Duplicate</span>
                            <span>({node?.addType})</span>
                        </DuplicateAction>
                        <DeleteAction onClick={() => onDelete && onDelete(node)}>
                            <DeleteIcon />
                        </DeleteAction>
                    </motion.aside>}
                </WhatIfActions>
            </AnimatePresence>}
        </div>
    </>
}

const StyledDescription = styled.section`
    margin-top: 30px;
    margin-left: 10px;
    position: relative;
    .description-title{
        position: absolute;
        top: -25px;
        color: white;
        left: 38%;
        font-size: 1.1rem;
    }
    textarea {
        border: none;
        padding: 15px 10px 10px 15px;
        line-height: 11px;
        color: white;
        height: 100%;
        border-radius: 40px;
        display: flex;
        resize: none;
        background: #3F495D 0% 0% no-repeat padding-box;
        box-shadow: inset 2px 2px 4px #000000;
        &:focus-visible{    
            outline: none;

        }
    }
    .description-length{
        color: white;
        position: absolute;
        font-size: 0.8rem;
        right: 15px;
        bottom: 5px;
    }
`;
const StyledWhatIf = styled(WhatIf)`
    & > ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      gap: 10px;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
  
        &:nth-of-type(2) {
          color: #fff;
        }
      }
    }
    .description{
        margin-top: 30px;
        height: 500px;
    }
  
    margin-left: ${({ node }) => (node?.addType === EAddType.Or ? 100 : (100 + node?.depth! * 100)) + 'px'};
    margin-top: 30px;
    background: #3F495D 0% 0% no-repeat padding-box;
    box-shadow: inset 2px 2px 4px #000000;
    border-radius: 40px;
    padding: 10px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;
  
  `

export default StyledWhatIf;
