import React, { useEffect, useMemo, useRef } from 'react';
import { arc, select } from "d3";

import UsersBadge from '../UsersBadge/UsersBadge';
import styled from 'styled-components';

export interface IGaugeProps {
  value: number;
  width: number;
  radius: number;
  size: number;
  container?: {
    height: number;
    width: number;
  };
  users?: number;
  arrowValue?: string | undefined;
  needleRadius?: number;
  needleFontSize?: number;
  headline?: string;
  style?: React.CSSProperties;
}

export interface IGaugeContainerProps {
  height: string;
}

const ARC_DEGREES = 180;
const START_ANGLE_DEGREES = -90;
const ARC_CHUNKS_PADDING = 2;

const deg2rad = (deg: number): number => {
  return deg * Math.PI / 180
}

const percToDeg = (perc: number): number => {
  return (perc / 100) * ARC_DEGREES;
};

const percToRad = (perc: number): number => {
  return deg2rad(percToDeg(perc));
};

const GaugeContainer = styled.section<{ height: string; width: string; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  
  background: linear-gradient(#4d4f67 0%, #10121e 100%);
  
  padding: 35px;
  
  height: ${props => props.height};
  width: ${props => props.width};
  
  border-radius: 50%;
  box-shadow: inset #000000 3px 3px 6px;

  margin-bottom: 20px;
  position: relative;
`;

const GaugeInnerContainer = styled.div<{ height: string; width: string; }>`
  background: linear-gradient(#444666 0%, #272d44 100%);
  box-shadow: inset #000000 3px 3px 6px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  padding: 10px;
  
  border-radius: 50%;
  
  height: ${props => props.height};
  width: ${props => props.width};
`

const StyledText = styled.p`
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
  color: #e7e7e7;
  text-shadow: 2px 2px 4px #000000;
  font-size: 1rem;
  opacity: 0.8;
  margin: 0;
  display: flex;
  width: 50%;
  text-align: center;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const StyledUsersContainer = styled.footer`
  position: absolute;
  bottom: -20px;

  background: transparent linear-gradient(107deg, #383A52 0%, #1C1D30 100%) 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000040;
    border-radius: 35px;
    opacity: 1;
    padding: 0px 15px;

  div {
    background: transparent linear-gradient(101deg, #30354E 0%, #414763 52%, #30354E 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 1px 1px 1px #000000e3, 3px 3px 6px #00000040;
    border-radius: 25px;

    p {
      padding: 0 10px 0 15px;
    }
  }
`

//INFO(PPavlov): https://codesandbox.io/s/react-d3-gauge-gwebx?file=/src/components/Gauge/components/GaugeArc/GaugeArc.js
//INFO(PPavlov): https://jsfiddle.net/t019mo73/
const Gauge = ({ value, width, radius, size, container, users, arrowValue = undefined, needleRadius, needleFontSize, headline, style }: IGaugeProps) => {
  const NEEDLE_RADIUS = needleRadius ? needleRadius : 15;

  const element = useRef<HTMLDivElement | null>(null);

  const sections = [
    "#FF0000",
    "#FF2300",
    "#FF3C00",
    "#FF5300",
    "#FF6F00",
    "#FF8800",
    "#FFA000",
    "#FFBC00",
    "#FFCC00",
    "#FFE600",
    "#FFF700",
    "#F7FF00",
    "#E6FF00",
    "#D3FF00",
    "#BBFF00",
    "#AAFF00",
    "#88FF00",
    "#66FF00",
    "#09FF00",
  ]

  useEffect(() => {
    render();
  }, [value])

  const height = width / 2;

  const renderSection = (outerRadius: number, innerRadius: number, gauge: any) => {
    let arcStart = START_ANGLE_DEGREES;
    sections.map((section, sectionIndex) => {
      let index = sectionIndex + 1;

      let totalPadding = (ARC_CHUNKS_PADDING * (sections.length - 1));

      let arcEnd = arcStart + ((ARC_DEGREES - totalPadding) / sections.length);

      let arcGenerator: any = arc()
        .outerRadius(outerRadius)
        .innerRadius(innerRadius)
        .startAngle(deg2rad(arcStart))
        .endAngle(deg2rad(arcEnd));

      gauge.append("path")
        .attr("d", arcGenerator())
        .attr("id", "gauge-path-" + index)
        .attr('class', "gauge-section-" + index)
        .attr("fill", section);

      arcStart = arcEnd + ARC_CHUNKS_PADDING;
    });
  }

  const calcPointerPosition = (perc: number): string => {
    var leftX, leftY, rightX, rightY, topX, topY;

    const thetaRad = percToRad(perc);

    const centerX = 0;
    const centerY = 0;

    topX = centerX - (width / 5) * Math.cos(thetaRad);
    topY = centerY - (width / 5) * Math.sin(thetaRad);

    leftX = centerX - (NEEDLE_RADIUS / 2) * Math.cos(thetaRad - Math.PI / 2);
    leftY = centerY - (NEEDLE_RADIUS / 2) * Math.sin(thetaRad - Math.PI / 2);

    rightX = centerX - (NEEDLE_RADIUS / 2) * Math.cos(thetaRad + Math.PI / 2);
    rightY = centerY - (NEEDLE_RADIUS / 2) * Math.sin(thetaRad + Math.PI / 2);
    return "M " + leftX + " " + leftY + " L " + topX + " " + topY + " L " + rightX + " " + rightY;
  };

  const renderNeedle = (gauge: any, value: number) => {
    const valueToDegrees = percToDeg(value);
    const colorIndex = Math.floor(valueToDegrees / (ARC_DEGREES / (sections.length -1)));
    const color = sections[colorIndex];

    gauge.append("circle")
      .attr("class", "gauge-needle-center")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", NEEDLE_RADIUS)
      .attr("fill", color);

    gauge.append("path")
      .attr("fill", color)
      .attr('d', calcPointerPosition.bind(this, value));

    gauge
      .append('text')
      .attr('dy', 5)
      .attr('width', 20)
      .attr('style', `
        color: #303146;
        font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
        font-weight: bold;
        font-size: ${needleFontSize ? needleFontSize.toString() : '1'}rem;
        text-anchor: middle;
      `)
      .text(arrowValue ? arrowValue : value)
  }

  const render = () => {
    if (!element || !element.current) {
      return;
    }

    while (element.current.firstChild) {
      element.current.removeChild(element.current.firstChild);
    }

    const startAngle = deg2rad(-90);
    const endAngle = deg2rad(90);

    const innerRadius = radius - size;
    const outerRadius = radius;

    let svg = select(element.current).append("svg")
      .attr("width", `${width}px`)
      .attr("height", `${height + NEEDLE_RADIUS}px`);

    let gauge = svg.append("g")
      .attr("class", "gauge")
      .style("transform", `translate(${width / 2}px, ${width / 2}px`);

    let defaultArc = arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(startAngle)
      .endAngle(endAngle) as any;

    gauge.append("path")
      .attr("class", "gauge-background")
      .attr("fill", "transparent")
      .attr("d", defaultArc);

    renderSection(outerRadius, innerRadius, gauge);

    renderNeedle(gauge, value);
  }

  return <GaugeContainer
    height={`${(container?.height || height * 2)}px`}
    width={`${(container?.width || height * 2)}px`}
    style={style}
  >
    <GaugeInnerContainer
      height={`${height * 2}px`}
      width={`${height * 2}px`}
      
    >
      <div ref={element}></div>
      <StyledText>
        {headline ? headline : 'Simpool Score'}
      </StyledText>
    </GaugeInnerContainer>
    { users && 
    <StyledUsersContainer>
      <div>
        <UsersBadge users={users!}/>
      </div>
    </StyledUsersContainer> }
  </GaugeContainer>
};

export default React.memo(Gauge);
