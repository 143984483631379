import { AxiosInstance, AxiosResponse } from "axios";
import {
  GrowthSimulationResponse,
  GrowthSimulationSaaSResponse,
  IExecuteGrowthSimulationInput,
  IExecuteGrowthSimulationSaaSInput,
  IGrowthSimulatorScenario,
  IGrowthSimulatorScenarioInput,
  IGrowthSimulatorWidget,
  IUserBasicResponse,
} from "../models/growthSimulator.models";

import { EGoalType, Genre } from "../../../store/growthSimulator/growthSimulator.state";
import { IGrowthSimulatorRetentionNumbersForm } from "../components/GrowthSimulatorRetentionNumbers/GrowthSimulatorRetentionNumbers";

export class GrowthSimulatorV2Service {
  public static axios: AxiosInstance;
  public static axiosAuthorized: AxiosInstance;

  public static async registerUserBasic({
    name,
    email,
  }: {
    name?: string;
    email: string;
  }): Promise<IUserBasicResponse> {
    const { data } = await GrowthSimulatorV2Service.axios.post<
      { user?: string; email: string },
      AxiosResponse<IUserBasicResponse>
    >(`/onboarding/user-basic`, {
      email,
      name,
    });

    return data;
  }

  public static async getGrowthSimulatorWidgets(
    dashboardId: number
  ): Promise<IGrowthSimulatorWidget[]> {
    const { data } = await GrowthSimulatorV2Service.axios.get<
      {},
      AxiosResponse<IGrowthSimulatorWidget[]>
    >(`/dashboard/${dashboardId}/widgets`);

    return data;
  }

  public static async getGrowthSimulationById(
    growthSimulationId: number
  ): Promise<GrowthSimulationSaaSResponse | GrowthSimulationResponse> {
    const { data } = await GrowthSimulatorV2Service.axiosAuthorized.get<
      IExecuteGrowthSimulationInput,
      AxiosResponse<GrowthSimulationSaaSResponse |GrowthSimulationResponse>
    >(`/simit/kpi/simulation/${growthSimulationId}`);

    return data;
  }

  public static async getGrowthSimulationByIdShare(
    growthSimulationId: number,
    titleId: number,
    genre: Genre,
    goal: EGoalType
  ): Promise<{ url: string}> {
    const { data } = await GrowthSimulatorV2Service.axiosAuthorized.get<
      IExecuteGrowthSimulationInput,
      AxiosResponse<{ url: string}>
    >(`/simit/kpi/simulation/${growthSimulationId}/share?title_id=${titleId}&genre=${genre}&goal=${goal}`);

    return data;
  }


  public static async executeGrowthSimulation(
    payload: IExecuteGrowthSimulationInput
  ): Promise<GrowthSimulationResponse> {
    const { data } = await GrowthSimulatorV2Service.axiosAuthorized.post<
      IExecuteGrowthSimulationInput,
      AxiosResponse<GrowthSimulationResponse>
    >(`simit/kpi`, payload);

    return data;
  }

  public static async executeGrowthSimulationSaaS(
    payload: IExecuteGrowthSimulationSaaSInput
  ): Promise<GrowthSimulationSaaSResponse> {
    const { data } = await GrowthSimulatorV2Service.axiosAuthorized.post<
      IExecuteGrowthSimulationInput,
      AxiosResponse<GrowthSimulationSaaSResponse>
    >(`simit/saas`, payload);

    return data;
  }

  public static async getArppu(
    payload: GetArppuPayload
  ): Promise<{ apds: number }> {
    const { data } = await GrowthSimulatorV2Service.axios.post<
      IGrowthSimulatorRetentionNumbersForm,
      AxiosResponse<{ apds: number }>
    >(`simit/kpi/apds`, payload);

    return data;
  }

  public static async getGrowthSimulatorScenarios(
    genre: Genre
  ): Promise<IGrowthSimulatorScenario[]> {
    const { data } = await GrowthSimulatorV2Service.axiosAuthorized.get<
      {},
      AxiosResponse<IGrowthSimulatorScenario[]>
    >(`simit/kpi/scenarios?genre=${genre}`);

    return data;
  }

  public static async saveGrowthSimulatorScenario(
    payload: IGrowthSimulatorScenarioInput
  ): Promise<IGrowthSimulatorScenario> {
    const { data } = await GrowthSimulatorV2Service.axiosAuthorized.post<
      IGrowthSimulatorScenarioInput,
      AxiosResponse<IGrowthSimulatorScenario>
    >(`simit/kpi/scenario`, payload);

    return data;
  }

  public static async exportData(scenarioId: number) {
    const data = await GrowthSimulatorV2Service.axiosAuthorized.get(
      `simit/kpi/${scenarioId}/export`
    );
    return data;
  }

  public static async exportDataSaaS(scenarioId: number) {
    const data = await GrowthSimulatorV2Service.axiosAuthorized.get(
      `simit/saas/${scenarioId}/export`
    );
    return data;
  }
}

export type GetArppuPayload = {
  retention_anchors: {
    [key: string]: string;
  };
  duration: number;
};
