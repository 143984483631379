import { SegmentationService } from "../../services/segmentation.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllSegmentsAction = createAsyncThunk(
  "[segmentation]/getAllSegments",
  async (titleId: number) => await SegmentationService.getAllSegments(titleId)
);

export const getAllSegmentsFieldsAction = createAsyncThunk(
    "[segmentation]/getAllSegmentFields",
    async (titleId: number) => await SegmentationService.getAllSegmentFields(titleId)
  );
  
