import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import { StyledTextField } from "./styles";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styled from "styled-components";

const StyledPasswordInput = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
  <StyledTextField
    type={showPassword ? 'text' : 'password'}
    InputProps={{
      endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
      )
    }}
    { ...props}/>
  )
};

export default styled(StyledPasswordInput)`
  .MuiFormHelperText-root.Mui-error {
    color: #fff;
  }
`;
