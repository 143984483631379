import * as React from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import { CircularProgress, FormHelperText, Grid, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SegmentationPageForm from './SegmentationPageForm/SegmentationPageForm';

const StyledDialogHeader = styled.div`
    color: #959599;
    font-weight: 300;
    background: #3D3E5A;
    height: 50px;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #303148;
`;

const StyledDialogBody = styled.div`
    color: #959599;
    background: #3D3E5A;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #303148;
    padding: 15px;
`;

const StyledDialogFooter = styled.div`
    color: #959599;
    background: #3D3E5A;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #303148;
`;

const StyledQuarterContainer = styled.div`
    display: flex;
    flex: 0 0 25%;

    &.full > div {
        width: 100%;
    }

    label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const StyledThreeQuarterContainer = styled.div`
    display: flex;
    flex: 0 0 75%;

    .white {
        color: white;
    }
`;

const StyledHalfContainer = styled.div`
    display: flex;
    flex: 0 0 50%;
`;

const StyledHalfCenterContainer = styled.div`
    display: flex;
    flex: 0 0 4%;
`;

const StyledHalfRightContainer = styled.div`
    display: flex;
    align-self: center;
    justify-content: flex-start;
    flex: 0 0 47.5%;
`;

const StyledHalfLeftContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-self: center;
    justify-content: flex-end;
    flex: 0 0 47.5%;

    &.align-bottom {
        align-self: end;
        padding-bottom: 5px;
        flex: 0 0 50%;
    }
`;

const StyledInput = styled.input`
    background-color : #606178; 
    outline: none;
    border-radius: 15px;
    border: 1px solid #606178;
    height: 20px;
    text-align: center;
    color: #959599;

    &:focus {
        background-color: white; 
        color: black;
    }

    &.small {
        height: 15px;
        width: 70px;
    }
`;

const StyledBorderContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #43445F;
    padding: 15px;
    margin: 10px;
    border-radius: 15px;

    &.full {
        width: 100%;
    }

    &.almost-full {
        width: 90%;
    }

    &.half {
        width: 50%;
    }
`;

const StyledControls = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
`;

const StyledSmallButton = styled.div`
    width: 50px;
    text-align: center;
    border-radius: 15px;
    background: #B6B6B6;
    height: 25px;
    margin: 5px;
    color: black;
    font-size: 10px;
    line-height: 25px;

    &.blue {
        background: #5DA7FE
    }
`;

const StyledWhiteLabel = styled.div`
    color: white;
    font-size: 18px;
    font-weight: 300;
`;

const StyledCompareRow = styled.div`
    display: flex;
    width: 100%;

    > * {
        margin: auto;
        width: 25%;
        min-width: 25%;
        max-width: 25%;
    }

    &:hover {
        background: #5B5C74
    }

    > :first-child {
        &:hover {
            width: 50%;
            min-width: 50%;
            max-width: 50%;

            &+* {
                width: 0%;
                min-width: 0%;
                max-width: 0%;
            }
        }
    }
`;

const StyledSelectRow = styled.div`
    display: flex;
    width: 100%;

    > * {
        margin: auto;
        width: 25%;
        min-width: 25%;
        max-width: 25%;

        &:hover {
            width: 50%;
            min-width: 50%;
            max-width: 50%;

            &+* {
                width: 50%;
                min-width: 50%;
                max-width: 50%;
            }
        }
    }
    &:hover {
        background: #5B5C74
    }
`;

const StyledSpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const MenuProps = {
    PaperProps: {
        style: {
            background: '#43445F',
            width: 250,
        },
    },
};

const names = [
    'api',
    // 'Braze',
];

export interface IAddEditDialogProps {
    onClose: any;
    selectedValue: any;
    filters: any;
    open: boolean;
    loading: boolean;
    hideFooter?: boolean;
    hideSegmentName?: boolean;
    hideFilters?: boolean;
}

const AddEditDialog = (props: IAddEditDialogProps) => {
    const { onClose, selectedValue, open, hideFooter, hideSegmentName, loading, hideFilters } = props;
    const [value, setValue] = React.useState({
        filters: [],
        name: '',
        time: '',
        days: [],
        output_config: {
            url: undefined,
            export_type: undefined
        }
    }) as any;

    type OutputConfig = {
        url: string;
        export_type: string
    }
    const [outputConfig, setOutputConfig ] = React.useState(selectedValue?.segment_schedule?.output_config?.export_type || '');

    React.useEffect(() => {
        setValue({
            id: selectedValue.segment_id,
            filters: props.filters,
            name: selectedValue.segment_name,
            time: selectedValue.segment_schedule?.schedule?.time,
            days: selectedValue.segment_schedule?.schedule?.days,
            output_config: selectedValue?.segment_schedule?.output_config
        });
        setOutputConfig(selectedValue?.segment_schedule?.output_config?.export_type || '')
    }, [props.filters, selectedValue]);

    const handleClose = () => {
        onClose(null);
    };

    const handleSubmit = () => {
        onClose(value, value.id ? true : false, hideFilters);
    };

    const updateState = (event: any, key: string, val: any, filterIndex: any, isDate: any, isTime: any) => {
        if (filterIndex !== undefined) {
            let filters: any = value.filters;
            if (key === 'checked') {
                filters[filterIndex].checked = !filters[filterIndex].checked;
                setValue({
                    ...value,
                    filters: filters
                });
            } else if (key === 'selected') {
                if (!filters[filterIndex].selectedValues) {
                    filters[filterIndex].selectedValues = [];
                }
                let index = filters[filterIndex].selectedValues.indexOf(val);
                if (index > -1) {
                    filters[filterIndex].selectedValues.splice(index, 1);
                } else {
                    filters[filterIndex].selectedValues.push(val);
                }
            } else {
                if (!filters[filterIndex].results) {
                    filters[filterIndex].results = {};
                }
                if (val.length) {
                    filters[filterIndex].results[key] = +val;
                } else {
                    delete filters[filterIndex].results[key];
                }
            }
        } else if (isDate !== undefined) {
            let days = value.days || [];
            if (event.target.checked) {
                days.push(key);
            } else {
                days = days.filter((el: string) => el !== key);
            }
            setValue({
                ...value,
                days
            });
        } else if (isTime !== undefined) {
            setValue({
                ...value,
                time: val
            });
        } else {
            let obj: any = value;
            obj[key] = val as any;
            setValue(obj);
        }
    }
    window.test = value;

    const handleOutputConfig = (exportType: string | null) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = event.target;
        let _outputConfig: OutputConfig | null = null;
        if(exportType && value) {
            _outputConfig = {
                url: value,
                export_type: exportType
            }

        }
        setValue((prevValue: any) => {
            return {
                ...prevValue,
                output_config: _outputConfig
            }
        })
    };

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: '#606077',
                    boxShadow: 'none',
                    borderRadius: 20,
                    border: '2px solid #BBBBBB',
                    width: 620,
                    maxWidth: 620
                },
            }}
            open={open} onClose={handleClose}>
            {!hideSegmentName && <DialogTitle>
                <StyledDialogHeader>
                    <StyledHalfLeftContainer>Segment's name</StyledHalfLeftContainer>
                    <StyledHalfCenterContainer></StyledHalfCenterContainer>
                    <StyledHalfRightContainer>
                        <StyledInput
                            defaultValue={value.name}
                            onInput={(event: any) => updateState(event, 'name', event.target.value, undefined, undefined, undefined)}>
                        </StyledInput>
                    </StyledHalfRightContainer>
                </StyledDialogHeader>
            </DialogTitle>}
            <DialogContent>
                {!hideFilters && <StyledDialogBody className="margin-bottom-small">
                    <StyledWhiteLabel>Filters</StyledWhiteLabel>
                    <StyledBorderContainer className="almost-full">
                        {loading ? <StyledSpinnerContainer>
                            <CircularProgress size="8rem" />
                        </StyledSpinnerContainer>
                        : <SegmentationPageForm 
                            fields={props.filters}
                            onSubmit={(filters) => setValue((prev:any) => {
                                return {...prev, filters}
                            })}
                            titleId={88}
                            selectedValues={props.selectedValue}
                            />}
                    </StyledBorderContainer>
                </StyledDialogBody>}
                {!hideFooter && <StyledDialogFooter>
                    <StyledBorderContainer className="full">
                        <StyledControls>
                            <StyledQuarterContainer>
                                <StyledWhiteLabel className="padding-top-small">Schedule</StyledWhiteLabel>
                            </StyledQuarterContainer>
                            <StyledThreeQuarterContainer>
                                <FormGroup className="day-picker" row>
                                    <FormControlLabel
                                        value="sun"
                                        control={
                                            <Checkbox
                                                checked={value.days && value.days.includes('sun')}
                                                onChange={e => {
                                                    updateState(e, 'sun', e.target.checked, undefined, true, undefined);
                                                }}
                                            />
                                        }
                                        label="Sun"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="mon"
                                        control={
                                            <Checkbox
                                                checked={value.days && value.days.includes('mon')}
                                                onChange={e => {
                                                    updateState(e, 'mon', e.target.checked, undefined, true, undefined);
                                                }}
                                            />
                                        }
                                        label="Mon"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="tue"
                                        control={
                                            <Checkbox
                                                checked={value.days && value.days.includes('tue')}
                                                onChange={e => {
                                                    updateState(e, 'tue', e.target.checked, undefined, true, undefined);
                                                }}
                                            />
                                        }
                                        label="Tue"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="wed"
                                        control={
                                            <Checkbox
                                                checked={value.days && value.days.includes('wed')}
                                                onChange={e => {
                                                    updateState(e, 'wed', e.target.checked, undefined, true, undefined);
                                                }}
                                            />
                                        }
                                        label="Wed"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="thu"
                                        control={
                                            <Checkbox
                                                checked={value.days && value.days.includes('thu')}
                                                onChange={e => {
                                                    updateState(e, 'thu', e.target.checked, undefined, true, undefined);
                                                }}
                                            />
                                        }
                                        label="Thu"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="fri"
                                        control={
                                            <Checkbox
                                                checked={value.days && value.days.includes('fri')}
                                                onChange={e => {
                                                    updateState(e, 'fri', e.target.checked, undefined, true, undefined);
                                                }}
                                            />
                                        }
                                        label="Fri"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="sat"
                                        control={
                                            <Checkbox
                                                checked={value.days && value.days.includes('sat')}
                                                onChange={e => {
                                                    updateState(e, 'sat', e.target.checked, undefined, true, undefined);
                                                }}
                                            />
                                        }
                                        label="Sat"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </StyledThreeQuarterContainer>
                        </StyledControls>
                        <div className="break"></div>
                        <StyledControls>
                            <StyledQuarterContainer></StyledQuarterContainer>
                            <StyledThreeQuarterContainer className="margin-left-negative-tiny">
                                <span className="white">At</span>
                                <input
                                    className="time-picker"
                                    type="time"
                                    defaultValue={value.time}
                                    onChange={(event) => {
                                        updateState(event, 'time', event.target.value, undefined, undefined, true);
                                    }}
                                />
                            </StyledThreeQuarterContainer>
                        </StyledControls>
                    </StyledBorderContainer>
                    <StyledControls>
                        <StyledHalfContainer>
                            <StyledBorderContainer className="full">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <StyledQuarterContainer>
                                        <StyledWhiteLabel>Use As</StyledWhiteLabel>
                                    </StyledQuarterContainer>
                                </Grid>
                                <Grid item xs={6}>
                                    <StyledThreeQuarterContainer className="dark-select">
                                        <Select
                                            value={outputConfig}
                                            onChange={(event: SelectChangeEvent) => {
                                                setOutputConfig(event.target.value as string);
                                                if(event.target.value as string === RESET) {
                                                    handleOutputConfig(null)(event as any)
                                                }
                                            }}
                                            input={<OutlinedInput label="--Choose--" />}
                                            displayEmpty
                                            renderValue={(outputConfig) => outputConfig ? outputConfig : '--Choose--'}
                                            MenuProps={MenuProps}
                                            label="Choose"
                                        >
                                            {outputConfig !== '' && <MenuItem value={RESET}>{RESET}</MenuItem>}
                                            {names.map((name: string) => (
                                                <MenuItem key={name} value={name}>
                                                    {/* <Checkbox defaultChecked={selected.indexOf(name as never) > -1} /> */}
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </StyledThreeQuarterContainer>
                                </Grid>
                                {(outputConfig && outputConfig === 'api') && <><Grid item xs={4} >
                                    <div id="outlined-weight-helper-text" style={{ fontSize: '14px', color: 'white', padding: '5px', lineHeight: '12px'}}>{outputConfig}</div>
                                </Grid>
                                <Grid item xs={8}>
                                    <StyledOutlinedInput id="outlined-basic" label={outputConfig} size='small' onChange={handleOutputConfig(outputConfig)} value={value?.output_config?.url}/>
                                </Grid>
                                </>}
                                
                            </Grid>
                            </StyledBorderContainer>
                        </StyledHalfContainer>
                        <StyledHalfLeftContainer className="align-bottom">
                            <StyledSmallButton onClick={handleClose}>Cancel</StyledSmallButton>
                            <StyledSmallButton onClick={handleSubmit} className="blue">Done</StyledSmallButton>
                        </StyledHalfLeftContainer>
                    </StyledControls>
                </StyledDialogFooter>}
            </DialogContent>
        </Dialog>
    );
}

AddEditDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
};

export default AddEditDialog;

const StyledOutlinedInput = styled(OutlinedInput)`
  height: 25px;

     fieldset {
        border-color: transparent !important;
    }
    background-color: white;

    &.Mui-disabled{
        background-color: #606178;

    }
`

const RESET = '--reset--';
