import { Dialog, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetReportDataQuery } from "../../../../queries/useGetReportDataQuery";
import { setBulbState } from "../../../../store/growthSimulator/growthSimulator.actions";
import { growthSimulatorGenreSelector } from "../../../../store/growthSimulator/growthSimulator.selector";
import { Genre } from "../../../../store/growthSimulator/growthSimulator.state";
import { StyledCloseTabDialog } from "../../../ScenarioBuilder/components/ScenarioBuilderWrapper/ScenarioBuilderWrapper";
import { StyledBackButton } from "../GrowthSimulatorButtons/GrowthSimulatorButtons";
import GrowthSimulatorConnectivity from "../GrowthSimulatorConnectivity/GrowthSimulatorConnectivity";

interface Props {
  simulationId: number;
  bulbSetter?: (setter: boolean) => void;
  isDialogOpen?: boolean;
  dialogSetter?: (setter: boolean) => void;
}

const GrowthSimulatorDetailsGoalDescription = ({ simulationId, bulbSetter, isDialogOpen = false, dialogSetter }: Props) => {
  // const goal = useSelector(goalSelector);
  const genre = useSelector(growthSimulatorGenreSelector);

  const [reportData, setReportData] = useState<string | number | Date>();
  const dispatch = useDispatch();
  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      dashboard_id: genre === Genre.SaaS ? 4000 : 2000,
      title_id: 88,
      whatif_id: simulationId,
      report_location: 10,
      topic_id: 40,
      date_from: '-1',
      date_to: '-1'
    }, `GrowthSimulatorDetailsLabel-
        ${simulationId}`, (payload) =>
    Boolean(
      payload.dashboard_id &&
      payload.whatif_id
    )
  );
  useEffect(() => {
    simulationId && setReportData(undefined);
    dispatch?.(setBulbState(false));

  }, [simulationId])

  useEffect(() => {
    if(reportData) {
      bulbSetter?.(true);
    }
  }, [reportData])

  useEffect(() => {
    if (
      !getReportDataQueryResponse ||
      !simulationId ||
      getReportDataQueryResponse.isLoading
    ) {
      return;
    }
    const { data, error } = getReportDataQueryResponse;

    if (!data || error) {
      return;
    }

    const [item] = data;

    const value = item?.results;

    if (JSON.stringify(reportData) === JSON.stringify(value)) {
      
      return;
    }

    setReportData(value);

  }, [getReportDataQueryResponse, reportData, simulationId])

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: '#1a273f',
          boxShadow: 'none',
          borderRadius: 20,
          border: '2px solid #BBBBBB',
          maxWidth: '100vw',
          padding: '20px 20px',
          maxHeight: '100vh',
          color: "white",
        }
      }}
      open={isDialogOpen}
      onClose={() => dialogSetter && dialogSetter(false)}
    >
      <DialogContent style={{
        padding: 10
      }}>
        <p dangerouslySetInnerHTML={{ __html: reportData as string }} >
        </p>
      </DialogContent>
      <StyledCloseTabDialog>
        <div className="dialog-btns">
          <StyledBackButton onClick={() => dialogSetter && dialogSetter(false)}>
            Close
          </StyledBackButton>
          <GrowthSimulatorConnectivity />
        </div>
      </StyledCloseTabDialog>
    </Dialog>

  );
};

export default GrowthSimulatorDetailsGoalDescription;

