import styled from "styled-components";

export const ScenarioBuilderStyledInput = styled.input`
    background: transparent;
    color: white;
    border: none;

    &:focus {
      outline: none;
    }
`