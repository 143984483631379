import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpoolPrimaryButton, SimpoolWarnButton } from '../../../../components/Buttons/Buttons';
import { getGrowthSimulatorScenarios } from '../../../../store/growthSimulator/growthSimulator.actions';
import { growthSimulatorGenreSelector, growthSimulatorScenariosSelector } from '../../../../store/growthSimulator/growthSimulator.selector';

import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import SimpoolDialog from '../../../../components/Dialog/Dialog';
import { IGrowthSimulatorScenario } from '../../models/growthSimulator.models';

export interface ISettingsProps {
  open: boolean;
  onClose: () => void;
  onScenarioSelected: (scenario: IGrowthSimulatorScenario) => void;

  className?: string;
}

const SettingsTableHeader = styled.header`
  margin-left: 25px;
  margin-bottom: 10px;
  margin-right: 15px;
`

const SettingsTableHeaderRow = styled.ul`
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    margin: 0;
    padding: 0;
`

const SettingsTableHeaderRowItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #66677D;
`

const SettingsTableHeaderCell = styled.strong`
  color: #fff;
  font-weight: normal;
  padding: 10px;
`


const SettingsTableContainer = styled.section`
    max-height: 300px;
    overflow-y: scroll;
    direction: rtl;
    padding-left: 10px;
    margin-right: 15px;

  & > div, ul {
    direction: ltr;
  }

  &::-webkit-scrollbar {
    width: 15px;              
  }

  &::-webkit-scrollbar-track {
    background: #424252;        
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777782;    
    border: 1px solid #424252; 
  }

`

const SettingsTableRowItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4E4F69;

    overflow: auto;

    
  &::-webkit-scrollbar {
    width: 15px;              
  }

  &::-webkit-scrollbar-track {
    background: #424252;        
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777782;    
    border: 1px solid #424252; 
  }
`

const SettingsTableCell = styled.span`
  color: #fff;
  font-weight: normal;
  padding: 10px;
  width: 100%;
  text-align: center;
`

interface ISettingsTableRowProps {
  className?: string;
  created: string;
  name: string;
  onSelect: () => void;
}

const GrowthSimulatorScenariosTableRow = ({ className, created, name, onSelect }: ISettingsTableRowProps) => {
  return <ul className={className} onClick={onSelect}>
    <SettingsTableRowItem>
      <SettingsTableCell>{moment(created).format('lll')}</SettingsTableCell>
    </SettingsTableRowItem>
    <SettingsTableRowItem>
      <SettingsTableCell>{name}</SettingsTableCell>
    </SettingsTableRowItem>
  </ul>
}

const StyledGrowthSimulatorScenariosTableRow = styled(GrowthSimulatorScenariosTableRow)`
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin: 0;
  padding: 0;
  margin: 10px 0px;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  user-select: none;

  &:hover {
    cursor: pointer;

    li {
      background: #a9a9a9;
    }
  }
`

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;

 & > div {
    display: flex;
    align-items: center;
    height: 30px;

    & > input {
      width: 300px;
      height: 100%;
      
      outline: none;
      border: none;
      padding: 0;
      padding-left: 10px;
      border-radius: 10px 0 0 10px;
    }

    & > span {
      background: white;
      padding: 5px;
      height: 100%;
      background: white;
      padding: 0 5px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
    }
 }
`

export const Search = ({ className, onChange }: { className?: string, onChange: (term: string) => void }) => {
  return <section className={className}>
    <StyledForm>
      <div>
        <input id="search" onChange={({ target: { value } }) => onChange(value)} />
        <span>
          <SearchIcon />
        </span>
      </div>
    </StyledForm>
  </section>
}

const StyledSearch = styled(Search)`
    margin-left: 25px;
    margin-right: 15px;
`

export const GrowthSimulatorScenariosDialog = ({ open, onClose, onScenarioSelected }: ISettingsProps) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const genre = useSelector(growthSimulatorGenreSelector);

  const scenarios = useSelector(growthSimulatorScenariosSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      dispatch(getGrowthSimulatorScenarios(genre));
    }
  }, [open])

  return <SimpoolDialog
    open={open}
    title="Scenarios"
    onClose={onClose}
    footer={
      <>
        <SimpoolPrimaryButton onClick={() => {
          onClose();
        }}>
          Done
        </SimpoolPrimaryButton>
        <SimpoolWarnButton onClick={onClose}>
          Cancel
        </SimpoolWarnButton>
      </>
    }
  >
    <>
      <StyledSearch onChange={setSearchTerm} />
      <SettingsTableHeader>
        <SettingsTableHeaderRow>
          <SettingsTableHeaderRowItem>
            <SettingsTableHeaderCell>Created</SettingsTableHeaderCell>
          </SettingsTableHeaderRowItem>
          <SettingsTableHeaderRowItem>
            <SettingsTableHeaderCell>Scenario's Name</SettingsTableHeaderCell>
          </SettingsTableHeaderRowItem>
        </SettingsTableHeaderRow>
      </SettingsTableHeader>
      <SettingsTableContainer>
        <div>
          {scenarios
            ?.filter(({ name }) => Boolean(searchTerm) ? name!.includes(searchTerm!) : true)
            ?.sort((a, b) => (new Date(b.created!).getTime() - new Date(a.created!).getTime()))
            ?.map((scenario, index) => <StyledGrowthSimulatorScenariosTableRow
              key={index}
              name={scenario.name!}
              created={scenario.created!}
              onSelect={() => {
                onScenarioSelected(scenario);
                onClose();
              }}
            />)}
        </div>
      </SettingsTableContainer>
    </>
  </SimpoolDialog>;
};

export default styled(GrowthSimulatorScenariosDialog)`
  
`;
