import { Field, WrappedFieldProps } from 'redux-form';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TableViewIcon from '@mui/icons-material/TableView';
import styled from 'styled-components';
import MUITooltip from '../../../../components/MUITooltip/MUITooltip';
import { ReduxFormNumericFormat } from '../../../../shared/ReduxFormNumericFormat/ReduxFormNumericFormat';
const GrowthSimulatorRouletteUpInput = ({ input: { value, onChange }, meta, suffix, disabled }: WrappedFieldProps & { suffix: string, disabled?: boolean }) => <StyledGrowthSimulatorRouletteControl
  onClick={() => !disabled && onChange({
    formattedValue: `${Math.max((value.floatValue || 0) + 1, 0)}${suffix}`,
    value: `${Math.max((value.floatValue || 0) + 1, 0)}`,
    floatValue: Math.max((value.floatValue || 0) + 1, 0)
  })}
  type="button"
>
  <StyledTopArrow></StyledTopArrow>
</StyledGrowthSimulatorRouletteControl>

const GrowthSimulatorRouletteDownInput = ({ input: { value, onChange }, meta, suffix, disabled }: WrappedFieldProps & { suffix: string, disabled?: boolean }) => <StyledGrowthSimulatorRouletteControl
  onClick={() => !disabled && onChange({
    formattedValue: `${Math.max((value.floatValue || 0) - 1, 0)}${suffix}`,
    value: `${Math.max((value.floatValue || 0) - 1, 0)}`,
    floatValue: Math.max((value.floatValue || 0) - 1, 0)
  })}
  type="button"
>
  <StyledDownArrow></StyledDownArrow>
</StyledGrowthSimulatorRouletteControl>

interface Props {
  title: string;
  showTableIcon: boolean;
  tableViewIconClick?: () => void;
  suffix?: string;
  toolTip?: string;
  iconTooltip?: string;
  name: string;
  disabled?: boolean;
  className?: string;
  disabledIcon?: boolean
}

const StyledGrowthSimulatorRouletteInputContainer = styled.div`
    background: linear-gradient(#4d4f67 0%,#10121e 100%);
    height: 0px;
    width: 100px;
    border-radius: 15px;
    box-shadow: inset #000000 3px 3px 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 10px 30px 15px;
    gap: 10px;
`

const StyledGrowthSimulatorRouletteInput = styled(ReduxFormNumericFormat)<{ disabled?: boolean}>`
  display: flex;
    width: 100%;
    height : 30px;
    background: linear-gradient(#444666 0%,#272d44 100%);
    box-shadow: inset #000000 3px 3px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;

    background: ${({ disabled}) => disabled ? 'grey' : '#fff'};
    color: #000;

    &:focus {
      outline: none;
    }
`

const StyledGrowthSimulatorRouletteInputTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: #FFC301;
  margin: 0;
  position: relative;
  &:hover {
    cursor: pointer;
    .html-tooltip {
      opacity: 1;
      visibility: visible;
      width: 100%;
    }
  }
`

const StyledGrowthSimulatorRouletteInputTitleContainer = styled.div`
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const StyledGrowthSimulatorRouletteControlsContainer = styled.div`
`

const StyledGrowthSimulatorRouletteControl = styled.button<{ disabled?: boolean }>`
    border: none;
    background: transparent;
    padding: 0;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${({disabled}) => disabled && "pointer-events: none"}
`

const StyledTopArrow = styled(PlayArrowIcon)`
  font-size: 2rem !important;
  color: #777c99;
  transform: rotate(270deg);
  
`

const StyledDownArrow = styled(PlayArrowIcon)`
  font-size: 2rem !important;
  color: #777c99;
  transform: rotate(90deg);
`

const StyledGrowthSimulatorRouletteInputWrapper = styled.div`
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const StyledTableViewIcon = styled(TableViewIcon)<{ disabled?: boolean}>`
    color: #4A9CFB;
    ${({ disabled}) => disabled ? `
      color: grey;
      pointer-events: none;
    ` : `
      // cursor: pointer;
    `}
    
    font-size: 1.5rem;

    &:hover {
      color: rgba(74,156,251, 0.5);
    }
`
const StyledTableViewIconWrapper = styled.div`
display: flex;
  position: relative;
    &:hover {
      cursor: pointer;
      .html-tooltip {
        opacity: 1;
        visibility: visible;
        width: 400%;
      }
    }
`

const GrowthSimulatorRouletteInput = ({
  title,
  showTableIcon,
  tableViewIconClick,
  suffix,
  toolTip,
  iconTooltip,
  name,
  disabled = false,
  className,
  disabledIcon = false,
  ...rest
}: Props) => {
  return (
    <StyledGrowthSimulatorRouletteInputWrapper className={className} {...rest}>
      <StyledGrowthSimulatorRouletteInputTitleContainer>
        <MUITooltip title={toolTip && <div dangerouslySetInnerHTML={{ __html: toolTip}}/>} >
          <StyledGrowthSimulatorRouletteInputTitle>
            {title}
          </StyledGrowthSimulatorRouletteInputTitle>
        </MUITooltip>
        {showTableIcon
          && <StyledTableViewIconWrapper>
            <MUITooltip title={iconTooltip}>
              <StyledTableViewIcon onClick={tableViewIconClick} disabled={disabledIcon}></StyledTableViewIcon>
            </MUITooltip>
          </StyledTableViewIconWrapper>
        }
      </StyledGrowthSimulatorRouletteInputTitleContainer>

      <StyledGrowthSimulatorRouletteInputContainer>
        <Field
          component={StyledGrowthSimulatorRouletteInput}
          suffix={suffix}
          name={name}
          disabled={disabled}
        />
        <StyledGrowthSimulatorRouletteControlsContainer>
          <Field
            component={ GrowthSimulatorRouletteUpInput}
            suffix={suffix}
            name={name}
            disabled={disabled}
          />
          <Field
            component={GrowthSimulatorRouletteDownInput}
            suffix={suffix}
            name={name}
            disabled={disabled}
          />
        </StyledGrowthSimulatorRouletteControlsContainer>
      </StyledGrowthSimulatorRouletteInputContainer>
    </StyledGrowthSimulatorRouletteInputWrapper>
  );
};

export default GrowthSimulatorRouletteInput;
