import { device } from "../../helpers/device";
import styled from "styled-components";

type Props = {
  number: number;
  text: string;
}
const Card = ({ number, text}: Props) => {
  return (
    <StyledCard>
      <div className="number">{number}</div>
      <div className="text">{text}</div>
    </StyledCard>
);
};

export default Card;

const StyledCard = styled.div`
  box-sizing: border-box;
  display: flex;
  background: #2C3C55;
  border: 1px solid rgba(255, 250, 250, 0.4);
  box-shadow: inset 1px 1px 25px 1px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  /* width: 260px; */
  /* height: 288px; */
  padding: 53px 45px;
  flex-direction: column;
  gap: 44px;
  width: 100%; 
  height: 450px;  

  @media ${device.mobileL} { 
    height: auto !important;
  }


  @media ${device.laptopSL} {
    gap: 30px;
    padding: 25px 15px;

    .number {
      font-size: 106px !important;
      line-height: 106px !important;
    }
    .text{
      font-size: 20px !important;
      line-height: 33px !important;
    }
  }
  @media ${device.laptopS} { 
    height: 320px;  
    padding: 25px 15px;
    gap: 30px;

    .text{
      font-size: 15px !important;
      line-height: 25px !important;
    }
    .number {
      font-size: 80px !important;
      line-height: 80px !important;
    }
  }

  @media ${device.laptop} { 
    /* width: 180px;  */
    padding: 25px 15px;
    height: 260px;
    gap: 10px;
    .text{
      font-size: 12.5px !important;
      line-height: 21px !important;
    }
    .number {
      font-size: 66.6667px !important;
      line-height: 66.6667px !important;
    }
  }


  .number{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 106.667px;
    line-height: 100px;
   
    text-align: center;
    letter-spacing: -0.02em;

    background: linear-gradient(180deg, #FF9852 0%, #E9681F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .text{
    font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
line-height: 33px;
  /* or 167% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #FFFFFF;
  }

`