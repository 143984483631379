
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

export interface ISidesheetProps {
  open: boolean,
  position: 'left' | 'right',
  children: any,
  onClose: () => void,
  className?: string,
  title: string;
}

const SidesheetOverlay = styled.div`
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    z-index: 100;
`;

export const Sidesheet = ({ position, open, children, onClose, className, title }: ISidesheetProps) => {
  return <>
    <section className={cn(className, 'sidesheet', {
      ['sidesheet-open']: open,
      ['sidesheet-left']: position === 'left',
      ['sidesheet-right']: position === 'right',
    })}>
      <div className="sidesheet-content">
        <header>
          <h2>{title}</h2>
          <button onClick={onClose}>
            <CloseIcon></CloseIcon>
          </button>
        </header>
        <div className="sidesheet-content-wrapper">
          {children}
        </div>
      </div>
    </section>
    {open ? <>
      <Helmet>
        <style>
          {`body { overflow: hidden }`}
        </style>
      </Helmet>
      <SidesheetOverlay onClick={onClose}></SidesheetOverlay >
    </> : null}
  </>
};

export default styled(Sidesheet)`
    width: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    top: 0;
    background: #1A273F 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #000000;
    z-index: 1000;
    transition: all 300ms;
    overflow: hidden;

    &.sidesheet-left {
      left: 0;
      flex-direction: row;
    }

    &.sidesheet-right {
      right: 0;
      flex-direction: row-reverse;
    }

    &.sidesheet-expanded {
      width: 100vw !important;
    }

    &.sidesheet-open {
      width: 400px;
      display: flex;
      z-index: 2147483647;

      .sidesheet-content {
        width: 400px;

        .sidesheet-content-wrapper {
          width: 400px;

          &::-webkit-scrollbar {
          width: 15px;              
          }
        
          &::-webkit-scrollbar-track {
            background: #424252;        
          }
        
          &::-webkit-scrollbar-thumb {
            background-color: #777782;    
            border: 1px solid #424252; 
          }
        }
      }

      @media screen and (max-width: 400px) {
        width: 100vw;
      }
    }

    .sidesheet-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      header {
        flex-direction: row;
        display: flex;
        padding: 15px;
        justify-content: space-between;
        height: 5%;
        align-items: center;
        color: white;

        button {
          border: none;
          background: none;
          cursor: pointer;

          svg {
            transform: scale(1.5);
            color: white;
          }
        }
      }

      .sidesheet-content-wrapper {
        height: 100%;
        overflow: auto;
      }
    }
`;
