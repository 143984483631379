import { IPeriod } from "../components/DateRangePicker/DateRangePicker";
import { UseQueryResult } from "react-query";
import { useGetReportDataQuery } from "./useGetReportDataQuery";

export interface IGetImproveCohortsReportDataResponse {
  simpool_score: number;
  cohort: string;
  users: number;
  ltv: number;
  avg_ltv: number;
  apds: number;
  avg_apds: number;
  arppu30: number;
  avg_arppu: number;
  conv: number;
  avg_conv: number;
}

export interface IGetImproveCohortsReportDataPayload {
  period?: IPeriod;
  titleId?: number;
}

export const useGetImproveCohortsReportDataQuery = ({
  period,
  titleId
}: IGetImproveCohortsReportDataPayload): UseQueryResult<IGetImproveCohortsReportDataResponse[], Error> | undefined => {
  const reportId = 13605;

  const response = useGetReportDataQuery(
    {
      report_id: reportId,

      dashboard_id: 100,
      title_id: titleId,
      days_back: period?.daysBack,
      date_from: String(period?.dateFrom),
      date_to: String(period?.dateTo),
    },
    `GetImproveCohortsReportDataQuery-${reportId}-${period?.daysBack}-${titleId}-${period?.dateFrom}-${period?.dateTo}`
  );

  const data: IGetImproveCohortsReportDataResponse[] | undefined =
    response?.data?.map(
      ({
        simpool_score,
        cohort,
        users,
        ltv,
        avg_ltv,
        apds,
        avg_apds,
        arppu30,
        avg_arppu,
        conv,
        avg_conv,
      }) => ({
        simpool_score,
        cohort,
        users,
        ltv,
        avg_ltv,
        apds,
        avg_apds,
        arppu30,
        avg_arppu,
        conv,
        avg_conv,
      })
    );

  return { ...response, data } as UseQueryResult<
    IGetImproveCohortsReportDataResponse[],
    Error
  >;
};
