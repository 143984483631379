import { EGoalType, Genre } from "../../../store/growthSimulator/growthSimulator.state";
import { DynamicCpi } from "../components/GrowthSimulatorDynamicCpiTable/GrowthSimulatorDynamicCpiTable";
import { IGrowthSimulatorRetentionNumbersForm } from "../components/GrowthSimulatorRetentionNumbers/GrowthSimulatorRetentionNumbers";

export interface IUserBasicResponse {
  id: number
  name: string
  email: string
  account_id: number
  status: number
  scopes: IScope[]
  subscription: any
  access_token: string
  trial_start: string
  trial_status: 'active' | null
  email_verified: boolean
}

export interface IScope {
  user_id: number
  title_id: number
}

export interface IGrowthSimulatorWidget {
  report_id: number;
  dashboard_id: number;
  chart_type: string;
  x_axis: string;
  y_axis: string;
  series: string;
  headline: string;
  title_id?: any;
  width: number;
  eco_topic_id: number;
  status: number;
  x_label: string;
  y_label: string;
  eco_topic_name: string;
  tooltip: string;
  y_axis_format: string;
  report_location: number;
  export_button: boolean;
  tooltip_additional_info?: any;
  preview_rows?: any;
  is_filtered: boolean;
  static_filters?: any;
  data_source: string;
  stack_normelize: boolean;
  wharehouse: string;
  group_by?: any;
  use_currency: boolean;
}

export interface TokenEarningsDist {
  portion: number;
  value: number;
}

export interface Web2Data {
  simulation_id: number;
  title_id: number;
  day: number;
  dau: number;
  token_dist: number;
  type: string;
  retention: number;
  apds: number;
  daily_installs: number;
  total_installs: number;
  total_organic_installs: number;
  daily_organic_installs: number;
  daily_paid_installs: number;
  total_paid_installs: number;
  marketing_spent: number;
}

export interface TokenEarningsDist2 {
  portion: number;
  value: number;
}

export interface Web3Data {
  simulation_id: number;
  title_id: number;
  day: number;
  dau: number;
  token_dist: number;
  type: string;
  retention: number;
  apds: number;
}

export interface GrowthSimulationResponse {
  simulation_id: number;
  simulation_config: GrowthSimulatorConfig;
}

export interface GrowthSimulationSaaSData {
  simulation_id: number
  title_id: number
  month: number
  package_name: string
  package_type: string
  package_price: number
  subscribers: number
  leads: number
  conversion: number
}

export interface IGrowthSimulationSaaSConfig {
  id: string;
}

export interface GrowthSimulationSaaSResponse {
  simulation_config: IGrowthSimulationSaaSConfig;
  simulation_id: number;
}

export interface Web2RetentionAnchors {
  1: string;
  6: string;
  14: string;
  30: string;
  60: string;
  90: string;
  150: string;
  365: string;
}

export interface Web3RetentionAnchors {
  1: string;
  6: string;
  14: string;
  30: string;
  60: string;
  90: string;
  150: string;
  365: string;
}

export interface TokenEarningsDist {
  portion: number;
  value: number;
}

export interface IExecuteGrowthSimulationInput {
  user_id: string;
  title_id: number;
  installs: number;
  duration: string;

  retention: number;
  organic: string;
  dau_goal: number;
  budget: number;
  install_rate: number;
  cpi: DynamicCpi[];
  cpa: number;
  cpc: number;
  ctr: number;
  cpm: number;
  impressions_per_dau: number;
  current_dau_max_seniority: number;

  extra_params?: any;
  is_tutorial?: boolean;

  web2_retention_anchors: Web2RetentionAnchors;
  web3_conversion: number;
  web3_retention_anchors?: Web3RetentionAnchors;
}

export interface IGrowthSimulatorScenarioInput {
  name: string;
  title_id: number;
  data: GrowthSimulatorConfig;
  genre: Genre;
}

export interface IGrowthSimulatorScenario {
  id?: number;
  name?: string;
  user_id?: number;
  title_id?: number;
  created?: string;
  data: GrowthSimulatorConfig;
  genre: Genre;
}

export enum ECalculationType {
  TotalInstalls = 10,
  PaidInstalls = 20,
  OrganicInstalls = 30,
  TotalMarketingCost = 40,
  ECPI = 50,
  MaxDau = 60,
  TotalRevenues = 70,

  TimeToPositiveCashflow = 90,
  ROASTime = 80,
}

export enum ERevenueType {
  APDS = 10,
  ARPDAU = 20,
  ROAS = 30,
  LTV = 40,
}

export type NumericFormatType = {
  formattedValue: string;
  value: string;
  floatValue: number;
};

export type CalculatorData = {
  dauGoal: number;
  budget: number;
  dailyInstalls: number;
  days: number;
  cpi: number;
  organicUplift: number;
  arppu: number;
  conversion: number;
  currentDauScale: number;
  dauMaximumSeniority: number;
  impressionsPerDau: number;
  cpm: number;
  ctr: number;
  cpc: number;
  installRate: number;
  cpa: number;
  monetizationType: 'CPM' | 'CPC' | 'CPA'
};

export type SaasFromData = {
  monthly_subscribers?: number | null;
  growth_goal?: number | null,
  marketing_budget?: number | null,
  cost_per_lead: number;
  conversion: number;
  monthly_subscribers_churn: number;
  annual_subscribers_churn: number;
  predicted_months: number;
  starter: {
    monthly: {
      price: number;
      proportion: number;
    },
    annually: {
      price: number;
      proportion: number;
    }
  },
  beginner: {
    monthly: {
      price: number;
      proportion: number;
    },
    annually: {
      price: number;
      proportion: number;
    }
  }
}
export type GrowthSimulatorConfig = {
  tab: string;
  id: string;
  name?: string;
  retentionNumbers: {
    day: number;
    value: number;
  }[];
  calculator: CalculatorData;
  dynamicCpi?: DynamicCpi[];
  dynamicCpiWeightedAverage?: number;
  saasForm?: SaasFromData,
  goal: EGoalType
};

export type CalculatorFormData = {
  dauGoal: NumericFormatType;
  budget: NumericFormatType;
  dailyInstalls: NumericFormatType;
  days: NumericFormatType;
  cpi: NumericFormatType;
  organicUplift: NumericFormatType;
  arppu: NumericFormatType;
  conversion: NumericFormatType;
  currentDauScale: NumericFormatType;
  dauMaximumSeniority: NumericFormatType;
  impressionsPerDau: NumericFormatType;
  cpm: NumericFormatType;
  ctr: NumericFormatType;
  cpc: NumericFormatType;
  installRate: NumericFormatType;
  cpa: NumericFormatType;
  monetizationType: 'CPM' | 'CPC' | 'CPA'
};

export type GrowthSimulatorFormData = {
  tab: string;
  id: string;
  name?: string;
  retentionNumbers: {
    day: number;
    value: NumericFormatType;
  }[];
  calculator: CalculatorFormData;
  dynamicCpi?: DynamicCpi[];
  dynamicCpiWeightedAverage?: NumericFormatType;
  saasForm: GrowthSimulatorSaaSFormModel,
  goal: EGoalType,
  isScenarioSaved: boolean;
};

export interface  IExecuteGrowthSimulationSaaSInput {
  user_id: string;
  title_id: number;
  simulation_type: 'GROWTH';
  monthly_subscribers: number | null;
  growth_goal: number | null,
  marketing_budget:number | null,
  cost_per_lead: number;
  conversion: number;
  monthly_subscribers_churn: number;
  annual_subscribers_churn: number;
  predicted_months: number;

  extra_params: IGrowthSimulationSaaSConfig;
  is_tutorial?: boolean;

  packages: IExecuteGrowthSimulationSaaSPackagesInput[];
  
}

export interface IExecuteGrowthSimulationSaaSPackagesInput {
  package_name: 'Starter' | 'Beginner';
  period: 'Monthly' | 'Annually';
  price: number;
  proportion: number;
}

export interface GrowthSimulatorSaaSFormModel {
  monthly_subscribers: NumericFormatType;
  cost_per_lead: NumericFormatType;
  conversion: NumericFormatType;
  monthly_subscribers_churn: NumericFormatType;
  annual_subscribers_churn: NumericFormatType;
  predicted_months: NumericFormatType;
  growth_goal: NumericFormatType,
  marketing_budget:NumericFormatType,
  starter: {
    monthly: {
      price: NumericFormatType;
      proportion: NumericFormatType;
    },
    annually: {
      price: NumericFormatType;
      proportion: NumericFormatType;
    }
  },
  beginner: {
    monthly: {
      price: NumericFormatType;
      proportion: NumericFormatType;
    },
    annually: {
      price: NumericFormatType;
      proportion: NumericFormatType;
    }
  }
}

export enum ETutorialStep {
  RetentionNumbers,
  Days,
  CPI,
  OrganicUplift,
  ARPPU,
  Conversion,

  DauGoal,
  Budget,
  DailyInstalls,

  RunButton,
  RunButtonSaaS = 76,

  NewMonthlyCustomers = 10,
  CostPerLead = 20,
  LeadToCustomerConversion = 30,
  PricingPlan = 35,
  MonthlySubscriberChurn = 60,
  AnnualSubscriberChurn = 70,
  PredictedMonths = 75,
  DetailsEndMessage = 240,

  DetailsInstallsSection = 200,
  DetailsKPIListSection = 210,
  DetailsChartsSection = 220,
  DetailsSummary = 230,

  SecondStepCpi = 10,
  SecondStepRunButton = 20,
  SecondStepEmailRegistration = 30,
  SecondStepCPL = 40,
}