import React, { ReactElement, ReactNode, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Breakpoint } from '@mui/material';

export interface IDialogProps {
  open: boolean;
  title: string;
  children?: ReactNode;
  className?: string;
  footer?: ReactElement;
  width?: number;
  height?: number;
  onClose: () => void;
  resizable?: boolean;
  render?: (shouldResize: any) => ReactNode,
  maxWidth?: Breakpoint
}

const StyledDialogHeader = styled.header`
    color: #959599;
    margin-bottom: 15px;
`;

const StyledDialogHeaderText = styled.h2`
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1.6rem;
    font-weight: normal;
`

const StyledDialogWrapper = styled.div`
    color: #959599;
    background: #3D3E5A;
    border-radius: 20px;
    display: flex;
    flex-direction: column;

    padding: 15px;
    box-shadow: inset 0px 0px 9px #000000D9;
    min-height: 95%;
    .simpool-dialog {
    display: flex;
    flex: 1;
  }
`;

const StyledDialogFooter = styled.footer`
    color: #959599;
    background: #3D3E5A;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;

    button {
      &:first-of-type {
        margin-left: 10px;
      }
    }
`;

const ResizableDialogContent = styled.div`
    
    /* overflow-y: auto; */
  

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background: #424252;        
    }

    &::-webkit-scrollbar-thumb {
      background-color: #777782;    
      border: 1px solid #424252; 
    }
`

const ResizableDialogOverlay = styled.div`
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    z-index: 100;
`

const ResizableDialogContentWrapper = styled.section`
    padding: 20px 24px;
    flex: 1 1 auto;
    display: flex;
    color: red;
`
export type ShouldResize = {width: number; height: number};

const ResizableDialog = ({ className, children, onClose, render }: { className?: string, children?: ReactNode, onClose?: () => void, render?: (shouldResize: ShouldResize) => ReactNode }) => {
  const [state, setState] = useState({ width: 700, height: 600 });

  const onResize = (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
    setState({ width: size.width, height: size.height });
  };

  return <>
    <Resizable className={className} width={state.width}
      onResize={onResize}
      height={state.height}
      // draggableOpts
    >
      <ResizableDialogContentWrapper
        style={{
          width: state.width + "px",
          height: state.height + "px"
        }}>
        <ResizableDialogContent
          style={{
            width: state.width + "px",
            height: state.height + "px"
          }}
        >
          {/* {children} */}
          {render ? render({width: state.width, height: state.height}) : children}
        </ResizableDialogContent>
      </ResizableDialogContentWrapper>
    </Resizable>
    <Helmet>
      <style>
        {`body { overflow: hidden }`}
      </style>
    </Helmet>
    <ResizableDialogOverlay onClick={() => onClose && onClose()}></ResizableDialogOverlay>
  </>
}

const StyledResizableDialog = styled(ResizableDialog)`
  background-color: rgb(96, 96, 119);
  box-shadow: none;
  border-radius: 35px;
  border: 2px solid rgb(187, 187, 187);
  box-shadow: inset 3px 3px 6px #000000E0, 0px 3px 14px #000000;
  
  position: fixed;
  margin: 20px;
  z-index: 1000;
  left: 33%;
  top: 15%;

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
`


const SimpoolDialog = ({ open, title, children, className, footer, onClose, resizable, render, maxWidth }: IDialogProps) => {
  if (!open) {
    return null;
  }

  return resizable ? <StyledResizableDialog onClose={onClose} render={
    (shouldResize) => {
      return (
      <StyledDialogWrapper style={{ height: 'calc(100% - 30px)'}}>
        <StyledDialogHeader>
          <StyledDialogHeaderText>{title}</StyledDialogHeaderText>
        </StyledDialogHeader>
        <main className='simpool-dialog' style={{ height: 'calc(100% - 100px)', overflow: 'hidden'}}>
          {render ? render(shouldResize) : children}
        </main>
        {footer && <StyledDialogFooter>
          {footer}
        </StyledDialogFooter>}
      </StyledDialogWrapper>
      );
    }
  }>
    
  </StyledResizableDialog> : <Dialog open={open} className={className} onClose={onClose} maxWidth={maxWidth}>
    <DialogContent>
      <StyledDialogWrapper>
        <StyledDialogHeader>
          <StyledDialogHeaderText>{title}</StyledDialogHeaderText>
        </StyledDialogHeader>
        <main>
          {children && children}
        </main>
        {footer && <StyledDialogFooter>
          {footer}
        </StyledDialogFooter>}
      </StyledDialogWrapper>
    </DialogContent>
  </Dialog>
}

export default styled(SimpoolDialog)`
  
 .MuiDialog-paper {
    background-color: rgb(96, 96, 119);
    box-shadow: none;
    border-radius: 35px;
    border: 2px solid rgb(187, 187, 187);
    box-shadow: inset 3px 3px 6px #000000E0, 0px 3px 14px #000000;
 }
`;
