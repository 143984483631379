import { INotificationsSlice, initialState } from "./notifications.state";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { OptionsObject } from "notistack";

export interface INotification {
  key?: number;
  message: string;
  dismissed?: boolean;
  options?: OptionsObject;
}

export const notificationsSlice = createSlice({
  name: "[notifications]",
  initialState: initialState,
  reducers: {
    enqueueSnackbar: (
      state: INotificationsSlice,
      {
        payload: { notification },
      }: PayloadAction<{ notification: INotification }>
    ) => {
      state.notifications = [...state.notifications, notification];
    },
    closeSnackbar: (
      state: INotificationsSlice,
      {
        payload: { key, dismiss },
      }: PayloadAction<{ dismiss: boolean; key: number }>
    ) => {
      state.notifications = state.notifications.map((notification) =>
        dismiss || notification.key === key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeSnackbar: (
      state: INotificationsSlice,
      { payload: { key } }: PayloadAction<{ key: number }>
    ) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== key
      );
    },
  },
});

export default notificationsSlice.reducer;
