
import { useQuery } from "react-query";
import { GetArppuPayload, GrowthSimulatorV2Service } from "../pages/GrowthSimulatorV2/services/growthSimulator.service";

const getApds = async (form: GetArppuPayload | undefined): Promise<{apds: number} | undefined> => {
  if(!form) {
    return;
  }
  const apds  = await GrowthSimulatorV2Service.getArppu(form)

  return apds;
};

export const useGetApdsQuery = (form: GetArppuPayload | undefined) => {
  return useQuery<{apds: number} | undefined, Error>(`useGetPagesQuery--${JSON.stringify(form)}`, () => getApds(form));
};
