import { useEffect } from 'react';

const useOutsideAlerter = (ref: any, handler: (event: any) => void) =>{
  useEffect(() => {
    function handleClickOutside(event:any ) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        handler(event)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideAlerter;