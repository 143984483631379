import {
  EGoalType,
  Genre,
} from "../../store/growthSimulator/growthSimulator.state";
import {
  GrowthSimulatorStepFive,
  GrowthSimulatorStepFour,
  GrowthSimulatorStepOne,
  GrowthSimulatorStepThree,
  GrowthSimulatorStepTwo,
} from "./components/GrowthSimulatorSteps/GrowthSimulatorSteps";
import { Pages, trackPageSource, trackSource } from "../../mixpanel";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  setGenre,
  setGoalType,
} from "../../store/growthSimulator/growthSimulator.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import GrowthSimulatorDetails from "./components/GrowthSimulatorDetails/GrowthSimulatorDetails";
import { GrowthSimulatorFormData } from "./models/growthSimulator.models";
import { GrowthSimulatorV2Service } from "./services/growthSimulator.service";
import GrowthSimulatorWizard from "./components/GrowthSimulatorWizard/GrowthSimulatorWizard";
import background from "../../assets/landing_bg.png";
import { getFormValues } from "redux-form";
import { getUserOnboarding } from "../../store/onboarding/onboarding.actions";
import styled from "styled-components";
import { userSelector } from "../../store/onboarding/onboarding.selectors";

interface Props {}

const StyledGrowthSimulatorContainer = styled.div`
  background: #202e48;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${background}) no-repeat center fixed;
  background-size: 100% 100%;
  box-sizing: border-box;
`;

const StyledGrowthSimulatorMain = styled.main`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

enum ERoute {
  Wizard = "wizard",
  Details = "details",
  Unknown = "unknown",
}

const mobileMatchMedia = window.matchMedia("(max-width: 800px)");

const GrowthSimulatorV2 = (props: Props) => {
  const { pathname, search } = useLocation();
  const [route, setRoute] = useState<ERoute>(ERoute.Unknown);
  const history = useHistory();
  const [isMobileMode, setIsMobileMode] = useState(mobileMatchMedia.matches);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isCalledRef = useRef(false);

  const source = new URLSearchParams(search).get("src") || undefined;

  useEffect(() => {
    trackPageSource(Pages.GrowthSimulator, source);
  }, [])

  useEffect(() => {
    if (!isCalledRef?.current) {
      if(user) {
        isCalledRef.current = true;
        dispatch(getUserOnboarding({ userId: user.id }));
      }
    }
  }, [user]);

  const onResolutionChange = () => {
    setIsMobileMode(mobileMatchMedia.matches);
  };

  useEffect(() => {
    mobileMatchMedia.addListener(onResolutionChange);

    return () => {
      mobileMatchMedia.removeListener(onResolutionChange);
    };
  }, []);

  useEffect(() => {
    if (isMobileMode) {
      history.push("/mobile-backdrop");
    }
  }, [isMobileMode]);

  useEffect(() => {
    const path = pathname.replace(/\/growth-simulator-v2\//, "");

    if (path.includes("details")) {
      setRoute(ERoute.Details);
    } else {
      setRoute(ERoute.Wizard);
    }
  }, [pathname]);

  const steps = [
    // <Route path="/growth-simulator-v2/wizard/step-1" key={"/growth-simulator-v2/wizard/step-1"}><GrowthSimulatorWizardStepFirst /></Route>,
    <Route
      path="/growth-simulator-v2/wizard/step-1"
      key={"/growth-simulator-v2/wizard/step-1"}
    >
      <GrowthSimulatorStepOne />
    </Route>,
    // <Route path="/growth-simulator-v2/wizard/step-2" key={"/growth-simulator-v2/wizard/step-2"}><GrowthSimulatorWizardStepSecond /></Route>,
    <Route
      path="/growth-simulator-v2/wizard/step-2"
      key={"/growth-simulator-v2/wizard/step-2"}
    >
      <GrowthSimulatorStepTwo />
    </Route>,
    <Route
      path="/growth-simulator-v2/wizard/step-3"
      key={"/growth-simulator-v2/wizard/step-3"}
    >
      <GrowthSimulatorStepThree />
    </Route>,
    <Route
      path="/growth-simulator-v2/wizard/step-4"
      key={"/growth-simulator-v2/wizard/step-4"}
    >
      <GrowthSimulatorStepFour />
    </Route>,
    <Route
      path="/growth-simulator-v2/wizard/step-5"
      key={"/growth-simulator-v2/wizard/step-5"}
    >
      <GrowthSimulatorStepFive />
    </Route>,
  ];

  return (
    <StyledGrowthSimulatorMain className={`growth-simulator-${route}`}>
      <Switch>
        {/* <Redirect from="/growth-simulator-v2" exact to="/growth-simulator-v2/wizard" />
        <Redirect from="/growth-simulator-v2/wizard" exact to="/growth-simulator-v2/wizard/step-1" /> */}
        <Redirect
          from="/growth-simulator-v2"
          exact
          to="/growth-simulator-v2/details"
        />
        <Redirect
          from="/growth-simulator-v2/wizard"
          exact
          to="/growth-simulator-v2/details"
        />
        <Route path="/growth-simulator-v2/wizard">
          <StyledGrowthSimulatorContainer>
            <GrowthSimulatorWizard
              onBack={(step) =>
                history.push(`/growth-simulator-v2/wizard/step-${step - 1}`)
              }
              onNext={(step) =>
                history.push(`/growth-simulator-v2/wizard/step-${step + 1}`)
              }
              steps={steps}
              onSubmit={() => {
                setRoute(ERoute.Details);

                history.push("/growth-simulator-v2/details");
              }}
            />
          </StyledGrowthSimulatorContainer>
        </Route>

        <Route path="/growth-simulator-v2/details">
          <GrowthSimulatorDetails
            onBack={() =>
              history.push(`/growth-simulator-v2/wizard/step-${steps.length}`)
            }
          />
        </Route>
      </Switch>
    </StyledGrowthSimulatorMain>
  );
};

export default GrowthSimulatorV2;
