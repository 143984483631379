import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationContext, IApplicationContext, defaultState } from '../../Context';
import { getSettingsErrorSelector } from '../../store/settings/settings.selector';
import { getCurrency, setTitle } from '../../store/shared/shared.actions';

import { useSnackbar } from 'notistack';
import { UseQueryResult } from 'react-query';
import useNotifier from '../../hooks/useNotifier';
import { useGetAccountTitlesQuery } from '../../queries/useGetAccountTitlesQuery';
import { useGetPagesQuery } from '../../queries/useGetPagesQuery';
import { useGetRetentionReportDataQuery } from '../../queries/useGetRetentionReportDataQuery';
import { useGetTopBarReportDataQuery } from '../../queries/useGetTopBarReportDataQuery';
import { userSelector } from '../../store/onboarding/onboarding.selectors';
import { getSettings } from '../../store/settings/settings.actions';

export interface FetchedDataProviderProps {
  children: ReactElement;
}

export const FetchedDataProvider = ({ children }: FetchedDataProviderProps) => {
  const [state, setState] = useState<IApplicationContext>(defaultState);
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(userSelector);

  useNotifier();

  const dispatch = useDispatch();

  const getSettingsError = useSelector(getSettingsErrorSelector);

  //TODO(PPavlov): Refactor
  useEffect(() => {
    if (!getSettingsError) {
      return;
    }

    enqueueSnackbar(getSettingsError.message, {
      variant: 'error',
    });
  }, [getSettingsError]);

  const useFetchStateEffect = function <T>(
    query: UseQueryResult<T | undefined, Error>,
    key: keyof IApplicationContext,
    dependencies?: any[]
  ) {
    useEffect(() => {
      const { isLoading, data, error } = query;

      if (!data) {
        return;
      }

      if (JSON.stringify(state[key]) !== JSON.stringify({ isLoading, data, error })) {
        setState((ctx) => ({
          ...ctx,
          isLoading,
          [key]: {
            isLoading,
            data,
            error,
          },
        }));
      }
    }, [query, ...(dependencies || [])]);
  };

  useEffect(() => {
    if (!state?.accountTitlesQuery || state?.accountTitlesQuery?.isLoading) {
      return;
    }

    if (state?.title) {
      return;
    }

    setState((s) =>
      JSON.parse(
        JSON.stringify(
          Object.assign(s, {
            title: state?.accountTitlesQuery && state.accountTitlesQuery?.data && state.accountTitlesQuery?.data[0],
          })
        )
      )
    );
  }, [state?.accountTitlesQuery]);

  const titleId = state?.title?.id;
  const period = state?.period;

  useEffect(() => {
    dispatch(setTitle(state?.title));
  }, [state?.title]);

  useEffect(() => {
    if (!titleId) {
      return;
    }

    dispatch(getSettings(titleId));
    dispatch(getCurrency(titleId));
  }, [titleId]);

  useFetchStateEffect(useGetTopBarReportDataQuery({ titleId, period }), 'topBarReportDataQuery', [titleId, period]);
  useFetchStateEffect(useGetRetentionReportDataQuery({ titleId }), 'userRetentionReportDataQuery', [titleId]);

  useFetchStateEffect(useGetPagesQuery(), 'pagesQuery');

  useFetchStateEffect(useGetAccountTitlesQuery(user?.id), 'accountTitlesQuery', [user]);

  return <ApplicationContext.Provider value={[state, setState]}>{children}</ApplicationContext.Provider>;
};

export default FetchedDataProvider;
