import { AxiosInstance, AxiosResponse } from "axios";

import { GrowthSimulationResponse } from "../pages/GrowthSimulatorV2/models/growthSimulator.models";

export class GrowthSimulatorService {
  public static axios: AxiosInstance;


  public static async executeGrowthSimulation(data: any): Promise<AxiosResponse<GrowthSimulationResponse>> {
    return await GrowthSimulatorService.axios.post<any, AxiosResponse<GrowthSimulationResponse>>(`simit/kpi`, data);
  }
}

export interface TokenEarningsDist {
    portion: number;
    value: number;
}

export interface Config {
    token_name: string;
    token_value: number;
    token_daily_cap: number;
    web3_conv: number;
    token_earnings_dist: TokenEarningsDist[];
}

export interface Web2Data {
    simulation_id: number;
    title_id: number;
    day: number;
    dau: number;
    token_dist: number;
    config: Config;
    type: string;
    retention: number;
    apds: number;
}

export interface TokenEarningsDist2 {
    portion: number;
    value: number;
}

export interface Config2 {
    token_name: string;
    token_value: number;
    token_daily_cap: number;
    web3_conv: number;
    token_earnings_dist: TokenEarningsDist2[];
}

export interface Web3Data {
    simulation_id: number;
    title_id: number;
    day: number;
    dau: number;
    token_dist: number;
    config: Config2;
    type: string;
    retention: number;
    apds: number;
}
