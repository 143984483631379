import styled from "styled-components";

export interface IArrowUpSVGProps {
  className?: string;
  direction?: string;
}
const ArrowSVG = ({className}: IArrowUpSVGProps) => {
  return (
    <svg className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.50453 0L4 2.05084L6.49547 0L7.5 1.12362L4 4L0.5 1.12362L1.50453 0Z" fill="grey"></path>
    </svg>
  );
}

export const StyledArrowUpSVG = styled(ArrowSVG)`
  transform: ${props => props.direction === 'up' && 'rotate(180deg)'};
  width: 8px;
  height: 4px;
`;

export interface IArrowButtonProps {
  className?: string;
  direction?: string;
  onChange?: () => void;
}
const ArrowButton = ({className, direction = 'up', onChange}: IArrowButtonProps) => {
  return (
    <button className={className} onClick={onChange}>
      <StyledArrowUpSVG direction={direction} className="arrow"/>
    </button>
  );
}

export const StyledArrowButton = styled(ArrowButton)`
  width: 20px;
  border: 1px solid grey;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-top-right-radius: ${props => props.direction === 'up' ? '10px' : '0'};
  border-bottom-right-radius:${props => props.direction === 'down' ? '10px' : '0'};;
  &:hover {
    cursor: pointer;
    svg.arrow {
      fill: red;
    }
  }
`;