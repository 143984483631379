import { IGetReportDataResponse, useGetReportDataQuery } from '../../../queries/useGetReportDataQuery';
import React, { useEffect, useState } from 'react';

import BaseCohortsTable from '../../BaseCohortsTable/BaseCohortsTable';
import ChartContainer from '../ChartContainer/ChartContainer';
import CohortsTable from '../../CohortsTable/CohortsTable';
import { IFilterBarPayload } from '../../FilterBar/FilterBar';
import { IGetWidgetsResponse } from '../../../queries/useGetWidgetsQuery';
import Table from '../../../shared/Table/Table';
import { handleHeadlineHelper } from '../../../pages/Analytics/Analytics';
import { sharedAccountIdSelector } from '../../../store/shared/shared.selector';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

export interface IMonitorTableChartProps {
  widget: IGetWidgetsResponse,
  titleId?: number,
  filter?: IFilterBarPayload,
  cross_title?: boolean,
  extraFilters?: {
    query?: string;
  }
}

const MonitorTableContainer = styled.div`
  width: 100%;
  height: 65vh;
`

const MonitorTableChart = ({ widget, titleId, filter, cross_title = true, extraFilters }: IMonitorTableChartProps) => {
  const [groupByFilter, setGroupByFilter] = useState<string[] | undefined>();
  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>();
  const accountIdSelector = useSelector(sharedAccountIdSelector);

  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      report_id: widget?.report_id,
      dashboard_id: widget?.dashboard_id,
      title_id: widget?.title_id || titleId,
      days_back: filter?.daysBack || 60,
      date_from: (filter?.dateFrom && String(filter?.dateFrom)) || "-1",
      date_to: (filter?.dateTo && String(filter?.dateTo)) || "-1",
      country: filter?.country,
      platform: filter?.platform,
      source: filter?.source,
      group_by: groupByFilter,
      currency: currencyFilter,
      account_id: cross_title ? accountIdSelector : undefined,
      extra_filters: extraFilters
    }, `MonitorTableChart-
        ${currencyFilter && currencyFilter}-
        ${widget?.report_id}-${widget?.title_id || titleId}-
        ${filter?.dateFrom}-${filter?.daysBack}-
        ${filter?.dateTo}-${filter?.country}-
        ${filter?.platform}-${filter?.source}-
        ${extraFilters?.toString()}-${groupByFilter?.toString()}-
        ${cross_title && `account_id: ${accountIdSelector}`}`);

  const [monitorTableChartData, setMonitorTableChartData] = useState<{} | any[] | undefined>();
  const [reportData, setReportData] = useState<IGetReportDataResponse[]>();

  useEffect(() => {
    if (!widget || !getReportDataQueryResponse || getReportDataQueryResponse.isLoading) {
      return;
    }

    const { data } = getReportDataQueryResponse;

    if (JSON.stringify(reportData) === JSON.stringify(data)) {
      return;
    }

    setMonitorTableChartData(data);
    setReportData(data);
  }, [getReportDataQueryResponse])

  const handleGroupBy = (item: string | undefined) => {
    item ? setGroupByFilter([item]) : setGroupByFilter(undefined);
  }

  const handleCurrency = (item: string) => {
    setCurrencyFilter(item);
  }

  return <ChartContainer
    loading={getReportDataQueryResponse?.isLoading}
    headline={handleHeadlineHelper(widget?.headline, groupByFilter, currencyFilter)}
    groupBy={widget?.group_by || []}
    onGroupByChange={handleGroupBy}
    onCurrencyChange={handleCurrency}
    showCurrency={widget.use_currency}
   
    disableLegendFilter
  >
    <MonitorTableContainer>
      <BaseCohortsTable
        collection={monitorTableChartData as any} isLoading={getReportDataQueryResponse?.isLoading}
        titleId={titleId}
        ></BaseCohortsTable>
    </MonitorTableContainer>
  </ChartContainer>
};

export default MonitorTableChart;
