import { AxiosInstance, AxiosResponse } from "axios";

import { useAxiosAuthorized } from "../hooks/useAxiosAuthorized";
import { useQuery } from "react-query";

export type IChartTreeItem = {
  eco_topic_id: number;
  eco_topic_name: string;
  headline: string;
  report_id?: number;
  report_location: number;
  tooltip: string | null;
  topic_id?: number;
};

export interface IChartTreePayload {
  dashboard_id: number;
}

export type IChartTreeResponse = {
  [key: string]: IChartTreeItem[];
};

const getReportData = async (
  axios: AxiosInstance,
  payload: IChartTreePayload
): Promise<IChartTreeResponse> => {
  const { data } = await axios.get<
    IChartTreePayload,
    AxiosResponse<IChartTreeResponse>
  >(`/dashboard/${payload.dashboard_id}/chart-tree`);

  return data;
};

export const useGetReportDataQuery = (
  payload: IChartTreePayload,
  key: string
) => {
  const axios = useAxiosAuthorized();

  return useQuery<IChartTreeResponse, Error>(key, () => getReportData(axios, payload));
};
