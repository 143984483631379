import { AxiosInstance, AxiosResponse } from "axios";

import { useAxiosAuthorized } from "../hooks/useAxiosAuthorized";
import { useQuery } from "react-query";

const { REACT_APP_API_URL } = process.env;

export enum DropDownType {
  Platforms = "platforms",
  Sources = "sources",
  Countries = "countries",
  GroupBy = "group_by",
  Status = 'status'
}
export type DropdownRequestsPayload = {
  type: DropDownType;
  title_id?: number;
};

export type DropdownRequestsResponse = {
  source: string;
  country: string;
  platform: string;
  group_by: string;
};

const getDropdownValues = async (
  axios: AxiosInstance,
  payload: DropdownRequestsPayload
): Promise< { msg: string; result: DropdownRequestsResponse[]; status: string[]; } | undefined
> => {
  if (!payload?.title_id) {
    return undefined;
  }

  const { data } = await axios.get<
    DropdownRequestsPayload,
    AxiosResponse<{
      msg: string;
      result: DropdownRequestsResponse[];
      status: string[];
    }>
  >(`${REACT_APP_API_URL}/dashboard/${payload.type}`, {
    params: { title_id: payload.title_id },
  });

  return data;
};

export const useGetDropdownValues = (type: DropDownType, titleId?: number) => {
  const axios = useAxiosAuthorized();

  return useQuery<
    | {
        msg: string;
        result: DropdownRequestsResponse[];
        status: string[];
      }
    | undefined,
    Error
  >(`useGetDropdownValues-${type}-${titleId}`, () =>
    getDropdownValues(axios, { type, title_id: titleId })
  );
};
