import { AxiosInstance, AxiosResponse } from "axios";

export interface IGetAccountTitlesResponse {
  id: number,
  name: string,
  status: number,
  account_id: number,
  type: number,
  icon?: string
}

export class AccountTitlesService {
  public static axios: AxiosInstance;

  public static async getAccountTitles(accountId: number): Promise<IGetAccountTitlesResponse> {
    const { data } = await AccountTitlesService.axios.get<
      undefined,
      AxiosResponse<IGetAccountTitlesResponse>
    >(`/onboarding/user/${accountId}/titles`);

    return data;
  }
}

