import * as Yup from 'yup';

import { MixpanelEvent, trackMixpanelEvent } from '../../../../mixpanel';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { StyledBackButton, StyledNextButton } from '../GrowthSimulatorButtons/GrowthSimulatorButtons';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { EGoalType } from '../../../../store/growthSimulator/growthSimulator.state';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Switch from '@mui/material/Switch';
import { clone } from '../../../../helpers/extensions';
import styled from 'styled-components';
import { useFormik } from 'formik';

interface Props {
  onCancel?: () => void;
  onSave?: (values: DynamicCpi[]) => void;

  days: number;
  budget: number;
  dynamicCPI: DynamicCpi[] | undefined;
  goal: EGoalType | null;
}


const StyledGrowthSimulatorDetailsSegmentTableHeaderList = styled.ul<{goal: EGoalType | null}>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: ${({ goal }) => goal === EGoalType.BudgetGoal ? '20% 20% 20% 20%  calc(20% - 20px)' : 'repeat(3, 2fr) 1fr'} ;
  align-items: center;
  justify-items: center;
  text-align: center;
  grid-column-gap: 5px;
`

const StyledGrowthSimulatorDetailsSegmentTableHeaderListItem = styled.li`
  background: #46526e;
  width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e7e7e7;

    font-weight: normal;
    font-size: 1rem;
    padding: 5px 0;

    &:first-of-type {
      border-top-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
    }
`

const StyledGrowthSimulatorDetailsSegmentTableNumberInput = styled.input`
  width: 100%;
  background: #fff;
  border: 2px solid #58a4fd;
  color: #000;
  border-radius: 5px;
  text-align: center;
  height: 20px;
  &:focus {
      outline: none;
    }

  &:disabled {
    background: #616274;
    color: #d9d9d9;
  }

  &::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`

const StyledGrowthSimulatorDetailsSegmentTableBodyContainer = styled.div`
display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
`

const StyledGrowthSimulatorDetailsSegmentTableBodyList = styled.ul<{goal: EGoalType | null}>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: ${({ goal }) => goal === EGoalType.BudgetGoal ? '20% 20% 20% 20%  calc(20% - 20px)' : 'repeat(3, 2fr) 1fr'};
  text-align: center;
  grid-column-gap: 5px;
 
  grid-template-rows: repeat(1, 40px);
`

const StyledGrowthSimulatorDetailsSegmentTableBodyListItem = styled.li`
   display: flex;
    align-items: center;
    justify-content: center;
    background: #233048;
    padding: 0px 25px;
    height: 40px;
    max-height: 40px;

    &.error-invalid {
      outline: 1px solid red;
    }

    &:nth-of-type(5){
      &:hover{
        cursor: pointer;
        .MuiSvgIcon-root {
          opacity: 0.6;
        }
      }
    }
`

const StyledDeleteIcon = styled(DeleteIcon)`
  color: #fff;
`

const StyledGrowthSimulatorDetailsSegmentTableContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 10px;
`


const StyledTopArrow = styled(PlayArrowIcon)`
  font-size: 1rem !important;
  color: #777c99;
  transform: rotate(270deg);
  
`

const StyledDownArrow = styled(PlayArrowIcon)`
  font-size: 1rem !important;
  color: #777c99;
  transform: rotate(90deg);
`


const StyledGrowthSimulatorRouletteControl = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledRouletteInputContainer = styled.div`
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`

const StyledAddCircleOutlineButton = styled.button<{ disabled?: boolean }>`
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiSvgIcon-root {
    fill: white;
  }
  &:hover{
    cursor: pointer;
    opacity: 0.6;
  }
  opacity: ${({ disabled }) => disabled && '0.6'};
`

const StyledFooterControls = styled.div`
  margin-top: 15px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
`

const StyledControlButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const StyledSwitchContainer = styled.div`
    grid-column: 4 /span 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledSwitchLabel = styled.span`
    font-size: 1rem;
    color: #fff;
    font-weight: 500;
`

const StyledSwitchWrapper = styled.div`
    display: grid;
    grid-template-columns: 20% 20% 20% 20%  calc(20% - 20px);
    grid-column-gap: 5px;
`

const StyledSwitch = styled(Switch)`
    width: 100px !important;
    height: 45px !important;
  

    & .Mui-checked {
      transform: translateX(60px) !important;
    }

    .MuiSwitch-thumb {
      width: 25px !important;
      height: 25px !important;
    }

    .Mui-checked + .MuiSwitch-track {
      background: #d9d9d9 !important;
    }
`

type RouletteInputProps = {
  value: number;
  name?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  setFieldValue: any
  disabled?: boolean;
}
const RouletteInput = ({ value, name, onChange, onBlur, setFieldValue, disabled }: RouletteInputProps) => {

  return <StyledRouletteInputContainer>
    <StyledGrowthSimulatorDetailsSegmentTableNumberInput
      type="text" pattern="[0-9]*"
      value={value}
      onInput={({ target: { value: v } }: any) => setFieldValue(Number(v))}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
    <div>
      <StyledGrowthSimulatorRouletteControl
        onClick={() => setFieldValue(name, Number(value!) + 1)}
      >
        <StyledTopArrow></StyledTopArrow>
      </StyledGrowthSimulatorRouletteControl>
      <StyledGrowthSimulatorRouletteControl
        onClick={() => setFieldValue(name, Number(value!) - 1)}
      >
        <StyledDownArrow></StyledDownArrow>
      </StyledGrowthSimulatorRouletteControl>
    </div>
  </StyledRouletteInputContainer>
}

export type DynamicCpi = {
  day_from: number;
  day_to: number;
  cpi: number;
  budget: number;
}

const initial = {
  day_from: 0,
  day_to: 0,
  cpi: 0,
  budget: 0,
};

export const StyledGrowthSimulatorDetailsSegmentTableNumberLabel = styled.span`
      font-size: 1rem;
      color: white;
      padding: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
  `

const GrowthSimulatorDynamicCpiTable = ({ onCancel, onSave, days, budget, dynamicCPI, goal }: Props) => {
  const [automatic, setAutomatic] = useState(true);
  const [total, setTotal] = useState<number>(0)

  const validationSchema = Yup.array().of(Yup.object({
    day_from: Yup.number().max(days).min(1),
    day_to: Yup.number().max(days).min(1).when('day_from', ([day_from], schema) => {
      if (day_from) {
        return schema.min(day_from + 1);
      }

      return schema;
    }),
    cpi: Yup.number().required().min(0).notOneOf([0])
  }))

  const { values, handleChange, handleBlur, setFieldValue, setValues, submitForm, errors, touched } = useFormik({
    initialValues: dynamicCPI || [
      {
        day_from: 1,
        day_to: days,
        cpi: 0,
        budget: budget
      },
    ] as DynamicCpi[],
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values: DynamicCpi[]) => {
      onSave && onSave(values);
      onCancel && onCancel();
    }
  });

  const handleDeleteRow = (index: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setValues((prev: DynamicCpi[]) => {
      if (prev.length > 1) {
        prev.splice(index, 1);
      }
      return prev;
    })
  };


  const handleValueChange = ({ target: { value } }: ChangeEvent<{ value: number }>, index: number, property: keyof DynamicCpi): void => {
    let _values = clone(values);

    _values[index][property] = Number(value);

    for (let i = 0; i < _values.length; ++i) {
      let row = _values[i];

      if (i !== 0) {
        let previousRow = _values[i - 1];
        let previousRowDayTo = Number(previousRow?.day_to) || 0;

        row.day_from = previousRowDayTo + 1;

        if (i === (_values.length - 1)) {
          row.day_to = days;
        }
      }

      const automaticBudget = Math.round((((_values[i]?.day_to - _values[i]?.day_from) + 1) / days) * budget);

      if (automatic) {
        row.budget = automaticBudget;
      }

      setValues(_values);
    }
  }


  const handleAddRow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    setValues((rows: DynamicCpi[]) => {
      let _rows: DynamicCpi[] = JSON.parse(JSON.stringify(rows));

      _rows.forEach((r, index) => {
        if (!touched[index]?.day_to) {
          r.day_to = initial.day_to;
        }
      })

      return [..._rows, {
        day_from: initial.day_from,
        day_to: days,
        cpi: initial.cpi,
        budget: initial.budget,
      }]
    }, true)
  };

  useEffect(() => {
    setTotal(values.reduce((acc, value) => acc + (Number(value?.budget) || 0), 0));
  }, [values])

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onCancel && onCancel()
    trackMixpanelEvent(MixpanelEvent.ARPPUCancel);
  };

  return (
    <>
      <StyledGrowthSimulatorDetailsSegmentTableContainer>
        {goal === EGoalType.BudgetGoal &&<StyledSwitchWrapper>
          <StyledSwitchContainer>
            <StyledSwitchLabel>Automatic</StyledSwitchLabel>
            <StyledSwitch checked={automatic} onChange={(event, checked) => setAutomatic(checked)} />
          </StyledSwitchContainer>
        </StyledSwitchWrapper>}
        <header>
          <StyledGrowthSimulatorDetailsSegmentTableHeaderList goal={goal}>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
              Day from
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
              Day to
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
              CPI
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
            {goal === EGoalType.BudgetGoal && <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
              Budget
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>}
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem />
          </StyledGrowthSimulatorDetailsSegmentTableHeaderList>
        </header>
        <StyledGrowthSimulatorDetailsSegmentTableBodyContainer>
          {values && values.map((v: DynamicCpi, index: number) =>
            <StyledGrowthSimulatorDetailsSegmentTableBodyList goal={goal} key={`GrowthSimulatorDetailsSegmentTable-index-${index}`}>
              <StyledGrowthSimulatorDetailsSegmentTableBodyListItem
                className={errors[index] && errors?.[index]?.day_to && 'error-invalid'}
              >
                <RouletteInput
                  name={`[${index}].day_from`}
                  value={v?.day_from}
                  onChange={(event) => handleValueChange(event, index, 'day_from')}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled
                />
              </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              <StyledGrowthSimulatorDetailsSegmentTableBodyListItem
                className={errors[index] && errors?.[index]?.day_to && 'error-invalid'}
              >
                <RouletteInput
                  name={`[${index}].day_to`}
                  value={v?.day_to}
                  onChange={(event) => handleValueChange(event, index, 'day_to')}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled={index === values.length - 1}
                />
              </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              <StyledGrowthSimulatorDetailsSegmentTableBodyListItem
                className={errors[index] && errors?.[index]?.cpi && 'error-invalid'}
              >
                <StyledGrowthSimulatorDetailsSegmentTableNumberLabel>
                  $
                </StyledGrowthSimulatorDetailsSegmentTableNumberLabel>
                <StyledGrowthSimulatorDetailsSegmentTableNumberInput
                  name={`[${index}].cpi`}
                  value={v?.cpi}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                />
              </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              {goal === EGoalType.BudgetGoal && <StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
                <StyledGrowthSimulatorDetailsSegmentTableNumberLabel>
                  $
                </StyledGrowthSimulatorDetailsSegmentTableNumberLabel>
                <StyledGrowthSimulatorDetailsSegmentTableNumberInput
                  name={`[${index}].budget`}
                  value={v?.budget}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={automatic}
                >
                </StyledGrowthSimulatorDetailsSegmentTableNumberInput>
              </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>}
              <StyledGrowthSimulatorDetailsSegmentTableBodyListItem onClick={handleDeleteRow(index)}>
                <StyledDeleteIcon />
              </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            </StyledGrowthSimulatorDetailsSegmentTableBodyList>
          )}

        </StyledGrowthSimulatorDetailsSegmentTableBodyContainer>
      </StyledGrowthSimulatorDetailsSegmentTableContainer>

      {goal === EGoalType.BudgetGoal && <StyledSwitchWrapper>
        <StyledSwitchContainer style={{ color: total !== budget ? '#FF0000' : '#fff' }}>
          <StyledSwitchLabel style={{ color: 'inherit' }}>Total</StyledSwitchLabel>
          <StyledSwitchLabel style={{ color: 'inherit' }}>$ {total}</StyledSwitchLabel>
        </StyledSwitchContainer>
      </StyledSwitchWrapper>}

      <StyledFooterControls>
        <StyledAddCircleOutlineButton onClick={handleAddRow}>
          <AddCircleOutlineIcon fontSize='large' />
        </StyledAddCircleOutlineButton>
        <StyledControlButtonsWrapper>
          <StyledBackButton onClick={handleCancel}>
            Cancel
          </StyledBackButton>
          <StyledNextButton disabled={Boolean(Object.keys(errors).length)} onClick={() => submitForm()}>
            Save
          </StyledNextButton>
        </StyledControlButtonsWrapper>
      </StyledFooterControls>
    </>

  );
};

export default GrowthSimulatorDynamicCpiTable;
