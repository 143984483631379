import { ReactElement, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import Button from '@mui/material/Button';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { device } from "../../../../helpers/device";
import { getGrowthSimulatorWidgets } from "../../../../store/growthSimulator/growthSimulator.actions";
import styled from 'styled-components';
import { trackRetentionNext } from "../../../../mixpanel";
import { useDispatch } from "react-redux";

interface Props {
  steps: ReactElement[];

  onSubmit: () => void;
  onNext?: (step: number) => void;
  onBack?: (step: number) => void;

  className?: string;
}

const StyledGrowthSimulatorWizardContainer = styled.section`
  background: #606077;
  border-radius: 35px;
  /* width: 50%; */
  /* height: 80%; */
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 400px;
`

const StyledGrowthSimulatorWizardInsideContainer = styled.div`
    background: #1A273F;
    margin: 25px;
    border-radius: 25px;
    width: calc(100% - 50px);
    /* height: calc(100% - 50px); */
    box-shadow: inset 4px 4px 9px #000000d9;
    border: 1px solid #212239;
    display: grid;
    grid-template-rows: 80% 20%;
    grid-template-columns: 100%;
`

const StyledBackDoubleArrowIcon = styled(DoubleArrowIcon)`
    transform: rotate(180deg);
`

const StyledBackButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #a6a6a6 !important;
    color: #262742 !important;
`

const StyledNextButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #5ea8fd !important;
  color: #262742 !important;
`

const StyledGrowthSimulatorWizardFooter = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const StyledGrowthSimulatorStepsContainer = styled.main`
  display: flex;
  box-sizing: border-box;
`
const INITIAL_STATE = 1;

const GrowthSimulatorWizard = ({ steps, onSubmit, onBack, onNext, className }: Props) => {
  const { pathname } = useLocation();
  const [step, setStep] = useState<number>(INITIAL_STATE);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGrowthSimulatorWidgets(2000));
  }, [])
  const handleBackClick = () => {
    onBack && onBack(step);
  }

  const handleNextClick = () => {
    trackRetentionNext();
    if (step === steps.length) {
      onSubmit && onSubmit();
    } else {
      onNext && onNext(step);
    }
  }

  useEffect(() => {
    const step = pathname
      .split('/')
      .pop()!
      .split('-')!
      .pop();

    setStep(Number(step));
  }, [pathname])

  return (
    <StyledGrowthSimulatorWizardContainer className={className}>
      <StyledGrowthSimulatorWizardInsideContainer>
        <StyledGrowthSimulatorStepsContainer>
          <Switch>
            {steps}
          </Switch>
        </StyledGrowthSimulatorStepsContainer>
       {/* <StyledGrowthSimulatorWizardFooter>
          <StyledBackButton onClick={handleBackClick}>
            <StyledBackDoubleArrowIcon></StyledBackDoubleArrowIcon>
            <span> Back </span>
          </StyledBackButton>
          <StyledNextButton onClick={handleNextClick}>
            <span> Next </span>
            <DoubleArrowIcon></DoubleArrowIcon>
          </StyledNextButton>
        </StyledGrowthSimulatorWizardFooter> */}
      </StyledGrowthSimulatorWizardInsideContainer>
    </StyledGrowthSimulatorWizardContainer>
  );
};

export default styled(GrowthSimulatorWizard)`
    /* box-sizing: border-box;
    display: flex; */
    @media ${device.laptopL} { 
      font-size: 14px;

      /* width: 60%; */
    }

    @media ${device.laptop} { 
      font-size: 12px;

      /* width: 70%; */
    }
`;
