import { goalSelector, growthSimulatorActiveTabSelector, growthSimulatorGenreSelector, povSelector } from '../../../../store/growthSimulator/growthSimulator.selector';
import { EGoalType, EPointOfViewType } from '../../../../store/growthSimulator/growthSimulator.state';

import HomeIcon from '@mui/icons-material/Home';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import MonetizationPersonaAvatar from '../../../../assets/monetization_persona_avatar.png';
import ProductPersonaAvatar from '../../../../assets/product_persona_avatar.png';
import UserAcquisitionAvatar from '../../../../assets/user_acquisition_avatar.png';
import GrowthSimulatorDropDown from '../../../../components/Dropdown/GrowthSimulatorDropDown';
import GrowthSimulatorConnectivity from '../GrowthSimulatorConnectivity/GrowthSimulatorConnectivity';
import { change, getFormValues } from 'redux-form';
import { GrowthSimulatorFormData } from '../../models/growthSimulator.models';
import { useEffect } from 'react';
import { fistLetterCapitalize } from '../../../ScenarioBuilder/helpers/helpers';

interface Props {
  handleHomeIconClick?: () => void;
  handleGoalTypeChange: (goal: EGoalType) => void;
  showHomeIcon: boolean;
  showMenuIcon: boolean;
  showSubtitle: boolean;
}

const StyledGrowthSimulatorWizardAvatarHeader = styled.header`
  background: #1c2942;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
`

const StyledPersonaAvatarListContainer = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledSelectionContainer = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
`


const StyledSelectedPersonaAvatarContainer = styled.div`
    position: relative;
    left: 15px;
    background: #ffc301;
    border-radius: 40px;
    padding: 1px;
    transform: scale(1.5);
    top: 10px;

`

const StyledPersonaAvatarContainer = styled.div`
    box-shadow: inset 0px 2px 4px #000000;
    border: 1px solid #FFFFFF52;
    height: 48px;
    width: 48px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1A273F;
    img { 
      width: 100%;
    }
`

const StyledSelectedAvatarBadgeTitleText = styled.p`
    background: #ffc301;
    font-weight: 500;
    padding: 5px 15px 5px 40px;
    border-radius: 15px;
    margin: 0;
    font-size: 1.2rem;
`

const StyledNavigationContainer = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-left: auto;
`

const StyledHomeIcon = styled(HomeIcon)`
  color: #b3b6bb;
`

const StyledHomeIconButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
`

const StyledSelectedAvatarBadgeSubTitleText = styled.p<{ showSubtitle: boolean }>`
  margin: 0;
  box-shadow: inset 0px 2px 4px #000000;
  border: 1px solid #FFFFFF52;
  border-radius: 23px;
  padding: 5px 15px 5px 35px;
  color: #FFFFFF;
  background: #303c51;
  visibility: ${({ showSubtitle }) => showSubtitle ? 'visible' : 'hidden'};
`

const StyledSelectedAvatarBadgeTextContainer = styled.div`
  margin-top: 20px;
`

type AvatarMapper = {
  [key in EPointOfViewType]: string
};

const avatarMapper: AvatarMapper = {
  [EPointOfViewType.UserAcquisition]: UserAcquisitionAvatar,
  [EPointOfViewType.Monetization]: MonetizationPersonaAvatar,
  [EPointOfViewType.Product]: ProductPersonaAvatar
}
const GrowthSimulatorAvatarHeader = ({
  showHomeIcon,
  showMenuIcon,
  showSubtitle,
  handleHomeIconClick,
  handleGoalTypeChange
}: Props) => {
  const pov = useSelector(povSelector);
  const goal = useSelector(goalSelector);
  const tab = useSelector(growthSimulatorActiveTabSelector);
  const formValues = useSelector(state => getFormValues('growthSimulatorForm')(state)) as { members: GrowthSimulatorFormData[]; };
  const genre = useSelector(growthSimulatorGenreSelector);

  const sassGoalForm: EGoalType | undefined
    = useSelector(state => 
      (getFormValues('growthSimulatorForm')(state) as { members: GrowthSimulatorFormData[]; })
      ?.members.find(({ id }) => id === tab)?.goal);

  const tabIndex = formValues?.members.findIndex(({ id }) => id === tab)!;
  const dispatch = useDispatch();
  
  const goalTypeChangeHandler = (goal: EGoalType) => {
    dispatch(change('growthSimulatorForm', `members[${tabIndex}].goal`, goal));
    handleGoalTypeChange?.(goal);
  }
  return <StyledGrowthSimulatorWizardAvatarHeader>
    <StyledPersonaAvatarListContainer>
      <StyledSelectionContainer>
        <StyledSelectedPersonaAvatarContainer>
          <StyledPersonaAvatarContainer>
            <img src={pov ? avatarMapper[pov] : ''} alt="Product" />
          </StyledPersonaAvatarContainer>
        </StyledSelectedPersonaAvatarContainer>
        <StyledSelectedAvatarBadgeTextContainer>
          <StyledSelectedAvatarBadgeTitleText>{fistLetterCapitalize(genre)}</StyledSelectedAvatarBadgeTitleText> 
          <StyledSelectedAvatarBadgeSubTitleText showSubtitle={showSubtitle}>{sassGoalForm}</StyledSelectedAvatarBadgeSubTitleText>
        </StyledSelectedAvatarBadgeTextContainer>
        {showMenuIcon && sassGoalForm && <GrowthSimulatorDropDown value={sassGoalForm} handleGoalTypeChange={goalTypeChangeHandler} />}
      </StyledSelectionContainer>
      <StyledNavigationContainer>

        {/* <StyledPersonaAvatarContainer>
          <img src={MonetizationPersonaAvatar} alt="Monetization" />
        </StyledPersonaAvatarContainer>

        <StyledPersonaAvatarContainer>
          <img src={ProductPersonaAvatar} alt="Product" />
        </StyledPersonaAvatarContainer> */}

        <GrowthSimulatorConnectivity/>
        {showHomeIcon && <StyledHomeIconButton onClick={() => {
          handleHomeIconClick && handleHomeIconClick();
        }}>
          <StyledHomeIcon></StyledHomeIcon>
        </StyledHomeIconButton>}
      </StyledNavigationContainer>
    </StyledPersonaAvatarListContainer>
  </StyledGrowthSimulatorWizardAvatarHeader>
};

export default GrowthSimulatorAvatarHeader;
