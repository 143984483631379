import { AxiosInstance, AxiosResponse } from "axios";

import { useAxiosAuthorized } from "../hooks/useAxiosAuthorized";
import { useQuery } from "react-query";

export interface IGetAccountTitlesResponse {
    id: number,
    name: string,
    status: number,
    account_id: number,
    type: number,
    icon?: string
}

const getAccountTitles = async (axios: AxiosInstance, accountId: number| undefined): Promise<IGetAccountTitlesResponse[] | undefined> => {
  if (!accountId) {
    return undefined;
  }

  const { data } = await axios.get<undefined, AxiosResponse<IGetAccountTitlesResponse[]>>(`/onboarding/user/${accountId}/titles`);

  return data;
};

export const useGetAccountTitlesQuery = (accountId: number | undefined) => {
  const axios = useAxiosAuthorized();
  
  return useQuery<IGetAccountTitlesResponse[] | undefined, Error>(`useGetAccountTitles-${accountId}`, () => getAccountTitles(axios, accountId));
};
