import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import React from 'react';
import RetentionTable from '../../components/RetentionTable/RetentionTable';
import styled from 'styled-components';

export interface IRetentionProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
}

export const StyledRetentionContainer = styled.div`
  margin: 0px 30px 30px 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  height: calc(100vh - 100px);
`

const Retention = ({ title, page }: IRetentionProps) => {
  return <StyledRetentionContainer>
    <RetentionTable title={title} page={page}></RetentionTable>
  </StyledRetentionContainer>
};

export default Retention;
