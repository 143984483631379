import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from 'react';
import { money, percent, value } from "../../helpers/formatters";

import { Divider } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Gauge from '../Gauge/Gauge';
import GroupsIcon from '@mui/icons-material/Groups';
import { IGetTopCohortsReportDataResponse } from "../../queries/useGetTopCohortsReportDataQuery";
import UsersBadge from "../UsersBadge/UsersBadge";
import styled from 'styled-components';

export interface ITopCohortsCardProps {
  className?: string,
  data: IGetTopCohortsReportDataResponse
}

const TopCohortsCardContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-left: -40px;
      
  h3, p {
    margin: 0;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`

const TopCohortsCardGaugeWrapper = styled.section`
    position: relative;
    left: 40px;
    z-index: 11;
`

const TopCohortsCardContentWrapper = styled.aside`
  color: #E7E7E7;
  position: relative;
  top: 45px;
  z-index: 10;
  width: 100%;
  & > div {
    user-select: none;
  }
`

const TopCohortsCardContent = styled.div`
  background: #212239 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 6px #00000073;
  border-radius: 20px;
`

const TopCohortsCardHeader = styled.header`
    display: flex;
    flex-direction: row;

    padding: 10px calc(5vw - 45px) 10px calc(5vw - 20px);

    background: #2E2F4B 0% 0% no-repeat padding-box;
    box-shadow: inset 3px 3px 6px #00000073;
    border-radius: 20px 20px 0 0;

    h3 {
      border-right: 1px solid #A8A8A8;
      padding-right: 10px;
      margin-right: 10px;
      white-space: nowrap;
      font-weight: normal;
      opacity: 0.8;
      font-size: 1rem;
    }
`

const TopCohortsCardMain = styled.main`
  white-space: nowrap;

  aside {
    position: relative;
  }

  & > ul {
    border-top: 1px solid #A8A8A8;
    position: relative;
    padding-left: 15%;

    &:first-of-type {
      display: grid;
      grid-template-columns: 1fr 0.6fr 1.4fr;


      li {
        padding: 5px;
        margin: 5px 5px 5px 0;

        border-right: 1px solid #A8A8A8;
        padding-right: 15px;
      }

      li:nth-child(3n) {
        border-right: none;
      }
    }
  }
`

const TopCohortsCardFooter = styled.footer`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 10;

    button {
      cursor: pointer;
      background: #43445E 0% 0% no-repeat padding-box;
      box-shadow: 1px 1px 2px #000000B5;
      border: none;
      border-radius: 0 0 10px 10px;
      padding: 0 15px;

    svg {
      color: #fff;
      opacity: 0.5;
    }
  }
`

const ValuesContainer = styled.span`
  color: #09FF00;
  font-weight: bold;
`;

const HorizontalDivider = styled.span`
  border-right: 1px solid #A8A8A8;
  width: 0px;
  display: inline-block;
  margin: 0 auto;
  height: 100%;
`

const AvgContainer = styled.span<{ positive: boolean }>`
  color: ${({ positive }) => positive ? '#09FF00' : '#FF0000'};
`

//TODO(PPavlov): Refactor
const AnimatedRow = ({ title, value, avg, positive, className }: { className?: string, title: string, positive: boolean, value: number | string, avg: number | string }) => {
  const [hovered, setHovered] = useState(false);

  return <ul
    className={className}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
  >
    <li>{title}</li>
    <AnimatePresence>
      {hovered ? <motion.li
        transition={{ delay: 0.1 }}
        initial={{ width: 0, overflow: 'hidden', opacity: 0 }}
        animate={{ width: '100%', opacity: 1 }}
        exit={{ width: 0, overflow: 'hidden', opacity: 0 }}
      >
        <div>
          <AvgContainer positive={positive}>{avg}</AvgContainer>
        </div>
      </motion.li> : <li> <HorizontalDivider /></li>
      }
    </AnimatePresence>
    <li>
      <ValuesContainer>
        {value}
      </ValuesContainer>
    </li>
  </ul>
}

const StyledAnimatedRow = styled(AnimatedRow)`
    max-height: 1rem;

    display: grid;
    grid-template-columns: 0.6fr 0.8fr 0.6fr;
    width: 100%;
    height: 100%;

    padding: 10px 0 !important;
    text-align: center;

    border-top: 1px solid #A8A8A8;

    & > li {
      & >  div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        border-left: 1px solid #A8A8A8;
        border-right: 1px solid #A8A8A8;
        
        overflow: hidden;
        white-space: nowrap;
    }
  }
   
`

export const TopCohortsCard = ({ className, data }: ITopCohortsCardProps) => {
  const [expanded, setExpanded] = useState(false);

  return <TopCohortsCardContainer>
    <TopCohortsCardGaugeWrapper>
      <Gauge
        width={120}
        radius={120 / 2}
        size={15}
        value={data?.simpool_score}
        container={{
          height: 100,
          width: 100
        }}
      />
    </TopCohortsCardGaugeWrapper>
    <TopCohortsCardContentWrapper>
      <TopCohortsCardContent>
        <TopCohortsCardHeader>
          <h3>{data?.cohort}</h3>
          <UsersBadge users={data?.users}/>
        </TopCohortsCardHeader>
        <TopCohortsCardMain>
          <ul>
            <li>LTV30</li>
            <li>
              <ValuesContainer>
                {money(data?.ltv!)}
              </ValuesContainer>
            </li>
            <li>{money(data?.ltv! - data?.avg_ltv!, 'always')} vs avg</li>
          </ul>
          <AnimatePresence>
            {expanded ? <motion.aside
              transition={{ delay: 0.1 }}
              initial={{ height: 0, overflow: 'hidden', opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, overflow: 'hidden', opacity: 0 }}
            >
              <StyledAnimatedRow
                value={money(data?.arppu30)}
                avg={`(${money(data?.arppu30! - data?.avg_arppu, 'always')} vs avg)`}
                positive={(data?.arppu30! - data?.avg_arppu) > 0}
                title="ARPPU30"
              />

              <StyledAnimatedRow
                value={data?.apds}
                avg={`(${value((data?.apds - data?.avg_apds), 'always')} vs avg)`}
                title="APDS"
                positive={(data?.apds - data?.avg_apds) > 0}
              />

              <StyledAnimatedRow
                value={percent(data?.conv)}
                avg={`(${percent((data?.conv - data?.avg_conv), 'always')} vs avg)`}
                title="CONVERSION"
                positive={(data?.conv - data?.avg_conv) > 0}
              />
            </motion.aside> : null}
          </AnimatePresence>
        </TopCohortsCardMain>
      </TopCohortsCardContent>

      <TopCohortsCardFooter>
        <button onClick={() => setExpanded((exp) => !exp)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
      </TopCohortsCardFooter>
    </TopCohortsCardContentWrapper>
  </TopCohortsCardContainer >
};

export default TopCohortsCard;
