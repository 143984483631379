import { useCallback, useEffect } from "react";
import styled from "styled-components";
import { SimpoolPrimaryButton } from "../../../components/Buttons/Buttons";
import InputWithArrows from "./InputWithArrows";
import { GrowthSimulatorFormik } from "./types";

interface MainLineProps {
  className?: string;
  formikValues?: GrowthSimulatorFormik | any;
  formikHandler?: any;
  onSubmit?: () => Promise<any>;
}

const MainLine = ({ className, formikValues, formikHandler, onSubmit }: MainLineProps): JSX.Element => {
  const calculatePaidInstalls = (dailyInstalls: string, days: string) => {
    const parsedDailyInstalls = parseInt(dailyInstalls);
    const parsedDays = parseInt(days);
    return parsedDailyInstalls * parsedDays;
  };

  const calculateTotalInstalls = (dailyInstalls: string, days: string, organic: string) => {
    const parsedDailyInstalls = parseInt(dailyInstalls);
    const parsedDays = parseInt(days);
    const parsedOrganic = parseInt(organic) / 100;
    return (parsedDailyInstalls * parsedDays) * (1 + parsedOrganic);
  };

  const calculateOrganicInstalls = (totalinstalls: any, payedInstalls: any) => {
    const parsedTotalInstalls = parseInt(totalinstalls);
    const parsedPayedInstalls = parseInt(payedInstalls);
    return parsedTotalInstalls - parsedPayedInstalls;
  }

  const calculateTotalMarketingConst = (paidInstalls: number, cpi: number) => {
    return paidInstalls * cpi;
  };

  const calculatedCpi = (cpi: number, organic: string, ) => {
    const parsedOrganic = parseInt(organic) / 100;
    const result = (cpi * (1 - (parsedOrganic / (1 + parsedOrganic)))).toFixed(2);
    return Number(result);
  };

  

  

  const web2Retention = [
    '1',
    '90',
    '7',
    '120',
    '14',
    '150',
    '30',
    '180',
    '60',
    '365',
  ];

  //PaidInstalls Calculation
  useEffect(() => {
    if (formikValues?.headLine?.days && formikValues?.headLine?.dailyInstals) {
      const result = calculatePaidInstalls(formikValues?.headLine?.dailyInstals, formikValues?.headLine?.days);
      formikHandler && formikHandler('execute.paidInstalls', result);
    }
  }, [formikValues?.headLine?.dailyInstals, formikValues?.headLine?.days, formikHandler]);

   //TotalInstalls Calculation
   useEffect(() => {
    if (formikValues?.headLine?.days && formikValues?.headLine?.dailyInstals) {
      const result = calculateTotalInstalls(
        formikValues?.headLine?.dailyInstals,
        formikValues?.headLine?.days,
        formikValues?.headLine?.organic
      );
      formikHandler && formikHandler('execute.totalInstalls', result);
    }
  }, [formikValues?.headLine?.dailyInstals, formikValues?.headLine?.days, formikHandler, formikValues?.headLine?.organic]);

   //Organic installs Calculation
   useEffect(() => {
      if(formikValues?.execute?.totalInstalls && formikValues?.execute?.paidInstalls) {
        const result = calculateOrganicInstalls(formikValues?.execute?.totalInstalls, formikValues?.execute?.paidInstalls);
        if( result ) {
          formikHandler && formikHandler('execute.organicInstalls', result);
        }
      }
   }, [formikValues?.execute?.totalInstalls, formikValues?.execute?.paidInstalls, formikHandler])

   //Organic installs Calculation
  useEffect(() => {
    if (formikValues?.execute?.paidInstalls &&formikValues?.headLine?.cpi) {
      const result = calculateTotalMarketingConst(
        formikValues?.execute?.paidInstalls as number,
        formikValues?.headLine?.cpi as number
      );
      if( result ) {
        formikHandler && formikHandler('execute.totalMarketingCost', `$${result}`);
      }  
    }
  }, [formikValues?.execute?.paidInstalls, formikValues?.headLine?.cpi, formikHandler]);

  //eCPI calculation listener 
  useEffect(() => {
    if (formikValues?.headLine?.cpi && formikValues?.headLine?.organic) {
      const result = calculatedCpi(
        formikValues?.headLine?.cpi as number,
        formikValues?.headLine?.organic as string
      );
      if( result ) {
        formikHandler && formikHandler('execute.eCPI', result);
      }  
    }
  }, [formikValues?.headLine?.cpi, formikValues?.headLine?.organic, formikHandler]);

  //Total web2players
  const calculateTotalWeb2Players = (dailyInstalls: string, days: string, web2web3conversion: string, organic: string) => {
    const parsedDailyInstalls = parseInt(dailyInstalls);
    const parsedDays = parseInt(days);
    const parsed2web3conversion = parseInt(web2web3conversion) / 100;
    const parsedOrganic = parseInt(organic) / 100;
    return parsedDailyInstalls * (1 + parsedOrganic) * parsedDays * (1 - parsed2web3conversion);
  };

  useEffect(() => {
    if (formikValues?.headLine?.dailyInstals && formikValues?.headLine?.organic && formikValues?.headLine?.days && formikValues?.headLine?.web2web3conversion) {
      const result = calculateTotalWeb2Players(
        formikValues?.headLine?.dailyInstals as string,
        formikValues?.headLine?.days as string,
        formikValues?.headLine?.web2web3conversion as string,
        formikValues?.headLine?.organic as string
        
      );
     
      if( result ) {
        formikHandler && formikHandler('execute.totalWeb2Players', result);
      }  
    }
  }, [formikValues?.headLine?.dailyInstals, formikValues?.headLine?.organic, formikValues?.headLine?.days, formikValues?.headLine?.web2web3conversion, formikHandler]);

  //Total web3players
  const calculateTotalWeb3Players = (dailyInstalls: string, days: string, web2web3conversion: string, organic: string) => {
    const parsedDailyInstalls = parseInt(dailyInstalls);
    const parsedDays = parseInt(days);
    const parsed2web3conversion = parseInt(web2web3conversion) / 100;
    const parsedOrganic = parseInt(organic) / 100;
    return parsedDailyInstalls * (1 + parsedOrganic) * parsedDays * parsed2web3conversion;
  };

  useEffect(() => {
    if (formikValues?.headLine?.dailyInstals && formikValues?.headLine?.organic && formikValues?.headLine?.days && formikValues?.headLine?.web2web3conversion) {
      const result = calculateTotalWeb3Players(
        formikValues?.headLine?.dailyInstals as string,
        formikValues?.headLine?.days as string,
        formikValues?.headLine?.web2web3conversion as string,
        formikValues?.headLine?.organic as string
        
      );
     
      if( result ) {
        formikHandler && formikHandler('execute.totalWeb3Players', result);
      }  
    }
  }, [formikValues?.headLine?.dailyInstals, formikValues?.headLine?.organic, formikValues?.headLine?.days, formikValues?.headLine?.web2web3conversion, formikHandler]);

  const generateRetentionForm = (name: string) => {
    const componentArray = [];
    for (let index = 0; index < web2Retention.length; index+=2) {
      componentArray.push( (
        <div className="row" key={`${name}-retention-${index}`}>
          <div className="col">
            <label htmlFor="">{web2Retention[index]} Day</label>
            <InputWithArrows value={formikValues?.[name]?.[web2Retention[index]]} formikHandler={formikHandler} name={`${name}.${web2Retention[index]}`}/>
          </div>
          <div className="col">
            <label htmlFor="">{web2Retention[index + 1]} Day</label>
            <InputWithArrows value={formikValues?.[name]?.[web2Retention[index + 1]]} formikHandler={formikHandler} name={`${name}.${web2Retention[index + 1]}`}/>
          </div>
        </div>
      )
    );
    }
    return componentArray;
  }
  return (
    <div className={className}>
      <div className="apds-1">
        <div className="title">Web 2 retention</div>
        <div className="body">
          { generateRetentionForm('web2_retention_anchors')}
        </div>
        <div className="footer">
          <div className="apds">
            <div className="input-row">
              <label htmlFor="">APDS</label>
              <input value={formikValues?.apds?.web2} readOnly name={'apds.web2'} type="text"/>
            </div>
          </div>
        </div>
      </div>
      <div className="apds-2">
        <div className="title">Web 3 retention</div>
          <div className="body">
          { generateRetentionForm('web3_retention_anchors')}
          </div>
          <div className="footer">
            <div className="apds">
              <div className="input-row">
                <label htmlFor="">APDS</label>
                <input value={formikValues?.apds?.web3} readOnly name={'apds.web3'} type="text"/>
              </div>
            </div>
          </div>
      </div>
      <div className="apds-3">
        <div className="body simple">
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">Total installs</label>
              <input
                readOnly
                type="text"
                className="text"
                value={(formikValues?.execute?.totalInstalls as number).toFixed(2)}
              />
            </div>
          </div>
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">Paid installs</label>
              <input
                readOnly
                type="text"
                className="text"
                value={formikValues?.execute?.paidInstalls}
              />
            </div>
          </div>
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">Organic installs</label>
              <input
                readOnly
                type="text"
                className="text"
                value={formikValues?.execute?.organicInstalls}
              />
            </div>
          </div>
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">Total marketing cost</label>
              <input
                readOnly
                type="text"
                className="text"
                value={formikValues?.execute?.totalMarketingCost}
              />
            </div>
          </div>
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">eCPI</label>
              <input
                readOnly
                type="text"
                className="text"
                value={`$${formikValues?.execute?.eCPI}`}
              />
            </div>
          </div>
          <div className="separator"></div>
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">Total web2 players</label>
              <input
                readOnly
                type="text"
                className="text"
                // value={
                //   calculateTotalWeb2Players(
                //     formikValues?.headLine?.dailyInstals,
                //     formikValues?.headLine?.days,
                //     formikValues?.headLine?.web2web3conversion
                //     )
                //   }
                value={formikValues?.execute?.totalWeb2Players}
              />
            </div>
          </div>
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">Max web2 DAU</label>
              <input readOnly type="text" className="text" value={formikValues?.execute?.maxWeb2Dau}/>
            </div>
          </div>
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">Total web3 players</label>
              <input type="text" 
                readOnly
                className="text" 
                value={formikValues?.execute?.totalWeb3Players}
                />
            </div>
          </div>
          <div className="row singlecol">
            <div className="col">
              <label htmlFor="">Max web3.0 DAU</label>
              <input type="text" className="text" value={formikValues?.execute?.maxWeb3Dau} readOnly/>
            </div>
          </div>
        </div>
        <div className="execute-wrapper">
          <SimpoolPrimaryButton onClick={() => onSubmit && onSubmit()}>Execute</SimpoolPrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default styled(MainLine)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 32%);
  grid-gap: 15px;
  background-color: #43445F;
  padding: 20px;
  /* height: 600px; */
  margin-top: 15px;
  color: white;
  border-radius: 15px;
  [class^="apds"] {
    /* height: 304px; */
    
    width: 100%;
    /* border: 1px solid red; */
    display: grid;
    /* grid-template-rows: 48px auto 40px; */
    grid-template-rows: auto;
    border-radius: 15px;
    overflow: hidden;
    .title {
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2E2F4B;
    }
    .footer { 
      border-top: 1px solid rgb(73,74,93);
      background: #2E2F4B 0% 0% no-repeat padding-box;
      box-shadow: inset 3px 3px 6px #00000073;
      display: flex;
      padding-left: 15px;
      align-items: center;
      .apds{
        display: flex;
        align-items: center;
        height: 40px;
        .input-row {
          height: 30px;
          display: flex;
          align-items: center;
          label {
            margin-right: 15px;
            
          }
          input {
            border-radius: 15px;
            /* height: 30px; */
            height: 100%;
            padding-left: 15px;
            text-align: center;
            &:read-only {
              background-color: #fffee0;
              cursor: default;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .separator{
      height: 20px;
    }
    .body{
      background: #212238;
      padding: 10px;
      display: grid;
      grid-template-rows: 50px 50px 50px 50px 50px;
      /* grid-template-rows:auto ; */
      &.simple {
        border-radius: 15px;
        /* grid-template-rows:minmax(40px, auto) ; */
        grid-template-rows: repeat(10, 40px);
        
      }
      .row {
        /* border: 1px solid white; */
        display: grid;
        grid-template-columns: 1fr 1fr;
        &.singlecol {
          display: grid;
          grid-template-columns: 1fr;
          .col {
            /* padding: 0; */
            grid-template-columns: auto 240px;
          }
        }
        .col {
          display: grid;
          grid-template-columns: auto minmax(120px, 200px);;
          padding: 8px;
          grid-gap: 15px;
          /* align-items: center; */
          .text {
            border-radius: 15px;
            border: 0;
            outline: 1px solid grey;
            outline-offset: -1px;
            padding-left: 15px;
          }
          label {
            display: grid;
            align-self: center;
            margin-left: 15px;
          }
          input {
            &:read-only {
              background-color: #fffee0;
              cursor: default;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }

  .execute-wrapper{
    display: flex;
    justify-content: center;
    padding-top: 10px;
    /* height: 40px; */
    button {
      /* width: 50%; */
     &:focus {
      outline: none;
     }
    }
  }
`