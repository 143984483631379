import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { MixpanelEvent, trackMixpanelEvent } from '../../../../mixpanel';
import { addARPPUValues, addARPPUWeightedAverage } from '../../../../store/growthSimulator/growthSimulator.actions';
import { ARPPUTableValues, tooltipSelectorById } from '../../../../store/growthSimulator/growthSimulator.selector';
import { StyledBackButton, StyledNextButton } from '../GrowthSimulatorButtons/GrowthSimulatorButtons';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useFormik } from 'formik';
import { change } from 'redux-form';
import MUITooltip from '../../../../components/MUITooltip/MUITooltip';
import { useGetApdsQuery } from '../../../../queries/useGetApdsQuery';
import { NumericFormatType } from '../../models/growthSimulator.models';
import { StyledGrowthSimulatorDetailsSegmentTableNumberLabel } from '../GrowthSimulatorDynamicCpiTable/GrowthSimulatorDynamicCpiTable';

interface Props {
  onCancel?: () => void;
  onSave?:(values: GrowthSimulatorDetailsSegmentTableType[]) => void;
  days?: number;
  retentionNumbers?: {
    day: number;
    value: NumericFormatType;
  }[];
  tabIndex: number;
  // scenarioName: string;
  scenarioId: string;
}


const StyledGrowthSimulatorDetailsSegmentTableHeaderList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 10fr 12fr 12fr 16fr 12fr 16fr 16fr 6fr;
  align-items: center;
  justify-items: center;
  text-align: center;
  grid-column-gap: 5px;
  /* grid-column-gap: 1%; */

`

const StyledGrowthSimulatorDetailsSegmentTableHeaderListItem = styled.li<{ showCursor?: boolean}>`
  background: #46526e;
  width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e7e7e7;

    font-weight: normal;
    font-size: 1rem;
    padding: 5px 0;

    &:first-of-type {
      border-top-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
    }
    ${({ showCursor}) => showCursor && `cursor: pointer;`}
`

const StyledGrowthSimulatorDetailsSegmentTableFooterListItem = styled(StyledGrowthSimulatorDetailsSegmentTableHeaderListItem)<{ value?: number }>`
  /* height: 25px; */
  &:first-of-type {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 0px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 0px;
  }

  ${props => props.value !== 100 && css`
    color: red;
  `}
`;
//color: ${props => props.value === 100 ? 'white' : 'red'}
const StyledGrowthSimulatorDetailsSegmentTableNumberInput = styled.input<{ disabled?: boolean}>`
    /* pointer-events: none; */
  
  width: 100%;
  background: ${({disabled}) => disabled ? '#616274' : 'white'  };
  
  border: 2px solid #58a4fd;
  color:${({disabled}) => disabled ? '#d9d9d9' : 'black'  };
  border-radius: 5px;
  text-align: center;
  height: 20px;
  &:focus {
      outline: none;
    }
`

const StyledGrowthSimulatorDetailsSegmentTableBodyContainer = styled.div`
display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
`

const StyledGrowthSimulatorDetailsSegmentTableBodyList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 10fr 12fr 12fr 16fr 12fr 16fr 16fr 6fr;
  align-items: center;
  justify-items: center;
  text-align: center;
  grid-column-gap: 5px;
 
  grid-template-rows: repeat(1, 40px);
`

const StyledGrowthSimulatorDetailsSegmentTableBodyListItem = styled.li<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #233048;
  padding: 0px 5px;
  height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  width: 100%;
  &:nth-of-type(2),
  &:nth-of-type(4),
  &:nth-of-type(6) {
    padding: 0px 15px;
  }

  &:nth-of-type(3),
  &:nth-of-type(5),
  &:nth-of-type(7){
    padding: 0px 10px;
  }
  &:nth-of-type(8){
    padding: 0px 10px;
    .MuiSvgIcon-root {
      fill: white;
    }
    &:hover{
      cursor: pointer;
      .MuiSvgIcon-root {
        opacity: 0.6;
      }
    }
  }
  ${props => props.disabled && 
    "pointer-events: none;"
  }
`

const StyledGrowthSimulatorDetailsSegmentTableContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* pointer-events: none; */
`


const StyledTopArrow = styled(PlayArrowIcon)`
  font-size: 1rem !important;
  color: #777c99;
  transform: rotate(270deg);
  
`

const StyledDownArrow = styled(PlayArrowIcon)`
  font-size: 1rem !important;
  color: #777c99;
  transform: rotate(90deg);
`


const StyledGrowthSimulatorRouletteControl = styled.button`
  /* pointer-events: none; */

  border: none;
  background: transparent;
  padding: 0;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledRouletteInputContainer = styled.div`
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`

const StyledAddCircleOutlineButton = styled.button<{ disabled?: boolean}>`
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiSvgIcon-root {
    fill: white;
  }
  &:hover{
    cursor: ${({disabled}) => !disabled && "pointer"};
    opacity: 0.6;
  }
  opacity: ${({disabled}) => disabled && '0.6'};;
`

const StyledFooterControls = styled.div`
  margin-top: 15px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
`

const StyledControlButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

type RouletteInputProps = {
  initial?: number;
  name?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  setFieldValue?: any;
  disabled?: boolean;
}
const RouletteInput = ({ initial = 0, name, onChange, setFieldValue, disabled = false}: RouletteInputProps) => {
  const [value, setValue] = useState<number>(initial);
  const inputRef: React.RefObject<HTMLInputElement> | null = useRef(null);
 
  useEffect(() => {
    setValue(initial)
  }, [initial]);

  return <StyledRouletteInputContainer>
    <StyledGrowthSimulatorDetailsSegmentTableNumberInput
      type="text" pattern="[0-9]*"
      value={value}
      // onInput={({ target: { value } }: any) => setValue(Number(value))}
      name={name}
      onChange={(e: any) => {
        setValue((prev) => {
          const value = e.target.value;
          if(Number(value) > -1 || Number(value) === 0) {
            onChange && onChange(e)
            return Number(value);
          }
          return prev;
        })
      }}
      disabled={disabled}
      ref={inputRef}
      id="test123"
      
    />
    {!disabled && <div>
      <StyledGrowthSimulatorRouletteControl
        onClick={() => {
          setValue((v) => {
            setFieldValue(name, v++);
            
            return v++;
          })}
        }
      >
        <StyledTopArrow></StyledTopArrow>
      </StyledGrowthSimulatorRouletteControl>
      <StyledGrowthSimulatorRouletteControl
        onClick={() => setValue((v) => {
          const value = v - 1;
          if(Number(value) > -1 || Number(value) === 0) {
            setFieldValue(name, v - 1);
            return Number(value);
          }
          return v;
        })}
      >
        <StyledDownArrow></StyledDownArrow>
      </StyledGrowthSimulatorRouletteControl>
    </div>}
  </StyledRouletteInputContainer>
}

export type GrowthSimulatorDetailsSegmentTableType = {
  segment: string;
  proportion: number;
  paymnet_per_user: number;
  average_purchase_size: number;
  apds: number;
  payments_interval: number;
  arppu: number;
}

export type ARPPUScenario = {
  segment: string;
  proportion: number;
  APDS: number;
  multiplier: number;
};

const scenarios: Record<number, ARPPUScenario[]> = {
  1: [{
    segment: '1',
    proportion:  1,
    APDS: 0,
    multiplier: 6.125
  }],
  2: [
    {
      segment: '1',
      proportion:  0.75,
      APDS: 0,
      multiplier: 2.625
    },
    {
      segment: '2',
      proportion:  0.25,
      APDS: 0,
      multiplier: 15.75
    }
  ],
  3: [
    {
      segment: '1',
      proportion:  0.6,
      APDS: 0,
      multiplier: 2.625
    },
    {
      segment: '2',
      proportion:  0.3,
      APDS: 0,
      multiplier: 8.75
    },
    {
      segment: '3',
      proportion:  0.1,
      APDS: 0,
      multiplier: 17.5
    }
  ],
  4: [
    {
      segment: '1',
      proportion:  0.6,
      APDS: 0,
      multiplier: 2.625
    },
    {
      segment: '2',
      proportion:  0.3,
      APDS: 0,
      multiplier: 8.75
    },
    {
      segment: '3',
      proportion:  0.1,
      APDS: 0,
      multiplier: 14
    },
    {
      segment: '4',
      proportion:  0.05,
      APDS: 0,
      multiplier: 17.5
    }
  ],
  5: [
    {
      segment: '1',
      proportion:  0.5,
      APDS: 0,
      multiplier: 3.5
    },
    {
      segment: '2',
      proportion:  0.34,
      APDS: 0,
      multiplier: 5.25
    },
    {
      segment: '3',
      proportion:  0.1,
      APDS: 0,
      multiplier: 14
    },
    {
      segment: '4',
      proportion:  0.05,
      APDS: 0,
      multiplier: 19.25
    },
    {
      segment: '5',
      proportion:  0.001,
      APDS: 0,
      multiplier: 21
    }
  ],
};

const GrowthSimulatorDetailsSegmentTable = ({onCancel, onSave, days = 0, retentionNumbers, tabIndex, scenarioId }: Props) => {
  const segmentTooltip = useSelector(tooltipSelectorById(90, 10));
  const proportionTooltip = useSelector(tooltipSelectorById(90, 20));
  const paymentsPerUserTooltip = useSelector(tooltipSelectorById(90, 30));
  const averagePurchaseSizeTooltip = useSelector(tooltipSelectorById(90, 40));
  const apdsTooltip = useSelector(tooltipSelectorById(90, 50));
  const paymentsIntervalTooltip = useSelector(tooltipSelectorById(90, 60));
  const arppuTooltip = useSelector(tooltipSelectorById(90, 70));
  
  const mapRetentionNumbers = useMemo(() => {
    if(retentionNumbers && retentionNumbers.length > 0) {
      const retentionAnchors: any = {};
      retentionNumbers.forEach((anchor) => {
        retentionAnchors[anchor.day] = anchor.value.formattedValue;
      });
      
      return {
        "retention_anchors": {
          ...retentionAnchors
        },
        "duration": Number(days)
      }
    }
  }, [retentionNumbers, days]);

  const dispatch = useDispatch();
  
  const apdsQuery = useGetApdsQuery(mapRetentionNumbers);
  const initialValues = useSelector(ARPPUTableValues)?.[scenarioId];
  const [apds, setApds] = useState<number | undefined>(undefined);

  // const initialTest = useSelector(ARPPUTableValues)?.[scenarioName];


  useEffect(() => {
    if (!apdsQuery || apdsQuery?.isLoading) {
      return;
    }

    if (!apdsQuery?.data) {
      return;
    }

    const { data, error } = apdsQuery;

    if (!data || error) {
      return;
    }
    
    setApds(data.apds);
  }, [apdsQuery]);

  const prepareInitialValue = (quantity: number) => {
    const initialScenarios = scenarios[quantity];
    if(initialScenarios && apds ) {
      const initialValue = initialScenarios.map((initialScenario) => {
        const result: any = {};

        result.segment = initialScenario.segment;
        result.proportion = initialScenario.proportion;
        result.average_purchase_size = 0;
        result.apds = apds * initialScenario.multiplier;
        result.payments_interval = 0;
        result.arppu = 0;
        result.paymnet_per_user = 0;
        return result;
      });
      return initialValue as GrowthSimulatorDetailsSegmentTableType[]
    } else {
      return [{
        segment: '1',
        proportion: 0.030,
        paymnet_per_user: 0,
        average_purchase_size: 0,
        apds: 0,
        payments_interval: 0,
        arppu: 0,
      }] as GrowthSimulatorDetailsSegmentTableType[];
    }
  };

  const { values, handleChange, setFieldValue, setValues, submitForm, resetForm} = useFormik({
    initialValues: initialValues || [{
      segment: '',
      proportion: 0,
      paymnet_per_user: 0,
      average_purchase_size: 0,
      apds: 0,
      payments_interval: 0,
      arppu: 0,
    }],
    onSubmit: (values: GrowthSimulatorDetailsSegmentTableType[]) => {
      const weightedAverage = Number(values.reduce((
        prevValue: number, nextValue: GrowthSimulatorDetailsSegmentTableType
      ) => prevValue + (nextValue.proportion * nextValue.arppu), 0).toFixed(2));
      
      dispatch(change('growthSimulatorForm', `members[${tabIndex}].calculator.arppu`, {
        formattedValue: `$${weightedAverage}`,
        value: `${weightedAverage}`,
        floatValue: weightedAverage,
      }))
      dispatch(addARPPUWeightedAverage(weightedAverage, scenarioId));
      onSave && onSave(values);
      dispatch(addARPPUValues(values, scenarioId))
      onCancel && onCancel();
    }
  });
  
  useEffect(() => {
    if(apds && resetForm) {
      const number = values.length;
      resetForm({values: initialValues || prepareInitialValue(number)})
    }
  }, [apds, resetForm, initialValues]);

  const proportion: number = values.reduce((accumulator: number, currentValue: GrowthSimulatorDetailsSegmentTableType) => {
    return accumulator + Number(currentValue.proportion)
  }, 0);

  const handleDeleteRow = (index: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if(values) {
      if(values.length <= 1) {
        return;
      }
      const rowsQuantity = values.length - 1
      const result =  prepareInitialValue(rowsQuantity);
      setValues(result)
    }
  };

  const handleAddRow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if(values) {
      if(values.length >= 5) {
        return;
      }
      const rowsQuantity = values.length + 1
      const result =  prepareInitialValue(rowsQuantity);
      setValues(result)
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    onCancel && onCancel()
    trackMixpanelEvent(MixpanelEvent.ARPPUCancel);
  };

  useEffect(() => {
    if(values && values.length > 0) {
      values.forEach((value, index) => {
        if(Number(value.apds) && Number(value.payments_interval)) {
          setFieldValue(`[${index}].paymnet_per_user`, Number(value.apds) * Number(value.payments_interval))
        }
        if(Number(value.average_purchase_size) && Number(value.apds)) {
          setFieldValue(`[${index}].arppu`, Number(value.average_purchase_size) * Number(value.paymnet_per_user))
        }
      })
    }

  }, [values]);

  return (
    <>
      <StyledGrowthSimulatorDetailsSegmentTableContainer>
      <header>
        <StyledGrowthSimulatorDetailsSegmentTableHeaderList>
          <MUITooltip title={segmentTooltip}>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem showCursor={!!segmentTooltip}>
              Segment
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
          </MUITooltip>
          <MUITooltip title={proportionTooltip}>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem showCursor={!!proportionTooltip}>
              Proportion
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
          </MUITooltip>
          <MUITooltip title={paymentsPerUserTooltip}>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem showCursor={!!paymentsPerUserTooltip}>
              Payment Per User
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
          </MUITooltip>
          <MUITooltip title={averagePurchaseSizeTooltip}>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem showCursor={!!averagePurchaseSizeTooltip}>
              Average Purchase Size
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
          </MUITooltip>
          <MUITooltip title={apdsTooltip}>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem showCursor={!!apdsTooltip}>
              APDs
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
          </MUITooltip>
          <MUITooltip title={paymentsIntervalTooltip}>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem showCursor={!!paymentsIntervalTooltip}>
              Payments Interval
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
          </MUITooltip>
          <MUITooltip title={arppuTooltip}>
            <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem showCursor={!!arppuTooltip}>
              ARPPU
            </StyledGrowthSimulatorDetailsSegmentTableHeaderListItem>
          </MUITooltip>
          <StyledGrowthSimulatorDetailsSegmentTableHeaderListItem />
        </StyledGrowthSimulatorDetailsSegmentTableHeaderList>
      </header>
      <StyledGrowthSimulatorDetailsSegmentTableBodyContainer>
        {values && values.map((v: GrowthSimulatorDetailsSegmentTableType, index: number) =>
          <StyledGrowthSimulatorDetailsSegmentTableBodyList key={`GrowthSimulatorDetailsSegmentTable-index-${index}`}>
            <StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              <StyledGrowthSimulatorDetailsSegmentTableNumberInput
                name={`[${index}].segment`}
                value={v.segment}
                onChange={handleChange}
                type="text"
                disabled
              ></StyledGrowthSimulatorDetailsSegmentTableNumberInput>
            </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            <StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              <RouletteInput
                name={`[${index}].proportion`}
                // value={v.segment}
                initial={v.proportion}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                disabled
              ></RouletteInput>

            </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            <StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              <StyledGrowthSimulatorDetailsSegmentTableNumberInput
                name={`[${index}].paymnet_per_user`}
                value={v.paymnet_per_user.toFixed(1)}
                // value={v.apds * v.payments_interval}
                onChange={handleChange}
                disabled
              >
              </StyledGrowthSimulatorDetailsSegmentTableNumberInput>
            </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            <StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            <StyledGrowthSimulatorDetailsSegmentTableNumberLabel>
                  $
              </StyledGrowthSimulatorDetailsSegmentTableNumberLabel>
              <RouletteInput
                name={`[${index}].average_purchase_size`}
                initial={v.average_purchase_size}
                onChange={handleChange}
                setFieldValue={setFieldValue}
              ></RouletteInput>
            </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            <StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              <StyledGrowthSimulatorDetailsSegmentTableNumberInput
                name={`[${index}].apds`}
                value={v.apds.toFixed(1)}
                disabled
              >
              </StyledGrowthSimulatorDetailsSegmentTableNumberInput>
            </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            <StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              <RouletteInput
                name={`[${index}].payments_interval`}
                initial={v.payments_interval}
                onChange={handleChange}
                setFieldValue={setFieldValue}
              ></RouletteInput>
            </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            <StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
              <StyledGrowthSimulatorDetailsSegmentTableNumberLabel>
                  $
              </StyledGrowthSimulatorDetailsSegmentTableNumberLabel>
              <StyledGrowthSimulatorDetailsSegmentTableNumberInput
                name={`[${index}].arppu`}
                value={v.arppu.toFixed(1)}
                onChange={handleChange}
                disabled
              >
              </StyledGrowthSimulatorDetailsSegmentTableNumberInput>
            </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
            <StyledGrowthSimulatorDetailsSegmentTableBodyListItem onClick={handleDeleteRow(index)} disabled={index !== values.length - 1}>
              {(index === values.length - 1 && values.length !== 1) && <DeleteIcon />}
            </StyledGrowthSimulatorDetailsSegmentTableBodyListItem>
          </StyledGrowthSimulatorDetailsSegmentTableBodyList>
        )}

      </StyledGrowthSimulatorDetailsSegmentTableBodyContainer>
      <footer>
      <StyledGrowthSimulatorDetailsSegmentTableHeaderList>
          <StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
           
          </StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
          <StyledGrowthSimulatorDetailsSegmentTableFooterListItem value={proportion}>
            {/* { proportion }% */}
          </StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
          <StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
            
          </StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
          <StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
            
          </StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
          <StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
            
          </StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
          <StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
            
          </StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
          <StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
            
          </StyledGrowthSimulatorDetailsSegmentTableFooterListItem>
          <StyledGrowthSimulatorDetailsSegmentTableFooterListItem />
        </StyledGrowthSimulatorDetailsSegmentTableHeaderList>
      </footer>
    </StyledGrowthSimulatorDetailsSegmentTableContainer>
    <StyledFooterControls>
      <StyledAddCircleOutlineButton onClick={handleAddRow} disabled={values.length === 5}>
        <AddCircleOutlineIcon fontSize='large'/>
      </StyledAddCircleOutlineButton>
      <StyledControlButtonsWrapper>
        <StyledBackButton onClick={handleCancel}>
          Cancel
        </StyledBackButton>
        <StyledNextButton onClick={() => submitForm()}>
          Save
        </StyledNextButton>
      </StyledControlButtonsWrapper>
    </StyledFooterControls>
  </>
    
  );
};

export default GrowthSimulatorDetailsSegmentTable;
