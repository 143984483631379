import { useMemo } from "react";
import styled from "styled-components";
import InputWithArrows, { InputType } from "./InputWithArrows";

interface PlayerSegmentsProps {
  className?: string;
  formikHandler?: any;
  formikValues?: any;
}


const PlayerSegments = ({className, formikValues, formikHandler }: PlayerSegmentsProps) => {

  return (
    <div className={className}>
      <header>
        Web2 payers segments
      </header>
      <div className="body">
        <div className="labels">
          <div></div>
          <div>Proportion</div>
          <div>Payments</div>
          <div>Avg Payment</div>
        </div>
        <div className="input-row">
          <label htmlFor="">Whales</label>
          <InputWithArrows
            value={formikValues.whales?.proportion}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.whales.proportion'}
            />
          <InputWithArrows
            value={formikValues.whales?.payments}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.whales.payments'}
            type={InputType.Integer}
          />
          <InputWithArrows 
            value={formikValues.whales?.avgPurchase}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.whales.avgPurchase'}
            currencySign 
            type={InputType.Integer}
          />
        </div>
        <div className="input-row">
          <label htmlFor="">Dolphins</label>
          <InputWithArrows
            value={formikValues.dolphins?.proportion}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.dolphins.proportion'}
            />
          <InputWithArrows
            value={formikValues.dolphins?.payments}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.dolphins.payments'}
            type={InputType.Integer}
          />
          <InputWithArrows 
            value={formikValues.dolphins?.avgPurchase}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.dolphins.avgPurchase'}
            currencySign 
            type={InputType.Integer}
          />
        </div>
        <div className="input-row">
          <label htmlFor="">Fish</label>
          <InputWithArrows
            value={formikValues.fish?.proportion}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.fish.proportion'}
            />
          <InputWithArrows
            value={formikValues.fish?.payments}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.fish.payments'}
            type={InputType.Integer}
          />
          <InputWithArrows 
            value={formikValues.fish?.avgPurchase}
            formikHandler={formikHandler}
            name={'web2PlayersSegments.fish.avgPurchase'}
            currencySign 
            type={InputType.Integer}
          />
        </div>
      </div>
    </div>
  );

};

export default styled(PlayerSegments)`
  height: 100%;
  background-color: #2E2F4B;
  border-radius: 15px;
  /* padding: 10px; */
  header {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2E2F4B;
    height: 48px;
    overflow: hidden;
    border-radius: 15px;
  }
  .body{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    background-color:#212238;
    padding-bottom: 15px;
    .labels{
      display: grid;
      grid-template-columns: 100px 1fr 1fr 1fr;
      padding: 10px;
      justify-content: center;
      div {
        display: grid;
        justify-content: center;
      }
    }

    .input-row{
      display: grid;
      grid-template-columns: 100px minmax(100px, 230px) minmax(100px, 230px) minmax(100px, 230px);
      padding-left: 15px;
      padding-right: 15px;
      grid-gap: 10px;
      justify-content: space-between;
      div {
        justify-content: center;
      }
      label{
        align-self: center;
      }
    }
  }
`