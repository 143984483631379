import { ISegment, ISegmentField } from "../store/segmentation/segmentation.state";

import { AxiosInstance } from "axios";

export class SegmentationService {
    public static axios: AxiosInstance;

    public static async getAllSegments(titleId: Number): Promise<ISegment[]> {
        const { data } = await SegmentationService.axios.get<any[]>
        (`segment/${titleId}`);
      
        return data;
      };

      public static async getSegment(titleId: number, segmentId: number): Promise<ISegment> {
        const { data } = await SegmentationService.axios.get<ISegment>
        (`segment/${titleId}/${segmentId}`);
      
        return data;
      };
      
      public static async getAllSegmentFields(title_id: Number): Promise<ISegmentField[]> {
        const { data } = await SegmentationService.axios.get<any[]>
        (`segment/fields/?title_id=${title_id}`);
      
        return data;
      };

      
      public static async getAllFilters(titleID: number): Promise<any[]> {
        const { data } = await SegmentationService.axios.get<any[]>
        (`segment/?title_id=${titleID}`);
      
        return data;
      };
      
      public static async getSampleDataForSegment(titleID: number, segmentID: number): Promise<any[]> {
        const { data } = await SegmentationService.axios.get<any[]>
        (`segment/data/?title_id=${titleID}&segment_id=${segmentID}&sample=20`);
      
        return data;
      };
      
      public static async changeActiveStatus(): Promise<any[]> {
        const { data } = await SegmentationService.axios.get<any[]>
        (`segment/`);
      
        return data;
      };
      
      public static async addSegment(body: any): Promise<any[]> {
        const { data } = await SegmentationService.axios.post<any[]>
        (`segment/`, body);
        
        return data;
      };
      
      public static async editSegment(body: any, id: number): Promise<any[]> {
        const { data } = await SegmentationService.axios.patch<any[]>
        (`segment/${id}`, body);
      
        return data;
      };
      
      public static async deleteSegment(body: any): Promise<any[]> {
        const { data } = await SegmentationService.axios.delete<any[]>
        (`segment/`, {
          data: body
        });
      
        return data;
      };
      
      public static async downloadSegment(): Promise<any[]> {
          const { data } = await SegmentationService.axios.get<any[]>
          (`segment/`);
        
          return data;
      };
    
}  