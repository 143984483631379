import 'react-day-picker/lib/style.css';

import { AnimatePresence, motion } from 'framer-motion';
import DayPicker, { DateUtils, LocaleUtils, Modifier, Modifiers } from 'react-day-picker';
import React, { useEffect, useState } from 'react';

import CalendarAltLightIcon from '../Icons/CalendarAltLightIcon';
import CalendarAltRegularIcon from '../Icons/CalendarAltRegularIcon';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { dateToString } from '../../helpers/formatters';
import moment from 'moment';
import styled from 'styled-components';

export interface IDateRangePickerProps {
  className?: string;
  expanded?: boolean;
  onChange?: (period: IPeriod) => void;
  initialState?: IPeriod
}

export interface IPeriod {
  daysBack?: number;
  dateFrom?: Date | string;
  dateTo?: Date | string;
}

export interface ICalendarButton {
  className?: string;
  period?: number;
  selected?: boolean;
  onClick?: () => void;
}

const CalendarButton = ({ className, period, onClick }: ICalendarButton) => {
  return <button className={className} onClick={onClick}>
    {period ? <>
      <CalendarTodayIcon />
      <span>{period}</span>
    </> : <CalendarAltLightIcon style={{ width: '1.5rem' }} />}
  </button>
}

const StyledCalendarButton = styled(CalendarButton)`
  color: #FFF !important;

  background: none;
  border: none;
  padding: 5px;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1.8rem;
  }

  span {
    font-size: 0.6rem;
    position: absolute;
    bottom: 1.1rem;
  }

  opacity: ${({ selected }) => selected ? '1' : '0.5'};

  &:hover {
    opacity: 1;
  }
`

const StyledChevronRight = styled(ChevronRightIcon)`
  color: #fff;
  font-size: 2rem !important;
`

const StyledDayPickerWrapper = styled.aside`
  position: absolute;
  background: #fff;
  z-index: 20;
  top: 35px;
  left: calc(100% - 30px);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 3px solid #478CDF;
  border-radius: 25px;
  padding: 5px;

  & > .DayPicker {
   .DayPicker-Months {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      font-size: 0.8rem;

      .DayPicker-Month {
        .DayPicker-Caption {
          text-align: center;
        }

        .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background-color: #f0f8ff !important;
            color: #4a90e2;
        }
        .DayPicker-Day {
          border-radius: 0 !important;
        }
        .DayPicker-Day--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }
        .DayPicker-Day--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }
      }
    }
  }
`

const INITIAL_STATE = {
  daysBack: 60,
  dateFrom: "-1",
  dateTo: "-1"
}

export interface IYearMonthFormProps {
  className?: string;
  handleFromDateChange: (date: Date) => void;
  handleToDateChange: (date: Date) => void;
  fromDate?: Date;
  toDate?: Date;
  year: Date;
  handleYearChange: (date: Date) => void;
}

const StyledDayPickerInputWrapper = styled.div`
  input {
    border: 1px solid #58C31B;
    text-align: center;
    border-radius: 5px;
    margin: 0 10px;
  }
`

const DateRangePickerHeader = ({ className, handleFromDateChange, fromDate, toDate, handleToDateChange, year, handleYearChange }: IYearMonthFormProps) => {
  return (
    <form className={className}>
      <ul>
        <li>
          <StyledDayPickerInputWrapper>
            <DayPickerInput
              value={fromDate}
              onDayChange={handleFromDateChange} />
          </StyledDayPickerInputWrapper>
          <StyledDayPickerInputWrapper>
            <DayPickerInput
              value={toDate}
              onDayChange={handleToDateChange}
            />
          </StyledDayPickerInputWrapper>
        </li>
        <li>
          <ChevronRightIcon 
            style={{ transform: 'rotate(180deg) scale(2)' }} 
            onClick={() => handleYearChange(moment(year).add(-1, 'year').toDate())}
          />
          <span>{year?.getFullYear()}</span>
          <ChevronRightIcon 
            style={{ transform: 'rotate(0deg) scale(2)' }}
            onClick={() => handleYearChange(moment(year).add(1, 'year').toDate())}
          />
        </li>
        <li></li>
      </ul>
    </form>
  );
}

const StyledDateRangePickerHeader = styled(DateRangePickerHeader)`
  & > ul {
   margin: 0;
   padding: 0;
   list-style-type: none;
  
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

   & > li {
     display: flex;
     justify-content: center;
     align-items: center;
     margin-bottom: 10px;

     & > span {
      color: #58C31B;
      padding: 0 15px;
      font-size: 2rem;
      font-weight: bold;
     }
   }
  }
`

const DateRangePicker = ({ className, expanded = false, onChange, initialState = INITIAL_STATE }: IDateRangePickerProps) => {
  const [daysBack, setDaysBack] = useState(initialState?.daysBack);
  const [hovered, setHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [from, setFrom] = useState<Date | null | undefined>();
  const [to, setTo] = useState<Date | null | undefined>();
  const [payload, setPayload] = useState<IPeriod>(initialState);
  const [year, setYear] = useState<Date | undefined>(new Date());

  const modifiers = { start: from, end: to } as Partial<Modifiers>;

  const [_timeout, _setTimeout] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (from && to) {
      setDaysBack(undefined);
      setPayload((prev) => {
        return {
          ...prev,
          dateFrom: dateToString(from),
          dateTo: dateToString(to),
          daysBack: -1
        }
      })
    }
  }, [from, to])

  useEffect(() => {
    if (daysBack) {
      setFrom(undefined);
      setTo(undefined);
      setPayload((prev) => {
        return {
          ...prev,
          dateFrom: initialState?.dateFrom,
          dateTo: initialState?.dateTo,
          daysBack: daysBack
        }
      })
    }
  }, [daysBack])

  useEffect(() => {
    if(payload) {

      onChange && onChange(payload)
    }
  }, [payload])

  return <AnimatePresence>
    <section className={className}
      onMouseEnter={() => {
        clearTimeout(_timeout as any);
        setHovered(true);
      }}
      onMouseLeave={() => {
        _setTimeout(  setTimeout(() => {
          setHovered(false);
          setIsOpen(false);
        }, 1 * 1000))
      }}
    >
      <div>
        <StyledCalendarButton
          period={daysBack}
          selected
        ></StyledCalendarButton>
        <StyledChevronRight />
      </div>

      {(hovered || expanded) && <motion.section
        transition={{ delay: 0.1 }}
        initial={{ width: 0, overflow: 'hidden', opacity: 0 }}
        animate={{ width: 'auto', opacity: 1 }}
        exit={{ width: 0, overflow: 'hidden', opacity: 0 }}
      >
        {[7, 30, 60, 90, 120, 180, 365].map(p => <StyledCalendarButton
          period={p}
          selected={daysBack === p}
          onClick={() => setDaysBack(p)}
          key={p}
        ></StyledCalendarButton>)}

        <StyledCalendarButton onClick={() => setIsOpen((state) => !state)}></StyledCalendarButton>

        {isOpen && <StyledDayPickerWrapper>
          <StyledDateRangePickerHeader
            handleFromDateChange={(date) => setFrom(date)}
            handleToDateChange={(date) => setTo(date)}
            fromDate={from!}
            toDate={to!}
            year={year!}
            handleYearChange={(date) => setYear(date)}
          />
          <DayPicker
            month={year}
            numberOfMonths={12}
            selectedDays={[from as Modifier, { from, to }]}
            canChangeMonth={false}

            modifiers={modifiers}
            onDayClick={(day: Date) => {
              const range = DateUtils.addDayToRange(day, { from, to });

              setFrom(range.from);
              setTo(range.to);
            }}
          />
        </StyledDayPickerWrapper>}
      </motion.section>}
    </section>
  </AnimatePresence>
};

export default styled(DateRangePicker)`
    position: relative;

    padding: 5px;
    padding-right: 15px;

    display: inline-flex;
    width: auto;
    align-self: flex-start;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: #3D3E5A;
    border-radius:0  35px 35px 0;

    & > div {
      display: flex;
      align-items: center;
    }

    & > section {
      display: flex;
      align-items: center;
    }
`;
