import { useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import styled from 'styled-components';
import Tooltip from "../Tooltip/Tooltip";

const SortByHeader = ({ header, onSort, className, tooltip }: {
  header: string | React.ReactElement,
  onSort: (
    direction: 'ascending' | 'descending'
  ) => void,
  className?: string,
  tooltip?: string
}) => {
  const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending')

  return <li className={className} onClick={() => {
    const newDirection = ['ascending', 'descending']
      .find(d => d !== sortDirection) as 'ascending' | 'descending';

    setSortDirection(() => newDirection);

    onSort(newDirection);
  }}>
    <span>{header}</span>
    {tooltip && <Tooltip text={tooltip} /> }
    <span>
      {sortDirection === 'descending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </span>
  </li>
}

export default styled(SortByHeader)<{ tooltip?: any }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: ${({ tooltip}) => tooltip && 'pointer'};
    &:hover {
      .html-tooltip{
        visibility: visible;
        opacity: 1;
       
      }
    }
    & > span {
      display: flex;
     

      &:last-of-type {
        position: absolute;
        right: -7px;
        bottom: -7px;
        cursor: pointer;
      }

      & > svg {
        font-size: 2rem;
      }
    }
`;
