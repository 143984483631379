import { useEffect, useState } from 'react';
import FilterBar, { IFilterBarPayload } from "../../../../components/FilterBar/FilterBar";

import styled from "styled-components";
import { SimpoolSimpleButton } from "../../../../components/Buttons/Buttons";
import SegmentationDialog from '../../../Segmentation/components/SegmentationDialog/SegmentationDialog';

export interface ISubHeaderProps {
  onAdd: () => void;
  className?: string;
  onFilter?: (filter: IFilterBarPayload) => void;
  filter?: IFilterBarPayload
  titleId?: number;
}

const SubHeader = ({ className, onAdd, onFilter, filter, titleId }: ISubHeaderProps) => {
  const [open, setOpen] = useState(false);

  const [filterState, setFilterState] = useState<IFilterBarPayload>();

  useEffect(() => {
    if (!filterState) {
      return;
    }

    onFilter && onFilter(filterState);
  }, [filterState, onFilter])

  return <>
    <SegmentationDialog open={open} titleId={titleId!} onClose={() => setOpen(false)} onSubmit={(payload) => setFilterState((prev) => ({
      ...(prev!),
      segments: payload
    }))} />
    <header className={className}>
      <ul>
        <li>
          <FilterBar titleId={titleId} onChange={(payload) => setFilterState(payload)} filter={filter} />
          <SimpoolSimpleButton onClick={() => setOpen(true)}>Segment</SimpoolSimpleButton>
        </li>
        <li>
          <SimpoolSimpleButton onClick={onAdd}>Add</SimpoolSimpleButton>
        </li>
      </ul>
    </header>
  </>
}

export default styled(SubHeader)`
   background: #202E48 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #000000;
  padding: 10px;
  button {
    color: white;
  }
  & > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
  

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }

`
