import * as Yup from 'yup';

import { Box, FormControlLabel, FormGroup, LinearProgress, Link, Typography } from "@mui/material";
import { Pages, trackPageSource } from '../../../mixpanel';
import { StyledCheckbox, StyledLandingButton, StyledLandingPageInnerContainer, StyledLandingPageWrapper, StyledTextField } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userRegisteredErrorSelector, userRegisteredLoadingSelector, userSelector } from "../../../store/onboarding/onboarding.selectors";

import { AppDispatch } from '../../../store/app.state';
import { AuthService } from "../../../services/auth.service";
import GoogleLogin from '../../../shared/GoogleLogin/GoogleLogin';
import { OnboardingService } from '../../../services/onboarding.service';
import { Link as RouterLink } from 'react-router-dom';
import SimpoolDialog from '../../../components/Dialog/Dialog';
import { StyledBackButton } from "../../GrowthSimulatorV2/components/GrowthSimulatorButtons/GrowthSimulatorButtons";
import { StyledCloseTabDialog } from "../../ScenarioBuilder/components/ScenarioBuilderWrapper/ScenarioBuilderWrapper";
import StyledPasswordInput from '../StyledPasswordInput';
import logo from '../../../assets/logo2.png';
import { registerUser } from "../../../store/onboarding/onboarding.actions";
import { unwrapResult } from '@reduxjs/toolkit';
import { useApplicationContext } from "../../../Context";
import { useFormik } from "formik";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useLocation } from "react-router";

const Register = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userRegistered = useSelector(userSelector);

  const error = useSelector(userRegisteredErrorSelector);
  const { search } = useLocation();
  const intent = new URLSearchParams(search).get('intent')?.toString();
  const [verifyEmailDialogOpen, setVerifyEmailDialogOpen] = useState(false);
  const userRegisteredLoading = useSelector(userRegisteredLoadingSelector);

  const source = new URLSearchParams(search).get('src') || undefined;

  useEffect(() => {
    trackPageSource(Pages.Register, source)
  }, []);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const validationSchema = Yup.object({
    first_name: Yup.string().required('First name is required').min(2, 'First name should be more than 2 characters'),
    last_name: Yup.string().required('Last name is required').min(3, 'Last name should be more than 3 characters'),
    email: Yup.string().required('Email is required').email('Please enter a valid email'),
    password: Yup.string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Password should contain one digit, one small letter, one capital letter and a symbol')
  })

  useEffect(() => {
    if (userRegistered) {

      setVerifyEmailDialogOpen(true);
    }
  }, [userRegistered])

  const { validateForm, values, handleChange, handleSubmit, handleBlur, errors, touched, isValid } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      company: '',
      email: '',
      password: '',
      accepted_marketing: true
    },
    validationSchema,
    onSubmit: ({
      first_name,
      last_name,
      company,
      email,
      password,
      accepted_marketing
    }) => {
      dispatch(registerUser({
        acceptedMarketing: accepted_marketing,
        firstName: first_name,
        lastName: last_name,
        company,
        email,
        password,
        intent
      }))
    }
  });

  return (
    <>
      <SimpoolDialog
        open={verifyEmailDialogOpen}
        title={`Thank you for registering, please verify your email!`}
        onClose={() => setVerifyEmailDialogOpen(false)}
      >
        <StyledCloseTabDialog>
          <div className="dialog-btns">
            <StyledBackButton onClick={() => setVerifyEmailDialogOpen(false)}>
              Ok
            </StyledBackButton>
          </div>
        </StyledCloseTabDialog>
      </SimpoolDialog>
      <StyledLandingPageWrapper style={{ maxHeight: 'none' }}>
        {userRegisteredLoading && <LinearProgress></LinearProgress>}
        <StyledLandingPageInnerContainer>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '440px', display: 'flex', justifyContent: 'center' }}>
              <img src={logo} alt="" />
            </Box>
            <Box sx={{ width: '650px', display: 'flex', justifyContent: 'center', marginBottom: '22px' }}>
              <Typography
                variant="h3"
                color={'white'}
                fontFamily={['Open Sans, sans-serif']}
                fontWeight={700}
                fontSize={32}
              >
                Registration Page
              </Typography>
            </Box>
            <Box sx={{ width: '650px', display: 'flex', justifyContent: 'center', marginBottom: '46px' }}>
              <Typography
                variant="h6"
                color={'white'}
                textAlign="center"
                fontFamily={['DM Sans, sans-serif']}
                fontWeight={400}

              >
                Please fill in your account's details
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box sx={{ width: '440px', marginBottom: '30px' }}>
                <StyledTextField
                  id="first_name"
                  placeholder="First Name"
                  name="first_name"
                  fullWidth
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.first_name}
                  helperText={errors.first_name}
                />
                {/* {errors.first_name && touched.first_name ? (
                <div>{errors.first_name}</div>
              ) : null} */}
              </Box>
              <Box sx={{ width: '440px', marginBottom: '30px' }}>
                <StyledTextField
                  id="last_name"
                  placeholder="Last Name"
                  name="last_name"
                  fullWidth
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.last_name}
                  helperText={errors.last_name}
                />

                {/* {errors.last_name && touched.last_name ? (
                <div>{errors.last_name}</div>
              ) : null} */}
              </Box>
              <Box sx={{ width: '440px', marginBottom: '30px' }}>
                <StyledTextField
                  id="company"
                  placeholder="Company"
                  name="company"
                  fullWidth
                  value={values.company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <Box sx={{ width: '440px', marginBottom: '30px' }}>
                <StyledTextField
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  fullWidth
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(errors.email && touched.email)}
                  helperText={errors.email}
                />
              </Box>
              <Box sx={{ width: '440px', marginBottom: '30px' }}>
                <StyledPasswordInput
                  id="password"
                  placeholder="Password"
                  fullWidth
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.password}
                  helperText={errors.password}
                />
                {/* {errors.password && touched.password ? (
                <div>{errors.password}</div>
              ) : null} */}
              </Box>
              {error && <Box sx={{ width: '440px', display: 'flex', justifyContent: 'center', marginBottom: '40px', color: 'red' }}>
                {error?.message}
              </Box>}

              <Box sx={{ width: '440px', marginBottom: '25px' }}>
                <FormGroup>
                  <FormControlLabel control={<StyledCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="accepted_marketing"
                    name="accepted_marketing"
                    defaultChecked />}
                    label={
                      <Typography
                        variant="subtitle2"
                        color={'rgba(255, 255, 255, 0.7)'}
                        fontFamily={['DM Sans, sans-serif']}>

                        I would like to receive the Marketing Materials
                      </Typography>
                    } />
                </FormGroup>
              </Box>
              <Box sx={{ width: '440px', marginBottom: '25px' }}>
                <Typography
                  variant="subtitle1"
                  color={'rgba(255, 255, 255, 0.7)'}
                  fontFamily={['DM Sans, sans-serif']}
                  fontSize={13}>
                  By registering, I accept the <Link href="https://simpool.io/terms-of-use/" color='#FFFFFF' target="_blank">Terms and Conditions</Link> of this application
                </Typography>
              </Box>
              <Box sx={{ width: '440px', marginBottom: '10px' }}>
                <StyledLandingButton disabled={!isValid} variant="contained" size="large" type="submit" style={{ width: '100%', height: '56px' }}>Register</StyledLandingButton>
              </Box>

              <Box sx={{ width: '440px', marginBottom: '10px' }}>
                <Typography
                  variant="h6"
                  color={'white'}
                  textAlign="center"
                  fontFamily={['DM Sans, sans-serif']}
                  fontWeight={400}
                >
                  Or
                </Typography>
              </Box>

              <Box sx={{ width: '440px', marginBottom: '10px' }}>
                <GoogleLogin onClick={async () => {
                  const { redirect_uri } = await OnboardingService.googleAuthorize({
                    redirect: 'growth-simulator-v2'
                  })

                  window.location.href = redirect_uri;
                }}></GoogleLogin>
              </Box>
            </form>
            <Box sx={{ width: '440px', marginBottom: '25px' }}>
              <Typography
                variant="subtitle1"
                color='#FFFFFF'
                fontFamily={['DM Sans, sans-serif']}>
                Already have an account? <RouterLink to={'login'} style={{ color: 'white' }}>Login</RouterLink>
              </Typography>
            </Box>
          </Box>

        </StyledLandingPageInnerContainer>
      </StyledLandingPageWrapper>
    </>

  );
};

export default Register;
