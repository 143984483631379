import { StyledHeading, StyledLandingButton, StyledLandingPageWrapper } from "./styles";

import { CARDS } from "./constants";
import Card from "./Card";
import { device } from "../../helpers/device";
import styled from "styled-components";

type Props = {
  buttonOnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonText: string;
}

const SecondLanding = ({ buttonOnClick, buttonText }:Props ) => {
  return (
    <StyledSecondWrapper id="second">
      <StyledHeading>
          Here's what Simpool can do for you:
      </StyledHeading>
        <StyledInnerContainerWrapper>
          <StyledInnerContainer>
            <StyledRowsContainer>
              <StyledCardsRowContainer>
                <StyledCardsTitle>
                  CMO / VP of marketing
                </StyledCardsTitle>
                <StyledCardRowContainer>
                  { CARDS['cmo'].map((card, index) => {
                    return <Card number={card.number} text={card.text} key={`cmo=${index}`}/>
                  })}
                </StyledCardRowContainer>
                <StyledLandingButton
                  style={{ alignSelf: 'center'}}
                  variant="contained"
                  size="large"
                  onClick={buttonOnClick}
                >{ buttonText }</StyledLandingButton>
              </StyledCardsRowContainer>
              <StyledCardsRowContainer>
                <StyledCardsTitle>
                  Performance Marketing / User Acquisition manager
                </StyledCardsTitle>
                <StyledCardRowContainer>
                { CARDS['ua'].map((card, index) => {
                    return <Card number={card.number} text={card.text} key={`uA=${index}`}/>
                  })}
                </StyledCardRowContainer>
              </StyledCardsRowContainer>
            </StyledRowsContainer>
          </StyledInnerContainer>
        </StyledInnerContainerWrapper>
      </StyledSecondWrapper>
  )
};

export default SecondLanding;

const StyledSecondWrapper = styled(StyledLandingPageWrapper)`
  display: flex;
  justify-content: center;
  padding: 60px 120px;
  box-sizing: border-box;
  height: fit-content;
  max-height: fit-content;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileL} { 
    padding: 40px 70px !important;
    text-align: center;
  }

`

const StyledInnerContainerWrapper = styled.div`
  display: grid;
  /* width: 1357px; */
  box-sizing: border-box;
  margin-top: 30px;
  max-width: 1357px;
`
const StyledInnerContainer = styled.div`
  background: rgba(26, 40, 64, 0.85);
  opacity: 0.8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width:100%;
  display: flex;
  padding: 35px 60px;
  box-sizing: border-box;
  height: fit-content;
`

const StyledRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: red; */
  gap: 50px;
  box-sizing: border-box;

`

const StyledCardsRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: green; */
  box-sizing: border-box;
  gap: 25px;

  @media ${device.mobileL} { 
  }

  
`
const StyledCardsTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 54px;
  /* or 225% */

  letter-spacing: -0.02em;
  box-sizing: border-box;

  color: #FFFFFF;
  @media ${device.laptop} { 
    font-size: 20px;
    line-height: 45px;
  }
`
const StyledCardRowContainer = styled.div`
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(3, 1fr) ;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;

  @media ${device.mobileL} { 
    margin-bottom: 50px;
  }

  @media ${device.laptop} { 
    gap: 16px;
  }
  @media ${device.laptopS} {
    gap: 20px;
  }
  @media ${device.laptopSL} {
    gap: 20px;
  }
`