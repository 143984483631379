import { trackCancelTourGS, trackFinishTourGS } from '../../../../mixpanel';
import { useEffect, useState } from 'react';

import { EGoalType } from '../../../../store/growthSimulator/growthSimulator.state';
import { Helmet } from 'react-helmet';
import { IGrowthSimulatorWidget } from '../../models/growthSimulator.models';
import { ITourStep } from '../../../../shared/tour/types';
import { Tour } from '../../../../shared/tour';
import { goalSelector } from '../../../../store/growthSimulator/growthSimulator.selector';
import { useApplicationContext } from '../../../../Context';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export interface GrowthSimulatorTourProps {
  widgets: IGrowthSimulatorWidget[];
  name: string;
  provider: any;
}

export function GrowthSimulatorTour({ widgets, name, provider: Provider }: GrowthSimulatorTourProps) {
  const goal = useSelector(goalSelector);
  const [showTour, setShowTour] = useState<boolean>(false);
  const [context, setContext] = useApplicationContext();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const step = params.get('step');

    if(step) {
      setContext((s) => ({
        ...s,
        tourStep: Number(step),
      }))
    }
  }, [])

  useEffect(() => {
    setContext((s) => ({
      ...s,
      isTourActive: showTour,
      tour: name
    }))
  }, [showTour, name])

  useEffect(() => {
    if(context.isTourActive) {
      return;
    }

    if(!widgets?.length) {
      return;
    }

    let state: EGoalType[] = [];
    try {
      state = JSON.parse(localStorage.getItem(`growth-simulator-tutorial-${name}`)!) || [];
    } catch {
      localStorage.removeItem(`growth-simulator-tutorial-${name}`);
    }
    const hasCompletedGoalTutorial = Boolean(state?.find((g) => g === goal));

    setShowTour(!hasCompletedGoalTutorial);
  }, [goal, widgets])

  const handleTourClose = () => {
    trackCancelTourGS();

    setContext((s) => ({
      ...s,
      isTourActive: false,
      tour: name
    }))

    setShowTour(false);
  }

  const handleStepChange = (step: number) => {
    setContext((s) => ({
      ...s,
      tourStep: step,
    }))
  }

  const handleTourComplete = () => {
    trackFinishTourGS()

    setContext((s) => ({
      ...s,
      isTourActive: false,
      tour: name
    }))

    setShowTour(false);

    saveTourProgress();

    function saveTourProgress() {
      let state: EGoalType[] = [];
      try {
        state = JSON.parse(localStorage.getItem(`growth-simulator-tutorial-${name}`)!) || [];
      } catch {
        localStorage.removeItem(`growth-simulator-tutorial-${name}`);
      }

      function onlyUnique<T>(value: T, index: number, array: T[]) {
        return array.indexOf(value) === index;
      }

      let completedTutorials = [...state, goal].filter(onlyUnique);

      localStorage.setItem(`growth-simulator-tutorial-${name}`, JSON.stringify(completedTutorials));
    }
  }

  return <>
    <Helmet>
      <style>
        {
          `
            .simpool-growth-simulator-tour {
              background: #4A9CFB;
              color: #000000;
              width: 330px;
              transform: translateX(15%);
            }

            .simpool-growth-simulator-tour-button {
              color: #FFFFFF;
              border: none;
              background: none;
            }

            .simpool-growth-simulator-tour .shepherd-arrow:before {
              background: #4A9CFB;
            }

            .margin-left-auto {
              margin-left: auto;
            }
          `
        }
      </style>
    </Helmet>
    {
      showTour && <Provider widgets={widgets}>
        {({ steps }: { steps: ITourStep[] }) =>
         <Tour
            tourData={{
              steps
            }}
            step={context.tourStep}
            onComplete={handleTourComplete}
            onDismiss={handleTourClose}
            onStepChange={handleStepChange}
          />
        }
      </Provider>

    }
  </>
}
