import React from 'react';
import { scoreColor } from '../../../helpers/colors';
import styled from 'styled-components';

export interface IScoreProps {
  value: number;
  className?: string;
}

const Arrow = ({ value, className }: IScoreProps) => {
  return <div className={className}>
    <p />
  </div>
};

export default styled(Arrow)`
    width: 35px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
      background: ${({ value }) => scoreColor(value)};
      height: 5px;
      width: 40px;
      margin: 0 auto;
      position: relative;
      cursor: pointer;
        
      &:before,
      &:after {
        content: "";
        background: ${({ value }) => scoreColor(value)};
        position: absolute;
        height: 5px;
        width: 15px;
      }
    
      &:before {
        right: -5px;
        bottom: -4px;
        transform: rotate(-45deg);
      }
    
      &:after {
        right: -5px;
        top: -4px;
        transform: rotate(45deg);
      }
    }
`;
