import { RefObject, useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useApplicationContext } from '../../Context';
import { money } from '../../helpers/formatters';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { StyledCloseTabDialog } from '../../pages/ScenarioBuilder/components/ScenarioBuilderWrapper/ScenarioBuilderWrapper';
import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { changeTitle } from '../../store/shared/shared.actions';
import { sharedTitleSelector } from '../../store/shared/shared.selector';
import { SimpoolPrimaryButton, SimpoolSecondaryButton } from '../Buttons/Buttons';
import Dialog from '../Dialog/Dialog';
import UsersBadge from '../UsersBadge/UsersBadge';

export interface IRevenueProps {
  className?: string;
  expanded?: boolean;

  title: string;
  amount: number;
  ltv: number;

  users?: number;

  src: any;

  variant: 'green' | 'yellow' | 'blue';

  collapsed?: boolean;
  isBorder?: boolean;
  gameTitle?: string;
}

const colorByVariant = (variant: 'green' | 'yellow' | 'blue'): string => {
  switch (variant) {
    case 'green':
      return '#58C31B';
    case 'blue':
      return '#52A0FA';
    case 'yellow':
      return '#FFCC00';
    default:
      return '#000';
  }
}

const StyledDivider = styled.div`
    display: flex;
    height: 50%;
    margin: 0;
    border: 1px solid #A8A8A8;
    margin: 0 15px;
  `

const Money = styled.span`
    font-weight: bold;
  `

const Text = styled.span`
    color: white;
    white-space: nowrap;
  `

const RevenueContainer = styled.div`
    & > section {
      background: #262742;
      box-shadow: inset 2px 2px 4px #000000E3;
      border-radius: 35px;
      opacity: 0.9;
      
      height: 45px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    & > header {
      display: flex;
      font-size: 1.2rem;
      color: #FFFFFF;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      margin: 0px 20px 10px 0;
    }
  `

const StyledGamesListArrowContainer = styled.div`
    left: calc(20% + 40px);
    position: absolute;
    z-index: 10;
    top: 80px;
    background: #3D3E5A 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    color: #fff;
    display: flex;
    cursor: pointer;
`

const StyledGamesListMenuContainer = styled.aside`
  width: 85px;
  left: calc(20% + 40px + 20px);
  position: absolute;
  z-index: 10;
  top: calc(80px + 20px);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #000000fc;

  header {
    background: #25273D;
    color: #FFFFFF;
    padding: 5px 10px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    & > li {
      padding: 5px;
      text-align: center;
      &.selected {
        background: #CDCDD2;
      }
      & > a {
        text-decoration: none;
        color: #25273D;
      }

      &:hover {
        background: #CDCDD2;
      }
    }
  }
`;

const GamesList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [{ accountTitlesQuery }, setApplicationContext] = useApplicationContext();
  const titleSelector = useSelector(sharedTitleSelector);
  const dispatch = useDispatch();
  const ref = useRef<HTMLElement>(null);
  useOutsideAlerter(ref, () => setIsOpen(false));
  
  const [closeDialogState, setCloseDialogState] = useState<{ isOpen: boolean, title: IGetAccountTitlesResponse | undefined }>({
    isOpen: false,
    title: undefined,
  });
  const [shouldPreventTitleChange, setShouldPreventTitleChange] = useState(false);

  const { location } = useHistory();
  useEffect(() => {
    if ( location ) {
      setShouldPreventTitleChange(
        location.pathname?.includes('scenario-builder') ||
        location.pathname?.includes('segmentation')
      );
    }
  }, [location]);
  
  const handleChangeTitle = (title?: IGetAccountTitlesResponse) => {
    const currentTitle = closeDialogState.title || title
    if( currentTitle) {
      setApplicationContext(
        state => JSON.parse(JSON.stringify(Object.assign(state, {
          title: currentTitle
        }))));
        dispatch(changeTitle(currentTitle))
    }
    setCloseDialogState((prev) => ({ ...prev, isOpen: false, title: undefined }))
  };

  const handleCancelChangeTitle = () => {
    setCloseDialogState((prev) => ({ ...prev, isOpen: false, title: undefined }))
  };
  
  const dialogTitle = "Click 'Continue' and the title will be changed. Else, click 'Close'"
  return <>
    <Dialog open={closeDialogState.isOpen} title={`You will loose all unsaved data`} onClose={() => {}}>
      <StyledCloseTabDialog>
        <h3>{dialogTitle}</h3>
        <div className="dialog-btns">
          <SimpoolPrimaryButton onClick={() => handleChangeTitle()}>Continue</SimpoolPrimaryButton>
          <SimpoolSecondaryButton onClick={handleCancelChangeTitle}>Cancel</SimpoolSecondaryButton>
        </div>
      </StyledCloseTabDialog>
    </Dialog>
    <StyledGamesListArrowContainer
      onClick={() => setIsOpen((state) => !state)}
    >
      {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </StyledGamesListArrowContainer>
    {isOpen && <StyledGamesListMenuContainer ref={ref as RefObject<HTMLDivElement>}>
      <header>
        <span>My games</span>
      </header>
      <ul>
        {accountTitlesQuery && accountTitlesQuery.isLoading ? null : accountTitlesQuery?.data?.map((title, index) => <li key={`titles-query-list${index}`} className={`${title.id === titleSelector?.id && 'selected'}`}>
          <a href="javascript: void(0)" onClick={() => {
            if (shouldPreventTitleChange) {
              setCloseDialogState((prev) => ({ ...prev, isOpen: true, title: title }))
              return;
            }
            handleChangeTitle(title)
            setIsOpen(false);
          }}>{title.name}</a>
        </li>)}
      </ul>
    </StyledGamesListMenuContainer>}
  </>
}



const Revenue = ({ className, title, amount, ltv, expanded, users, src, collapsed, isBorder = false, gameTitle = '' }: IRevenueProps) => {
  return <div className={className}>
    <img src={src} />
    {collapsed && gameTitle && <div className='company-title'>{gameTitle}</div>}

    {expanded && <GamesList />}

    {collapsed ? null : <RevenueContainer>
      <header>
        <b>{title}</b>
        <span>&nbsp;revenues</span>
      </header>
      <section>
        {expanded && <aside>
          <UsersBadge users={users!} />
        </aside>}
        <Money>{amount ? money(amount) : <CircularProgress size="2rem" />}</Money>
        <StyledDivider />
        <Text>LTV {ltv ? money(ltv) : <CircularProgress size="2rem" />}</Text>
      </section>
    </RevenueContainer>}
  </div>
};

export default styled(Revenue)`
  margin-bottom: 5%;
  margin-left: 15px;

  display: flex;
  flex-direction: row;
  align-items: end;
  font-size: 1rem;

  height: 85px;

  position: relative;

  header {
    & > b {
      color: ${({ variant }) => colorByVariant(variant)};
    }
  }

  section {
    padding: ${({ expanded }) => expanded ? '0 40px 0 0' : '0 40px 0 90px'};
    box-shadow: ${({ variant }) => `inset 2px 2px 4px #000000, 0px 0px 20px ${colorByVariant(variant)}`};

    & > span:first-of-type {
      color: ${({ variant }) => colorByVariant(variant)};
    }

    aside {
      margin-right: 8rem;
      padding-left: 20px;
      display: flex;
      align-items: center;
    }
  }

  img {
    border-radius: 50%;
    max-width: 80px;
    max-height: 80px;
    ${props => props.isBorder && `
    outline: 6px solid white;
    outline-offset: -6px;
  `}
    
    position: absolute;
    z-index: 1;
    top: 1rem;

    left: ${({ expanded }) => expanded ? 'calc(20% + 1rem)' : '-15px'};
  }
`;
