import { SerializedError } from "@reduxjs/toolkit";

export interface ISegmentField {
  column_name: string;
  data_type: ESegmentDataType;
  column_values: string;
}

export enum ESegmentDataType {
    Number = 'NUMBER',
    Float = 'FLOAT',
    Variant = 'VARIANT',
    Text = 'TEXT',
    Object = 'Object'
}

export interface IFilter {
    op: string | null;
    values: number[];
    column_name: string;
    column_type: string;
}

export interface ISegmentSchedule {
    days: string[];
    time: string;
}

export interface ISegment {
    segment_id: number;
    segment_name: string;
    priority: number;
    filters: IFilter[];
    status: number;
    segment_schedule: ISegmentSchedule;
    users: number;
    estimated_worth: string;
    is_template: boolean;
    query: string;
}

export interface ISegmentationState {
  segments?: ISegment[];
  fields?: ISegmentField[];
  error: {
    getAllSegments?: SerializedError;
    getAllSegmentsFields?: SerializedError;
  };
  loading: {
    getAllSegments?: boolean;
    getAllSegmentsFields?: boolean;
  };
}

export const initialState: ISegmentationState = {
  error: {},
  loading: {},
};
