import * as React from 'react';

import { addSegment, deleteSegment, editSegment, getAllFilters, getAllSegments, getSampelDataForSegemnt, getSampleDataURL } from './services/Segmentation.services';

import Add from '@mui/icons-material/Add';
import AddEditDialog from './components/AddEditDialog';
import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import SegmentsTable from '../../components/SegmentsTable/SegmentsTable';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { useState } from 'react';

export interface ISegmentsProps {
    page?: IGetPageResponse;
    title?: IGetAccountTitlesResponse;
}

const StyledContainer = styled.div`
  margin: 0px 30px 0px 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledNavigationContainer = styled.div`
  margin: 30px 30px 30px 0px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledTitleContainer = styled.div`
    display: flex;
    flex: 0 0 50%;
`;

const StyledTitle = styled.div`
    background: #404163;
    width: auto;
    padding-right: 30px;
    height: 40px;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
`;

const StyledTitleText = styled.div`
    color: white;
    font-size: 21px;
    padding-left: 15px;
    padding-top: 6px;
    font-weight: 300;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 0 0 50%;
`;

const StyledButton = styled.div`
    cursor: pointer;
    align-self: flex-end;
    width: 40px!important;
    height: 40px;
    border-radius: 50px;
    background: gray;

    &:hover {
        background: #4A9CFB;
    }
`;

const segmentTableSize = '1.1fr 0.4fr 0.5fr 0.6fr 0.5fr 0.7fr 0.7fr 0.9fr';

const Segments = ({ title, page }: ISegmentsProps) => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({});
    const [sampleName, setSampleName] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    const [sampleDataRows, setSampleDataRows] = React.useState(null);
    const [sampleSegmentsRows, setSampleSegmentsRows] = React.useState([]);
    const [sampleDataColumns, setSampleDataColumns] = React.useState([]);
    const [sampleTableSize, setSampleTableSize] = React.useState('1.1fr 0.4fr 0.5fr 0.6fr 0.5fr 0.7fr 0.7fr 0.9fr');
    const [sampleTableWidth, setSampleTableWidth] = React.useState(0);

    const segmentsDataColumns = [
        {
            header: "Segment's name",
            key: 'name',
            type: 'text',
            styles: {
                justifyContent: 'start',
                paddingLeft: 20
            }
        },
        {
            header: 'Users',
            key: 'users',
            type: 'text'
        },
        {
            header: 'Priority',
            key: 'priority',
            type: 'text'
        },
        {
            header: 'Exec time',
            key: 'exec',
            type: 'date',
            styles: {
                justifyContent: 'start',
                paddingLeft: 20
            }
        },
        {
            header: 'Is active',
            key: 'isActive',
            disabled: (item: any) => {
                return !!item.segment_schedule;
            },
            checked: (item: any) => {
                return item.original?.segment_schedule?.status === 1 ? true : false;
            },
            type: 'switch',
            onClick: (segmentId: number, status: number) => (event: any) => {
                event.stopPropagation();
                const updateStatus = async(segmentId: number, status: number) => {
                    console.log('status', status)
                    const body = {
                        title_id: title?.id,
                        schedule: {
                            status: status === 1 ? 2 : 1,
                        }
                    };
                    try {
                        Swal.showLoading();
                        await editSegment(body, segmentId);
                        const success = await fetchSegments(title?.id!);
                        if(success) {
                            Swal.close()
                        }
                        
                    } catch (error) {
                        Swal.close()
                    }
                    Swal.close()
                };
                updateStatus(segmentId, status);
                
            }
        },
        {
            header: 'Settings',
            key: 'settings',
            type: 'icons',
            styles: {
                marginRight: 10
            },
            icons: [
                {
                    type: 'edit_schedule',
                    onClick: (event: any, segment: any) => {
                        event.stopPropagation();
                        setData(segment.original);
                        // setOpen(true);
                        setHideFilters(true);
                        setOpen(true);
                    }
                },
                {
                    type: 'edit',
                    onClick: (event: any, segment: any) => {
                        event.stopPropagation();
                        setData(segment.original);
                        setOpen(true);
                        setHideFilters(false);
                    }
                },
                {
                    type: 'delete',
                    onClick: async (event: any, segment: any) => {
                        event.stopPropagation();
                        if (segment.original.is_template) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'You cant delete template!'
                            });
                            return;
                        }
                        Swal.showLoading();
                        await deleteSegment({
                            title_id: title?.id,
                            segment_id: segment.segmentID
                        });
                        await fetchSegments(title?.id!);
                        Swal.close();
                    }
                },
                {
                    type: 'download',
                    onClick: (event: any, segment: any) => {
                        event.stopPropagation();
                        Swal.showLoading();
                        fetch(getSampleDataURL(title?.id!, segment.segmentID))
                            .then(resp => resp.blob())
                            .then(blob => {
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.style.display = 'none';
                                a.href = url;
                                // the filename you want
                                a.download = 'segment-data.txt';
                                document.body.appendChild(a);
                                a.click();
                                window.URL.revokeObjectURL(url);
                                Swal.close();
                            })
                            .catch(() => alert('oh no!'));

                    }
                },
                {
                    type: 'info',
                    onClick: (event: any, segment: any) => {

                        event.stopPropagation();

                    }
                },
            ]
        },
        {
            header: 'Estimated Value',
            key: 'estimate',
            type: 'money'
        },
        {
            header: '',
            key: null,
            type: 'buttons',
            buttons: [
                {
                    type: 'simit',
                    onClick: (event: any) => {
                        event.stopPropagation();
                    }
                },
                {
                    type: 'seeit',
                    onClick: (event: any, { original: { segment_id, segment_name } }: {
                        original: {
                            segment_id: number;
                            segment_name: string;
                            priority: number;
                            filters: {
                                op: string;
                                values: number[];
                                column_name: string;
                                column_type: string;
                            }[];
                            query: string;
                            status: number;
                            segment_schedule?: any;
                            users: number;
                            estimated_worth: string;
                            is_template: boolean;
                        }
                    }) => {
                        event.stopPropagation();
                        window.open(`/segment-results?segment_id=${segment_id}&segment_name=${segment_name}&title_id=${title?.id}`, '_blank');
                    }
                },
            ]
        },
    ];

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const fetchSegments = React.useCallback(async (titleId: Number) => {
        const segments: any = await getAllSegments(titleId);

        setSampleSegmentsRows(segments.map((el: any) => {
            console.log(el);
            
            return {
                original: el,
                name: el.segment_name || '',
                users: el.users || '0',
                priority: el.priority || '',
                exec: el.segment_schedule ? ((el.segment_schedule?.schedule?.days?.map((el: any) => capitalizeFirstLetter(el)))?.join(', ') + ' ' + el.segment_schedule?.schedule?.time) : '',
                isActive: el.isActive || true,
                estimate: el.estimated_worth || '',
                segmentID: el.segment_id,
                segment_schedule: el.segment_schedule
            };
        }));
        return true;
    }, [])

    React.useEffect(() => {
        if (!title?.id) {
            return;
        }

        /* eslint-disable  @typescript-eslint/no-unused-expressions */
        (async () => {
            await fetchSegments(title?.id);
            setFilters((await getAllFilters(title?.id!)) as any);
        })();
    }, [title?.id, fetchSegments]);

    const onRowClick = async (segment: any) => {
        Swal.showLoading();
        const segments: any = await getSampelDataForSegemnt(title?.id!, segment.segmentID);
        setSampleName(segment.name);
        setSampleDataRows(segments);
        if (segments[0]) {
            const keys = Object.keys(segments[0]);
            setSampleDataColumns(keys.map((key) => {
                let label = key.split('_');
                return {
                    header: label.join(' '),
                    key: key,
                    type: 'text',
                };
            }) as any);
            setSampleTableSize((keys.map((key) => '150px')).join(' '));
            setSampleTableWidth(keys.length * 160);
            setTimeout(() => {
                for (let counter = 0; counter < document.getElementsByClassName('Segments-table').length; counter++) {
                    document.getElementsByClassName('Segments-table')[counter].scroll(0, 0);
                }
            }, 500)
        }
        Swal.close();
    };

    const handleClickOpen = () => {
        setHideFilters(false);
        setData({});
        setOpen(true);
    };

    // const handleClose = async (data: any, isEdit: boolean = false) => {
    //     setOpen(false);
    //     if (data) {
    //         let filters = data.filters.filter((filter: any) => filter.checked);
    //         let body = {
    //             segment_name: data.name,
    //             priority: 0,
    //             filters: filters.map((filter: any) => {
    //                 switch (filter.data_type) {
    //                     case 'TEXT':
    //                         return {
    //                             column_name: filter.column_name,
    //                             column_type: filter.data_type,
    //                             values: filter.selectedValues
    //                         };
    //                     case 'FLOAT':
    //                     case 'NUMBER':
    //                         let op = 'lt';
    //                         let values: any = [];
    //                         if (filter.results.eq) {
    //                             op = 'eq';
    //                             values = [filter.results.eq];
    //                         } else if (filter.results.gt) {
    //                             op = 'gt';
    //                             values = [filter.results.gt];
    //                         } else if (filter.results.lt) {
    //                             op = 'lt';
    //                             values = [filter.results.lt];
    //                         }
    //                         return {
    //                             column_name: filter.column_name,
    //                             column_type: filter.data_type,
    //                             op: op,
    //                             values: values,
    //                         };
    //                     default:
    //                         return null;
    //                 }
    //             }).filter((el: any) => el !== undefined),
    //             query: "string",
    //             title_id: title?.id,
    //             days: data.days,
    //             time: data.time
    //         };
    //         if (isEdit) {
    //             await editSegment(body, data.id);
    //         } else {
    //             await addSegment(body);
    //         }
    //         await fetchSegments(title?.id!);
    //     }
    // };
    const handleClose = async(data: any, isEdit: boolean = false, isHidenFilters: boolean = false) => {
        setOpen(false);
        if (data) {

            let filters = data.filters.filter((filter:any) => (filter.value || filter.values));
            let body: any = {
                segment_name: data.name,
                priority: 0,
                filters: filters.map((filter: any) => {
                    switch (filter.column_type) {
                        case 'TEXT':
                            return {
                                column_name: filter.column_name,
                                column_type: filter.column_type,
                                values: filter.values
                            };
                        case 'FLOAT':
                        case 'NUMBER':
                            return {
                                column_name: filter.column_name,
                                column_type: filter.column_type,
                                op: filter.op,
                                values: [filter.value],
                            };
                        default:
                            return null;
                    }
                }).filter((el: any) => el !== undefined),
                query: "string",
                title_id: title?.id,
            };
            
            if ( isHidenFilters) {
                body = prepareSchedulteBody(data);
            }
            if (isEdit) {
                if(!isHidenFilters) {
                    const schedule = {
                        days: data.days,
                        time: data.time,
                        output_config: data?.output_config || null
                    }
                    body.schedule = schedule;
                }
                
                await editSegment(body, data.id);
            } else {
                body.days = data.days;
                body.time = data.time;
                body.output_config = data?.output_config || null;
                await addSegment(body);
            }
            await fetchSegments(title?.id!);

        }
    }

    const prepareSchedulteBody = (data: any) => {
            return {
                title_id: title?.id,
                schedule: {
                    days: data.days,
                    time: data.time,
                    output_config: data?.output_config || null
                }
            }
    };

    const [hideFilters, setHideFilters ] = useState(false);
    
    return (
        <div>
            <StyledContainer>
                <StyledNavigationContainer>
                    <StyledTitleContainer>
                        <StyledTitle>
                            <StyledTitleText>Segments</StyledTitleText>
                        </StyledTitle>
                    </StyledTitleContainer>
                    <StyledButtonContainer>
                        <StyledButton onClick={handleClickOpen}>
                            <Add style={{ color: '#2C2C41', fontSize: 40 }}></Add>
                        </StyledButton>
                    </StyledButtonContainer>
                </StyledNavigationContainer>
            </StyledContainer>
            <StyledContainer>
                <SegmentsTable onRowClick={onRowClick} rows={sampleSegmentsRows} columns={segmentsDataColumns} columnSize={segmentTableSize}></SegmentsTable>
            </StyledContainer>
            {
                sampleDataRows && [
                    <StyledContainer>
                        <StyledNavigationContainer>
                            <StyledTitleContainer>
                                <StyledTitle>
                                    <StyledTitleText>{sampleName} Sample Data</StyledTitleText>
                                </StyledTitle>
                            </StyledTitleContainer>
                        </StyledNavigationContainer>
                    </StyledContainer>,
                    <StyledContainer>
                        <SegmentsTable bodyWidth={sampleTableWidth} rows={sampleDataRows} columns={sampleDataColumns} columnSize={sampleTableSize}></SegmentsTable>
                    </StyledContainer>
                ]
            }
            <AddEditDialog
                loading={false}
                selectedValue={data}
                filters={filters}
                open={open}
                onClose={handleClose}
                hideFilters={hideFilters}
                // hideSegmentName
            />
        </div>
    );
};

export default Segments;
