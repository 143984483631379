import { useHistory, useLocation } from "react-router-dom";

import { AuthService } from '../../services/auth.service';
import { OnboardingService } from "../../services/onboarding.service";
import { useDispatch } from "react-redux";
import { useEffect } from 'react';

function buildQueryString(params: Record<string, string | number | boolean>): string {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];

    // Convert boolean values to lowercase strings
    const stringValue = typeof value === 'boolean' ? String(value) : value.toString();

    searchParams.append(key, stringValue);
  }

  return searchParams.toString();
}

const OAuthCallback = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = async () => {
      const parameters = AuthService.ParseImplicitFlowResponse(location.search);

      const user = await OnboardingService.oAuthCallback(parameters.code as string);

      await dispatch({
        type: '[onboarding]/loginUser/fulfilled',
        payload: user
      })

      let state = parameters['state'] as Record<string, string>;

      if(state?.['redirect']) {
        let redirect = `${state?.['redirect']}`;

        delete state?.['redirect'];
        let url_redirect = `${redirect}?${buildQueryString(state)}`.endsWith('?') ? `${redirect}` : `${redirect}?${buildQueryString(state)}`.slice(0, -1);
        history.push(url_redirect)
      } else {
        history.push('/')
      }
    } 

    handler();
  }, [location])

  return null;
};

export default OAuthCallback;
