import { CurrencyCode, firstCharUpperCase, money, parseStringToNumber, percent } from '../../helpers/formatters';
import FilterBar, { IFilterBarPayload } from '../FilterBar/FilterBar';
import { IGetReportDataPayload, getReportData } from '../../queries/useGetReportDataQuery';
import { useEffect, useState } from 'react';

import { ConnectingAirportsOutlined } from '@mui/icons-material';
import { GetRetentionReportDataResponse } from '../../queries/useGetRetentionReportDataQuery';
import { IChartTreeItem } from '../../queries/useGetChartTreeQuery';
import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import { NoFiltersPayload } from '../UserAcquisitionTable/UserAcquisitionTable';
import RetentionTableHeader from './RetentionTableHeader';
import RetentionTableLegend from './RetentionTableLegend';
import SortByHeader from '../UserAcquisitionTable/SortByHeader';
import cn from 'classnames';
import { settingsSelector } from '../../store/settings/settings.selector';
import styled from 'styled-components';
import { useAxiosAuthorized } from '../../hooks/useAxiosAuthorized';
import { useSelector } from 'react-redux';

export interface IUserAcquisitionTable {
  className?: string;
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
}


export type MinMax = {
  min: number;
  max: number;
}

export type LegendObjectType = {
  from: number;
  to: number;
  color: string;
  type: ColumnType,
  fontColor: string;
}

export type ColumnType = 'num' | 'prec' | 'curr_num' | 'curr_dec';

const initialPayload = {
    // report_id: 13862,
    dashboard_id: 200,
    title_id: 88,
    days_back: 60,
    date_from: '-1',
    date_to: '-1',
    topic_id: 10,
    report_location: 10
}

const isFloat = (number: number) => {
  return Number(number) === number && number % 1 !== 0;
}

const generateIncrementer = (maxValue: number) => {
    return isFloat(maxValue) ? 0.01 : 1;
}


const RetentionTable = ({ className, page, title }: IUserAcquisitionTable) => {
  const [ payload, setPayload ] =useState<IGetReportDataPayload | null>(null);
  const [ data, setData ] = useState<GetRetentionReportDataResponse[] | undefined>(undefined);
  
  const [sortBy, setSortBy] = useState<{ key: keyof GetRetentionReportDataResponse, direction: 'ascending' | 'descending' } | undefined>(undefined);
  const [ max, setMax ] = useState<number>(0);
  const [min, setMin ] = useState<number>(0);
  const [maxValue, setMaxValue ] = useState<number>(0);
  const [valueType, setValueType ] = useState<ColumnType | null>(null);
  const [legendValues, setLegendValues ] = useState<LegendObjectType[] | undefined>(undefined);
  const [isDecimal, setIsDecimal] = useState<boolean>(false);
  const [tooltip, setTooltip ] = useState<string | null>(null);
  const axios = useAxiosAuthorized();
  const currencyCode = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_code}")?.['parameter_value'] as CurrencyCode;

  useEffect(() => {
    if(payload) {
      getReportData(axios, {
        ...payload,
        title_id: title?.id
      }).then((response) => {
        console.log(response)
        if(!response || response.length === 0) {
          setData(undefined);
          return;
        }

        setData(response as GetRetentionReportDataResponse[]);
        setMaxValue(calculateMaxValueLength(response!));

        if(response.length > 0) {
            const valueType = response[0].hasOwnProperty('type') ? response[0]['type'] : 'num';
            setValueType(valueType as ColumnType);
        }
      });
    }
  }, [payload, title])

  useEffect(() => {
    if(data) {
      let max: number = 0;
      let valueType: ColumnType = 'num';
     
      let values: number [] = [];
      const dataTocheck = data;
      let isDecimalFlag = false;
      dataTocheck.forEach((item) => {
        Object.keys(item).forEach(key => {
            if(key.startsWith('month')){
              if(item[key as keyof GetRetentionReportDataResponse]) {
                const parsedItem = parseStringToNumber(item[key as keyof GetRetentionReportDataResponse] as string);
                isDecimalFlag = isFloat(parsedItem);
                  values.push(parsedItem);
                  if( max  < parsedItem) {
                   max = parsedItem
                  }
              }
            }
            if(key.startsWith('type')){
              valueType = item['type'] as ColumnType;
            }
        });
      });
      setMax(max / 4);
      values.sort((a, b) => (a - b));
      setIsDecimal(isDecimalFlag);
      
      const valueLeg = generateLegendObjects(values[0], values[values.length - 1], 4, valueType, isDecimalFlag);
    
      setLegendValues(valueLeg);
      setMin(values[0]);
      setMax(values[values.length - 1]);
    }
    
  }, [data])


  useEffect(() => {
    if(data && sortBy) {
      setData((prevData) => {
        return prevData?.sort((a, b) => {
          if (sortBy.direction === 'descending') {
            if (typeof a[sortBy.key] === 'string') {
              return String(a[sortBy.key]).localeCompare(String(b[sortBy.key]))
            } else {
              return Number(a[sortBy.key]) - Number(b[sortBy.key]);
            }
          } else {
            if (typeof a[sortBy.key] === 'string') {
              return String(b[sortBy.key]).localeCompare(String(a[sortBy.key]));
            } else {
              return Number(b[sortBy.key]) - Number(a[sortBy.key]);
            }
          }
        })
      });
    }
  }, [sortBy]);

  const includeArray = ["report_id","dashboard_id", "title_id"]
  const handleFilterBar = (payloadFromFilters: IFilterBarPayload) => {
    if(payload){

      setPayload((prev) => {
        let newResult = {};
        if(prev) {
          Object.keys(prev).forEach(key => {
            if(includeArray.includes(key)){
              newResult = {
                ...newResult,
                [key]: prev[key as keyof NoFiltersPayload]
              }
            }
          })
        }

        const filterToPayload = {
          country: payloadFromFilters?.country,
          group_by: payloadFromFilters?.groupBy,
          platform: payloadFromFilters?.platform,
          source: payloadFromFilters?.source,
          days_back: payloadFromFilters?.daysBack,
          date_from: payloadFromFilters?.dateFrom,
          date_to: payloadFromFilters?.dateTo
        } as any;

        Object.keys(filterToPayload).forEach(k => filterToPayload[k] === undefined && delete filterToPayload[k]);
  
        return { ...newResult as NoFiltersPayload, ...filterToPayload} as IGetReportDataPayload;
      })
    }
  }
  
  const handleReportChange = (item: IChartTreeItem) => {
    console.log(item);
    
    setPayload((prev) => {
      const prevState  = prev ? prev : initialPayload;
      return {
        ...prevState,
        report_id: item.report_id, 
        topic_id: item.topic_id,
        report_location: item.report_location}
    })

    if(item.tooltip) {
      setTooltip(item.tooltip);
    }
  }

  const calculateMaxValueLength = (data: GetRetentionReportDataResponse[]) => {
      let maxLength = 0;
      let minLength = 0;

      const dataTocheck = data;

      dataTocheck?.forEach((item) => {
        const valueType = (item.hasOwnProperty('type') ? item['type'] : 'number') as ColumnType;

        Object.keys(item).forEach(key => {
            if(key.startsWith('month')){
              if(typeof item[key as keyof GetRetentionReportDataResponse] === 'number') {
                const trimmedItem = formatAnumber(item[key as keyof GetRetentionReportDataResponse], valueType);
                if( maxLength  < trimmedItem.length) {
                  maxLength = trimmedItem.length
                }
              }
            }
        });
      });
      return maxLength;
  }

  
  return (<>
  <FilterBar onChange={handleFilterBar} titleId={title?.id}/>
  <RetentionTableHeader onChange={handleReportChange} tooltip={tooltip}/>
  <HorizontalScrollWrapper>
    <section className={cn('user-acquisition-table', className)}>
      < StyledCohortsTableHeader>
        <ul>
          <li>
            {data && (<StyledCohortsTableItemHeaderContainer style={{gridTemplateColumns: `repeat(${Object.keys(data[0]).length - 1}, 1fr)`}}>
              { data && Object.keys(data[0]).map((header, index) => {
                if(!header.startsWith('type')) {
                  if(header.startsWith('month')) {
                    return <StyledTableLi key={`${index}-acq-header`}><span>{ header.split(/[_| ]/g).map(string => firstCharUpperCase(string)).join(' ') }</span></StyledTableLi>
                  }
                  return <SortByHeader  key={`${index}-acq-header`} header={ header.split(/[_| ]/g).map(string => firstCharUpperCase(string)).join(' ')} onSort={(direction) => setSortBy({ key: header as keyof GetRetentionReportDataResponse, direction })}/>
                }
                return null;
              })}
            </StyledCohortsTableItemHeaderContainer>)}
          </li>
        </ul>
      </StyledCohortsTableHeader>
      <StyledCohortsContent>
        <ul>
          {legendValues && data?.map((d, index) => <StyledCohortsTableItem cohort={d} key={index} devider={max} maxValueLength={maxValue} legendObject={legendValues} currencyCode={currencyCode} />)}
        </ul>
      </StyledCohortsContent>
      
      </section>
      { valueType && <RetentionTableLegend 
        values={ legendValues && legendValues}
        currencyCode={currencyCode}/>}
  </HorizontalScrollWrapper>
  </>)
}

const generateLegendObjects = (minTableValue: number, maxTableValue: number, amountOfLegends = 4, type: ColumnType, isDecimal: boolean) => {
  let pivotValue = (maxTableValue - minTableValue) / amountOfLegends;
  isDecimal ? pivotValue = parseFloat(pivotValue.toFixed(1)) : pivotValue = Math.floor(pivotValue);
  const pivotStep =  isDecimal ? 0.01 : 1;
  const results:LegendObjectType[]  = [];

  for (let index = 0; index < amountOfLegends; index++) {
    if(index === 0) {
      const legendItem: LegendObjectType = {
        from: isDecimal ?  parseFloat((minTableValue).toFixed(2)) : minTableValue,
        to: isDecimal ? parseFloat((pivotValue + minTableValue).toFixed(2)) : pivotValue + minTableValue,
        color: `hsla(212, ${100 - 10}%, ${100 - 20 * (index + 1)}%, 1)`,
        fontColor: `hsla(0, 0%, 0%, 1)`,
        type
      }
      results.push(legendItem)
    }
    else {
      const prevTo = results[index - 1].to; 
      const legendItem: LegendObjectType = {
        from: isDecimal ? parseFloat((prevTo + pivotStep).toFixed(2)) : prevTo + pivotStep,
        to:isDecimal ? parseFloat((prevTo + pivotValue).toFixed(2)) : prevTo + pivotValue,
        color: `hsla(212, ${100 - 10 * (index + 1)}%, ${100 - 20 * index}%, 1)`,
        type,
        fontColor: `hsla(360, 100%, 100%, 1)`,
      }
      results.push(legendItem)
    }
  }
  
  return results;

}
export default styled(RetentionTable)`
display: flex;
flex-direction: column;
flex: 1;

&::-webkit-scrollbar {
  width: 15px;              
}

&::-webkit-scrollbar-track {
  background: #424252;        
}

&::-webkit-scrollbar-thumb {
  background-color: #777782;    
  border: 1px solid #424252; 
}

overflow: auto;
background-color: #212134;

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

& > ul {
  display: flex;
  flex-direction: column;
  padding: 10px;
}`;

const HorizontalScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`

const StyledCohortsTableItemHeaderContainer = styled.ul`
display: grid;
grid-column-gap: 10px;
min-height: 44px;
& > li {
  background: #404052;
  text-align: center;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.8rem;
  padding: 0px 0;
  color: #E7E7E7;
}
`


export const formatAnumber = (number: number | string | Date, type: ColumnType, currencyCode?: CurrencyCode) => {
  if(typeof number === 'number') {
   if(type === 'curr_dec'){
     return money(number, undefined, undefined, undefined, currencyCode)
   } else if (type === 'curr_num') {
     return money(number, undefined, 0, undefined, currencyCode);
   } else if (type === 'prec') {
     return percent(number);
   } else if (type === 'num') {
     return number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
     });
  }
 
}
return '0';
}

const prepareFormatedCell = (value: number,  type: ColumnType, legend: LegendObjectType[], currencyCode?: CurrencyCode) => {
  let color: string = legend[legend.length - 1].color;
  let formattedNumber: string = '0';
  let fontColor: string = 'white';
  legend.forEach((legendItem, index) => {
    
    if(value >= legendItem.from && value <= legendItem.to) {
      color = legendItem.color;
      fontColor = legendItem.fontColor;
     
    } 
    formattedNumber = formatAnumber(value, type, currencyCode);
  })

  return {
    color, 
    formattedNumber,
    fontColor
  }
}

const CohortsTableItem = ({ cohort, className, devider, maxValueLength, legendObject, currencyCode }: 
  { cohort: GetRetentionReportDataResponse, 
    className?: string, 
    devider: number, 
    maxValueLength: number,
    legendObject: LegendObjectType[],
    currencyCode?: CurrencyCode }) => {

const valueType = (cohort.hasOwnProperty('type') ? cohort['type'] : 'number') as ColumnType;
 const generateStyleObject = (value:string | number, devider: number) => {
   const styleObject = {
    background: "#BFDDFF", color: "#FFFFFF"
   }
  const num = typeof value == 'number' ?  value : parseStringToNumber(value);
   if(num > -1 && num < devider - generateIncrementer(num)) {
    styleObject.background = "#BFDDFF";
    styleObject.color = 'black';
   } else if (num >= devider && num < devider * 2 - generateIncrementer(num)) {
    styleObject.background = "#91C0F7";
   } else if (num >= devider * 2 && num < devider * 3 - generateIncrementer(num)) {
    styleObject.background = "#6FAEF7";
   } else if (num >= devider * 3 ) {
    styleObject.background = "#3F96FA";
   } 

   return styleObject;
 }

 const geneRateFontSize = () => {
   if(maxValueLength <= 7) {
     return {
       fontSize: '0.87rem' 
     }
   } else if(maxValueLength >= 8 && maxValueLength < 10) {
    return {
      fontSize: '0.8rem' 
    } 
   } else if(maxValueLength >= 9) {
    return {
      fontSize: '0.6rem' 
    } 
   }
 } 



  return (<>
    <li className={cn('cohorts-table-item', className)}>
      <ul style={{gridTemplateColumns: `repeat(${Object.keys(cohort).length - 1}, 1fr)`}}>
        { cohort && Object.keys(cohort).map((key, index) => {
          let value = cohort[key as keyof GetRetentionReportDataResponse];
         
          if(!value && value !== 0){
            return <li key={`table-legend-${index}`} style={{ background: "#393A4F"}}>{ value }</li>
          } else if (key === "install_month_name") {
            let date;
            return <li  key={`table-legend-${index}`}>{ value }</li>
            
          } else if(key === "type") {
            return null;
          }
            const preparedvalues = prepareFormatedCell(value as number, valueType, legendObject);
            return <li  key={`table-legend-${index}`} style={
              {
                backgroundColor: preparedvalues.color,
                color: preparedvalues.fontColor,
                 ...geneRateFontSize()}}>{formatAnumber(value, valueType, currencyCode) }</li>
        })}
      </ul>
    </li>
  </>)
};

const StyledTableLi = styled.li`
  background: #393A4F !important;
  border-bottom: 10px solid #393A4F;
  margin-bottom: -10px;
  white-space: break-spaces;
  `

const StyledCohortsTableItem = styled(CohortsTableItem)`
  margin: 10px 0;

  & > ul {
    display: grid;
    grid-column-gap: 10px;
    
    & > li {
      &:not(:first-child) {
        border-bottom: 10px solid #393A4F;
        margin-bottom: -10px;
        font-size: 0.89vh;
      }
      padding: 0 5px;
      background: #2C2C3E;
      padding-bottom: 10px;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0.8rem;
      color: #E7E7E7;

      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7) {
        b {
          border-right: 1px solid #A8A8A8;
          padding-right: 10px;
          margin-right: 5px;
        }
      }

      

      &:nth-of-type(1) {
        p {
          background: #30314680 0% 0% no-repeat padding-box;
          border: 1px solid #939393;
          border-radius: 50%;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;

          & > span {
            min-width: 1rem;
            max-width: 1rem;
            color: #25273D;
            font-weight: bold;
            font-weight: bold;
            border-radius: 50%;
            font-size: 1rem;
            padding: 0.2rem 0.4rem;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
`

const StyledCohortsContainer = styled.div`
  margin: 0 30px;
  overflow: hidden;
`

const StyledCohortsTableHeader = styled.header`
  padding: 10px 30px 0px 65px;
  min-width: 1285px;
`

export const StyledCohortsContent = styled.main`
    overflow: auto;
    overflow-y: scroll;
    direction: rtl;
    height: 85%;
    margin: 10px 0 10px 20px;
    padding: 0 30px;

    min-width: 1300px;
    
    & > ul {
      direction: ltr;

      & > li:first-of-type {
        margin-top: 0;
      }
    }

    &::-webkit-scrollbar {
      width: 15px;              
    }

    &::-webkit-scrollbar-track {
      background: #424252;        
    }

    &::-webkit-scrollbar-thumb {
      background-color: #777782;    
      border: 1px solid #424252; 
    }
`