import Logo from '../../../../assets/logo1.png';
import styled from 'styled-components';

interface Props {
  fixed: boolean;
}

const StyledHeaderContainer = styled.header<{fixed: boolean}>`
    position:  ${({ fixed }) => fixed ? 'fixed': 'static'};
    top: 0;
    width: 100%;
    z-index: 100;
    background: #3D3E5A;
    box-shadow: 0px 3px 14px #0000006e;
    border-radius: 0px 0px 50px 50px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledLogoSection = styled.section`
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    user-select: none;
    cursor: pointer;
`

const StyledLogoWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 35px;
  display: inline-flex;
  padding: 5px 25px;
  opacity: 0.9;
  box-shadow: inset 2px 2px 4px #000000e3;
`

const StyledHeadline = styled.h2`
  margin: 0;

  color: #fff;

  b {
    color: #4A9CFB;
  }
`

const GrowthSimulatorHeader = ({ fixed }: Props) => {
  return (
    <StyledHeaderContainer fixed={fixed}>
      <StyledLogoSection>
        <StyledLogoWrapper>
          <img src={Logo} alt="Simpool Logo" width="140px" />
        </StyledLogoWrapper>
        <StyledHeadline><b>Improve</b> Your revenues</StyledHeadline>
      </StyledLogoSection>
    </StyledHeaderContainer>
  );
};

export default GrowthSimulatorHeader;
