export const colorWhite = '#FFFFFF';
export const colorOrange = '#FFFFFF';
export const inputBgColor = '#283954';

export const CARDS: Record<string, { number: number, text: string}[]> = {
  'cmo': [
    {
      number: 1,
      text: 'Use various scenarios to evalute the potential outcomes of different marketing campaign strategies'
    },
    {
      number: 2,
      text: 'Evaluate potential risks and their impact on marketing outcomes'
    },{
      number: 3,
      text: 'Optimize your marketing mix, improve campaign performance, and achieve better results overall'
    }
  ],
  'ua': [
    {
      number: 1,
      text: 'Improve the assessment of different budget scenarios and their potential impact on user acquisition metrics such as CPA, conversion rates, and overall user volume'
    },
    {
      number: 2,
      text: 'Simulate different scenarios by adjusting variables such as budget allocation, bidding strategies, and targeting for each channel'
    },{
      number: 3,
      text: 'Predict the potential outcomes of different campaign scenarios to optimize your campaign strategies, identify risks or opportunities, and allocate resources more effectively.'
    }
  ]
}