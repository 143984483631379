import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAppState } from '../store/app.state';
import { removeSnackbar } from '../store/notifications/notifications.actions';
import { useSnackbar } from 'notistack';

let displayed: any[] = [];

const useNotifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((store: IAppState) => store.notifications.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: number) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: number) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(({ key, message, options, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options?.onClose) {
                        options?.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(Number(myKey)));
                    removeDisplayed(Number(myKey));
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key!);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
