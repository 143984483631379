import { IAppState } from "./app.state"
import { combineReducers } from "@reduxjs/toolkit"
import { reducer as formReducer } from 'redux-form';
import growthSimulatorReducer from "./growthSimulator/growthSimulator.slice"
import notificationsReducer from "./notifications/notifications.slice"
import scenarioBuilderReducer from "./scenarioBuilder/scenarioBuilder.slice"
import segmentationReducer from "./segmentation/segmentation.slice";
import billingReducer from "./billing/billing.slice";
import onboardingReducer from "./onboarding/onboarding.slice";
import settingsReducer from "./settings/settings.slice"
import sharedReducer from "./shared/shared.slice"

const rootReducer = combineReducers<IAppState>({
    settings: settingsReducer,
    notifications: notificationsReducer,
    shared: sharedReducer,
    scenarioBuilder: scenarioBuilderReducer,
    segmentation: segmentationReducer,
    growthSimulator: growthSimulatorReducer,
    billing: billingReducer,
    form: formReducer,
    onboarding: onboardingReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer