import { useEffect, useState } from "react"
import { IFilter } from "../../store/segmentation/segmentation.state"
import DateRangePicker, { IPeriod } from "../DateRangePicker/DateRangePicker"
import { FiltersDropDownContainer } from "../Dropdown/MultiselectDropdown"

import styled from "styled-components"
import { DropDownType } from "../../queries/useGetDropdownValues"
import GroupBy from "../GroupBy/GroupBy"

export enum FilterType {
  Country = 'country',
  GroupBy = 'groupBy',
  Platform = 'platform',
  Source = 'source',
  Status = 'status'
}

type FilterBarProps = {
  onChange?: (payload: IFilterBarPayload) => void;
  showGroupBy?: boolean;
  titleId?: number;
  filter?: IFilterBarPayload
}

export interface IFilterBarPayload extends IPeriod {
  groupBy: string[];
  country: string[];
  platform: string[];
  source: string[];
  days_back?: number;
  date_from?: string | Date;
  date_to?: string | Date;
  segments?: IFilter[];
  daysBack?: number;
  dateFrom?: string | Date;
  dateTo?: string | Date;
}

const FilterBar = ({ onChange, showGroupBy = false, titleId, filter }: FilterBarProps) => {
  const [payload, setPayload] = useState<IFilterBarPayload>(filter as IFilterBarPayload);

  useEffect(() => {
    onChange && onChange(payload!)
  }, [payload]);

  const handleDropdown = (values: string[], filerType: FilterType) => {
    setPayload((prev) => {
      let result = {};
      if (values.length > 0) {
        result = {
          ...prev,
          [filerType]: values
        }
      } else if (prev && filerType in prev && values.length === 0) {
        for (const key in prev) {
          if (key !== filerType) {
            result = {
              ...result,
              [key]: prev[key as keyof IFilterBarPayload]
            };

          }
        }
      } else {
        result = { ...prev }
      }

      return result as IFilterBarPayload;
    })
  }
  const handleDayPicker = (payload?: IPeriod) => {
    setPayload((prev: any) => {
      return { ...prev, ...payload }
    })
  }

  return (
    <TablePanelWrapper >
      <section>
        <DateRangePicker onChange={handleDayPicker} expanded initialState={{
          daysBack: payload?.daysBack || 60,
          dateFrom: payload?.dateFrom || '-1',
          dateTo: payload?.dateTo || '-1'
        }} />
        <TablePanel>
          <DropdownWrapper countOfCells={3}>
            <FiltersDropDownContainer titleId={titleId} type={DropDownType.Countries} onChange={handleDropdown} filerType={FilterType.Country} setValues={filter && filter[FilterType.Country]!} />
            <FiltersDropDownContainer titleId={titleId} type={DropDownType.Platforms} onChange={handleDropdown} filerType={FilterType.Platform} setValues={filter && filter[FilterType.Platform]!} />
            <FiltersDropDownContainer titleId={titleId} type={DropDownType.Sources} onChange={handleDropdown} filerType={FilterType.Source} setValues={filter && filter[FilterType.Source]!} />
          </DropdownWrapper>
        </TablePanel>
      </section>
      {showGroupBy && <GroupByWrapper>
        <GroupBy callBack={handleDropdown} />
      </GroupByWrapper>}
    </TablePanelWrapper>
  )
}

export default FilterBar;

export const TablePanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  & > section {
    align-items: center;
    justify-content: center;
    display: flex;

    & > section {
      align-self: center;
    }
  }
`

export const TablePanel = styled.div`
  display: flex;
  align-items: center;
  
  width: max-content;
  
  box-sizing: border-box;
  background: #3D3E5A 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #0000006E;

  border-radius: 33px;
  margin-left: 5px;

  height: auto;
  padding: 8px 16px;
`

export const GroupByWrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 58px;
`

export const DropdownWrapper = styled.div<{ countOfCells?: number }>`
  display: grid;
  grid-column-gap: 29px;
  grid-template-columns: ${({ countOfCells }) => `repeat(${countOfCells}, 1fr)`};
`