import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { growthSimulatorApplicationTypeSelector, growthSimulatorBusinessTypeSelector, growthSimulatorRevenueModelTypeSelector, povSelector } from "../../../store/growthSimulator/growthSimulator.selector";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = {
  title?: string;
  cards?: ReactElement[];
  step?: number;
  goBackHandler?: () => void;
  hideProgressBar?: boolean
};

const GrowthSimulatorStepsWrapper = ({ title = '', cards = [], step, goBackHandler, hideProgressBar = true }: Props) => {
  return (
    <Box sx={{
      width: '100%',
      maxWidth: '1050px',
      minWidth: '525px',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      padding: '50px 50px'
      }}>
      {(step && step !== 1 && !hideProgressBar ) && <GrowthSimulatorProgressBar />}
      <Box 
        sx={{
          color: 'white',
          display: 'grid',
          gridTemplateColumns: '1fr 9fr'
        }}
        
      > 
        
          <Box sx={{
            cursor: 'pointer',
            opacity: step === 1 ? '0': '1'
          }}
          onClick={goBackHandler}>
            <ArrowBackIcon fontSize="large" />
          </Box>
        <Box sx={{
          color: 'white',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Typography
            variant="h4"
            color={'white'}
            textAlign="center"
            >
            { title }
          </Typography>
      </Box>
      </Box>
      <Box sx={{
        width: '100%',
        marginBottom: '40px',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        maxWidth: '880px',
        margin: '40px 0px',
        display: 'flex'
      }}>
        { cards }
      </Box>
    </Box>
  );
};

export default GrowthSimulatorStepsWrapper;

const GrowthSimulatorProgressBar = () => {
  const businessType = useSelector(growthSimulatorBusinessTypeSelector);
  const applicationType = useSelector(growthSimulatorApplicationTypeSelector);
  const revenueType = useSelector(growthSimulatorRevenueModelTypeSelector);
  const povType = useSelector(povSelector);

  return (
    <StyledProgressBarContainer chucksNumber={4}>
        <StyledProgressBarItem>
          <StyledProgressBarItemSquare active={!!businessType}/>
            {businessType}
        </StyledProgressBarItem>
        <StyledProgressBarItem>
          <StyledProgressBarItemSquare active={!!applicationType}/>
          {applicationType}
        </StyledProgressBarItem>
        <StyledProgressBarItem>
          <StyledProgressBarItemSquare active={!!revenueType}/>
          {revenueType}
        </StyledProgressBarItem>
        <StyledProgressBarItem>
          <StyledProgressBarItemSquare active={!!povType}/>
          {povType}
        </StyledProgressBarItem>

    </StyledProgressBarContainer>
  )
};

export { 
  GrowthSimulatorProgressBar
}

const StyledProgressBarContainer = styled.div<{ chucksNumber?: number}>`
  box-sizing: border-box;
  width: 100%;
  display: grid;
  gap: 25px;
  padding: 20px 50px;
  ${(props) => props.chucksNumber && `grid-template-columns: repeat(${props.chucksNumber}, 1fr);`}
`
const StyledProgressBarItemSquare = styled.div<{ active?: boolean}>`
  height: 100%;
  background-color: ${(props) => props.active ? '#EC6F27' : '#47679A'};
  border-radius: 5px;
  height: 20px;
  margin-bottom: 10px;
`
const StyledProgressBarItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white
`