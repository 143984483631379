import { device } from "../../helpers/device";
import styled from "styled-components";

const YoutubeEmbed = ({ embedId }: { embedId: string}) => (
  <div className="video-responsive">
    <StyledIFrame
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default YoutubeEmbed;

const StyledIFrame = styled.iframe`
  width: 100%;
  border-radius: 25.6667px;
  width: 1250px;
  height: 622px;

  @media ${device.mobileL} { 
    width: 350px !important;
    }


  @media ${device.laptopSL} {
        width: 1100px !important;
        height: 500px !important;
    }
    
    @media ${device.laptopS} {
      width: 944px !important;
      height: 467px !important;
    }
    @media ${device.laptop} {
        width: 786px !important;
        height: 400px !important;
    }
`