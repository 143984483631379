import styled from "styled-components";
import AdvantageComponent from "./AdvantageComponent";

import { Button } from "@mui/material";
import { ISubscription } from "../../store/billing/billing.state";

type Props = {
  item: ISubscription;
  onButtonClick?: () => void;
};

const SubscriptionCard = ({ item, onButtonClick }: Props) => {
  const generateHeader = (item_header: string) => {
    const spanObjectIndex = item.header.indexOf('/');
    const firstPart = spanObjectIndex !== -1 ? item_header.slice(0, spanObjectIndex + 1) : item_header;
    const spanString = spanObjectIndex !== -1 && item_header.slice(spanObjectIndex + 1);
    if(spanObjectIndex !== -1) {
      return (
        <>
        { firstPart } <span> {spanString } </span>
        </>
      )
    }
    return (
      <>
      { item_header}
      </>
    )
   
  };

  return (
    <StyledWrapper isMostPopular={item.is_popular}>
      {item.is_popular && <StyledMostPopular>MOST POPULAR</StyledMostPopular>}
      <StyledContentContainer>
        <StyledCardPreHeader>{ item.pre_header }</StyledCardPreHeader>
        <StyledCardHeader isMostPopular={item.is_popular}>
          { generateHeader(item.header)}
          <StyledCardSubHeader>{item.sub_header}</StyledCardSubHeader>
          </StyledCardHeader>
        <StyledAdvantageWrapper>
          { item.plan_data.features.map?.((advantage, index) => {
            return <AdvantageComponent key={`advantage_item_${index}`} text={advantage.text} isAdvantage={advantage.isAdvantage}/>
          })}
        </StyledAdvantageWrapper>
      </StyledContentContainer>
      <StyledButtonContainer>
          <StyledCardButton
              variant="contained"
              size="large"
              onClick={onButtonClick}
          >{item.button_text}</StyledCardButton>
        </StyledButtonContainer>
    </StyledWrapper>
  );
};

export default SubscriptionCard;

const StyledContentContainer = styled.div`
  height: 100%;
  position: relative;
`
const StyledWrapper = styled.div<{ isMostPopular?: boolean}>`
  width: ${({ isMostPopular}) => isMostPopular === true ? '300px' : '264px'};
  height: ${({ isMostPopular}) => isMostPopular === true ? '529px' : '490.41px'};
  background: ${({ isMostPopular}) => isMostPopular === true ? '#254170' : '#283954'};
  border: 2px solid #76BDF5;
  box-shadow: 4px 8px 20px 4px rgba(22, 31, 50, 0.45);
  border-radius: 12px;
  position: relative;
`

const StyledCardPreHeader = styled.div`
  margin-top: 13px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
`

const StyledCardHeader = styled.div<{ isMostPopular?: boolean}>`
  margin-top: ${({ isMostPopular}) => isMostPopular === true ? '26px' : '30px'};;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  span {
    opacity: 0.5;
    margin-left: -10px;
    font-size: 24px;
    line-height: 15px;
  }
`

const StyledCardSubHeader = styled.div`
  font-size: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #FFFFFF;
  width: 100%;
  opacity: 0.5;
  text-align: center;
  position: absolute;
`

const StyledAdvantageWrapper = styled.div`
  margin-left: 30px;
  margin-top: 30px;
`

const StyledMostPopular = styled.div<{ isMostPopular?: boolean}>`
  background: rgba(59, 135, 249, 0.55);
  border-radius: 10px 9px 0px 0px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  color: white;
`

const StyledCardButton = styled(Button)`
  width: 130px;
  height: 30px;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px  !important;
  font-family: 'DM Sans', sans-serif;
  
  &.MuiButtonBase-root{
    background: linear-gradient(90.59deg, #FF9852 -43.16%, #E9681F 104.39%);
    box-shadow: 3px 6px 20px 7px rgba(28, 39, 63, 0.25);
    border-radius: 6px;
    font-size: 13px;
    line-height: 24px  !important;
    font-weight: 400;
    text-transform: none;
    font-family: 'DM Sans', sans-serif;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 30px;
  position: absolute;
  width: 100%;
`