import * as fromActions from "./segmentation.actions";

import { ISegmentationState, initialState } from "./segmentation.state";

import { createSlice } from "@reduxjs/toolkit";

export const segmentationSlice = createSlice({
  name: "[segmentation]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fromActions.getAllSegmentsAction.fulfilled,
      (state: ISegmentationState, { payload }) => {
        state.loading.getAllSegments = false;
        state.segments = payload;
      }
    );
    builder.addCase(
      fromActions.getAllSegmentsAction.pending,
      (state: ISegmentationState) => {
        state.loading.getAllSegments = true;
        state.error.getAllSegments = undefined;
        state.segments = undefined;
      }
    );
    builder.addCase(
      fromActions.getAllSegmentsAction.rejected,
      (state: ISegmentationState, { error }) => {
        state.loading.getAllSegments = false;
        state.error.getAllSegments = error;
      }
    );

    builder.addCase(
        fromActions.getAllSegmentsFieldsAction.fulfilled,
        (state: ISegmentationState, { payload }) => {
          state.loading.getAllSegmentsFields = false;
          state.fields = payload;
        }
      );
      builder.addCase(
        fromActions.getAllSegmentsFieldsAction.pending,
        (state: ISegmentationState) => {
          state.loading.getAllSegmentsFields = true;
          state.error.getAllSegmentsFields = undefined;
          state.fields = undefined;
        }
      );
      builder.addCase(
        fromActions.getAllSegmentsFieldsAction.rejected,
        (state: ISegmentationState, { error }) => {
          state.loading.getAllSegmentsFields = false;
          state.error.getAllSegmentsFields = error;
        }
      );
  },
});

export default segmentationSlice.reducer;
