import { EGoalType, EPointOfViewType, Genre } from '../../../../store/growthSimulator/growthSimulator.state';
import { ETutorialStep, GrowthSimulatorFormData, IGrowthSimulatorWidget } from '../../models/growthSimulator.models';
import React, { useEffect, useState } from 'react';
import { change, getFormValues } from 'redux-form';
import { goalSelector, growthSimulationSaaSSelector, growthSimulationSelector, growthSimulatorBulbState, growthSimulatorGenreSelector, povSelector } from "../../../../store/growthSimulator/growthSimulator.selector";
import { useDispatch, useSelector } from "react-redux";

import { ITourStep } from "../../../../shared/tour/types";
import { ReactElement } from "react";
import Register from '../../../Landing/Register/Register';
import { StyledSimpoolTourContent } from './GrowthSimulatorTourStepContent';
import TutorialRegister from '../../../Landing/TutorialRegister/TutorialRegister';
import { convertToNumericFormatType } from '../../helpers/helpers';
import { renderToString } from 'react-dom/server';
import { simpoolTourInputContentFactory } from './GrowthSimulatorTourStepsProviderB';
import { useApplicationContext } from '../../../../Context';
import { userSelector } from '../../../../store/onboarding/onboarding.selectors';

const FIRST_TUTORIAL_ECO_TOPIC_ID = 80;
const SECOND_TUTORIAL_ECO_TOPIC_ID = 110;

export const simpoolTourRegisterComponentFactory = (pov: EPointOfViewType, text: string) =>
  ({ moveToNextStep, moveToPreviousStep }: { moveToNextStep: () => void, moveToPreviousStep: () => void }) =>
    <TutorialRegister html={text} moveToNextStep={moveToNextStep} moveToPreviousStep={moveToPreviousStep}/>


export const GrowthSimulatorTourStepsProviderA = ({ children, widgets }: { 
    children: (props: { steps: ITourStep[] }) => ReactElement,
    widgets: IGrowthSimulatorWidget[]
}) => {
    const goal = useSelector(goalSelector);
    const genre = useSelector(growthSimulatorGenreSelector);

    const growthSimulationSaaS = useSelector(growthSimulationSaaSSelector);
    const growthSimulation = useSelector(growthSimulationSelector);
    const isBulbVisible = useSelector(growthSimulatorBulbState);
    const pov = useSelector(povSelector);
    const [context, ] = useApplicationContext();
    const user = useSelector(userSelector)

    const form = useSelector(state => getFormValues('growthSimulatorForm')(state)) as { members: GrowthSimulatorFormData[]; };

    const dispatch = useDispatch();

    const [steps, setSteps] = useState<ITourStep[]>([]);

    const tutorialStepsFactory = function (): {
        [step: number]: ITourStep
      } {
        return ({
          [ETutorialStep.SecondStepEmailRegistration + SECOND_TUTORIAL_ECO_TOPIC_ID]: {
            title: widgets.find(w => w.report_location === ETutorialStep.SecondStepEmailRegistration && w.eco_topic_id === SECOND_TUTORIAL_ECO_TOPIC_ID)?.headline!,
            component: simpoolTourRegisterComponentFactory(pov!, widgets.find(w => w.report_location === ETutorialStep.SecondStepEmailRegistration && w.eco_topic_id === SECOND_TUTORIAL_ECO_TOPIC_ID)?.tooltip!),
            hasNextBtn: false,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
            width: '35%',
            disabled: Boolean(user),
            flat: true
          },
          [ETutorialStep.DetailsEndMessage]: {
            title: widgets.find(w => w.report_location === ETutorialStep.DetailsEndMessage && w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.DetailsEndMessage && w.eco_topic_id === 80)?.tooltip!}
            />),
            width: '35%',
            hasNextBtn: false,
            hasCloseBtn: false,
            hasCompleteBtn: true,
            hasPreviousBtn: false,
            // hide: context.dialogs.simulationResultsDialogOpen
          },
          [ETutorialStep.DetailsSummary]: {
            title: widgets.find(w => w.report_location === ETutorialStep.DetailsSummary && w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.DetailsSummary && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "bottom",
              targetElementDataTourId: "growth_simulator_details_section_summary",
            },
            host: {
              onClick({moveToNextStep}){
                moveToNextStep();
              }
            },
            width: '30%',
            hasNextBtn: false,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
            hide: !isBulbVisible
          },
          [ETutorialStep.DetailsInstallsSection]: {
            title: widgets.find(w => w.report_location === ETutorialStep.DetailsInstallsSection && w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.DetailsInstallsSection && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "bottom",
              targetElementDataTourId: "growth_simulator_details_section_installs",
            },
            handleNextStep(moveToNextStep) {
              moveToNextStep();
            },
            width: '30%',
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
            hide: genre === Genre.SaaS ? !growthSimulationSaaS?.simulation_id : !growthSimulation?.simulation_id!
          },
          [ETutorialStep.DetailsKPIListSection]: {
            title: widgets.find(w => w.report_location === ETutorialStep.DetailsKPIListSection&& w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.DetailsKPIListSection&& w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "bottom",
              targetElementDataTourId: "growth_simulator_details_kpi_list_section",
            },
            handleNextStep(moveToNextStep) {
              moveToNextStep();
            },
            width: '30%',
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.DetailsChartsSection]: {
            title: widgets.find(w => w.report_location === ETutorialStep.DetailsChartsSection&& w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.DetailsChartsSection&& w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "top",
              targetElementDataTourId: "growth_simulator_details_charts_section",
            },
            width: '30%',
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.NewMonthlyCustomers]: {
            title: widgets.find(w => w.report_location === ETutorialStep.NewMonthlyCustomers&& w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.NewMonthlyCustomers&& w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_goal_sass_monthly_subscribers",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].saasForm.monthly_subscribers`, convertToNumericFormatType(100)));
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.CostPerLead]: {
            title: widgets.find(w => w.report_location === ETutorialStep.CostPerLead && w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.CostPerLead && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_goal_sass_cost_per_lead",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].saasForm.cost_per_lead`, convertToNumericFormatType(20, '$')));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.LeadToCustomerConversion]: {
            title: widgets.find(w => w.report_location === ETutorialStep.LeadToCustomerConversion && w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.LeadToCustomerConversion && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_goal_sass_conversion",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].saasForm.conversion`, convertToNumericFormatType(10, undefined, '%')));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.PricingPlan]: {
            title: widgets.find(w => w.report_location === ETutorialStep.PricingPlan)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.PricingPlan)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_goal_sass_pricing_plan",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].saasForm.starter.monthly`, {
                price: convertToNumericFormatType(50, '$'),
                proportion: convertToNumericFormatType(80, undefined, '%'),
              }));
    
              dispatch(change('growthSimulatorForm', `members[${0}].saasForm.starter.annually`, {
                price: convertToNumericFormatType(45, '$'),
                proportion: convertToNumericFormatType(20, undefined, '%'),
              }));
    
              moveToNextStep();
            },
            width: '25%',
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.MonthlySubscriberChurn]: {
            title: widgets.find(w => w.report_location === ETutorialStep.MonthlySubscriberChurn && w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.MonthlySubscriberChurn&& w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_goal_sass_monthly_subscribers_churn",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].saasForm.monthly_subscribers_churn`, convertToNumericFormatType(10, undefined, '%')));
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.AnnualSubscriberChurn]: {
            title: widgets.find(w => w.report_location === ETutorialStep.AnnualSubscriberChurn&& w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.AnnualSubscriberChurn&& w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_goal_sass_annual_subscribers_churn",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].saasForm.annual_subscribers_churn`, convertToNumericFormatType(2, undefined, '%')));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.PredictedMonths]: {
            title: widgets.find(w => w.report_location === ETutorialStep.PredictedMonths&& w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.PredictedMonths&& w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_goal_sass_predicted_months",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].saasForm.predicted_months`, convertToNumericFormatType(24)));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.RetentionNumbers]: {
            title: 'Retention Numbers',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 10 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_retention_numbers",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].retentionNumbers[0]`, {
                day: 1,
                value: convertToNumericFormatType(45, undefined, '%')
              }));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
          },
          [ETutorialStep.Days]: {
            title: 'Days',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 30 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_calculator_list_item_days",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].calculator.days`, convertToNumericFormatType(365)));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
          },
          [ETutorialStep.CPI]: {
            title: 'CPI',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 40 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_calculator_list_item_cpi",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].calculator.cpi`, convertToNumericFormatType(2, '$')));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
          },
          [ETutorialStep.OrganicUplift]: {
            title: 'Organic Uplift',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 50 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_calculator_list_item_organic_uplift",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].calculator.organicUplift`, convertToNumericFormatType(30, undefined, '%')));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
          },
          [ETutorialStep.Conversion]: {
            title: 'Conversion',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 70 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_calculator_list_item_conversion",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].calculator.conversion`, convertToNumericFormatType(5, undefined, '%')));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
          },
          [ETutorialStep.ARPPU]: {
            title: 'ARPPU',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 60 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_calculator_list_item_arppu",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].calculator.arppu`, convertToNumericFormatType(50, undefined, '$')));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false,
          },
          [ETutorialStep.DauGoal]: {
            title: 'DAU Goal',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 90 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_calculator_list_item_dau_goal",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].calculator.dauGoal`, convertToNumericFormatType(20000)));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.Budget]: {
            title: 'Budget',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 80 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_calculator_list_item_budget",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].calculator.budget`, convertToNumericFormatType(100000, '$')));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.DailyInstalls]: {
            title: widgets.find(w => w.report_location === 20 && w.eco_topic_id === 80)?.headline!,
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 20 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "right",
              targetElementDataTourId: "growth_simulator_details_calculator_list_item_daily_installs",
            },
            handleNextStep(moveToNextStep) {
              dispatch(change('growthSimulatorForm', `members[${0}].calculator.dailyInstalls`, convertToNumericFormatType(1000)));
    
              moveToNextStep();
            },
            hasNextBtn: true,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.RunButton]: {
            title: 'Run Button',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === 75 && w.eco_topic_id === 80)?.tooltip!}
            />),
            arrow: {
              direction: "auto",
              targetElementDataTourId: "growth_simulator_details_calculator_run_button",
            },
            host: {
              onClick: ({ moveToNextStep }) => {
                moveToNextStep()
              }
            },
            hasNextBtn: false,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
          [ETutorialStep.RunButtonSaaS]: {
            title: 'Run Button',
            htmlDescription: renderToString(<StyledSimpoolTourContent pov={pov}
              text={widgets.find(w => w.report_location === ETutorialStep.RunButtonSaaS)?.tooltip!}
            />),
            arrow: {
              direction: "auto",
              targetElementDataTourId: "growth_simulator_details_calculator_run_button",
            },
            host: {
              onClick: ({ moveToNextStep }) => {
                moveToNextStep()
              }
            },
            hasNextBtn: false,
            hasCloseBtn: false,
            hasCompleteBtn: false,
            hasPreviousBtn: false
          },
        })
      }
    
      useEffect(() => {
        if (form && widgets?.length) {
          let tutorialSteps = tutorialStepsFactory();
          
          switch (genre) {
            case Genre.SaaS:
              setSteps([
                tutorialSteps[ETutorialStep.NewMonthlyCustomers],
                tutorialSteps[ETutorialStep.CostPerLead],
                tutorialSteps[ETutorialStep.LeadToCustomerConversion],
                tutorialSteps[ETutorialStep.PricingPlan],
                tutorialSteps[ETutorialStep.MonthlySubscriberChurn],
                tutorialSteps[ETutorialStep.AnnualSubscriberChurn],
                tutorialSteps[ETutorialStep.PredictedMonths],
                tutorialSteps[ETutorialStep.SecondStepEmailRegistration + SECOND_TUTORIAL_ECO_TOPIC_ID],
                // tutorialSteps[ETutorialStep.DetailsInstallsSection],
                // tutorialSteps[ETutorialStep.DetailsKPIListSection],
                // tutorialSteps[ETutorialStep.DetailsChartsSection],
                // tutorialSteps[ETutorialStep.DetailsSummary],
                tutorialSteps[ETutorialStep.DetailsEndMessage],
              ]);
            break;
            case Genre.Gaming:
              switch (goal) {
                case EGoalType.BudgetGoal:
                    setSteps([
                      tutorialSteps[ETutorialStep.RetentionNumbers],
                      tutorialSteps[ETutorialStep.Budget],
                      tutorialSteps[ETutorialStep.Days],
                      tutorialSteps[ETutorialStep.CPI],
                      tutorialSteps[ETutorialStep.OrganicUplift],
                      tutorialSteps[ETutorialStep.Conversion],
                      tutorialSteps[ETutorialStep.ARPPU],
                      tutorialSteps[ETutorialStep.SecondStepEmailRegistration + SECOND_TUTORIAL_ECO_TOPIC_ID],
                      // tutorialSteps[ETutorialStep.DetailsInstallsSection],
                      // tutorialSteps[ETutorialStep.DetailsKPIListSection],
                      // tutorialSteps[ETutorialStep.DetailsChartsSection],
                      // tutorialSteps[ETutorialStep.DetailsSummary],
                      tutorialSteps[ETutorialStep.DetailsEndMessage],
                    ]);
                  break;
                case EGoalType.DailyActiveUsersGoal:
                  setSteps([
                    tutorialSteps[ETutorialStep.RetentionNumbers],
                    tutorialSteps[ETutorialStep.DauGoal],
                    tutorialSteps[ETutorialStep.Days],
                    tutorialSteps[ETutorialStep.CPI],
                    tutorialSteps[ETutorialStep.OrganicUplift],
                    tutorialSteps[ETutorialStep.Conversion],
                    tutorialSteps[ETutorialStep.ARPPU],
                    tutorialSteps[ETutorialStep.SecondStepEmailRegistration + SECOND_TUTORIAL_ECO_TOPIC_ID],
                    // tutorialSteps[ETutorialStep.DetailsInstallsSection],
                    // tutorialSteps[ETutorialStep.DetailsKPIListSection],
                    // tutorialSteps[ETutorialStep.DetailsChartsSection],
                    // tutorialSteps[ETutorialStep.DetailsSummary],
                    tutorialSteps[ETutorialStep.DetailsEndMessage],
                  ]);
                  break;
                case EGoalType.FreeStyle:
                  setSteps([
                    tutorialSteps[ETutorialStep.RetentionNumbers],
                    tutorialSteps[ETutorialStep.DailyInstalls],
                    tutorialSteps[ETutorialStep.Days],
                    tutorialSteps[ETutorialStep.CPI],
                    tutorialSteps[ETutorialStep.OrganicUplift],
                    tutorialSteps[ETutorialStep.Conversion],
                    tutorialSteps[ETutorialStep.ARPPU],
                    tutorialSteps[ETutorialStep.SecondStepEmailRegistration + SECOND_TUTORIAL_ECO_TOPIC_ID],
                    // tutorialSteps[ETutorialStep.DetailsInstallsSection],
                    // tutorialSteps[ETutorialStep.DetailsKPIListSection],
                    // tutorialSteps[ETutorialStep.DetailsChartsSection],
                    // tutorialSteps[ETutorialStep.DetailsSummary],
                    tutorialSteps[ETutorialStep.DetailsEndMessage],
                  ]);
                  break;
                default:
                  throw new Error('Not Implemented');
              }
            break;
            default:
              throw new Error('Not Implemented');
          }
          
        }
      }, [goal, form, widgets, growthSimulationSaaS, isBulbVisible, context.dialogs.simulationResultsDialogOpen, user])
    
    
    return children({ steps });
}
