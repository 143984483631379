import * as Yup from 'yup';

import { Box, FormControlLabel, FormGroup, LinearProgress, Link, Typography } from "@mui/material";
import { EGoalType, Genre } from '../../../store/growthSimulator/growthSimulator.state';
import { StyledCheckbox, StyledLandingButton, StyledLandingPageInnerContainer, StyledLandingPageWrapper, StyledTextField } from "../styles";
import { goalSelector, growthSimulatorGenreSelector } from '../../../store/growthSimulator/growthSimulator.selector';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userRegisteredErrorSelector, userRegisteredLoadingSelector, userSelector } from "../../../store/onboarding/onboarding.selectors";

import { AppDispatch } from '../../../store/app.state';
import GoogleLogin from '../../../shared/GoogleLogin/GoogleLogin';
import { OnboardingService } from '../../../services/onboarding.service';
import { Link as RouterLink } from 'react-router-dom';
import SimpoolDialog from '../../../components/Dialog/Dialog';
import { StyledBackButton } from "../../GrowthSimulatorV2/components/GrowthSimulatorButtons/GrowthSimulatorButtons";
import { StyledCloseTabDialog } from "../../ScenarioBuilder/components/ScenarioBuilderWrapper/ScenarioBuilderWrapper";
import StyledPasswordInput from '../StyledPasswordInput';
import { registerUser } from "../../../store/onboarding/onboarding.actions";
import { useApplicationContext } from '../../../Context';
import { useFormik } from "formik";

export interface IRegisterProps {
  html?: string;
  moveToPreviousStep?: () => void;
  moveToNextStep?: () => void;
}

const TutorialRegister = ({ html, moveToNextStep }: IRegisterProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const userRegistered = useSelector(userSelector);

  const error = useSelector(userRegisteredErrorSelector);
  const [verifyEmailDialogOpen, setVerifyEmailDialogOpen] = useState(false);
  const userRegisteredLoading = useSelector(userRegisteredLoadingSelector);
  const [context] = useApplicationContext();

  const goal = useSelector(goalSelector);
  const genre = useSelector(growthSimulatorGenreSelector);

  const getSimulationId = () => {
    switch (genre) {
      case Genre.Gaming:
        switch (goal) {
          case EGoalType.BudgetGoal:
            return 1300;
          case EGoalType.DailyActiveUsersGoal:
            return 1298;
          case EGoalType.FreeStyle:
            return 102104;
          default:
            return -1;
        }
      case Genre.SaaS:
        switch (goal) {
          case EGoalType.BudgetGoal:
            return 1187;
          case EGoalType.DailyActiveUsersGoal:
            return 1187;
          case EGoalType.FreeStyle:
            return 1187;
          default:
            return -1;
        }
    }
  }

  const validationSchema = Yup.object({
    first_name: Yup.string().required('First name is required').min(2, 'First name should be more than 2 characters'),
    email: Yup.string().required('Email is required').email('Please enter a valid email'),
    password: Yup.string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Password should contain one digit, one small letter, one capital letter and a symbol')
  })

  useEffect(() => {
    if (userRegistered) {
      setVerifyEmailDialogOpen(true);
    }
  }, [userRegistered])

  const { validateForm, values, handleChange, handleSubmit, handleBlur, errors, touched, isValid } = useFormik({
    initialValues: {
      first_name: '',
      email: '',
      password: '',
      accepted_marketing: true
    },
    validationSchema,
    onSubmit: async ({
      first_name,
      email,
      password,
      accepted_marketing
    }) => {
      await dispatch(registerUser({
        acceptedMarketing: accepted_marketing,
        firstName: first_name,
        email,
        password,
      }))

      const btn = document.querySelector(`[data-tour-id="growth_simulator_details_calculator_run_button"]`) as HTMLElement;
      btn.click();

      moveToNextStep && moveToNextStep();
    }
  });

  return (
    <>
      <SimpoolDialog
        open={verifyEmailDialogOpen}
        title={`Thank you for registering, please verify your email!`}
        onClose={() => setVerifyEmailDialogOpen(false)}
      >
        <StyledCloseTabDialog>
          <div className="dialog-btns">
            <StyledBackButton onClick={() => setVerifyEmailDialogOpen(false)}>
              Ok
            </StyledBackButton>
          </div>
        </StyledCloseTabDialog>
      </SimpoolDialog>
      <StyledLandingPageWrapper style={{ maxHeight: 'none', borderRadius: '5px' }}>
        {userRegisteredLoading && <LinearProgress></LinearProgress>}
        <StyledLandingPageInnerContainer style={{
          padding: '15px',
          minHeight: 'initial',
        }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
              <Typography
                variant="h6"
                color={'white'}
                textAlign="center"
                fontFamily={['DM Sans, sans-serif']}
                fontWeight={400}
              >
                <span dangerouslySetInnerHTML={{ __html: html! }}></span>
              </Typography>
            </Box>
            <form onSubmit={handleSubmit} style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <Box sx={{ width: '70%', marginBottom: '15px' }}>
                <StyledTextField
                  id="first_name"
                  placeholder="First Name"
                  name="first_name"
                  fullWidth
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.first_name}
                  helperText={errors.first_name}
                />
              </Box>
              <Box sx={{ width: '70%', marginBottom: '15px' }}>
                <StyledTextField
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  fullWidth
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(errors.email && touched.email)}
                  helperText={errors.email}
                />
              </Box>
              <Box sx={{ width: '70%', marginBottom: '15px' }}>
                <StyledPasswordInput
                  id="password"
                  placeholder="Password"
                  fullWidth
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Box>
              {error && <Box sx={{ width: '70%', display: 'flex', justifyContent: 'center', marginBottom: '20px', color: 'red' }}>
                {error?.message}
              </Box>}

              <Box sx={{ width: '70%', marginBottom: '10px' }}>
                <StyledLandingButton disabled={!isValid} variant="contained" size="large" type="submit" style={{
                  width: '100%',
                  height: '56px',
                  marginTop: '0'
                }}>Register</StyledLandingButton>
              </Box>

              <Box sx={{ width: '70%', marginBottom: '10px' }}>
                <Typography
                  variant="h6"
                  color={'white'}
                  textAlign="center"
                  fontFamily={['DM Sans, sans-serif']}
                  fontWeight={400}
                >
                  Or
                </Typography>
              </Box>

              <Box sx={{ width: '70%', marginBottom: '10px' }}>
                <GoogleLogin onClick={async () => {
                  const { redirect_uri } = await OnboardingService.googleAuthorize({
                    goal: goal?.toString()!,
                    genre,
                    redirect: 'growth-simulator-v2/details',
                    step: ((context?.tourStep || 0) + 1).toString()!,
                    simulation_id: getSimulationId().toString()
                  })

                  window.location.href = redirect_uri;
                }}></GoogleLogin>
              </Box>

              <Box sx={{ width: '100%', marginBottom: '10px' }}>
                <FormGroup>
                  <FormControlLabel control={<StyledCheckbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="accepted_marketing"
                    name="accepted_marketing"
                    defaultChecked />}
                    label={
                      <Typography
                        variant="subtitle2"
                        color={'rgba(255, 255, 255, 0.7)'}
                        fontFamily={['DM Sans, sans-serif']}>

                        I would like to receive the Marketing Materials
                      </Typography>
                    } />
                </FormGroup>
              </Box>
              <Box sx={{ width: '100%', marginBottom: '10px' }}>
                <Typography
                  variant="subtitle1"
                  color={'rgba(255, 255, 255, 0.7)'}
                  fontFamily={['DM Sans, sans-serif']}
                  fontSize={13}>
                  By registering, I accept the <Link href="https://simpool.io/terms-of-use/" color='#FFFFFF' target="_blank">Terms and Conditions</Link> of this application
                </Typography>
              </Box>

            </form>
            <Box sx={{ width: '100%', marginBottom: '10px' }}>
              <Typography
                variant="subtitle1"
                color='#FFFFFF'
                fontFamily={['DM Sans, sans-serif']}>
                Already have an account? <RouterLink to={'login'} style={{ color: 'white' }}>Login</RouterLink>
              </Typography>
            </Box>
          </Box>

        </StyledLandingPageInnerContainer>
      </StyledLandingPageWrapper>
    </>

  );
};

export default TutorialRegister;
