import { EAddType, ICohort, INode } from '../../models/models';
import { IScenario, IScenarioBuilderConfiguration, IScenarioTabs } from '../../../../store/scenarioBuilder/scenarioBuilder.state';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { SimpoolPrimaryButton, SimpoolSecondaryButton } from '../../../../components/Buttons/Buttons';
import { activeScenarioTabIdSelector, scenarioTabCohortFiltersSelector, scenarioTabCohortSelector, scenarioTabNodesSelector, scenarioTabsSelector } from '../../../../store/scenarioBuilder/scenarioBuilder.selector';
import { addScenarioTab, addScenarioTabNode, changeScenarioTabNode, deleteScenarioTab, deleteScenarioTabNode, getDefaultCohort, resetScenarioCohort, setActiveScenarioTabId, setScenarioTabCohort, setScenarioTabFilter, setScenarioTabs } from '../../../../store/scenarioBuilder/scenarioBuilder.actions';
import { dfs, flattenTree } from '../../helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { AppDispatch } from '../../../../store/app.state';
import Dialog from '../../../../components/Dialog/Dialog';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IFilterBarPayload } from '../../../../components/FilterBar/FilterBar';
import { IGetAccountTitlesResponse } from '../../../../services/accountTitles.service';
import ScenarioBuilderFooter from '../ScenarioBuilderFooter';
import ScenarioBuilderHeader from '../ScenarioBuilderHeader/ScenarioBuilderHeader';
import { ScenarioBuilderStyledActionTab } from '../ScenarioBuilderStyledActionTab/ScenarioBuilderStyledActionTab';
import { ScenarioBuilderStyledInput } from '../ScenarioBuilderStyledInput/ScenarioBuilderStyledInput';
import { ScenarioBuilderStyledInputContainer } from '../ScenarioBuilderStyledInputContainer/StyledScenarioInputContainer';
import { ScenarioBuilderStyledMain } from '../ScenarioBuilderStyledMain/ScenarioBuilderStyledMain';
import { ScenarioBuilderStyledTab } from '../ScenarioBuilderStyledTab/ScenarioBuilderStyledTab';
import { ScenarioBuilderStyledTabContext } from '../ScenarioBuilderStyledTabContext/ScenarioBuilderStyledTabContext';
import { ScenarioBuilderStyledTabList } from '../ScenarioBuilderStyledTabList/ScenarioBuilderStyledTabList';
import { ScenarioBuilderStyledTabPanel } from '../ScenarioBuilderStyledTabPanel/ScenarioBuilderStyledTabPanel';
import ScenarioBuilderSubHeader from '../ScenarioBuilderSubHeader/ScenarioBuilderSubHeader';
import StyledWhatIf from '../WhatIf';
import Xarrow from 'react-xarrows';
import { enqueueSnackbar } from '../../../../store/notifications/notifications.actions';
import styled from 'styled-components';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export interface IScenarioBuilderWrapperProps {
  className?: string;
  configuration: IScenarioBuilderConfiguration;
  scenarios?: IScenario[];
  title?: IGetAccountTitlesResponse;

  onCreate?: (scenario: INode[], name: string, cohort: ICohort, filter: IFilterBarPayload) => void;
  onSimulate?: (selectedScenarios: { [key: string]: IScenario }) => void;
}

const StyledAddButton = styled.div`
    background: #5C616A;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
`

function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export const ScenarioBuilderWrapper = ({
  className,
  configuration,
  scenarios: __scenarios,
  onCreate,
  onSimulate,
  title
}: IScenarioBuilderWrapperProps) => {
  const defaultFilters = JSON.stringify({
    daysBack: 60,
    dateFrom: '-1',
    dateTo: '-1'
  });

  const dispatch = useDispatch<AppDispatch>();
  const value = useSelector(activeScenarioTabIdSelector())
  const scenarioTabs = useSelector(scenarioTabsSelector);
  const currentCohort = useSelector(scenarioTabCohortSelector(value)) as ICohort | undefined;
  const currentFilters = useSelector(scenarioTabCohortFiltersSelector(value)) as IFilterBarPayload | undefined;
  // const scenarioAdded = useSelector(scenarioAddedSelector()) as boolean;

  const { state: cohortState } = useLocation<ICohort | undefined>();

  const state = useSelector(scenarioTabNodesSelector());

  useEffect(() => {
    if (scenarioTabs && scenarioTabs.length && scenarioTabs.length > 0) {
      if (cohortState && cohortState.cohort) {
        const newScenarioId = onScenarioTabAdd();
        const preFilters = cohortState.cohort.split(',');
        const newFilters: IFilterBarPayload = {
          ...currentFilters!,
          platform: [preFilters[0].trim()],
          country: [preFilters[1].trim()],
          source: [preFilters[2].trim()]
        };
        dispatch(setScenarioTabFilter(newFilters, newScenarioId));
      }
    } else {
      const newScenarioId = onScenarioTabAdd();
      if (cohortState && cohortState.cohort) {
        const preFilters = cohortState.cohort.split(',');
        const newFilters: IFilterBarPayload = {
          ...currentFilters!,
          platform: [preFilters[0].trim()],
          country: [preFilters[1].trim()],
          source: [preFilters[2].trim()]
        };
        dispatch(setScenarioTabFilter(newFilters, newScenarioId));
      }
    }
  }, []);


  const memoizedRequest = (tabId: string, titleId: number, filter?: IFilterBarPayload) => {
    dispatch(getDefaultCohort({ titleId, filter }))
      .then(unwrapResult)
      .then(result => {
        if (result[0]) {
          dispatch(setScenarioTabCohort(result[0], tabId))
        }
      })
  };


  useEffect(() => {
    if (!currentFilters) {
      return;
    }
    if (value) {
      const filtersString = JSON.stringify(currentFilters);

      if (scenarioTabs.find(sc => sc.id === value)?.useCohort && filtersString !== defaultFilters) {
        if (title?.id) {
          dispatch(resetScenarioCohort(value));
          memoizedRequest(value, title.id, currentFilters)
        }
      } else if (!scenarioTabs.find(sc => sc.id === value)?.useCohort) {
        if (title?.id) {
          dispatch(resetScenarioCohort(value));
          memoizedRequest(value, title.id, currentFilters)
        }
      }
    }
  }, [currentFilters, title?.id]);

  useEffect(() => {
    if (value && !currentCohort) {
      if (title?.id) {
        memoizedRequest(value, title?.id);
      }
    }
  }, [value, title?.id]);

  const handleScenarioChange = (event: React.SyntheticEvent, value: string) => {
    dispatch(setActiveScenarioTabId(value));
  };

  const render = (nodes: INode[], parentId?: string): ReactElement | null => {
    if (!nodes?.length) {
      return null;
    }

    return <>
      {
        nodes?.map((node) => {
          const { id, addType } = node;

          const handleOnDescriptionChange = (text: string): void => {
            let workflow = clone(state[value!]);
            let current = dfs(workflow, ({ id }: INode) => id === node?.id);
            current!.description = text;

            dispatch(changeScenarioTabNode({
              scenarioId: value!,
              nodes: workflow
            }))
          };

          const handleOnChange = (
            object: string,
            val: { [key: string]: string | number | string[] }[],
            changes: { [key: string]: string | number| string[] }[] | undefined,
            description: string | undefined
          ): void => {
            
            let workflow = clone(state[value!]);
            let current = dfs(workflow, ({ id }: INode) => id === node?.id);

            current!.object = object;
            current!.value = val;
            current!.changes = changes;
            current!.description = description;
            
            dispatch(changeScenarioTabNode({
              scenarioId: value!,
              nodes: workflow
            }))
          };

          const handleOnAdd = (addType: EAddType, node?: INode) => {
            dispatch(addScenarioTabNode({
              scenarioId: value!,
              node: {
                id: uuidv4(),
                children: [],
                addType,
                depth: Number(node?.depth) === 0 ? 1 : Number(node?.depth),
                parentTabId: value!
              }
            }));
          };

          const handleOnDelete = ({ id }: INode) => {
            let workflow = clone(state[value!]);

            let previous = dfs(workflow, ({ id }: INode) => id === parentId);

            workflow = workflow.filter(node => node.id !== id);

            if (previous?.children) {
              previous.children = previous?.children.filter(x => x.id !== id);
            }

            dispatch(deleteScenarioTabNode({
              scenarioId: value!,
              nodes: workflow
            }))
          };

          const handleOnDuplicate = (node: INode) => {
            function duplicateNode(): INode {
              return {
                id: uuidv4(),
                children: flattenTree(node?.children)?.map(x => clone({
                  ...x,
                  id: uuidv4()
                })) || [],
                addType: node?.addType,
                depth: node?.depth,
                value: node?.value,
                object: node?.object
              };
            }

            let workflow = clone(state[value!]);

            let current = dfs(workflow, ({ id }: INode) => id === parentId);

            if (current?.addType === EAddType?.Or) {
              workflow.push(duplicateNode())
            } else {
              current?.children.push(duplicateNode());
            }

            dispatch(changeScenarioTabNode({
              scenarioId: value!,
              nodes: workflow
            }))
          };

          const rootNode = state[node?.parentTabId!]?.[0];

          const displayArrow = rootNode?.id !== node.id;

          return <>
            <StyledWhatIf node={node} configuration={configuration} key={`whatif-${id}`}
              onDescriptionChange={handleOnDescriptionChange}
              onChange={handleOnChange}
              onAdd={handleOnAdd}
              onDelete={handleOnDelete}
              onDuplicate={handleOnDuplicate}
            />
            {displayArrow ? <Xarrow key={`xarrow-${id}`} start={parentId!} end={id} path="grid" labels={{
              end: addType ? <h3 style={{
                color: 'black',
                background: addType === EAddType.And ? 'green' : 'yellow',
                fontSize: '0.8rem',
                position: 'relative',
                padding: '5px',
                textTransform: 'uppercase',
                top: addType === EAddType.Or ? '-60px' : '-40px',
                left: addType === EAddType.Or ? '-25px' : '-40px'
              }}
              >{addType}</h3> : undefined
            }}
              strokeWidth={2}
              color="#3F495D"
              startAnchor={addType === EAddType.Or ? { position: 'auto', offset: { x: -250 } } : { position: 'bottom', offset: { x: -250 } }}
              endAnchor={addType === EAddType.Or ? { position: 'left', offset: { x: 0 } } : { position: 'auto', offset: {} }}
            /> : null}
            <>
              {render(node?.children, id)}
            </>
          </>
        })
      }
    </>
  }

  const setScenarioName = (newName: string, scenarioId: string) => {
    let updatedScenarios = JSON.parse(JSON.stringify(scenarioTabs)) as IScenarioTabs[];
    updatedScenarios[updatedScenarios.findIndex(sc => sc.id === scenarioId)].name = newName;
    dispatch(setScenarioTabs(updatedScenarios))
  };

  const handleOnEdit = (scenario: IScenario): void => {
    if (!scenario.workflow) {
      return;
    }

    if (scenarioTabs?.find(s => s.name === scenario.name)) {
      dispatch(enqueueSnackbar({
        message: `You are currently editing scenario: ${scenario.name}`,
        options: {
          variant: "error",
        },
      }))

      return;
    }

    const scenarioTabId = uuidv4();

    const scenarioTab: IScenarioTabs = {
      name: scenario.name,
      id: scenarioTabId
    };

    dispatch(addScenarioTab(scenarioTab));
    dispatch(setActiveScenarioTabId(scenarioTabId));
    scenario.cohort_key && dispatch(setScenarioTabFilter(scenario.cohort_key, scenarioTabId))
    const workflow: INode[] = JSON.parse(JSON.stringify(scenario.workflow));

    dfs(workflow, (current, previous) => {
      current.parentTabId = scenarioTabId;

      return false;
    })

    workflow.forEach(node => dispatch(addScenarioTabNode({
      scenarioId: scenarioTabId,
      node
    })))
  };

  const onScenarioTabAdd = () => {
    const newId = uuidv4();
    dispatch(addScenarioTab({ name: `Scenario ${scenarioTabs.length + 1}`, id: newId }));
    dispatch(addScenarioTabNode({
      scenarioId: newId, node: {
        id: uuidv4(),
        addType: EAddType.Or,
        depth: 0,
        children: [],
        parentTabId: newId
      }
    }));

    dispatch(setActiveScenarioTabId(newId));
    return newId;
  };


  const [closeDialogState, setCloseDialogState] = useState<{ isOpen: boolean, tabId: string | undefined, title: string | undefined }>({
    isOpen: false,
    tabId: undefined,
    title: undefined
  });

  const handleCloseDialog = () => {
    setCloseDialogState((prev) => ({ ...prev, isOpen: false }))
  };

  const handleOpenDialog = (tabId: string, title: string) => () => {
    setCloseDialogState((prev) => ({ ...prev, isOpen: true, tabId, title }))
  };

  const handleDeleteScenario = useCallback(() => {
    dispatch(deleteScenarioTab(closeDialogState.tabId!));
    setCloseDialogState((prev) => ({ ...prev, isOpen: false, tabId: undefined }))

  }, [closeDialogState.tabId, dispatch]);
  const dialogTitle = "Click 'Cancel' and then 'Add' before closing in order to save the scenario's data. Else, click 'Close'"
  return <>
    <Dialog open={closeDialogState.isOpen} title={`You will loose all data of the scenario - ${closeDialogState.title || ''}.`} onClose={handleCloseDialog}>
      <StyledCloseTabDialog>
        <h3>{dialogTitle}</h3>
        <div className="dialog-btns">
          <SimpoolPrimaryButton onClick={handleDeleteScenario}>Close</SimpoolPrimaryButton>
          <SimpoolSecondaryButton onClick={handleCloseDialog}>Cancel</SimpoolSecondaryButton>
        </div>
      </StyledCloseTabDialog>
    </Dialog>
    <section className={className}>
      {(scenarioTabs.length !== 0 && value) && (<ScenarioBuilderStyledTabContext value={value}>
        <ScenarioBuilderStyledTabList onChange={handleScenarioChange}>
          {scenarioTabs.map(({ name, id }, index) => <ScenarioBuilderStyledTab
            key={id}
            value={id}
            disableRipple
            disableTouchRipple
            disableFocusRipple
            icon={<ScenarioBuilderStyledInputContainer>
              <ScenarioBuilderStyledInput defaultValue={name}
                onChange={({ target: { value } }) => setScenarioName(value, id)}
              />
              <HighlightOffIcon onClick={handleOpenDialog(id, name)} />
            </ScenarioBuilderStyledInputContainer>}
          />)}
          <ScenarioBuilderStyledActionTab
            icon={<StyledAddButton><AddIcon /></StyledAddButton>}
            onClick={onScenarioTabAdd}
          />
        </ScenarioBuilderStyledTabList>
        {scenarioTabs.map(({ name, id }, index) => <ScenarioBuilderStyledTabPanel key={id} value={id}>
          <ScenarioBuilderStyledMain>
            <ScenarioBuilderHeader cohort={currentCohort} currentFilter={currentFilters} />
            <ScenarioBuilderSubHeader onAdd={() => {
              const currentNode = flattenTree(state[id])?.filter(w => w.addType === EAddType.Or);
              onCreate && onCreate(currentNode, name, currentCohort!, currentFilters!);
            }}
              onFilter={(payload) => dispatch(setScenarioTabFilter(payload, id))}
              filter={currentFilters}
              titleId={title?.id}
            />
            <section>
              <section>
                {
                  value && render(state[id!], state[id!] && state[id!][0]?.id)
                }
              </section>
            </section>
          </ScenarioBuilderStyledMain>
        </ScenarioBuilderStyledTabPanel>)}
      </ScenarioBuilderStyledTabContext>)}
      {__scenarios?.length ? <ScenarioBuilderFooter
        onSimulation={(selectedScenarios) => onSimulate && onSimulate(selectedScenarios)}
        cohort={currentCohort!}
        onDelete={() => { }}
        onEdit={handleOnEdit}
        scenarios={__scenarios!}
      /> : null}
    </section>
  </>
};

export default styled(ScenarioBuilderWrapper)`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 85%;

    .dialog-buttons{
      align-items: center;
      display: flex;
    }
`;

export const StyledCloseTabDialog = styled.div`
align-items: center;
position: relative;
.share-text {
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  margin-bottom: 20px;
}
  h3 {
    display: flex;
    text-align: center;

  }
  .dialog-btns{

    display: flex;
    gap: 20px;
    justify-content: center;
    .con-logo{
      position: absolute;
      right: 30px;
    }
  }
`
