import { EPermission, hasPermission } from "../../../../permissions/permissions";
import { MixpanelEvent, trackMixpanelEvent } from "../../../../mixpanel";
import { StyledBackButton, StyledNextButton } from "../GrowthSimulatorButtons/GrowthSimulatorButtons";
import { goalSelector, growthSimulationSaaSSelector, growthSimulationSelector, growthSimulatorGenreSelector, tooltipSelectorById } from "../../../../store/growthSimulator/growthSimulator.selector";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ConnectivityLogo } from '../../../../assets/connectivity.svg';
import { Genre } from "../../../../store/growthSimulator/growthSimulator.state";
import { GrowthSimulatorV2Service } from "../../services/growthSimulator.service";
import MUITooltip from "../../../../components/MUITooltip/MUITooltip";
import SimpoolDialog from '../../../../components/Dialog/Dialog';
import { StyledCloseTabDialog } from "../../../ScenarioBuilder/components/ScenarioBuilderWrapper/ScenarioBuilderWrapper";
import { enqueueSnackbar } from "../../../../store/notifications/notifications.actions";
import { isRegistered } from "../../../ScenarioBuilder/helpers/helpers";
import { openInNewTab } from "../GrowthSimulatorForm/GrowthSimulatorForm";
import styled from "styled-components";
import { useState } from "react";
import { userSelector } from "../../../../store/onboarding/onboarding.selectors";

type Props = {

}

const GrowthSimulatorConnectivity = ({}: Props) => {
  const tooltip = useSelector(tooltipSelectorById( 80 , 190));
  const userOnboarding = useSelector(userSelector);
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  
  const [shareDialogOpen, setShareDialogOpen] = useState<{ shareLink?: string, isOpen: boolean}>({
    shareLink: undefined,
    isOpen: false
  });

  const dispatch = useDispatch();
  const { REACT_APP_URL } = process.env;
  const genre = useSelector(growthSimulatorGenreSelector);
  const growthSimulation = useSelector(growthSimulationSelector);
  const growthSimulationSaaS = useSelector(growthSimulationSaaSSelector);
  const goal = useSelector(goalSelector);

  const handleOpenRegisterDialog = () => {
    setRegisterDialogOpen(true);
  }

  const handleCloseRegisterDialog = () => {
    setRegisterDialogOpen(false);
  }
  const handleOpenSubscriptionDialog = () => {
    setSubscriptionDialogOpen(true);
  }

  const handleCloseSubscriptionDialog = () => {
    setSubscriptionDialogOpen(false);
  }

  const handleOpenShareDialog = (link: string) => {
    setShareDialogOpen({
      isOpen: true,
      shareLink: link
    });
  }

  const handleCloseShareDialog = () => {
    setShareDialogOpen({
      isOpen: false,
      shareLink: undefined
    });
  }


  const handleClick = () => {
    if (isRegistered()) {
      GrowthSimulatorV2Service
      .getGrowthSimulationByIdShare(genre === Genre.SaaS ? growthSimulationSaaS?.simulation_id! : growthSimulation?.simulation_id!, 88, genre, goal!)
      .then((response ) => handleOpenShareDialog(response.url));
    } else {
      handleOpenRegisterDialog();
    }
  }
  return (
    <>
      <SimpoolDialog open={registerDialogOpen} title={
        `You need to be a registered and subscribed user in order to use Premium features.`
      } onClose={handleCloseRegisterDialog}>
        <StyledCloseTabDialog>
          <div className="dialog-btns">
            <StyledBackButton onClick={(e) => {
              e.preventDefault();
              handleCloseRegisterDialog();
              trackMixpanelEvent(MixpanelEvent.SaveCancel);
            }}>
              Cancel
            </StyledBackButton>
            <StyledNextButton onClick={(e) => {
              e.preventDefault();
              trackMixpanelEvent(MixpanelEvent.SaveRegister);
              handleCloseRegisterDialog();
              openInNewTab(`${REACT_APP_URL}/register?intent=subscription`);
            }}>
              Register
            </StyledNextButton>
          </div>
      </StyledCloseTabDialog>
    </SimpoolDialog>
    <SimpoolDialog open={shareDialogOpen.isOpen} title={
        `Copy the link below and share your simulation with colleagues`
      } onClose={handleCloseShareDialog}>
        <StyledCloseTabDialog>

          <div className="share-text">{ shareDialogOpen.shareLink}</div>
          <div className="dialog-btns">
            <StyledBackButton onClick={(e) => {
              e.preventDefault();
              handleCloseShareDialog();
            }}>
              Cancel
            </StyledBackButton>
            <StyledNextButton onClick={(e) => {
              e.preventDefault();
              shareDialogOpen.shareLink && navigator.clipboard.writeText(shareDialogOpen.shareLink);
              dispatch(
                enqueueSnackbar({
                  message: "Share link copied to clipboard",
                  options: {
                    variant: "success",
                  },
                }))
              }}>
              Copy
            </StyledNextButton>
          </div>
      </StyledCloseTabDialog>
    </SimpoolDialog>
      <SimpoolDialog open={subscriptionDialogOpen} title={`You need to be subscribed in order to use premium features!`} onClose={handleCloseSubscriptionDialog}>
        <StyledCloseTabDialog>
          <div className="dialog-btns">
            <StyledBackButton onClick={(e) => {
              e.preventDefault();
              handleCloseSubscriptionDialog();
            } }>
              Cancel
            </StyledBackButton>
            <StyledNextButton onClick={(e) => {
              e.preventDefault();
              handleCloseSubscriptionDialog();
              openInNewTab(`${REACT_APP_URL}/subscription`);
            } }>
              Subscribe
            </StyledNextButton>
          </div>
        </StyledCloseTabDialog>
      </SimpoolDialog>
      <MUITooltip title={tooltip} zIndexValue="1600" >
          <StyledIconContainer onClick={handleClick} className='con-logo'>
            <ConnectivityLogo />
          </StyledIconContainer>
        </MUITooltip>
    </>
  );
};

export default GrowthSimulatorConnectivity;

const StyledIconContainer = styled.div`
  width: 24px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
  svg {
    width: 24px;
    height: 24px;
  }
`