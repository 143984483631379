export const groupBy = <T, K extends string | number | symbol>(
  list: T[],
  getKey: (item: T) => K
) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) {
      previous[group] = [];
    }
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

export const chunk = function chunk<T>(array: T[], chunkSize: number): T[][] {
  const R = [];
  for (let i = 0, len = array?.length; i < len; i += chunkSize)
    R.push(array?.slice(i, i + chunkSize));
  return R;
};

export function rtrim(str: string, ch?: string): string {
  if (!ch) {
    return str;
  }

  var i: number = str.length - 1;

  while (ch === str.charAt(i) && i >= 0) i--;

  return str.substring(0, i + 1);
}

export function trim(str: string, ch?: string) {
  var start = 0, 
      end = str.length;

  while(start < end && str[start] === ch)
      ++start;

  while(end > start && str[end - 1] === ch)
      --end;

  return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}

export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
