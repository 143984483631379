import React, { ReactElement } from "react";

import { SnackbarOrigin } from "@mui/material";
import { SnackbarProvider } from "notistack";

const MAX_SNACK = 3;
const AUTO_HIDE_DURATION = 3000;
const POSITION: SnackbarOrigin  = {
  vertical: "top",
  horizontal: "right"
};

export default function NotificationProvider({ children }: {children: ReactElement}) {
  return (
    <SnackbarProvider
      maxSnack={MAX_SNACK}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={POSITION}
    >
      {children}
    </SnackbarProvider>
  );
}
