import { CircularProgress, MenuItem, OutlinedInput } from "@mui/material";
import { Field, Form, Formik, FormikConfig, FormikValues, useFormik, useFormikContext } from "formik";
import { Checkbox, Select } from 'formik-mui';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSegmentsFieldsAction } from "../../../../store/segmentation/segmentation.actions";
import { fieldsSelector, getAllSegmentsFieldsLoadingSelector } from '../../../../store/segmentation/segmentation.selector';
import { ESegmentDataType, IFilter, ISegmentField } from "../../../../store/segmentation/segmentation.state";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import styled from 'styled-components';
import { SimpoolPrimaryButton } from "../../../../components/Buttons/Buttons";
import { Select as MaterialSelect, Checkbox as MaterialCheckbox, ListItemText } from '@mui/material';
import AcqusitionCell from "../../../../components/UserAcquisitionTable/AcqusitionCell";
import DynamicTooltip from "./DynamicTooltip";

export interface ISegmentationFormInputProps {
  field?: ISegmentField;
  checked?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: { [key: string]: any };
}

const StyledLabel = styled.label`
  color: #959599;
  text-overflow: ellipsis;
  overflow: hidden;
`

const StyledTextFieldContainer = styled.section`
    width: 100%;
    display: grid;
    width: 100%;
    grid-template-columns: 0.1fr 0.4fr 1fr;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #5B5C74
    }

`

const StyledNumberFieldContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 0.8fr 0.2fr 0.6fr 0.2fr 0.6fr 0.2fr 0.6fr;
  align-items: center;
  justify-content: center;

  &:hover {
      background: #5B5C74
  }
`

const StyledTextField = styled.input`
    height: 15px;
    width: 70px;
    outline: none;
    border-radius: 15px;
    border: 1px solid #606178;
    height: 20px;
    text-align: center;

    &:disabled {
      background-color: #606178;
      color: #959599;
    }

    background-color: white; 
    color: black;
        
    &.small {
        height: 15px;
        width: 70px;
    }

    -moz-appearance:textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    
  `

const StyledSelect = styled(Select)`
    height: 25px !important;
    width: 140px;
    background-color: #606178;
    outline: none;
    border-radius: 15px;
    border: 1px solid #606178;
    height: 20px;
    text-align: center;
    color: #959599;
    border: none;
`

const StyledMultiselect = styled(MaterialSelect)`
  width: 307px;
  height: 25px;
  fieldset {
    border-color: transparent !important;
  }
  background-color: white;

  &.Mui-disabled{
    background-color: #606178;

  }
`
class SegmentationFormInput extends React.Component<ISegmentationFormInputProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ISegmentationFormInputProps) {
    super(props);
  }

  public shouldComponentUpdate({ field, values, checked }: ISegmentationFormInputProps): boolean {
    let hasValueChanged = false;
    if (field?.data_type === ESegmentDataType.Number) {
      hasValueChanged = values[`${field.column_name}-GT`] !== this.props.values[`${field.column_name}-GT`] ||
        values[`${field.column_name}-LT`] !== this.props.values[`${field.column_name}-LT`] ||
        values[`${field.column_name}-EQ`] !== this.props.values[`${field.column_name}-EQ`]
    } else {
      hasValueChanged = values[`${field?.column_name}-TEXT`] !== this.props.values[`${field?.column_name}-TEXT`]
    }

    return (checked !== this.props.checked) || hasValueChanged;
  }

  public render(): React.ReactNode {
    const { field, checked, setFieldValue, values } = this.props;
    
    switch (field?.data_type) {
      // case ESegmentDataType.Text:
      //   return <StyledTextFieldContainer key={field?.column_name}>
      //     <Field component={Checkbox} name={`${field?.column_name}-checked`} type="checkbox" />
      //     <StyledLabel htmlFor={field?.column_name}>{field?.column_name}</StyledLabel>
      //     <Field name={`${field?.column_name}-TEXT`} id={field?.column_name} as="select" component={StyledSelect} displayEmpty disabled={!checked}>
      //       <MenuItem disabled value={''}>--Choose--</MenuItem>
      //       {field?.column_values?.split(',')?.filter(Boolean)?.map((value, index) => {
      //         return <MenuItem key={`${field?.column_name}-${index}`} value={value}>{value}</MenuItem >
      //       })}
      //     </Field>
      //   </StyledTextFieldContainer>
      case ESegmentDataType.Text:
        return <StyledTextFieldContainer key={field?.column_name}>
          <Field component={Checkbox} name={`${field?.column_name}-checked`} type="checkbox" />
          <DynamicTooltip item={<StyledLabel htmlFor={field?.column_name}>{field?.column_name}</StyledLabel>} text={field?.column_name}/>
            <StyledMultiselect
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={values[`${field?.column_name}-TEXT`]|| []}
              onChange={(change) => {
                setFieldValue(`${field?.column_name}-TEXT`, change.target.value);
              }}
              input={<OutlinedInput label="Name" />}
              disabled={!checked}
              label={field?.column_name}
              renderValue={(selected:any) => selected.join(', ')}
          >
            {field?.column_values?.split(',')?.filter(Boolean)?.map((value, index) => {
               return <MenuItem key={`${field?.column_name}-${index}`} value={value}>
                  <MaterialCheckbox checked={values[`${field?.column_name}-TEXT`]?.indexOf(value) > -1} />
                  <ListItemText primary={value} />
               </MenuItem >
            })}
          </StyledMultiselect>
        </StyledTextFieldContainer>
      case ESegmentDataType.Float:
      case ESegmentDataType.Number:
        return <StyledNumberFieldContainer key={field?.column_name}>
          <Field component={Checkbox} name={`${field?.column_name}-checked`} type="checkbox" />
          <DynamicTooltip item={<StyledLabel htmlFor={`${field?.column_name}-GT`}>{field?.column_name}</StyledLabel>} text={field?.column_name}/>
          <ArrowBackIosIcon style={{ fontSize: 18 }}></ArrowBackIosIcon>
          <StyledTextField name={`${field?.column_name}-GT`} type="number" id={`${field?.column_name}-GT`} disabled={!checked} onChange={(e) => {
            const value = e.target.value;
            setFieldValue(`${field?.column_name}-GT`, value);
          }} value={values[`${field?.column_name}-GT`]} />
          <ArrowForwardIosIcon style={{ fontSize: 18 }}></ArrowForwardIosIcon>

          <StyledTextField name={`${field?.column_name}-LT`} type="number" id={`${field?.column_name}-LT`} disabled={!checked} onChange={(e) => {
            const value = e.target.value;
            setFieldValue(`${field?.column_name}-LT`, value);
          }} value={values[`${field?.column_name}-LT`]} />
          <DensityLargeIcon style={{ fontSize: 13, marginTop: 3 }}></DensityLargeIcon>
          <StyledTextField name={`${field?.column_name}-EQ`} type="number" id={`${field?.column_name}-EQ`} disabled={!checked} onChange={(e) => {
            const value = e.target.value;
            setFieldValue(`${field?.column_name}-EQ`, value);
          }} value={values[`${field?.column_name}-EQ`]} />
        </StyledNumberFieldContainer>
      default:
        return null;
    }
  }
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  gap: 1rem;
`

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
export interface ISegmentationFormProps {
  titleId: number;
  onSubmit: (values: IFilter[]) => void;
  fields?: ISegmentField[];
  selectedValues?: any;
}
export type SelecetedValue = {
  column_name: string;
  column_type: ESegmentDataType;
  op: 'gt' | 'lt' | 'eq'
  values: string[] | number [];
}
const SegmentationPageForm = ({ titleId, onSubmit, fields, selectedValues }: ISegmentationFormProps) => {
  const dispatch = useDispatch();

  const getAllSegmentFieldsLoading = useSelector(getAllSegmentsFieldsLoadingSelector);

  const initialValues = useMemo(() => {
    if (!fields?.length) {
      return {};
    }
    const choosedValues = selectedValues.filters as SelecetedValue[];
    const mappedFilters =  fields?.map(({ column_name, data_type }) => {
      const currentChoosedValue = choosedValues?.find((cv) => cv.column_name === column_name);
      
      let row: any = {
        [`${column_name}-checked`]: Boolean(currentChoosedValue)
      };

      if (data_type === ESegmentDataType.Text) {
        row[`${column_name}-TEXT`] = currentChoosedValue?.values || '';
      } else {
        row[`${column_name}-GT`] = currentChoosedValue?.op === 'gt' ? currentChoosedValue?.values?.[0] : '';
        row[`${column_name}-LT`] = currentChoosedValue?.op === 'lt' ? currentChoosedValue?.values?.[0] : '';
        row[`${column_name}-EQ`] = currentChoosedValue?.op === 'eq' ? currentChoosedValue?.values?.[0] : '';
      }

      return row;
    }).reduce((accumulator, value) => {
      let row: any = {};


      Object.keys(value).forEach(key => row[key] = value[key]);

      return {
        ...accumulator,
        ...row
      }
    }, {}) as FormikValues;
    
    
    return mappedFilters;
  }, [fields, selectedValues]);
  

  const handleFormikSubmit = async(values: FormikValues) => {
    const selected = Object.keys(values).filter(k => k.includes('checked')).filter(k => values[k]);

    const rawSegments = selected.map(s => Object.keys(values)
      .filter(k => k !== s)
      .filter(k => k.startsWith(s.replace('checked', '')))
      .map(k => ({
        key: k,
        value: values[k]
      }))
    );

    const getOperationNameFromKey = (key: string): string | null => {
      if (key.endsWith('GT')) {
        return 'gt';
      } else if (key.endsWith('LT')) {
        return 'lt';
      } else if (key.endsWith('EQ')) {
        return 'eq'
      } else {
        return null;
      }
    }

    const segments: IFilter[] = rawSegments
      .map((s) => s
        .filter(({ value }) => Boolean(value))
        .map(v => {
          const columnName = v['key']?.slice(0, v['key']?.lastIndexOf('-'));
          const field = fields?.find(f => f.column_name === columnName);

          const result = {
            op: getOperationNameFromKey(v['key']),
            column_name: field?.column_name!,
            value: getOperationNameFromKey(v['key']) && v['value']?.toString(),
            column_type: field?.data_type!,
            values: !getOperationNameFromKey(v['key']) ? v['value'] : null
          };
          return result;
        }))
      .flat(1);
        console.log('handleFormikSubmit');
        
    onSubmit && onSubmit(segments);
  }

  return getAllSegmentFieldsLoading ? <SpinnerContainer>
    <CircularProgress size="8rem" />
  </SpinnerContainer>
    : <Formik
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
    >
      {(formik) => {
        const values: { [key: string]: any } = formik.values;
        return <StyledForm>
          {fields?.map(field => {
            return <SegmentationFormInput field={field} checked={values[`${field?.column_name}-checked`]} key={`${field?.column_name}-checked`} setFieldValue={formik.setFieldValue} values={values} />
          })}
          {/* <SimpoolPrimaryButton type="submit">Submit</SimpoolPrimaryButton> */}
          <FormikSubmitter />
        </StyledForm>
      }}

    </Formik>
};

export default SegmentationPageForm;

const FormikSubmitter = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    if(values) {
      console.log('FormikSubmitter', values);
      
      submitForm()
    }
  }, [values]);

  return null;
}
