import { ARPPUTableWeightedAverages, goalSelector, tooltipSelectorById, widgetsTooltipSelector } from '../../../../store/growthSimulator/growthSimulator.selector';
import { Field, change } from 'redux-form';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { EGoalType } from '../../../../store/growthSimulator/growthSimulator.state';
import Expandable from '../../../../shared/Expandable/Expandable';
import { GrowthSimulatorFormData } from '../../models/growthSimulator.models';
import GrowthSimulatorRouletteInput from '../GrowthSimulatorRouletteInput/GrowthSimulatorRouletteInput';
import MUITooltip from '../../../../components/MUITooltip/MUITooltip';
import { NumericFormat } from 'react-number-format';
import { StyledGrowthSimulatorDetailsSegmentHeaderTabListItemAddButton } from '../GrowthSimulatorButtons/GrowthSimulatorButtons';
import { StyledReduxFormNumericFormat } from '../../../../shared/ReduxFormNumericFormat/ReduxFormNumericFormat';
import config from '../../../../config';
import styled from 'styled-components';
import { trackAddCpi } from '../../../../mixpanel';
import { useApplicationContext } from '../../../../Context';
import { isValueZero } from '../goals/GrowthSimulatorSaaSGoal/GrowthSimulatorSaaSGoal';

export type NumberFormatValue = {
  value: string,
  formattedValue: string,
  floatValue: number
}

export interface IGrowthSimulatorCalculatorFormValue {
  dauGoal?: NumberFormatValue;
  days: NumberFormatValue;
  cpi: NumberFormatValue;
  organicUplift: NumberFormatValue;
  conversion?: number;
  arppu?: number;
  dailyInstalls: NumberFormatValue;
  budget?: NumberFormatValue;
}


export interface IGrowthSimulatorCalculatorValue {
  dauGoal: number;
  days: number;
  cpi: number;
  organicUplift: number;
  conversion: number;
  arppu: number;
  dailyInstalls: number;
  budget: number;
  dynamicCpiWeightedAverage?: number;
};

interface Props {
  onSegmentDialogOpen: () => void;
  onDynamicCpiDialogOpen: () => void;
  field: string;
  value: GrowthSimulatorFormData;
  index: number;
  goal: EGoalType
}

const StyledGrowthSimulatorDetailsCalculatorContainer = styled.div`
      box-shadow: inset 5px 5px 12px #00000066;
      background: #19253c;
      padding: 1px 0;
`

const StyledGrowthSimulatorDetailsCalculatorList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 10px;
    display: flex;
    flex-direction: column;

    li:nth-child(odd) {
      background: #29334b;
    }

    li:nth-child(even) {
      background: #1a273f;
    }
`

const StyledGrowthSimulatorDetailsCalculatorListItem = styled.li`
    color: #CFD5DF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
`

const StyledGrowthSimulatorDetailsCalculatorLabel = styled.span`
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  &:hover {
    cursor: pointer;
    .html-tooltip {
      opacity: 1;
      visibility: visible;
      width: 300%;
    }
  }
`

const StyledGrowthSimulatorDetailsCalculatorInput = styled(NumericFormat)`
    
    width: 90px;
    height: 25px;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #58a4fd;

    background: #fff;
    color: #000;

    &:disabled {
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #000;
    }
`

const StyledGrowthSimulatorDetailsCalculatorRadioGroup = styled.div`
      display: flex;
    align-items: center;
    justify-content: center;
`

const StyledGrowthSimulatorDetailsCalculatorRadioControl = styled.div`
  
`

const StyledGrowthSimulatorDetailsCalculatorRadioControlLabel = styled.span`
  
`

const StyledGrowthSimulatorRouletteInputContainer = styled.div`
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 15px;
`

const StyledGrowthSimulatorDetailsCalculatorInputWrapper = styled.div`
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
`

const DailyInstallsExpandable = styled(Expandable)`
  section {
    margin: 0;
    background: #29334b;

    & > span {
      top: 3px;
      position: relative;
      margin-left: 5px;
    }

    li {
      width: 100%;
      background: #29334b !important;
      padding-left: 0;
    }
  }

 aside {
    margin-left: 10px;

    li:nth-child(even) {
      background: #29334b !important;
    }

    li:nth-child(odd) {
      background: #1a273f !important;
    }
 }
`

const InAppPurchaseExpandable = styled(Expandable)`
  aside {
    box-shadow: inset 5px 5px 12px #00000066;
    background: #19253c;
    padding: 1px 0 1px 0px;

    & > div {
      padding: 15px 0px;
      margin-bottom: 10px;
    }
  }
`


const DailyActiveUsersGoalForm = ({ field, onDynamicCpiDialogOpen, value }: any) => {
  const dauGoalToolTip = useSelector(tooltipSelectorById(50, 10));
  const cpilToolTip = useSelector(tooltipSelectorById(60, 30));
  const daysToolTip = useSelector(tooltipSelectorById(60, 20));
  const organicToolTip = useSelector(tooltipSelectorById(60, 40));
  const addCpiTooltip = useSelector(tooltipSelectorById(70, 20));

  return <>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_dau_goal">
      <MUITooltip title={dauGoalToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          DAU Goal
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        name={`${field}.calculator.dauGoal`}
        validate={[isValueZero]}
        firstInput={true}/>
    </StyledGrowthSimulatorDetailsCalculatorListItem>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_days">
      <MUITooltip title={daysToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          Days
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        name={`${field}.calculator.days`}
        validate={[isValueZero]}/> 
    </StyledGrowthSimulatorDetailsCalculatorListItem>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_cpi">
      <MUITooltip title={cpilToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          CPI is
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <StyledGrowthSimulatorDetailsCalculatorInputWrapper>
        <StyledGrowthSimulatorDetailsSegmentHeaderTabListItemAddButton type="button" onClick={(e) => {
          e.preventDefault();
          onDynamicCpiDialogOpen();
          trackAddCpi();
        }}>
          <MUITooltip title={addCpiTooltip}>
            <AddIcon></AddIcon>
          </MUITooltip>
        </StyledGrowthSimulatorDetailsSegmentHeaderTabListItemAddButton>

        {value.dynamicCpiWeightedAverage ? <Field
          component={StyledReduxFormNumericFormat}
          thousandSeparator
          allowNegative={false}
          prefix="$"
          decimalScale={2}
          disabled
          name={`${field}.dynamicCpiWeightedAverage`} /> : <Field
          component={StyledReduxFormNumericFormat}
          thousandSeparator
          allowNegative={false}
          decimalScale={2}
          prefix="$"
          name={`${field}.calculator.cpi`} />}
      </StyledGrowthSimulatorDetailsCalculatorInputWrapper>
    </StyledGrowthSimulatorDetailsCalculatorListItem>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_organic_uplift">
      <MUITooltip title={organicToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          Organic uplift is
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        suffix="%"
        name={`${field}.calculator.organicUplift`} />
    </StyledGrowthSimulatorDetailsCalculatorListItem>
  </>
}

const BudgetGoalForm = React.memo(({ field, onDynamicCpiDialogOpen, value }: any) => {
  const budgetGoalToolTip = useSelector(tooltipSelectorById(50, 20));
  const organicToolTip = useSelector(tooltipSelectorById(60, 40));
  const daysToolTip = useSelector(tooltipSelectorById(60, 20));
  const cpilToolTip = useSelector(tooltipSelectorById(60, 30));
  const addCpiToolTip = useSelector(tooltipSelectorById(70, 20));
  return <>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_budget">
      <MUITooltip title={budgetGoalToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          Budget
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        prefix="$"
        name={`${field}.calculator.budget`}
        validate={[isValueZero]}
        firstInput={true}/>
    </StyledGrowthSimulatorDetailsCalculatorListItem>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_days">
      <MUITooltip title={daysToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          Days
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        name={`${field}.calculator.days`}
        validate={[isValueZero]}/>
    </StyledGrowthSimulatorDetailsCalculatorListItem>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_cpi">
      <MUITooltip title={cpilToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          CPI is
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <StyledGrowthSimulatorDetailsCalculatorInputWrapper>
        <StyledGrowthSimulatorDetailsSegmentHeaderTabListItemAddButton type="button" onClick={(e) => {
          e.preventDefault();
          onDynamicCpiDialogOpen();
          trackAddCpi();
        }}>
          <MUITooltip title={addCpiToolTip}>
            <AddIcon></AddIcon>
          </MUITooltip>
        </StyledGrowthSimulatorDetailsSegmentHeaderTabListItemAddButton>
        {value.dynamicCpiWeightedAverage ? <Field
          component={StyledReduxFormNumericFormat}
          thousandSeparator
          allowNegative={false}
          decimalScale={2}
          prefix="$"
          disabled
          name={`${field}.dynamicCpiWeightedAverage`} /> : <Field
          component={StyledReduxFormNumericFormat}
          thousandSeparator
          allowNegative={false}
          decimalScale={2}
          prefix="$"
          name={`${field}.calculator.cpi`} />}
      </StyledGrowthSimulatorDetailsCalculatorInputWrapper>
    </StyledGrowthSimulatorDetailsCalculatorListItem>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_organic_uplift">
      <MUITooltip title={organicToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          Organic uplift is
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        suffix="%"
        name={`${field}.calculator.organicUplift`} />
    </StyledGrowthSimulatorDetailsCalculatorListItem>
  </>
})

const FreeStyleGoalForm = ({ field, onDynamicCpiDialogOpen, value }: any) => {
  // const dauFreestyleToolTip = useSelector(tooltipSelectorById(60, 10));
  const organicToolTip = useSelector(tooltipSelectorById(60, 40));
  const daysToolTip = useSelector(tooltipSelectorById(60, 20));
  const cpilToolTip = useSelector(tooltipSelectorById(60, 30));
  const allTooltips = useSelector(widgetsTooltipSelector);
  const addCpilToolTip = useSelector(tooltipSelectorById(70, 20));
  const DAUSeniorityInputToolTip = useSelector(tooltipSelectorById(80, 36));
  const DAUScaleInputToolTip = useSelector(tooltipSelectorById(80, 35));

  const [context] = useApplicationContext();

  return <>
    <DailyInstallsExpandable disabled={context.isTourActive} data-tour-id="growth_simulator_details_calculator_list_item_daily_installs" component={<StyledGrowthSimulatorDetailsCalculatorListItem >
      <MUITooltip title={allTooltips['daily installs on free style']}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          Daily Paid installs
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        name={`${field}.calculator.dailyInstalls`}
        validate={[isValueZero]}
        firstInput={true}
      />
    </StyledGrowthSimulatorDetailsCalculatorListItem>}>
      <>
        <StyledGrowthSimulatorDetailsCalculatorListItem>
          <MUITooltip title={DAUScaleInputToolTip}>
            <StyledGrowthSimulatorDetailsCalculatorLabel>
              Current DAU Scale
            </StyledGrowthSimulatorDetailsCalculatorLabel>
          </MUITooltip>
          <Field
            component={StyledReduxFormNumericFormat}
            thousandSeparator
            allowNegative={false}
            name={`${field}.calculator.currentDauScale`}
          />
        </StyledGrowthSimulatorDetailsCalculatorListItem>
        <StyledGrowthSimulatorDetailsCalculatorListItem>
          <MUITooltip title={DAUSeniorityInputToolTip}>
            <StyledGrowthSimulatorDetailsCalculatorLabel>
              DAU Maximum Seniority
            </StyledGrowthSimulatorDetailsCalculatorLabel>
          </MUITooltip>
          <Field
            component={StyledReduxFormNumericFormat}
            thousandSeparator
            allowNegative={false}
            name={`${field}.calculator.dauMaximumSeniority`} />
            
        </StyledGrowthSimulatorDetailsCalculatorListItem>
      </>
    </DailyInstallsExpandable>

    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_days" >
      <MUITooltip title={daysToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          Days
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        name={`${field}.calculator.days`}
        validate={[isValueZero]}
      />
    </StyledGrowthSimulatorDetailsCalculatorListItem>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_cpi">
      <MUITooltip title={cpilToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          CPI is
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <StyledGrowthSimulatorDetailsCalculatorInputWrapper>
        <StyledGrowthSimulatorDetailsSegmentHeaderTabListItemAddButton type="button" onClick={(e) => {
          e.preventDefault();
          onDynamicCpiDialogOpen();
          trackAddCpi();
        }}>
          <MUITooltip title={addCpilToolTip}>
            <AddIcon></AddIcon>
          </MUITooltip>

        </StyledGrowthSimulatorDetailsSegmentHeaderTabListItemAddButton>
        {value.dynamicCpiWeightedAverage ? <Field
          component={StyledReduxFormNumericFormat}
          thousandSeparator
          allowNegative={false}
          prefix="$"
          disabled
          decimalScale={2}
          name={`${field}.dynamicCpiWeightedAverage`} /> : <Field
          component={StyledReduxFormNumericFormat}
          thousandSeparator
          allowNegative={false}
          decimalScale={2}
          prefix="$"
          name={`${field}.calculator.cpi`} 
          validate={[isValueZero]}/>}
      </StyledGrowthSimulatorDetailsCalculatorInputWrapper>
    </StyledGrowthSimulatorDetailsCalculatorListItem>
    <StyledGrowthSimulatorDetailsCalculatorListItem data-tour-id="growth_simulator_details_calculator_list_item_organic_uplift">
      <MUITooltip title={organicToolTip}>
        <StyledGrowthSimulatorDetailsCalculatorLabel>
          Organic uplift is
        </StyledGrowthSimulatorDetailsCalculatorLabel>
      </MUITooltip>
      <Field
        component={StyledReduxFormNumericFormat}
        thousandSeparator
        allowNegative={false}
        suffix="%"
        name={`${field}.calculator.organicUplift`} 
      />
    </StyledGrowthSimulatorDetailsCalculatorListItem>
  </>
  {/* <StyledGrowthSimulatorDetailsCalculatorListItem>
      <StyledGrowthSimulatorDetailsCalculatorLabel>
        Target function
      </StyledGrowthSimulatorDetailsCalculatorLabel>
      <StyledGrowthSimulatorDetailsCalculatorRadioGroup>
        <StyledGrowthSimulatorDetailsCalculatorRadioControl>
          <StyledGrowthSimulatorDetailsCalculatorRadioControlLabel>
            LTV
          </StyledGrowthSimulatorDetailsCalculatorRadioControlLabel>
          <Radio
            checked={targetFunction === 'LTV'}
            onChange={handleTargetFunctionChange}
            value="LTV"
          />
        </StyledGrowthSimulatorDetailsCalculatorRadioControl>

        <StyledGrowthSimulatorDetailsCalculatorRadioControl>
          <StyledGrowthSimulatorDetailsCalculatorRadioControlLabel>
            ROAS
          </StyledGrowthSimulatorDetailsCalculatorRadioControlLabel>
          <Radio
            checked={targetFunction === 'ROAS'}
            onChange={handleTargetFunctionChange}
            value="ROAS"
          />
        </StyledGrowthSimulatorDetailsCalculatorRadioControl>
      </StyledGrowthSimulatorDetailsCalculatorRadioGroup>
    </StyledGrowthSimulatorDetailsCalculatorListItem> */}

}

const StyledRadioGroupContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`

const AdMonetizationExpandable = styled(Expandable)`
  &+aside {
    box-shadow: inset 5px 5px 12px #00000066;
    background: #19253c;
    padding: 1px 0 1px 0px;
  }
`

const GrowthSimulatorCalculator = ({ field, onSegmentDialogOpen, onDynamicCpiDialogOpen, value, index, goal }: Props) => {
  // const goal = useSelector(goalSelector);
  const arppuDynamicValue = useSelector(ARPPUTableWeightedAverages)?.[value.name!];
  const dispatch = useDispatch();
  const [monetizationType, setMonetizationType] = useState<'CPM' | 'CPC' | 'CPA'>('CPM');

  // const [targetFunction, setTargetFunction] = useState<'LTV' | 'ROAS'>('LTV');

  // const handleTargetFunctionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setTargetFunction(event.target.value as 'LTV' | 'ROAS');
  // };

  const handleMonetizationTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonetizationType((event.target as HTMLInputElement).value as 'CPM' | 'CPC' | 'CPA');
    dispatch(change('growthSimulatorForm', `members[${index}].calculator.monetizationType`, (event.target as HTMLInputElement).value as 'CPM' | 'CPC' | 'CPA'))
  };


  
  const conversionTooltip = useSelector(tooltipSelectorById(60, 50));
  const arppuTooltip = useSelector(tooltipSelectorById(60, 60));
  const arppuIconTooltip = useSelector(tooltipSelectorById(70, 30));

  const cpaTooltip = useSelector(tooltipSelectorById(80, 180));
  const installRateTooltip = useSelector(tooltipSelectorById(80, 170));
  const CPCinputTooltip = useSelector(tooltipSelectorById(80, 160));
  const CTRinputTooltip = useSelector(tooltipSelectorById(80, 150));
  const CPMinputTooltip = useSelector(tooltipSelectorById(80, 140));
  const impressionsPerDAUinputTooltip = useSelector(tooltipSelectorById(80, 130));
  const CPAmodelTooltip = useSelector(tooltipSelectorById(80, 120));
  const CPCmodelTooltip = useSelector(tooltipSelectorById(80, 110));
  const CPMmodelTooltip = useSelector(tooltipSelectorById(80, 90));

  
  return (
    <div>
      <StyledGrowthSimulatorDetailsCalculatorContainer>
        <StyledGrowthSimulatorDetailsCalculatorList>

          {goal === EGoalType.FreeStyle && <FreeStyleGoalForm
            field={field}
            value={value}
            onDynamicCpiDialogOpen={onDynamicCpiDialogOpen}
          />}
          {goal === EGoalType.BudgetGoal && <BudgetGoalForm
            field={field}
            value={value}
            onDynamicCpiDialogOpen={onDynamicCpiDialogOpen}
          />}
          {goal === EGoalType.DailyActiveUsersGoal && <DailyActiveUsersGoalForm
            field={field}
            value={value}
            onDynamicCpiDialogOpen={onDynamicCpiDialogOpen}
          />}
        </StyledGrowthSimulatorDetailsCalculatorList>


      </StyledGrowthSimulatorDetailsCalculatorContainer>

      <InAppPurchaseExpandable title="In App Purchase" defaultExpanded={true}>
        <StyledGrowthSimulatorRouletteInputContainer>
          <GrowthSimulatorRouletteInput
            data-tour-id="growth_simulator_details_calculator_list_item_conversion"
            title="Conversion Rate"
            showTableIcon={false}
            suffix="%"
            toolTip={conversionTooltip}
            name={`${field}.calculator.conversion`}
          ></GrowthSimulatorRouletteInput>
          <GrowthSimulatorRouletteInput
            data-tour-id="growth_simulator_details_calculator_list_item_arppu"
            title="ARPPU"
            showTableIcon
            tableViewIconClick={onSegmentDialogOpen}
            suffix="$"
            toolTip={arppuTooltip}
            iconTooltip={arppuIconTooltip}
            name={`${field}.calculator.arppu`}
            disabled={!!arppuDynamicValue}
            disabledIcon={!config.FEATURE_FLAGS.useArppuTable}
          ></GrowthSimulatorRouletteInput>
        </StyledGrowthSimulatorRouletteInputContainer>
      </InAppPurchaseExpandable>

      <AdMonetizationExpandable title="Ad Monetization">
        <StyledGrowthSimulatorDetailsCalculatorList>
          <StyledRadioGroupContainer>
            <FormControl>
              <RadioGroup
                row
                name="monetizationType"
                value={value?.calculator?.monetizationType}
                onChange={handleMonetizationTypeChange}
              >
                <MUITooltip title={CPMmodelTooltip}>
                  <FormControlLabel value="CPM" control={<Radio />} label="CPM" />
                </MUITooltip>
                <MUITooltip title={CPCmodelTooltip}>
                  <FormControlLabel value="CPC" control={<Radio />} label="CPC" />
                </MUITooltip>
                <MUITooltip title={CPAmodelTooltip}>
                  <FormControlLabel value="CPA" control={<Radio />} label="CPA" />
                </MUITooltip>
              </RadioGroup>
            </FormControl>
          </StyledRadioGroupContainer>



          {monetizationType === 'CPM' && <>
            <StyledGrowthSimulatorDetailsCalculatorListItem>
              <MUITooltip title={impressionsPerDAUinputTooltip}>
                <StyledGrowthSimulatorDetailsCalculatorLabel>
                  Impressions per DAU
                </StyledGrowthSimulatorDetailsCalculatorLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                allowNegative={false}
                name={`${field}.calculator.impressionsPerDau`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
            <StyledGrowthSimulatorDetailsCalculatorListItem>
              <MUITooltip title={CPMinputTooltip}>
                <StyledGrowthSimulatorDetailsCalculatorLabel>
                  CPM
                </StyledGrowthSimulatorDetailsCalculatorLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                allowNegative={false}
                prefix="$"
                name={`${field}.calculator.cpm`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
          </>}

          {monetizationType === 'CPC'  && <>
          <StyledGrowthSimulatorDetailsCalculatorListItem>
            <MUITooltip title={impressionsPerDAUinputTooltip}>
              <StyledGrowthSimulatorDetailsCalculatorLabel>
                Impressions per DAU
              </StyledGrowthSimulatorDetailsCalculatorLabel>
            </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                allowNegative={false}
                name={`${field}.calculator.impressionsPerDau`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
            <StyledGrowthSimulatorDetailsCalculatorListItem>
              <MUITooltip title={CTRinputTooltip}>
                <StyledGrowthSimulatorDetailsCalculatorLabel>
                  CTR
                </StyledGrowthSimulatorDetailsCalculatorLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                suffix="%"
                thousandSeparator
                allowNegative={false}
                name={`${field}.calculator.ctr`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
            <StyledGrowthSimulatorDetailsCalculatorListItem>
              <MUITooltip title={CPCinputTooltip}>
                <StyledGrowthSimulatorDetailsCalculatorLabel>
                  CPC
                </StyledGrowthSimulatorDetailsCalculatorLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                prefix="$"
                allowNegative={false}
                name={`${field}.calculator.cpc`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
          </>}
          {monetizationType === 'CPA' && <>
          <StyledGrowthSimulatorDetailsCalculatorListItem>
              <MUITooltip title={impressionsPerDAUinputTooltip}>
                <StyledGrowthSimulatorDetailsCalculatorLabel>
                  Impressions per DAU
                </StyledGrowthSimulatorDetailsCalculatorLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                allowNegative={false}
                name={`${field}.calculator.impressionsPerDau`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
            <StyledGrowthSimulatorDetailsCalculatorListItem>
              <MUITooltip title={CTRinputTooltip}>
                <StyledGrowthSimulatorDetailsCalculatorLabel>
                  CTR
                </StyledGrowthSimulatorDetailsCalculatorLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                suffix="%"
                thousandSeparator
                allowNegative={false}
                name={`${field}.calculator.ctr`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
            <StyledGrowthSimulatorDetailsCalculatorListItem>
              <MUITooltip title={installRateTooltip}>
                <StyledGrowthSimulatorDetailsCalculatorLabel>
                  Install Rate
                </StyledGrowthSimulatorDetailsCalculatorLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                suffix="%"
                allowNegative={false}
                name={`${field}.calculator.installRate`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
            <StyledGrowthSimulatorDetailsCalculatorListItem>
              <MUITooltip title={cpaTooltip}>
                <StyledGrowthSimulatorDetailsCalculatorLabel>
                  CPA
                </StyledGrowthSimulatorDetailsCalculatorLabel>
              </MUITooltip>
             
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                prefix="$"
                allowNegative={false}
                name={`${field}.calculator.cpa`} />
            </StyledGrowthSimulatorDetailsCalculatorListItem>
          </>}

        </StyledGrowthSimulatorDetailsCalculatorList>
      </AdMonetizationExpandable>
    </div>
  );
};

export default React.memo(GrowthSimulatorCalculator)

