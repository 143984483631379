import { ISetting, ISettingsSlice } from "./settings.state";
import { SerializedError, createSelector } from "@reduxjs/toolkit";

import { IAppState } from "../app.state";

const settingsFeatureSelector = (state: IAppState): ISettingsSlice =>
  state.settings;

export const settingsSelector = createSelector(
  settingsFeatureSelector,
  (substate: ISettingsSlice): ISetting[] | undefined => substate.settings
);

export const getSettingsErrorSelector = createSelector(
  settingsFeatureSelector,
  (substate: ISettingsSlice): SerializedError | undefined => substate.error?.getSettings
);

export const updateSettingsErrorSelector = createSelector(
  settingsFeatureSelector,
  (substate: ISettingsSlice): SerializedError | undefined => substate.error?.updateSettings
);

