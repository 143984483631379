
import { IGetAccountTitlesResponse } from "../../queries/useGetAccountTitlesQuery";
import { ICurrencyResponse } from "../../queries/useGetCurrenciesQuery";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CurrencyService } from "../../services/currency.service";
import { AccountTitlesService } from "../../services/accountTitles.service";
import { Pagination } from "../../components/ScenarioPaginationFilter/ScenarioPaginationFilter";
import { EStatus } from "../scenarioBuilder/scenarioBuilder.state";

export const SET_TITLE = "[shared]/setTitle";
export const CHANGE_TITLE = "[shared]/changeTitle";
export const SET_CURRENCY = "[shared]/setCurrency";
export const GET_CURRENCY = "[shared]/getCurrency";
export const GET_ACCOUNT_TITLES = "[shared]/getAcoountTitles";
export const SET_STATUS_FILTER = "[shared]/setStatusFilter";
export const SET_LIMIT_FILTER = "[shared]/setLimitFilter";
export const SET_WHATIF_DESCRIPTION = "[shared]/setWhatIfDescription";

export const getCurrency = createAsyncThunk(
  GET_CURRENCY,
  async (titleId: number) => await CurrencyService.geCurrencies(titleId)
);

export const getAcoountTitles = createAsyncThunk(
  GET_ACCOUNT_TITLES,
  async (accountId: number) => await AccountTitlesService.getAccountTitles(accountId)
);

export const setTitle = (title?: IGetAccountTitlesResponse) => ({
  type: "[shared]/setTitle",
  payload: {
    title,
  },
});

export const changeTitle = (title?: IGetAccountTitlesResponse) => ({
  type: "[shared]/changeTitle",
  payload: {
    title,
  },
});

export const setCurrency = (currency?: ICurrencyResponse) => ({
  type: typeof SET_CURRENCY,
  payload: {
    currency,
  },
});

export const setStatusFilter = (values: EStatus[]) => ({
  type: SET_STATUS_FILTER,
  payload: {
    values,
  },
});

export const setLimitFilter = (value: Pagination) => ({
  type: SET_LIMIT_FILTER,
  payload: {
    value,
  },
});

export const setWhatIfDescription = (payload: string | undefined) => ({
  type: SET_WHATIF_DESCRIPTION,
  payload
});
