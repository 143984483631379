import styled from 'styled-components';
import cn from 'classnames';
import { LegendObjectType } from './RetentionTable';
import { formatAnumber } from './RetentionTable';
import { CurrencyCode } from '../../helpers/formatters';

export interface IUserAcquisitionTable {
  className?: string;
  values?: LegendObjectType[];
  currencyCode?: CurrencyCode
}

const RetentionTableLegend = ({className, values, currencyCode }: IUserAcquisitionTable) => {
  return (
    <section className={cn(className)}>
      <ul>
        { values?.map((value, index) => {
          if(index === values.length - 1) {
            return <li key={`re-table-legend-${index}`}><div style={{ background: value.color }}></div>{ `${formatAnumber(value.from, value.type, currencyCode)}+` }</li>
          }
          return <li key={`re-table-legend-${index}`}><div style={{ background: value.color }}></div>{ `${formatAnumber(value.from, value.type, currencyCode)}-${formatAnumber(value.to, value.type, currencyCode)}` }</li>
        })}
      </ul>
    </section>
  )
};

export default styled(RetentionTableLegend)`
  padding: 15px 0;
  background: #2E2F4B 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 6px #00000073;
  border-radius: 0px 0px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: #FFFFFF;
      opacity: 0.65;
      padding: 0 24px 0 15px;
      border-right: 1px solid white;

      &:last-child {
        border-right: none;

      }
      div {
        width: 25px;
        height: 25px;
        background: #0065DB;
        margin-right: 15px;
        border-radius: 6px;
      }
    }
  }
`