import { ILoginUser, IRegisterUser } from "./onboarding.state";

import { SerializedError, createAsyncThunk } from "@reduxjs/toolkit";
import { OnboardingService } from "../../services/onboarding.service";

export const getUserOnboarding = createAsyncThunk(
  "[onboarding]/getUserOnboarding",
  async ({ userId, shopperId }: { userId?: number, shopperId?: number }) => await OnboardingService.getUserOnboarding(userId, shopperId)
);

export const loginUser = createAsyncThunk(
  "[onboarding]/loginUser",
  async (login: ILoginUser) => await OnboardingService.loginUser(login)
);

export const forgotPassword = createAsyncThunk(
  "[onboarding]/forgotPassword",
  async (email: string) => await OnboardingService.forgotPassword(email)
);

export const changePassword = createAsyncThunk(
  "[onboarding]/changePassword",
  async (input: {
      login_id: string;
      new_password: string;
    }) => await OnboardingService.changePassword(input)
)

export const resendVerifyEmail = createAsyncThunk(
  "[onboarding]/resendVerifyEmail",
  async (email: string) => await OnboardingService.resendVerifyEmail(email)
)

export const registerUser = createAsyncThunk(
  "[onboarding]/registerUser",
  async (user: IRegisterUser, { rejectWithValue }) => {
    try {
      const response = await OnboardingService.registerUser(user)
      return response;
    } catch (err: any) {
      if (!err?.response) {
        throw err
      }
      const rejectObject: SerializedError = {
        code: err.response.status,
        message: err.response.data?.detail
      }
      return rejectWithValue(rejectObject)
    }
  }
);
