import {
  ApplicationType,
  RevenueModelType,
} from "./../../pages/GrowthSimulatorV2/components/GrowthSimulatorSteps/GrowthSimulatorSteps";
import { EGoalType, EPointOfViewType, Genre } from "./growthSimulator.state";
import {
  GetArppuPayload,
  GrowthSimulatorV2Service,
} from "../../pages/GrowthSimulatorV2/services/growthSimulator.service";
import {
  IExecuteGrowthSimulationInput,
  IExecuteGrowthSimulationSaaSInput,
  IGrowthSimulatorScenarioInput,
} from "../../pages/GrowthSimulatorV2/models/growthSimulator.models";

import { BusinessType } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorSteps/GrowthSimulatorSteps";
import { GrowthSimulatorDetailsSegmentTableType } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorDetailsSegmentTable/GrowthSimulatorDetailsSegmentTable";
import { IGrowthSimulatorRetentionNumbersForm } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorRetentionNumbers/GrowthSimulatorRetentionNumbers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {deleteUser} from "../../store/onboarding/onboarding.slice";

export const getGrowthSimulatorWidgets = createAsyncThunk(
  "[growth-simulator]/getGrowthSimulatorWidgets",
  async (dashboardId: number) =>
    await GrowthSimulatorV2Service.getGrowthSimulatorWidgets(dashboardId)
);

export const setPointOfViewType = (pov: EPointOfViewType | null) => {
  return {
    type: "[growth-simulator]/setPointOfViewType",
    payload: {
      pov,
    },
  };
};

export const setGoalType = (goal: EGoalType | null) => {
  return {
    type: "[growth-simulator]/setGoalType",
    payload: {
      goal,
    },
  };
};

export const executeGrowthSimulation = createAsyncThunk(
  "[growth-simulator]/executeGrowthSimulation",
  async ({
    input
  }: {
    input: IExecuteGrowthSimulationInput;
  },thunkApi) => {
    try{
      return await GrowthSimulatorV2Service.executeGrowthSimulation(input)
    }
    catch (err: any) {
      if(err.response.status === 401) {
        thunkApi.dispatch(deleteUser())
      }
      return thunkApi.rejectWithValue(err)
    }}
);

export const executeGrowthSimulationSaaS = createAsyncThunk(
  "[growth-simulator]/executeGrowthSimulationSaaS",
  async ({
    input
  }: {
    input: IExecuteGrowthSimulationSaaSInput
  },thunkApi) => {
    try{
      return await GrowthSimulatorV2Service.executeGrowthSimulationSaaS(input)
    } 
    catch (err: any) {
      if(err.response.status === 401) {
        thunkApi.dispatch(deleteUser())
      }
      return thunkApi.rejectWithValue(err)
    }}
);

export const getGrowthSimulatorScenarios = createAsyncThunk(
  "[growth-simulator]/getGrowthSimulatorScenarios",
  async (genre: Genre,thunkApi) =>{
    try{
      return await GrowthSimulatorV2Service.getGrowthSimulatorScenarios(genre)
    }
    catch (err: any) {
      if(err.response.status === 401) {
        thunkApi.dispatch(deleteUser())
      }
      return thunkApi.rejectWithValue(err)
    }
  }
    
);

export const saveGrowthSimulatorScenario = createAsyncThunk(
  "[growth-simulator]/saveGrowthSimulatorScenario",
  async ({input}: {input: IGrowthSimulatorScenarioInput},thunkApi) => {
    try {
      const response = await GrowthSimulatorV2Service.saveGrowthSimulatorScenario(input)
      return response;
    }
    catch (err: any) {
      if(err.response.status === 401) {
        thunkApi.dispatch(deleteUser())
      }
      return thunkApi.rejectWithValue(err)
    }}
);

export const setRetentionNumbersForm = (
  form: IGrowthSimulatorRetentionNumbersForm[]
) => {
  return {
    type: "[growth-simulator]/setRetentionNumbersForm",
    payload: {
      form,
    },
  };
};

export const registerUserBasic = createAsyncThunk(
  "[growth-simulator]/registerUserBasic",
  async ({ email, name }: { email: string; name?: string },thunkApi) =>{
    try{
      await GrowthSimulatorV2Service.registerUserBasic({ email, name })
    }
    catch (err: any) {
      if(err.response.status === 401) {
        thunkApi.dispatch(deleteUser())
      }
      return thunkApi.rejectWithValue(err)
    }}
);

export const setActiveTab = (activeTab: string) => {
  return {
    type: "[growth-simulator]/setActiveTab",
    payload: {
      activeTab,
    },
  };
};

export const resetGrowthSimulator = () => {
  return {
    type: "[growth-simulator]/resetGrowthSimulator",
  };
};

export const getArppu = createAsyncThunk(
  "[growth-simulator]/getArppu",
  async (form: GetArppuPayload) => await GrowthSimulatorV2Service.getArppu(form)
);

export const addARPPUValues = (
  value: GrowthSimulatorDetailsSegmentTableType[],
  scenarioName: string
) => {
  return {
    type: "[growth-simulator]/addARPPUValues",
    payload: {
      value,
      scenarioName,
    },
  };
};

export const addARPPUWeightedAverage = (
  value: number,
  scenarioName: string
) => {
  return {
    type: "[growth-simulator]/addARPPUWeightedAverage",
    payload: {
      value,
      scenarioName,
    },
  };
};

export const setBusinessType = (businessType: BusinessType | null) => {
  return {
    type: "[growth-simulator]/setBusinessType",
    payload: {
      businessType,
    },
  };
};

export const setApplicationType = (applicationType: ApplicationType | null) => {
  return {
    type: "[growth-simulator]/setApplicationType",
    payload: {
      applicationType,
    },
  };
};

export const setRevenueModelType = (
  revenueModelType: RevenueModelType | null
) => {
  return {
    type: "[growth-simulator]/setRevenueModelType",
    payload: {
      revenueModelType,
    },
  };
};

export const setBulbState = (payload: boolean) => {
  return {
    type: "[growth-simulator]/setBulbState",
    payload,
  };
};

export const resetGrowthSimulation = () => {
  return {
    type: "[growth-simulator]/resetGrowthSimulation",
  };
};

export const setGenre = (genre: Genre) => {
  return {
    type: "[growth-simulator]/setGenre",
    payload: genre,
  };
};