import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from "styled-components"
import Tooltip from '../../../components/Tooltip/Tooltip';
import cn from 'classnames';
import { IWhatif } from '../../../store/scenarioBuilder/scenarioBuilder.state';
import { useEffect, useRef, useState } from 'react';

interface SimulationTooltipProps {
  className?: string;
  whatIfObject?: IWhatif;
  userCount?: number;
  coeff?: number;
}

const SimulationTooltip = ({className, whatIfObject, userCount, coeff}: SimulationTooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef<any>(null);

  let timer: any;
  const handleOpen = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    timer && clearTimeout(timer);
    if(isVisible) {
      return;
    }
    setIsVisible(true);
    timer = setTimeout(() => {
      setIsVisible(false);
      clearTimeout(timer)
    }, 4500)
  }
  return (
    <div ref={wrapperRef} className={cn(className, "simulation-tooltip", {visible: isVisible})} onMouseEnter={(e) => handleOpen(e)}>
      <InfoOutlinedIcon className="info-icon" />
      <Tooltip text={whatIfObject && generateSimulationToolTip(whatIfObject, userCount, coeff)} shouldCalculate={false} />
    </div>
  );
}

export default styled(SimulationTooltip)`
  .info-icon{
    fill: white;
  }
  &.visible {
    .html-tooltip {
      visibility: visible;
      opacity: 1;
      background: #9091A0;
      div {
          background: #9091A0;

      }
    }
  } 
`

const generateSimulationToolTip = (whatIfObject: IWhatif, userCount?: number, coeff?: number) => {
  let text = '';
  if (whatIfObject.description) {
      text = text + 'description: ' + whatIfObject.description + ';\n';
  }
  if (whatIfObject.job_id) {
      text = text + 'job_id: ' + whatIfObject.job_id + ';\n';
  }
  if (whatIfObject.scenario_id) {
      text = text + 'scenario_id: ' + whatIfObject.scenario_id + ';\n';
  }
  if (userCount) {
    text = text + 'users: ' + userCount + ';\n';
  }
  if (userCount) {
    text = text + 'coeff: ' + coeff + ';\n';
  }
  return text;
}