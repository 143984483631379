import Analytics from '../Analytics/Analytics';
import { IGetAccountTitlesResponse } from '../../services/accountTitles.service';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import React from 'react';
import styles from './SeeIt.module.scss';
import { useLocation } from 'react-router-dom';
import { IPeriod } from '../../components/DateRangePicker/DateRangePicker';

export interface ICohortsResultProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
}

const CohortsResult = ({ page, title }: ICohortsResultProps) => {
  const { search } = useLocation();
  const country = new URLSearchParams(search).get('country')?.toString();
  const platform = new URLSearchParams(search).get('platform')?.toString();
  const source = new URLSearchParams(search).get('source')?.toString();
  const titleId = Number(new URLSearchParams(search).get('titleId')?.toString());
  const period = new URLSearchParams(search).get('period')?.toString();
  const date = period && JSON.parse(period) as IPeriod;
  return <Analytics
    page={page}
    hideFilters
    titleId={titleId}
    filter={{
      country: [country!],
      platform: [platform!],
      source: [source!],
      groupBy: [],
      ...date
    }} />
};

export default CohortsResult;
