import { Dispatch, SetStateAction, createContext, useContext } from "react";

import { GetRetentionReportDataResponse } from "./queries/useGetRetentionReportDataQuery";
import { GetUserAcquisitionReportDataResponse } from "./queries/useGetUserAcquisitionReportDataQuery";
import { IGetAccountTitlesResponse } from "./queries/useGetAccountTitlesQuery";
import { IGetPageResponse } from "./queries/useGetPagesQuery";
import { IGetTopBarReportDataResponse } from "./queries/useGetTopBarReportDataQuery";
import { IPeriod } from './components/DateRangePicker/DateRangePicker';

export interface IOAuthUser {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  jti: string;
  authenticationType: string;
  email: string;
  email_verified: boolean;
  preferred_username: string;
  applicationId: string;
  roles: string[];
  simpool?: {
    simpool_id: number;
    titles: string[];
  };
}

export interface IApplicationContext {
  topBarReportDataQuery?: {
    isLoading: boolean;
    error: Error | null;
    data: IGetTopBarReportDataResponse | undefined;
  };
  userAcquisitionReportDataQuery?: {
    isLoading: boolean;
    error: Error | null;
    data: GetUserAcquisitionReportDataResponse[] | undefined;
  };
  userRetentionReportDataQuery?: {
    isLoading: boolean;
    error: Error | null;
    data: GetRetentionReportDataResponse[] | undefined;
  };
  authentication?: {
    user: IOAuthUser;
    parameters: { [key: string]: string };
  };
  pagesQuery?: {
    isLoading: boolean;
    error: Error | null;
    data: IGetPageResponse[] | undefined;
  };
  accountTitlesQuery?: {
    isLoading: boolean;
    error: Error | null;
    data: IGetAccountTitlesResponse[] | undefined;
  }
  title?: IGetAccountTitlesResponse;
  period?: IPeriod;
  isLoading?: boolean | undefined;

  isTourActive?: boolean;
  tour?: string;
  tourStep?: number;

  counters: {
    run: number;
  }

  dialogs: {
   segmentDialogOpen?: boolean;
   scenariosDialogOpen?: boolean;
   dynamicCpiDialogOpen?: boolean;
   registerDialogOpen?: boolean;
   subscriptionDialogOpen?: boolean;
   enterDaysDialogOpen?: boolean;
   simulationResultsDialogOpen?: boolean;
   deleteScenarioDialog?: {
    isOpen?: boolean;
    callback?: () => void
   }
  }
}

export const defaultState: IApplicationContext = {
  counters: {
    run: 0
  },
  dialogs: {
  }
};

type ContextType = [
  IApplicationContext,
  Dispatch<SetStateAction<IApplicationContext>>
];

export const ApplicationContext = createContext<ContextType | undefined>(
  undefined
);

export const useApplicationContext = (): ContextType => {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error("useApplicationContext must be within ApplicationProvider");
  }

  return context;
};
