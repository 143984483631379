import Dropdown from '../Dropdown/Dropdown';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { IChartTreeItem } from '../../queries/useGetChartTreeQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsDropDown from '../Dropdown/SettingsDropDown';
import SettingsIcon from '@mui/icons-material/Settings';
import cn from 'classnames';
import styled from 'styled-components';

export interface IUserAcquisitionTable {
  className?: string;
  onChange?: (item: IChartTreeItem) => void;
  tooltip: string | null;
}

const RetentionTableHeader = ({ className, onChange}: IUserAcquisitionTable) => {
  return (
    <section className={cn(className)}>
      <div className="left-icons">
        <div className="setings-icon-wrapper icon-wrapper">
          <SettingsDropDown />
        </div>

        <div className="info-icon-wrapper icon-wrapper">
          <InfoOutlinedIcon className="info-icon" />
        </div>
        <Dropdown onChange={onChange} />
      </div>
      <div className="heart-icon-wrapper">
        <FavoriteBorderOutlinedIcon />
      </div>
    </section>
  )
};

const iconColor = '#9091A0';

export default styled(RetentionTableHeader)`
  margin-top: 15px;
  padding: 10px 30px 10px 30px;
  background: #2E2F4B 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 6px #00000073;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-icons {
    display: flex;
    .icon-wrapper {
      align-items: center;
      display: flex;
      margin-right:25px;
    }
  }
  .setings-icon {
    width: 2rem;
    height: 2rem;
    fill: ${iconColor};
    &:hover {
      cursor: pointer;
      fill: #FFF;
    }
  }

  .info-icon {
    width: 2rem;
    height: 2rem;
    fill: ${iconColor};
    &:hover {
      cursor: pointer;
      fill: #FFF;
      
    }
  }

  .heart-icon-wrapper{
    svg {
      width: 2rem;
      height: 2rem;
      fill: ${iconColor};
      &:hover {
        cursor: pointer;
        fill: #FFF;
        
      }
    }
  }
  .info-icon-wrapper {
    position: relative;
    &:hover {
      cursor: pointer;
      fill: #FFF;
      &::after {
        ${props => props.tooltip && `
          content: "${props.tooltip}";
        `}
        width: 129px;
        
        background: #9091A0;
        display: block;
        padding: 8px 8px;
        color: #FFF;
        font-size: 0.8rem;
        position: absolute;
        right: -139px;
        z-index: 1;
        border-radius: 15px;
        top: 32px;
      }
    }
  }
`
