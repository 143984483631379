import {
  EStatus,
  IScenario,
  IScenarioSimulationStatus,
  IScenarioTabs,
  ISimulationInfo
} from "./scenarioBuilder.state";
import { ICohort, INode } from "../../pages/ScenarioBuilder/models/models";

import { IFilterBarPayload } from "../../components/FilterBar/FilterBar";
import { Pagination } from "../../components/ScenarioPaginationFilter/ScenarioPaginationFilter";
import {
  ScenarioBuilderService
} from "../../services/scenarioBuilder.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getConfiguration = createAsyncThunk(
  "[scenarioBuilder]/getConfiguration",
  async (titleId: number) =>
    await ScenarioBuilderService.getConfiguration(titleId)
);

export const getConfigurationObjects = createAsyncThunk(
  "[scenarioBuilder]/getConfigurationObjects",
  async (titleId: number) =>
    await ScenarioBuilderService.getConfigurationObjects(titleId)
);

export const getConfigurationObjectsByPath = createAsyncThunk(
  "[scenarioBuilder]/getConfigurationObjectsByPath",
  async ({ titleId, path }: { titleId: number; path: string }) =>
    await ScenarioBuilderService.getConfigurationObjectsByPath(titleId, path)
);

export const setConfigurationObjectsByPath = (payload: any) => ({
  type: '[scenarioBuilder]/setConfigurationObjectsByPath',
  payload
});

export const getDefaultCohort = createAsyncThunk(
  "[scenarioBuilder]/getDefaultCohort",
  async ({ titleId, filter }: { titleId: number; filter?: IFilterBarPayload }) =>
    await ScenarioBuilderService.getDefaultCohort(titleId, filter)
);

export const createScenario = createAsyncThunk(
  "[scenarioBuilder]/createScenario",
  async (scenario: IScenario) =>
    await ScenarioBuilderService.createScenario(scenario)
);

export const runScenarioSimulation = createAsyncThunk(
  "[scenarioBuilder]/runScenarioSimulation",
  async ({ titleId, scenarioId }: { titleId: number; scenarioId: number }) =>
    await ScenarioBuilderService.runScenarioSimulation(titleId, scenarioId)
);

export const getSimulationStatus = createAsyncThunk(
  "[scenarioBuilder]/getSimulationStatus",
  async ({ jobId, whatIfId }: { jobId: string; whatIfId: string }) =>
    await ScenarioBuilderService.getSimulationStatus(jobId, whatIfId)
);

export const getScenarioSimulationStatus = createAsyncThunk(
  "[scenarioBuilder]/getScenarioSimulationStatus",
  async (scenarioId: number) =>
    await ScenarioBuilderService.getScenarioSimulationStatus(scenarioId)
);

export const setScenarioSimulationStatus = (
  scenarioSimulationStatus?: IScenarioSimulationStatus
) => ({
  type: "[scenarioBuilder]/setScenarioSimulationStatus",
  payload: {
    scenarioSimulationStatus,
  },
});

export const getSimulationResult = createAsyncThunk(
  "[scenarioBuilder]/getSimulationResult",
  async ({ jobId, full }: { jobId: string; full?: boolean }) =>
    await ScenarioBuilderService.getSimulationResult(jobId, full)
);

export const getScenarios = createAsyncThunk(
  "[scenarioBuilder]/getScenarios",
  async ({
    titleId,
    statuses,
    limit,
  }: {
    titleId: number;
    statuses?: string[];
    limit?: Pagination;
  }) => await ScenarioBuilderService.getScenarios(titleId, statuses, limit)
);

export const pollScenarioStatus = ({
  scenario_id,
  status,
  job_id,
}: {
  scenario_id: number;
  status?: EStatus;
  job_id?: string[];
}) => ({
  type: "[scenarioBuilder]/pollScenarioStatus",
  payload: {
    scenario_id,
    status,
    job_id,
  },
});

export const clearConfigurationObjectsByPath = () => ({
  type: "[scenarioBuilder]/clearConfigurationObjectsByPath",
})

export const setScenarioFilter = (value: IFilterBarPayload) => ({
  type: '[scenarioBuilder]/setScenarioFilter',
  payload: {
    value
  },
});

export const addScenarioTab = (payload: IScenarioTabs) => ({
  type: '[scenarioBuilder]/addScenarioTab',
  payload
});

export const deleteScenarioTab = (payload: string) => ({
  type: '[scenarioBuilder]/deleteScenarioTab',
  payload
});

export const setScenarioTabs = (payload: IScenarioTabs[]) => ({
  type: '[scenarioBuilder]/setScenarioTabs',
  payload
});

export const setScenarioTabFilter = (filter: IFilterBarPayload, scenarioId: string) => ({
  type: '[scenarioBuilder]/setScenarioTabFilter',
  payload: {
    filter,
    scenarioId
  }
});

export const clearScenarioTabFilter = () => ({
  type: '[scenarioBuilder]/clearScenarioTabFilter',
});

export const setScenarioTabCohort = (cohort: ICohort, scenarioId: string) => ({
  type: '[scenarioBuilder]/setScenarioTabCohort',
  payload: {
    cohort,
    scenarioId
  }
});

export const clearScenarioTabCohort = () => ({
  type: '[scenarioBuilder]/clearScenarioTabCohort',
});

export const setActiveScenarioTabId = (payload: string) => ({
  type: '[scenarioBuilder]/setActiveScenarioTabId',
  payload
});

export const setScenarioAdded = (payload: boolean) => ({
  type: '[scenarioBuilder]/setScenarioAdded',
  payload
});

export const resetScenarioCohort = (payload: string) => ({
  type: '[scenarioBuilder]/resetScenarioCohort',
  payload
});

export const addScenarioTabNode = (payload: {
  scenarioId: string,
  node: INode
}) => ({
  type: '[scenarioBuilder]/addScenarioTabNode',
  payload
});

export const deleteScenarioTabNode = (payload: {
  scenarioId: string,
  nodes: INode[]
}) => ({
  type: '[scenarioBuilder]/deleteScenarioTabNode',
  payload
})

export const changeScenarioTabNode = (payload: {
  scenarioId: string,
  nodes: INode[]
}) => ({
  type: '[scenarioBuilder]/changeScenarioTabNode',
  payload
})

export const setSimulationInfo = (payload: {
  scenarioId: string,
  simulationInfo: ISimulationInfo
}) => ({
  type: '[scenarioBuilder]/setSimulationInfo',
  payload
})
