import {
  IRegisterUser,
  IUserOnboarding,
} from "../store/onboarding/onboarding.state";
import axios, { AxiosInstance, AxiosResponse } from "axios";

import { string } from "yup";

const { REACT_APP_API_URL } = process.env;

export class OnboardingService {
  public static axios: AxiosInstance;
  public static axiosAuthorized: AxiosInstance;

  public static async getUserOnboarding(
    userId?: number,
    shopperId?: number
  ): Promise<IUserOnboarding> {
    const { data } = await OnboardingService.axiosAuthorized.get<
      undefined,
      AxiosResponse<IUserOnboarding>
    >(
      `/onboarding/user/${
        Boolean(shopperId) ? shopperId : userId
      }?is_shopper=${Boolean(shopperId)}`
    );

    return data;
  }

  public static async forgotPassword(email: string) {
    await OnboardingService.axios.post<void>(
      `/onboarding/user/forgot_password`,
      {
        login_id: email,
      }
    );
  }

  public static async oAuthCallback(
    code: string,
    state?: string,
    error?: string
  ) {
    const url = "/onboarding/oauth2/callback";

    const params: Record<string, string> = {
      code,
      state: state!,
      error: error!,
    };

    Object.keys(params).forEach((key) => {
      if (!params[key as keyof typeof params]) {
        delete params[key as keyof typeof params];
      }
    });

    const query = new URLSearchParams(params).toString();

    const { data } = await OnboardingService.axios.get(`${url}?${query}`);

    return data;
  }

  public static async googleAuthorize(state: { [key: string]: string } = {}) {
    const { data } = await OnboardingService.axios.post<{
      redirect_uri: string;
    }>(`/onboarding/oauth2/authorize`, {
      state,
    });

    return data;
  }

  public static async changePassword({
    login_id,
    new_password,
  }: {
    login_id: string;
    new_password: string;
  }): Promise<void> {
    await OnboardingService.axios.post<void>(
      `/onboarding/user/change-password`,
      {
        login_id,
        new_password,
      }
    );
  }

  public static async resendVerifyEmail(email: string): Promise<void> {
    await OnboardingService.axios.put<void>(
      `/onboarding/user/resend_registration_verification`,
      {
        email,
        send_verify_email: true,
      }
    );
  }

  public static async registerUser({
    acceptedMarketing,
    firstName,
    lastName,
    company,
    password,
    email,
    intent,
  }: IRegisterUser): Promise<IUserOnboarding> {
    const { data, request } = await OnboardingService.axios.post<
      undefined,
      AxiosResponse<IUserOnboarding>
    >(`/onboarding/user`, {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      company,
      account_id: 197,
      scopes: [88],
      intent,
      accepted_marketing: acceptedMarketing,
    });
    if (request.status === 409) {
      throw new Error(JSON.parse(request.response)?.detail);
    }
    return data;
  }

  public static async loginUser({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<any> {
    const { data, request } = await axios.post(
      `${REACT_APP_API_URL}/onboarding/user/login`,
      {
        login_id: email,
        password,
      }
    );

    if (request.status === 213) {
      throw new Error(JSON.parse(request.response)?.detail);
    }

    return data;
  }
}
