import { EChartType, IGetWidgetsResponse, useGetWidgetsQuery } from '../../queries/useGetWidgetsQuery';
import FilterBar, { IFilterBarPayload } from '../../components/FilterBar/FilterBar';
import React, { useEffect, useState } from 'react';

import BarChart from '../../components/Charts/BarChart/BarChart';
import { FilterByTopic } from '../../components/FilterByTopic/FilterByTopic';
import GaugeChart from '../../components/Charts/GaugeChart/GaugeChart';
import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import LineChart from '../../components/Charts/LineChart/LineChart';
import MonitorTableChart from '../../components/Charts/MonitorTableChart/MonitorTableChart';
import PieChart from '../../components/Charts/PieChart/PieChart';
import StackedBarChart from '../../components/Charts/StackedBarChart/StackedBarChart';
import StackedLineChart from '../../components/Charts/StackedLineChart/StackedLineChart';
import TableChart from '../../components/Charts/TableChart/TableChart';
import styled from 'styled-components';

//Google custom number format
//https://www.benlcollins.com/spreadsheets/google-sheets-custom-number-format/
const TOTAL_COLUMNS = 12;

const calculateWidth = (ratio: number) => `calc(${(100 / TOTAL_COLUMNS) * ratio}% - (1rem * ${ratio === TOTAL_COLUMNS ? 0 : TOTAL_COLUMNS / ratio}) / 2)`;

export const handleHeadlineHelper = (headline: string, groupByFilter: string[] | undefined, currencyFilter: string | undefined = undefined) => {
  let headlineResult = headline ? headline : '';
  if (groupByFilter && groupByFilter.length > 0) {
    headlineResult = headlineResult + ` (group by ${groupByFilter[0]})`;
  }
  if (currencyFilter && currencyFilter.length > 0) {
    headlineResult = headlineResult + ` - ${currencyFilter}`;
  }

  return headlineResult;
}

export interface IAnalyticsProps {
  className?: string;
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
  whatIfId?: number;
  hideFilters?: boolean;
  titleId?: number;
  filter?: IFilterBarPayload;
  extraFilters?: {
    query?: string;
  }
}

const StyledHeader = styled.header`
  margin: 0px 30px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartWidgetWrapperStyled = styled.li<{ widthRatio: number }>`
  display: flex;

  align-items: center;
  justify-content: center;

  flex-basis: ${({ widthRatio }) => calculateWidth(widthRatio)};
  max-width: ${({ widthRatio }) => `calc(((100% / 12) * ${widthRatio}))`};

  margin-top: 2rem;
`
const Analytics = ({ className, page, title, whatIfId, hideFilters, titleId, filter: cohortFilter, extraFilters }: IAnalyticsProps) => {
  const [widgetsQuery, setWidgetsQuery] = useState<{
    isLoading: boolean;
    error: Error | null;
    data: IGetWidgetsResponse[] | undefined;
  } | undefined>();
  const [filter, setFilter] = useState<IFilterBarPayload | undefined>(cohortFilter);
  const [topic, setTopic] = useState<{ ecoTopicName: string, ecoTopicId: number }>();
  const getWidgetsQueryResponse = useGetWidgetsQuery(page?.dashboard_id);
  
  useEffect(() => {
    if (!getWidgetsQueryResponse || getWidgetsQueryResponse?.isLoading) {
      return;
    }

    if (!getWidgetsQueryResponse?.data) {
      return;
    }

    const { data, error } = getWidgetsQueryResponse;

    if (!data || error) {
      return;
    }

    if (JSON.stringify(widgetsQuery?.data) === JSON.stringify(data)) {
      return;
    }

    setWidgetsQuery(getWidgetsQueryResponse);
  }, [getWidgetsQueryResponse])

  useEffect(() => {
    if(cohortFilter) {
      setFilter(cohortFilter)
    }
  }, [cohortFilter]);

  const chartFactory = (widget: IGetWidgetsResponse) => {
    switch (widget?.chart_type) {
      case EChartType.MonitorTable:
        return <MonitorTableChart widget={widget} titleId={titleId || title?.id } filter={filter} cross_title={page?.cross_title} extraFilters={extraFilters}/>
      case EChartType.LineTs:
        return <StackedLineChart widget={widget} titleId={titleId || title?.id} filter={filter} cross_title={page?.cross_title} whatIfId={whatIfId} extraFilters={extraFilters} />
      case EChartType.Line:
        return <LineChart widget={widget} titleId={titleId || title?.id} filter={filter} cross_title={page?.cross_title} whatIfId={whatIfId} extraFilters={extraFilters} />
      case EChartType.Pie:
        return <PieChart widget={widget} titleId={titleId || title?.id} filter={filter} cross_title={page?.cross_title} whatIfId={whatIfId} extraFilters={extraFilters}/>
      case EChartType.Bar:
        return <BarChart widget={widget} titleId={titleId || title?.id} filter={filter} cross_title={page?.cross_title} whatIfId={whatIfId} extraFilters={extraFilters}/>
      case EChartType.BarTs:
        return <StackedBarChart widget={widget} titleId={titleId || title?.id} filter={filter} cross_title={page?.cross_title} whatIfId={whatIfId} extraFilters={extraFilters}/>
      case EChartType.Table:
        return <TableChart widget={widget} titleId={titleId || title?.id} filter={filter} cross_title={page?.cross_title} whatIfId={whatIfId} extraFilters={extraFilters}/>
      case EChartType.Gauge:
        return <GaugeChart widget={widget} titleId={titleId || title?.id} filter={filter} cross_title={page?.cross_title} whatIfId={whatIfId} extraFilters={extraFilters}/>
      default:
        return null
    }
  }

  return widgetsQuery &&
    !widgetsQuery.isLoading &&
    widgetsQuery.data &&
    widgetsQuery.data.length ? <>
    <StyledHeader style={{ display: hideFilters ? 'none' : 'flex' }}>
      {!hideFilters && <FilterBar titleId={title?.id} onChange={setFilter} />}
      <FilterByTopic widgets={widgetsQuery?.data} onChange={setTopic} />
    </StyledHeader>
    <section className={className}>
      <ul>
        {widgetsQuery
          ?.data
          .filter((w) => w.eco_topic_id === topic?.ecoTopicId)
          .map((w) => <ChartWidgetWrapperStyled widthRatio={w?.width} key={w?.report_id}
          >
            {chartFactory(w)}
          </ChartWidgetWrapperStyled>)}
      </ul>
    </section> </> : null;
};
export default styled(Analytics)`
  
  margin: 0 30px;
  color: #fff;
  height: 80%;

  & > ul {
    padding: 0;
    margin: 0;
    height: 100%;

    list-style-type: none;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
