import { AxiosInstance, AxiosResponse } from "axios";
import { ISubscription } from "../store/billing/billing.state";

export class BillingService {
    public static axios: AxiosInstance;
  
    public static async getSubscriptions(): Promise<ISubscription[]> {
      const { data } = await BillingService.axios.get<
        undefined,
        AxiosResponse<ISubscription[]>
      >(`billing/subscription/all`);
  
      return data;
    }
}