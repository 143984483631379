import { ESubscriptionStatus } from "../store/billing/billing.state";
import { IActivatedSubscription } from "../store/onboarding/onboarding.state";

export enum EPermission {
    growthSimulatorOpenScenario = 0x01,
    growthSimulatorAddScenario = 0x02,
    growthSimulatorSaveScenario = 0x03,
    growthSimulatorDynamicCPI = 0x04,
    growthSimulatorDynamicARPPU = 0x05,
}

export const hasPermission = (activatedSubscription: IActivatedSubscription | null, permission: EPermission): boolean => {
    if (!activatedSubscription) {
        return false;
    }

    const { status, plan_id } = activatedSubscription;

    if (status === ESubscriptionStatus.NotActive) {
        return false;
    }

    switch (plan_id) {
        case 1:
        case 2:
            return [
                EPermission.growthSimulatorOpenScenario,
                EPermission.growthSimulatorAddScenario,
                EPermission.growthSimulatorSaveScenario,
                EPermission.growthSimulatorDynamicCPI,
                EPermission.growthSimulatorDynamicARPPU,
            ].includes(permission)
        default:
            return false;
    }
}