import * as Yup from 'yup';

import { Box, LinearProgress, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { StyledLandingButton, StyledLandingPageInnerContainer, StyledLandingPageWrapper, StyledTextField } from "../Landing/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userRegisteredErrorSelector, userRegisteredLoadingSelector } from "../../store/onboarding/onboarding.selectors";

import SimpoolDialog from '../../components/Dialog/Dialog';
import { StyledBackButton } from '../GrowthSimulatorV2/components/GrowthSimulatorButtons/GrowthSimulatorButtons';
import { StyledCloseTabDialog } from '../ScenarioBuilder/components/ScenarioBuilderWrapper/ScenarioBuilderWrapper';
import { forgotPassword } from '../../store/onboarding/onboarding.actions';
import logo from '../../assets/logo2.png';
import { useFormik } from "formik";

const ForgotPasswordPage = () => {
  const error = useSelector(userRegisteredErrorSelector);
  const { search } = useLocation();
  const [verifyEmailDialogOpen, setVerifyEmailDialogOpen] = useState(false);
  const userRegisteredLoading = useSelector(userRegisteredLoadingSelector);
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').email('Please enter a valid email'),
  })

  const { validateForm, values, handleChange, handleSubmit, handleBlur, errors, touched, isValid } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async ({ email }) => {
      await dispatch(forgotPassword(email));
      setVerifyEmailDialogOpen(true);
    }
  });

  useEffect(() => {
    (() => validateForm())();
  }, [])

  return (
    <>
      <SimpoolDialog
        open={verifyEmailDialogOpen}
        title={`Your request to reset your password has been processed successfully. An email has been sent to your registered address with a secure link to create a new password.
        Please check your inbox, including any spam or junk folders. This link will remain valid for 24 hours from the time of sending.`}
        onClose={() => setVerifyEmailDialogOpen(false)}
      >
        <StyledCloseTabDialog>
          <div className="dialog-btns">
            <StyledBackButton onClick={() => setVerifyEmailDialogOpen(false)}>
              Ok
            </StyledBackButton>
          </div>
        </StyledCloseTabDialog>
      </SimpoolDialog>
      <StyledLandingPageWrapper style={{ maxHeight: 'none' }}>
        {userRegisteredLoading && <LinearProgress></LinearProgress>}
        <StyledLandingPageInnerContainer>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '440px', display: 'flex', justifyContent: 'center' }}>
              <img src={logo} alt="" />
            </Box>
            <Box sx={{ width: '650px', display: 'flex', justifyContent: 'center', marginBottom: '22px' }}>
              <Typography
                variant="h3"
                color={'white'}
                fontFamily={['Open Sans, sans-serif']}
                fontWeight={700}
                fontSize={32}
              >
                Forgot Password
              </Typography>
            </Box>
            <Box sx={{ width: '650px', display: 'flex', justifyContent: 'center', marginBottom: '46px' }}>
              <Typography
                variant="h6"
                color={'white'}
                textAlign="center"
                fontFamily={['DM Sans, sans-serif']}
                fontWeight={400}

              >
                Enter the email address associated with your account and we'll send you a link to reset your password
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box sx={{ width: '440px', marginBottom: '30px' }}>
                <StyledTextField
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  fullWidth
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(errors.email && touched.email)}
                  helperText={errors.email}
                />
              </Box>
              {error && <Box sx={{ width: '440px', display: 'flex', justifyContent: 'center', marginBottom: '40px', color: 'red' }}>
                {error?.message}
              </Box>}
              <Box sx={{ width: '440px', marginBottom: '25px' }}>
                <StyledLandingButton disabled={!isValid} variant="contained" size="large" type="submit" style={{ width: '100%', height: '56px' }}>Continue</StyledLandingButton>
              </Box>
            </form>
            <Box sx={{ width: '440px', marginBottom: '25px' }}>
              <Typography
                variant="subtitle1"
                color='#FFFFFF'
                fontFamily={['DM Sans, sans-serif']}>
                Don't have an account ? <RouterLink to={'register'} style={{ color: 'white' }}>Register</RouterLink>
              </Typography>
            </Box>
          </Box>

        </StyledLandingPageInnerContainer>
      </StyledLandingPageWrapper>
    </>

  );
};

export default ForgotPasswordPage;