import { TabPanel } from "@mui/lab";
import styled from "styled-components";

export const ScenarioBuilderStyledTabPanel = styled(TabPanel)`
  padding: 0 !important;
  height: 100%;
  margin: 0 20px;
  max-height: 75%;
  height: 75%;
  min-height: 75%;

  & > main {
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;