import './index.css';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'
import dotenv from "dotenv";
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
    gtmId: process.env.NODE_ENV !== "production" ? 'GTM-M4MCR23' : 'GTM-522BZNH'
}

TagManager.initialize(tagManagerArgs)

dotenv.config();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
