function subscribe<T>(
  eventName: string,
  listener: (event: { detail: T }) => void
): void {
  document.addEventListener(eventName, listener as any);
}

function unsubscribe<T>(
  eventName: string,
  listener: (event: { detail: T }) => void
): void {
  document.removeEventListener(eventName, listener as any);
}

function publish<T>(eventName: string, data: T): void {
  const event = new CustomEvent<T>(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe };
