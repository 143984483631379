import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const CustomCheckbox = ({ checked, onChange }: { checked: boolean, onChange: () => any}) => {
const uniqueId = uuidv4();
  return (
    <>
      <StlyledInput type="checkbox" id={uniqueId} name="happy" value="yes" checked={checked} onChange={onChange}></StlyledInput>
      <label htmlFor={uniqueId}></label>
    </>
  );

}

export default styled(CustomCheckbox)`
  position: absolute;
  top: 35%;
  left: 20px;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 5px;
  margin: 0;
  background: transparent;
`;

const StlyledInput = styled.input`
  position: absolute;
  top: 35%;
  left: 20px;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 5px;
  margin: 0;
  background: transparent;
  position: absolute;
  z-index: -1;
  opacity: 0;
  &:not(:disabled){
    &:not(:checked) {
      &+label{
        &:hover {
          &::before {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23B3B3BC' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
          }
        }
      }
    }
  }
  &:checked {
    &+label {
      &::before {
        border-color: #B3B3BC;
        background-color: #B3B3BC;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%232E2F4B' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      }
    }
  }
  &+label{
    display: inline-flex;
    align-items: center;
    user-select: none;
    position: absolute;
  top: 38%;
  left: 20px;
    &::before {
      content: '';
      display: inline-block;
      width: 1rem;
  height: 1rem;
      flex-shrink: 0;
      flex-grow: 0;
      border: 2px solid #B3B3BC;
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }
`