import { AxiosInstance, AxiosResponse } from "axios";

import { ISetting } from "../store/settings/settings.state";

export class SettingsService {
  public static axios: AxiosInstance;

  public static async getSettings(titleId: number): Promise<ISetting[]> {
    const { data } = await SettingsService.axios.get<
      undefined,
      AxiosResponse<ISetting[]>
    >(`dashboard/title/${titleId}/settings`);

    return data;
  }

  public static async updateSettings(titleId: number, settings: ISetting[]): Promise<ISetting[]> {
    const { data } = await SettingsService.axios.patch<
      ISetting[],
      AxiosResponse<ISetting[]>
    >(`dashboard/title/${titleId}/settings`, settings);

    return data;
  }
}
