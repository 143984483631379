import {
  IBillingState, ISubscription
} from "./billing.state";

import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../app.state";

const billingStateFeatureSelector = (
  state: IAppState
): IBillingState => state.billing;

export const subscriptionSelector = createSelector(
  billingStateFeatureSelector,
  (state: IBillingState): ISubscription[] => state.subscriptions
);
