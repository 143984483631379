import { CurrencyCode, currencyFormatterInterceptor, money, toFixedNumber, value } from '../../../helpers/formatters';
import { IGetReportDataResponse, useGetReportDataQuery } from '../../../queries/useGetReportDataQuery';
import React, { useEffect, useState } from 'react';

import { Chart } from "react-google-charts";
import ChartContainer from '../ChartContainer/ChartContainer';
import { IChartLegend } from '../models';
import { IFilterBarPayload } from '../../FilterBar/FilterBar';
import { IGetWidgetsResponse } from "../../../queries/useGetWidgetsQuery";
import { SERIES_COLORS } from '../../../helpers/colors';
import { groupBy } from '../../../helpers/extensions';
import { handleHeadlineHelper } from '../../../pages/Analytics/Analytics';
import moment from 'moment';
import { settingsSelector } from '../../../store/settings/settings.selector';
import { sharedAccountIdSelector } from '../../../store/shared/shared.selector';
import { useSelector } from 'react-redux';

export interface IStackedLineChartProps {
  widget: IGetWidgetsResponse,
  titleId?: number,
  filter?: IFilterBarPayload,
  cross_title?: boolean;
  whatIfId?: number;
  extraFilters?: {
    query?: string;
  }
}

const percent = (value: number, signDisplay?: 'auto' | 'never' | 'always') => {
  return (value)?.toLocaleString("en-US", {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    signDisplay
  });
}


export const formatter = (x: any, f: string, currencyCode: CurrencyCode) => {
  switch (f) {
    case 'percent':
      return percent(x);
    case 'currency':
      return money(x, 'auto', 2, 2, currencyCode);
    case 'decimal':
      return Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(x);
    case 'number':
      return Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(x);
    case 'number-currency':
      return money(x, 'auto', 0, 0, currencyCode);

    default:
      return x && value(Number(x), 'never', 0, 0);
  }

}

const StackedLineChart = ({ widget, titleId, filter, cross_title = false, whatIfId, extraFilters }: IStackedLineChartProps) => {
  //Specific groupBy filter from the settings menu, for the specific chart type
  //can be copied and reuse in others;
  const accountIdSelector = useSelector(sharedAccountIdSelector);
  const [groupByFilter, setGroupByFilter] = useState<string[] | undefined>();
  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>();
  const currencySign = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_symbol}");
  const currencyCode = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_code}")?.['parameter_value'] as CurrencyCode;

  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      report_id: widget?.report_id,
      dashboard_id: widget?.dashboard_id,
      title_id: widget?.title_id || titleId,
      days_back: filter?.daysBack || 60,
      date_from: (filter?.dateFrom && String(filter?.dateFrom)) || "-1",
      date_to: (filter?.dateTo && String(filter?.dateTo)) || "-1",
      country: filter?.country,
      platform: filter?.platform,
      source: filter?.source,
      group_by: groupByFilter,
      currency: currencyFilter,
      account_id: cross_title ? accountIdSelector : undefined,
      whatif_id: whatIfId,
      extra_filters: extraFilters
    }, `StackedLineChart-
        ${currencyFilter && currencyFilter}-
        ${widget?.report_id}-${widget?.title_id || titleId}-${filter?.dateFrom}-
        ${filter?.daysBack}-${filter?.dateTo}-${filter?.country}-${filter?.platform}-${filter?.source}-
        ${extraFilters?.toString()}-${groupByFilter?.toString()}-${cross_title && `account_id: ${accountIdSelector}`}-${whatIfId}`);

  const [reportData, setReportData] = useState<IGetReportDataResponse[]>();

  const [rows, setRows] = useState<{ v: Date; }[][]>();
  const [columns, setColumns] = useState<{ type: string; label: any; }[]>();
  const [xAxisTicks, setXAxisTicks] = useState<Date[]>();
  const [yAxisTicks, setYAxisTicks] = useState<number[]>();
  const [legend, setLegend] = useState<{ [key: string]: string }>();

  const [legendFilters, setLegendFilters] = useState<IChartLegend[]>([]);

  useEffect(() => {
    if (!widget || !getReportDataQueryResponse || getReportDataQueryResponse.isLoading) {
      return;
    }

    const { data } = getReportDataQueryResponse;

    if (!data) {
      return;
    }

    if (JSON.stringify(reportData) === JSON.stringify(data)) {
      return;
    }

    const xAxis = widget?.x_axis.toLowerCase();
    const yAxis = widget?.y_axis.toLowerCase();
    const seriesKey = widget?.series.toLowerCase();

    // 0. sort the data by x axis key
    const sortedData = data
      .sort((a, b) => moment(a[xAxis as keyof typeof a]).toDate().getTime() - moment(b[xAxis as keyof typeof a]).toDate().getTime());

    // 1. group by x axis
    const groupByXAxis = groupBy(sortedData, (d) => String(d[xAxis as keyof typeof d]));

    // 2. group by series key
    const groupBySeries = Object.assign({}, ...(Object.keys(groupByXAxis).map(k => ({
      [k]: groupBy(groupByXAxis[k], (d) => String(d[seriesKey as keyof typeof d]))
    }))));

    // 3. generate series
    const series = Object.keys(groupBy(data, (d) => String(d[seriesKey as keyof typeof d])));

    // 4. generate legend
    const _legend = Object.assign({}, ...series.map((s, i) => ({ [s]: SERIES_COLORS[i] })));

    // 5. generate rows
    const rowGenerator = (value: number | string, format: string, color: string, tooltip: any) => [
      { v: value, f: format },
      { v: color },
      { v: tooltip }
    ]

    const _rows = Object.entries<{ [key: string]: IGetReportDataResponse[] }>(groupBySeries).map(([x, gbs]) => [
      { v: moment(x).toDate() },
    ].concat(series.map(s => gbs[s] ? legendFilters && legendFilters.find(l => l.value == s) ? [null, null, null] :
      rowGenerator(
        Number(gbs[s][0][yAxis as keyof IGetReportDataResponse]),
        String(gbs[s][0][yAxis as keyof IGetReportDataResponse]),
        _legend[s], currencyCode && customHTML(gbs[s][0][xAxis as keyof IGetReportDataResponse], gbs[s][0][seriesKey as keyof IGetReportDataResponse], gbs[s][0][yAxis as keyof IGetReportDataResponse], widget?.y_axis_format, currencyCode)) : [null, null, null],

    ).flat(1) as []))

    // 6. generate columns
    const columnGenerator = (label: string) => [
      { type: 'number', label },
      { type: 'string', role: 'style' as any },
      { type: 'string', role: 'tooltip', p: { html: true } }
    ]

    const _columns = [
      { type: 'date', label: widget?.x_label || widget?.x_axis },
    ].concat(series.map(columnGenerator).flat(1) as [])

    // 7. generate x axis ticks
    const _xAxisTicks = _rows.map(r => r[0]?.v);

    // 8. generate y axis ticks

    const _yAxisTicks = Array.from(
      new Set(data.filter(d => !Object.values(d).some(v => legendFilters.map(lf => lf.value).includes(v))).map(d => d[yAxis as keyof typeof d]))).sort((a, b) => Number(a) - Number(b));

    setRows(_rows);
    setColumns(_columns);
    setXAxisTicks(_xAxisTicks);
    setYAxisTicks(_yAxisTicks as number[]);
    setLegend(_legend);

    setReportData(data);
  }, [getReportDataQueryResponse, legendFilters])

  const handleGroupBy = (item: string | undefined) => {
    item ? setGroupByFilter([item]) : setGroupByFilter(undefined);
  }

  const handleCurrency = (item: string) => {
    setCurrencyFilter(item);
  }

  const min = (yAxisTicks?.[0] && ((yAxisTicks?.[0] * 0.91) < 0 ? 0 : yAxisTicks?.[0] * 0.91)) || 0;

  const options = {
    curveType: "function",
    legend: "none",
    vAxis: {
      gridlines: { color: 'transparent' },
      baselineColor: '#9999A4',
      textStyle: { color: '#9999A4' },
      viewWindow: { min: min, },
      format: currencyFormatterInterceptor(widget?.y_axis_format, currencySign?.["parameter_value"]),
    },
    hAxis: {
      gridlines: { color: 'transparent' },
      baselineColor: '#9999A4',
      textStyle: { color: '#9999A4' },
      format: 'YYYY-MM-dd',
      ticks: xAxisTicks
    },
    backgroundColor: 'transparent',
    chartArea: { width: '80%', height: '70%' },
    tooltip: { isHtml: true }
  };



  return <ChartContainer
    loading={getReportDataQueryResponse?.isLoading}
    headline={handleHeadlineHelper(widget?.headline, groupByFilter, currencyFilter)}
    legend={Object.entries(legend || {})?.map(([s, b]) => ({ value: s, background: b }))}
    onChange={(filters) => {
      setLegendFilters(filters);
      setReportData(undefined);
    }}
    groupBy={widget?.group_by || []}
    onGroupByChange={handleGroupBy}
    tooltip={widget.tooltip}
    showCurrency={widget.use_currency}
    onCurrencyChange={handleCurrency}
    exportData={reportData}
  >
    <Chart
      chartType="LineChart"
      height="300px"
      width="100%"
      columns={columns as any}
      rows={rows as any}
      options={options as any}
    />
  </ChartContainer>
};

export default StackedLineChart;

export const customHTML = (xAxis: any, seriesKey: any, yAxis: any, yAxisFormat: string, currencyCode: CurrencyCode) => {
  if (!yAxis || !xAxis) {
    return null;
  }


  return `
  <div style="width: fit-content; height: fit-content; padding: 5px 5px; color: black;">
    <div><b>${xAxis}</b></div>
    <div>${seriesKey}: <b>${formatter(yAxis, yAxisFormat, currencyCode)}</b></div>
  </div>
          `
}
