import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IFilterBarPayload } from '../../components/FilterBar/FilterBar';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import { IGetAccountTitlesResponse } from '../../services/accountTitles.service';
import { ScenarioBuilderService } from '../../services/scenarioBuilder.service';
import { IScenario } from '../../store/scenarioBuilder/scenarioBuilder.state';
import { setWhatIfDescription } from '../../store/shared/shared.actions';
import Analytics from '../Analytics/Analytics';
import { SpinnerContainer } from '../Segmentation/components/SegmentationForm/SegmentationForm';

export interface ISimulationResultProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
}

export const SimulationResult = ({ page, title }: ISimulationResultProps) => {
  //TODO(PPavlov): Make generic and pass all query params to analytics page in the future
  //TODO(PPavlov): Remove SimulationResult in the future
  const { search } = useLocation();
  const whatIfId = Number(new URLSearchParams(search).get('whatIfId'));
  const scenarioId = Number(new URLSearchParams(search).get('scenario_id'));
  const titleId = Number(new URLSearchParams(search).get('titleId')) || undefined;
  
  const [scenario, setScenario] = useState<IScenario | undefined>();
  const dispatch = useDispatch();
  const [ isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchScenario = async() => {
      if(scenarioId && dispatch && whatIfId) {
        try {
          const scenario = await ScenarioBuilderService.getScenario(scenarioId);
          setScenario(scenario);
              
          const description = scenario.whatifs?.find(x => x.id === +whatIfId)?.description;
          description && dispatch(setWhatIfDescription(description))
          setIsLoading(false)
        } catch (e){
          setIsLoading(false)
          console.error(e)
        }
      }
    }
    fetchScenario();
  }, [scenarioId, dispatch, whatIfId]);
  
  return ( isLoading ?
    <SpinnerContainer>
      <CircularProgress size="8rem" />
    </SpinnerContainer> 
    : (
    <Analytics
      page={page} 
      whatIfId={whatIfId!}
      hideFilters
      titleId={titleId || scenario?.title_id}
      filter={scenario?.cohort_key}
      />
    )
  )
};

export default SimulationResult;
