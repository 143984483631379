import { ApplicationType, BusinessType, RevenueModelType } from './../../pages/GrowthSimulatorV2/components/GrowthSimulatorSteps/GrowthSimulatorSteps';
import {
  GrowthSimulationResponse,
  GrowthSimulationSaaSResponse,
  IGrowthSimulatorScenario,
  IGrowthSimulatorWidget,
  IUserBasicResponse
} from "../../pages/GrowthSimulatorV2/models/growthSimulator.models";

import { GrowthSimulatorDetailsSegmentTableType } from '../../pages/GrowthSimulatorV2/components/GrowthSimulatorDetailsSegmentTable/GrowthSimulatorDetailsSegmentTable';
import { IGrowthSimulatorRetentionNumbersForm } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorRetentionNumbers/GrowthSimulatorRetentionNumbers";
import { SerializedError } from "@reduxjs/toolkit";

export enum EPointOfViewType {
  UserAcquisition = "User Acquisition",
  Monetization = "Monetization",
  Product = "Product",
}

export enum EGoalType {
  DailyActiveUsersGoal = "Growth Goal",
  BudgetGoal = "Budget Goal",
  FreeStyle = "Other Goal",
  // SassGoal = "SaaS"
}

export enum Genre {
  Gaming = 'gaming',
  SaaS = 'saas'
};


export interface IGrowthSimulatorState {
  widgets: IGrowthSimulatorWidget[];
  pov: EPointOfViewType | null;
  goal: EGoalType | null;
  business: BusinessType | null;
  application: ApplicationType | null;
  revenue: RevenueModelType | null;
  growthSimulatorRetentionNumbers: IGrowthSimulatorRetentionNumbersForm[];

  growthSimulationByScenarioId: {
    [key: string]: GrowthSimulationResponse | GrowthSimulationSaaSResponse | undefined;
  };

  growthSimulation: GrowthSimulationResponse | null;

  growthSimulatorScenarios: IGrowthSimulatorScenario[];
  scenario: IGrowthSimulatorScenario | null;
  activeTab: string | null;
  arppuTable: {
    arppu?: number,
    days: number,
    values: Record<string, GrowthSimulatorDetailsSegmentTableType[]>;
    weightedAverages: Record<string, number>;
  };
  loading: {
    getGrowthSimulatorWidgets?: boolean;
    executeGrowthSimulation?: boolean;
    executeGrowthSimulationSaaS?: boolean;
    getGrowthSimulatorScenarios?: boolean;
    saveGrowthSimulatorScenario?: boolean;
    registerUserBasic?: boolean;
  };
  error: {
    getGrowthSimulatorWidgets?: SerializedError;
    executeGrowthSimulation?: SerializedError;
    executeGrowthSimulationSaaS?: SerializedError;
    getGrowthSimulatorScenarios?: SerializedError;
    saveGrowthSimulatorScenario?: SerializedError;
    registerUserBasic?: SerializedError;
  },
  bulbState: boolean;
  genre: Genre
}

export const initialState: IGrowthSimulatorState = {
  widgets: [],
  scenario: null,
  pov: EPointOfViewType.Product,
  goal: EGoalType.FreeStyle,
  business: null,
  application: null,
  revenue: null,
  growthSimulation: null,
  activeTab: null,
  growthSimulationByScenarioId: {},
  growthSimulatorScenarios: [],
  error: {},
  loading: {},
  growthSimulatorRetentionNumbers: [
    { day: 1, value: 40 },
    { day: 7, value: 20 },
    { day: 14, value: 12 },
    { day: 30, value: 8 },
    { day: 60, value: 5 },
    { day: 90, value: 4 },
    { day: 150, value: 2 },
    { day: 365, value: 1 },
  ],
  arppuTable: {
    arppu: undefined,
    days: 0,
    values: {},
    weightedAverages: {}
  },
  bulbState: false,
  genre: Genre.Gaming
};
