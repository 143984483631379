import { DataTable, IChartDataAdapter, IChartLegend } from "../models";

import { CurrencyCode, firstCharUpperCase } from "../../../helpers/formatters";
import { IGetReportDataResponse } from "../../../queries/useGetReportDataQuery";
import { IGetWidgetsResponse } from "../../../queries/useGetWidgetsQuery";
import { SERIES_COLORS } from "../../../helpers/colors";
import { formatter } from "../StackedLineChart/StackedLineChart";
import { groupBy } from "../../../helpers/extensions";

export class LineChartDataAdapter implements IChartDataAdapter {
  private _widget?: IGetWidgetsResponse;
  private _currencyCode?: CurrencyCode;

  public initialize(
    widget: IGetWidgetsResponse,
    currencyCode: CurrencyCode
  ): IChartDataAdapter {
    this._widget = widget;
    this._currencyCode = currencyCode;
    return this;
  }

  public adapt(
    report: IGetReportDataResponse[],
    filter?: Partial<IChartLegend>[]
  ): DataTable {
    if (!report || !this._widget) {
      return new DataTable();
    }

    const series = this._widget?.series.toLowerCase();
    const yAxis = this._widget?.y_axis.toLowerCase();
    const xAxis = this._widget?.x_axis.toLowerCase();

    const dataTable = new DataTable();

    const seriesValues = report
      .map((r) => r[series as keyof typeof r])
      .filter((x, i, a) => a.indexOf(x) === i);

    const xAxisValues = report
      .map((r) => r[xAxis as keyof typeof r])
      .filter((x, i, a) => a.indexOf(x) === i);

    const seriesToColor: { [key: string]: string } = seriesValues.reduce(
      (acc, serie, index) => ({
        ...acc,
        [serie?.toString()!]: SERIES_COLORS[index],
      }),
      {}
    );

    const reportGroupedByXAxis =
      groupBy(report, (item) => item[xAxis as keyof typeof item] as string) ||
      [];

    const columns = seriesValues.length;

    //INFO(PPavlov): Populate grouping for missing entries
    //INFO(PPavlov): Place null in correct position based on series value index
    //INFO(PPavlov): Order grouped by values by series in summary
    const orderedGroupedByXAxis: Record<string, IGetReportDataResponse[]> = {};
    xAxisValues.forEach((section) => {
      seriesValues.forEach((value, index) => {
        const valueBySeries = reportGroupedByXAxis[
          section as keyof typeof reportGroupedByXAxis
        ].find((v) => v?.[series as keyof typeof v] === value);

        if(!orderedGroupedByXAxis[section as keyof typeof orderedGroupedByXAxis]){
          orderedGroupedByXAxis[section as keyof typeof orderedGroupedByXAxis] = [];
        }

        orderedGroupedByXAxis[section as keyof typeof orderedGroupedByXAxis][index] = valueBySeries || null!;
      });
    });

    dataTable.addColumn({ type: "string" });

    for (let i = 0; i < columns; ++i) {
      dataTable.addColumn({ type: "number" });
      dataTable.addColumn({ type: "string", role: "style" });
      dataTable.addColumn({
        type: "string",
        role: "tooltip",
        p: { html: true },
      });
    }

    xAxisValues.forEach((section) => {

      const row = orderedGroupedByXAxis[
        section as keyof typeof orderedGroupedByXAxis
      ].map((v) => {
        if (
          v === null ||
          filter?.find((f) => f.value == v[series as keyof typeof v])
        ) {
          return [
            { v: undefined, f: undefined },
            { v: undefined },
            { v: undefined },
          ];
        }

        const color = seriesToColor[v[series as keyof typeof v]?.toString()!];
      
        return [
          {
            v: v[yAxis as keyof typeof v],
            f: v[yAxis as keyof typeof v]?.toString(),
          },
          { v: color },
          {
            v:
              this._widget &&
              customHTML(
                section?.toString(),
                v[series as keyof typeof v],
                v[yAxis as keyof typeof v],
                this._widget?.y_axis_format,
                this._currencyCode || 'USD',
                xAxis
              ),
          },
        ];
      });

      dataTable.addRow([{ v: section }, ...row.flat(1)]);
    });

    const legend: IChartLegend[] = seriesValues.map((value, index) => ({
      background: SERIES_COLORS[index],
      value: value?.toString(),
    })) as IChartLegend[];

    dataTable.setLegend(legend);
    return dataTable;
  }
}

const customHTML = (xAxis: any, seriesKey: any, yAxis: any, yAxisFormat: string, currencyCode: CurrencyCode, xAxisFormat?: any) => {
  if (yAxis === null || xAxis === null || yAxis === undefined || xAxis === undefined) {
    return null;
  }

  return `
  <div style="width: fit-content; height: fit-content; padding: 5px 5px; color: black;">
    <div>${xAxisFormat && firstCharUpperCase(xAxisFormat)}: <b>${xAxis}</b></div>
    <div>${seriesKey}: <b>${formatter(yAxis, yAxisFormat, currencyCode)}</b></div>
  </div>
          `
};
