import { NumericFormat } from "react-number-format";
import { WrappedFieldProps } from "redux-form";
import styled from "styled-components";

export const ReduxFormNumericFormat = ({ input: { onChange, onFocus, value }, meta, ...other }: WrappedFieldProps & { firstInput: boolean }) => {
  return <NumericFormat
    onClick={(event: any) => event.stopPropagation()}
    onValueChange={onChange}
    onFocus={onFocus}
    value={value.formattedValue}
    {...other}
  />
}

export const StyledReduxFormNumericFormat = styled(ReduxFormNumericFormat)`
  width: 90px;
  height: 25px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #58a4fd;

  background: #fff;
  color: #000;

  &:disabled {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #000;
  }
  ${props => ((props.firstInput && props.meta.error) ||(props.meta.error && props.meta.visited)) && 'color: orange; border: 2px solid orange;' }
`