import * as fromActions from "./billing.actions";

import { createSlice } from "@reduxjs/toolkit";
import {
  IBillingState, initialState
} from "./billing.state";


export const billingSlice = createSlice({
  name: "[billing]",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(
      fromActions.getSubscriptions.fulfilled,
      (state: IBillingState, { payload }) => {
        state.loading.getSubscriptions = false;
        state.error.getSubscriptions = null;
        state.subscriptions = payload;
      }
    );
    builder.addCase(
      fromActions.getSubscriptions.pending,
      (state: IBillingState) => {
        state.loading.getSubscriptions = true;
        state.error.getSubscriptions = null;
        state.subscriptions = [];
      }
    );
    builder.addCase(
      fromActions.getSubscriptions.rejected,
      (state: IBillingState, { error }) => {
        state.loading.getSubscriptions = false;
        state.error.getSubscriptions = error;
      }
    );
  },
});

export default billingSlice.reducer;
