import { CurrencyCode, currencyFormatterInterceptor, money } from '../../../helpers/formatters';
import { DataTable, IChartLegend } from '../models';
import { IGetReportDataResponse, useGetReportDataQuery } from '../../../queries/useGetReportDataQuery';
import React, { useEffect, useState } from 'react';

import { Chart, } from 'react-google-charts';
import ChartContainer from '../ChartContainer/ChartContainer';
import { IFilterBarPayload } from '../../FilterBar/FilterBar';
import { IGetWidgetsResponse } from '../../../queries/useGetWidgetsQuery';
import { SERIES_COLORS } from '../../../helpers/colors';
import { StackedBarChartDataAdapter } from './StackedBarChartDataAdapter';
import { groupBy } from '../../../helpers/extensions';
import { handleHeadlineHelper } from '../../../pages/Analytics/Analytics';
import moment from 'moment';
import { settingsSelector } from '../../../store/settings/settings.selector';
import { sharedAccountIdSelector } from '../../../store/shared/shared.selector';
import { useSelector } from 'react-redux';

export interface IStackedBarChartProps {
  widget: IGetWidgetsResponse,
  titleId?: number,
  filter?: IFilterBarPayload,
  cross_title?: boolean;
  whatIfId?: number;
  extraFilters?: {
    query?: string;
  }
}

export const StackedBarChart = ({ widget, titleId, filter, cross_title = false, whatIfId, extraFilters }: IStackedBarChartProps) => {
  const [groupByFilter, setGroupByFilter] = useState<string[] | undefined>();
  const [legendFilters, setLegendFilters] = useState<IChartLegend[]>([]);
  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>();
  const accountIdSelector = useSelector(sharedAccountIdSelector);
  const currencyCode = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_code}")?.['parameter_value'] as CurrencyCode;
  const currencySign = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_symbol}");

  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      report_id: widget?.report_id,
      dashboard_id: widget?.dashboard_id,
      title_id: widget?.title_id || titleId,
      days_back: filter?.daysBack || 60,
      date_from: (filter?.dateFrom && String(filter?.dateFrom)) || "-1",
      date_to: (filter?.dateTo && String(filter?.dateTo)) || "-1",
      country: filter?.country,
      platform: filter?.platform,
      source: filter?.source,
      group_by: groupByFilter,
      currency: currencyFilter,
      account_id: cross_title ? accountIdSelector : undefined,
      whatif_id: whatIfId,
      extra_filters: extraFilters
    }, `StackedBarChart-
        ${currencyFilter && currencyFilter}-
        ${widget?.report_id}-
        ${widget?.title_id || titleId}-
        ${filter?.dateFrom}-
        ${filter?.daysBack}-
        ${filter?.dateTo}-
        ${filter?.country}-
        ${filter?.platform}-
        ${filter?.source}-
        ${extraFilters?.toString()}-
        ${groupByFilter?.toString()}-
        ${cross_title && `account_id: ${accountIdSelector}`}-${whatIfId}`);

  const [reportData, setReportData] = useState<IGetReportDataResponse[]>();

  useEffect(() => {
    if (!widget || !getReportDataQueryResponse || getReportDataQueryResponse.isLoading) {
      return;
    }

    const { data, error } = getReportDataQueryResponse;

    if (error) {
      return;
    }

    if (JSON.stringify(reportData) === JSON.stringify(data)) {
      return;
    }

    setReportData(data);
    
  }, [getReportDataQueryResponse])

  const handleGroupBy = (item: string | undefined) => {
    item ? setGroupByFilter([item]) : setGroupByFilter(undefined);
  }

  const handleCurrency = (item: string) => {
    setCurrencyFilter(item);
  }

  const options = {
    legend: "none",
    backgroundColor: 'transparent',
    chartArea: { width: '80%', height: '70%' },
    isStacked: widget?.stack_normelize ? 'percent' : true,
    vAxis: {
      gridlines: { color: 'transparent' },
      baselineColor: '#9999A4',
      textStyle: { color: '#9999A4' },
      format: currencyFormatterInterceptor(widget?.y_axis_format, currencySign?.["parameter_value"]),
    },
    hAxis: {
      gridlines: { color: 'transparent' },
      baselineColor: '#212175',
      textStyle: { color: '#9999A4' },
    },
    tooltip: { isHtml: true }
  };

  const [rows, cols, legend] = new StackedBarChartDataAdapter()
    .initialize(widget, currencyCode)
    .adapt(reportData!, legendFilters)
    .make();

  return <ChartContainer
    loading={getReportDataQueryResponse?.isLoading}
    headline={handleHeadlineHelper(widget?.headline, groupByFilter, currencyFilter)}
    legend={legend}
    groupBy={widget?.group_by || []}
    onGroupByChange={handleGroupBy}
    onChange={(filter) => setLegendFilters(filter)}
    showCurrency={widget.use_currency}
    onCurrencyChange={handleCurrency}
    tooltip={widget.tooltip}
    exportData={reportData}
  >
    {Boolean(reportData) && <Chart
      chartType="ColumnChart"
      rows={rows as any}
      columns={cols as any}
      options={options as any}
      height="300px"
      width="100%"
    />}

  </ChartContainer>
};

export default StackedBarChart;



