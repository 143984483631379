import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

export interface ICohortsCardAnimatedRowProps {
  className?: string;
  title: string;
  value: number;
  avg: number;
  format?: (value: number) => string | number;
  color?: {
    positive: string;
    negative: string;
  }
}

const ValuesContainer = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold;
`;

const AvgContainer = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  background: #212239 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 16px;
  padding: 0px 10px;
  margin-bottom: 5px;
`

const AvgContainerWrapper = styled.div`
  margin: 0 5px;
  padding: 0 5px;
`

const HorizontalDivider = styled.span`
  border-right: 1px solid #A8A8A8;
  width: 0px;
  display: inline-block;
  margin: 0 auto;
  height: 100%;
`

const CohortsCardAnimatedRow = ({
  className,
  title,
  avg,
  value,
  format,
  color = { positive: '#09FF00', negative: '#FF0000' }
}: ICohortsCardAnimatedRowProps) => {
  const [hovered, setHovered] = useState(false);

  return <ul
  className={cn('cohorts-card-animated-row', className)}
  style={{
    gridTemplateColumns: hovered ? '0.6fr auto 0.4fr' : '0.8fr 1fr 0.8fr'
  }}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
  >
    <li style={{
      color: value > avg ? color.positive : color.negative
    }}>{title}</li>
    <AnimatePresence>
      {hovered ? <motion.li
        transition={{ delay: 0.1 }}
        initial={{ width: 0, overflow: 'hidden', opacity: 0 }}
        animate={{ width: '100%', opacity: 1 }}
        exit={{ width: 0, overflow: 'hidden', opacity: 0 }}
      >
        <AvgContainerWrapper>
          <AvgContainer color={value > avg ? color.positive : color.negative}>
            {format ? format(avg) : avg} vs avg
          </AvgContainer>
        </AvgContainerWrapper>
      </motion.li> : <li> <HorizontalDivider /></li>
      }
    </AnimatePresence>
    <li>
      <ValuesContainer color={value > avg ? color.positive : color.negative}>
        {format ? format(value) : value}
      </ValuesContainer>
    </li>
  </ul>
};

export default styled(CohortsCardAnimatedRow)`
  &:hover {
    background: #363752;
  }

  max-height: 1.3rem;

  display: grid;
  height: 100%;

  padding: 15px 10px;
  margin: 0;
  list-style-type: none;
  text-align: center;

  border-top: 1px solid #A8A8A8;

  & > li {
    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }

    & >  div {
      display: flex;
      flex-direction: row;
      justify-content: center;

      border-left: 1px solid #A8A8A8;
      border-right: 1px solid #A8A8A8;

      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
