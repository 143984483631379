import { useEffect, useState } from 'react';

import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import { useApplicationContext } from '../../Context';
import { useLocation } from 'react-router-dom';

export interface IPageContainerProps {
  component: any
}

const dfs = (
  nodes: IGetPageResponse[],
  predicate: (node: IGetPageResponse) => boolean): IGetPageResponse | undefined => {
  const stack: IGetPageResponse[] = [...nodes] || [];
  while (stack.length) {
    const current = stack.pop()!;

    if (predicate(current)) {
      return current;
    }

    current?.children?.forEach(n => stack.push(n))
  }

  return undefined;
}


const PageContainer = ({ component: Component }: IPageContainerProps) => {
  const { pathname } = useLocation();
  const [page, setPage] = useState<IGetPageResponse | undefined>();
  const [{ pagesQuery, title }] = useApplicationContext();

  useEffect(() => {
    if (pagesQuery?.isLoading) {
      return;
    }

    if (!pagesQuery?.data?.length) {
      return;
    }

    const [path] = pathname.split('/').filter(Boolean);

    const _page = dfs(pagesQuery?.data!, (n) => n.path === path);

    if (!_page) {
      return;
    }

    setPage(_page);
  }, [pagesQuery, pathname])

  return (page || title) ? <Component {...{ page, title }} /> : null;
};

export default PageContainer;
