import { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import axios from 'axios';

const {REACT_APP_API_URL}  = process.env;

export interface IVerifyCallbackProps {
}

const VerifyCallback = (props: IVerifyCallbackProps) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParameters: {[key: string]: string} = location.search
      .split('?')
      .pop()
      ?.split("&")
      ?.map((p) => p.split("="))
      .reduce(
        (aggregate, [key, value]) => ({ ...aggregate, [key]: value }),
        {}
      )!;

    const verificationId = location.pathname
      .replace('/verify-callback/', '')
      .trim();

    const simpoolId = Number(queryParameters['simpool_id']);

    axios.post(`${REACT_APP_API_URL}/onboarding/user/verify`, {
      verification_id: verificationId,
      simpool_id: simpoolId
    }).then((_) => {
      history.push(`/complete?simpool_id=${simpoolId}`)
    })
  }, [location])

  return null;
};

export default VerifyCallback;
