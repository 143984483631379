import { Box, Dialog, DialogContent, Tab, Tabs } from '@mui/material';
import { DEFAULT_CALCULATOR_INPUT, DEFAULT_RETENTION, DEFAULT_RETENTION_NUMBERS, DEFAULT_TITLE_ID, NOT_USED, NOT_USER_RETENTION_ANCHORS } from '../../constants/growthSimulator.constants';
import { EGoalType, Genre } from '../../../../store/growthSimulator/growthSimulator.state';
import { EPermission, hasPermission } from '../../../../permissions/permissions';
import { FieldArray, InjectedFormProps, WrappedFieldArrayProps, change, formValueSelector, getFormMeta, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import GrowthSimulatorDynamicCpiTable, { DynamicCpi } from '../GrowthSimulatorDynamicCpiTable/GrowthSimulatorDynamicCpiTable';
import { GrowthSimulatorFormData, GrowthSimulatorSaaSFormModel, IExecuteGrowthSimulationInput, IGrowthSimulatorScenario, NumericFormatType, Web2RetentionAnchors } from '../../models/growthSimulator.models';
import GrowthSimulatorSaaSGoal, { DEFAULT_SAAS } from '../goals/GrowthSimulatorSaaSGoal/GrowthSimulatorSaaSGoal';
import { MixpanelEvent, trackMixpanelEvent, trackSimulationExecute, trackSimulationSave } from '../../../../mixpanel';
import { StyledBackButton, StyledNextButton, StyledOpenButton, StyledRunButton, StyledSaveButton } from '../GrowthSimulatorButtons/GrowthSimulatorButtons';
import { executeGrowthSimulation, executeGrowthSimulationSaaS, saveGrowthSimulatorScenario, setActiveTab, setGenre, setGoalType } from '../../../../store/growthSimulator/growthSimulator.actions';
import { goalSelector, growthSimulationSaaSSelector, growthSimulatorActiveTabSelector, growthSimulatorGenreSelector, tooltipSelectorById } from '../../../../store/growthSimulator/growthSimulator.selector';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { AppDispatch, RootState } from '../../../../store/app.state';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CSVLink } from 'react-csv';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import GrowthSimulatorCalculator from '../GrowthSimulatorCalculator/GrowthSimulatorCalculator';
import GrowthSimulatorDetailsSegmentTable from '../GrowthSimulatorDetailsSegmentTable/GrowthSimulatorDetailsSegmentTable';
import GrowthSimulatorRetentionNumbers from '../GrowthSimulatorRetentionNumbers/GrowthSimulatorRetentionNumbers';
import GrowthSimulatorScenariosDialog from '../GrowthSimulatorScenariosDialog/GrowthSimulatorScenariosDialog';
import { GrowthSimulatorV2Service } from '../../services/growthSimulator.service';
import MUITooltip from '../../../../components/MUITooltip/MUITooltip';
import { MenuItem } from '@szhsin/react-menu';
import SimpoolDialog from '../../../../components/Dialog/Dialog';
import { StyledCloseTabDialog } from '../../../ScenarioBuilder/components/ScenarioBuilderWrapper/ScenarioBuilderWrapper';
import { StyledMenu } from '../../../../components/Dropdown/SettingsDropDown';
import { TabContext } from '@mui/lab';
import { clone } from '../../../../helpers/extensions';
import cn from 'classnames';
import config from '../../../../config';
import { convertToNumericFormatType } from '../../helpers/helpers';
import { growthSimulationSelector } from '../../../../store/growthSimulator/growthSimulator.selector';
import { isRegistered, returnObjectByKey } from '../../../ScenarioBuilder/helpers/helpers';
import styled from 'styled-components';
import { unwrapResult } from '@reduxjs/toolkit';
import { useApplicationContext } from '../../../../Context';
import { useLocation } from 'react-router-dom';
import { userSelector } from '../../../../store/onboarding/onboarding.selectors';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router";

const StyledGrowthSimulatorTabHeader = styled.header`
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-column-gap: 10px;

    margin-bottom: 10px;
    padding: 0 5px;
`

const StyledGrowthSimulatorTabHeaderAside = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledGrowthSimulatorTabHeaderSection = styled.section`
  display: inline-grid;
  grid-template-columns: 9fr 1fr;
  
  align-items: center;

  color: #fff;

  background: #19253c;
    border-radius: 5px;
    padding: 5px;
    box-shadow: inset 5px 5px 12px #00000066;
`

const StyledGrowthSimulatorAddScenarioIconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`

const StyledGrowthSimulatorCallToActionContainer = styled.div`
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`

const StyledTab = styled(Tab)`
    &.Mui-selected {
      color: transparent !important;
    }
    
    margin: 0 2.5px !important;

    height: 35px !important;
    min-height: 30px !important;
    padding: 10px !important;
    border-radius: 5px !important;
    border: 1px solid rgba(255, 255, 255, 0.5) !important;

    input {
      color: rgba(255, 255, 255, 0.5) !important;
    }

    span {
      color: rgba(255, 255, 255, 0.5) !important;
    }

  &.growth-simulator-tab-active {
    border: 1px solid #fff !important;
    input {
      color: #fff !important;
    }

    span {
      color: #fff !important;
    }
  }

`

const StyledTabs = styled(Tabs)`
  min-height: auto !important;

  .MuiTabScrollButton-root {
    height: 35px !important;
    min-height: 30px !important;
  }
`

const TabLabel = ({
  className,
  initial,
  onChange,
  closeTab,
  shouldShowX = true
}: {
  className?: string,
  initial?: string,
  onChange: (val: string) => void,
  closeTab: () => void;
  shouldShowX?: boolean;
}) => {
  const [value, setValue] = useState<string | undefined>(initial);

  useEffect(() => {
    onChange(value!);
  }, [value])

  const handleValueChange = ({ target: { value: inputValue } }: { target: { value: string } }) => {
    setValue(inputValue);
  }

  return <div className={className}>
    <span><EditIcon /></span>
    <input onChange={handleValueChange} value={value} maxLength={12} />
    {shouldShowX && <span onClick={closeTab} className='close-icon'><CloseIcon fontSize='large' /></span>}

  </div>
}

const StyledTabLabel = styled(TabLabel)`
    display: flex;
    align-items: center;
    justify-content: center;
  .close-icon{
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
  span {
    color: #fff;

    svg {
      position: relative;
      top: 1px;
      font-size: 1rem;
    }
  }

  input {
    width: 100px;
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    color: #fff;
    text-align: center;
  }

`

interface Props extends InjectedFormProps<{ members: GrowthSimulatorFormData[]; }, {}> {
}

const { REACT_APP_URL } = process.env;
export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noreferrer');
};
const getGenre = () => {
  const genre_from_url = new URLSearchParams(window.location.search).get('genre');
  return genre_from_url;
}
const GrowthSimulatorFieldArray = ({ fields, meta }: WrappedFieldArrayProps<GrowthSimulatorFormData>) => {
  const tab = useSelector(growthSimulatorActiveTabSelector);
  const userOnboarding = useSelector(userSelector);
  const addTabTooltip = useSelector(tooltipSelectorById(70, 10));
  const saveButtonTooltip = useSelector(tooltipSelectorById(70, 40));
  const dispatch = useDispatch<AppDispatch>();
  const [context, setContext] = useApplicationContext();
  const location = useLocation();
  const history = useHistory()

  useEffect(() => {
    dispatch(setActiveTab(INITIAL_ID));
  }, [dispatch])

  const goal = useSelector(goalSelector);
  const genre = useSelector(growthSimulatorGenreSelector);

  const [isDisabledRunButton, setIsDisabledRunButton] = useState(false);
  const userBasic = useSelector(userSelector);

  let handleExecuteSimulationTimer: ReturnType<typeof setTimeout> | undefined = undefined;

  const formValues = useSelector(state => getFormValues('growthSimulatorForm')(state)) as { members: GrowthSimulatorFormData[]; };
  const currentTabIndex = formValues.members.findIndex(({ id }) => id === tab);
  const activeInput = useSelector<RootState>(state => state.form['growthSimulatorForm']?.active) as string | undefined
  const syncErrors = useSelector(state => (getFormSyncErrors('growthSimulatorForm')(state) as { members: any[]} )?.members?.[currentTabIndex])?.[genre === Genre.SaaS ? 'saasForm' : 'calculator']

  // const [ errors, setErrors ] = useState<Array<Record<string, string>>>([]);
  // useEffect(() => {
  //   console.log(syncErrors && Object.values(syncErrors))
  // }, [syncErrors]);

  // useEffect(() => {
  //   const currentKey = activeInput?.split?.('.').pop();
  //   if(currentKey ) {
  //     const result: any[] = [];
  //     syncErrors && returnObjectByKey(syncErrors, currentKey, result, true);
  //     const error: string | undefined = result.pop()
      
  //     if(error) {
  //       setErrors((prev) => {
  //         const hasAlreadyError = prev.findIndex((p) => p.hasOwnProperty(currentKey)) !== -1
  //         return hasAlreadyError ? prev : [...prev, { [currentKey]: error }];
  //       })
  //     }
  //     else {
  //       setErrors((prev) => {
  //         const newErrors = prev.filter((err) => !err.hasOwnProperty(currentKey))
  //         return newErrors;
  //       })
  //     }
  //   }
  // }, [activeInput, syncErrors]);

  const sassGoalForm: GrowthSimulatorSaaSFormModel | undefined
    = useSelector(state =>
      (getFormValues('growthSimulatorForm')(state) as { members: GrowthSimulatorFormData[]; })
        ?.members.find(({ id }) => id === tab)?.saasForm)

  const currentFormGoal:any
    = useSelector(state =>
      (getFormValues('growthSimulatorForm')(state) as { members: GrowthSimulatorFormData[]; })
        ?.members.find(({ id }) => id === tab)?.goal);


  const form = useSelector(state =>
    (getFormValues('growthSimulatorForm')(state) as { members: GrowthSimulatorFormData[]; }));

  const isCalledRef = useRef(false);

  useEffect(() => {
    const handler = async () => {
      const searchParams = new URLSearchParams(location.search);

      const simulationId = Number(searchParams.get('simulation_id'));
      const genre = searchParams.get('genre') as Genre;
      const goal = searchParams.get('goal') as EGoalType;
      const shareId = searchParams.get('goal') as EGoalType;

      if (simulationId && genre && goal && form?.members?.length) {
        isCalledRef.current = true;

        const [member] = form.members;

        await dispatch(setGenre(genre));
        await dispatch(setGoalType(goal));

        const simulation = await GrowthSimulatorV2Service.getGrowthSimulationById(simulationId);
        await dispatch(change('growthSimulatorForm', `members`, []))

        const config: any = simulation.simulation_config;

        let field;
        
        if(genre === Genre.SaaS) {
          field = handleSaveScenarioSelected({
            data: {
              saasForm: config.extra_params,
              name: member.name,
              goal
            },
            name: member.name
          } as any);
        } else {
          field = handleSaveScenarioSelected({
            data: {
              ...config.extra_params,
              name: member.name,
              goal
            },
            name: member.name
          } as any);
        }

  

        await dispatch(setActiveTab(field?.id!));

        await dispatch(setGenre(genre));
        await dispatch(setGoalType(goal));

        
        switch (genre) {
          case Genre.Gaming:
            await dispatch({
              type: '[growth-simulator]/executeGrowthSimulation/fulfilled',
              payload: {
                ...simulation,
                simulation_config: {
                  ...simulation.simulation_config,
                  id: field?.id!,
                  name: field?.name!,
                  tab: field?.tab!
                }
              }
            });
            break;
          case Genre.SaaS:
            await dispatch({
              type: '[growth-simulator]/executeGrowthSimulationSaaS/fulfilled',
              payload: {
                ...simulation,
                simulation_config: {
                  ...simulation.simulation_config,
                  id: field.id,
                  name: field.name,
                  tab: field.tab
                }
              }
            });
            break
          default:
            throw new Error('Not Implemented')
        }

        
      }
    }

    if (!isCalledRef?.current) {
      handler();
    }
  }, [form])

  const handleAddSaaSPkg = () => {
    if (isRegistered() || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
      if (hasPermission(userOnboarding?.subscription!, EPermission.growthSimulatorAddScenario) || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {

      } else {
        handleOpenSubscriptionDialog();
      }
    } else {
      handleOpenRegisterDialog();
    }
  }
  const handleAddScenario = () => {
    if (isRegistered() || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
      if (hasPermission(userOnboarding?.subscription!, EPermission.growthSimulatorAddScenario) || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
    fields.push({
      tab: fields.length.toString(),
      id: uuidv4(),
      name: `Scenario ${uuidv4().slice(0, 5)}`,
      retentionNumbers: DEFAULT_RETENTION_NUMBERS,
      calculator: DEFAULT_CALCULATOR_INPUT,
      saasForm: DEFAULT_SAAS,
      goal: EGoalType.FreeStyle,
      isScenarioSaved: false
    })
      } else {
        handleOpenSubscriptionDialog();
      }
    } else {
      handleOpenRegisterDialog();
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    dispatch(setActiveTab(newValue.toString()))
  };

  const handleOpenDynamicCpiDialog = () => {
    if (context.isTourActive) {
      return;
    }

    if (isRegistered() || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
      if (hasPermission(userOnboarding?.subscription!, EPermission.growthSimulatorDynamicCPI) || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
        const member = formValues.members.find(({ id }) => id === tab)!;

        const days = member?.calculator.days;
        const budget = member?.calculator.budget;

        if (goal === EGoalType.BudgetGoal) {
          if (!budget || !budget.floatValue) {
            return handleOpenEnterDaysDialog();
          }
        }

        if (!days || !days.floatValue) {
          return handleOpenEnterDaysDialog();
        }

        setContext((state) => ({
          ...state,
          dialogs: {
            ...state.dialogs,
            dynamicCpiDialogOpen: true
          }
        }))
      } else {
        handleOpenSubscriptionDialog();
      }
    } else {
      handleOpenRegisterDialog()
    }
  }

  useEffect(() => {
    setContext(ctx => ({
      ...ctx,
      counters: {
        ...ctx.counters,
        run: 0
      }
    }));
  }, [goal])

  const handleExecuteSimulation = () => {
    if (isRegistered()) {
      const counter = context.counters.run + 1;
      setContext(ctx => ({
        ...ctx,
        counters: {
          ...ctx.counters,
          run: counter
        }
      }));

      const transformSAASInput = (model: GrowthSimulatorSaaSFormModel) => {
        return {
          monthly_subscribers: model.monthly_subscribers.floatValue,
          cost_per_lead: model.cost_per_lead.floatValue,
          conversion: model.conversion.floatValue,
          monthly_subscribers_churn: model.monthly_subscribers.floatValue,
          annual_subscribers_churn: model.annual_subscribers_churn.floatValue,
          predicted_months: model.predicted_months.floatValue,
          growth_goal: model.growth_goal.floatValue,
          marketing_budget: model.marketing_budget.floatValue,
          starter: {
            monthly: {
              price: model.starter.monthly.price.floatValue,
              proportion: model.starter.monthly.proportion.floatValue,
            },
            annually: {
              price: model.starter.annually.price.floatValue,
              proportion: model.starter.annually.proportion.floatValue,
            }
          },
          beginner: {
            monthly: {
              price: model.beginner.monthly.price.floatValue,
              proportion: model.beginner.monthly.proportion.floatValue,
            },
            annually: {
              price: model.beginner.annually.price.floatValue,
              proportion: model.beginner.annually.proportion.floatValue,
            }
          }
        }
      }

      setIsDisabledRunButton(true);
      handleExecuteSimulationTimer = setTimeout(() => {
        clearTimeout(handleExecuteSimulationTimer);
        setIsDisabledRunButton(false);
      }, 10 * 1000);

      switch (genre) {
        case Genre.SaaS:
          dispatch(executeGrowthSimulationSaaS(
            {
              input: {
                user_id: userBasic?.id?.toString()!,
                is_tutorial: context.isTourActive && context.tour === 'tour-a',
                title_id: 88,
                simulation_type: "GROWTH",
                monthly_subscribers: (currentFormGoal === EGoalType.FreeStyle && (sassGoalForm?.monthly_subscribers?.floatValue || 0)) || null,
                growth_goal: (currentFormGoal === EGoalType.DailyActiveUsersGoal && (sassGoalForm?.growth_goal?.floatValue || 0)) || null,
                marketing_budget: (currentFormGoal === EGoalType.BudgetGoal && (sassGoalForm?.marketing_budget?.floatValue || 0)) || null,
                cost_per_lead: sassGoalForm?.cost_per_lead?.floatValue || 0,
                conversion: sassGoalForm?.conversion?.floatValue || 0,
                monthly_subscribers_churn: sassGoalForm?.monthly_subscribers_churn?.floatValue || 0,
                annual_subscribers_churn: sassGoalForm?.annual_subscribers_churn?.floatValue || 0,
                predicted_months: sassGoalForm?.predicted_months?.floatValue || 0,
                packages: [
                  {
                    package_name: "Starter",
                    period: "Monthly",
                    price: sassGoalForm?.starter?.monthly?.price?.floatValue || 0,
                    proportion: sassGoalForm?.starter?.monthly?.proportion?.floatValue || 0
                  },
                  {
                    package_name: "Starter",
                    period: "Annually",
                    price: sassGoalForm?.starter?.annually?.price?.floatValue || 0,
                    proportion: sassGoalForm?.starter?.annually?.proportion?.floatValue || 0
                  },
                  {
                    package_name: "Beginner",
                    period: "Monthly",
                    price: sassGoalForm?.beginner?.monthly?.price?.floatValue || 0,
                    proportion: sassGoalForm?.beginner?.monthly?.proportion?.floatValue || 0
                  },
                  {
                    package_name: "Beginner",
                    period: "Annually",
                    price: sassGoalForm?.beginner?.annually?.price?.floatValue || 0,
                    proportion: sassGoalForm?.beginner?.annually?.proportion?.floatValue || 0
                  },
                ],
                extra_params: {
                  id: tab!,
                  ...transformSAASInput(sassGoalForm!)
                }
              }
            }
          ))
          break;
        case Genre.Gaming:
          // case EGoalType.DailyActiveUsersGoal:
          // case EGoalType.FreeStyle:
          const input = formValues.members.find(({ id }) => id === tab)!;

          let transformedCalculatorInput = transformCalculatorInput(input);

          const web2_retention_anchors = input.retentionNumbers.reduce((acc, val) => ({
            ...acc,
            [val.day]: `${val.value.floatValue}%`
          }), {})

          const _dynamicCPI: DynamicCpi[] | undefined = input.dynamicCpi ? clone(input.dynamicCpi) : undefined;

          /* (PPavlov) Fix for Nadav, day_from = 1, messes up the calculations */
          if (_dynamicCPI) {
            _dynamicCPI.forEach((value) => {
              value.day_from -= 1;
            })
          }

          const payload: IExecuteGrowthSimulationInput = {
            user_id: userBasic?.id?.toString()!,
            is_tutorial: context.isTourActive && context.tour === 'tour-a',
            retention: DEFAULT_RETENTION,
            title_id: DEFAULT_TITLE_ID,
            installs: input.calculator?.dailyInstalls?.floatValue || NOT_USED,
            duration: String(input.calculator.days.floatValue),
            dau_goal: input.calculator.dauGoal.floatValue || NOT_USED,
            budget: input.calculator.budget.floatValue || NOT_USED,
            web3_conversion: NOT_USED,
            web3_retention_anchors: NOT_USER_RETENTION_ANCHORS,
            web2_retention_anchors: web2_retention_anchors as Web2RetentionAnchors,
            organic: `${input.calculator.organicUplift.floatValue}%`,

            cpa: (input.calculator.monetizationType === 'CPA' && input.calculator.cpa.floatValue) || NOT_USED,
            cpc: (input.calculator.monetizationType === 'CPC' && input.calculator.cpc.floatValue) || NOT_USED,
            cpm: (input.calculator.monetizationType === 'CPM' && input.calculator.cpm.floatValue) || NOT_USED,

            ctr: ((input.calculator.monetizationType === 'CPC' || input.calculator.monetizationType === 'CPA') && input.calculator.ctr.floatValue) || NOT_USED,
            current_dau_max_seniority: input.calculator.dauMaximumSeniority.floatValue || NOT_USED,
            impressions_per_dau: input.calculator.impressionsPerDau.floatValue || NOT_USED,
            install_rate: (input.calculator.monetizationType === 'CPA' && input.calculator.installRate.floatValue) || NOT_USED,
            extra_params: transformedCalculatorInput,
            cpi: _dynamicCPI || [
              { day_from: 0, day_to: input.calculator.days.floatValue, cpi: input.calculator.cpi.floatValue, budget: input.calculator.budget.floatValue }
            ]
          }

          dispatch(executeGrowthSimulation({ input: payload }));
          break;
        default:
          throw new Error('Not Implemented')
      }

      trackSimulationExecute();
    
  } else {
    handleOpenRegisterDialog()
  }
  }

  const handleSaveScenario = () => {
    if (isRegistered() || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
      if (hasPermission(userOnboarding?.subscription!, EPermission.growthSimulatorSaveScenario) || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
        trackSimulationSave();

    const input = formValues.members.find(({ id }) => id === tab)!;
    const tabIndex = formValues.members.findIndex(({ id }) => id === tab)!;

    dispatch(saveGrowthSimulatorScenario({
      input: {
        name: input.name!,
        data: {
          tab: input.tab,
          id: input.id,
          name: input.name,
          retentionNumbers: input.retentionNumbers.map(({ day, value }) => ({
            day,
            value: value.floatValue
          })),
          calculator: {
            budget: input.calculator.budget?.floatValue,
            dauGoal: input.calculator.dauGoal?.floatValue,
            dailyInstalls: input.calculator.dailyInstalls?.floatValue,
            days: input.calculator.days?.floatValue,
            cpi: input.calculator.cpi?.floatValue,
            organicUplift: input.calculator.organicUplift?.floatValue,
            conversion: input.calculator.conversion?.floatValue,
            arppu: input.calculator.arppu?.floatValue,
            currentDauScale: input.calculator.currentDauScale?.floatValue,
            dauMaximumSeniority: input.calculator.dauMaximumSeniority?.floatValue,
            impressionsPerDau: input.calculator.impressionsPerDau?.floatValue,
            cpm: input.calculator.cpm?.floatValue,
            ctr: input.calculator.ctr?.floatValue,
            cpc: input.calculator.cpc?.floatValue,
            installRate: input.calculator.installRate?.floatValue,
            cpa: input.calculator.cpa?.floatValue,
            monetizationType: input.calculator.monetizationType
          },
          dynamicCpi: input.dynamicCpi,
          dynamicCpiWeightedAverage: input.dynamicCpiWeightedAverage?.floatValue,
          saasForm: {
            monthly_subscribers: (input.goal === EGoalType.FreeStyle && (input?.saasForm?.monthly_subscribers?.floatValue || 0)) || null,
            growth_goal: (input.goal === EGoalType.DailyActiveUsersGoal && (input?.saasForm?.growth_goal?.floatValue || 0)) || null,
            marketing_budget: (input.goal === EGoalType.BudgetGoal && (input?.saasForm?.marketing_budget?.floatValue || 0)) || null,
            cost_per_lead: input.saasForm.cost_per_lead?.floatValue,
            conversion: input.saasForm.conversion?.floatValue,
            monthly_subscribers_churn: input.saasForm.monthly_subscribers_churn?.floatValue,
            annual_subscribers_churn: input.saasForm.annual_subscribers_churn?.floatValue,
            predicted_months: input.saasForm.predicted_months?.floatValue,
            starter: {
              monthly: {
                price: input.saasForm.starter.monthly.price?.floatValue,
                proportion: input.saasForm.starter.monthly.proportion?.floatValue,
              },
              annually: {
                price: input.saasForm.starter.annually.price?.floatValue,
                proportion: input.saasForm.starter.annually.proportion?.floatValue,
              }
            },
            beginner: {
              monthly: {
                price: input.saasForm.beginner.monthly.price?.floatValue,
                proportion: input.saasForm.beginner.monthly.proportion?.floatValue,
              },
              annually: {
                price: input.saasForm.beginner.annually.price?.floatValue,
                proportion: input.saasForm.beginner.annually.proportion?.floatValue,
              }
            }
          },
          goal: input.goal
        },
        title_id: DEFAULT_TITLE_ID,
        genre: genre
      }
    }))
      .then(unwrapResult)
      .then((result) => {
        dispatch(change('growthSimulatorForm', `members[${tabIndex}].isScenarioSaved`, true))
      })
      } else {
        handleOpenSubscriptionDialog();
      }
    } else {
      handleOpenRegisterDialog()
    }
  }


  const handleCloseDynamicCpiDialog = () => {
    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        dynamicCpiDialogOpen: false
      }
    }))
  }

  const handleOpenScenariosDialog = () => {
    if (context.isTourActive) {
      return;
    }

    if (isRegistered() || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
      if (hasPermission(userOnboarding?.subscription!, EPermission.growthSimulatorOpenScenario) || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
        setContext((state) => ({
          ...state,
          dialogs: {
            ...state.dialogs,
            scenariosDialogOpen: true
          }
        }))
      } else {
        handleOpenSubscriptionDialog();
      }
    } else {
      handleOpenRegisterDialog();
    }
  }

  const handleCloseScenariosDialog = () => {
    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        scenariosDialogOpen: false
      }
    }))
  }

  const handleOpenSegmentDialog = () => {
    if (context.isTourActive) {
      return;
    }

    if (config.FEATURE_FLAGS.useArppuTable) {
      setContext((state) => ({
        ...state,
        dialogs: {
          ...state.dialogs,
          segmentDialogOpen: true
        }
      }))
      return;
    }

    if (isRegistered() || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
      if (hasPermission(userOnboarding?.subscription!, EPermission.growthSimulatorDynamicARPPU) || userBasic?.trial_status === 'active' || userBasic?.trial_status === 'subscribed') {
        const member = formValues.members.find(({ id }) => id === tab)!;

        const days = member.calculator.days;
        if (days && days.floatValue > 0) {
          handleOpenSegmentDialog();
        } else {
          handleOpenEnterDaysDialog();
        }
      } else {
        handleOpenSubscriptionDialog();
      }
    } else {
      handleOpenRegisterDialog();
    }
  }

  const handleCloseSegmentDialog = () => {
    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        segmentDialogOpen: false
      }
    }))
  }

  const handleOpenEnterDaysDialog = () => {
    if (context.isTourActive) {
      return;
    }


    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        enterDaysDialogOpen: true
      }
    }))
  }

  const handleCloseEnterDaysDialog = () => {
    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        enterDaysDialogOpen: false
      }
    }))
  }

  const handleOpenRegisterDialog = () => {
    if (context.isTourActive) {
      return;
    }

    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        registerDialogOpen: true
      }
    }))

  }

  const handleCloseRegisterDialog = () => {
    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        registerDialogOpen: false
      }
    }))
  }

  const handleOpenSubscriptionDialog = () => {
    if (context.isTourActive) {
      return;
    }

    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        subscriptionDialogOpen: true
      }
    }))
  }

  const handleCloseSubscriptionDialog = () => {
    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        subscriptionDialogOpen: false
      }
    }))
  }

  const handleSaveScenarioSelected = (scenario: IGrowthSimulatorScenario): GrowthSimulatorFormData => {
    const { calculator } = scenario.data;
    const { saasForm } = scenario.data;

    const field = {
      tab: fields.length.toString(),
      id: uuidv4(),
      name: scenario.name,
      retentionNumbers: scenario?.data?.retentionNumbers?.map(({ value, day }) => ({
        day,
        value: convertToNumericFormatType(value || 0, undefined, '%')
      })),
      dynamicCpi: scenario.data.dynamicCpi,
      dynamicCpiWeightedAverage: scenario.data.dynamicCpiWeightedAverage ? convertToNumericFormatType(scenario.data.dynamicCpiWeightedAverage, '$') : undefined,
      calculator: {
        dauGoal: convertToNumericFormatType(calculator?.dauGoal || 0),
        budget: convertToNumericFormatType(calculator?.budget, '$'),
        dailyInstalls: convertToNumericFormatType(calculator?.dailyInstalls || 0),
        days: convertToNumericFormatType(calculator?.days || 0),
        cpi: convertToNumericFormatType(calculator?.cpi, '$'),
        organicUplift: convertToNumericFormatType(calculator?.organicUplift, undefined, '%'),
        arppu: convertToNumericFormatType(calculator?.arppu, '$'),
        conversion: convertToNumericFormatType(calculator?.conversion, undefined, '%'),
        currentDauScale: convertToNumericFormatType(calculator?.currentDauScale || 0),
        dauMaximumSeniority: convertToNumericFormatType(calculator?.dauMaximumSeniority || 0),
        impressionsPerDau: convertToNumericFormatType(calculator?.impressionsPerDau || 0),
        ctr: convertToNumericFormatType(calculator?.ctr || 0, undefined, '%'),
        cpc: convertToNumericFormatType(calculator?.cpc || 0, '$'),
        installRate: convertToNumericFormatType(calculator?.installRate || 0, undefined, '%'),
        cpa: convertToNumericFormatType(calculator?.cpa || 0, '$'),
        cpm: convertToNumericFormatType(calculator?.cpm || 0, '$'),
        monetizationType: calculator?.monetizationType
      },
      saasForm: {
        monthly_subscribers: convertToNumericFormatType(saasForm?.monthly_subscribers || 0),
        growth_goal: convertToNumericFormatType(saasForm?.growth_goal || 0),
        marketing_budget: convertToNumericFormatType(saasForm?.marketing_budget || 0, '$'),
        cost_per_lead: convertToNumericFormatType(saasForm?.cost_per_lead || 0, '$'),
        conversion: convertToNumericFormatType(saasForm?.conversion || 0, undefined, '%'),
        monthly_subscribers_churn: convertToNumericFormatType(saasForm?.monthly_subscribers_churn || 0, undefined, '%'),
        annual_subscribers_churn: convertToNumericFormatType(saasForm?.annual_subscribers_churn || 0, undefined, '%'),
        predicted_months: convertToNumericFormatType(saasForm?.predicted_months || 0),
        starter: {
          monthly: {
            price: convertToNumericFormatType(saasForm?.starter?.monthly.price || 0, '$'),
            proportion: convertToNumericFormatType(saasForm?.starter?.monthly.proportion || 0, undefined, '%'),
          },
          annually: {
            price: convertToNumericFormatType(saasForm?.starter?.annually.price || 0, '$'),
            proportion: convertToNumericFormatType(saasForm?.starter?.annually.proportion || 0, undefined, '%'),
          }
        },
        beginner: {
          monthly: {
            price: convertToNumericFormatType(saasForm?.beginner?.monthly.price || 0, '$'),
            proportion: convertToNumericFormatType(saasForm?.beginner?.monthly.proportion || 0, undefined, '%'),
          },
          annually: {
            price: convertToNumericFormatType(saasForm?.beginner?.annually.price || 0, '$'),
            proportion: convertToNumericFormatType(saasForm?.beginner?.annually.proportion || 0, undefined, '%'),
          }
        }
      },
      goal: scenario.data.goal,
      isScenarioSaved: true
    };

    fields.push(field)

    return field;
  }

  const handleDynamicCPISubmit = (values: DynamicCpi[]): void => {
    const member = formValues.members.find(({ id }) => id === tab)!;
    const tabIndex = formValues.members.findIndex(({ id }) => id === tab)!;

    const parsedValues = values.map(({ day_from, day_to, cpi, budget }) => {
      return {
        day_from: Number(day_from),
        day_to: Number(day_to),
        cpi: Number(cpi),
        budget: Number(budget)
      }
    });

    const weightedAverage = parsedValues
      .reduce((acc, { day_from, day_to, cpi }) => acc + (((day_to - day_from) / member?.calculator?.days.floatValue) * cpi), 0);

    dispatch(change('growthSimulatorForm', `members[${tabIndex}].dynamicCpi`, parsedValues))
    dispatch(change('growthSimulatorForm', `members[${tabIndex}].dynamicCpiWeightedAverage`, {
      formattedValue: `$${weightedAverage}`,
      value: `${weightedAverage}`,
      floatValue: weightedAverage,
    }))
  }

  const calculatorValidator = (goal: EGoalType): boolean => {
    const member = formValues.members.find(({ id }) => id === tab)!;
    switch (genre) {
      case Genre.Gaming:
        switch (goal) {
          case EGoalType.BudgetGoal:
            return !(Boolean(member?.calculator?.budget?.floatValue) && Boolean(member?.calculator?.days?.floatValue) && (Boolean(member?.calculator?.cpi?.floatValue) || Boolean(member?.dynamicCpiWeightedAverage?.floatValue)));
          case EGoalType.DailyActiveUsersGoal:
            return !(Boolean(member?.calculator?.dauGoal?.floatValue) && Boolean(member?.calculator?.days?.floatValue) && (Boolean(member?.calculator?.cpi?.floatValue) || Boolean(member?.dynamicCpiWeightedAverage?.floatValue)));
          case EGoalType.FreeStyle:
            return !(Boolean(member?.calculator?.dailyInstalls?.floatValue) && Boolean(member?.calculator?.days?.floatValue) && (Boolean(member?.calculator?.cpi?.floatValue) || Boolean(member?.dynamicCpiWeightedAverage?.floatValue)));
          default:
            return false;
        };
      case Genre.SaaS:
        switch (goal) {
          case EGoalType.BudgetGoal:
            return !(Boolean(member?.saasForm?.marketing_budget?.floatValue) && Boolean(member?.saasForm?.cost_per_lead?.floatValue) && Boolean(member?.saasForm?.conversion?.floatValue) && Boolean(member?.saasForm?.monthly_subscribers_churn?.floatValue) && Boolean(member?.saasForm?.annual_subscribers_churn?.floatValue) && Boolean(member?.saasForm?.predicted_months?.floatValue));
          case EGoalType.DailyActiveUsersGoal:
            return !(Boolean(member?.saasForm?.growth_goal?.floatValue) && Boolean(member?.saasForm?.cost_per_lead?.floatValue) && Boolean(member?.saasForm?.conversion?.floatValue) && Boolean(member?.saasForm?.monthly_subscribers_churn?.floatValue) && Boolean(member?.saasForm?.annual_subscribers_churn?.floatValue) && Boolean(member?.saasForm?.predicted_months?.floatValue));
          case EGoalType.FreeStyle:
            return !(Boolean(member?.saasForm?.monthly_subscribers?.floatValue) && Boolean(member?.saasForm?.cost_per_lead?.floatValue) && Boolean(member?.saasForm?.conversion?.floatValue) && Boolean(member?.saasForm?.monthly_subscribers_churn?.floatValue) && Boolean(member?.saasForm?.annual_subscribers_churn?.floatValue) && Boolean(member?.saasForm?.predicted_months?.floatValue));
          default:
            return false;
        };
      default:
        return false;
    }
  }
  const [exportingData, setExportingData] = useState([]);
  const growthSimulationSaaS = useSelector(growthSimulationSaaSSelector)?.simulation_id;
  const growthSimulation = useSelector(growthSimulationSelector)?.simulation_id;

  useEffect(() => {
    if (growthSimulationSaaS || growthSimulation) {
      prepareExportingData();
    }

  }, [growthSimulationSaaS, growthSimulation]);

  const prepareExportingData = async () => {
    try {
      const { data } = genre === Genre.SaaS ?
        await GrowthSimulatorV2Service.exportDataSaaS(growthSimulationSaaS!) :
        await GrowthSimulatorV2Service.exportData(growthSimulation!);

      setExportingData(data);
      return data;
    } catch {
      return undefined;
    }
  };

  return <>
    {tab && <TabContext value={tab!}>
      <StyledGrowthSimulatorTabHeader>
        <StyledGrowthSimulatorTabHeaderSection>
          <StyledTabs
            value={tab!}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            TabIndicatorProps={{
              sx: { backgroundColor: 'transparent' }
            }}
          >
            {fields.map((field, index) => <StyledTab

              key={formValues?.members?.[index]?.id}
              disableRipple
              value={formValues?.members?.[index]?.id}
              label={<StyledTabLabel
                initial={formValues?.members?.[index]?.name}
                onChange={(value) => dispatch(change('growthSimulatorForm', `members[${index}].name`, value))}
                shouldShowX={formValues.members.length > 1}
                closeTab={() => {
                    const prevTabId = index !== 0 ? formValues.members[index - 1].id : formValues.members[index + 1].id;
                    const currentCallback = () => {
                      dispatch(setActiveTab(prevTabId ))
                      fields.remove(index);
                      setContext((state) => ({
                        ...state,
                        dialogs: {
                          ...state.dialogs,
                          deleteScenarioDialog: {
                            ...state.dialogs.deleteScenarioDialog,
                            isOpen: false
                          }
                        }
                      }))
                    };
                    if (!formValues?.members?.[index]?.isScenarioSaved) {
                      setContext((state) => ({
                        ...state,
                        dialogs: {
                          ...state.dialogs,
                          deleteScenarioDialog: {
                            isOpen: true,
                            callback: currentCallback
                          }
                        }
                      }))
                    } else {
                      currentCallback();
                    }
                }} />}
              className={cn({
                ['growth-simulator-tab-active']: tab === formValues?.members?.[index]?.id,

              }, `growth-simulator-tab-id-${formValues?.members?.[index]?.id}`)} />)}
          </StyledTabs>
          <StyledGrowthSimulatorAddScenarioIconButton onClick={handleAddScenario}>
            <MUITooltip title={addTabTooltip}>
              <AddIcon></AddIcon>
            </MUITooltip>
          </StyledGrowthSimulatorAddScenarioIconButton>
        </StyledGrowthSimulatorTabHeaderSection>
        <StyledGrowthSimulatorTabHeaderAside>
          <StyledOpenButton onClick={handleOpenScenariosDialog}>Open</StyledOpenButton>
        </StyledGrowthSimulatorTabHeaderAside>
      </StyledGrowthSimulatorTabHeader>
      {fields.map((field, index, value) =>
        <Box key={`${field}.retentionNumbers-${index}`}
          hidden={tab !== value.get(index)?.id}
        >
          {genre !== Genre.SaaS && <Box mb={2}>
            <FieldArray name={`${field}.retentionNumbers`} component={GrowthSimulatorRetentionNumbers} />
          </Box>}
          {genre !== Genre.SaaS && <GrowthSimulatorCalculator
            value={value.get(index)}
            onDynamicCpiDialogOpen={handleOpenDynamicCpiDialog}
            onSegmentDialogOpen={handleOpenSegmentDialog}
            field={field}
            index={index}
            goal={value.get(index).goal}
          />}
          {genre === Genre.SaaS
            &&
            <GrowthSimulatorSaaSGoal
              scenarioId={tab}
              fieldsTest={value.get(index).saasForm}
              fieldName={`${field}.saasForm`}
              handleIconClick={handleAddSaaSPkg}
              goal={value.get(index).goal}
            />}
        </Box>)}
    </TabContext>}

    <StyledGrowthSimulatorCallToActionContainer>
      <StyledMenu
        offsetY={5}
        menuButton={
          <StyledSaveButton
            type="button"
            endIcon={<ArrowDropDownIcon />}
          >
            Save
          </StyledSaveButton>
        }>
        <MenuItem >
          <MUITooltip title={saveButtonTooltip} >
            <div onClick={handleSaveScenario} >Save to Simpool</div>
          </MUITooltip>
        </MenuItem>
        <MenuItem disabled={!Boolean(exportingData?.length)}>
          <CSVLink data={exportingData?.length > 0 ? exportingData : []} filename={`simpool_growth_${goal}_${growthSimulationSaaS}`}>
            Download
          </CSVLink>
        </MenuItem>
      </StyledMenu>
      <StyledRunButton
        data-tour-id="growth_simulator_details_calculator_run_button"
        onClick={handleExecuteSimulation}
        disabled={
          isDisabledRunButton || (currentFormGoal && calculatorValidator(currentFormGoal))
        }>
        <span>Run</span>
      </StyledRunButton>
    </StyledGrowthSimulatorCallToActionContainer>
      <span
        style={{
          color: 'orange',
          fontSize: '16px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        { syncErrors && Object.values(syncErrors).length > 0 && 'Orange marked fields are mandatory' }
      </span>

    <SimpoolDialog open={context.dialogs.subscriptionDialogOpen!} title={
      `You need to be logged in or have an active subscription in order to use premium features!`
    } onClose={handleCloseSubscriptionDialog}>
      <StyledCloseTabDialog>
        <div className="dialog-btns">
          <StyledBackButton onClick={(e) => {
            e.preventDefault();
            handleCloseSubscriptionDialog();
          }}>
            Cancel
          </StyledBackButton>
          <StyledNextButton onClick={(e) => {
            e.preventDefault();
            handleCloseSubscriptionDialog();
            openInNewTab(`${REACT_APP_URL}/subscription`);
          }}>
            Subscribe
          </StyledNextButton>
          <StyledNextButton onClick={(e) => {
            e.preventDefault();
            handleCloseSubscriptionDialog();
            // openInNewTab(`${REACT_APP_URL}/login`);
            const genre_from_url = getGenre()
            const genre_for_redirect = genre ? genre : genre_from_url
            history.push('/login?src=/growth-simulator-v2/details&redirect=/growth-simulator-v2/details?genre=' + genre_for_redirect);
          }}>
            Login
          </StyledNextButton>
        </div>
      </StyledCloseTabDialog>
    </SimpoolDialog>

    <SimpoolDialog open={context.dialogs.registerDialogOpen!} title={
      `You need to be a registered and subscribed user in order to use Premium features.`
    } onClose={handleCloseRegisterDialog}>
      <StyledCloseTabDialog>
        <div className="dialog-btns">
          <StyledBackButton onClick={(e) => {
            e.preventDefault();
            handleCloseRegisterDialog();
            trackMixpanelEvent(MixpanelEvent.SaveCancel);
          }}>
            Cancel
          </StyledBackButton>
          <StyledNextButton onClick={(e) => {
            e.preventDefault();
            trackMixpanelEvent(MixpanelEvent.SaveRegister);
            handleCloseRegisterDialog();
            openInNewTab(`${REACT_APP_URL}/register?intent=subscription`);
          }}>
            Register
          </StyledNextButton>
        </div>
      </StyledCloseTabDialog>
    </SimpoolDialog>

    <SimpoolDialog
      open={context.dialogs.enterDaysDialogOpen!}
      title={`You need to enter days in order to use dynamic CPI!`}
      onClose={() => (
        setContext((state) => ({
          ...state,
          dialogs: {
            ...state.dialogs,
            enterDaysDialogOpen: false
          }
        }))
      )}
    >
      <StyledCloseTabDialog>
        <div className="dialog-btns">
          <StyledBackButton onClick={handleCloseEnterDaysDialog}>
            Cancel
          </StyledBackButton>
        </div>
      </StyledCloseTabDialog>
    </SimpoolDialog>

    <GrowthSimulatorScenariosDialog
      onScenarioSelected={handleSaveScenarioSelected}
      open={context.dialogs.scenariosDialogOpen!} onClose={handleCloseScenariosDialog}>
    </GrowthSimulatorScenariosDialog>

    <Dialog
      PaperProps={{
        style: {
          backgroundColor: '#1a273f',
          boxShadow: 'none',
          borderRadius: 20,
          border: '2px solid #BBBBBB',
          width: goal === EGoalType.BudgetGoal ? 800 : 600,
          maxWidth: goal === EGoalType.BudgetGoal ? 800 : 600,
          padding: '5px 0',
          maxHeight: 400
        }
      }}
      open={context.dialogs.dynamicCpiDialogOpen!} onClose={handleCloseDynamicCpiDialog}
    >
      <DialogContent style={{
        padding: 10
      }}>
        <GrowthSimulatorDynamicCpiTable
          onCancel={handleCloseDynamicCpiDialog}
          dynamicCPI={formValues?.members?.find(({ id }) => id === tab)?.dynamicCpi}
          goal={goal}
          onSave={handleDynamicCPISubmit}
          days={formValues?.members?.find(({ id }) => id === tab)?.calculator?.days?.floatValue!}
          budget={formValues?.members?.find(({ id }) => id === tab)?.calculator?.budget?.floatValue!}
        ></GrowthSimulatorDynamicCpiTable>
      </DialogContent>
    </Dialog>

    <Dialog
      PaperProps={{
        style: {
          backgroundColor: '#1a273f',
          boxShadow: 'none',
          borderRadius: 20,
          border: '2px solid #BBBBBB',
          width: 800,
          maxWidth: 800,
          padding: '5px 0',
          maxHeight: 400
        }
      }}
      open={context.dialogs.segmentDialogOpen!} onClose={handleCloseSegmentDialog}
    >
      <DialogContent style={{
        padding: 10
      }}>
        <GrowthSimulatorDetailsSegmentTable
          onCancel={handleCloseSegmentDialog}
          onSave={(values) => console.log(values)}
          days={formValues?.members?.find(({ id }) => id === tab)?.calculator?.days?.floatValue}
          retentionNumbers={formValues?.members?.find(({ id }) => id === tab)?.retentionNumbers}
          tabIndex={formValues?.members?.findIndex(({ id }) => id === tab)!}
          scenarioId={formValues?.members?.find(({ id }) => id === tab)?.id || ''}

        ></GrowthSimulatorDetailsSegmentTable>
      </DialogContent>
    </Dialog>
    <SimpoolDialog
      open={context.dialogs.deleteScenarioDialog?.isOpen!}
      title={`You will lose unsaved scenario, click Close to lose it anyway. `}
      onClose={() => (
        setContext((state) => ({
          ...state,
          dialogs: {
            ...state.dialogs,
            deleteScenarioDialog: {
              ...state.dialogs.deleteScenarioDialog,
              isOpen: false
            }
          }
        }))
      )}
    >
      <StyledCloseTabDialog>
        <div className="dialog-btns">
          <StyledNextButton onClick={context.dialogs.deleteScenarioDialog?.callback}>
            Close
          </StyledNextButton>
          <StyledBackButton onClick={() => (
            setContext((state) => ({
              ...state,
              dialogs: {
                ...state.dialogs,
                deleteScenarioDialog: {
                  ...state.dialogs.deleteScenarioDialog,
                  isOpen: false
                }
              }
            }))
          )}>
            Cancel
          </StyledBackButton>
        </div>
      </StyledCloseTabDialog>
    </SimpoolDialog>
  </>
}

const GrowthSimulatorForm = ({ handleSubmit }: Props) => {
  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <FieldArray name="members" component={GrowthSimulatorFieldArray} />
    </form>
  );
};

const INITIAL_ID = uuidv4();

export default reduxForm({
  form: 'growthSimulatorForm',
  initialValues: {
    members: [
      {
        tab: '0',
        id: INITIAL_ID,
        name: `Scenario X`,
        retentionNumbers: DEFAULT_RETENTION_NUMBERS,
        calculator: DEFAULT_CALCULATOR_INPUT,
        saasForm: DEFAULT_SAAS,
        //TODO: Add goal everywhere
        goal: EGoalType.FreeStyle,
        isScenarioSaved: false
      } as GrowthSimulatorFormData
    ]
  }
})(GrowthSimulatorForm);

function transformCalculatorInput(input: GrowthSimulatorFormData) {
  const { retentionNumbers, dynamicCpi, calculator, dynamicCpiWeightedAverage, id, tab, name } = input;

  let calculatorInput = Object.keys(calculator).reduce((accumulator, key) => {
    if (!Array.isArray(calculator[key as keyof typeof calculator])) {
      return {
        ...accumulator,
        [key]: (calculator[key as keyof typeof calculator] as NumericFormatType)?.floatValue
      };
    }

    return accumulator;
  }, {});

  let output = {
    calculator: calculatorInput,
    retentionNumbers: retentionNumbers.map((v) => ({
      ...v,
      value: v.value.floatValue
    })),
    dynamicCpi,
    dynamicCpiWeightedAverage: dynamicCpiWeightedAverage ? dynamicCpiWeightedAverage?.floatValue : undefined,
    id, tab, name
  }
  return output;
}

