import React, { useEffect, useRef, useState } from 'react';
import { SeeItButtonStyled, SimItButtonStyled } from '../Buttons/Buttons';
import { money, percent, value } from '../../helpers/formatters';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Switch from '@mui/material/Switch';
import cn from 'classnames';
import styled from 'styled-components';
import UpdateIcon from '@mui/icons-material/Update';
import { StyledSpinnerWrapper } from '../Dropdown/MultiselectDropdown';
import { CircularProgress } from '@mui/material';

export interface ISegmentsTableProps {
  className?: string;
  rows?: any;
  columns?: Array<any>;
  columnSize?: String;
  onRowClick?: any;
  bodyWidth?: any;
}

const SegmentsTableItem = ({ className, columns, row, onRowClick }: { columns: any, row: any, className?: string, onRowClick: any }) => {
  
  return (
    <li className={cn('Segments-table-item', className)}>
      <ul>
        {
          columns.map((col: any, index: number) => {
            
            switch(col.type) {
              case 'money':
                return (
                  <li style={col.styles} key={index}>
                    <p>
                      <span>{money(row[col.key])}</span>
                    </p>
                  </li>
                )
              case 'percent':
                return (
                  <li style={col.styles} key={index}>
                    <p>
                      <span>{percent(row[col.key])}</span>
                    </p>
                  </li>
                )
              case 'switch':
                return (
                  <li style={col.styles} key={index}>
                    <p>
                      <span><Switch disabled={!row?.segment_schedule} onClick={col.onClick(row.segmentID, row?.segment_schedule?.status)} checked={col.checked(row)} /></span>
                    </p>
                  </li>
                )
              case 'icons':
                return (
                  <li className='table-icons' key={index}>
                      {
                        col.icons.map((ico: any, index: number) => {
                          switch(ico.type) {
                            case 'edit_schedule':
                              return <UpdateIcon className="svg_icons" key={index} onClick={(e) => ico.onClick(e, row)} />
                            case 'edit':
                              return <EditIcon className="svg_icons" key={index} onClick={(e) => ico.onClick(e, row)} />
                            case 'delete':
                              return <DeleteIcon className="svg_icons" key={index} onClick={(e) => ico.onClick(e, row)} />
                            case 'download':
                              return <DownloadIcon className="svg_icons" key={index} onClick={(e) => ico.onClick(e, row)} />
                            case 'info':
                              return <InfoIcon className="svg_icons" key={index} onClick={(e) => ico.onClick(e, row)} />
                            default:
                              return '';
                          }
                        })
                      }
                  </li>
                )
              case 'buttons':
                return (
                  <li style={col.styles} key={index}>
                      {
                        col.buttons.map((but: any, index: number) => {
                          switch(but.type) {
                            case 'simit':
                              return <SimItButtonStyled key={index} onClick={(event: any) => but.onClick(event, row)}  styles="margin-right: 20px"/>
                            case 'seeit':
                              return <SeeItButtonStyled key={index} onClick={(event: any) => but.onClick(event, row)} />
                            default:
                              return '';
                          }
                        })
                      }
                  </li>
                )
              default:
                // console.log(row[col.key]);
                
                return (
                  <li style={col.styles} key={index} onClick={() => onRowClick(row)}>
                    <p>
                      <span>{row[col.key]}</span>
                    </p>
                  </li>
                );
            }
          })
        }
      </ul>
  </li>);
};

const StyledSegmentsTableItem = styled(SegmentsTableItem)<any>`
  margin: 10px 0;

  & > ul {
    display: grid;
    grid-template-columns: ${(props: any) => props.columnSize ? props.columnSize : "0.3fr 0.3fr 0.3fr 0.7fr 0.4fr 0.4fr 0.4fr 0.3fr 0.4fr 0.4fr"};
    grid-column-gap: 10px;
    
    & > li {
      background: #2C2C3E;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 1rem;
      color: #E7E7E7;
    }
  }

  .table-icons .svg_icons {
    transform: scale(1.2);
    flex: 1 1 0px;
    margin-right: 10px;
    cursor: pointer;

    &:first-child {
      margin-left: 10px;
    }

    &:hover {
      color: #4A9CFB;
    }
  }
`


export const StyledSegmentsTableItemHeaderContainer = styled.ul<any>`
    display: grid;
    grid-template-columns: ${(props: any) => props.columnSize ? props.columnSize : "0.3fr 0.3fr 0.3fr 0.7fr 0.4fr 0.4fr 0.4fr 0.3fr 0.4fr 0.4fr"};
    grid-column-gap: 10px;

    & > li {
      background: #404052;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.2rem;
    padding: 10px 0;
    color: #E7E7E7;
    }
`

export const StyledSegmentsTableHeader = styled.header<any>`
  ${(props: any) => {
    return props.bodyWidth ? ('width: ' + props.bodyWidth + 'px; overflow: auto;') : 'overflow: autp;'
  }}
  direction: ltr;
  padding: 10px 10px 10px 10px;
  background: transparent linear-gradient(180deg, #212134 0%, #2C2C41 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000042;

  li {
    color: #C1C1C4;
  }
`

export const StyledSegmentsContent = styled.main<any>`
    ${(props: any) => {
      return props.bodyWidth ? ('width: ' + props.bodyWidth + 'px; overflow: auto;') : 'overflow: autp;'
    }}
    direction: rtl;
    max-height: 400px;
    padding: 10px 10px 10px 10px;
    overflow-x: auto;

    & > ul {
      direction: ltr;

      & > li:first-of-type {
        margin-top: 0;
      }
    }

    &::-webkit-scrollbar {
      width: 15px;              
    }

    &::-webkit-scrollbar-track {
      background: #424252;        
    }

    &::-webkit-scrollbar-thumb {
      background-color: #777782;    
      border: 1px solid #424252; 
      background-clip: padding-box;
    }

    li {
      color: #C1C1C4!important;
      height: 50px;
    }
`

const SortByHeader = ({ header, onSort, className, allowSort }: {
  header: string | React.ReactElement,
  onSort: (
    direction: 'ascending' | 'descending'
  ) => void,
  className?: string,
  allowSort?: boolean
}) => {
  const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending')

  return <li className={className} onClick={() => {
    const newDirection = ['ascending', 'descending']
      .find(d => d !== sortDirection) as 'ascending' | 'descending';

    setSortDirection(() => newDirection);

    onSort(newDirection);
  }}>
    <span>{header}</span>
    {allowSort && <span>
      {sortDirection === 'descending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </span>}
  </li>
}

export const SortByHeaderStyled = styled(SortByHeader)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & > span {
      display: flex;

      &:last-of-type {
        position: absolute;
        right: 0;
        cursor: pointer;
      }

      & > svg {
        font-size: 2rem;
      }
    }
`;

const SegmentsTable = ({ className, columns=[], rows=[], columnSize, onRowClick=()=>{}, bodyWidth }: ISegmentsTableProps) => {
  const [sortBy, setSortBy] = useState<{ key: any, direction: 'ascending' | 'descending' } | undefined>({ key: '', direction: 'ascending' });
  const [sortedRows, setSortedRows] = useState([]);
  const [paddingLeft, setPaddingLeft ] = useState('10px');

  const ref = useRef(null);

  useEffect(() => {
    if(rows.length !== sortedRows.length) {
      setSortedRows(rows);
    }
  }, [rows]);

  useEffect(() => {
    if (sortBy && sortBy.key) {;
      setSortedRows(rows.sort((a: any, b: any) => {
        return sortBy.direction === 'ascending' ? 
          a[sortBy.key] > b[sortBy.key] ? 1 : -1 :
          a[sortBy.key] < b[sortBy.key] ? 1 : -1
      }))
    }
  }, [sortBy]);

  useEffect(() => {
    if(ref && ref.current) {
      refHandler(ref.current)
    }
  }, [ref]);

  const refHandler = (e:any) => {
    const cellWidth = e.offsetHeight;
    const cellActualWidth = e.scrollHeight;
    // console.log('OffsetHeight', cellWidth);
    // console.log('ScrollHeight', cellActualWidth);

    if( cellActualWidth - cellWidth > 0) {
      setPaddingLeft('24px');
    }
  }

  return <section className={cn('Segments-table', className)}>
    <StyledSegmentsTableHeader bodyWidth={bodyWidth} style={{ paddingLeft: paddingLeft}}>
      <ul>
        <li>  
          <StyledSegmentsTableItemHeaderContainer columnSize={columnSize}>
            {
              columns.map((col, index) => {
                return (
                  <SortByHeaderStyled key={index} allowSort={col.key} header={col.header} onSort={(direction) => setSortBy({ key: col.key, direction })} />
                );
              })
            }
          </StyledSegmentsTableItemHeaderContainer>
        </li>
      </ul>
    </StyledSegmentsTableHeader>
    <StyledSegmentsContent bodyWidth={bodyWidth} ref={ref} >
      { rows?.length < 1 ? (
        <StyledSpinnerWrapper style={{height: '400px', alignItems: 'center'}}><CircularProgress size="4rem" /></StyledSpinnerWrapper>
      ) : (
        <ul>
          {
            rows.map((row: any, index: number) => <StyledSegmentsTableItem onRowClick={onRowClick} key={index} columns={columns} row={row} columnSize={columnSize} />)
          }
        </ul>
      )}
    </StyledSegmentsContent>
  </section>
};

export default styled(SegmentsTable)`
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: auto;
    direction: ltr;
    background-color: #212134;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    & > ul {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    &::-webkit-scrollbar {
      width: 15px;              
    }

    &::-webkit-scrollbar-track {
      background: #424252;        
    }

    &::-webkit-scrollbar-thumb {
      background-color: #777782;    
      border: 1px solid #424252; 
    }
`;
