import React from 'react';
import styled from 'styled-components';

export interface ILogoProps {
  className?: string;
}

const Logo = ({ className }: ILogoProps) => {
  return <div className={className}>
    <div >
      <img src="https://simpool.io/wp-content/uploads/2021/10/logo1.png" alt="logo" />
    </div>
    <h2>
      <b>Improve</b> Your revenues
    </h2>
  </div>
};

export default styled(Logo)`
    display: inline-flex;
    flex-direction: column;
    align-items: center;

  & > div {
    background: #FFFFFF;
    border-radius: 35px;
    display: inline-flex;
    padding: 5px 25px;
    opacity: 0.9;
    box-shadow: inset 2px 2px 4px #000000e3;
  }

  h2 {
    margin: 0;
    font-size: 1.2rem;
    text-align: center;

    color: #E7E7EB;

    b {
      color: #4A9CFB
    }
  }

  img {
    width: 100px;
    height: auto;
  }
`;
