import { ICohort, INode } from "../../pages/ScenarioBuilder/models/models";

import { IFilterBarPayload } from "../../components/FilterBar/FilterBar";
import { SerializedError } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export interface Data {
  bonuses: string[];
}

export interface IScenarioBuilderConfiguration {
  version: number;
  id: number;
  name: string;
  created: Date;
  data: Data;
  title_id: number;
  is_active: boolean;
}

export interface IDefaultCohort {
  users: number;
  all_users: number;
  all_ltv30: number;
  ltv30: number;
  all_apds_30: number;
  apds_30: number;
  all_arppu30: number;
  arppu30: number;
  all_conv30: number;
  conv30: number;
}

export interface IWhatIfConfiguration {
  [key: string]: { [key: string]: string | number }[];
}

export enum EStatus {
  CREATED = 1,
  STARTED = 2,
  FAILED = 3,
  COMPLETED = 4,
  PARTIAL = 5,
}

export interface IWhatif {
  id?: number;
  scenario_id?: number;
  configuration: IWhatIfConfiguration[];
  status?: EStatus;
  job_id?: string;
  changes?: { [key: string]: string | number }[];
  description?: string;
}

export interface ScenarioPaymentChange {
  coeff: number;
  price: number;
}
export interface IScenario {
  id?: number;
  job_id?: number;
  status?: EStatus;
  cohort_key?: IFilterBarPayload;
  params?: {
    ltv?: number;
    apds?: number;
    arppu30?: number;
    conv?: number;
    users?: number;
    payment_change?: ScenarioPaymentChange[]
  };
  title_id: number;
  name: string;
  whatifs: IWhatif[];
  workflow: INode[];
  end?: string;
}

export interface ISimulationInfo {
  title_id: number;
  scenario_id: number;
  whatif_id: number;
  simulation_job_id: string;
}

export interface ISimulationStatus {
  scenario_id: number;
  whatif_id: number;
  job_id: string;
  status: string;
}

export interface IWhatifStatus {
  id: number;
  job_id: string;
  status: string;
}

export interface IScenarioSimulationStatus {
  scenario_id: number;
  whatifs_status: IWhatifStatus[];
  status: string;
}

export interface IHighLevel {}

export interface ISimulationResult {
  run_id: string;
  scenario_id: number;
  whatif_id: number;
  cohort_key: string;
  number_of_users: number;
  total_ltv: number;
  arppu: number;
  arpu: number;
  apds: number;
  conversion_rate: number;
  updated: Date;
}

export interface IScenarioTabs {
  id: string;
  name: string;
  useCohort?: boolean;
}

export interface IScenarioBuilderState {
  configuration?: IScenarioBuilderConfiguration;
  defaultCohort?: ICohort[];
  scenarios?: IScenario[];
  simulationInfo?: { [key: string]: ISimulationInfo };
  simulationStatus?: ISimulationStatus;
  simulationResult: { [key: string]: ISimulationResult };
  scenarioSimulationStatus?: IScenarioSimulationStatus[];
  configurationObjects?: string[];
  configurationObjectsByPath?: {
    [key: string]: { [key: string]: string | number }[];
  };
  loading: {
    getConfiguration?: boolean;
    getConfigurationObjects?: boolean;
    getDefaultCohort?: boolean;
    createScenario?: boolean;
    runScenarioSimulation?: boolean;
    getSimulationStatus?: boolean;
    getSimulationResult?: boolean;
    getScenarioSimulationStatus?: boolean;
    getScenarios?: boolean;
    getConfigurationObjectsByPath?: boolean;
  };
  error: {
    getConfiguration?: SerializedError;
    getConfigurationObjects?: SerializedError;
    getDefaultCohort?: SerializedError;
    createScenario?: SerializedError;
    runScenarioSimulation?: SerializedError;
    getSimulationStatus?: SerializedError;
    getSimulationResult?: SerializedError;
    getScenarioSimulationStatus?: SerializedError;
    getScenarios?: SerializedError;
    getConfigurationObjectsByPath?: SerializedError;
  };
  filters?: IFilterBarPayload;
  scenarioTabs: IScenarioTabs[];
  scenarioTabCohorts: Record<string, ICohort>;
  scenarioTabFilters: Record<string, IFilterBarPayload>;
  scenarioTabNodes: Record<string, INode[]>;
  activeScenarioTabId?: string;
  scenarioAdded: boolean;
}

const initialScenarioId = uuidv4();

export const initialState: IScenarioBuilderState = {
  error: {},
  loading: {},
  scenarioTabs: [],
  scenarioTabCohorts: {},
  scenarioTabFilters: {},
  scenarioTabNodes: {},
  simulationResult: {},
  activeScenarioTabId: initialScenarioId,
  scenarioAdded: false,
};
