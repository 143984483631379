import DateRangePicker, { IPeriod } from '../../components/DateRangePicker/DateRangePicker';
import { IGetImproveCohortsReportDataResponse, useGetImproveCohortsReportDataQuery } from '../../queries/useGetImproveCohortsReportDataQuery';
import { IGetTopCohortsReportDataResponse, useGetTopCohortsReportDataQuery } from '../../queries/useGetTopCohortsReportDataQuery';
import React, { useEffect, useState } from 'react';

import CohortsTable from '../../components/CohortsTable/CohortsTable';
import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import { UseQueryResult } from 'react-query';
import styled from 'styled-components';
import { useApplicationContext } from '../../Context';

export interface ICohortsProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse
}

export const StyledCohortsContainer = styled.div`
  margin: 0 30px;
  overflow: hidden;
`

const Cohorts = ({ page, title }: ICohortsProps) => {
  const [improveCohortsReportQuery, setImproveCohortsReportQuery] = useState<UseQueryResult<IGetImproveCohortsReportDataResponse[], Error> | undefined>();
  const [topCohortsReportQuery, setTopCohortsReportQuery] = useState<UseQueryResult<IGetTopCohortsReportDataResponse[], Error> | undefined>();
  const [{ period: contextPeriod }, setContext ] = useApplicationContext();
  const [period, setPeriod] = useState<IPeriod | undefined>(contextPeriod);
  const getImproveCohortsReportDataQueryResponse = useGetImproveCohortsReportDataQuery({ period: period, titleId: title?.id });
  const getTopCohortsReportDataQueryResponse = useGetTopCohortsReportDataQuery({ period: period, titleId: title?.id });

  useEffect(() => {
    if (!getImproveCohortsReportDataQueryResponse || getImproveCohortsReportDataQueryResponse.isLoading) {
      return;
    }

    const { data, error } = getImproveCohortsReportDataQueryResponse;

    if (!data || error) {
      return;
    }

    if (JSON.stringify(improveCohortsReportQuery) === JSON.stringify(getImproveCohortsReportDataQueryResponse)) {
      return;
    }

    setImproveCohortsReportQuery(getImproveCohortsReportDataQueryResponse);
  }, [getImproveCohortsReportDataQueryResponse]);

  useEffect(() => {
    if (!getTopCohortsReportDataQueryResponse || getTopCohortsReportDataQueryResponse.isLoading) {
      return;
    }

    const { data, error } = getTopCohortsReportDataQueryResponse;

    if (!data || error) {
      return;
    }

    if (JSON.stringify(topCohortsReportQuery) === JSON.stringify(getTopCohortsReportDataQueryResponse)) {
      return;
    }

    setTopCohortsReportQuery(getTopCohortsReportDataQueryResponse);
  }, [getTopCohortsReportDataQueryResponse]);

  useEffect(() => {
    setContext(state => {
      return {
        ...state,
        period
      }
    })
  }, [period]);
  
  return <StyledCohortsContainer>
    <DateRangePicker initialState={contextPeriod} onChange={setPeriod}/>
    <CohortsTable
      improveCohortsReportQuery={improveCohortsReportQuery}
      topCohortsReportQuery={topCohortsReportQuery}
      titleId={title?.id!}
    ></CohortsTable>
  </StyledCohortsContainer>
};

export default Cohorts;
