import { CurrencyCode, currencyFormatterInterceptor } from '../../../helpers/formatters';
import { DataTable, IChartLegend } from '../models';
import { IGetReportDataResponse, useGetReportDataQuery } from '../../../queries/useGetReportDataQuery';
import React, { useEffect, useState } from 'react';

import Chart from 'react-google-charts';
import ChartContainer from '../ChartContainer/ChartContainer';
import { IFilterBarPayload } from '../../FilterBar/FilterBar';
import { IGetWidgetsResponse } from '../../../queries/useGetWidgetsQuery';
import { PieChartDataAdapter } from './PieChartDataAdapter';
import { SERIES_COLORS } from '../../../helpers/colors';
import { groupBy } from '../../../helpers/extensions';
import { handleHeadlineHelper } from '../../../pages/Analytics/Analytics';
import { settingsSelector } from '../../../store/settings/settings.selector';
import { sharedAccountIdSelector } from '../../../store/shared/shared.selector';
import { useSelector } from 'react-redux';

export interface IPieChartProps {
  widget: IGetWidgetsResponse,
  titleId?: number,
  filter?: IFilterBarPayload,
  cross_title?: boolean;
  whatIfId?: number;
  extraFilters?: {
    query?: string;
  }
}

const PieChart = ({ widget, titleId, filter, cross_title = false, whatIfId, extraFilters }: IPieChartProps) => {
  const [groupByFilter, setGroupByFilter] = useState<string[] | undefined>();
  const [legendFilters, setLegendFilters] = useState<IChartLegend[]>([]);
  const [reportData, setReportData] = useState<IGetReportDataResponse[]>();
  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>();
  const accountIdSelector = useSelector(sharedAccountIdSelector);
  const currencyCode = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_code}")?.['parameter_value'] as CurrencyCode;
  const currencySign = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_symbol}");
  
  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      report_id: widget?.report_id,
      dashboard_id: widget?.dashboard_id,
      title_id: widget?.title_id || titleId,
      days_back: filter?.daysBack || 60,
      date_from: (filter?.dateFrom && String(filter?.dateFrom)) || "-1",
      date_to: (filter?.dateTo && String(filter?.dateTo)) || "-1",
      country: filter?.country,
      platform: filter?.platform,
      source: filter?.source,
      group_by: groupByFilter,
      currency: currencyFilter,
      account_id: cross_title ? accountIdSelector : undefined,
      whatif_id: whatIfId,
      extra_filters: extraFilters
    }, `PieChart-
        ${currencyFilter && currencyFilter}-
        ${widget?.report_id}-
        ${widget?.title_id || titleId}-
        ${filter?.dateFrom}-${filter?.daysBack}-
        ${filter?.dateTo}-
        ${filter?.country}-
        ${filter?.platform}-
        ${filter?.source}-
        ${extraFilters?.toString()}-
        ${groupByFilter?.toString()}-
        ${cross_title && `account_id: ${accountIdSelector}`}-${whatIfId}`);

  useEffect(() => {
    if (!widget || !getReportDataQueryResponse || getReportDataQueryResponse.isLoading) {
      return;
    }

    const { data } = getReportDataQueryResponse;
    if (!data) {
      return;
    }
    if (JSON.stringify(reportData) === JSON.stringify(data)) {
      return;
    }

    setReportData(data);
  }, [getReportDataQueryResponse])



  const handleGroupBy = (item: string | undefined) => {
    item ? setGroupByFilter([item]) : setGroupByFilter(undefined);
  }

  const handleCurrency = (item: string) => {
    setCurrencyFilter(item);
  }

  const [rows, cols, legend] = new PieChartDataAdapter()
    .initialize(widget, currencyCode)
    .adapt(reportData!, legendFilters)
    .make();

  const options = {
    legend: 'none',
    backgroundColor: 'transparent',
    chartArea: { width: '80%', height: '70%' },
    is3D: true,
    format: currencyFormatterInterceptor(widget?.y_axis_format, currencySign?.["parameter_value"]),
    slices: legend.map(l => ({color: l.background})),
    tooltip: { isHtml: true }
  };

  return <ChartContainer
    loading={getReportDataQueryResponse?.isLoading}
    headline={handleHeadlineHelper(widget?.headline, groupByFilter, currencyFilter)}
    legend={legend}
    groupBy={widget?.group_by || []}
    onGroupByChange={handleGroupBy}
    onChange={(filter) => setLegendFilters(filter)}
    disableLegendFilter
    showCurrency={widget.use_currency}
    onCurrencyChange={handleCurrency}
    tooltip={widget.tooltip}
    exportData={reportData}
  >
    <Chart
      chartType="PieChart"
      rows={rows as any}
      columns={cols as any}
      options={options as any}
      height="300px"
      width="100%"
    />
  </ChartContainer>
};

export default PieChart;
