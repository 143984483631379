import { DropDownType, DropdownRequestsResponse, useGetDropdownValues } from '../../queries/useGetDropdownValues';
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { CircularProgress } from '@mui/material';
import { FilterType } from '../FilterBar/FilterBar';
import { MouseEvent } from 'react';
import { Search } from '../../pages/Settings/Settings';
import { SimItButton } from '../Buttons/Buttons';
import classNames from 'classnames';
import styled from 'styled-components'

type Checked = {
  [key: string]: boolean
}

const removeRecordFromTheObject = (object: Checked, key: keyof Checked) => {
  let result: Checked = {};
  Object.keys(object).forEach(k => {
    if( k !== key ) {
      result = { ...result, [k]: true }
    }
  })
  return result;
}

type MultiselectDropdownProps = {
  type: DropDownType;
  filerType: FilterType;
  onChange?: (values: string[], filterType: FilterType) => void;
  values?: {[key: string]: string}[];
  isLoading: boolean;
  setValues?: string[];
}

type FiltersDropDownContainerProps = {
    titleId?: number;
    filerType: FilterType;
    type: DropDownType;
    onChange?: (values: string[], filterType: FilterType) => void;
    setValues?: string[];
}

export const FiltersDropDownContainer = ({ type, titleId, filerType, onChange, setValues }: FiltersDropDownContainerProps) => {
  const [value, setValue] = useState<DropdownRequestsResponse[]>();  
  const getDropdownValuesQuery = useGetDropdownValues(type, titleId);
  const [isLoading, setIsLoading ] = useState(true);

  

  useEffect(() => {
    if(getDropdownValuesQuery && getDropdownValuesQuery?.isLoading ) {
      return;
    }
    setIsLoading(false);
    const { data } =  getDropdownValuesQuery;

    if(!data) {
      return;
    }

    if(JSON.stringify(value) === JSON.stringify(data?.result)) {
      return;
    }

    setValue(data?.result);

  }, [getDropdownValuesQuery])

  return <MultiselectDropdown type={type} filerType={filerType} onChange={onChange} values={value} isLoading={isLoading} setValues={setValues}/>
}


const MultiselectDropdown = ({type, filerType, onChange, values, isLoading, setValues} : MultiselectDropdownProps) => {
  const [ checked, setChecked ] = useState<Checked>({});
  const [ term, setTerm ] = useState('');

  const prepareCheckedInitialValues = (values: string[]) => {
    let objectResult = {};
    values.forEach((key) => {
      objectResult = {...objectResult, [key]: true }
    })

    return objectResult as Checked;
  }

  useEffect(() => {
    if(!setValues){
      return
    }

    const preparedValues = prepareCheckedInitialValues(setValues);
    if(JSON.stringify(checked) === JSON.stringify(preparedValues)){
      return;
    }
    setChecked(preparedValues)

  }, [setValues]);

  const checkAll = (e: any) => {
    if(values) {
      let objectResult = {};
      values.forEach((key) => {
        const value = key[Object.keys(key)[0] as keyof DropdownRequestsResponse];
        if(value && value.toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
          objectResult = {...objectResult, [value]: true }
        }
      })
      setChecked(objectResult);
    }
  }

  const uncheckAll = (e: any) => {
    setChecked({})
  }

  return (
    <StyledDropdownWrapper title={type} render={(setVisible: any) => {
        return <>
        <div className='search-wrapper'>
        <div>
          <Search onChange={(string) => setTerm(string)}/>
          <div className='check-clear-all-wrapper'>
              <span onClick={checkAll}>check all</span><span onClick={uncheckAll}>clear all</span>
          </div>
        </div>
        <SimItButton onClick={(e:any) => {
          e.stopPropagation();
          const array = Object.keys(checked).filter(option => checked[option]);
          onChange && onChange(array, filerType);
          setVisible && setVisible(false)
        }}>Apply</SimItButton>
      </div>
      <ul>
        { (values && !isLoading) && values?.filter((key) => {
          const value = key[Object.keys(key)[0] as keyof DropdownRequestsResponse];
          return value.toLocaleLowerCase().includes(term.toLocaleLowerCase());
        }).map((key, index) => {
            return <li key={index} onClick={(e) => {
              e.stopPropagation();
              const value = key[Object.keys(key)[0] as keyof DropdownRequestsResponse];
              setChecked((prevState) => {
                let result;
                if(value in prevState) {
                  result = removeRecordFromTheObject(prevState, value); 
                } else {
                  result = {...prevState, [value]: true};
                }
                return result;
              })
            }}>
              {key[Object.keys(key)[0] as keyof DropdownRequestsResponse] in checked ? <CheckBoxIcon className="checked"/> : <CheckBoxOutlineBlankIcon />}
              <div>{key[Object.keys(key)[0] as keyof DropdownRequestsResponse]}</div>
              </li>
        })}
        { isLoading && <StyledSpinnerWrapper><CircularProgress size="2rem" /></StyledSpinnerWrapper>}
      </ul>
        </>
    }} />
  )
}

export const StyledSpinnerWrapper = styled.div`
  height: 2.2rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
`
export default MultiselectDropdown;

type DropdownWrapperProps = {
  className?: string;
  open?: boolean; 
  title?: string;
  render?: (callback: Dispatch<SetStateAction<boolean>>) => ReactNode;
}

const DropdownWrapper: React.FC<DropdownWrapperProps> = ({ className, title = 'Countries', render} ) => {
  const [visible, setVisible ]=useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleOpen = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setVisible((prev) => !prev)
  }

  const handleClickOutside = (e: any) => {
    if(ref &&  ref.current && !ref.current.contains(e.target)){
      setVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  },[])

  return (
    <div className={classNames(className, { open: visible})} onClick={handleOpen}  ref={ref}>
      { `-- ${title} --`}
      <ArrowDropDownIcon className="dropdown-arrow"/>
      <div className={classNames('menu', { open: visible})} onClick={(e) =>e.stopPropagation()}>
        { render && render(setVisible)}
      </div>
    </div>
  )
}

const StyledDropdownWrapper = styled(DropdownWrapper)`
  .search-wrapper{
    margin: 10px 10px 0px 15px;
    display: flex;
    button {
      font: normal normal 600 1rem Segoe UI;
      height: 30px;
    }
    section {
      margin-right: 10px;
    }
    .check-clear-all-wrapper{
      display: flex;
      justify-content: space-around;
      span {
        &:hover {
          text-decoration: underline;
        }
        font-size: 1rem;
      }
    }
    form {
      margin: 0;
      input {
        width: auto;
      }
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #31334E 0% 0% no-repeat padding-box;
  box-shadow: inset 2px 2px 3px #000000D9;
  border-radius: 21px;
  font-size: 1.2rem;   
  color: #918F8F;
  &.open {
    color: #E2E2E2;
    background: #3D3E5A 0% 0% no-repeat padding-box;

  }
  padding: 5px 30px 5px 10px;  
  cursor: pointer;
  position: relative;
  justify-content: flex-start;
  .dropdown-arrow {
    position: absolute;
    right: 6px;
    font-size: 2rem;
  }
  .menu {
    position: absolute;
    min-width: 100%;
    width: max-content;
    // height: 200px;
    background: white;
    top: 37px;
    left: 0;
    z-index: 100;
    background: #3D3E5A 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 9px #000000D9;
    border: 1px solid #212239;
    border-radius: 21px;
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.1s linear,opacity 0.1s linear;
    box-sizing: border-box;
   
    &.open {
      visibility: visible;
      opacity: 1;
    }

    ul {
      box-sizing: border-box;
      max-height: 160px;
      margin-right: 8px;
      margin-left: 8px;

      padding: 0px 0px;
      height: 87%;
      overflow: auto;
      list-style: none;
      &::-webkit-scrollbar {
        width: 4px;              
      }
  
      &::-webkit-scrollbar-track {
        background: #424252;        
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: #777782;    
        border: 1px solid #424252; 
      }

      li { 
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 25px;
        padding-right: 6px;
        padding-left: 6px;
        > div {
          margin-left: 10px;
        }
        svg {
          color: rgba(255, 255, 255, 0.3);
          &.checked {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

`
