import { value } from '../../helpers/formatters';
import {
  GrowthSimulationResponse,
  IGrowthSimulatorScenario,
  IGrowthSimulatorWidget,
} from "../../pages/GrowthSimulatorV2/models/growthSimulator.models";

import { IGrowthSimulatorRetentionNumbersForm } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorRetentionNumbers/GrowthSimulatorRetentionNumbers";
import { SerializedError } from "@reduxjs/toolkit";
import { GrowthSimulatorDetailsSegmentTableType } from '../../pages/GrowthSimulatorV2/components/GrowthSimulatorDetailsSegmentTable/GrowthSimulatorDetailsSegmentTable';

export enum ESubscriptionStatus {
  Active = 'active',
  NotActive = 'not_active'
}

export enum EPlanType {
  Monthly = 'monthly',
  Annually = 'annually'
}

export interface ISubscription {
  id: number
  plan_name: string
  display_name: string
  description: string
  plan_type: EPlanType
  plan_link: string
  plan_data: PlanData
  status: string
  amount: number
  is_popular: any
  header: string
  pre_header: string
  sub_header?: string
  button_text: string;
  order: number;
}

export interface PlanData {
  features: Feature[]
}

export interface Feature {
  text: string
  isAdvantage: boolean
}

export interface IBillingState {
  subscriptions: ISubscription[],
  loading: {
    getSubscriptions: boolean;
  };
  error: {
    getSubscriptions: SerializedError | null;
  },
}

export const initialState: IBillingState = {
  subscriptions: [],
  loading: {
    getSubscriptions: false
  },
  error: {
    getSubscriptions: null
  },
};
