import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { StyledLandingPageWrapper } from '../Landing/styles';
import styled from 'styled-components';

export interface CompleteProps {
}

const StyledCompleteContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    background: #1A2840;
    width: 50%;
    margin: 15% auto;
    padding: 5px 0 15px 0;
`

export function Complete({ }: CompleteProps) {
  const location = useLocation();
  const [time, setTime] = useState(3);
  const history = useHistory();

  useEffect(() => {
    const queryParameters: { [key: string]: string } = location.search
      .split('?')
      .pop()
      ?.split("&")
      ?.map((p) => p.split("="))
      .reduce(
        (aggregate, [key, value]) => ({ ...aggregate, [key]: value }),
        {}
      )!;

    const simpoolId = queryParameters['simpool_id'];

    let interval = setInterval(() =>
      setTime((t) => {
        if ((t - 1) === 0) {
          history.push(`/growth-simulator-v2`);

          clearInterval(interval);

          return -1;
        }

        return t - 1
      })
      , 1 * 1000)
  }, []);


  return <StyledLandingPageWrapper style={{ height: '100%', maxHeight: 'none' }}>
    <StyledCompleteContainer>
      <h2>Email Verification Complete</h2>

      <p>Thank you. Your email has been verified</p>

      <p>Redirecting you to the application in {time}.</p>
    </StyledCompleteContainer>
  </StyledLandingPageWrapper>
}
