import { IGetReportDataResponse, useGetReportDataQuery } from "../../../queries/useGetReportDataQuery";
import { useEffect, useState } from "react";

import ChartContainer from "../ChartContainer/ChartContainer";
import { CurrencyCode } from "../../../helpers/formatters";
import Gauge from "../../Gauge/Gauge";
import { IFilterBarPayload } from "../../FilterBar/FilterBar";
import { IGetWidgetsResponse } from "../../../queries/useGetWidgetsQuery";
import { formatter } from "../StackedLineChart/StackedLineChart";
import { settingsSelector } from "../../../store/settings/settings.selector";
import { sharedAccountIdSelector } from "../../../store/shared/shared.selector";
import { useSelector } from "react-redux";

export interface IGaugeChartProps {
  widget: IGetWidgetsResponse,
  titleId?: number,
  filter?: IFilterBarPayload,
  cross_title?: boolean;
  whatIfId?: number;
  extraFilters?: {
    query?: string;
  }
}

const GaugeChart = ({ widget, titleId, filter, cross_title = false, whatIfId, extraFilters }: IGaugeChartProps) => {
  const accountIdSelector = useSelector(sharedAccountIdSelector);
  const [reportData, setReportData] = useState<IGetReportDataResponse[]>();

  const currencyCode = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_code}")?.['parameter_value'] as CurrencyCode;

  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      report_id: widget?.report_id,
      dashboard_id: widget?.dashboard_id,
      title_id: widget?.title_id || titleId,
      days_back: filter?.daysBack || 60,
      date_from: (filter?.dateFrom && String(filter?.dateFrom)) || "-1",
      date_to: (filter?.dateTo && String(filter?.dateTo)) || "-1",
      country: filter?.country,
      platform: filter?.platform,
      source: filter?.source,
      account_id: cross_title ? accountIdSelector : undefined,
      whatif_id: whatIfId,
      extra_filters: extraFilters
    }, `GaugeChart-
        ${widget?.report_id}-${widget?.title_id || titleId}-${filter?.dateFrom}-
        ${filter?.daysBack}-
        ${filter?.dateTo}-
        ${filter?.country}-
        ${filter?.platform}-
        ${filter?.source}-
        ${extraFilters?.toString()}-
        ${cross_title && `account_id: ${accountIdSelector}`}-${whatIfId}`);

  useEffect(() => {
    if (!widget || !getReportDataQueryResponse || getReportDataQueryResponse.isLoading) {
      return;
    }

    const { data, error } = getReportDataQueryResponse;

    if (!data || error) {
      return;
    }

    if (JSON.stringify(reportData) === JSON.stringify(data)) {
      return;
    }
    setReportData(data);
  }, [getReportDataQueryResponse, widget, reportData])

  
  const report = reportData?.[0];
  const reportValue = report && report.value / (report?.max_val)
  const formattedValue = report?.org_value && formatter(report.org_value, widget?.y_axis_format, currencyCode);
  return <ChartContainer 
    loading={getReportDataQueryResponse?.isLoading}
    headline={widget?.headline}
    groupBy={[]}
    tooltip={widget.tooltip}
    exportData={reportData}
    disableLegendFilter
  >
    {reportValue && <Gauge
      width={140}
      radius={140 / 2}
      size={15}
      value={Math.round(reportValue * 100)}
      container={{
        height: 120,
        width: 120
      }}
      arrowValue={report.value ? report.value.toFixed(2) : undefined}
      needleRadius={20}
      needleFontSize={0.9}
      headline={formattedValue}
      style={{ margin: '15px 0 15px 0' }}
    />}

  </ChartContainer>
};


export default GaugeChart;