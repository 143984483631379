import { DataTable, IChartDataAdapter, IChartLegend } from "../models";

import { IGetReportDataResponse } from "../../../queries/useGetReportDataQuery";
import { IGetWidgetsResponse } from "../../../queries/useGetWidgetsQuery";
import { SERIES_COLORS } from "../../../helpers/colors";
import { groupBy } from "../../../helpers/extensions";
import { CurrencyCode } from "../../../helpers/formatters";
import { customHTML } from "../StackedLineChart/StackedLineChart";

export class BarChartDataAdapter implements IChartDataAdapter {
  private _widget?: IGetWidgetsResponse;
  private _currencyCode?: CurrencyCode;

  public initialize(widget: IGetWidgetsResponse, currencyCode: CurrencyCode): IChartDataAdapter {
    this._widget = widget;
    this._currencyCode = currencyCode;
    return this;
  }

  public adapt(
    report: IGetReportDataResponse[],
    filter?: Partial<IChartLegend>[]
  ): DataTable {
    if (!report || !this._widget) {
      return new DataTable();
    }

    const series = this._widget?.series.toLowerCase();
    const yAxis = this._widget?.y_axis.toLowerCase();
    const xAxis = this._widget?.x_axis.toLowerCase();

    const dataTable = new DataTable();

    const seriesValues = report
      .map((r) => r[series as keyof typeof r])
      .filter((x, i, a) => a.indexOf(x) === i);

    const seriesToColor: { [key: string]: string } = seriesValues.reduce(
      (acc, serie, index) => ({
        ...acc,
        [serie?.toString()!]: SERIES_COLORS[index],
      }),
      {}
    );

    const xAxisValues = report
      .map((r) => r[xAxis as keyof typeof r])
      .filter((x, i, a) => a.indexOf(x) === i);

    const reportGroupedByXAxis = groupBy(
      report,
      (item) => item[xAxis as keyof typeof item] as string
    );

    const columns = Math.max(
      ...Object.keys(reportGroupedByXAxis).map(
        (x) => reportGroupedByXAxis[x].length
      )
    );

    dataTable.addColumn({ type: "string" });

    for (let i = 0; i < columns; ++i) {
      dataTable.addColumn({ type: "number" });
      dataTable.addColumn({ type: "string", role: "style" });
      dataTable.addColumn({ type: 'string', role: 'tooltip', p: {html: true}})
    }
    
    xAxisValues.forEach((section) => {
      const row = reportGroupedByXAxis[
        section as keyof typeof reportGroupedByXAxis
      ].map((v) => {
        if (filter?.find((f) => f.value == v[series as keyof typeof v])) {
          return [
            undefined, 
            undefined,
            undefined
          ];
        }
        
        const color = seriesToColor[v[series as keyof typeof v]?.toString()!];

        return [
          {
            v: v[yAxis as keyof typeof v],
            f: v[yAxis as keyof typeof v]?.toString(),
          },
          { v: color },
          {
            v: (this._widget && this._currencyCode)
              && customHTML(section?.toString(), v[series as keyof typeof v],  v[yAxis as keyof typeof v], this._widget?.y_axis_format, this._currencyCode)
          }
        ];
      });

      dataTable.addRow([{ v: section }, ...row.flat(1)]);
    });

    const legend: IChartLegend[] = seriesValues.map((value, index) => ({
      background: SERIES_COLORS[index],
      value: value?.toString(),
    })) as IChartLegend[];

    dataTable.setLegend(legend);

    return dataTable;
  }
}
