import { IPeriod } from "../components/DateRangePicker/DateRangePicker";
import { UseQueryResult } from "react-query";
import { useGetReportDataQuery } from "./useGetReportDataQuery";

export interface IGetTopCohortsReportDataResponse {
  simpool_score: number;
  cohort: string;
  users: number;
  ltv: number;
  avg_ltv: number;
  apds: number;
  avg_apds: number;
  arppu30: number;
  avg_arppu: number;
  conv: number;
  avg_conv: number;
}

export interface IGetTopCohortsReportDateQueryPayload {
  period?: IPeriod;
  titleId?: number;
}

export const useGetTopCohortsReportDataQuery = ({
  period,
  titleId
}: IGetTopCohortsReportDateQueryPayload): UseQueryResult<
  IGetTopCohortsReportDataResponse[],
  Error
> => {
  const reportId = 13604;
  const response = useGetReportDataQuery(
    {
      report_id: 13604,

      dashboard_id: 100,
      title_id: titleId,
      days_back: period?.daysBack,
      date_from: String(period?.dateFrom),
      date_to: String(period?.dateTo),
      report_location: 8,
      topic_id: 1
    },
    `GetTopCohortsReportDataQuery-${reportId}-${period?.daysBack}-${titleId}-${period?.dateFrom}-${period?.dateTo}-topic_id-1-report_location-8`
  );

  const data: IGetTopCohortsReportDataResponse[] | undefined =
    response?.data?.map(
      ({
        simpool_score,
        cohort,
        users,
        ltv,
        avg_ltv,
        apds,
        avg_apds,
        arppu30,
        avg_arppu,
        conv,
        avg_conv,
      }) => ({
        simpool_score,
        cohort,
        users,
        ltv,
        avg_ltv,
        apds,
        avg_apds,
        arppu30,
        avg_arppu,
        conv,
        avg_conv,
      })
    );

  return { ...response, data } as UseQueryResult<
    IGetTopCohortsReportDataResponse[],
    Error
  >;
};
