import GroupsIcon from '@mui/icons-material/Groups';
import React from 'react';
import UsersSolidIcon from '../Icons/UsersSolidIcon';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

export interface IUsersBadgeProps {
  className?: string;
  users: number;
}

const UsersBadge = ({ users, className }: IUsersBadgeProps) => {
  return <p className={className}>
    <UsersSolidIcon/>
    {users ? <span>{users}</span> : <CircularProgress size="2rem" />}
    
  </p>
};

export default styled(UsersBadge)`
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding: 0 5px;
      font-size: 1rem;
    }
  
    span, svg {
      color: #FFFCAE;
      opacity: 0.8;
      text-shadow: 2px 2px 4px #000000;
    }
`;
