import styled from "styled-components";
import React, { useRef, useEffect, useState } from 'react';
import MUITooltip from "../../../../components/MUITooltip/MUITooltip";
import cn from 'classnames';

type Props = {
  item: any;
  text?: string;
};

const DynamicTooltip = ({ item, text }: Props) => {
  const elementRef = useRef<React.MutableRefObject<HTMLSpanElement> | null>(null);
  const [ isHovered, setIsHovered ] = useState(false);
  const Element = React.cloneElement(item, {
    ref: elementRef,
    className: cn(
      item.props.className,
      isHovered ? 'cursor' : '')
  });

  useEffect(() => {
    if(elementRef && elementRef.current) {
      refHandler(elementRef.current);
      console.log(elementRef.current);
      
    }
  }, [elementRef])

  const refHandler = (e:any) => {
    const cellWidth = e.offsetWidth;
    const cellActualWidth = e.scrollWidth;
    if( cellActualWidth - cellWidth > 0) {
      setIsHovered(true);
    }
  };

  return (
    <StyledWrapper>
      <MUITooltip title={isHovered ? text : ''}>
        { Element }
      </MUITooltip>
    </StyledWrapper>
  );
};

export default DynamicTooltip;

const StyledWrapper = styled.div`
  display: grid;
  .cursor {
    cursor: pointer;
  }
`