import { AxiosInstance, AxiosResponse } from 'axios';

import { useQuery } from 'react-query';
import { useAxiosAuthorized } from '../hooks/useAxiosAuthorized';
import { DynamicCpi } from '../pages/GrowthSimulatorV2/components/GrowthSimulatorDynamicCpiTable/GrowthSimulatorDynamicCpiTable';

export interface IGetReportDataPayload {
  report_id?: number;
  dashboard_id: number;
  title_id?: number;
  days_back?: number;
  date_from?: string;
  date_to?: string;
  group_by?: string[];
  country?: string[];
  source?: string[];
  platform?: string[];
  currency?: string;
  account_id?: number;
  whatif_id?: number;
  simulation_id?: number;
  topic_id?: number;
  report_location?: number;
  extra_filters?: {
    cpi?: DynamicCpi[];
    arpdau?: number;
    installs?: number;
    conversion?: number;
    arppu?: number;
    days?: number;
    dau_goal?: number;
    budget?: number;
    total_marketing_cost?: number;
    total_installs?: number;
    organic_installs?: number;
    paid_installs?: number;
    daily_installs?: number;
    query?: string;
  };
}

export interface IGetReportDataResponse {
  users: number;
  top_ltv: number;
  avg_ltv: number;
  ltv_to_improve: number;
  max_potential: number;
  actual_rev: number;
  delta_to_potential: number;

  apds: number;
  arppu30: number;
  avg_apds: number;
  avg_arppu: number;
  avg_conv: number;
  cohort: string;
  conv: number;
  ltv: number;
  simpool_score: number;

  arppu: string;
  arpu: string;
  avg_playing_days: string;
  avg_tx: string;
  conversion: string;
  d1_retention: string;
  d6_retention: string;
  install_date: Date;
  installs: number;
  onetime_payers: string;
  paid_more_than_five: string;
  paid_more_than_once: string;
  paying_users: number;
  reached_level_5: string;
  revenues: string;
  tx_per_payer: number;

  install_month_name: Date;
  'month 0': string | number;
  'month 1': string | number;
  'month 2': string | number;
  'month 3': string | number;
  'month 4': string | number;
  'month 5': string | number;
  'month 6': string | number;
  'month 7': string | number;
  'month 8': string | number;
  'month 9': string | number;
  'month 10': string | number;
  'month 11': string | number;
  'month 12': string | number;
  'month 13': string | number;
  'month 14': string | number;
  'month 15': string | number;
  'month 16': string | number;
  'month 17': string | number;
  'month 18': string | number;
  type: string;
  grp: string;
  last_payment_dt: Date | string;

  results?: string;

  rev: number;
  platform: string;
  country: string;
  source: string;

  rnk: number;
  seg: string;
  usd_amount: string;

  bonus_type: string;
  collectors_ratio: number;
  level_seg: string;

  ratio: number;

  index: number;
  topic: string;
  yesterday: number;
  avg_last_week: number;
  avg_last_month: number;
  yesterday_last_week_diff: number;
  yesterday_last_week_ratio: string;
  yesterday_last_month_diff: number;
  yesterday_last_month_ratio: string;
  last_week_last_month_diff: number;
  last_week_last_month_ratio: string;

  max_val: number;
  min_val: number;
  value: number;
  org_value: number;
}

export const getReportData = async (
  axios: AxiosInstance,
  payload: IGetReportDataPayload,
  filter?: (payload: IGetReportDataPayload) => boolean
): Promise<IGetReportDataResponse[] | undefined> => {
  if (filter && !filter(payload)) {
    return undefined;
  }

  if (!payload.dashboard_id || !payload.title_id || !payload.date_from || !payload.date_to) {
    return undefined;
  }

  const { data } = await axios.post<IGetReportDataPayload, AxiosResponse<IGetReportDataResponse[]>>(
    `/dashboard/data`,
    payload
  );

  return data;
};

export const useGetReportDataQuery = (
  payload: IGetReportDataPayload,
  key: string,
  filter?: (payload: IGetReportDataPayload) => boolean
) => {
  const axios = useAxiosAuthorized();

  return useQuery<IGetReportDataResponse[] | undefined, Error>(key, () => getReportData(axios, payload, filter));
};
