import * as actions from "./shared.actions";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ICurrencyResponse } from "../../queries/useGetCurrenciesQuery";
import { IGetAccountTitlesResponse } from "../../queries/useGetAccountTitlesQuery";
import { Pagination } from "../../components/ScenarioPaginationFilter/ScenarioPaginationFilter";
import { EStatus } from "../scenarioBuilder/scenarioBuilder.state";

export interface ISharedSlice {
  title?: IGetAccountTitlesResponse;
  currency?: ICurrencyResponse,
  accountTitles?: IGetAccountTitlesResponse,
  statusFilters?: EStatus[];
  limitFilter: Pagination;
  whatIfDescription?: string;
}

export const initialState: ISharedSlice = {
  currency: {
    id: 1,
    title_id: 88,
    currency: [{ name: 'coins' }]
  },
  limitFilter: 25
};

export const sharedSlice = createSlice({
  name: "[shared]",
  initialState,
  reducers: {
    setTitle: (
      state: ISharedSlice,
      {
        payload: { title },
      }: PayloadAction<{ title?: IGetAccountTitlesResponse }>
    ) => {
        state.title = title;
    },
    changeTitle: (
      state: ISharedSlice,
      {
        payload: { title },
      }: PayloadAction<{ title?: IGetAccountTitlesResponse }>
    ) => {
        state.title = title;
    },
    setCurrency: (
      state: ISharedSlice,
      {
        payload: { currency },
      }: PayloadAction<{ currency?: ICurrencyResponse }>
    ) => {
        state.currency = currency;
    },
    setStatusFilter: (
      state: ISharedSlice, 
      {
        payload: { values }
      }: PayloadAction<{ values?: EStatus[] }>
    ) => {
      state.statusFilters = values
    },
    setLimitFilter: (
      state: ISharedSlice, 
      {
        payload: { value }
      }: PayloadAction<{ value: Pagination }>
    ) => {
      state.limitFilter = value
    },
    setWhatIfDescription: (
      state: ISharedSlice,
      {
        payload
      }: PayloadAction<string>
    ) => {
      state.whatIfDescription = payload;
    }
  },
  extraReducers: (e) => {
    e.addCase(
      actions.getCurrency.fulfilled,
      (state: ISharedSlice, { payload }) => {
        state.currency = payload;
    });
    e.addCase(
      actions.getAcoountTitles.fulfilled,
      (state: ISharedSlice, { payload }) => {
        state.accountTitles = payload;
      }
    );
  }
});

export default sharedSlice.reducer;
