import { StyledLandingButton, StyledLandingPageWrapper } from "./styles";

import YoutubeEmbed from "./VideoEmbed";
import { device } from "../../helpers/device";
import styled from "styled-components";

type Props = {
  buttonOnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonText: string;
}

const ThirdLanding = ({ buttonOnClick, buttonText }: Props) => {
  return (
    <StyledSecondWrapper id="third">
      <StyledInnerContainerWrapper>
        <StyledInnerContainer>
          <StyledHeader>
            See Simpool in action!
          </StyledHeader>
          <YoutubeEmbed embedId="zHQEJS05fpY" />
          <StyledButtonSection>
            <StyledThirdLandingButton
              variant="contained"
              size="large"
              onClick={buttonOnClick}
              style={{ marginTop: '30px' }}
            >{buttonText}
            </StyledThirdLandingButton>
          </StyledButtonSection>
        </StyledInnerContainer>
      </StyledInnerContainerWrapper>
    </StyledSecondWrapper>
  );
}

export default ThirdLanding;

const textColor = '#FFFFFF';
const StyledSecondWrapper = styled(StyledLandingPageWrapper)`
  display: flex;
  justify-content: center;
  padding: 30px 120px;
  box-sizing: border-box;
  height: fit-content;
  max-height: fit-content;

  @media ${device.mobileL} { 
    padding: 10px;
  }

`

const StyledInnerContainerWrapper = styled.div`
  display: grid;
  width: 1357px;
  box-sizing: border-box;

  @media ${device.mobileL} { 
    width: 100%;
  }

`
const StyledInnerContainer = styled.div`
  border-radius: 10px;
  width:100%;
  display: flex;
  padding: 35px 60px;
  box-sizing: border-box;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  @media ${device.mobileL} { 
    padding: 0;
    align-items: center;
    }


  .video-responsive {
    display: flex;
    justify-content: center;
    width: 1250px;
    height: 622px;

    @media ${device.mobileL} { 
      width: 350px !important;
      height: 400px !important;
    }

    @media only screen and (max-width: ${device.laptopSL})and (min-width: ${device.laptopS}){
        width: 1100px !important;
        height: 500px !important;
    }
    
    @media only screen and (max-width: ${device.laptopS})and (min-width: ${device.laptop}) {
      width: 944px !important;
      height: 467px !important;
    }

    @media only screen and (max-width: ${device.laptop}) and (min-width: ${device.mobileL}){
      width: 786px !important;
      height: 400px !important;
    }
  }
`
const StyledHeader = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  text-align: center;
  letter-spacing: -0.02em;
  color: white;
  font-style: normal;
  font-weight: 800;
  font-size: 44.3333px;
  line-height: 56px;
  margin: 0;
  margin-bottom: 20px;
  @media ${device.laptop} {
    font-size: 31.6667px;
    line-height: 40px;
  }
  @media ${device.laptopS} {
    font-size: 38px;
    line-height: 48px;
  }
`

const StyledSubHeader = styled.h3`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17.4949px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${textColor};
  @media ${device.laptop} {
    font-size: 12.4964px;
    line-height: 21px;  
  }
  @media ${device.laptopS} {
    font-size: 14.9957px;
    line-height: 25px; 
  }
`

const StyledButtonSection = styled.div`
  display: flex;
  justify-content: center;

    @media ${device.mobileL} { 
      margin-bottom: 100px;
    }

`

const StyledThirdLandingButton = styled(StyledLandingButton)`
  width: 516px;
  height: 81px;
  font-size: 28px;
  line-height: 29px;
  @media ${device.laptopS} {
    height: 60px !important;
    font-size: 21px !important;
    line-height: 22px !important;
    width: 380px !important;

  }
  @media ${device.laptop} {
    font-size: 17.5px !important;
    line-height: 18px !important;
    height: 50px !important;
    width: 325px !important;

  }
`