import { ICohort, INode } from "../../pages/ScenarioBuilder/models/models";
import {
  IScenarioBuilderConfiguration,
  IScenarioBuilderState,
  IScenarioSimulationStatus,
  IScenarioTabs,
  ISimulationInfo,
  ISimulationResult,
  ISimulationStatus,
} from "./scenarioBuilder.state";

import { IAppState } from "../app.state";
import { IFilterBarPayload } from "../../components/FilterBar/FilterBar";
import { createSelector } from "@reduxjs/toolkit";

const scenarioBuilderFeatureSelector = (
  state: IAppState
): IScenarioBuilderState => state.scenarioBuilder;

export const configurationSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (
    substate: IScenarioBuilderState
  ): IScenarioBuilderConfiguration | undefined => substate?.configuration
);

export const configurationObjectsSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): string[] | undefined =>
    substate?.configurationObjects
);

export const configurationObjectsByPathSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (
    substate: IScenarioBuilderState
  ): { [key: string]: { [key: string]: string | number }[] } | undefined =>
    substate.configurationObjectsByPath
);

export const defaultCohortSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): ICohort[] | undefined =>
    substate?.defaultCohort
);

export const scenariosSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState) => substate?.scenarios
);

export const simulationInfoSelectorByScenarioId = (scenarioId: number) => createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): ISimulationInfo | undefined =>
    substate?.simulationInfo?.[scenarioId.toString()]
);

export const simulationStatusSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): ISimulationStatus | undefined =>
    substate?.simulationStatus
);

export const scenarioSimulationStatusSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): IScenarioSimulationStatus[] | undefined =>
    substate?.scenarioSimulationStatus
);

export const simulationResultCacheSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): { [key: string]: ISimulationResult } => substate?.simulationResult
)

export const simulationResultSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): ISimulationResult[] =>
    Object.values(substate?.simulationResult)
);

export const simulationResultByJobIdSelector = (jobId: number) =>
  createSelector(
    scenarioBuilderFeatureSelector,
    (substate: IScenarioBuilderState): ISimulationResult | undefined => {
      return substate?.simulationResult[jobId]
    }
  );

export const filterSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): IFilterBarPayload | undefined =>
    substate?.filters
);

export const scenarioTabsSelector = createSelector(
  scenarioBuilderFeatureSelector,
  (substate: IScenarioBuilderState): IScenarioTabs[] => substate?.scenarioTabs
);

export const scenarioTabCohortSelector = (id: string | undefined) =>
  createSelector(
    scenarioBuilderFeatureSelector,
    (substate: IScenarioBuilderState): ICohort | undefined =>
      substate?.scenarioTabCohorts[id!]
  );

export const scenarioTabCohortFiltersSelector = (id: string | undefined) =>
  createSelector(
    scenarioBuilderFeatureSelector,
    (substate: IScenarioBuilderState): IFilterBarPayload | undefined =>
      substate?.scenarioTabFilters[id!]
  );

export const activeScenarioTabIdSelector = () =>
  createSelector(
    scenarioBuilderFeatureSelector,
    (substate: IScenarioBuilderState): string | undefined =>
      substate?.activeScenarioTabId
  );

export const scenarioAddedSelector = () =>
  createSelector(
    scenarioBuilderFeatureSelector,
    (substate: IScenarioBuilderState): boolean => substate?.scenarioAdded
  );

export const scenarioTabNodesSelector = () =>
  createSelector(
    scenarioBuilderFeatureSelector,
    (substate: IScenarioBuilderState): Record<string, INode[]> =>
      substate?.scenarioTabNodes
  );
