import cn from "classnames";
import { useState, useEffect } from "react";
import styled  from "styled-components";
import { FilterType } from "../FilterBar/FilterBar";

type GroupByType = {
  title?: string;
  className?: string;
  filterType?: FilterType
  callBack?: (options: string[], filterType: FilterType) => void;
}

type OptionType = {
  [key: string]: boolean;
}
const GroupBy = ({ className, title, callBack, filterType = FilterType.GroupBy }:GroupByType) => {
  const options = {
    "Install_Date": true,
    "Platform": false,
    "Country":false,
    "Source": false,
  }

  const [ checkedOptions, setCheckedOptions ] = useState<OptionType>(options);

  const handleClick = (item: string) => {
    setCheckedOptions((options) => {
      const newState = { ...options};
      newState[item] = !newState[item];
      return newState;
    })

  }

  useEffect(() => {
    const aray = Object.keys(checkedOptions).filter(option => checkedOptions[option])
    callBack && callBack(aray, filterType);
  }, [checkedOptions])

  return (
    <div className={className}>
      <span className="title">Group By:</span>
      <div className="group-by">
        { Object.keys(checkedOptions).map((item,index) => {
          return <StyledButton key={index} title={item} className={cn({ cheked: checkedOptions[item] })} onClick={(item) => handleClick(item)}/>
        })}
      </div>
    </div>
  )
}



type ButtonType = {
  title: string;
  className?: string;
  onClick: (item: string) => any 
}
const Button = ({title = "Button", className, onClick}: ButtonType) => {
  return (
    <div className={className} onClick={() => onClick(title)}>
      {title.split('_').map(string => string[0].toUpperCase() + string.substring(1)).join(' ')}
    </div>
  )
}

const StyledButton = styled(Button)`
  min-width: 108px;
  min-height: 34px;
  background: #4E4E5A 0% 0% no-repeat padding-box;
  font-size: 1.2rem;
  color: #E7E7E7;
  display: flex;
  justify-content:center;
  align-items: center;

  &.cheked, &:hover {
    background: #4A9CFB 0% 0% no-repeat padding-box;
    color: #000000;
    cursor: pointer;
  }
`

const StyledGroupBy = styled(GroupBy)`
width: max-content;
display: flex;
align-items: center;
.group-by {
  height: max-content;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 9px;
  box-sizing: border-box;
  // padding-left: 9px;
  // padding-right: 9px;
  padding: 9px;
  background: #212134 0% 0% no-repeat padding-box;
 
}
span {
  font-size: 1.2rem;
  color: #E7E7E7;
  margin-right: 9px;
}
`

export default StyledGroupBy;