import {
  GrowthSimulationResponse,
  GrowthSimulationSaaSResponse,
  IGrowthSimulatorScenario,
  IGrowthSimulatorWidget
} from "../../pages/GrowthSimulatorV2/models/growthSimulator.models";
import { ApplicationType, BusinessType, RevenueModelType } from './../../pages/GrowthSimulatorV2/components/GrowthSimulatorSteps/GrowthSimulatorSteps';
import {
  EGoalType,
  EPointOfViewType,
  Genre,
  IGrowthSimulatorState,
} from "./growthSimulator.state";

import { createSelector } from "@reduxjs/toolkit";
import { GrowthSimulatorDetailsSegmentTableType } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorDetailsSegmentTable/GrowthSimulatorDetailsSegmentTable";
import { IGrowthSimulatorRetentionNumbersForm } from "../../pages/GrowthSimulatorV2/components/GrowthSimulatorRetentionNumbers/GrowthSimulatorRetentionNumbers";
import { IAppState } from "../app.state";

const growthSimulatorFeatureSelector = (
  state: IAppState
): IGrowthSimulatorState => state.growthSimulator;

export const widgetsSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): IGrowthSimulatorWidget[] =>
    substate?.widgets
);

export const povSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): EPointOfViewType | null => substate?.pov
);

export const goalSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): EGoalType | null => substate?.goal
);

export const growthSimulationSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): GrowthSimulationResponse |  undefined => {
    return substate?.growthSimulationByScenarioId[substate?.activeTab!] as GrowthSimulationResponse;
  }
);

export const growthSimulationSaaSSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): GrowthSimulationSaaSResponse |  undefined => {
    return substate?.growthSimulationByScenarioId[substate?.activeTab!] as GrowthSimulationSaaSResponse;
  }
);

export const growthSimulationDetailsLoadingSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): Boolean | undefined => {
    return substate?.loading?.executeGrowthSimulation || substate?.loading?.executeGrowthSimulationSaaS;
  }
)


export const growthSimulationLoadingSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): Boolean | undefined => {
    return substate?.loading?.executeGrowthSimulation;
  })

export const growthSimulatorRetentionNumbersSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): IGrowthSimulatorRetentionNumbersForm[] =>
    substate?.growthSimulatorRetentionNumbers
);

export const growthSimulatorScenariosSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): IGrowthSimulatorScenario[] => {
    return substate?.growthSimulatorScenarios;
  }
);

export const growthSimulatorScenarioSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): IGrowthSimulatorScenario | null => {
    return substate?.scenario;
  }
);

export type WidgetTooltips = { [key: string]: string };
export const widgetsTooltipSelector = createSelector(
  growthSimulatorFeatureSelector,
  (substate: IGrowthSimulatorState): WidgetTooltips => {
    let result: WidgetTooltips = {};
    substate?.widgets?.forEach((widget) => {
      if (widget.headline && widget.tooltip) {
        result[widget.headline as keyof typeof result] = widget.tooltip;
      }
    });
    return result;
  }
);

export const tooltipSelectorById = (ecoTopic: number, reportLocation: number) =>
  createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): string | undefined =>
      substate?.widgets?.find(
        (w) =>
          w.eco_topic_id === ecoTopic && w.report_location === reportLocation
      )?.tooltip
  );

  export const ARPPUTableAPDS = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): number | undefined => substate?.arppuTable?.arppu
  );

  export const ARPPUTableValues = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): Record<string, GrowthSimulatorDetailsSegmentTableType[]> => substate.arppuTable.values
  );

  export const ARPPUTableWeightedAverages = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): Record<string, number> => substate.arppuTable.weightedAverages
  );

  export const growthSimulatorBusinessTypeSelector = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): BusinessType | null => substate.business
  );

  export const growthSimulatorApplicationTypeSelector = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): ApplicationType | null => substate.application
  );

  export const growthSimulatorRevenueModelTypeSelector = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): RevenueModelType | null => substate.revenue
  );

  export const growthSimulatorActiveTabSelector = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): string | null => substate.activeTab
  );

  export const growthSimulatorBulbState = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): boolean => substate.bulbState
  );

  export const growthSimulatorGenreSelector = createSelector(
    growthSimulatorFeatureSelector,
    (substate: IGrowthSimulatorState): Genre => substate.genre
  );