import "./GoogleLogin.css";

import { Typography } from "@mui/material";

const GoogleLogin = (props: any) => {
    return (
        <div className="google-btn" {...props}>
            <div className="google-icon-wrapper">
                <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
            </div>
            <div className="google-text-wrapper">

                <Typography
                    variant="h6"
                    color={'white'}
                    textAlign="center"
                    fontFamily={['DM Sans, sans-serif']}
                    fontWeight={400}
                >
                    Sign in with Google
                </Typography>
            </div>
        </div>
    );
};

export default GoogleLogin;
