import { AxiosInstance, AxiosResponse } from "axios";

import { useAxiosAuthorized } from "../hooks/useAxiosAuthorized";
import { useQuery } from "react-query";

export interface IGetPageResponse {
  id: number;
  name: string;
  dashboard_id?: any;
  parent_id?: any;
  order: number;
  path?: string;
  children?: IGetPageResponse[];
  cross_title?: boolean;
}

const getPages = async (axios: AxiosInstance): Promise<IGetPageResponse[]> => {
  const { data } = await axios.get<undefined, AxiosResponse<IGetPageResponse[]>>(`/dashboard/pages`);

  return data;
};

export const useGetPagesQuery = () => {
  const axios = useAxiosAuthorized();
  
  return useQuery<IGetPageResponse[] | undefined, Error>(`useGetPagesQuery`, () => getPages(axios));
};
