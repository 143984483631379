import styled from "styled-components";
import { ReactComponent as XIcon } from "../../assets/growth_simulator/x_circle.svg";
import { ReactComponent as VIcon } from "../../assets/growth_simulator/v_circle.svg";

type Props = {
  text: string;
  isAdvantage?: boolean
}
const AdvantageComponent = ({text, isAdvantage = false}: Props) => {
  return (
    <StyledAdvantageContainer>
      <StyledIconContainer>
      {isAdvantage ? <VIcon style={{ width: '14px', height: '14px'}} /> : <XIcon style={{ width: '14px', height: '14px'}}/>}
      </StyledIconContainer>
    <StyledAdvantageText>{text}</StyledAdvantageText>
    </StyledAdvantageContainer>
  );

};

export default AdvantageComponent;


const StyledCardSubHeader = styled.p`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
`

const StyledAdvantageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`
const StyledAdvantageText = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #FFFFFF;
  margin-left: 9px;
`
const StyledIconContainer = styled.div`
  
`