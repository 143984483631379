import React, { useEffect, useState } from 'react';

import Analytics from '../Analytics/Analytics';
import { CircularProgress } from '@mui/material';
import { IGetAccountTitlesResponse } from '../../services/accountTitles.service';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import { ISegment } from '../../store/segmentation/segmentation.state';
import { SegmentationService } from '../../services/segmentation.service';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

export interface ISegmentationResultProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
}

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`


const SegmentationResult = ({ page, title }: ISegmentationResultProps) => {
  const { search } = useLocation();
  const segmentId = Number(new URLSearchParams(search).get('segment_id'));
  const segmentName = String(new URLSearchParams(search).get('segment_name'));
  const titleId = Number(new URLSearchParams(search).get('title_id')) || undefined;
  const [segment, setSegment] = useState<ISegment>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSegment = async () => {
      if (segmentId && titleId) {
        setIsLoading(true);
        try {
          const segment = await SegmentationService.getSegment(titleId!, segmentId);
          setSegment(segment);
        } catch (e) {
          console.error(e)
        }

        setIsLoading(false);
      }
    }
    fetchSegment();
  }, [segmentId, titleId]);

  return isLoading ?
  <SpinnerContainer>
    <CircularProgress size="8rem" />
  </SpinnerContainer> 
  : (<Analytics page={page}
    titleId={titleId}
    extraFilters={{ query: segment?.query }}
    hideFilters
  />)
};

export default SegmentationResult;
