import {
  ISegment,
  ISegmentField,
  ISegmentationState,
} from "./segmentation.state";

import { IAppState } from "../app.state";
import { createSelector } from "@reduxjs/toolkit";

const segmentationFeatureSelector = (state: IAppState): ISegmentationState =>
  state.segmentation;

export const segmentsSelector = createSelector(
  segmentationFeatureSelector,
  (substate: ISegmentationState): ISegment[] | undefined => substate?.segments
);

export const getAllSegmentsLoadingSelector = createSelector(
  segmentationFeatureSelector,
  (substate: ISegmentationState): boolean | undefined =>
    substate?.loading?.getAllSegments
);

export const fieldsSelector = createSelector(
  segmentationFeatureSelector,
  (substate: ISegmentationState): ISegmentField[] | undefined =>
    substate?.fields
);

export const getAllSegmentsFieldsLoadingSelector = createSelector(
  segmentationFeatureSelector,
  (substate: ISegmentationState): boolean | undefined =>
    substate?.loading?.getAllSegmentsFields
);
