import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Helmet } from "react-helmet";
import { TourData } from "./types";
import { TourStep } from "./tour-step";
import { Z_INDEX_TARGET_ELEMENT } from "./constants";
import styled from "styled-components";

interface TourProps {
  tourData: TourData;
  onDismiss: () => void;
  onComplete: () => void;
  onStepChange: (step: number) => void;
  step?: number;
}

const StyledActionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5%;
`;

const Tour: React.FC<TourProps & RouteComponentProps> = ({
  tourData,
  onDismiss,
  onComplete,
  onStepChange,
  step = 0
}) => {
  const { steps } = tourData;

  const [currentStep, setCurrentStep] = useState<number>(step);

  useEffect(() => {
    if (steps.length) {
      if (!currentStep) {
        setCurrentStep(steps.findIndex(step => !step.disabled))
      }
    }
  }, [steps])

  useEffect(() => {
    onStepChange(currentStep);
  }, [currentStep])

  const moveToPreviousStep = useCallback(() => {
    const previousStep = currentStep! - 1;
    setCurrentStep(previousStep);
  }, [currentStep, steps]);

  const moveToNextStep = useCallback(() => {
    const nextStep = currentStep === undefined ? 0 : currentStep + 1;

    if (tourData.steps.length === nextStep) {
      onComplete();
    } else {
      if (tourData.steps[nextStep].disabled) {
        const nextNotDisabledStep = steps.slice(nextStep)
          .findIndex(step => !step.disabled);

        setCurrentStep(nextNotDisabledStep + nextStep);
      } else {
        setCurrentStep(nextStep);
      }
    }
  }, [currentStep, steps]);

  return (
    <>
      <Helmet>
        <style>
          {`
            .tour-step-highlighted-element {
              z-index: ${Z_INDEX_TARGET_ELEMENT} !important;
            }
          `}
        </style>
      </Helmet>
      {
        <TourStep
          hide={tourData.steps[currentStep!]?.hide || tourData.steps[currentStep!]?.disabled}
          closable={tourData.steps[currentStep!]?.hasCloseBtn}
          flat={tourData.steps[currentStep!]?.flat}
          onClose={onDismiss}
          title={tourData.steps[currentStep!]?.title}
          body={<div>{tourData.steps[currentStep!]?.description}</div>}
          htmlBody={tourData.steps[currentStep!]?.htmlDescription}
          arrow={tourData.steps[currentStep!]?.arrow}
          onUpdate={tourData.steps[currentStep!]?.handleUpdate}
          host={tourData.steps[currentStep]?.host}
          width={tourData.steps[currentStep]?.width}
          component={tourData.steps[currentStep]?.component}
          moveToNextStep={moveToNextStep}
          moveToPreviousStep={moveToPreviousStep}
          footer={
            <StyledActionContainer>
              <button
                style={{
                  display:
                    tourData.steps[currentStep!]?.hasPreviousBtn &&
                      currentStep! > 0
                      ? "initial"
                      : "none",
                }}
                className="tour-step-previous-btn"
                onClick={() => {
                  if (tourData.steps[currentStep!].handlePreviousStep) {
                    tourData.steps[currentStep!].handlePreviousStep!(
                      moveToPreviousStep
                    );
                  } else {
                    moveToPreviousStep();
                  }
                }}
              >
                PREVIOUS
              </button>
              <button
                style={{
                  display:
                    tourData.steps[currentStep!]?.hasNextBtn &&
                      currentStep! < tourData.steps.length - 1
                      ? "initial"
                      : "none",
                }}
                className="tour-step-next-btn"
                onClick={() => {
                  if (tourData.steps[currentStep!].handleNextStep) {
                    tourData.steps[currentStep!].handleNextStep!(
                      moveToNextStep
                    );
                  } else {
                    moveToNextStep();
                  }
                }}
              >
                NEXT
              </button>
              <button
                style={{
                  display:
                    tourData.steps[currentStep!]?.hasCompleteBtn &&
                      currentStep === tourData.steps.length - 1
                      ? "initial"
                      : "none",
                }}
                className="tour-step-done-btn"
                onClick={onComplete}
              >
                DONE
              </button>
            </StyledActionContainer>
          }
        />
      }
    </>
  );
};

export default withRouter(Tour);
