import "./styles.css";

import { ArrowDirection, IHostHandlers } from "../types";
import React, { ReactElement, useEffect, useRef } from "react";

import cn from 'classnames';
import { createPopper } from "@popperjs/core";

interface TourStepProps {
  arrow?: {
    direction: ArrowDirection;
    targetElementDataTourId?: string;
  };
  title?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  closable?: boolean;
  onClose?: () => void;
  onUpdate?: () => void;
  htmlBody?: string;
  host?: IHostHandlers;
  moveToNextStep: () => void;
  moveToPreviousStep: () => void;
  width?: string;
  component?: any;
  hide?: boolean;
  flat?: boolean;
}

const getDOMElementGeometricProps = (
  element: HTMLElement
): {
  top: number;
  left: number;
  width: number;
  height: number;
} => {
  const box = element?.getBoundingClientRect();

  return {
    top: box?.top + window.pageYOffset || 0,
    left: box?.left + window.pageXOffset || 0,
    width: element?.offsetWidth || 0,
    height: element?.offsetHeight || 0,
  };
};

const applyStylesToHtmlElement = (
  element: HTMLElement,
  style: { [key: string]: any }
) =>
  Object.keys(style).map(
    (key) => element && (element.style[key as any] = style[key])
  );

const TourStep: React.SFC<TourStepProps> = ({
  arrow,
  title,
  body,
  htmlBody,
  footer,
  closable,
  onUpdate,
  onClose,
  host,
  moveToNextStep,
  moveToPreviousStep,
  width,
  component: Component,
  hide,
  flat
}) => {
  const tourStepContentRef = useRef<HTMLDivElement>();
  const highlightElementStageRef = useRef<HTMLDivElement>();
  const previousTarget = useRef<{
    element: HTMLElement;
    savedStyles: {};
  }>();

  useEffect(() => {
    if (tourStepContentRef.current) {
      const targetElement: HTMLElement = arrow?.targetElementDataTourId
        ? (document.querySelector(
            `[data-tour-id="${arrow?.targetElementDataTourId}"]`
          ) as HTMLElement)
        : document.body;

      const target = getDOMElementGeometricProps(targetElement);

      const step = document?.querySelector(".tour-step-highlighted-element");

      if (Boolean(host?.onClick)) {
        targetElement?.addEventListener(
          "click",
          (event) =>
            host?.onClick({ moveToNextStep, moveToPreviousStep }, event),
          { once: true }
        );
      }

      if (onUpdate) {
        onUpdate();
      }

      const attribute = step?.getAttribute("data-tour-id");

      if (attribute !== arrow?.targetElementDataTourId) {
        document
          .querySelector(".tour-step-highlighted-element")
          ?.classList.remove("tour-step-highlighted-element");

        setTimeout(
          () => targetElement?.classList?.add("tour-step-highlighted-element"),
          300
        );
      }

      createPopper(
        targetElement,
        tourStepContentRef.current,
        arrow
          ? { placement: arrow.direction as any }
          : {
              placement: "auto",
              modifiers: [
                {
                  name: "computeStyles",
                  enabled: true,
                  fn({ state }) {
                    state.styles.popper = {
                      ...state.styles.popper,
                      position: "fixed",
                      left: `${
                        (window.innerWidth - state.rects.popper.width) / 2
                      }px`,
                      top: "50%",
                      transform: "translateY(-50%)",
                    };

                    return state;
                  },
                },
              ],
            }
      );

      if(highlightElementStageRef.current) {
        applyStylesToHtmlElement(highlightElementStageRef.current, {
          position: "absolute",
          background: "transparent",
          width: `${target.width}px`,
          height: `${target.height}px`,
          top: `${target.top}px`,
          left: `${target.left}px`,
        });
      }
    
      previousTarget.current = {
        element: targetElement,
        savedStyles: {
          "z-index": targetElement?.style["z-index" as any],
          position: targetElement?.style["position"],
        },
      };

      if (targetElement) {
        targetElement.style["position"] = "relative";
      }
    }

    return () => {
      if (previousTarget.current) {
        applyStylesToHtmlElement(
          previousTarget.current.element,
          previousTarget.current.savedStyles
        );
      }
    };
  }, [arrow, hide]);

  return hide ? null : (
    <div className={"tour-step"}>
      <div
        className={cn("tour-step__content", {
          'tour-step__content--flat': flat,
        })}
        ref={tourStepContentRef as any}
        style={{
          width: width ? width : "300px",
        }}
      >
        {flat ? null : <div className="tour-step__title__wrapper">
          {title && (
            <div className="tour-step__title__wrapper__content">{title}</div>
          )}
          {closable && (
            <button
              className="tour-step__title__wrapper__close-btn"
              onClick={onClose}
            >
              X
            </button>
          )}
        </div>}
        <div className="tour-step__body__wrapper">
          {Component && (
            <div className="tour-step__body__wrapper__content">
              <Component
                moveToNextStep={moveToNextStep}
                moveToPreviousStep={moveToPreviousStep}
              />
            </div>
          )}

          {htmlBody && (
            <div
              className="tour-step__body__wrapper__content"
              dangerouslySetInnerHTML={{ __html: htmlBody }}
            ></div>
          )}
          {body && (
            <div className="tour-step__body__wrapper__content">{body}</div>
          )}
        </div>
        <div className="tour-step__footer__wrapper">
          {footer && (
            <div className="tour-step__footer__wrapper__content">{footer}</div>
          )}
        </div>
      </div>
      <div className="tour-step__backdrop" />
      {arrow?.targetElementDataTourId && (
        <div ref={highlightElementStageRef as any} />
      )}
    </div>
  );
};

export default TourStep;
