import styled from 'styled-components'
// import Dropdown, { MenuItem}  from '../shared/Dropdown'
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu
} from '@szhsin/react-menu';
import {
  menuSelector,
  menuItemSelector, menuButtonSelector} from "@szhsin/react-menu/style-utils";

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useGetReportDataQuery } from '../../queries/useGetChartTreeQuery';
import { useEffect, useState } from 'react';
import {IChartTreeItem} from '../../queries/useGetChartTreeQuery';
import fi from 'date-fns/esm/locale/fi/index.js';

export interface IDropdown {
  className?: string;
  onChange?: (item: IChartTreeItem) => void;
  initialReportId?: number;
}

//13865
const DropdownComponent = ({ className, onChange  }: IDropdown) => {
  const querry = useGetReportDataQuery({
    dashboard_id: 200
  }, "Dropdown");

  const [value, setValue ] = useState<IChartTreeItem | null>(null);

  useEffect(() => {
    if(value && onChange) {
      onChange(value);
    }
  }, [value])

  
  return querry?.data ? (<StyledMenu offsetY={5} menuButton={<StyledMenuButon>{ value ? value.headline : querry?.data[Object.keys(querry?.data)[0]][0].headline} <ArrowDropDownIcon /></StyledMenuButon>} transition>
      {querry?.data && Object.keys(querry.data).map((key, index) => {
        const label = querry.data[key][0].eco_topic_name;
        
        return <StyledSubMenu key={index} offsetX={15} offsetY={-12} label={ label }>
                { querry.data[key].sort((a, b) => a.report_location - b.report_location).map((item, index) => {
                  if(item.headline === 'Active Users'&& !value) {
                    setValue(item);
                  }
                  return <MenuItem key={`sub ${index}`} onClick={() => setValue(item)} report-location={item.report_location}>{ item.headline}</MenuItem>
                })}
              </StyledSubMenu>
      })}
        </StyledMenu> ) : null;
}

export default DropdownComponent;

export const StyledMenu = styled(Menu)`
  ${menuSelector.name} {
    font-size: 1.2rem;
    background: #3D3E5A;
    border: 1px solid #212239;
    color: #E2E2E2;
    border-radius: 21px;
    box-shadow: inset 0px 0px 9px #000000D9;
    padding: 15px 9px;
    ${menuItemSelector.hover} {
      background: #5e5f76;
    }
  }
`
const StyledSubMenu = styled(SubMenu)`
  ${menuSelector.name} {
    font-size: 1.2rem;
    background: #3D3E5A;
    border: 1px solid #212239;
    color: #E2E2E2;
    border-radius: 21px;
    box-shadow: inset 0px 0px 9px #000000D9;
    padding: 11px 11px;
    
  }
`
export const StyledMenuButon = styled(MenuButton)`
  color: #918F8F;
  font-size: 1.2rem;
  background: #2B2D47 0% 0% no-repeat padding-box;
  box-shadow: inset 2px 2px 3px #000000D9;
  border-radius: 21px;
  cursor: pointer;
	outline: inherit;
  border:none;
  padding: 4px 10px 4px 15px;
  line-height: 2.28rem;
  display: flex;
  &.szh-menu-button--open {
    background: #3D3E5A 0% 0% no-repeat padding-box;
    color: #E2E2E2;
    svg {
    fill: #E2E2E2;
  }
  }
  svg {
    fill: #918F8F;
    font-size: 2rem;
    line-height: 2rem;
  }
`