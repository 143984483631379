import * as fromActions from '../../store/scenarioBuilder/scenarioBuilder.actions';
import * as fromSelectors from '../../store/scenarioBuilder/scenarioBuilder.selector';
import * as fromSharedSelectors from '../../store/shared/shared.selector';

import { EActionType, ISBWebSocketMessage, SBWebsocketService } from './SBWebsocketService';
import { EStatus, IScenario, ISimulationInfo, ISimulationResult } from '../../store/scenarioBuilder/scenarioBuilder.state';
import { ICohort, INode } from './models/models';
import { deleteScenarioTab, setConfigurationObjectsByPath } from '../../store/scenarioBuilder/scenarioBuilder.actions';
import { subscribe, unsubscribe } from './helpers/event';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../store/app.state';
import { IFilterBarPayload } from "../../components/FilterBar/FilterBar";
import { IGetAccountTitlesResponse } from '../../services/accountTitles.service';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import ScenarioBuilderWrapper from './components/ScenarioBuilderWrapper/ScenarioBuilderWrapper';
import { enqueueSnackbar } from '../../store/notifications/notifications.actions';
import { flattenTree } from './helpers/helpers';
import { scenarioTabsSelector } from '../../store/scenarioBuilder/scenarioBuilder.selector';
import { setSimulationInfo } from '../../store/scenarioBuilder/scenarioBuilder.actions';
import { useEffect } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import { setStatusFilter } from '../../store/shared/shared.actions';

export interface IScenarioBuilderProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse
}

const sbWebsocketService = new SBWebsocketService();

export const ScenarioBuilder = ({ page, title }: IScenarioBuilderProps) => {
  const configuration = useSelector(fromSelectors.configurationSelector);
  const scenarios = useSelector(fromSelectors.scenariosSelector);
  const scenarioStatuses = useSelector(fromSharedSelectors.sharedStatusFilterSelector);
  const scenarioLimit = useSelector(fromSharedSelectors.sharedLimitFilterSelector);
  const scenarioResultCache = useSelector(fromSelectors.simulationResultCacheSelector)
  const [jwtToken,] = useLocalStorage<string | undefined>('jwtToken', undefined);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!title)
      return;

    const { id } = title;

    sbWebsocketService.reset();
    sbWebsocketService.connect(jwtToken!, id);
    sbWebsocketService.initialize();

    sbWebsocketService.onOpen((event) => {
    })

    sbWebsocketService.on(EActionType.POLL_SCENARIOS_SUCCESS, ({ payload }: ISBWebSocketMessage<IScenario[]>) => {
      dispatch({
        type: "[scenarioBuilder]/getScenarios/fulfilled",
        payload
      })
    })

    sbWebsocketService.on(EActionType.GET_SCENARIOS_FAILED, ({ payload }: ISBWebSocketMessage<IScenario[]>) => {
      dispatch(enqueueSnackbar({
        message: "Failed to fetch scenarios",
        options: {
          variant: 'error',
        },
      }))
    })

    sbWebsocketService.on(EActionType.CREATE_SCENARIO_SUCCESS, ({ payload }: ISBWebSocketMessage<IScenario>) => {
      dispatch({
        type: '[scenarioBuilder]/createScenario/fulfilled',
        payload
      })

      dispatch(enqueueSnackbar({
        message: "Scenario creation succeeded",
        options: {
          variant: "success",
        },
      }))
    });

    sbWebsocketService.on(EActionType.CREATE_SCENARIO_FAILED, ({ payload }) => {
      dispatch(enqueueSnackbar({
        message: "Scenario creation failed",
        options: {
          variant: 'error',
        },
      }))
    });

    sbWebsocketService.on(EActionType.START_SIMULATION_SUCCESS, ({ payload }: ISBWebSocketMessage<ISimulationInfo[]>) => {
      const [simulationInfo] = payload;

      dispatch(setSimulationInfo({
        scenarioId: simulationInfo.scenario_id.toString(),
        simulationInfo
      }));

      dispatch(enqueueSnackbar({
        message: "Simulation started successfully",
        options: {
          variant: 'success',
        },
      }))
    })

    sbWebsocketService.on(EActionType.START_SIMULATION_FAILED, ({ payload }) => {
      dispatch(enqueueSnackbar({
        message: "Simulation failed to started",
        options: {
          variant: 'error',
        },
      }))
    })

    sbWebsocketService.on(EActionType.GET_JOB_RESULT_SUCCESS, ({ payload }: ISBWebSocketMessage<ISimulationResult[]>) => {
      dispatch({
        type: "[scenarioBuilder]/getSimulationResult/fulfilled",
        payload
      })
    })

    const handleWhatIfExpandedEvent = ({ detail: {
      id, status, job_id
    } }: {
      detail: {
        id: number;
        job_id: string;
        status: EStatus
      }
    }) => {
      if (status !== EStatus.COMPLETED) {
        return;
      }
      if (!job_id) {
        return;
      }
      if (scenarioResultCache[id!]) {
        return;
      }

      sbWebsocketService.send({
        action_type: EActionType.GET_JOB_RESULT,
        payload: {
          job_id: job_id, full: false
        }
      })
    }

    subscribe('whatif-expanded-event', handleWhatIfExpandedEvent);

    return () => {
      sbWebsocketService.close();

      unsubscribe('whatif-expanded-event', handleWhatIfExpandedEvent);
    }
  }, [title])

  useEffect(() => {

    if (title?.id) {
      dispatch(fromActions.getConfiguration(title?.id));
      dispatch(fromActions.getConfigurationObjects(title?.id));
      dispatch(setConfigurationObjectsByPath({}));
    }
  }, [title, scenarioStatuses, scenarioLimit]);

  const handleCreate = (nodes: INode[], name: string, cohort: ICohort, filter: IFilterBarPayload) => {
    const scenario = {
      title_id: title?.id!,
      name: name,
      cohort_key: {
        groupBy: filter.groupBy,
        country: filter.country,
        platform: filter.platform,
        source: filter.source,
        days_back: filter.days_back || filter.daysBack,
        date_from: filter.date_from || filter.dateFrom,
        date_to: filter.date_to || filter.dateTo,
        segment_filters: filter.segments,
      },
      params: {
        conv: cohort?.conv,
        apds: cohort?.apds,
        arppu30: cohort?.arppu30,
        ltv: cohort?.ltv,
        users: cohort?.users
      },
      whatifs: nodes.map(({ value, object, children, changes, description }) => {
        const child = flattenTree(children)?.map((child) => ({
          [child?.object!]: child?.value!
        }));
        const childChanges = flattenTree(children)?.map((child) => {
          return child.changes;
        }).flat(1);

        let currentChanges: { [key: string]: string | number | string[]}[] = [];
        if (changes && changes.length > 0) {
          currentChanges = [...changes]
        }
        if (childChanges && childChanges.length > 0) {
          childChanges.forEach((change) => {
            if (change) {
              currentChanges.push(change)
            }
          })
        }
        return {
          configuration: [
            {
              [object!]: value!
            },
            ...child
          ],
          changes: currentChanges,
          description
        }
      }),
      workflow: nodes
    }

    sbWebsocketService.send({
      action_type: EActionType.CREATE_SCENARIO,
      payload: scenario
    });
  }

  const handleSimulate = (selectedScenarios: { [key: string]: IScenario }) => {
    Object.keys(selectedScenarios).forEach(s => {
      const { id } = selectedScenarios[s];

      sbWebsocketService.send({
        action_type: EActionType.START_SIMULATION,
        payload: { title_id: title?.id!, scenario_id: id! }
      })
    });

    dispatch(enqueueSnackbar({
      message: "Simulation started",
      options: {
        variant: "success",
      }
    }));
  }

  const filterScenarios = (): IScenario[] | undefined => {
    return scenarios?.filter(scenario => scenarioStatuses?.includes(scenario.status!))
  }
  return title ?
    <ScenarioBuilderWrapper configuration={configuration!}
      onCreate={handleCreate}
      onSimulate={handleSimulate}
      // scenarios={(scenarioStatuses && scenarioStatuses.length < 1) ? scenarios : filterScenarios()}
      scenarios={scenarios}
      title={title}
    /> : null;
};

export default ScenarioBuilder;
