import { ICurrencyResponse } from './../../queries/useGetCurrenciesQuery';
import { createSelector } from "@reduxjs/toolkit";
import { IAppState } from "../app.state";
import { ISharedSlice } from "./shared.slice";
import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { Pagination } from '../../components/ScenarioPaginationFilter/ScenarioPaginationFilter';
import { EStatus } from '../scenarioBuilder/scenarioBuilder.state';

const sharedFeatureSelector = (state: IAppState): ISharedSlice =>
  state.shared;

export const sharedSelector = createSelector(
  sharedFeatureSelector,
  (substate: ISharedSlice): ICurrencyResponse | undefined => substate.currency
);
  
export const sharedTitleSelector = createSelector(
  sharedFeatureSelector,
  (substate: ISharedSlice): IGetAccountTitlesResponse | undefined => substate.title
);

export const sharedAccountIdSelector = createSelector(
  sharedFeatureSelector,
  (substate: ISharedSlice): number | undefined => substate.title?.account_id
);

export const sharedStatusFilterSelector = createSelector(
  sharedFeatureSelector,
  (substate: ISharedSlice): EStatus[] | undefined => substate?.statusFilters
);

export const sharedLimitFilterSelector = createSelector(
  sharedFeatureSelector,
  (substate: ISharedSlice): Pagination => substate?.limitFilter
);

export const sharedWhatIfDescriptionSelector = createSelector(
  sharedFeatureSelector,
  (substate: ISharedSlice): string | undefined => substate?.whatIfDescription
);
