import {
  IGetReportDataResponse,
  useGetReportDataQuery,
} from "./useGetReportDataQuery";

import { UseQueryResult } from "react-query";

export type GetRetentionReportDataResponse = Pick<
  IGetReportDataResponse,
  | "install_month_name"
  | "month 0"
  | "month 1"
  | "month 2"
  | "month 3"
  | "month 4"
  | "month 5"
  | "month 6"
  | "month 7"
  | "month 8"
  | "month 9"
  | "month 10"
  | "month 11"
  | "month 12"
  | "month 13"
  | "month 14"
  | "month 15"
  | "month 16"
  | "month 17"
  | "month 18"
  | "type"
>;

export const useGetRetentionReportDataQuery = ({
  titleId,
}: {
  titleId?: number;
}): UseQueryResult<GetRetentionReportDataResponse[], Error> => {
  const response = useGetReportDataQuery(
    {
      topic_id: 80,
      report_location: 110,
      report_id: 13842,
      dashboard_id: 8,
      title_id: titleId,
      days_back: 90,
      date_from: "-1",
      date_to: "-1",
    },
    "GetUserRetentionReportDataQuery"
  );

  const data: GetRetentionReportDataResponse[] | undefined =
    response?.data?.map((data) => ({
      ...data,
      install_month_name: new Date(data.install_month_name),
    }));

  return { ...response, data } as UseQueryResult<
    GetRetentionReportDataResponse[],
    Error
  >;
};
