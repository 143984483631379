import { IGetTopCohortsReportDataResponse } from '../../queries/useGetTopCohortsReportDataQuery';
import React from 'react';
import { SeeMoreButtonStyled } from '../Buttons/Buttons';
import TopCohortsCard from '../TopCohortsCard/TopCohortsCard';
import styled from 'styled-components';

export interface ITopCohortsProps {
  className?: string;
  data?: IGetTopCohortsReportDataResponse[]
}

const TopCohortsFooter = styled.footer`
  margin: 20px 15px;
`
export const TopCohorts = ({ className, data }: ITopCohortsProps) => {
  return <section className={className}>
    <header>
      <h2>
        <b>Top</b> Cohorts
      </h2>
    </header>
    <ul>
      {data?.slice(0, 3)?.map((d, index) => <li key={index}>
        <TopCohortsCard data={d}></TopCohortsCard>
      </li>)}
    </ul>
    <TopCohortsFooter>
      <SeeMoreButtonStyled />
    </TopCohortsFooter>
  </section>
};

export default styled(TopCohorts)`
    background: transparent linear-gradient(0deg, #2F3143 0%, #383A50 52%, #2F3143 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 3px 3px 6px #000000;
    border: 1px solid #D9D9DDB5;
    position: relative;

    & > ul {
      list-style-type: none;

      margin: 0;
      padding: 0;

      & > li {
        margin: 30px 20px;
        width: 100%;
        display: flex;
        justify-content: flex-start;

        &:last-of-type {
          margin-top: 30px;
          margin-bottom: 0;
        }
      }
    }

    & > header {
    position: absolute;
    left: 30%;
    top: -45px;

    background: #303146 0% 0% no-repeat padding-box;
    box-shadow: inset 2px 2px 4px #000000, 3px 3px 3px #00000029;
    border: 1px solid #9898A2;
    border-radius: 95px;

    padding: 5px 35px;

    h2 {
      color: #FFFEDF;
      margin: 0;
      font-size: 2rem;
      font-weight: 500;

      b {
        color: #58C31B;
      }
    }
  }
`;
