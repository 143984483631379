import { IOnboardingState, IUserOnboarding } from "./onboarding.state";
import { SerializedError, createSelector } from "@reduxjs/toolkit";

import { IAppState } from "../app.state";

const onboardingStateFeatureSelector = (
  state: IAppState
): IOnboardingState => state.onboarding;

export const userSelector = createSelector(
  onboardingStateFeatureSelector,
  (state: IOnboardingState): IUserOnboarding | null => state.user
);

export const userLoginErrorSelector = createSelector(
  onboardingStateFeatureSelector,
  (state: IOnboardingState): SerializedError | null => state.error.loginUser
);

export const userLoginLoadingSelector = createSelector(
  onboardingStateFeatureSelector,
  (state: IOnboardingState): boolean => state.loading.loginUser
);

export const userRegisteredLoadingSelector = createSelector(
  onboardingStateFeatureSelector,
  (state: IOnboardingState): boolean => state.loading.registerUser
);

export const userRegisteredErrorSelector = createSelector(
  onboardingStateFeatureSelector,
  (state: IOnboardingState): any => state.error.registerUser
);

