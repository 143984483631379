import { DropdownWrapper, FilterType, TablePanel, TablePanelWrapper } from "../FilterBar/FilterBar";
import PaginationFilter, { Pagination } from "../ScenarioPaginationFilter/ScenarioPaginationFilter";
import { setLimitFilter, setStatusFilter } from "../../store/shared/shared.actions";

import { DropDownType } from "../../queries/useGetDropdownValues";
import { EStatus } from "../../store/scenarioBuilder/scenarioBuilder.state";
import MultiselectDropdown from "../Dropdown/MultiselectDropdown";
import { useDispatch } from "react-redux";

export type Values ={
  Created: string;
  Started: string;
  Failed: string;
  Completed: string;
  Partial: string;
}
const ScenarioStatusFilter = () => {
  const dispatch = useDispatch();

  const values: {[key: string]: string}[] = [
    {
      Created: 'Created',
    },
    {
      Started: 'Started',
    },
    {
      Failed: 'Failed',
    },
    {
      Completed: 'Completed',
    },
    {
      Partial: 'Partial',
    }
  ];
  
  const handleChange = (values: string[]) => {
    // const result = values?.map((value)=> String(EStatus[value.toUpperCase() as keyof typeof EStatus]));
    const result1 = values?.map((value)=> EStatus[value.toUpperCase() as keyof typeof EStatus]);
    
    dispatch(setStatusFilter(result1))
  }
  
  return (
    <TablePanelWrapper>
      <section>
        <TablePanel>
          <DropdownWrapper countOfCells={2}>
            <MultiselectDropdown type={DropDownType.Status} onChange={handleChange} filerType={FilterType.Status} values={values} isLoading={false}/>
            <PaginationFilter defaultValue={25} onChange={(v) => dispatch(setLimitFilter(v as Pagination))}/>
          </DropdownWrapper>
        </TablePanel>
      </section>
    </TablePanelWrapper>
  );

}

export default ScenarioStatusFilter;