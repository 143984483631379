import { AllCohortsButtonStyled } from '../Buttons/Buttons';
import Gauge from '../Gauge/Gauge';
import { IGetImproveCohortsReportDataResponse } from '../../queries/useGetImproveCohortsReportDataQuery';
import { IGetReportDataResponse } from '../../queries/useGetReportDataQuery';
import ImproveCohortsCard from '../ImproveCohortsCard/ImproveCohortsCard';
import React from 'react';
import styled from 'styled-components';

export interface IImproveCohortsProps {
  className?: string;
  data?: IGetImproveCohortsReportDataResponse[]
}

  const StyledCohortListContainer = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    padding-bottom: 5%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    margin-top: 15px;
    margin-left: 15px;
    background: #2c2c41;

    &>li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `

  const StyledCohortCardNumber = styled.div`
    background: transparent linear-gradient(230deg, #4D4F67 0%, #10121E 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 3px 3px 6px #000000;
    display: inline-flex;
    padding: 15px 35px;
    border-radius: 50%;
    opacity: 0.69;
    margin-bottom: 10%;
    margin-top: 5%;

    h3 {
      text-align: left;
      font-size: 3rem;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-shadow: inset 0px 1px 8px #000000, 0px 0px 14px #000000;
      margin: 0;
    }
  `

  const ImproveCohortsFooter = styled.footer`
    display: flex;
    flex-direction: row-reverse;
    margin: 0 15px;
  `

const ImproveCohorts = ({ className, data }: IImproveCohortsProps) => {
  return <section className={className}>
    <header>
      <h2>
        <b>Improve</b> Cohorts!
      </h2>
    </header>
    <StyledCohortListContainer>
      {data?.slice(0, 3)?.map((d, index) => <li key={index}>
        <StyledCohortCardNumber>
          <h3>
            {index + 1}
          </h3>
        </StyledCohortCardNumber>
        <ImproveCohortsCard data={d}></ImproveCohortsCard>
      </li>)}
    </StyledCohortListContainer>
    <ImproveCohortsFooter>
      <AllCohortsButtonStyled />
    </ImproveCohortsFooter>
  </section>
};

export default styled(ImproveCohorts)`
  position: relative;
  background: transparent linear-gradient(90deg, #2F3143 0%, #383A50 52%, #2F3143 100%) 0% 0% no-repeat padding-box;

  box-shadow: inset 3px 3px 6px #000000;
  border: 1px solid #4A9CFB;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  & > header {
    position: absolute;
    left: 35%;
    top: -45px;

    background: #303146 0% 0% no-repeat padding-box;
    box-shadow: inset 2px 2px 4px #000000, 3px 3px 3px #00000029;
    border: 1px solid #4A9CFB;
    border-radius: 96px;

    padding: 5px 35px;

    h2 {
      color: #FFFEDF;
      margin: 0;
      font-size: 2rem;
      font-weight: 500;

      b {
        color: #4A9CFB;
      }
    }
  }
`;
