import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";

import { IBillingState } from "./billing/billing.state";
import { IGrowthSimulatorState } from "./growthSimulator/growthSimulator.state";
import { INotificationsSlice } from "./notifications/notifications.state";
import { IOnboardingState } from "./onboarding/onboarding.state";
import { IScenarioBuilderState } from "./scenarioBuilder/scenarioBuilder.state";
import { ISegmentationState } from "./segmentation/segmentation.state";
import { ISettingsSlice } from "./settings/settings.state";
import { ISharedSlice } from "./shared/shared.slice";
import { deleteScenarioTab } from "./scenarioBuilder/scenarioBuilder.actions";
import { enqueueSnackbar } from "./notifications/notifications.actions";
import { getPersistConfig } from 'redux-deep-persist';
import { getSettings } from "./settings/settings.actions";
import localStorage from "redux-persist/es/storage";
import { persistReducer } from "redux-persist";
import rootReducer from "./app.reducer";

export interface IAppState {
  settings: ISettingsSlice;
  notifications: INotificationsSlice;
  shared: ISharedSlice;
  scenarioBuilder: IScenarioBuilderState;
  segmentation: ISegmentationState;
  growthSimulator: IGrowthSimulatorState;
  form: any;
  billing: IBillingState;
  onboarding: IOnboardingState;
}

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    switch (action?.type) {
      case "[settings]/updateSettings/rejected":
      case "[settings]/updateSettings/fulfilled":

      case "[scenarioBuilder]/createScenario/fulfilled":
      case "[scenarioBuilder]/createScenario/rejected":

      case "[shared]/changeTitle":

      case "[growth-simulator]/saveGrowthSimulatorScenario/fulfilled":
      case "[growth-simulator]/saveGrowthSimulatorScenario/rejected":

      case "[growth-simulator]/registerUserBasic/fulfilled":
      case "[growth-simulator]/registerUserBasic/rejected":

      case "[onboarding]/changePassword/fulfilled":
      case "[onboarding]/changePassword/rejected":

      case "[onboarding]/forgotPassword/fulfilled":
      case "[onboarding]/forgotPassword/rejected":

      case "[onboarding]/resendVerifyEmail/fulfilled":
      case "[onboarding]/resendVerifyEmail/rejected":
        return true;
      default:
        return false;
    }
  },
  effect: async (action, listenerApi) => {
    const state: IAppState = listenerApi.getState() as IAppState;

    switch (action?.type) {
      case "[onboarding]/forgotPassword/fulfilled": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Successfully send email to reset password",
            options: {
              variant: "success",
            },
          })
        );
        break;
      }
      case "[onboarding]/forgotPassword/rejected": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Failed to send email to reset password",
            options: {
              variant: "error",
            },
          })
        );
        break;
      }


      case "[onboarding]/changePassword/fulfilled": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Successfully changed password",
            options: {
              variant: "success",
            },
          })
        );
        break;
      }
      case "[onboarding]/changePassword/rejected": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Failed to change password",
            options: {
              variant: "error",
            },
          })
        );
        break;
      }

      case "[onboarding]/resendVerifyEmail/fulfilled": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Successfully resend verification email",
            options: {
              variant: "success",
            },
          })
        );
        break;
      }
      case "[onboarding]/resendVerifyEmail/rejected": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Failed to resend verification email",
            options: {
              variant: "error",
            },
          })
        );
        break;
      }
      case "[growth-simulator]/registerUser/fulfilled": {
        listenerApi.dispatch({
          type: '[onboarding]/loginUser/fulfilled',
          payload: action.payload
        })

        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Successfully registered user",
            options: {
              variant: "success",
            },
          })
        );
        break;
      }
      case "[growth-simulator]/registerUser/rejected": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Failed to register user",
            options: {
              variant: "error",
            },
          })
        );
        break;
      }
      case "[growth-simulator]/saveGrowthSimulatorScenario/fulfilled": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Successfully saved scenario",
            options: {
              variant: "success",
            },
          })
        );
        break;
      }
      case "[growth-simulator]/saveGrowthSimulatorScenario/rejected": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Failed to save scenario",
            options: {
              variant: "error",
            },
          })
        );
        break;
      }
      case "[shared]/changeTitle": {
        const scenarioTabs = state.scenarioBuilder.scenarioTabs;
        scenarioTabs?.length > 0 &&
          scenarioTabs?.forEach((tab) => {
            listenerApi.dispatch(deleteScenarioTab(tab.id));
          });
        break;
      }
      case "[settings]/updateSettings/rejected": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: action?.error?.message,
            options: {
              variant: "error",
            },
          })
        );
        break;
      }
      case "[settings]/updateSettings/fulfilled": {
        listenerApi.dispatch(
          enqueueSnackbar({
            message: "Updated settings",
            options: {
              variant: "success",
            },
          })
        );

        listenerApi.dispatch(getSettings(state?.shared?.title?.id!));
        break;
      }
    }
  },
});

const persistConfig = getPersistConfig({
  key: "state",
  storage: localStorage,
  whitelist: [
    'shared',
    'onboarding.user',
  ],
  rootReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
