import { CurrencyCode, value as decimal, money, percent, symbolToCurrencyCode } from '../../helpers/formatters';
import { SeeItButtonStyled, SimItButtonStyled } from '../Buttons/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { IGetImproveCohortsReportDataResponse } from '../../queries/useGetImproveCohortsReportDataQuery';
import cn from 'classnames';
import { getSettings } from '../../store/settings/settings.actions';
import { settingsSelector } from '../../store/settings/settings.selector';
import styled from 'styled-components';
import { valueColor } from '../../helpers/colors';

export interface IBaseCohortsTableProps {
  className?: string;
  isLoading?: boolean;
  collection?: TableItem[];
  titleId?: number;
}


export enum ESortDirection {
  Ascending = 'ascending',
  Descending = 'descending'
}

export enum ETableType {
  Average = 'average',
  Name = 'name',
  Value = 'value'
}

export enum EFormatType {
  Currency = 'currency',
  Percentage = 'precentage',
  Decimal = 'decimal'
}

interface ITableRowCellValue {
  value: number | string;
  average: number;
  type: ETableType;
  format: EFormatType;
  name?: string;
}


type TableItem = {
  [key: string]: ITableRowCellValue
}

export const StyledTableHeader = styled.header`
  padding: 10px 30px 10px 65px;
  background: transparent linear-gradient(180deg, #212134 0%, #2C2C41 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000042;
`

export const StyledTableItemHeaderContainer = styled.ul<{ properties?: string[] }>`
    display: grid;
    grid-template-columns: ${({ properties }) => 'repeat(' + (Number(properties?.length)) + ',1fr)'};
    grid-column-gap: 10px;

    & > li {
      background: #404052;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.2rem;
    padding: 10px 0;
    color: #E7E7E7;
    }
`

const SortByHeader = ({ header, onSort, className }: {
  header: string,
  onSort: (
    direction: ESortDirection
  ) => void,
  className?: string
}) => {
  const [sortDirection, setSortDirection] = useState<ESortDirection>(ESortDirection.Ascending)

  return <li className={className} onClick={() => {
    const newDirection = [ESortDirection.Ascending, ESortDirection.Descending]
      .find(d => d !== sortDirection) as ESortDirection;

    setSortDirection(() => newDirection);

    onSort(newDirection);
  }}>
    <span>{String(header)?.split('_').join(' ')}</span>
    <span>
      {sortDirection === ESortDirection.Descending ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </span>
  </li>
}

export const SortByHeaderStyled = styled(SortByHeader)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    & > span {
      display: flex;
      text-align: center;

      &:first-of-type {
        padding-right: 10px;
      }

      &:last-of-type {
        position: absolute;
        right: 0;
        cursor: pointer;
      }

      & > svg {
        font-size: 2rem;
      }
    }
`;

export const StyledCohortsContent = styled.main`
    overflow: auto;
    direction: rtl;
    height: 85%;
    margin: 10px 0 10px 20px;
    padding: 0 30px;

    & > ul {
      direction: ltr;

      & > li:first-of-type {
        margin-top: 0;
      }
    }

    &::-webkit-scrollbar {
      width: 15px;              
    }

    &::-webkit-scrollbar-track {
      background: #424252;        
    }

    &::-webkit-scrollbar-thumb {
      background-color: #777782;    
      border: 1px solid #424252; 
    }
`

const TableRowCell = (props: { value: ITableRowCellValue, currencySymbol?: string }) => {
  if (!props?.value) {
    return null;
  }

  const { value: { value, average, format } } = props;
  const formatFn = (type: EFormatType) => (value: any) => {
    if (isNaN(value)) {
      return value;
    }

    const currencyCode = (
      symbolToCurrencyCode[
      props.currencySymbol as keyof typeof symbolToCurrencyCode
      ] || 'USD'
    ) as CurrencyCode;

    switch (type) {
      case EFormatType.Currency:
        return money(value, 'always', 2, 2, currencyCode);
      case EFormatType.Percentage:
        return percent(value);
      case EFormatType.Decimal:
        return decimal(value, 'auto')
      default:
        return value;
    }
  }

  if (Boolean(average)) {
    return <li className={`table-item-type-average`}>
      <span
        style={{ color: valueColor((average - Number(value))) }}
      >
        {formatFn(format)((average - Number(value)))}
      </span>
      <span
        style={{ color: valueColor(((average / Number(value) - 1) * 100)) }}
      >
        ({formatFn(EFormatType.Percentage)((average / Number(value) - 1) * 100)})
      </span>
    </li>
  } else {
    return <li className={`table-item-type-default`}>
      <span>{formatFn(format)(value)}</span>
    </li>
  }
}


const TableRow = ({ value, className, properties, currencySymbol }: { value: TableItem, properties: string[], className?: string, currencySymbol?: string }) => {
  return <li className={cn('cohorts-table-item', className)}>
    <ul>
      {properties?.filter(x => !['index'].includes(x)).map(p => <TableRowCell
        currencySymbol={currencySymbol}
        value={value[p as keyof typeof value]}
      ></TableRowCell>)}
      <li>
        <SimItButtonStyled />
        <SeeItButtonStyled />
      </li>
    </ul>
  </li>
};

const StyledTableItem = styled(TableRow)`
  margin: 10px 0;

  & > ul {
    display: grid;
    grid-template-columns: ${({ properties }) => 'repeat(' + (Number(properties?.length)) + ',1fr)'};
    grid-column-gap: 10px;
    
    & > li {
      background: #2C2C3E;
      text-align: center;
      display: flex;
      flex-direction: column;

      &:not(:first-of-type) {
        word-break: break-word;
      }

      &:last-of-type {
        word-break: initial;
        display: flex;
        flex-direction: row;
        gap: 15px;
      }

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 1rem;
      padding: 10px;
      color: #E7E7E7;

      &.table-item-type-average {
        b {
          border-right: 1px solid #A8A8A8;
          padding-right: 10px;
          margin-right: 5px;
         
        }
      }

      &:nth-of-type(1) {
        p {
          background: #30314680 0% 0% no-repeat padding-box;
          border: 1px solid #939393;
          border-radius: 50%;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;

          & > span {
            min-width: 1rem;
            max-width: 1rem;
            color: #25273D;
            font-weight: bold;
            font-weight: bold;
            border-radius: 50%;
            font-size: 1rem;
            padding: 0.2rem 0.4rem;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
`


const REQUIRED_PROPERTIES = ['symbol', 'topic'];

const deepCopy = (obj: any): any => JSON.parse(JSON.stringify(obj));

export const BaseCohortsTable = ({ className, isLoading, collection: _collection, titleId }: IBaseCohortsTableProps) => {
  const [sortBy, setSortBy] = useState<{ key: keyof IGetImproveCohortsReportDataResponse, direction: ESortDirection } | undefined>();
  const settings = useSelector(settingsSelector);
  const dispatch = useDispatch();

  const [collection, setCollection] = useState<TableItem[] | undefined>();

  useEffect(() => {
    if (!settings) {
      if(titleId) {
        dispatch(getSettings(Number(titleId)))
      }
    }
  }, [settings, titleId, dispatch])

  const currencySymbol = settings
    ?.find(s => s.parameter_name === '{currency_symbol}')
    ?.parameter_value;

  useEffect(() => {
    if (_collection) {
      const keys = Object.keys(_collection[_collection.length - 1]).filter(c => !REQUIRED_PROPERTIES.includes(c));

      let accumulator: any = {};
      for (const key of keys) {
        const average = keys.filter(k => k !== key && k.slice(0, key.length) === key);

        if (average.length) {
          accumulator[key] = undefined;

          for (const iterator of average) {
            accumulator[iterator] = {
              name: `${iterator.replace(`${key}_`, '')}_average`
            };

            accumulator[`${iterator}_ratio`] = {
              name: iterator,
              average: key
            };
          }
        } else {
          if (!accumulator.hasOwnProperty(key)) {
            accumulator[key] = undefined;
          }
        }
      }

      let collectionAggregate = [];
      for (const collectionItem of _collection) {
        const columns = Object.keys(accumulator);

        let item: any = {};
        for (const column of columns.filter(x => !['last_week_last_month', 'last_week'].includes(x))) {
          const hasAverage = collectionItem[accumulator[column]?.average];

          item[column] = {
            value: hasAverage ? collectionItem[accumulator[column]?.name] : collectionItem[column],
            average: hasAverage ? collectionItem[accumulator[column]?.average] : undefined,
            format: collectionItem['symbol'],
            __item: collectionItem,
            name: hasAverage ? undefined : accumulator[column]?.name
          }
        }

        collectionAggregate.push(item);
      }

      setCollection(collectionAggregate);

      setSortBy({ key: 'index', direction: ESortDirection.Descending } as any);
    }
  }, [_collection])

  useEffect(() => {
    if (sortBy && collection) {
      setCollection((state) => {
        const sortedCollection = deepCopy(state?.sort((a, b) => {
          if (sortBy.direction === ESortDirection.Descending) {
            if (typeof a[sortBy.key]?.value === 'string') {
              return String(a[sortBy.key]?.value).localeCompare(String(b[sortBy.key]?.value))
            } else {
              return Number(a[sortBy.key]?.value) - Number(b[sortBy.key]?.value);
            }
          } else {
            if (typeof a[sortBy.key]?.value === 'string') {
              return String(b[sortBy.key]?.value).localeCompare(String(a[sortBy.key]?.value));
            } else {
              return Number(b[sortBy.key]?.value) - Number(a[sortBy.key]?.value);
            }
          }
        }))

        return sortedCollection;
      })
    }
  }, [sortBy]);

  const properties = collection && Object.keys(collection?.[collection?.length - 1])
    ?.filter((f) => !REQUIRED_PROPERTIES.includes(f));

  return !isLoading ? <section className={cn('base-cohorts-table', className)}>
    <StyledTableHeader>
      <ul>
        <li>
          <StyledTableItemHeaderContainer properties={properties}>
            {properties?.filter(x => !['index'].includes(x))?.map(p => <SortByHeaderStyled header={collection?.[collection?.length - 1][p]?.name ?? p} onSort={(direction) => setSortBy({ key: p as any, direction })} />)}

            <li><span>Actions</span></li>
          </StyledTableItemHeaderContainer>
        </li>
      </ul>
    </StyledTableHeader>
    <StyledCohortsContent>
      <ul>
        {collection?.map(d => <StyledTableItem value={d} properties={properties!} currencySymbol={currencySymbol} />)}
      </ul>
    </StyledCohortsContent>
  </section> : null;
};

export default styled(BaseCohortsTable)`
    height: 90%;
    margin-top: 30px;

    background-color: #212134;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    & > ul {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
`;
