import { Field, WrappedFieldArrayProps } from 'redux-form';

import { ReduxFormNumericFormat } from '../../../../shared/ReduxFormNumericFormat/ReduxFormNumericFormat';
import cn from 'classnames';
import { device } from '../../../../helpers/device';
import styled from 'styled-components';

export interface IGrowthSimulatorRetentionNumbersForm {
  day: number;
  value: number;
};
interface Props {
  onValueChange: (value: IGrowthSimulatorRetentionNumbersForm[]) => void;
  className?: string;
}

const StyledRetentionNumbersContainer = styled.ul`
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: repeat(2, 60px);
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 20px;

    background: #1C2942 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 22px #00000078;

    & > li {
      display: flex;
      align-items: center;
      height: 100%;
    }

    & > li:nth-of-type(1),
    & > li:nth-of-type(2) {
      border-bottom: 1px solid #d9d9d9;
    }

    & > li:nth-of-type(1),
    & > li:nth-of-type(3) {
      border-right: 1px solid #d9d9d9;
    }
`

const StyledRetentionDaysListContainer = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 50%;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 2%;
    margin: 0 2%;
`

const StyledRetentionDaysListItemContainer = styled.li`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 80%;
    background: #303c53;
    border: 2px solid #ffc301;
    color: #fff;
    border-radius: 10px;
    text-align: center;
  }
`

const StyledRetentionInputListItemContainer = styled.li`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5vw;
    height: 1.5vw;
    width: 100%;
    height: 100%;

      input {
        width: 100%;
        height: 100%;
        background: #fff;
        border: 2px solid #58a4fd;
        color: #000;
        border-radius: 10px;
        text-align: center;
        font-size: 1em;
      }
    }
`

const StyledRetentionInputsListContainer = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 2%;
    margin: 0 2%;
    
    width: 100%;
`

const StyledRetentionInputLabel = styled.li`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledDayLabel = styled.span`
  font-size: 1em;
  color: #ffc301;
`

const StyledRetentionLabel = styled.span`
  font-size: 1em;
  color: #58a4fd;
`

const GrowthSimulatorRetentionNumbers = ({ fields, className }: WrappedFieldArrayProps<{ day: number, value: number }> & { className?: string }) => {
  return (
    <StyledRetentionNumbersContainer className={cn(className)}>
      <StyledRetentionInputLabel>
        <StyledDayLabel>Day</StyledDayLabel>
      </StyledRetentionInputLabel>
      <li>
        <StyledRetentionDaysListContainer>
          {fields.map((field, index, fields) => <StyledRetentionDaysListItemContainer key={fields.get(index).day}>
            <div>
              <span>{fields.get(index).day}</span>
            </div>
          </StyledRetentionDaysListItemContainer>)}
        </StyledRetentionDaysListContainer>
      </li>
      <StyledRetentionInputLabel>
        <StyledRetentionLabel>Retention</StyledRetentionLabel>
        <StyledRetentionLabel>(%)</StyledRetentionLabel>
      </StyledRetentionInputLabel>
      <li>
        <StyledRetentionInputsListContainer className='growth-simulator-retention-numbers'>
          {fields.map((field, index, fields) => <StyledRetentionInputListItemContainer key={`RetentionInputListItem-${index}`}>
            <div>
              <Field
                component={ReduxFormNumericFormat}
                data-tour-id={index === 0 ? "growth_simulator_retention_numbers" : null}
                suffix="%"
                thousandSeparator
                allowNegative={false}
                name={`${field}.value`}
              />
            </div>
          </StyledRetentionInputListItemContainer>)}
        </StyledRetentionInputsListContainer>
      </li>
    </StyledRetentionNumbersContainer>
  );
};

export default styled(GrowthSimulatorRetentionNumbers)`
    font-size: 14px;

    @media ${device.laptopL} { 
      font-size: 12px;
    }

    @media ${device.laptop} { 
      font-size: 8px;

      grid-template-columns: 15% 85%;
    }
`;
