import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

import { ComponentType } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";

export interface IPrivateRouteProps {
    path: string,
    exact?: boolean,
    component: ComponentType<{}>
}

const PrivateRoute = (props: IPrivateRouteProps) => {
    const [jwtToken, ] = useLocalStorage<string | undefined>('jwtToken', undefined);
    const { search, pathname } = useLocation();
    const history = useHistory();
    const redirect = new URLSearchParams(search).get('redirect')?.toString();

    if(!jwtToken) {
        if(!pathname.includes('login')) {
            history.push('/login')
        }
        return null;
    }

    if(redirect?.length) {
        return <Redirect to={redirect}></Redirect>
    }

    return <Route {...props} />
};

export default PrivateRoute;