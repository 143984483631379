export const scoreColor = (score: number): string => {
    const sections = [
        "#FF0000",
        "#FF2300",
        "#FF3C00",
        "#FF5300",
        "#FF6F00",
        "#FF8800",
        "#FFA000",
        "#FFBC00",
        "#FFCC00",
        "#FFE600",
        "#FFF700",
        "#F7FF00",
        "#E6FF00",
        "#D3FF00",
        "#BBFF00",
        "#AAFF00",
        "#88FF00",
        "#66FF00",
        "#09FF00",
      ]

      const ARC_DEGREES = 180;

      const percToDeg = (perc: number): number => {
        return (perc / 100) * ARC_DEGREES;
      };

      const valueToDegrees = percToDeg(score);
      const colorIndex = Math.floor(valueToDegrees / (ARC_DEGREES / (sections.length -1)));
      const color = sections[colorIndex];

      return color;
}

export const valueColor = (value: number): string => {
    if(value === 0) {
      return '#FFFFFF';
    }
      
    return value > 0 ? '#09FF00' : '#FF0000';
}

//INFO(PPavlov): http://there4.io/2012/05/02/google-chart-color-list/
export const SERIES_COLORS = [
  '#E67300',
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#0099C6',
  '#DD4477',
  '#66AA00',

  '#3B3EAC',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#3B3EAC',
]

