import assert from 'assert-ts';

export enum EReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}

export enum EActionType {
  CREATE_SCENARIO = "create_scenario",
  CREATE_SCENARIO_SUCCESS = "create_scenario_success",
  CREATE_SCENARIO_FAILED = "create_scenario_failed",
  START_SIMULATION = "start_simulation",
  START_SIMULATION_SUCCESS = "start_simulation_success",
  START_SIMULATION_FAILED = "start_simulation_failed",
  GET_JOB_STATUS = "get_job_status",
  GET_JOB_STATUS_SUCCESS = "get_job_status_success",
  GET_JOB_STATUS_FAILED = "get_job_status_failed",
  GET_SCENARIO_STATUS = "get_scenario_status",
  GET_SCENARIO_STATUS_SUCCESS = "get_scenario_status_success",
  GET_SCENARIO_STATUS_FAILED = "get_scenario_status_failed",
  GET_JOB_RESULT = "get_job_result",
  GET_JOB_RESULT_SUCCESS = "get_job_result_success",
  GET_JOB_RESULT_FAILED = "get_job_result_failed",
  GET_SCENARIOS = "get_scenarios",
  GET_SCENARIOS_SUCCESS = "get_scenarios_success",
  GET_SCENARIOS_FAILED = "get_scenarios_failed",
  GET_SCENARIO = "get_scenario",
  GET_SCENARIO_SUCCESS = "get_scenario_success",
  GET_SCENARIO_FAILED = "get_scenario_failed",
  GET_JOB_RESULTS_HIGH_LEVEL = "get_job_results_high_level",
  GET_JOB_RESULTS_HIGH_LEVEL_SUCCESS = "get_job_results_high_level_success",
  GET_JOB_RESULTS_HIGH_LEVEL_FAILED = "get_job_results_high_level_failed",
  POLL_SCENARIOS_SUCCESS = "poll_scenarios_success"
}

function _parseWSEventJSONDataToObject<TData>(json: string): TData {
  const event = JSON.parse(JSON.parse(json));

  return event;
}

export interface ISBWebSocketMessage<TPayload> {
  action_type: EActionType;
  error?: any;
  payload: TPayload
}

const INITIAL_VALUE: CallbackCacheType = {
  "create_scenario": [],
  "create_scenario_success": [],
  "create_scenario_failed": [],
  "start_simulation": [],
  "start_simulation_success": [],
  "start_simulation_failed": [],
  "get_job_status": [],
  "get_job_status_success": [],
  "get_job_status_failed": [],
  "get_scenario_status": [],
  "get_scenario_status_success": [],
  "get_scenario_status_failed": [],
  "get_job_result": [],
  "get_job_result_success": [],
  "get_job_result_failed": [],
  "get_scenarios": [],
  "get_scenarios_success": [],
  "get_scenarios_failed": [],
  "get_scenario": [],
  "get_scenario_success": [],
  "get_scenario_failed": [],
  "get_job_results_high_level": [],
  "get_job_results_high_level_success": [],
  "get_job_results_high_level_failed": [],
  "poll_scenarios_success": [],
}


type CallbackCacheType = {
  [key in EActionType]: ((message: ISBWebSocketMessage<{}>) => void)[];
};

export class SBWebsocketService {
  private _websocket!: WebSocket;
  private _messageToCallback: CallbackCacheType = JSON.parse(JSON.stringify(INITIAL_VALUE));

  public get status(): EReadyState {
    assert(this._websocket);

    return this._websocket.readyState;
  }

  public connect(jwtToken: string, titleId: number): void {
    assert(titleId);
    assert(jwtToken);

    const parameters = jwtToken && JSON.parse(jwtToken);

    const { REACT_APP_API_URL } = process.env;

    const url = `wss:${REACT_APP_API_URL?.replace('https:', '')}`;

    this._websocket = new WebSocket(`${url}/simit/ws/${titleId}?auth=${parameters['access_token']}`);
  }

  public send<TPayload>(message: ISBWebSocketMessage<TPayload>) {
    assert(this._websocket);
    assert(this.status === EReadyState.OPEN);

    this._websocket.send(JSON.stringify(message));
  }

  public onOpen(callback: (event: Event) => void): void {
    assert(this._websocket);

    this._websocket.onopen = function (event) {
      return callback(event);
    };
  }

  public initialize(): void {
    assert(this._websocket);

    this.onMessage<{}>((message) => {
      const { action_type } = message;

      this._messageToCallback[action_type].forEach(callback => callback(message));
    })
  }

  private onMessage<TPayload>(callback: (message: ISBWebSocketMessage<TPayload>) => void): void {
    assert(this._websocket);

    this._websocket.onmessage = function ({ data }) {
      const event = _parseWSEventJSONDataToObject<ISBWebSocketMessage<TPayload>>(data);

      return callback(event);
    };
  }

  public reset(): void {
    this._messageToCallback = JSON.parse(JSON.stringify(INITIAL_VALUE));
  }

  public on<TPayload>(type: EActionType, callback: (message: ISBWebSocketMessage<TPayload>) => void): void {
    this._messageToCallback[type].push(callback as (message: ISBWebSocketMessage<{}>) => void);
  }

  public close(): void {
    this._websocket.close();
  }
}
