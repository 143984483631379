import { StyledMenu, StyledMenuButon } from '../Dropdown/Dropdown';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  MenuItem
} from '@szhsin/react-menu';
import { setLimitFilter } from '../../store/shared/shared.actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type Pagination = 10000 | 50 | 25 | 5;

export interface IPaginationFilterProps {
  onChange: (value: number) => void;
  defaultValue: number;
}

const PaginationFilter = ({ onChange, defaultValue }: IPaginationFilterProps) => {
  const [chosenValue, setChosenValue] = useState<Pagination>(defaultValue as Pagination)
  const values: Pagination[] = [10000, 50, 25, 5] as Pagination[];

  const handleSelect = (pagination: Pagination) => () => {
    setChosenValue(pagination);
    onChange(Number(pagination));
  };

  return (
    <StyledMenu offsetY={5} menuButton={<StyledMenuButon> --Show {chosenValue}-- <ArrowDropDownIcon /></StyledMenuButon>} transition>
      {values.filter((item) => item !== chosenValue).sort((a, b) => a - b).map((key) => {
        return <MenuItem key={uuidv4()} onClick={handleSelect(key)}>Show {key}</MenuItem>
      })}
    </StyledMenu>
  );
}


export default PaginationFilter;