import React, { useState } from 'react';

import ChartLineDuotoneIcon from '../Icons/ChartLineDuotoneIcon';
import DiceIcon from '../Icons/DiceIcon';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link } from 'react-router-dom';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Tooltip from '../Tooltip/Tooltip';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import styled from 'styled-components';

export interface ISimItButtonProps {
}

export interface IAllCohortsButtonProps {
}

export interface ISeeMoreButtonProps {

}

export interface ISimpoolButtonProps {
  className?: string,
  children?: any,
  onClick?: any
}

const SimpoolButton = ({ className, onClick, children, ...props }: ISimpoolButtonProps & any) => {
  return <button onClick={onClick} className={className} {...props}>{children}</button>;
};

const SeeItButton = styled(SimpoolButton) <any>`
  height: 33px;
  background: transparent linear-gradient(180deg, #59C31D 0%, #8BDD5C 52%, #59C31D 100%) 0% 0% no-repeat padding-box;
  border: none;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #262742;
  border-radius: 24px;
  cursor: pointer;

  span {
    font: normal normal 600 1rem Segoe UI;
  }

  &:hover {
    background: rgba(139,221,92, 0.8);
  }

  ${(props: any) => props.styles ? props.styles : ''}
`;

export const SimItButton = styled(SimpoolButton) <any>`
    background: transparent linear-gradient(180deg, #4A9CFB 0%, #6CB0FF 52%, #4A9CFB 100%) 0% 0% no-repeat padding-box;
    border: none;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #262742;
    border-radius: 24px;
    cursor: pointer;
    position: relative;
    height: 33px;
    .html-tooltip {
      opacity: ${(props: any) => props.hovered ? 1 : 0};
      visibility: ${(props: any) => props.hovered ? 'visible' : 'hidden'};
    }

    span {
      font: normal normal 600 1rem Segoe UI;
    }

    ${(props: any) => props.styles ? props.styles : ''}

`;

const AllCohortsButton = styled(SimpoolButton)`
    border: 1px solid #707070;
    padding: 5px 15px;
    border-radius: 24px;
    background: transparent;
    color: #FFFFFF;
    opacity: 0.8;

    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font: normal normal 600 1rem Segoe UI;
      padding-left: 5px;
      text-decoration: none;
      color: inherit;
    }
`

const SimpoolSimpleButton = styled(SimpoolButton)`
    background: #2E3D58 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #000000B3;
    border-radius: 27px;
    cursor: pointer;

    color: #687386;

    letter-spacing: 0px;
    border: none;
    outline: none;
    padding: 5px 15px;
    font-size: 1.4rem;
`

const SeeItButtonStyled = (props: any) => <SeeItButton styles={props.styles} onClick={props.onClick}>
  <ChartLineDuotoneIcon style={{ width: '15px', marginRight: '5px' }} />
  <span>SeeIt</span>
</SeeItButton>

const SimItButtonStyled = ({ disabled, onClick, styles, ...other }: {
  onClick?: (event: any) => void,
  disabled?: boolean,
  styles?: any
}) => {
  const [_timeout, _setTimeout] = useState<NodeJS.Timeout | undefined>();
  const [hovered, setHovered] = useState<boolean>(false);

  const handleOnMouseEnter = () => {
    clearTimeout(_timeout as any);
    setHovered(true);
  };
  const handleOnMouseLeave = () => {
    _setTimeout(setTimeout(() => {
      setHovered(false);
    }, 1 * 300));
  };

  return <SimItButton
    hovered={hovered}
    onMouseEnter={handleOnMouseEnter}
    onMouseLeave={handleOnMouseLeave}

    styles={styles} onClick={(event: any) => !disabled && onClick && onClick(event)}
    {...other}
  >
    <DiceIcon style={{ width: '15px', marginRight: '5px' }} />
    <span>SimIt</span>
    {disabled && <Tooltip text="SimIt is disabled!" />}
  </SimItButton>
}

const AllCohortsButtonStyled = () => <AllCohortsButton>
  <GroupsIcon />
  <Link to="/cohorts?type=improve">All cohorts</Link>
</AllCohortsButton>

const SeeMoreButtonStyled = () => <AllCohortsButton>
  <GroupsIcon />
  <Link to="/cohorts?type=top">See more</Link>
</AllCohortsButton>

const SimpoolPrimaryButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    background: #69AFFF;
    border: 0;
    border-radius: 15px;
    font-weight: bold;
    padding: 5px 15px;
    cursor: pointer;

    &:hover {
      background: rgba(105, 175, 255, 0.8)
    }
`

export const SimpoolSecondaryButton = styled.button`
    /* height: 33px; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    background: #FFCC00;
    border: 0;
    border-radius: 15px;
    font-weight: bold;
    padding: 5px 15px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 204, 0, 0.8)
    }
`

const SimpoolWarnButton = styled.button`
  background: #BCBCBC;
  border: 0;
  border-radius: 15px;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 5px 15px;
  cursor: pointer;

  &:hover {
    background: rgba(188, 188, 188, 0.8)
  }
`

export {
  SimItButtonStyled,
  SeeItButtonStyled,
  AllCohortsButtonStyled,
  SeeMoreButtonStyled,
  SimpoolPrimaryButton,
  SimpoolWarnButton,
  SimpoolSimpleButton
}
