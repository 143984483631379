import { IPeriod } from './../components/DateRangePicker/DateRangePicker';
import { UseQueryResult } from "react-query";
import { useGetReportDataQuery } from "./useGetReportDataQuery";

export interface IGetTopBarReportDataResponse {
  users: number;
  top_ltv: number;
  avg_ltv: number;
  ltv_to_improve: number;
  max_potential: number;
  actual_rev: number;
  delta_to_potential: number;
}

export const useGetTopBarReportDataQuery = ({
  titleId,
  period,
}: {
  titleId?: number;
  period?: IPeriod
}): UseQueryResult<IGetTopBarReportDataResponse, Error> => {
  const response = useGetReportDataQuery(
    {
      report_id: 13603,
      dashboard_id: 100,
      title_id: titleId,
      days_back: period?.daysBack,
      date_from: period?.dateFrom ? String(period?.dateFrom) : '-1',
      date_to: period?.dateTo ? String(period?.dateTo) : '-1',
      topic_id: 1,
      report_location: 7
    },
    `GetTopBarReportDataQuery-${titleId}--${period?.daysBack}-${period?.dateFrom}-${period?.dateTo}-topic_id-1-report_location-7`
  );

  const data: IGetTopBarReportDataResponse = response?.data
    ?.map(
      ({
        users,
        top_ltv,
        avg_ltv,
        ltv_to_improve,
        max_potential,
        actual_rev,
        delta_to_potential,
      }) => ({
        users,
        top_ltv,
        avg_ltv,
        ltv_to_improve,
        max_potential,
        actual_rev,
        delta_to_potential,
      })
    )
    ?.pop()!;

  return { ...response, data } as UseQueryResult<
    IGetTopBarReportDataResponse,
    Error
  >;
};
