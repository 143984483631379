import { IOAuthUser } from "../Context";
import jwt_decode from "jwt-decode";

const {
  REACT_APP_OAUTH_CLIENT_ID,
  REACT_APP_OAUTH_REDIRECT_URL,
  REACT_APP_OAUTH_IDP_HOST,
} = process.env;

function parseURLParams(url: string): Record<string, string | number | boolean | object> {
  const params: Record<string, string | number | boolean | Record<string, string | number | boolean | object>> = {};

  const searchParams = new URLSearchParams(url);

  searchParams.forEach((value, key) => {
    // Attempt to cast the value to number
    const numberValue = Number(value);
    if (!isNaN(numberValue)) {
      params[key] = numberValue;
      return;
    }

    // Attempt to cast the value to boolean
    if (value.toLowerCase() === 'true') {
      params[key] = true;
    } else if (value.toLowerCase() === 'false') {
      params[key] = false;
    } else {
      // Check if the value is a valid JSON string
      try {
        const jsonValue = JSON.parse(value);
        params[key] = jsonValue;
      } catch {
        params[key] = value;
      }
    }
  });

  return params;
}

export class AuthService {
  public static ParseImplicitFlowResponse(search: string): Record<string, string | number | boolean | object>{
    return parseURLParams(decodeURI(search));
  }

  public static ParseJwtToken(token: string): IOAuthUser {
    return jwt_decode(token);
  }

  public static Logout() {
    const params = new URLSearchParams({
      client_id: REACT_APP_OAUTH_CLIENT_ID!,
    });

    const url = `${REACT_APP_OAUTH_IDP_HOST}/oauth2/logout?${params}`;
    localStorage.removeItem("jwtToken");
    window.location.replace(url);
  }
}
