import * as fromSelectors from '../../../../store/scenarioBuilder/scenarioBuilder.selector';

import { money, percent, value } from "../../../../helpers/formatters"

import { ICohort } from "../../models/models"
import { IFilterBarPayload } from "../../../../components/FilterBar/FilterBar"
import { ScenarioBuilderHeaderItem } from "../ScenarioBuilderHeaderItem/ScenarioBuilderHeaderItem"
import ScenarioItemWithTooltip from "../ScenarioItemWithTooltip"
import Score from "../Score"
import Tooltip from "../../../../components/Tooltip/Tooltip"
import UsersBadge from "../../../../components/UsersBadge/UsersBadge"
import { generateCohortKey } from "../ScenarioBuilderFooter"
import styled from "styled-components"
import { useSelector } from "react-redux"

const StyledUsersContainer = styled.div`
  background: transparent linear-gradient(107deg, #383A52 0%, #1C1D30 100%) 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000040;
  border-radius: 35px;
  opacity: 1;
  padding: 0px 15px;
  border: 0.5px solid #B4B48E;
  transform: scale(0.8);

  div {
    background: transparent linear-gradient(101deg, #30354E 0%, #414763 52%, #30354E 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 1px 1px 1px #000000e3, 3px 3px 6px #00000040;
    border-radius: 25px;

    p {
      padding: 0 10px 0 15px;
    }
  }
`;

const Header = ({ className, cohort, currentFilter }: { className?: string, cohort?: ICohort, currentFilter?: IFilterBarPayload }) => {
  const ltvVsAverage = (cohort?.ltv! - cohort?.avg_ltv!) 
  const apdsVsAverage = cohort?.apds! - cohort?.avg_apds!
  const arppu30VsAverage = cohort?.arppu30! - cohort?.avg_arppu! 
  const conversionVsAverage = cohort?.conv! - cohort?.avg_conv! 

  const cohortText = generateCohortKey(currentFilter);

  return <header className={className}>
    <section>
      <ul>
        <li>
         <ScenarioItemWithTooltip tooltip={cohortText}>
            <span>{(cohort?.cohort && cohort.cohort !== 'NA') ? cohort.cohort : cohortText}</span>
         </ScenarioItemWithTooltip>
          

          <StyledUsersContainer>
            <div>
              <UsersBadge users={Number(cohort?.users)!} />
            </div>
          </StyledUsersContainer>

          {(cohort?.simpool_score &&  cohort?.simpool_score) !== -1 ? <Score value={cohort?.simpool_score ? cohort?.simpool_score : 0}></Score> : null}
        </li>

        <li>
          <ScenarioBuilderHeaderItem label="LTV30" value={money(cohort?.ltv!)} average={money(ltvVsAverage)} hideAverage={false} noFormatAvrg={ltvVsAverage}/>
        </li>
        <li>
          <ScenarioBuilderHeaderItem label="APDS" value={value(cohort?.apds!)} average={value(apdsVsAverage)} hideAverage={false} noFormatAvrg={apdsVsAverage}/>
        </li>
        <li>
          <ScenarioBuilderHeaderItem label="ARPPU30" value={money(cohort?.arppu30!)} average={money(arppu30VsAverage)} hideAverage={false} noFormatAvrg={arppu30VsAverage}/>
        </li>
        <li>
          <ScenarioBuilderHeaderItem label="CONVERSION" value={percent(cohort?.conv!)} average={percent(conversionVsAverage)} hideAverage={false} noFormatAvrg={conversionVsAverage}/>
        </li>
      </ul>
    </section>
  </header>
}


export default styled(Header)`
   & > section {
    & > ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      background: #162239 0% 0% no-repeat padding-box;
      border: 1px solid #F5F5F569;
      box-shadow: 3px 0px 6px #000000;
      width: 100%;
      & > li {
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        border-right: 1px solid #F5F5F569;

        &:first-of-type {
          
          .tooltip-container{
            /* overflow: hidden; */
            width: 260px;
            background: none;
            color: #E2E2E2;
            .ref-wrapper {
              span {
                font: normal normal 600 1rem Segoe UI;
                letter-spacing: 0px;
                color: #E2E2E2;
                margin: 0;
                text-align: center;
                //
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
            .html-tooltip{
              .inner-html{
                white-space: normal;
              }
            }
          }
        }
      }
    }
  }
`