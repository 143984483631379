import { EGoalType, Genre } from "../../../../store/growthSimulator/growthSimulator.state";
import {
  GrowthSimulationResponse,
  GrowthSimulationSaaSResponse,
  IGrowthSimulatorWidget,
} from "../../models/growthSimulator.models";
import { chunk, clone } from "../../../../helpers/extensions";
import {
  getGrowthSimulatorWidgets,
  resetGrowthSimulation,
  setBulbState,
  setGenre,
  setGoalType,
  setPointOfViewType,
} from "../../../../store/growthSimulator/growthSimulator.actions";
import {
  goalSelector,
  growthSimulationDetailsLoadingSelector,
  growthSimulationSaaSSelector,
  growthSimulationSelector,
  growthSimulatorBulbState,
  growthSimulatorGenreSelector,
  povSelector,
  widgetsSelector,
} from "../../../../store/growthSimulator/growthSimulator.selector";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { EChartType } from "../../../../queries/useGetWidgetsQuery";
import GrowthSimulatorAvatarHeader from "../GrowthSimulatorAvatarHeader/GrowthSimulatorAvatarHeader";
import GrowthSimulatorDetailsGoalDescription from "../GrowthSimulatorDetailsGoalDescription/GrowthSimulatorDetailsGoalDescription";
import GrowthSimulatorForm from "../GrowthSimulatorForm/GrowthSimulatorForm";
import GrowthSimulatorHeader from "../GrowthSimulatorHeader/GrowthSimulatorHeader";
import GrowthSimulatorResultLabel from "../results/GrowthSimulatorResultLabel/GrowthSimulatorResultLabel";
import { GrowthSimulatorTour } from "../GrowthSimulatorTour/GrowthSimulatorTour";
import { GrowthSimulatorTourStepsProviderA } from "../GrowthSimulatorTour/GrowthSimulatorTourStepsProviderA";
import { GrowthSimulatorTourStepsProviderB } from "../GrowthSimulatorTour/GrowthSimulatorTourStepsProviderB";
import LineChart from "../../../../components/Charts/LineChart/LineChart";
import { LinearProgress } from "@mui/material";
import MUITooltip from "../../../../components/MUITooltip/MUITooltip";
import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import { device } from "../../../../helpers/device";
import styled from "styled-components";
import { useApplicationContext } from "../../../../Context";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { userSelector } from "../../../../store/onboarding/onboarding.selectors";

interface Props {
  onBack: () => void;
  className?: string;
}

const StyledGrowthSimulatorDetailsAsideContainer = styled.aside`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledReportIdDebug = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
`;

const StyledGrowthSimulatorKPIListItemLabel = styled.h2``;

const StyledGrowthSimulatorDetailsContainer = styled.section`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 35% 65%;
`;

const StyledGrowthSimulatorDetailsMainContainer = styled.div`
  margin: 20px;
  box-shadow: inset 0px 3px 6px #000000;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledGrowthSimulatorInstallsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  background: #2b354b;
  margin: 10px;
  padding: 10px 40px;
  border-radius: 10px;
  @media ${device.laptopL} {
    padding: 10px 10px;
  }
`;

const StyledGrowthSimulatorInstallList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyleGrowthSimulatorInstallItem = styled.li`
  background: #1a273f;

  display: grid;
  grid-template-columns: 65% 35%;
  color: #d9d9d9;
  border-radius: 10px;
  span {
    @media ${device.laptop} {
      font-size: 9px;
    }
    @media ${device.laptopS} {
      font-size: 11px;
    }
  }
`;

const StyleGrowthSimulatorInstallLabel = styled.span`
  padding: 10px 10px 10px 20px;
  font-size: 1.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  //tooltip
  position: relative;
  &:hover {
    cursor: pointer;
    .html-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const StyledGrowthSimulatorInstallCounterContainer = styled.div`
  background: #3b4257;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #585e71;
`;

const StyledGrowthSimulatorInstallCounterLabel = styled.span`
  padding: 10px;
  font-weight: bold;
  font-size: 1.1rem;
  color: #9c9fa8;
`;

const StyledGrowthSimulatorKPIContainer = styled.div`
  box-shadow: inset 3px 3px 16px #000000e0;
  border-radius: 120px;
  padding: 20px 0;
  margin: 20px 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #19253c;
  position: relative;
  @keyframes pulse {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
    50% {
      opacity: 0.7;
      transform: scale(1.1);
    }
    80% {
      opacity: 1;
      transform: scale(1.3);
    }

    100% {
      opacity: 0.5;
      transform: scale(1);
    }
  }
  .bulb-icon-wrapper {
    position: absolute;
    left: -42px;
    top: 0;
    width: fit-content;
    height: fit-content;
    transform: rotate(180deg);
    &:hover {
      cursor: pointer;
    }
    .bulb-icon {
      fill: #57f900 !important;
      font-size: 3rem;
      box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
      animation: pulse 2s ease 0s infinite;
    }
  }
  @media ${device.laptopL} {
    font-size: 12px;
  }

  @media ${device.laptop} {
    font-size: 10px;
  }
`;
const StyledGrowthSimulatorKPIList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  gap: 5%;
  width: 95%;
  height: 100%;
  align-items: center;
`;

const StyledGrowthSimulatorKPIListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background: linear-gradient(#4d4f67 0%, #10121e 100%);
  padding: 3%;
  border-radius: 50%;
  width: 15%;
  height: 70%;
  box-shadow: inset #000000 3px 3px 6px;

  div:not(.html-tooltip):not(.inner-html) {
    background: linear-gradient(#444666 0%, #272d44 100%);
    box-shadow: inset #000000 3px 3px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    border-radius: 50%;
    width: 80%;
    height: 80%;

    h2 {
      color: #fff;
    }

    p {
      color: #ffc301;
      position: relative;
      &:hover {
        cursor: pointer;
        .html-tooltip {
          visibility: visible;
          opacity: 1;
          width: 200%;
        }
      }
    }
  }
`;

const StyledGrowthSimulatorChartContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  margin: 20px;
  grid-column-gap: 40px;
  max-height: 500px;
  .growth-simulator-chart {
    display: flex;
    flex-direction: column;
    .MuiSvgIcon-root {
      fill: #e2e2e2;
    }
    span {
      color: white;
      fill: white;
    }
  }
`;

const StyledGrowthSimulatorChart = styled.div`
  box-shadow: inset 5px 5px 8px #000000ba;
  background: #19253c;
  border-radius: 15px;
`;

const StyledAvatarContainer = styled.div`
  border: 1px solid #707070;
  border-radius: 63px;
  overflow: hidden;
  padding: 0px 10px;
  background: #1c2942;
`;

const StyledLinearProgress = styled(LinearProgress)`
  z-index: 1000 !important;
  margin: 0 15px;
  height: 10px !important;
  top: 5px;
  border-radius: 5px;
`;

const StyledGrowthSimulatorDetailsCallToActionContainer = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const StyledGrowthSimulatorDetailsGoalDescriptionContainer = styled.div`
  p {
    font-size: 1.2em;
    margin: 0;
    color: #fff;
    text-align: center;
  }
  span {
    font-weight: bold;
    color: hsla(212, 98%, 68%, 1);
  }
`;

const StyledGrowthSimulatorBlurOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: pointer;
`;

const GrowthSimulatorDetails = ({ onBack, className }: Props) => {
  const history = useHistory();
  const pov = useSelector(povSelector);
  const widgets = useSelector(widgetsSelector);

  const growthSimulation = useSelector(growthSimulationSelector);
  const growthSimulationSaaS = useSelector(growthSimulationSaaSSelector);
  const userBasic = useSelector(userSelector);

  const goal = useSelector(goalSelector);
  const genre = useSelector(growthSimulatorGenreSelector);

  const dispatch = useDispatch();
  const loading = useSelector(growthSimulationDetailsLoadingSelector);

  const [context, setContext] = useApplicationContext();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const shareId = searchParams.get('goal') as EGoalType;
  const genreToSet = searchParams.get('genre') as Genre;
  
  useEffect(() => {
    if (genreToSet) {
      dispatch(setGenre(genreToSet))
    }
  }, [genreToSet, dispatch]);
  useEffect(() => {
    if (genre) {
      dispatch(getGrowthSimulatorWidgets(genre === Genre.SaaS ? 4000 : 2000))
    }
  }, [genre, dispatch]);

  useEffect(() => {
    if (userBasic) {
      if (window?.clarity) {
        window.clarity("identify", userBasic?.id?.toString());
      }
    }
  }, [userBasic]);

  const chartFactory = (
    widget: IGrowthSimulatorWidget,
    simulation: GrowthSimulationResponse,
    simulationSaaS: GrowthSimulationSaaSResponse
  ) => {
    const simulationId =
      genre === Genre.SaaS
        ? simulationSaaS?.simulation_id
        : simulation?.simulation_id;

    if (!simulationId) {
      return null;
    }

    switch (widget?.chart_type) {
      case EChartType.Line:
        return (
          <LineChart
            widget={widget as any}
            titleId={88}
            whatIfId={simulationId}
            simulation_id={simulationId}
            hasReportLocation
            className="growth-simulator-chart"
          />
        );
      default:
        return null;
    }
  };

  const handleHomeIconClick = () => {
    history.push("/landing");
  };

  const isBulbVisible = useSelector(growthSimulatorBulbState);
  const [isBulbTooltipVisible, setIsBulbTooltipVisible] =
    useState<boolean>(true);

  let timer: any = null;
  const bulbSetterHandler = (setter: boolean) => {
    dispatch(setBulbState(setter));
    setIsBulbTooltipVisible(setter);
    timer = setTimeout(() => {
      setIsBulbTooltipVisible(false);
      clearTimeout(timer);
    }, 5000);
  };

  const handleBulbClick = () => {
    setContext((state) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        simulationResultsDialogOpen: true
      }
    }))
  };

  return (
    <>
      { !shareId && <GrowthSimulatorTour
        name="tour-a"
        provider={GrowthSimulatorTourStepsProviderA}
        widgets={widgets
          .filter((w) => w.eco_topic_id === 80 || w.eco_topic_id === 110)
          .sort((a, b) => a.report_location - b.report_location)}
      />}
      <StyledGrowthSimulatorDetailsContainer className={className}>
        <StyledGrowthSimulatorDetailsAsideContainer>
          <GrowthSimulatorHeader fixed={false}></GrowthSimulatorHeader>
          <StyledAvatarContainer>
            <GrowthSimulatorAvatarHeader
              showHomeIcon={true}
              showMenuIcon={true}
              showSubtitle
              handleHomeIconClick={handleHomeIconClick}
              handleGoalTypeChange={(goal) => {
                const povClone = clone(pov);
                //TODO: Check after final implementation 
                // dispatch(resetGrowthSimulation());
                // dispatch(setGoalType(goal));
                dispatch(setPointOfViewType(povClone!));
              }}
            ></GrowthSimulatorAvatarHeader>
          </StyledAvatarContainer>
          <GrowthSimulatorForm />
          <StyledGrowthSimulatorDetailsGoalDescriptionContainer>
            {Boolean(growthSimulation) && (
              <GrowthSimulatorDetailsGoalDescription
                simulationId={
                  genre === Genre.SaaS
                    ? growthSimulationSaaS?.simulation_id!
                    : growthSimulation?.simulation_id!
                }
                bulbSetter={bulbSetterHandler}
                isDialogOpen={context.dialogs.simulationResultsDialogOpen}
                dialogSetter={(value: boolean) => {
                  setContext((state) => ({
                    ...state,
                    dialogs: {
                      ...state.dialogs,
                      simulationResultsDialogOpen: false
                    }
                  }))
                }}
              />
            )}
          </StyledGrowthSimulatorDetailsGoalDescriptionContainer>
        </StyledGrowthSimulatorDetailsAsideContainer>
        <StyledGrowthSimulatorDetailsMainContainer data-tour-id="growth_simulator_details">
          {Boolean(loading) && <StyledLinearProgress />}
          {!Boolean(growthSimulation) && <StyledGrowthSimulatorBlurOverlay />}
          <StyledGrowthSimulatorInstallsContainer data-tour-id="growth_simulator_details_section_installs">
            {chunk(
              widgets
                .filter((w) => w.eco_topic_id === 10)
                .sort((a, b) => a.report_location - b.report_location),
              3
            ).map((c, index) => {
              return (
                <StyledGrowthSimulatorInstallList key={index}>
                  {c.map((widget) => (
                    <StyleGrowthSimulatorInstallItem key={widget.headline}>
                      <MUITooltip title={widget?.tooltip}>
                        <StyleGrowthSimulatorInstallLabel>
                          {widget.headline}
                        </StyleGrowthSimulatorInstallLabel>
                      </MUITooltip>
                      <StyledGrowthSimulatorInstallCounterContainer>
                        <GrowthSimulatorResultLabel
                          component={StyledGrowthSimulatorInstallCounterLabel}
                          widget={widget}
                          simulationId={
                            genre === Genre.SaaS
                              ? growthSimulationSaaS?.simulation_id!
                              : growthSimulation?.simulation_id!
                          }
                        />
                      </StyledGrowthSimulatorInstallCounterContainer>
                    </StyleGrowthSimulatorInstallItem>
                  ))}
                </StyledGrowthSimulatorInstallList>
              );
            })}
          </StyledGrowthSimulatorInstallsContainer>
          <StyledGrowthSimulatorKPIContainer>
            {isBulbVisible && (
              <MUITooltip
                title="Click Me!"
                open={isBulbTooltipVisible}
                placement="top"
                onClick={handleBulbClick}
              >
                <div
                  data-tour-id="growth_simulator_details_section_summary"
                  className="bulb-icon-wrapper"
                  onClick={handleBulbClick}
                >
                  <WbIncandescentOutlinedIcon className="bulb-icon" />
                </div>
              </MUITooltip>
            )}
            <StyledGrowthSimulatorKPIList data-tour-id="growth_simulator_details_kpi_list_section">
              {widgets
                .filter((w) => w.eco_topic_id === 20)
                .sort((a, b) => a.report_location - b.report_location)
                .map((widget) => (
                  <StyledGrowthSimulatorKPIListItem key={widget.headline}>
                    <div>
                      <GrowthSimulatorResultLabel
                        component={StyledGrowthSimulatorKPIListItemLabel}
                        widget={widget}
                        simulationId={
                          genre === Genre.SaaS
                            ? growthSimulationSaaS?.simulation_id!
                            : growthSimulation?.simulation_id!
                        }
                      />

                      <MUITooltip title={widget?.tooltip}>
                        <p>{widget.headline}</p>
                      </MUITooltip>
                    </div>
                  </StyledGrowthSimulatorKPIListItem>
                ))}
            </StyledGrowthSimulatorKPIList>
          </StyledGrowthSimulatorKPIContainer>
          <StyledGrowthSimulatorChartContainer data-tour-id="growth_simulator_details_charts_section">
            {widgets
              .filter((w) => w.eco_topic_id === 30)
              .sort((a, b) => a.report_location - b.report_location)
              .map((widget) => (
                <StyledGrowthSimulatorChart key={widget?.headline}>
                  {(growthSimulation || growthSimulationSaaS) &&
                    chartFactory(
                      widget,
                      growthSimulation as any,
                      growthSimulationSaaS!
                    )}
                </StyledGrowthSimulatorChart>
              ))}
          </StyledGrowthSimulatorChartContainer>
        </StyledGrowthSimulatorDetailsMainContainer>
      </StyledGrowthSimulatorDetailsContainer>

      <StyledReportIdDebug>
        {genre === Genre.SaaS
          ? growthSimulationSaaS?.simulation_id!
          : growthSimulation?.simulation_id!}
      </StyledReportIdDebug>
    </>
  );
};

export default styled(GrowthSimulatorDetails)`
  @media ${device.laptopL} {
  }

  @media ${device.laptop} {
  }
`;
