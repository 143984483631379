import {
  ECalculationType,
  ERevenueType,
} from "../models/growthSimulator.models";

export const DEFAULT_TITLE_ID = 88;
export const DEFAULT_RETENTION = 1;
export const NOT_USED = 0;
export const NOT_USER_RETENTION_ANCHORS =  {
  1: '0%',
  6: '0%',
  14: '0%',
  30: '0%',
  60: '0%',
  90: '0%',
  150: '0%',
  365: '0%',
};

export const DEFAULT_CALCULATIONS = {
  [ECalculationType.TotalInstalls]: 0,
  [ECalculationType.PaidInstalls]: 0,
  [ECalculationType.OrganicInstalls]: 0,
  [ECalculationType.TotalMarketingCost]: 0,
  [ECalculationType.ECPI]: 0,
  [ECalculationType.MaxDau]: 0,
  [ECalculationType.TotalRevenues]: 0,
  [ECalculationType.ROASTime]: 0,
  [ECalculationType.TimeToPositiveCashflow]: 0,
};

export const DEFAULT_CALCULATOR_INPUT = 
  {
    dauGoal: {
      formattedValue: "0",
      value: "0",
      floatValue: 0,
    },
    budget: {
      formattedValue: "$0",
      value: "0",
      floatValue: 0,
    },
    dailyInstalls: {
      formattedValue: "0",
      value: "0",
      floatValue: 0,
    },
    days: {
      formattedValue: "0",
      value: "0",
      floatValue: 0,
    },
    cpi: {
      formattedValue: "$0",
      value: "0",
      floatValue: 0,
    },
    organicUplift: {
      formattedValue: "0%",
      value: "0",
      floatValue: 0,
    },
    arppu: {
      formattedValue: "0$",
      value: "0",
      floatValue: 0,
    },
    conversion: {
      formattedValue: "0%",
      value: "0",
      floatValue: 0,
    },
    currentDauScale: {
      formattedValue: "0",
      value: "0",
      floatValue: 0,
    },
    dauMaximumSeniority: {
      formattedValue: "0",
      value: "0",
      floatValue: 0,
    },
    impressionsPerDau: {
      formattedValue: "0",
      value: "0",
      floatValue: 0,
    },
    cpm: {
      formattedValue: "0$",
      value: "0",
      floatValue: 0,
    },
    ctr: {
      formattedValue: "0%",
      value: "0",
      floatValue: 0,
    },
    cpc: {
      formattedValue: "$0",
      value: "0",
      floatValue: 0,
    },
    installRate: {
      formattedValue: "0%",
      value: "0",
      floatValue: 0,
    },
    cpa: {
      formattedValue: "$0",
      value: "0",
      floatValue: 0,
    },
    monetizationType: 'CPM' as 'CPM' | 'CPC' | 'CPA'
  }

export const DEFAULT_RETENTION_NUMBERS = [
  {
    day: 1,
    value: {
      formattedValue: "40%",
      value: "40",
      floatValue: 40,
    },
  },
  {
    day: 7,
    value: {
      formattedValue: "20%",
      value: "20",
      floatValue: 20,
    },
  },
  {
    day: 14,
    value: {
      formattedValue: "12%",
      value: "12",
      floatValue: 12,
    },
  },
  {
    day: 30,
    value: {
      formattedValue: "8%",
      value: "8",
      floatValue: 8,
    },
  },
  {
    day: 60,
    value: {
      formattedValue: "5%",
      value: "5",
      floatValue: 5,
    },
  },
  {
    day: 90,
    value: {
      formattedValue: "4%",
      value: "4",
      floatValue: 4,
    },
  },
  {
    day: 150,
    value: {
      formattedValue: "2%",
      value: "2",
      floatValue: 2,
    },
  },
  {
    day: 365,
    value: {
      formattedValue: "1%",
      value: "1",
      floatValue: 1,
    },
  },
];

export const DEFAULT_REVENUE = {
  [ERevenueType.APDS]: 0,
  [ERevenueType.ARPDAU]: 0,
  [ERevenueType.ROAS]: 0,
  [ERevenueType.LTV]: 0,
};

export const DEFAULT_SCENARIO_DATA = {
  retentionNumbers: DEFAULT_RETENTION_NUMBERS,
  budget: DEFAULT_CALCULATOR_INPUT.budget,
  dauGoal: DEFAULT_CALCULATOR_INPUT.dauGoal,
  dailyInstalls: DEFAULT_CALCULATOR_INPUT.dailyInstalls,
  days: DEFAULT_CALCULATOR_INPUT.days,
  cpi: DEFAULT_CALCULATOR_INPUT.cpi,
  dynamicCpi: undefined,
  organicUplift: DEFAULT_CALCULATOR_INPUT.organicUplift,
  conversion: DEFAULT_CALCULATOR_INPUT.conversion,
  arppu: DEFAULT_CALCULATOR_INPUT.arppu,
};
