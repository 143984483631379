import { AxiosInstance, AxiosResponse } from "axios";
import { ICurrencyResponse } from "../queries/useGetCurrenciesQuery";

export class CurrencyService {
  public static axios: AxiosInstance;

  public static async geCurrencies(titleId: number): Promise<ICurrencyResponse> {
    const { data } = await CurrencyService.axios.get<
      undefined,
      AxiosResponse<ICurrencyResponse>
    >(`dashboard/title/${titleId}/currency`);

    return data;
  }
}

