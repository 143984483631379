import { CurrencyCode, money, percent, symbolToCurrencyCode, value } from '../../helpers/formatters';
import React, { ElementRef, RefObject, useContext, useEffect, useMemo, useState } from 'react';
import { SeeItButtonStyled, SimItButtonStyled } from '../Buttons/Buttons';
import { scoreColor, valueColor } from '../../helpers/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import GroupsIcon from '@mui/icons-material/Groups';
import { IGetImproveCohortsReportDataResponse } from '../../queries/useGetImproveCohortsReportDataQuery';
import { IGetTopCohortsReportDataResponse } from '../../queries/useGetTopCohortsReportDataQuery';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { UseQueryResult } from 'react-query';
import cn from 'classnames';
import { getSettings } from '../../store/settings/settings.actions';
import { settingsSelector } from '../../store/settings/settings.selector';
import styled from 'styled-components';
import { useApplicationContext } from '../../Context';

export interface ICohortsTableProps {
  className?: string;
  improveCohortsReportQuery?: UseQueryResult<IGetImproveCohortsReportDataResponse[], Error> | undefined;
  topCohortsReportQuery?: UseQueryResult<IGetTopCohortsReportDataResponse[], Error> | undefined;
  titleId: number;
}

const StyledActionContainer = styled.aside`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    gap: 5px;
`

export interface ICohortsTableItemProps {
  cohort: IGetImproveCohortsReportDataResponse;
  className?: string;
  titleId: number;
  dynamicWidthArray: (number | undefined)[];
  currencySymbol: string;
}

const CohortsTableItem = ({ cohort, className, titleId, currencySymbol }: ICohortsTableItemProps) => {
  const navigate = useHistory();
  const [{ period: contextPeriod } ] = useApplicationContext();

  const currencyCode = (
    symbolToCurrencyCode[
      currencySymbol as keyof typeof symbolToCurrencyCode
    ] || 'USD'
  ) as CurrencyCode;

  console.log('contextPeriod', contextPeriod);
  
  return <li className={cn('cohorts-table-item', className)}>
    <ul>
      <li>
        {cohort?.simpool_score && <p>
          <span style={{
            background: scoreColor(cohort?.simpool_score)
          }}>
            <span>
              {cohort?.simpool_score}
            </span>
          </span>
        </p>}
      </li>
      <li>{cohort?.cohort}</li>
      <li>{cohort?.users}</li>
      <li>
        <b>{money(cohort?.ltv, 'never', 2, 2, currencyCode)}</b>
        <span style={{ color: valueColor((cohort?.ltv - cohort?.avg_ltv)) }}>{money((cohort?.ltv - cohort?.avg_ltv), 'always', 2, 2, currencyCode)} vs avg</span>
      </li>
      <li>
        <b>{cohort?.apds}</b>
        <span style={{ color: valueColor((cohort?.apds - cohort?.avg_apds)) }}>{value((cohort?.apds - cohort?.avg_apds), 'always')} vs avg</span>
      </li>
      <li>
        <b> {percent(cohort?.conv)}</b>
        <span style={{ color: valueColor((cohort?.conv - cohort?.avg_conv)) }}>{percent((cohort?.conv - cohort?.avg_conv), 'always')} vs avg</span>
      </li>
      <li>
        <b>{money(cohort?.arppu30, 'never', 2, 2, currencyCode)}</b>
        <span style={{ color: valueColor((cohort?.arppu30 - cohort?.avg_arppu)) }}>{money((cohort?.arppu30 - cohort?.avg_arppu), 'always', 2, 2, currencyCode)} vs avg</span>
      </li>
      <li>
        <StyledActionContainer>
          <SimItButtonStyled onClick={() => navigate.push('/scenario-builder', cohort)} />
          <SeeItButtonStyled onClick={() => 
            window.open(
              `/cohorts-results?titleId=${titleId}&platform=${cohort?.cohort?.split(',')[0]?.trim()}&country=${cohort?.cohort?.split(',')[1]?.trim()}&source=${cohort?.cohort?.split(',')[2]?.trim()}&period=${JSON.stringify(contextPeriod).trim()}`, '_blank'
            )}></SeeItButtonStyled>
        </StyledActionContainer>
      </li>
      <li><InfoOutlinedIcon /></li>
    </ul >
  </li >
};

const StyledCohortsTableItem = styled(CohortsTableItem)`
  margin: 10px 0;

  & > ul {
    display: grid;
    grid-template-columns: ${({ dynamicWidthArray }) => `
      ${(Number(dynamicWidthArray?.[0]))}px
      ${(Number(dynamicWidthArray?.[1])) + 5}px
      ${Number(dynamicWidthArray?.[2])}px
      ${Number(dynamicWidthArray?.[3])}px
      ${Number(dynamicWidthArray?.[4]) + 5}px
      ${Number(dynamicWidthArray?.[5])}px
      ${Number(dynamicWidthArray?.[6]) + 5}px
      ${Number(dynamicWidthArray?.[7])}px
      ${Number(dynamicWidthArray?.[8])}px`
    };
    grid-column-gap: 10px;
    
    & > li {
      background: #2C2C3E;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 1rem;
      padding: 10px 0;
      color: #E7E7E7;

      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(7) {
        b {
          border-right: 1px solid #A8A8A8;
          padding-right: 10px;
          margin-right: 5px;
        }
      }


      &:nth-of-type(1) {
        p {
          background: #30314680 0% 0% no-repeat padding-box;
          border: 1px solid #939393;
          border-radius: 50%;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;

          & > span {
            min-width: 1rem;
            max-width: 1rem;
            color: #25273D;
            font-weight: bold;
            font-weight: bold;
            border-radius: 50%;
            font-size: 1rem;
            padding: 0.2rem 0.4rem;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
`


export const StyledCohortsTableItemHeaderContainer = styled.ul`
    display: grid;
    grid-template-columns: 0.3fr 0.8fr 0.2fr 0.6fr 0.6fr 0.6fr 0.6fr 0.5fr 0.2fr;
    grid-column-gap: 10px;

    & > li {
      background: #404052;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.2rem;
    padding: 10px 0;
    color: #E7E7E7;
    }
`

export const StyledCohortsTableHeader = styled.header`
  padding: 10px 30px 10px 65px;
  background: transparent linear-gradient(180deg, #212134 0%, #2C2C41 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000042;
`

export const StyledCohortsContent = styled.main`
    overflow: auto;
    direction: rtl;
    height: 85%;
    margin: 10px 0 10px 20px;
    padding: 0 30px;

    & > ul {
      direction: ltr;

      & > li:first-of-type {
        margin-top: 0;
      }
    }

    &::-webkit-scrollbar {
      width: 15px;              
    }

    &::-webkit-scrollbar-track {
      background: #424252;        
    }

    &::-webkit-scrollbar-thumb {
      background-color: #777782;    
      border: 1px solid #424252; 
    }
`

enum ECohortsTableType {
  TopCohorts = 'top',
  ImproveCohorts = 'improve',
}

const SortByHeader = ({ header, onSort, className, refChange }: {
  header: string | React.ReactElement,
  onSort: (
    direction: 'ascending' | 'descending'
  ) => void,
  className?: string,
  refChange:(ref: HTMLLIElement) => void
}) => {
  const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending')

  return <li
    ref={refChange}
    className={className} onClick={() => {
      const newDirection = ['ascending', 'descending']
        .find(d => d !== sortDirection) as 'ascending' | 'descending';

      setSortDirection(() => newDirection);

      onSort(newDirection);
    }}>
    <span>{header}</span>
    <span>
      {sortDirection === 'descending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </span>
  </li>
}

export const SortByHeaderStyled = styled(SortByHeader)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & > span {
      display: flex;

      &:last-of-type {
        position: absolute;
        right: 0;
        cursor: pointer;
      }

      & > svg {
        font-size: 2rem;
      }
    }
`;

const CohortsTable = ({ className, improveCohortsReportQuery, topCohortsReportQuery, titleId }: ICohortsTableProps) => {
  const { search } = useLocation();
  const [sortBy, setSortBy] = useState<{ key: keyof IGetImproveCohortsReportDataResponse, direction: 'ascending' | 'descending' } | undefined>({ key: 'simpool_score', direction: 'ascending' });
  const [query, setQuery] = useState<{
    isLoading: boolean;
    error: Error | null;
    data: IGetImproveCohortsReportDataResponse[] | undefined;
  } | undefined>(undefined);
  const settings = useSelector(settingsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!settings) {
      if(titleId) {
        dispatch(getSettings(Number(titleId)))
      }
    }
  }, [settings, titleId])

  const currencySymbol = settings
    ?.find(s => s.parameter_name === '{currency_symbol}')
    ?.parameter_value;

  const [sectionWidth, setSectionWidth] = useState<(number | undefined)[]>([]);
  const refs = useMemo(() => Array.from({ length: 9 }, _ => React.createRef<HTMLLIElement>()), []);

  useEffect(() => {
    if (!sectionWidth?.length) {
      setSectionWidth(() => refs?.map(x => x?.current?.getBoundingClientRect().width));
    }
  }, [refs])

  //Object destructuring is not favored by typescript, that is why I am using JSON parse/stringify
  //The whole idea is to pass a new instance of an object
  useEffect(() => {
    if (sortBy && query) {
      setQuery((state) => JSON.parse(JSON.stringify(Object.assign(state!, {
        data: state?.data?.sort((a, b) => {
          if (sortBy.direction === 'descending') {
            if (typeof a[sortBy.key] === 'string') {
              return String(a[sortBy.key]).localeCompare(String(b[sortBy.key]))
            } else {
              return Number(a[sortBy.key]) - Number(b[sortBy.key]);
            }
          } else {
            if (typeof a[sortBy.key] === 'string') {
              return String(b[sortBy.key]).localeCompare(String(a[sortBy.key]));
            } else {
              return Number(b[sortBy.key]) - Number(a[sortBy.key]);
            }
          }
        })
      }))))
    }
  }, [sortBy])

  useEffect(() => {
    const params = new URLSearchParams(search);
    const _query = params.get('type') === ECohortsTableType.ImproveCohorts ?
      improveCohortsReportQuery :
      topCohortsReportQuery;

    setQuery(_query);
  }, [improveCohortsReportQuery, topCohortsReportQuery])

  return !query?.isLoading ? <section className={cn('cohorts-table', className)}>
    <StyledCohortsTableHeader>
      <ul>
        <li>
          <StyledCohortsTableItemHeaderContainer>
            <SortByHeaderStyled refChange={(ref) => {refs[0]= {current: ref}}} header='Score' onSort={(direction) => setSortBy({ key: 'simpool_score', direction })} />
            <SortByHeaderStyled refChange={(ref) => {refs[1]= {current: ref}}} header='Cohort name' onSort={(direction) => setSortBy({ key: 'cohort', direction })} />
            <SortByHeaderStyled refChange={(ref) => {refs[2]= {current: ref}}}header={<GroupsIcon />} onSort={(direction) => setSortBy({ key: 'users', direction })} />
            <SortByHeaderStyled refChange={(ref) => {refs[3]= {current: ref}}} header='LTV' onSort={(direction) => setSortBy({ key: 'ltv', direction })} />
            <SortByHeaderStyled refChange={(ref) => {refs[4]= {current: ref}}} header='APDS' onSort={(direction) => setSortBy({ key: 'apds', direction })} />
            <SortByHeaderStyled refChange={(ref) => {refs[5]= {current: ref}}} header='Conversion' onSort={(direction) => setSortBy({ key: 'conv', direction })} />
            <SortByHeaderStyled refChange={(ref) => {refs[6]= {current: ref}}} header='ARPPU' onSort={(direction) => setSortBy({ key: 'arppu30', direction })} />
            <li ref={refs[7]}>Actions</li>
            <li ref={refs[8]}><InfoOutlinedIcon /></li>
          </StyledCohortsTableItemHeaderContainer>
        </li>
      </ul>
    </StyledCohortsTableHeader>
    <StyledCohortsContent>
      <ul>
        {query?.data?.map((d) => <StyledCohortsTableItem
          currencySymbol={currencySymbol!}
          dynamicWidthArray={sectionWidth}
          cohort={d}
          key={d.cohort}
          titleId={titleId}
        />)}
      </ul>
    </StyledCohortsContent>
  </section> : null
};

export default styled(CohortsTable)`
    height: 90%;
    margin-top: 30px;

    overflow: auto;
    background-color: #212134;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    & > ul {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
`;
