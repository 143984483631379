import { IGetAccountTitlesResponse } from '../../queries/useGetAccountTitlesQuery';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import UserAcqusitionTable from '../../components/UserAcquisitionTable/UserAcquisitionTable';
import styled from 'styled-components';
import { useGetWidgetsQuery } from '../../queries/useGetWidgetsQuery';
import { useEffect, useState } from 'react';

export interface IUserAcquisitionProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
}
export type Tooltips = {
  [key: string]: string;
}

export const StyledUserAcquisitionContainer = styled.div`
  display: flex;
  margin: 0 30px;
  overflow: hidden;
  height: calc(100vh - 100px);
  flex-direction: column;
  margin-bottom: 20px;
`

const UserAcquisition = ({ page, title }: IUserAcquisitionProps) => {
  const getWidgetsQueryResponse = useGetWidgetsQuery(9);
  const [ tooltips, setTooltips ] = useState<Tooltips | undefined>();

  useEffect(() => {
    if (!getWidgetsQueryResponse || getWidgetsQueryResponse?.isLoading) {
      return;
    }

    if (!getWidgetsQueryResponse?.data) {
      return;
    }

    const { data, error } = getWidgetsQueryResponse;

    if (!data || error) {
      return;
    }

    const tooltips: Tooltips = data?.[0].tooltip_additional_info;
    setTooltips(tooltips)
    // console.log(getWidgetsQueryResponse);
  }, [getWidgetsQueryResponse]);


  return <StyledUserAcquisitionContainer>
    <UserAcqusitionTable page={page} title={title} additionalTooltips={tooltips}></UserAcqusitionTable>
  </StyledUserAcquisitionContainer>

};

export default UserAcquisition;
