import { AnimatePresence, motion } from "framer-motion";
import { useState } from 'react';
import { money, percent, value } from "../../helpers/formatters";

import Divider from '@mui/material/Divider';
import cn from 'classnames';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { useApplicationContext } from "../../Context";
import { IGetImproveCohortsReportDataResponse } from "../../queries/useGetImproveCohortsReportDataQuery";
import { SeeItButtonStyled, SimItButtonStyled } from '../Buttons/Buttons';
import CohortsCardAnimatedRow from "../CohortsCardAnimatedRow/CohortsCardAnimatedRow";
import Gauge from '../Gauge/Gauge';

export interface IImproveCohortsCardProps {
  className?: string,
  open?: boolean,
  data?: IGetImproveCohortsReportDataResponse
}

const ImproveCohortsCard = ({ className, open, data }: IImproveCohortsCardProps) => {
  const [expanded, setExpanded] = useState<boolean>(Boolean(open));
  const navigate = useHistory();
  const [{  title }] = useApplicationContext();

  return <section className={className}>
    <header>
      <h3>{data?.cohort}</h3>
      <Divider />
      <ul>
        <li>
          <span>LTV30</span>
        </li>
        <li>
          <span><b>{money(data?.ltv!)}</b></span>
        </li>
        <li>
          <span>
            {money(data?.ltv! - data?.avg_ltv!, 'always')} vs avg
          </span>
        </li>
      </ul>
    </header>
    <main>
      <section
        className={cn({
          expanded
        })}
       
        onClick={() => setExpanded((previousExpanded) => !previousExpanded)}
      >
        <Gauge
          width={120}
          radius={120 / 2}
          size={15}
          value={data?.simpool_score!}
          users={data?.users}
        />
      </section>
      <AnimatePresence>
        {expanded && <motion.aside
          transition={{ delay: 0.1 }}
          initial={{ height: 0, overflow: 'hidden', opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, overflow: 'hidden', opacity: 0 }}
        >
          <CohortsCardAnimatedRow
            key="APDS"
            title="APDS"
            avg={data?.avg_apds!}
            value={data?.apds!}
            format={value}
            color={{
              negative: '#FF6F00',
              positive: '#DBDCE0'
            }}
          />
          <CohortsCardAnimatedRow
            key="ARPPU30"
            title="ARPPU30"
            avg={data?.avg_arppu!}
            value={data?.arppu30!}
            format={money}
            color={{
              negative: '#FF6F00',
              positive: '#DBDCE0'
            }}
          />
          <CohortsCardAnimatedRow
            key="CONVERSION"
            title="CONVERSION"
            avg={data?.conv!}
            value={data?.avg_conv!}
            format={percent}
            color={{
              negative: '#FF6F00',
              positive: '#DBDCE0'
            }}
          />
        </motion.aside>}
      </AnimatePresence>
    </main>
    <footer>
      <SimItButtonStyled onClick={() => navigate.push('/scenario-builder', data)}/>
      <SeeItButtonStyled onClick={() => window.open(`/cohorts-results?titleId=${title?.id}&platform=${data?.cohort?.split(',')[0]?.trim()}&country=${data?.cohort?.split(',')[1]?.trim()}&source=${data?.cohort?.split(',')[2]?.trim()}`, '_blank')}></SeeItButtonStyled>
    </footer>
  </section>
}

export default styled(ImproveCohortsCard)`
    background: #2E2F4B 0% 0% no-repeat padding-box;
    box-shadow: inset 3px 3px 6px #00000073;
    border: 1px solid #4A9CFB;
    border-radius: 35px;
    position: relative;

    color: #E7E7E7;

    margin-bottom: 20%;
    margin-top: 5%;

   

    header {
      h3 {
        margin: 0;
        padding: 15px 0;
        text-align: center;
        text-shadow: 2px 2px 4px #000000;
        opacity: 0.8;
        font-size: 1.2rem;
        font-weight: normal;

        overflow: hidden;
        height: 1.2rem;
        line-height: 1.2rem;
        white-space: nowrap;
      }

      ul {
        margin: 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 10px;

        li {
          border-right: 1px solid #707070;
          padding-right: 15px;

          span {
            color: #FFFFFF;
            text-shadow: 1px 1px 3px #000000BF;
            opacity: 0.8;

            b {
              color: #4A9CFB;
            }
          }
        }

        li:last-of-type {
          border-right: none;
          padding-right: 0;
        }
      }
    }

    hr {
      background: #707070;
    }

    main {
      & > section {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        background: #212239;
        box-shadow: inset 3px 3px 6px #00000073;
        padding: 15px 45px;
        border-radius: 0 0 35px 35px;
        transition: border-radius 0.3s;

        &.expanded {
          border-radius: 0;
          }
        }
      }

      aside {
        .cohorts-card-animated-row {
          &:last-of-type {
            border-radius: 0 0 35px 35px;
          }
        }
      }
    

    & > footer {
      position: absolute;
      width: 50%;
      left: 15%;

      background: #292A42 0% 0% no-repeat padding-box;
      border-radius: 0 0 35px 35px;

      display: flex;
      align-items: center;
      gap: 7px;
      justify-content: center;
      padding: 15px 30px;
      box-shadow: inset 3px 3px 6px #00000073;

      margin-top: 1px;
    }
`;
