import { Button } from "@mui/material"
import styled from "styled-components"

export const StyledBackButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: hsla(0, 0%, 65%, 1) !important;
    color: #262742 !important;
    font-size: 1rem;
    width: 70px;
    border-radius: 20px !important;
    text-transform: none !important;

    &:hover {
      background: hsla(0, 0%, 55%, 1) !important;
    }
`

export const StyledNextButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: rgb(93,168,253) !important;
  color: hsla(238, 27%, 20%, 1) !important;
  font-size: 1rem;
  width: 80px;
  border-radius: 20px !important;
  text-transform: none !important;
  &:hover {
    background: rgba(93,168,253, 0.7) !important;
  }
  &:disabled {
    background: rgba(93,168,253, 0.5) !important;
  }
`

export const StyledRunButton = styled(StyledNextButton)`
 background: #ffc301 !important;

 &:hover {
    background: rgba(255,195,1, 0.7) !important;
  }

  &:disabled {
    background: rgba(255,195,1, 0.5) !important;
  }
`
export const StyledSaveButton = styled(StyledNextButton)`
  align-self: flex-end;
  background: #4A9CFB !important;
  padding-left: 16px !important;
  &:hover {
    background: rgba(74,156,251, 0.5) !important;
  }

  &:hover {
    cursor: pointer;
    .html-tooltip {
      opacity: 1;
      visibility: visible;
      width: 200%;
    }
  }
`

export const StyledOpenButton = styled.button`
  background: #4A9CFB;

  border-radius: 15px;
  outline: none;
  border: none;
  padding: 4px 10px;

  width: 70px;
  height: 30px;
  align-self: center;
  justify-self: center;

  &:hover {
    background: rgba(74,156,251, 0.5);
  }

  &:hover {
    cursor: pointer;
    .html-tooltip {
      opacity: 1;
      visibility: visible;
      width: 200%;
    }
  }
`

export const StyledGrowthSimulatorDetailsSegmentHeaderTabListItemAddButton = styled.button`
  box-shadow: 2px 2px 4px #000000A6;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4A9CFB;
  color: #28334a;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgba(74,156,251, 0.5) !important;
  }
  &:hover {
    cursor: pointer;
    .html-tooltip {
      opacity: 1;
      visibility: visible;
      width: 300%;
    }
  }
`