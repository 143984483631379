import styled from "styled-components";
import { StyledArrowButton } from "./ArrowUpSVG";
import InputWithArrows, { InputType } from "./InputWithArrows";

export interface IHeadlineProps {
  className?: string;
  formikHandler?: any;
  formikValues?: any;
  
}

const Headline = ({className, formikHandler, formikValues}: IHeadlineProps) => {
  return (
    <div className={className}>
      <div className="col-1">
        <label htmlFor="col-1">Daily installs</label>
        <InputWithArrows name="headLine.dailyInstals" type={InputType.Integer} formikHandler={formikHandler} value={formikValues?.headLine?.dailyInstals}/>
      </div>
      <div className="col-2">
        <label htmlFor="col-2">Days</label>
        <InputWithArrows name="headLine.days" type={InputType.Integer} formikHandler={formikHandler} value={formikValues?.headLine?.days}/>
      </div>
      <div className="col-3">
        <label htmlFor="col-3">web2/web3 conversion</label>
        <InputWithArrows name="headLine.web2web3conversion" formikHandler={formikHandler} value={formikValues?.headLine?.web2web3conversion}/>
      </div>
      <div className="col-4">
        <label htmlFor="col-4">CPI</label>
        <InputWithArrows name="headLine.cpi" type={InputType.Decimal} formikHandler={formikHandler} value={formikValues?.headLine?.cpi} currencySign/>
      </div>
      <div className="col-5">
        <label htmlFor="col-5">Organic</label>
        <InputWithArrows name="headLine.organic" formikHandler={formikHandler} value={formikValues?.headLine?.organic}/>
      </div>
    </div>
  );
};

export default styled(Headline)`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(50px, 19%)); */
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
  grid-gap: 15px;
  background-color: #43445F;
  padding: 20px;
  border-radius: 15px;

  label {
    margin-right: 15px;
  }
  [class^="col"] {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .col-input {
      height: 30px;
      text-align: center;
      width: 100%;
    }
  }
  
`;