
import styled from "styled-components"
import { valueColor } from "../../../../helpers/colors"

const HeaderItem = ({ className, label, value, average, hideAverage, noFormatAvrg }: any) => {
  const formattedAverage = average.indexOf('-') !== -1 ? average.slice(1) : average;
  return <ul className={className}>
    <li>
      <span>{label}</span>
    </li>
    <li>
      <span>{value}</span>
    </li>
    <li>
      {!hideAverage ? <span
        style={
          {
            color: valueColor(noFormatAvrg)
          }
        }
      >{formattedAverage} vs avg</span> : null}
    </li>
  </ul>
}

export const ScenarioBuilderHeaderItem = styled(HeaderItem)`
    padding: 0;
    margin: 0;
    list-style-type: none;


    width: 100%;

    display: grid;
    grid-template-columns: 0.8fr 0.4fr 0.8fr;

    color: #fff;

    & > li {
      border-right: 1px solid #F5F5F569;
      text-align: center;
      span {
        @media (max-width: 1550px) {
            font-size: 0.9rem;
        }
        @media (max-width: 1472px) {
            font-size: 0.8rem;
        }
        @media (max-width: 1392px) {
            font-size: 0.7rem;
        }
      }
      &:last-of-type {
        border: none;

        span {
          background: #212239 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          border-radius: 16px;
          padding: 5px 10px;
          @media (max-width: 1550px) {
            font-size: 0.9rem;
          }
          @media (max-width: 1472px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1392px) {
            font-size: 0.7rem;
        }
        }
      }
    }
`
