export enum Currency {
  USDollar = "USD",
  Euro = "EUR",
}

export const symbolToCurrencyCode = {
  "$": "USD",
  "€": "EUR",
};

export type CurrencyCode = "USD" | "EUR" | "AUD" | "CNY" | "CAD";

export const currencyFormatterInterceptor = (format: any, sign?: any) => {
  if (format === "currency" && sign) {
    return `${sign}#,##0.00`;
  }
  return format;
};

export const money = (
  money: number,
  signDisplay?: "auto" | "never" | "always",
  minimumFractionDigits: number | undefined = 2,
  maximumFractionDigits: number | undefined = undefined,
  currency: CurrencyCode = "USD"
): string => {
  if (!money && money !== 0) {
    return "";
  }

  return `${money?.toLocaleString("en-US", {
    style: "currency",
    currency,
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
    signDisplay,
  })}`;
};

export const value = (
  value: number,
  signDisplay?: "auto" | "never" | "always",
  minimumFractionDigits: number | undefined = 2,
  maximumFractionDigits: number | undefined = 2,
  useGrouping?: boolean 
): string => {
  if (!value && value !== 0) {
    return "";
  }

  return value?.toLocaleString("en-US", {
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
    useGrouping
  });
};

export const percent = (
  value: number,
  signDisplay?: "auto" | "never" | "always"
) => {
  if (!value && value !== 0) {
    return "";
  }

  return (value / 100)?.toLocaleString("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    signDisplay,
  });
};

export const dateToString = (date: Date) => {
  return date?.toISOString().split("T")[0];
};

export const parseStringToNumber = (value: string): number => {
  return parseFloat(value.toString().replace(/[$%,]/g, ""));
};

export const firstCharUpperCase = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const removeRecordFromTheObject = (
  object: Object,
  key: keyof Object
) => {
  let result: Object = {};
  Object.keys(object).forEach((k) => {
    if (k !== key) {
      result = { ...result, [k]: object[k as keyof Object] };
    }
  });
  return result;
};

export const toFixedNumber = (
  value: number,
  digits: number,
  base: number = 10
): number => {
  var pow = Math.pow(base || 10, digits);
  return Math.round(value * pow) / pow;
};
