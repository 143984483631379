import { AnimatePresence, motion } from "framer-motion"
import {
  Menu,
  MenuButton,
  MenuItem,
  SubMenu
} from '@szhsin/react-menu';
import { MouseEventHandler, useState } from 'react';

import { AuthService } from '../../services/auth.service';
import BarsLightIcon from '../Icons/BarsLightIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import { IGetPageResponse } from '../../queries/useGetPagesQuery';
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Settings from "../../pages/Settings/Settings";
import SimpoolDialog from '../Dialog/Dialog';
import Table from '../../shared/Table/Table';
import { TextField } from "@mui/material";
import { settingsSelector } from "../../store/settings/settings.selector";
import styled from 'styled-components';
import { useApplicationContext } from '../../Context';
import { useSelector } from "react-redux";

export interface IMenuProps {
  className?: string;
}

const StyledMenuButton = styled(MenuButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  background: #25273D;
  height: 5rem;
  border-radius: 50%;
  border: 1px solid #FFFFFFA3;

  svg {
    transform: scale(0.7);
    color: #90919C;
  }


  &:hover {
    border: 1px solid #fff;

    svg {
      color: #fff;
    }
  }
`

const Trigger = () => <StyledMenuButton>
  <BarsLightIcon></BarsLightIcon>
</StyledMenuButton>

const StyledMenu = styled(Menu)`
 [role="menu"] {
  border: 1px solid #FFFFFF8C;
  background: #25273DF2;
  width: 200px;
  z-index: 20;
  padding: 0;
 }
`

const StyledSubMenu = styled(SubMenu)`
  & > [role="menuitem"] {
    padding: 15px;
    color: #9696A0;
    font: normal normal normal 1.2rem Segoe UI;
  }
`

const StyledMenuItem = styled(MenuItem)`
  color: #9696A0;
  font: normal normal normal 1.2rem Segoe UI;

  padding: 0;

  a {
    width: 100%;
    height: 100%;
    
    padding: 15px;

    color: #9696A0;
    text-decoration: none;
  }
`

const StyledFooterMenuItem = styled(MenuItem)`
  padding: 15px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background: initial;
  }

  cursor: auto;

  & > a {
    cursor: pointer;

    border-radius: 50%;
    padding: 8px 11px 7px 11px;
    border: 1px solid #FFFFFF59;
    background: #FFFFFF30 0% 0% no-repeat padding-box;

    svg {
      color: #A3A4AD;
    }
  }
`

const NavigationMenu = ({ className }: IMenuProps) => {
  const [{ pagesQuery },] = useApplicationContext();
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    AuthService.Logout();
  }

  const handleSettings = () => {
    setOpen(true);
  }

  const render = (nodes: IGetPageResponse[]) => {
    //INFO(PPavlov): We do not access these pages from the menu
    const WHAT_IF_RESULTS_PAGE_ID = 36;
    const COHORT_RESULTS_PAGE_ID = 38;
    const SEGMENT_RESULTS_PAGE_ID = 39;

    return nodes?.filter(x => {
      return ![
        WHAT_IF_RESULTS_PAGE_ID,
        COHORT_RESULTS_PAGE_ID,
        SEGMENT_RESULTS_PAGE_ID
      ].includes(x?.id);
    }).map((node) => {
      return node?.children && node?.children?.length ?
        <StyledSubMenu label={node.name} key={node.name}>
          {render(node.children)}
        </StyledSubMenu> :
        <StyledMenuItem key={node.name}>
          <Link to={node?.path!}>{node.name}</Link>
        </StyledMenuItem>
    })
  }

  return <>
    <StyledMenu menuButton={Trigger} transition offsetY={20}>
      {pagesQuery && pagesQuery?.isLoading ? null : render(pagesQuery?.data!)}
      <StyledMenuItem key="Settings">
        <a href="javascript: void(0)" onClick={handleSettings}>
          Settings
        </a>
      </StyledMenuItem>
      <StyledFooterMenuItem>
        <Link to="/">
          <HomeIcon></HomeIcon>
        </Link>
        <Link to="/" onClick={handleLogout}>
          <LogoutIcon></LogoutIcon>
        </Link>
      </StyledFooterMenuItem>
    </StyledMenu>
    <Settings open={open} onClose={() => setOpen(false)} />
  </>
};

export default NavigationMenu;