import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Check } from '../../../../assets/check.svg';
import { ReactComponent as ComingSoon } from '../../../../assets/сoming_soon.svg';
import MUITooltip from "../../../../components/MUITooltip/MUITooltip";

type WizardChoiceCardProps = {
  title?: string;
  svgComponent?: any;
  onClick?: (e: any) => void;
  comingSoon?: boolean;
  tooltip?: string;
};

export const WizardChoiceCard = ({ title = '', svgComponent, onClick, comingSoon = false, tooltip = ''}: WizardChoiceCardProps) => {
  const handleOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    (!comingSoon && onClick) && onClick(e)
  };

  return (
    <WizardChoiceCardContainer onClick={handleOnClick} comingSoon={comingSoon} svgComponent={!!svgComponent}>
      <div className="bg">
        {svgComponent }
      </div>
      <MUITooltip title={tooltip}>
        <Box sx={{ width: '100%', textAlign: 'center'}}>
          <Typography
            variant={svgComponent ? 'h4' : 'h5'}
            color={'white'}
            >
            { title }
          </Typography>
        </Box>
      </MUITooltip>
      <div className="check-box">
        <Check/>
      </div>
      <div className="cs-box">
        <ComingSoon/>
      </div>
    </WizardChoiceCardContainer>
  );
};

const WizardChoiceCardContainer = styled.div<{ comingSoon?: boolean, svgComponent?: boolean}>`
  position: relative;
  /* width: 420px;
  height: 280px; */
  width: 280px;
  height: ${({ svgComponent }) => svgComponent ? '180px': '80px'};
  .bg {
    svg{
      width: 280px;
      height: 130px;
    }
  }
  background: #2C3C55;
  border: 1px solid rgba(255, 250, 250, 0.4);
  box-shadow: inset 1px 1px 25px 1px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  .cs-box{
    position: absolute;
    top: -27px;
    right: -40px;
    z-index: 1;
    visibility: ${({ comingSoon }) => comingSoon ? 'visible' : 'hidden'};
  }
  .check-box {
    position: absolute;
    top: -15px;
    right: -15px;
    visibility: hidden;
  }
  &:hover {
    ${({ comingSoon}) => !comingSoon ? `
      background: #283954;
      border: 4px solid #8FCEFF;
      box-shadow: 4px 8px 20px 4px rgba(22, 31, 50, 0.45);
      cursor: pointer;
      .check-box {
        visibility: visible;
      }
    ` : `
      pointer-events: none
    `}
    
  }
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    object-fit: cover;
    max-width: 280px;
  }
`