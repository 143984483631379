import { CurrencyCode, currencyFormatterInterceptor } from '../../../helpers/formatters';
import { IGetReportDataResponse, useGetReportDataQuery } from '../../../queries/useGetReportDataQuery';
import { useEffect, useState } from 'react';

import { Chart } from "react-google-charts";
import ChartContainer from '../ChartContainer/ChartContainer';
import { DynamicCpi } from '../../../pages/GrowthSimulatorV2/components/GrowthSimulatorDynamicCpiTable/GrowthSimulatorDynamicCpiTable';
import { IChartLegend } from '../models';
import { IFilterBarPayload } from '../../FilterBar/FilterBar';
import { IGetWidgetsResponse } from "../../../queries/useGetWidgetsQuery";
import { LineChartDataAdapter } from './LineChartDataAdapter';
import { handleHeadlineHelper } from '../../../pages/Analytics/Analytics';
import { settingsSelector } from '../../../store/settings/settings.selector';
import { sharedAccountIdSelector } from '../../../store/shared/shared.selector';
import { useSelector } from 'react-redux';

export interface ILineChartProps {
  widget: IGetWidgetsResponse,
  titleId?: number,
  filter?: IFilterBarPayload,
  cross_title?: boolean;
  whatIfId?: number;
  simulation_id?: number;
  className?: string;
  extraFilters?: {
    cpi?: DynamicCpi[];
    arpdau?: number;
    installs?: number;
    conversion?: number;
    arppu?: number;
    days?: number;
    dau_goal?: number;
    budget?: number;
    total_installs?: number;
    paid_installs?: number;
    organic_installs?: number;
    query?: string;
  };
  hasReportLocation?: boolean;
}

const LineChart = ({ widget, titleId, filter, cross_title = false, whatIfId, simulation_id, extraFilters, className, hasReportLocation }: ILineChartProps) => {
  const [groupByFilter, setGroupByFilter] = useState<string[] | undefined>();
  const [legendFilters, setLegendFilters] = useState<IChartLegend[]>([]);
  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>();
  const accountIdSelector = useSelector(sharedAccountIdSelector);
  const currencyCode = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_code}")?.['parameter_value'] as CurrencyCode;
  const currencySign = useSelector(settingsSelector)?.find(setting => setting.parameter_name === "{currency_symbol}");

  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      report_id: hasReportLocation ? undefined : widget?.report_id,
      dashboard_id: widget?.dashboard_id,
      title_id: widget?.title_id || titleId,
      days_back: filter?.daysBack || 60,
      date_from: (filter?.dateFrom && String(filter?.dateFrom)) || "-1",
      date_to: (filter?.dateTo && String(filter?.dateTo)) || "-1",
      country: filter?.country,
      platform: filter?.platform,
      source: filter?.source,
      group_by: groupByFilter,
      currency: currencyFilter,
      account_id: cross_title ? accountIdSelector : undefined,
      whatif_id: whatIfId,
      simulation_id,
      extra_filters: extraFilters,
      report_location: hasReportLocation ? widget?.report_location : undefined,
      topic_id: hasReportLocation ? widget?.eco_topic_id : undefined,
    }, `LineChart-
        ${currencyFilter && currencyFilter}-
        ${widget?.report_id}-${widget?.title_id || titleId}-${filter?.dateFrom}-
        ${filter?.daysBack}-
        ${filter?.dateTo}-
        ${filter?.country}-
        ${filter?.platform}-
        ${filter?.source}-
        ${extraFilters?.toString()}-
        ${groupByFilter?.toString()}-
        ${cross_title && `account_id: ${accountIdSelector}`}-${whatIfId}
        ${simulation_id}`);

  const [reportData, setReportData] = useState<IGetReportDataResponse[]>();

  useEffect(() => {
    if (!widget || !getReportDataQueryResponse || getReportDataQueryResponse.isLoading) {
      return;
    }

    const { data, error } = getReportDataQueryResponse;

    if (!data || error) {
      return;
    }

    if (JSON.stringify(reportData) === JSON.stringify(data)) {
      return;
    }

    setReportData(data);
  }, [getReportDataQueryResponse, widget, reportData])

  const handleGroupBy = (item: string | undefined) => {
    item ? setGroupByFilter([item]) : setGroupByFilter(undefined);
  }

  const handleCurrency = (item: string) => {
    setCurrencyFilter(item);
  }

  const [rows, cols, legend] = new LineChartDataAdapter()
    .initialize(widget, currencyCode)
    .adapt(reportData!, legendFilters)
    .make();

  const options = {
    curveType: "function",
    intervals: { style: "points", pointSize: 4 },
    legend: "none",
    vAxis: {
      gridlines: { color: 'transparent' },
      baselineColor: '#9999A4',
      textStyle: { color: '#9999A4' },
      format: currencyFormatterInterceptor(widget?.y_axis_format, currencySign?.["parameter_value"]),
    },
    hAxis: {
      gridlines: { color: 'transparent' },
      baselineColor: '#9999A4',
      textStyle: { color: '#9999A4' },
    },
    backgroundColor: 'transparent',
    chartArea: { width: '80%', height: '70%' },
    tooltip: { isHtml: true },
    interpolateNulls: true
  };

  return <ChartContainer loading={getReportDataQueryResponse?.isLoading}
    headline={handleHeadlineHelper(widget?.headline, groupByFilter, currencyFilter)}
    groupBy={widget?.group_by || []}
    onGroupByChange={handleGroupBy}
    legend={legend}
    onChange={(filter) => setLegendFilters(filter)}
    showCurrency={widget.use_currency}
    onCurrencyChange={handleCurrency}
    tooltip={widget.tooltip}
    exportData={reportData}
    className={className}
  >
    <Chart
      chartType="LineChart"
      height="300px"
      width="100%"
      rows={rows as any}
      columns={cols as any}
      options={options}
    />
  </ChartContainer>
};

export default LineChart;
