import { EPointOfViewType } from "../../../../store/growthSimulator/growthSimulator.state"
import MonetizationPersona from '../../../../assets/monetization_persona.png';
import ProductPersona from '../../../../assets/product_persona.png';
import UserAcquisitionPersona from '../../../../assets/user_acquisition_persona.png';
import styled from "styled-components";

const SimpoolTourContent = ({ text, className, pov }: { className?: string, text: string, pov: EPointOfViewType | null }) => {
    const povToPersona = {
        [EPointOfViewType.Monetization]: MonetizationPersona,
        [EPointOfViewType.Product]: ProductPersona,
        [EPointOfViewType.UserAcquisition]: UserAcquisitionPersona
    }

    return <section className={className}>
        <img src={povToPersona[pov!]} alt="Persona" />
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </section>
}

export const StyledSimpoolTourContent = styled(SimpoolTourContent)`
    display: grid;
    grid-template-columns: 1fr 5fr;
    align-items: center;
    justify-items: center;

    img {
      width: 80px;
      transform: rotateY(180deg);
      margin-right: 10px;
      margin-left: -10px;
    }

    p {
      font-size: 18px;
    }
`