import * as React from "react"

const UsersSolidIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26.697}
    height={20.488}
    {...props}
  >
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={26.697}
        height={20.488}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={1} result="blur" />
        <feFlood />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#a)">
      <path
        data-name="users-solid"
        d="M5.1 8.209a2.07 2.07 0 1 0-2.07-2.07 2.072 2.072 0 0 0 2.07 2.07Zm14.488 0a2.07 2.07 0 1 0-2.07-2.07 2.072 2.072 0 0 0 2.074 2.07Zm1.035 1.035h-2.07a2.064 2.064 0 0 0-1.458.6 4.73 4.73 0 0 1 2.429 3.538h2.134a1.034 1.034 0 0 0 1.042-1.034v-1.035a2.072 2.072 0 0 0-2.073-2.069Zm-8.279 0a3.622 3.622 0 1 0-3.622-3.622 3.62 3.62 0 0 0 3.626 3.622Zm2.484 1.035h-.268a5 5 0 0 1-4.43 0h-.265A3.726 3.726 0 0 0 6.139 14v.931a1.553 1.553 0 0 0 1.552 1.552H17a1.553 1.553 0 0 0 1.552-1.552V14a3.726 3.726 0 0 0-3.72-3.721ZM7.6 9.845a2.064 2.064 0 0 0-1.458-.6H4.07A2.072 2.072 0 0 0 2 11.313v1.035a1.034 1.034 0 0 0 1.035 1.035h2.131A4.742 4.742 0 0 1 7.6 9.845Z"
        fill="#fffcae"
      />
    </g>
  </svg>
)

export default UsersSolidIcon
