import { DataTable, IChartDataAdapter, IChartLegend } from "../models";

import { CurrencyCode } from "../../../helpers/formatters";
import { IGetReportDataResponse } from "../../../queries/useGetReportDataQuery";
import { IGetWidgetsResponse } from "../../../queries/useGetWidgetsQuery";
import { SERIES_COLORS } from "../../../helpers/colors";
import { customHTML } from "../StackedLineChart/StackedLineChart";
import { groupBy } from "../../../helpers/extensions";

export class StackedBarChartDataAdapter implements IChartDataAdapter {
  private _widget?: IGetWidgetsResponse;
  private _currencyCode?: CurrencyCode;

  public initialize(widget: IGetWidgetsResponse, currencyCode: CurrencyCode): IChartDataAdapter {
    this._widget = widget;
    this._currencyCode = currencyCode;

    return this;
  }

  public adapt(
    report: IGetReportDataResponse[],
    filter?: Partial<IChartLegend>[]
  ): DataTable {
    if (!report || !this._widget) {
      return new DataTable();
    }

    const series = this._widget?.series.toLowerCase();
    const xAxis = this._widget?.x_axis.toLowerCase();
    const yAxis = this._widget?.y_axis.toLowerCase();

    const { x_label, x_axis } = this._widget;

    const dataTable = new DataTable();

    const seriesValues = report
      .map((r) => r[series as keyof typeof r])
      .filter((x, i, a) => a.indexOf(x) === i);

    const seriesToColor: { [key: string]: string } = seriesValues.reduce(
      (acc, serie, index) => ({
        ...acc,
        [serie?.toString()!]: SERIES_COLORS[index],
      }),
      {}
    );

    dataTable.addColumn({ type: "string", label: x_label || x_axis });
    seriesValues.forEach((label) => {
      dataTable.addColumn({ type: "number", label: label?.toString() });
      dataTable.addColumn({ type: "string", role: "style" });
      dataTable.addColumn({ type: 'string', role: 'tooltip', p: {html: true}})
    });

    const reportByXAxis = groupBy(
      report,
      (item) => item[xAxis as keyof typeof item] as string
    );

    const reportBySeriesAndXAxis: {
      [key: string]: Record<string, IGetReportDataResponse[]>;
    } = Object.keys(reportByXAxis).reduce(
      (acc, key) => ({
        ...acc,
        [key]: groupBy(
          reportByXAxis[key],
          (d) => d[series as keyof typeof d] as string
        ),
      }),
      {}
    );
    
    Object.keys(reportBySeriesAndXAxis).forEach((r) => {
      const sections = seriesValues.map((section) => {
     
        if (filter?.find((f) => f.value == section)) {
          return [undefined, undefined, undefined];
        }

        const color = seriesToColor[section?.toString()!];
     
        return reportBySeriesAndXAxis[r][section as string]
          ? [
              {
                v: reportBySeriesAndXAxis[r][section as string][0][
                  yAxis as keyof IGetReportDataResponse
                ],
                f: reportBySeriesAndXAxis[r][section as string][0][
                  yAxis as keyof IGetReportDataResponse
                ]?.toString(),
              },
              {
                v: color,
              },
              {
                v: (this._widget && this._currencyCode)
                  && customHTML(r.toString(), section?.toString(), reportBySeriesAndXAxis[r][section as string][0][yAxis as keyof IGetReportDataResponse], this._widget?.y_axis_format, this._currencyCode)
              }
            ]
          : [undefined, undefined, undefined];
      });
      dataTable.addRow([{ v: r }, ...sections.flat(1)]);
    });

    const legend: IChartLegend[] = seriesValues.map((value, index) => ({
      background: SERIES_COLORS[index],
      value: value?.toString(),
    })) as IChartLegend[];

    dataTable.setLegend(legend);

    return dataTable;
  }
}
