import { object } from "yup";
import { INode } from "../models/models";

const dfs = (
  nodes: INode[],
  predicate: (current: INode, previous: INode | undefined) => boolean
): INode | undefined => {
  const stack: INode[] = [...nodes] || [];

  let previous: INode | undefined = undefined;

  while (stack.length) {
    const current = stack.pop()!;

    if (predicate(current, previous)) {
      return current;
    }

    current?.children?.forEach((n) => stack.push(n));

    previous = current;
  }

  return undefined;
};

const flattenTree = (nodes: INode[]): INode[] => {
  const stack: INode[] = [...nodes] || [];

  const accumulator: INode[] = [];

  while (stack.length) {
    const current = stack.pop()!;

    accumulator.push(current);

    current?.children?.forEach((n) => stack.push(n));
  }

  return accumulator;
};

export { dfs, flattenTree };

export const fistLetterCapitalize = (word: string) => {
  return word.length > 0 ? word[0].toUpperCase() + word.substring(1) : word;
}

export const getDatesFromDaysBack = (daysBack: number) => {
  const now = new Date();
  const fromDate = new Date(new Date().setDate(now.getDate() - daysBack));

  return [ formatDate(fromDate), formatDate(now)];
}

export const formatDate = (date: Date) => {
  return date.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
}

export const generateTooltipHtml = (changes: { [key: string]: string | number }[]): string => {
  if (changes) {
    const tooltip = changes.map(change => {
          return Object.keys(change).map((key: string) => `
              <div>${key}: ${change[key]}</div>
          `).join('&nbsp;');
      }).join('&nbsp;');

      return tooltip;
  }
  return '';
}

export const isDecimal = (number: number | undefined) => {
  if(!number || isNaN(number)) {
    return false;
  }
  const result = (number - Math.floor(number)) !== 0;

  return result;

}

export const isRegistered = () => {
  const token = localStorage.getItem('jwtToken');
  return !!token;
};


export const returnObjectByKey = (obj: any, term: string, result: any[] = [], exact?: boolean) => {
  const resultTest: any[] = [];
  if(!obj) {
    return;
  }
  if(Array.isArray(obj)) {
    obj.forEach((ob) => {
      returnObjectByKey(ob, term, result, exact);
    })
  }

  if(typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if(exact) {
        if(key === term) {
          result.push(obj[key])
        }
      } else {
        if(key.includes(term)) {
          result.push(obj[key])
        }

      }
      returnObjectByKey(obj[key], term, result, exact)
    })
  }
  return resultTest;
}
