import { Field, FieldArray, InjectedFormProps } from "redux-form";
import { GrowthSimulatorSaaSFormModel, NumericFormatType } from "../../../models/growthSimulator.models";
import React, { useCallback, useEffect, useState } from "react";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { EGoalType } from "../../../../../store/growthSimulator/growthSimulator.state";
import MUITooltip from "../../../../../components/MUITooltip/MUITooltip";
import { StyledReduxFormNumericFormat } from "../../../../../shared/ReduxFormNumericFormat/ReduxFormNumericFormat";
import { convertToNumericFormatType } from "../../../helpers/helpers";
import { returnObjectByKey } from "../../../../ScenarioBuilder/helpers/helpers";
import styled from "styled-components";
import { tooltipSelectorById } from "../../../../../store/growthSimulator/growthSimulator.selector";
import { useSelector } from "react-redux";

interface Props  {
  className?: string;
  scenarioId?: string;
  fieldsTest?: GrowthSimulatorSaaSFormModel;
  fieldName: string;
  handleIconClick?: () => void;
  goal?: EGoalType 
}


const StyledFormListItem = styled.li`
   color: #CFD5DF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

`

const StyledFormListItemLabel = styled.span`
  display: flex;
  gap: 20px;
  align-items: center;
`

const StyledFormList = styled.ul`
  margin: 10px;
  padding: 0;

  & > li:nth-child(even) {
    background: #1a273f !important;
  }
  
  & > li:nth-child(odd) {
    background: #29334b !important;
  }
`

const StyledPlanFormTable = styled.div`
  padding: 10px;
  width: 100%;
`

const StyledPlanFormTableHeader = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    text-align: center;
    grid-column-gap: 5px;
    margin: 0 5px;
    margin-bottom: 10px;
`

const StyledPlanFormTableHeaderItem = styled.li<{ shouldBeRed?: boolean}>`
    background: #46526e;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:${({ shouldBeRed}) => shouldBeRed ? 'orange' : '#e7e7e7'} ;
    font-weight: normal;
    font-size: 1rem;
    padding: 5px 0;
    cursor: pointer;
`

const StyledPlanFormTableRow = styled.ul`
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin: 5px 5px 0px 5px;
`

const StyledPlanFormTableSubRow = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`

const StyledPlanFormTableSubRowItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #233048;
`

const StyledPlanFormTableMultipleRowItem = styled.li`
    background: #233048;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-right: 5px;
`

const StyledFooterControls = styled.div`
  margin-top: 15px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
`
const StyledAddCircleOutlineButton = styled.button<{ disabled?: boolean}>`
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiSvgIcon-root {
    fill: #4A9CFB;
  }
  &:hover{
    cursor: ${({disabled}) => !disabled && "pointer"};
    opacity: 0.6;
  }
  opacity: ${({disabled}) => disabled && '0.6'};;
`
const StyledControlButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`
export const isValueZero = (value: NumericFormatType, allValues?: any, props?: any, name?: any )=> {
  
  return value && value.floatValue > 0 ? undefined : `Please fill the ${name.split('.').pop()?.split('_').join(' ')} field and click run again`;
};

const GrowthSimulatorSaaSGoal = styled(({ className, scenarioId, fieldsTest, fieldName, handleIconClick, goal = EGoalType.FreeStyle }: (Props)) => {
  const [propSum, setPropSum] = useState(0);
  useEffect(() => {
    if(fieldsTest) {
      const result: NumericFormatType[] = [];
      returnObjectByKey(fieldsTest, 'proportion', result)
      setPropSum(result.reduce((prev, curr) => prev + curr.floatValue, 0))
    }
  }, [fieldsTest]);
  
  const PROPORTION_ERROR_MESSAGE = 'The proportion column should always sum to 100%'
  const newMonthlyCustomers = useSelector(tooltipSelectorById(60, 10));
  const marketingBudgetTooltip = useSelector(tooltipSelectorById(60, 11));
  const growthGoalTooltip = useSelector(tooltipSelectorById(60, 12));

  const costPerLeadTooltip = useSelector(tooltipSelectorById(60, 20));
  const leadToCustomerConversionTooltip = useSelector(tooltipSelectorById(60, 30));
  const packageTooltip = useSelector(tooltipSelectorById(60, 40));
  const monthlySubscrChurnTooltip = useSelector(tooltipSelectorById(60, 50));
  const annualSubscrChurnTooltip = useSelector(tooltipSelectorById(60, 60));
  const PredictedMonthChurnTooltip = useSelector(tooltipSelectorById(60, 70));

  
  
  return (
    <>
      <section className={className}>
        <StyledFormList>
          <StyledFormListItem>
            {goal === EGoalType.FreeStyle && <>
            <MUITooltip title={newMonthlyCustomers}>
              <StyledFormListItemLabel>
                New monthly customers
              </StyledFormListItemLabel>
            </MUITooltip>
            <Field
              component={StyledReduxFormNumericFormat}
              thousandSeparator
              allowNegative={false}
              name={`${fieldName}.monthly_subscribers`}
              // name="monthly_subscribers"
              data-tour-id="growth_simulator_details_goal_sass_monthly_subscribers"
              validate={[isValueZero]}
              firstInput={true}
            /></>}
            {goal === EGoalType.BudgetGoal && <>
              <MUITooltip title={marketingBudgetTooltip}>
                <StyledFormListItemLabel>
                  Marketing Budget
                </StyledFormListItemLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                allowNegative={false}
                name={`${fieldName}.marketing_budget`}
                prefix="$"
                data-tour-id="growth_simulator_details_goal_sass_marketing_budget"
                validate={[isValueZero]}
                firstInput={true}
              /></>
            }
            {goal === EGoalType.DailyActiveUsersGoal && <>
              <MUITooltip title={growthGoalTooltip}>
                <StyledFormListItemLabel>
                  Growth goal
                </StyledFormListItemLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                allowNegative={false}
                name={`${fieldName}.growth_goal`}
                // name="monthly_subscribers"
                data-tour-id="growth_simulator_details_goal_sass_growth_goal"
                validate={[isValueZero]}
                firstInput={true}
              /></>
            }
          </StyledFormListItem>
            <StyledFormListItem>
              <MUITooltip title={costPerLeadTooltip}>
                <StyledFormListItemLabel>
                  Cost per lead
                </StyledFormListItemLabel>
              </MUITooltip>
              <Field
                component={StyledReduxFormNumericFormat}
                thousandSeparator
                prefix="$"
                allowNegative={false}
                name={`${fieldName}.cost_per_lead`}
                // name="cost_per_lead"
                data-tour-id="growth_simulator_details_goal_sass_cost_per_lead"
                validate={[isValueZero]}
              />
            </StyledFormListItem>

          <StyledFormListItem>
            <MUITooltip title={leadToCustomerConversionTooltip}>
              <StyledFormListItemLabel>
                Lead to customer conversion
              </StyledFormListItemLabel>
            </MUITooltip>
            <Field
              component={StyledReduxFormNumericFormat}
              thousandSeparator
              suffix="%"
              allowNegative={false}
              name={`${fieldName}.conversion`}
              // name="conversion"
              data-tour-id="growth_simulator_details_goal_sass_conversion"
              validate={[isValueZero]}
            />
          </StyledFormListItem>

          <StyledFormListItem>
            <StyledPlanFormTable >
              <StyledPlanFormTableHeader>
              <MUITooltip title={packageTooltip}>
                <StyledPlanFormTableHeaderItem>Package</StyledPlanFormTableHeaderItem>
              </MUITooltip>
                <StyledPlanFormTableHeaderItem>Plan</StyledPlanFormTableHeaderItem>
                <StyledPlanFormTableHeaderItem>Price</StyledPlanFormTableHeaderItem>
                <StyledPlanFormTableHeaderItem>Proportion</StyledPlanFormTableHeaderItem>
              </StyledPlanFormTableHeader>

              {[
                'Starter',
                // 'Beginner'
              ].map(pckg => (
                <StyledPlanFormTableRow key={pckg} data-tour-id={pckg === 'Starter' ? 'growth_simulator_details_goal_sass_pricing_plan' : null}>
                  <StyledPlanFormTableMultipleRowItem>{pckg}</StyledPlanFormTableMultipleRowItem>
                  <li>
                    <StyledPlanFormTableSubRow>
                      {['Monthly', 'Annually'].map(period => {
                        return(
                        <>
                        <StyledPlanFormTableSubRowItem>{period}</StyledPlanFormTableSubRowItem>
                        <StyledPlanFormTableSubRowItem>
                          <Field
                            component={StyledReduxFormNumericFormat}
                            thousandSeparator
                            allowNegative={false}
                            prefix="$"
                            name={`${fieldName}.${pckg.toLocaleLowerCase()}.${period.toLocaleLowerCase()}.price`}
                            validate={[isValueZero]}
                          />
                        </StyledPlanFormTableSubRowItem>
                        <StyledPlanFormTableSubRowItem>
                          <Field
                            component={StyledReduxFormNumericFormat}
                            thousandSeparator
                            allowNegative={false}
                            suffix="%"
                            // onFocus={}
                            name={`${fieldName}.${pckg.toLocaleLowerCase()}.${period.toLocaleLowerCase()}.proportion`}
                            validate={[isValueZero]}
                          />
                        </StyledPlanFormTableSubRowItem>
                        
                        </>
                      )}
                    )}
                    </StyledPlanFormTableSubRow>
                  </li>
                </StyledPlanFormTableRow>
              ))}
              <StyledPlanFormTableHeader style={{ marginTop: '10px'}}>
                <StyledPlanFormTableHeaderItem>
                  <StyledAddCircleOutlineButton >
                      <AddCircleOutlineIcon fontSize='large' onClick={handleIconClick}/>
                  </StyledAddCircleOutlineButton>
                </StyledPlanFormTableHeaderItem>
                <StyledPlanFormTableHeaderItem></StyledPlanFormTableHeaderItem>
                <StyledPlanFormTableHeaderItem></StyledPlanFormTableHeaderItem>
                <StyledPlanFormTableHeaderItem shouldBeRed={propSum !== 100 && propSum > 0}> { (propSum !== 100 && propSum > 0) && PROPORTION_ERROR_MESSAGE }</StyledPlanFormTableHeaderItem>
              </StyledPlanFormTableHeader>
            </StyledPlanFormTable>
          </StyledFormListItem>
          <StyledFormListItem>
            <MUITooltip title={monthlySubscrChurnTooltip}>
              <StyledFormListItemLabel>
                Monthly Subscribers Churn
              </StyledFormListItemLabel>
            </MUITooltip>
            <Field
              component={StyledReduxFormNumericFormat}
              thousandSeparator
              allowNegative={false}
              suffix="%"
              // name="monthly_subscribers_churn"
              name={`${fieldName}.monthly_subscribers_churn`}

              data-tour-id="growth_simulator_details_goal_sass_monthly_subscribers_churn"
            />
          </StyledFormListItem>
          <StyledFormListItem>
            <MUITooltip title={annualSubscrChurnTooltip}>
              <StyledFormListItemLabel>
                Annual Subscribers Churn
              </StyledFormListItemLabel>
            </MUITooltip>
            <Field
              component={StyledReduxFormNumericFormat}
              thousandSeparator
              suffix="%"
              allowNegative={false}
              name={`${fieldName}.annual_subscribers_churn`}
              data-tour-id="growth_simulator_details_goal_sass_annual_subscribers_churn"
            />
          </StyledFormListItem>

          <StyledFormListItem>
          <MUITooltip title={PredictedMonthChurnTooltip}>
            <StyledFormListItemLabel>
              Predicted Months
            </StyledFormListItemLabel>
          </MUITooltip>
            <Field
              component={StyledReduxFormNumericFormat}
              thousandSeparator
              allowNegative={false}
              data-tour-id="growth_simulator_details_goal_sass_predicted_months"
              name={`${fieldName}.predicted_months`}
              validate={[isValueZero]}
            />
          </StyledFormListItem>

        </StyledFormList>
      </section>
    </>
  );
})`
  box-shadow: inset 5px 5px 12px #00000066;
  background: #19253c;
  padding: 1px 0;
`;

const SaaSForm = ({ handleSubmit }: SaaSFormProps) => {
  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <FieldArray name="members" component={GrowthSimulatorSaaSGoal} />
    </form>
  );
};

// export default reduxForm<{members: GrowthSimulatorSaaSFormModel[]}, Props>({
//   form: 'gsf.goals.sass',
//   initialValues: {
//     members: [
//       {
//         monthly_subscribers: convertToNumericFormatType(0),
//         cost_per_lead: convertToNumericFormatType(0, '$'),
//         conversion: convertToNumericFormatType(0, undefined, '%'),
//         monthly_subscribers_churn:  convertToNumericFormatType(0, undefined, '%'),
//         annual_subscribers_churn:  convertToNumericFormatType(0, undefined, '%'),
//         predicted_months:  convertToNumericFormatType(0),
//         starter: {
//           monthly: {
//             price: convertToNumericFormatType(0, '$'),
//             proportion: convertToNumericFormatType(0, undefined, '%'),
//           },
//           annually: {
//             price: convertToNumericFormatType(0, '$'),
//             proportion: convertToNumericFormatType(0, undefined, '%'),
//           }
//         },
//       beginner: {
//           monthly: {
//             price: convertToNumericFormatType(0, '$'),
//             proportion: convertToNumericFormatType(0, undefined, '%'),
//           },
//           annually: {
//             price: convertToNumericFormatType(0, '$'),
//             proportion: convertToNumericFormatType(0, undefined, '%'),
//           }
//         }
//       }
//     ]
// }})(SaaSForm)

export default React.memo(GrowthSimulatorSaaSGoal);

export const DEFAULT_SAAS = {
  monthly_subscribers: convertToNumericFormatType(0),
  growth_goal: convertToNumericFormatType(0),
  marketing_budget:convertToNumericFormatType(0, '$'),
  cost_per_lead: convertToNumericFormatType(0, '$'),
  conversion: convertToNumericFormatType(0, undefined, '%'),
  monthly_subscribers_churn:  convertToNumericFormatType(0, undefined, '%'),
  annual_subscribers_churn:  convertToNumericFormatType(0, undefined, '%'),
  predicted_months:  convertToNumericFormatType(0),
  starter: {
    monthly: {
      price: convertToNumericFormatType(0, '$'),
      proportion: convertToNumericFormatType(0, undefined, '%'),
    },
    annually: {
      price: convertToNumericFormatType(0, '$'),
      proportion: convertToNumericFormatType(0, undefined, '%'),
    }
  },
  beginner: {
    monthly: {
      price: convertToNumericFormatType(0, '$'),
      proportion: convertToNumericFormatType(0, undefined, '%'),
    },
    annually: {
      price: convertToNumericFormatType(0, '$'),
      proportion: convertToNumericFormatType(0, undefined, '%'),
    }
  }
}

interface SaaSFormProps extends InjectedFormProps<{ members: GrowthSimulatorSaaSFormModel[]; }, {}> {
}