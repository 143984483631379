import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Helmet } from 'react-helmet';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Logo from '../Logo/Logo';
import Menu from '../Menu/Menu';
import Revenue from '../Revenue/Revenue';
import companysActual from '../../assets/companys_actual.png';
import { device } from '../../helpers/device';
import { sharedWhatIfDescriptionSelector } from '../../store/shared/shared.selector';
import simpoolsExtra from '../../assets/simpools_extra.png';
import styled from 'styled-components';
import totalPotential from '../../assets/total_potential.png';
import { trackSource } from '../../mixpanel';
import { useApplicationContext } from '../../Context';
import { useSelector } from 'react-redux';

export interface ITopNavProps {
  className?: string
}

const RevenueWrapper = styled.ul`
    list-style-type: none;
    display: grid;
    grid-template-columns: 40% 5% 25% 5% 25%;
    padding: 0;
    width: 100%;
    padding-right: 30px;

    & > li {
        &:nth-of-type(2) {
          & > span {
          padding-left: 15px;
        }
      }
      display: flex;
      align-items: center;
      justify-content: center;
    }
`

const StyledSymbol = styled.span<{ textAlign: 'left' | 'center' }>`
  font: normal normal bold 2rem Segoe UI;
  letter-spacing: 0px;
  color: #E7E7EB;
  text-shadow: 0px 0px 11px #000000;
  width: 100%;
  text-align: ${({ textAlign }) => textAlign}; 
`

const StyledTitle = styled.h2<{ isScenario?: boolean }>`
  color: #FFFEDF;
  margin: 0;
  font-size: ${({ isScenario }) => isScenario ? '1.9rem' : '2rem'};
  font-weight: 500;
  
  b {
    margin-left: ${({ isScenario }) => isScenario ? '10px' : '0px'};
    color: #4A9CFB;
    font-size: ${({ isScenario }) => isScenario ? '2.1rem' : '2rem'};
  }
`

const StyledTitleContainer = styled.li`
  height: auto !important;
  background: inherit !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  .simulation-tooltip {
    position: relative;
    white-space: nowrap;
    display: flex;
    margin-left: 10px;
    margin-top: 4px;
    cursor: pointer;
    .html-tooltip {
        
        left:28px;
        /* width: fit-content; */
        bottom: -36px;
    }
    .info-icon{
        fill: white;
        width: 1.3em;
        height: 1.3em;
    }
    &:hover { 
        .html-tooltip {
            visibility: visible;
            opacity: 1;
           
        }
    }
}
`

const TopNavContainer = styled.ul<{ expanded: boolean, isScenario: boolean }>`
  list-style-type: none;
  margin: 0;
  padding: 0;

  height: 100%;

  display: grid;
  grid-template-columns: ${({ expanded, isScenario }) => expanded ? `0.3fr auto 0.4fr` : isScenario ? '0.85fr 0.15fr' : `0.3fr auto 0.3fr`};
  grid-template-rows: 100%;

  & > li {
    position: relative;
    &:not(:first-of-type):not(:last-of-type) {
      background: #404163;
      height: 130px;
      box-shadow: 0px 3px 14px #0000006E;
      border-radius: 0px 0px 70px 70px;

      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
    }

    &:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:first-of-type {
      display: flex;
      align-items: center;
      padding-left: ${({ isScenario }) => isScenario ? `40px` : `20px`};
      .back-icon {
        font-size: 4rem;
        color: hsla(360, 100%, 100%, 0.4);
        margin-right: 26px;
        &:hover{
          cursor: pointer;
          color: hsla(360, 100%, 100%, 1);
        }
      }
    }
    .company-title {
      position: absolute;
      left: 111px;
      color: #FFFEDF;
      margin: 0;
      font-size: 2rem;
      font-weight: 500;
      margin-right: 30px;
      top: calc(50% - 0.8rem);
      width: max-content
  }
  }
`;

const titleMap: Record<string, string> = {
  "/cohort-analysis": "Cohort Analysis",
  "/user-acquisition": "User Acquisition Analysis",
  '/daily-dashboard': 'Daily Dashboard',
  '/daily-kpis': 'Daily KPIs',
  '/game-analysis': 'Game Analysis',
  '/segmentation': 'Segmentation',
  '/monitor': 'Monitoring',
  '/view-all': 'View All',
  '/whatif-results': 'What if',
  '/cohorts-results': 'Cohorts',
}

const BoldStyled = styled.b`
  color: #FFFEDF;
  margin: 0;
  font-size: 2rem;
  font-weight: 500;
`;

const AuxiliaryHeaderContainer = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
    margin: 0 10%;
    width: 100%;
    grid-column: span 2;
`

const AuxiliaryHeaderContainerItem = styled.li`
  display: flex;
  align-items: center;
`

const SimulationTooltip = styled.div`
  position: relative;
  white-space: nowrap;
  display: flex;
  margin-left: 10px;
  margin-top: 4px;

  cursor: pointer;

  .html-tooltip {
      left:28px;
      bottom: -36px;
  }
  .info-icon{
      fill: white;
      width: 1.3em;
      height: 1.3em;
  }

  &:hover { 
      .html-tooltip {
          visibility: visible;
          opacity: 1;
      }
  }
  
`
const routesToExclude = [
  'growth-simulator-v2',
  'mobile-backdrop'
];
const TopNav: React.FC<ITopNavProps> = ({ className }: ITopNavProps) => {
  const [{ topBarReportDataQuery, title }] = useApplicationContext();
  const [pageTitle, setPageTitle] = useState('');

  const history = useHistory();
  const location = useLocation();
  const [expanded, setExpanded] = useState(true);

  const [hide, setHide] = useState(false);
  const { search } = useLocation();
  const source = new URLSearchParams(search).get('src') || undefined;
  
  useEffect(() => {
    if(source) {
      trackSource(source)
    }
  }, []);

  useEffect(() => {
    setExpanded(!Object.keys(titleMap).includes(history.location.pathname));
    setPageTitle(titleMap[history.location.pathname]);

    setHide(
      location.pathname?.includes('whatif-results') ||
      location.pathname?.includes('cohorts-results') ||
      location.pathname?.includes('segment-results')
    );
  }, []);

  const tooltipDescription = useSelector(sharedWhatIfDescriptionSelector);

  const generateScenarioTitle = () => {
    const search = history.location.search;
    const params = new URLSearchParams(search);
    const whatIf = params.get('whatIfId');
    const scenarioName = params.get('scenario');
    const segmentName = params.get('segment_name');

    if (location.pathname?.includes('whatif-results')) {
      return <AuxiliaryHeaderContainer>
        <AuxiliaryHeaderContainerItem>
          <Logo></Logo>
        </AuxiliaryHeaderContainerItem>
        <AuxiliaryHeaderContainerItem>
          <BoldStyled style={{ color: 'rgb(74, 156, 251)', marginRight: 10 }}>Scenario Name:</BoldStyled>
          <BoldStyled style={{ marginRight: 10 }}>{scenarioName}</BoldStyled>
          <BoldStyled style={{ color: 'rgb(74, 156, 251)', marginRight: 10 }}>What If Id:</BoldStyled>
          <BoldStyled>{whatIf}</BoldStyled>

          <SimulationTooltip>
            <InfoOutlinedIcon className="info-icon" />
          </SimulationTooltip>
        </AuxiliaryHeaderContainerItem>
      </AuxiliaryHeaderContainer>
    } else if (location.pathname?.includes('cohorts-results')) {
      return <AuxiliaryHeaderContainer>
        <AuxiliaryHeaderContainerItem>
          <Logo></Logo>
        </AuxiliaryHeaderContainerItem>
        <AuxiliaryHeaderContainerItem>
          <BoldStyled>Cohorts Comparison Dashboard</BoldStyled>
        </AuxiliaryHeaderContainerItem>
      </AuxiliaryHeaderContainer>
    } else if (location.pathname?.includes('segment-results')) {
      return <AuxiliaryHeaderContainer>
        <AuxiliaryHeaderContainerItem>
          <Logo></Logo>
        </AuxiliaryHeaderContainerItem>
        <AuxiliaryHeaderContainerItem>
          <BoldStyled >Segment Analysis - {segmentName}</BoldStyled>
        </AuxiliaryHeaderContainerItem>
      </AuxiliaryHeaderContainer>
    }else {
      return <li>
        <Logo></Logo>
      </li>
    }
  }

  if(
    location.pathname.toLocaleLowerCase().includes('growth-simulator-v2') ||
    location.pathname.toLocaleLowerCase().includes('mobile-backdrop') ||
    location.pathname.toLocaleLowerCase().includes('landing') ||
    location.pathname.toLocaleLowerCase().includes('subscription') ||
    location.pathname.toLocaleLowerCase().includes('register') ||
    location.pathname.toLocaleLowerCase().includes('login') || 
    location.pathname.toLocaleLowerCase().includes('complete') || 
    location.pathname.toLocaleLowerCase().includes('verify-callback') ||
    location.pathname.toLocaleLowerCase().includes('forgot-password') ||
    location.pathname.toLocaleLowerCase().includes('oauth-callback')
    ) {
    return null;
  }

  return <nav className={className}>
    <Helmet>
      <style>
        {`body { margin-top: 130px }`}
      </style>
    </Helmet>
    <TopNavContainer expanded={expanded} isScenario={
      location.pathname?.includes('whatif-results') ||
      location.pathname?.includes('cohorts-results')
    }>
      {!hide && <li>
        <Menu></Menu>
        {expanded ? null : <Revenue
          title="Company's Actual"
          amount={topBarReportDataQuery?.data?.actual_rev!}
          ltv={topBarReportDataQuery?.data?.avg_ltv!}
          expanded
          users={topBarReportDataQuery?.data?.users}
          src={title?.icon || companysActual}
          variant='yellow'
          collapsed
          isBorder
          gameTitle={title?.name}
        ></Revenue>}
      </li>}

      {
        hide ? null : expanded ? (<li>
          <RevenueWrapper>
            <li>
              <Revenue
                title="Company's Actual"
                amount={topBarReportDataQuery?.data?.actual_rev!}
                ltv={topBarReportDataQuery?.data?.avg_ltv!}
                expanded
                users={topBarReportDataQuery?.data?.users}
                src={title?.icon || companysActual}
                variant='yellow'
                isBorder
              ></Revenue>
            </li>
            <li>
              <StyledSymbol textAlign="left">+</StyledSymbol>
            </li>
            <li>
              <Revenue
                title="Simpool's extra"
                amount={topBarReportDataQuery?.data?.delta_to_potential!}
                ltv={topBarReportDataQuery?.data?.ltv_to_improve!}
                src={simpoolsExtra}
                variant='blue'
              ></Revenue>
            </li>
            <li>
              <StyledSymbol textAlign="center">=</StyledSymbol>
            </li>
            <li>
              <Revenue
                title="Total potential"
                amount={topBarReportDataQuery?.data?.max_potential!}
                ltv={topBarReportDataQuery?.data?.top_ltv!}
                src={totalPotential}
                variant='green'
              ></Revenue>
            </li>
          </RevenueWrapper>
          {/* TODO(PPavlov): Refactor, remove StyledTitleContainer */}
        </li>) : <StyledTitleContainer> 
          <StyledTitle>
            <b>Simpool</b> Analytics - {pageTitle}
          </StyledTitle>
        </StyledTitleContainer>}
      {generateScenarioTitle()}

    </TopNavContainer>
  </nav >
};

export default styled(TopNav)`
  @media ${device.laptopS} { 
  }

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  background: #3D3E5A;
  box-shadow: 0px 3px 14px #0000006e;
  border-radius: 0px 0px 50px 50px;

  height: 100px;
  margin-bottom: 30px;
`;
