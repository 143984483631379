import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import {
  Menu,
  MenuButton,
  MenuItem,
  SubMenu
} from '@szhsin/react-menu';
import {
  menuItemSelector,
  menuSelector
} from "@szhsin/react-menu/style-utils";

import SettingsIcon from '@mui/icons-material/Settings';
import { CSVLink } from "react-csv";
import styled from 'styled-components';
import { IChartTreeItem } from '../../queries/useGetChartTreeQuery';

export interface IDropdown {
  className?: string;
  onChange?: (item: IChartTreeItem) => void;
  initialReportId?: number;
  iconSize?: number;
  values?: GroupByType[]
  onGroupByChange?: (item: string | undefined) => void;
  exportData?: any;
  headline?: string;
}

export type GroupByType = {
  ['name']: string
}
const SettingsDropDown = ({ className, onChange, iconSize = 2, values = [], onGroupByChange = () => {}, exportData = [], headline}: IDropdown) => {
  return <StyledMenu offsetY={5} menuButton={<StyledMenuButon><SettingsIcon style={{ fontSize: `${iconSize}rem`}}/></StyledMenuButon>} transition>
    <StyledSubMenu offsetX={14} offsetY={-12} label="Group by" className={ values.length === 0 ? 'disabled' : ''}>
      <ul className="menu-wrapper">
        <MenuItem  onClick={() => onGroupByChange(undefined)}>
            All
        </MenuItem>
        {values?.map((v, index) => {
          return <MenuItem key={index} onClick={() => onGroupByChange(v.name)}>
            {v.name}
          </MenuItem>
        })}
      </ul>
    </StyledSubMenu>
    <MenuItem >Change chart</MenuItem>
    <MenuItem disabled={(exportData.length === 0)}>
      {exportData.length > 0 && <CSVLink data={exportData} target="_blank" className='export' filename={`${headline}-${new Date().toLocaleTimeString()}.csv`}>
        Export
      </CSVLink>}
    </MenuItem>
  </StyledMenu>
}

export default SettingsDropDown;

export const StyledMenu = styled(Menu)`
  
  ${menuSelector.name} {
    font-size: 1.2rem;
    background: #3D3E5A;
    border: 1px solid #212239;
    border-radius: 21px;
    box-shadow: inset 0px 0px 9px #000000D9;
    padding: 15px 9px;
    color: #E2E2E2;
    
    a {
      text-decoration: none;
      color: inherit;
    }
    ${menuItemSelector.hover} {
      background: #5e5f76;
    }
  }
`
const StyledSubMenu = styled(SubMenu)`
  &.disabled { 
    pointer-events: none;
    color: hsla(0, 0%, 39%, 1);
  }
  ${menuSelector.name} {
    font-size: 1.2rem;
    background: #3D3E5A;
    border: 1px solid #212239;
    color: hsla(0, 0%, 89%, 1);
    border-radius: 21px;
    box-shadow: inset 0px 0px 9px #000000D9;
    padding: 11px 11px;
    .menu-wrapper {
      padding: 0px 5px;
      max-height:200px;
      overflow: auto;
      list-style: none;
      &::-webkit-scrollbar {
        width: 4px;              
      }
  
      &::-webkit-scrollbar-track {
        background: #424252;        
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: #777782;    
        border: 1px solid #424252; 
      }
      ${menuItemSelector.name} {
        // border-radius: 6px;
        padding: 0.375rem 0.625rem;
      }
    }
  }
`
export const StyledMenuButon = styled(MenuButton)`
  color: #E2E2E2;
  font-size: 1.2rem;
  background: none;
  cursor: pointer;
	outline: inherit;
  border:none;
  line-height: 2.28rem;
  display: flex;
  &:hover:not(.szh-menu-button--open) {
    &.szh-menu-button {
    svg {
      opacity: 0.8;
    }

  }
  }
  &.szh-menu-button--open {
    svg {
      opacity: 1;
    }

  }
  svg {
    // fill: #918F8F;
    fill: #FFFFFF;
    opacity: 0.5;
    font-size: 2rem;
    line-height: 2rem;
  }
`