import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as B2C } from '../../../../assets/b2c.svg';
import { ReactComponent as B2B } from '../../../../assets/test.svg';
import { setApplicationType, setBusinessType, setGoalType, setPointOfViewType, setRevenueModelType } from '../../../../store/growthSimulator/growthSimulator.actions';
import { EGoalType, EPointOfViewType } from '../../../../store/growthSimulator/growthSimulator.state';
import GrowthSimulatorStepsWrapper from "../GrowthSimulatorStepsWrapper";
import { WizardChoiceCard } from "../WizardChoiseCard/WizardChoiseCard";
import { ReactComponent as Gaming } from '../../../../assets/gaming.svg';
import { ReactComponent as Ecomerce } from '../../../../assets/ecommerce.svg';
import { ReactComponent as Fintech } from '../../../../assets/fintech.svg';
import { ReactComponent as Saas } from '../../../../assets/sass.svg';
import { ReactComponent as InAppPurchase } from '../../../../assets/inapppurchase.svg';
import { ReactComponent as AddMonetization } from '../../../../assets/addmonetization.svg';
import { ReactComponent as Hybrid } from '../../../../assets/hybrid.svg';
import { ReactComponent as Subscription } from '../../../../assets/subscription.svg';
import { tooltipSelectorById } from '../../../../store/growthSimulator/growthSimulator.selector';
import { trackApplicationType, trackBusinessType, trackGoal, trackPointOfView, trackRevenueType } from '../../../../mixpanel';
import { ReactComponent as UserAcquisition } from '../../../../assets/User_Acquisition_Persona.svg';
import { ReactComponent as Product } from '../../../../assets/Product_Persona.svg';
import { ReactComponent as Monetization } from '../../../../assets/Monetization_Persona.svg';

export enum BusinessType {
  B2B = 'B2B',
  B2C = 'B2C',
};

export enum ApplicationType {
Gaming = 'Gaming',
eCommerce = 'eCommerce',
Fintech = 'Fintech',
SaaS = 'SaaS'
};

export enum RevenueModelType {
InAppPurchase = 'In-app Purchase',
AddMonetization = 'Add Monetization',
Hybrid = 'Hybrid',
Subscription = 'Subscription'
};

const GS_WIZARD_STEP_ONE_TITLE = 'What kind of business do you have?';
const GS_WIZARD_STEP_TWO_TITLE = 'What is your website or application genre?';
const GS_WIZARD_STEP_THREE_TITLE = 'What is your application\'s revenues model?';
const GS_WIZARD_STEP_FOUR_TITLE = 'What is your point of view?';
const GS_WIZARD_STEP_FIVE_TITLE = 'What is your goal?';


const GrowthSimulatorStepOne = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const widgetTitle = useSelector(tooltipSelectorById(100, 10));

  const handleGoToTheNexStep = () => {
    history.push(`/growth-simulator-v2/wizard/step-2`);
  };

  const handleItemChose = (item: BusinessType) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setBusinessType(item));
    handleGoToTheNexStep();
    //Mixpanel
    trackBusinessType(item);
  };
  return (
    <GrowthSimulatorStepsWrapper title={widgetTitle || GS_WIZARD_STEP_ONE_TITLE}  step={1} cards={[
      <WizardChoiceCard key={BusinessType.B2C} title={BusinessType.B2C} svgComponent={<B2C />} onClick={handleItemChose(BusinessType.B2C)}/>,
      <WizardChoiceCard key={BusinessType.B2B} title={BusinessType.B2B} svgComponent={<B2B />} onClick={handleItemChose(BusinessType.B2B)} comingSoon/>
    ]}/>
  );
};

const GrowthSimulatorStepTwo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const widgetTitle = useSelector(tooltipSelectorById(100, 20));

  const handleGoToTheNexStep = () => {
    history.push(`/growth-simulator-v2/wizard/step-3`);
  };

  const handleGoToThePreviousStep = () => {
    dispatch(setBusinessType(null));
    history.push(`/growth-simulator-v2/wizard/step-1`);
  };

  const handleItemChose = (item: ApplicationType) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setApplicationType(item));
    handleGoToTheNexStep();
    //Mixpanel
    trackApplicationType(item);
  };
  return (
    <GrowthSimulatorStepsWrapper
      step={2}
      title={widgetTitle || GS_WIZARD_STEP_TWO_TITLE}
      goBackHandler={handleGoToThePreviousStep}
      cards={[
        <WizardChoiceCard key={ApplicationType.Gaming} title={ApplicationType.Gaming} svgComponent={<Gaming />} onClick={handleItemChose(ApplicationType.Gaming)}/>,
        <WizardChoiceCard key={ApplicationType.eCommerce} title={ApplicationType.eCommerce} svgComponent={<Ecomerce />} onClick={handleItemChose(ApplicationType.eCommerce)} comingSoon/>,
        <WizardChoiceCard key={ApplicationType.Fintech} title={ApplicationType.Fintech} svgComponent={<Fintech />} onClick={handleItemChose(ApplicationType.Fintech)} comingSoon/>,
        <WizardChoiceCard key={ApplicationType.SaaS} title={ApplicationType.SaaS} svgComponent={<Saas />} onClick={handleItemChose(ApplicationType.SaaS)} comingSoon/>
      ]}/>
  );
};

const GrowthSimulatorStepThree = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const widgetTitle = useSelector(tooltipSelectorById(100, 30));

  const handleGoToTheNexStep = () => {
    history.push(`/growth-simulator-v2/wizard/step-4`);
  };

  const handleGoToThePreviousStep = () => {
    dispatch(setApplicationType(null));
    history.push(`/growth-simulator-v2/wizard/step-2`);
  };

  const handleItemChose = (item: RevenueModelType) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setRevenueModelType(item));
    handleGoToTheNexStep();
    //Mixpanel
    trackRevenueType(item);
  };
  return (
    <GrowthSimulatorStepsWrapper
      step={3}
      title={widgetTitle || GS_WIZARD_STEP_THREE_TITLE}
      goBackHandler={handleGoToThePreviousStep}
      cards={[
        <WizardChoiceCard key={RevenueModelType.InAppPurchase} title={RevenueModelType.InAppPurchase} svgComponent={<InAppPurchase />} onClick={handleItemChose(RevenueModelType.InAppPurchase)}/>,
        <WizardChoiceCard key={RevenueModelType.AddMonetization} title={RevenueModelType.AddMonetization} svgComponent={<AddMonetization />} onClick={handleItemChose(RevenueModelType.AddMonetization)} comingSoon/>,
        <WizardChoiceCard key={RevenueModelType.Hybrid} title={RevenueModelType.Hybrid} svgComponent={<Hybrid />} onClick={handleItemChose(RevenueModelType.Hybrid)} comingSoon/>,
        <WizardChoiceCard key={RevenueModelType.Subscription} title={RevenueModelType.Subscription} svgComponent={<Subscription />} onClick={handleItemChose(RevenueModelType.Subscription)} comingSoon/>
      ]}/>
  );
};

const GrowthSimulatorStepFour = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const widgetTitle = useSelector(tooltipSelectorById(100, 40));

  const handleGoToTheNexStep = () => {
    history.push(`/growth-simulator-v2/wizard/step-5`);
  };

  const handleGoToThePreviousStep = () => {
    dispatch(setRevenueModelType(null));
    history.push(`/growth-simulator-v2/wizard/step-3`);
  };

  const handleItemChose = (item: EPointOfViewType) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setPointOfViewType(item));
    handleGoToTheNexStep();
    //Mixpanel
    trackPointOfView(item);
  };
  return (
    <GrowthSimulatorStepsWrapper
      step={4}
      title={widgetTitle || GS_WIZARD_STEP_FOUR_TITLE}
      goBackHandler={handleGoToThePreviousStep}
      cards={[
        <WizardChoiceCard
          key={EPointOfViewType.UserAcquisition}
          title={EPointOfViewType.UserAcquisition}
          onClick={handleItemChose(EPointOfViewType.UserAcquisition)}
          svgComponent={<UserAcquisition/>} />,
        <WizardChoiceCard
          key={EPointOfViewType.Monetization}
          title={EPointOfViewType.Monetization}
          onClick={handleItemChose(EPointOfViewType.Monetization)}
          svgComponent={<Monetization/>} />,
        <WizardChoiceCard
        key={EPointOfViewType.Product}
        title={EPointOfViewType.Product} 
        onClick={handleItemChose(EPointOfViewType.Product)}
        svgComponent={<Product/>} />,
      ]}/>
  );
};

const GrowthSimulatorStepFive = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const widgetTitle = useSelector(tooltipSelectorById(100, 50));

  const dauGoalTooltip = useSelector(tooltipSelectorById(50, 10));
  const budgetGoalTooltip = useSelector(tooltipSelectorById(50, 20));
  const freeStyleTooltip = useSelector(tooltipSelectorById(50, 30));
  
  const handleGoToTheNexStep = ()  => {
    history.push('/growth-simulator-v2/details');
  };

  const handleGoToThePreviousStep = () => {
    dispatch(setPointOfViewType(null));
    history.push(`/growth-simulator-v2/wizard/step-4`);
  };

  const handleItemChose = (item: EGoalType) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setGoalType(item));
    handleGoToTheNexStep();
    //Mixpanel
    trackGoal(item);
  };
  return (
    <GrowthSimulatorStepsWrapper
      step={5}
      title={widgetTitle || GS_WIZARD_STEP_FIVE_TITLE}
      goBackHandler={handleGoToThePreviousStep}
      cards={[
        <WizardChoiceCard key={EGoalType.DailyActiveUsersGoal} title={EGoalType.DailyActiveUsersGoal} onClick={handleItemChose(EGoalType.DailyActiveUsersGoal)} tooltip={dauGoalTooltip}/>,
        <WizardChoiceCard key={EGoalType.BudgetGoal} title={EGoalType.BudgetGoal} onClick={handleItemChose(EGoalType.BudgetGoal)} tooltip={budgetGoalTooltip}/>,
        <WizardChoiceCard key={EGoalType.FreeStyle} title={EGoalType.FreeStyle}  onClick={handleItemChose(EGoalType.FreeStyle)} tooltip={freeStyleTooltip}/>,
      ]}/>
  );
};

export { 
  GrowthSimulatorStepOne,
  GrowthSimulatorStepTwo,
  GrowthSimulatorStepThree,
  GrowthSimulatorStepFour,
  GrowthSimulatorStepFive
};
