import { ReactElement, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useApplicationContext } from '../../Context';
import { AuthService } from '../../services/auth.service';
import { getUserOnboarding } from '../../store/onboarding/onboarding.actions';
import { userSelector } from '../../store/onboarding/onboarding.selectors';

interface Props {
  children: ReactElement;
}

const AuthenticationProvider = ({ children }: Props) => {
  const user = useSelector(userSelector);
  const [, setApplicationContext] = useApplicationContext();
  const dispatch = useDispatch();

  useEffect(() => {
    const jwtToken = localStorage.getItem('jwtToken');

    if (jwtToken) {
      const token = JSON.parse(jwtToken);
      const parsedUser = AuthService.ParseJwtToken(token.access_token?.slice());
      setApplicationContext((state) => ({
        ...state,
        authentication: {
          user: parsedUser,
          parameters: { access_token: token.access_token?.slice() },
        },
      }));
      dispatch(getUserOnboarding({ userId: Number(parsedUser.simpool?.simpool_id) }));
    } else {
      localStorage.removeItem('jwtToken');
    }
  }, []);

  return children;
};

export default AuthenticationProvider;
