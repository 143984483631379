import { IGetReportDataResponse, useGetReportDataQuery } from '../../../queries/useGetReportDataQuery';
import React, { useEffect, useState } from 'react';

import ChartContainer from '../ChartContainer/ChartContainer';
import { IFilterBarPayload } from '../../FilterBar/FilterBar';
import { IGetWidgetsResponse } from '../../../queries/useGetWidgetsQuery';
import Table from '../../../shared/Table/Table';
import { handleHeadlineHelper } from '../../../pages/Analytics/Analytics';
import { sharedAccountIdSelector } from '../../../store/shared/shared.selector';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

export interface ITableChartProps {
  widget: IGetWidgetsResponse,
  titleId?: number,
  filter?: IFilterBarPayload,
  cross_title?: boolean;
  whatIfId?: number;
  extraFilters?: {
    query?: string;
  }
}

const TableContainer = styled.div`
  width: 100%;
  height: 300px;
`

const TableChart = ({ widget, titleId, filter, cross_title = true, whatIfId, extraFilters }: ITableChartProps) => {
  const [groupByFilter, setGroupByFilter ] = useState<string[] | undefined>();
  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>();
  const accountIdSelector = useSelector(sharedAccountIdSelector);

  const getReportDataQueryResponse = useGetReportDataQuery(
    {
      report_id: widget?.report_id,
      dashboard_id: widget?.dashboard_id,
      title_id: widget?.title_id || titleId,
      days_back: filter?.daysBack || 60,
      date_from: (filter?.dateFrom && String(filter?.dateFrom)) || "-1",
      date_to: (filter?.dateTo && String(filter?.dateTo)) || "-1",
      country: filter?.country,
      platform: filter?.platform,
      source: filter?.source,
      group_by: groupByFilter,
      currency: currencyFilter,
      account_id: cross_title ? accountIdSelector : undefined,
      whatif_id: whatIfId,
      extra_filters: extraFilters
    }, `TableChart-
        ${currencyFilter && currencyFilter}-
        ${widget?.report_id}-${widget?.title_id || titleId}-
        ${filter?.dateFrom}-${filter?.daysBack}-
        ${filter?.dateTo}-${filter?.country}-
        ${filter?.platform}-${filter?.source}-
        ${groupByFilter?.toString()}-
        ${extraFilters?.toString()}-
        ${cross_title && `account_id: ${accountIdSelector}`}-${whatIfId}`);

  const [tableChartData, setTableChartData] = useState<{} | any[] | undefined>();
  const [reportData, setReportData] = useState<IGetReportDataResponse[]>();

  useEffect(() => {
    if (!widget || !getReportDataQueryResponse || getReportDataQueryResponse.isLoading) {
      return;
    }

    const { data } = getReportDataQueryResponse;

    if(JSON.stringify(reportData) === JSON.stringify(data)) {
      return;
    }

    setTableChartData(data);
    setReportData(data);
  }, [getReportDataQueryResponse])

  const handleGroupBy = (item: string | undefined) => {
    item ? setGroupByFilter([item]) : setGroupByFilter(undefined);
  }

  const handleCurrency = (item: string) => {
    setCurrencyFilter(item);
  }

  return <ChartContainer 
    loading={getReportDataQueryResponse?.isLoading} 
    headline={handleHeadlineHelper(widget?.headline, groupByFilter, currencyFilter)}
    groupBy={widget?.group_by || []}
    onGroupByChange={handleGroupBy}
    onCurrencyChange={handleCurrency}
    showCurrency={widget.use_currency}
    tooltip={widget.tooltip}
    disableLegendFilter
    exportData={reportData}
  >
    <TableContainer>
      <Table tableData={tableChartData as { [key: string]: any }[]} tooltips={widget.tooltip_additional_info}/>
    </TableContainer>
  </ChartContainer>
};

export default TableChart;
