import DateRangePicker, { IPeriod } from '../components/DateRangePicker/DateRangePicker';
import { IGetImproveCohortsReportDataResponse, useGetImproveCohortsReportDataQuery } from '../queries/useGetImproveCohortsReportDataQuery';
import { IGetTopCohortsReportDataResponse, useGetTopCohortsReportDataQuery } from '../queries/useGetTopCohortsReportDataQuery';
import React, { useEffect, useState } from 'react';

import { AuthService } from '../services/auth.service';
import { CircularProgress } from '@mui/material';
import { IGetAccountTitlesResponse } from '../queries/useGetAccountTitlesQuery';
import { IGetPageResponse } from '../queries/useGetPagesQuery';
import ImproveCohorts from '../components/ImproveCohorts/ImproveCohorts';
import TopCohorts from '../components/TopCohorts/TopCohorts';
import { UseQueryResult } from 'react-query';
import styled from 'styled-components';
import { useApplicationContext } from '../Context';

export interface IDashboardProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
}

const MainContainer = styled.main`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 50px;
    margin: 0 50px;
    margin-top: 45px;
`

const Placeholder = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MissingDataText = styled.strong`
  color: #fff;
  font-size: 2rem;
`

const StyledDateRangePicker = styled.div`
  margin-left: 50px;
`

export const Dashboard = ({ page, title }: IDashboardProps) => {
  const [{ period: contextPeriod }, setContext] = useApplicationContext();
  const [period, setPeriod] = useState<IPeriod | undefined>(contextPeriod);
  const [improveCohortsReportQuery, setImproveCohortsReportQuery] = useState<UseQueryResult<IGetImproveCohortsReportDataResponse[], Error> | undefined>();
  const [topCohortsReportQuery, setTopCohortsReportQuery] = useState<UseQueryResult<IGetTopCohortsReportDataResponse[], Error> | undefined>();

  const getImproveCohortsReportDataQueryResponse = useGetImproveCohortsReportDataQuery({ period: period, titleId: title?.id });
  const getTopCohortsReportDataQueryResponse = useGetTopCohortsReportDataQuery({ period: period, titleId: title?.id });
  useEffect(() => {
    if (!getImproveCohortsReportDataQueryResponse || getImproveCohortsReportDataQueryResponse.isLoading) {
      return;
    }

    const { data, error } = getImproveCohortsReportDataQueryResponse;

    if (!data || error) {
      return;
    }

    if (JSON.stringify(improveCohortsReportQuery) === JSON.stringify(getImproveCohortsReportDataQueryResponse)) {
      return;
    }

    setImproveCohortsReportQuery(getImproveCohortsReportDataQueryResponse);
  }, [getImproveCohortsReportDataQueryResponse]);

  useEffect(() => {
    if (!getTopCohortsReportDataQueryResponse || getTopCohortsReportDataQueryResponse.isLoading) {
      return;
    }

    const { data, error } = getTopCohortsReportDataQueryResponse;

    if (!data || error) {
      return;
    }

    if (JSON.stringify(topCohortsReportQuery) === JSON.stringify(getTopCohortsReportDataQueryResponse)) {
      return;
    }

    setTopCohortsReportQuery(getTopCohortsReportDataQueryResponse);
  }, [getTopCohortsReportDataQueryResponse]);

  useEffect(() => {
    setContext(state => {
      return {
        ...state,
        period
      }
    })
  }, [period]);

  return <>
    {((topCohortsReportQuery && !topCohortsReportQuery?.isLoading ||
      improveCohortsReportQuery && !improveCohortsReportQuery?.isLoading)) &&
      <StyledDateRangePicker>
        <DateRangePicker onChange={setPeriod} initialState={contextPeriod} />
      </StyledDateRangePicker>
    }

    <MainContainer>
      {topCohortsReportQuery && !topCohortsReportQuery?.isLoading && topCohortsReportQuery?.data?.length ?
        <TopCohorts data={topCohortsReportQuery?.data}></TopCohorts> :
        topCohortsReportQuery?.error || topCohortsReportQuery?.data?.length === 0 ?
          <Placeholder>
            <MissingDataText>No data</MissingDataText>
          </Placeholder> :
          <Placeholder>
            <CircularProgress size="10rem" />
          </Placeholder>
      }
      {improveCohortsReportQuery && !improveCohortsReportQuery?.isLoading && improveCohortsReportQuery?.data?.length ?
        <ImproveCohorts data={improveCohortsReportQuery?.data}></ImproveCohorts> :
        improveCohortsReportQuery?.error || improveCohortsReportQuery?.data?.length === 0 ?
          <Placeholder>
            <MissingDataText>No data</MissingDataText>
          </Placeholder> :
          <Placeholder>
            <CircularProgress size="10rem" />
          </Placeholder>
      }
    </MainContainer>
  </>
}