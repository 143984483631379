import { ESubscriptionStatus } from '../billing/billing.state';
import { SerializedError } from "@reduxjs/toolkit";

export interface IScope {
  user_id: number;
  title_id: number;
}

export interface IActivatedSubscription {
  id: number,
  plan_id: number,
  user_id: number,
  subscription_id: string,
  status: ESubscriptionStatus,
  expires: string
}

export interface IRegisterUser {
  firstName: string;
  lastName?: string;
  company?: string;
  email: string;
  password: string;
  intent?: string;
  acceptedMarketing: boolean;
}

export interface ILoginUser {
  email: string;
  password: string;
}

export enum ETrialStatus {
  ACTIVE = "active",
  EXPIRED = "expired",
  NO_TRIAL = "no_trial",
  SUBSCRIBED = "subscribed"
}

export interface IUserOnboarding {
  id: number;
  name: string;
  email: string;
  account_id: number;
  is_admin: boolean;
  is_system: boolean;
  is_mfa_enabled: boolean;
  status: number;
  scopes: IScope[];
  subscription: IActivatedSubscription | null;
  access_token: string;
  trial_status: ETrialStatus
  email_verified: boolean;
  trial_start: string;
}

export interface IOnboardingState {
  user: IUserOnboarding | null,
  loading: {
    getUserOnboarding: boolean;
    registerUser: boolean;
    loginUser: boolean;
  };
  error: {
    getUserOnboarding: SerializedError | null;
    registerUser: SerializedError | null;
    loginUser: SerializedError | null;
  },
}

export const initialState: IOnboardingState = {
  user: null,
  loading: {
    getUserOnboarding: false,
    registerUser: false,
    loginUser: false
  },
  error: {
    getUserOnboarding: null,
    registerUser: null,
    loginUser: null
  },
};
