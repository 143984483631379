import { INotification } from "./notifications.slice";

export const enqueueSnackbar = (notification: INotification) => {
  return {
    type: "[notifications]/enqueueSnackbar",
    payload: {
      notification: {
        ...notification,
        key: notification.key || new Date().getTime() + Math.random(),
      },
    },
  };
};

export const closeSnackbar = (key?: number) => ({
  type: '[notifications]/closeSnackbar',
  payload: {
    dismiss: !key,
    key
  }
});

export const removeSnackbar = (key: number) => ({
  type: '[notifications]/removeSnackbar',
  payload: {
    key
  }
});
