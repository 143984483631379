import * as actions from "./scenarioBuilder.actions";

import {
  EAddType,
  ICohort,
  INode,
} from "../../pages/ScenarioBuilder/models/models";
import {
  IScenarioBuilderState,
  IScenarioSimulationStatus,
  IScenarioTabs,
  ISimulationInfo,
  initialState,
} from "./scenarioBuilder.state";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IFilterBarPayload } from "../../components/FilterBar/FilterBar";
import { v4 as uuidv4 } from "uuid";

export const scenarioBuilderSlice = createSlice({
  name: "[scenarioBuilder]",
  initialState,
  reducers: {
    setScenarioFilter: (
      state: IScenarioBuilderState,
      { payload: { value } }: PayloadAction<{ value: IFilterBarPayload }>
    ) => {
      state.filters = { ...value };
    },
    clearConfigurationObjectsByPath: (state: IScenarioBuilderState) => {
      state.configurationObjectsByPath = undefined;
    },
    setScenarioSimulationStatus: (
      state: IScenarioBuilderState,
      {
        payload: { scenarioSimulationStatus },
      }: PayloadAction<{
        scenarioSimulationStatus: IScenarioSimulationStatus;
      }>
    ) => {
      const index = state.scenarioSimulationStatus?.findIndex(
        ({ scenario_id }) =>
          scenario_id === scenarioSimulationStatus?.scenario_id
      );

      if (typeof index === "number" && index !== -1) {
        state.scenarioSimulationStatus![index!] = scenarioSimulationStatus;
      } else {
        state.scenarioSimulationStatus = [
          ...(state.scenarioSimulationStatus || []),
          scenarioSimulationStatus,
        ];
      }
    },
    addScenarioTab: (
      state: IScenarioBuilderState,
      { payload }: PayloadAction<IScenarioTabs>
    ) => {
      state.scenarioTabs = [...state.scenarioTabs, payload];
    },
    deleteScenarioTab: (
      state: IScenarioBuilderState,
      { payload }: PayloadAction<string>
    ) => {
      const filteredTabs = state.scenarioTabs.filter(
        (scenarioTab) => scenarioTab.id !== payload
      );

      const lastId = filteredTabs[filteredTabs.length - 1]?.id;

      const filteredNodes: Record<string, INode[]> = {};
      for (let key in state.scenarioTabNodes) {
        if (key !== payload) {
          filteredNodes[key] = state.scenarioTabNodes[key];
        }
      }
      state.scenarioTabNodes = filteredNodes;

      const filteredCohorts: Record<string, ICohort> = {};
      for (let key in state.scenarioTabCohorts) {
        if (key !== payload) {
          filteredCohorts[key] = state.scenarioTabCohorts[key];
        }
      }
      state.scenarioTabCohorts = filteredCohorts;

      const filteredFilters: Record<string, IFilterBarPayload> = {};
      for (let key in state.scenarioTabFilters) {
        if (key !== payload) {
          filteredFilters[key] = state.scenarioTabFilters[key];
        }
      }
      state.scenarioTabFilters = filteredFilters;

      if (lastId) {
        state.activeScenarioTabId = lastId;
      } else {
        const newId = uuidv4();
        const newScenarioTab = { name: `Scenario 1`, id: newId };
        const scenarioTabNode = {
          id: uuidv4(),
          addType: EAddType.Or,
          depth: 0,
          children: [],
          parentTabId: newId,
        };
        filteredTabs.push(newScenarioTab);
        state.scenarioTabNodes = {
          ...state.scenarioTabNodes,
          [newId]: [...(state.scenarioTabNodes[newId] || []), scenarioTabNode],
        };
        state.activeScenarioTabId = newId;
      }
      state.scenarioTabs = filteredTabs;
    },
    setScenarioTabs: (
      state: IScenarioBuilderState,
      { payload }: PayloadAction<IScenarioTabs[]>
    ) => {
      state.scenarioTabs = payload;
    },
    setScenarioTabCohort: (
      state: IScenarioBuilderState,
      {
        payload: { cohort, scenarioId },
      }: PayloadAction<{ cohort: ICohort; scenarioId: string }>
    ) => {
      const prevSate = JSON.stringify(state.scenarioTabCohorts[scenarioId]);
      const currentSate = JSON.stringify(cohort);
      if (prevSate === currentSate) {
        return;
      }
      state.scenarioTabCohorts[scenarioId] = cohort;
    },
    setScenarioTabFilter: (
      state: IScenarioBuilderState,
      {
        payload: { filter, scenarioId },
      }: PayloadAction<{ filter: IFilterBarPayload; scenarioId: string }>
    ) => {
      const prevSate = JSON.stringify(state.scenarioTabFilters[scenarioId]);
      const currentSate = JSON.stringify(filter);
      if (prevSate === currentSate) {
        return;
      }

      state.scenarioTabFilters[scenarioId] = filter;
    },
    resetScenarioCohort: (
      state: IScenarioBuilderState,
      { payload }: PayloadAction<string>
    ) => {
      const copy = state.scenarioTabCohorts;
      delete copy[payload];

      state.scenarioTabCohorts = copy;
    },
    clearScenarioTabFilter: (state: IScenarioBuilderState) => {
      state.scenarioTabFilters = {};
    },
    clearScenarioTabCohort: (state: IScenarioBuilderState) => {
      state.scenarioTabCohorts = {};
    },
    setActiveScenarioTabId: (
      state: IScenarioBuilderState,
      { payload }: PayloadAction<string>
    ) => {
      state.activeScenarioTabId = payload;
    },
    setScenarioAdded: (
      state: IScenarioBuilderState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.scenarioAdded = payload;
    },
    addScenarioTabNode: (
      state: IScenarioBuilderState,
      {
        payload: { scenarioId, node },
      }: PayloadAction<{ scenarioId: string; node: INode }>
    ) => {
      state.scenarioTabNodes = {
        ...state.scenarioTabNodes,
        [scenarioId]: [...(state.scenarioTabNodes[scenarioId] || []), node],
      };
    },
    deleteScenarioTabNode: (
      state: IScenarioBuilderState,
      {
        payload: { scenarioId, nodes },
      }: PayloadAction<{ scenarioId: string; nodes: INode[] }>
    ) => {
      state.scenarioTabNodes = {
        ...state.scenarioTabNodes,
        [scenarioId]: nodes,
      };
    },
    changeScenarioTabNode: (
      state: IScenarioBuilderState,
      {
        payload: { scenarioId, nodes },
      }: PayloadAction<{ scenarioId: string; nodes: INode[] }>
    ) => {
      state.scenarioTabNodes = {
        ...state.scenarioTabNodes,
        [scenarioId]: nodes,
      };
    },
    setSimulationInfo: (
      state: IScenarioBuilderState,
      {
        payload: { scenarioId, simulationInfo },
      }: PayloadAction<{
        scenarioId: string;
        simulationInfo: ISimulationInfo;
      }>
    ) => {
      state.simulationInfo = {
        ...state.simulationInfo,
        [scenarioId]: simulationInfo,
      };
    },
    setConfigurationObjectsByPath: (
      state: IScenarioBuilderState,
      {
        payload: any,
      }: PayloadAction<any>
    ) => {
      state.configurationObjectsByPath = {};
    },
  },
  extraReducers: (e) => {
    e.addCase(
      actions.getConfiguration.fulfilled,
      (state: IScenarioBuilderState, { payload }) => {
        state.loading.getConfiguration = false;
        state.configuration = payload;
      }
    );
    e.addCase(actions.getConfiguration.pending, (state) => {
      state.loading.getConfiguration = true;
      state.error.getConfiguration = undefined;
    });
    e.addCase(actions.getConfiguration.rejected, (state, payload) => {
      state.loading.getConfiguration = false;
      state.error.getConfiguration = payload.error;
    });

    e.addCase(
      actions.getConfigurationObjects.fulfilled,
      (state: IScenarioBuilderState, { payload }) => {
        state.loading.getConfigurationObjects = false;
        state.configurationObjects = payload;
      }
    );
    e.addCase(actions.getConfigurationObjects.pending, (state) => {
      state.loading.getConfigurationObjects = true;
      state.error.getConfigurationObjects = undefined;
    });
    e.addCase(actions.getConfigurationObjects.rejected, (state, payload) => {
      state.loading.getConfigurationObjects = false;
      state.error.getConfigurationObjects = payload.error;
    });

    e.addCase(
      actions.getConfigurationObjectsByPath.fulfilled,
      (state: IScenarioBuilderState, { payload: { data, path } }) => {
        state.loading.getConfigurationObjectsByPath = false;
        state.configurationObjectsByPath = {
          ...state.configurationObjectsByPath,
          [path]: data,
        };
      }
    );
    e.addCase(actions.getConfigurationObjectsByPath.pending, (state) => {
      state.loading.getConfigurationObjectsByPath = true;
      state.error.getConfigurationObjectsByPath = undefined;
    });
    e.addCase(
      actions.getConfigurationObjectsByPath.rejected,
      (state, payload) => {
        state.loading.getConfigurationObjectsByPath = false;
        state.error.getConfigurationObjectsByPath = payload.error;
      }
    );

    e.addCase(
      actions.createScenario.fulfilled,
      (state: IScenarioBuilderState, { payload }) => {
        state.loading.createScenario = false;
        state.scenarioAdded = true;

        state.scenarios?.unshift(payload);
      }
    );
    e.addCase(actions.createScenario.pending, (state) => {
      state.loading.createScenario = true;
      state.error.createScenario = undefined;
    });
    e.addCase(actions.createScenario.rejected, (state, payload) => {
      state.loading.createScenario = false;
      state.error.createScenario = payload.error;
    });

    e.addCase(
      actions.runScenarioSimulation.fulfilled,
      (state: IScenarioBuilderState) => {
        state.loading.runScenarioSimulation = false;
      }
    );
    e.addCase(actions.runScenarioSimulation.pending, (state) => {
      state.loading.runScenarioSimulation = true;
      state.error.runScenarioSimulation = undefined;
    });
    e.addCase(actions.runScenarioSimulation.rejected, (state, payload) => {
      state.loading.runScenarioSimulation = false;
      state.error.runScenarioSimulation = payload.error;
    });

    e.addCase(
      actions.getSimulationStatus.fulfilled,
      (state: IScenarioBuilderState, { payload }) => {
        state.loading.getSimulationStatus = false;
        state.simulationStatus = payload;
      }
    );
    e.addCase(actions.getSimulationStatus.pending, (state) => {
      state.loading.getSimulationStatus = true;
      state.error.getSimulationStatus = undefined;
    });
    e.addCase(actions.getSimulationStatus.rejected, (state, payload) => {
      state.loading.getSimulationStatus = false;
      state.error.getSimulationStatus = payload.error;
    });

    e.addCase(
      actions.getSimulationResult.fulfilled,
      (state: IScenarioBuilderState, { payload }) => {
        state.loading.getSimulationResult = false;

        if (payload?.length) {
          const [result] = payload;

          const { whatif_id } = result;

          state.simulationResult = {
            ...state.simulationResult,
            [whatif_id]: result,
          };
        }
      }
    );
    e.addCase(actions.getSimulationResult.pending, (state) => {
      state.loading.getSimulationResult = true;
      state.error.getSimulationResult = undefined;
    });
    e.addCase(actions.getSimulationResult.rejected, (state, payload) => {
      state.loading.getSimulationResult = false;
      state.error.getSimulationResult = payload.error;
    });

    e.addCase(
      actions.getScenarioSimulationStatus.fulfilled,
      (state: IScenarioBuilderState, { payload }) => {
        state.loading.getScenarioSimulationStatus = false;
        state.scenarioSimulationStatus = [
          ...(state.scenarioSimulationStatus || []),
          payload,
        ];
      }
    );
    e.addCase(actions.getScenarioSimulationStatus.pending, (state) => {
      state.loading.getScenarioSimulationStatus = true;
      state.error.getScenarioSimulationStatus = undefined;
    });
    e.addCase(
      actions.getScenarioSimulationStatus.rejected,
      (state, payload) => {
        state.loading.getScenarioSimulationStatus = false;
        state.error.getScenarioSimulationStatus = payload.error;
      }
    );

    e.addCase(
      actions.getScenarios.fulfilled,
      (state: IScenarioBuilderState, { payload }) => {
        state.loading.getScenarios = false;
        state.scenarios = payload;
      }
    );
    e.addCase(actions.getScenarios.pending, (state) => {
      state.loading.getScenarios = true;
      state.error.getScenarios = undefined;
    });
    e.addCase(actions.getScenarios.rejected, (state, payload) => {
      state.loading.getScenarios = false;
      state.error.getScenarios = payload.error;
    });
  },
});

export default scenarioBuilderSlice.reducer;
