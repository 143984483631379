import * as fromActions from '../../store/billing/billing.actions';
import * as fromSelectors from '../../store/billing/billing.selectors';

import { Box, Link, Typography } from "@mui/material";
import { EPlanType, ISubscription } from "../../store/billing/billing.state";
import { LinearProgress, Tabs } from "@mui/material";
import { StyledBackButton, StyledNextButton } from '../GrowthSimulatorV2/components/GrowthSimulatorButtons/GrowthSimulatorButtons';
import { trackSubscriptionPageNameClick, trackViewSubscriptionPage } from "../../mixpanel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { IGetAccountTitlesResponse } from "../../queries/useGetAccountTitlesQuery";
import { IGetPageResponse } from "../../queries/useGetPagesQuery";
import SimpoolDialog from '../../components/Dialog/Dialog';
import { StyledCloseTabDialog } from '../ScenarioBuilder/components/ScenarioBuilderWrapper/ScenarioBuilderWrapper';
import { StyledLandingPageWrapper, StyledTextField } from "../Landing/styles";
import SubscriptionCard from "./SubscriptionCard";
import Tab from '@mui/material/Tab';
import cn from "classnames";
import { resendVerifyEmail } from '../../store/onboarding/onboarding.actions';
import styled from "styled-components";
import { useApplicationContext } from "../../Context";
import { userSelector } from "../../store/onboarding/onboarding.selectors";
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

interface ISubscriptionProps {
  page?: IGetPageResponse;
  title?: IGetAccountTitlesResponse;
  className?: string;
}

const SubscriptionV2 = ({ className }: ISubscriptionProps) => {
  const SUBSCRIPTION_PRE_DATA: Record<
    EPlanType,
    {
      label: string,
      subscriptions: ISubscription[]
    }> = {
    'monthly':  {
      label: 'Monthly',
      subscriptions: []
    },
    'annually':  {
      label: 'Annually 20% off',
      subscriptions: []
    }
  };
  const [ subscriptionValue, setSubscriptionValue ] = useState(SUBSCRIPTION_PRE_DATA);
  const [value, setValue] = useState(Object.keys(SUBSCRIPTION_PRE_DATA)[0]);
  const [resendConfirmationEmailDialog, setResendConfirmationEmailDialog] = useState(false);
  const [contactUsDialog, setContactUsDialog] = useState<{ isOpen: boolean, callback?: (email: string) => void }>({ isOpen: false, callback: undefined});

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const subscriptions = useSelector(fromSelectors.subscriptionSelector);
  const [{ authentication }] = useApplicationContext();
  const user = useSelector(userSelector);

  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    dispatch(fromActions.getSubscriptions());
    trackViewSubscriptionPage()
}, []);

  useEffect(() => {
    if (subscriptions) {
      console.log(subscriptions);
      setSubscriptionValue((prev) => {
        setIsLoading(false);
        return {
          ...prev,
          monthly: {
            ...prev.monthly,
            subscriptions: subscriptions?.filter((sub) => sub.plan_type === EPlanType.Monthly)?.sort((a, b) => a.order - b.order)
          },
          annually: {
            ...prev.annually,
            subscriptions: subscriptions?.filter((sub) => sub.plan_type === EPlanType.Annually)?.sort((a, b) => a.order - b.order)
          }
        }
      })
    }
  }, [subscriptions]);

  const validationSchema = Yup.object({
    first_name: Yup.string().required('First name is required').min(2, 'First name should be more than 2 characters'),
    last_name: Yup.string().required('Last name is required').min(3, 'Last name should be more than 3 characters'),
    email: Yup.string().required('Email is required').email('Please enter a valid email'),
    password: Yup.string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Password should contain one digit, one small letter, one capital letter and a symbol')
  })

  const { validateForm, values, handleChange, handleSubmit, handleBlur, errors, touched, isValid } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: ({
      email,
    }) => {
      
    }
  });
  const handleSendEmail = (item: any,) => ( email: string) => {
    const _hsq: any[] = (window as any)._hsq = (window as any)._hsq || [];
    _hsq.push(['setPath', '/subscription']);
    _hsq.push(["identify",{
        email: email,
        ...item
    }]);
    _hsq.push(['trackPageView']);
  }
  return (
    <StyledSubscriptionWrapperContainer style={{ height: '100%', maxHeight: 'none', display: 'flex', width: '100%' }}>
        {isLoading && <StyledLinearProgress/>}
        <StyledSubscriptionWrapper className={cn('subscription', className)}>
          <StyledTitle>Choose your plan</StyledTitle>
           <Tabs value={value} onChange={handleChangeTabs} centered TabIndicatorProps={{
            sx: { backgroundColor: '#3167BB'}
           }}>
            { Object.keys(subscriptionValue).map?.((tab, index) => {
              return <StyledTab 
                label={subscriptionValue[tab as keyof typeof subscriptionValue]?.label}
                value={tab}
                key={`${tab}_${index}`}
              />
              })}
            </Tabs>
            <StyledCardWrapper>
              {subscriptionValue[value as keyof typeof subscriptionValue]?.subscriptions?.map?.(
                (
                  item,
                  index
                ) =>
                <SubscriptionCard
                  key={index}
                  item={item}
                  onButtonClick={
                    () => {
                      if(item.pre_header === 'Starter') {
                        history.push('/growth-simulator-v2/details')
                        return;
                      }
                      if(item.pre_header === 'Advanced' || item.pre_header === 'Pro') {
                        setContactUsDialog({
                          isOpen: true,
                          callback: handleSendEmail(item)
                        })
                        return;
                      }
                      trackSubscriptionPageNameClick(item)
                      if (user?.email_verified && user.trial_status === 'active') {
                          window.location.href = `${item.plan_link}&custom1=${authentication?.user.simpool?.simpool_id}&custom2=${item.id}`;
                      } else {
                          setResendConfirmationEmailDialog(true);
                      }
                  }}
                />)}
            </StyledCardWrapper>
            <Box sx={{ marginTop: '100px' }}>
                <Typography
                    variant="subtitle1"
                    color={'rgba(255, 255, 255, 0.7)'}
                    fontFamily={['DM Sans, sans-serif']}
                    justifyContent={'center'}
                    textAlign={'center'}>
                    <Link
                        href="https://simpool.io/terms-of-use/"
                        color='#FFFFFF'
                        target="_blank">
                        Terms & Conditions
                    </Link>&nbsp;&nbsp;&nbsp;
                    <Link
                        href="https://simpool.io/privacy-policy/"
                        color='#FFFFFF'
                        target="_blank">
                        Privacy Policy
                    </Link>&nbsp;&nbsp;&nbsp;
                    <Link
                        href="https://simpool.io/simpool-subscription-and-refund-policy/"
                        color='#FFFFFF' target="_blank">
                        Subscription & Refund Policy
                    </Link>
                </Typography>
                    </Box>
        </StyledSubscriptionWrapper>
        <SimpoolDialog open={resendConfirmationEmailDialog} maxWidth={'md'} title={
            `To subscribe to Simpool Premium, please ensure that you have confirmed your email first. To validate your email account, please click the "Resend" button below to resend the confirmation email.`
        } onClose={() => setResendConfirmationEmailDialog(false)}>
            <StyledCloseTabDialog>
                <div className="dialog-btns">
                    <StyledBackButton onClick={(e) => {
                        e.preventDefault();
                        setResendConfirmationEmailDialog(false);
                    }}>
                        Cancel
                    </StyledBackButton>
                    <StyledNextButton onClick={(e) => {
                        e.preventDefault();
                        setResendConfirmationEmailDialog(false);
                        dispatch(resendVerifyEmail(user?.email!))

                    }}>
                        Resend
                    </StyledNextButton>
                </div>
            </StyledCloseTabDialog>
        </SimpoolDialog>
        <SimpoolDialog open={contactUsDialog.isOpen} maxWidth={'md'} title={
            `Please type your email and we will contact you soon`
        } onClose={() => setContactUsDialog({
          isOpen: false,
          callback: undefined
        })}>
            <StyledCloseTabDialog>
            <form onSubmit={handleSubmit}>
              <Box sx={{ width: '100%', marginBottom: '30px' }}>
                <StyledTextField
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  fullWidth
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!(errors.email && touched.email)}
                  helperText={errors.email}
                  style={{ width: '100%'}}
                />
              </Box>
            </form>
                <div className="dialog-btns">
                    <StyledBackButton onClick={(e) => {
                        e.preventDefault();
                        setContactUsDialog({
                          isOpen: false,
                          callback: undefined
                        });
                    }}>
                        Cancel
                    </StyledBackButton>
                    <StyledNextButton
                      onClick={(e) => {
                        e.preventDefault();
                        contactUsDialog.callback?.(values.email);
                        setContactUsDialog({
                          isOpen: false,
                          callback: undefined
                        });
                      }
                      }
                      disabled={!!(errors.email && touched.email)}>
                        Send
                    </StyledNextButton>
                </div>
            </StyledCloseTabDialog>
        </SimpoolDialog>
    </StyledSubscriptionWrapperContainer>
  );
}

export default SubscriptionV2;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: none;
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.6);
    &.Mui-selected {
      color: white
    }
   
  }
`
const StyledSubscriptionWrapperContainer = styled(StyledLandingPageWrapper)`
  justify-content: center;
`
const StyledSubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 25px;
  
  width: 1200px;
  align-self: center;
  justify-self: center;
`

const StyledTitle = styled.h1`
    font-size: 2.5rem;
    text-align: center;
    color: #fff;
`

const StyledCardWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
`

const StyledLinearProgress = styled(LinearProgress)`
  position: absolute !important; 
  top: 0;
  width: 100%;
`
