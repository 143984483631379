import { useFormik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components"
import GrowthKpis from "./GrowthKpis";
import { InputType } from "./InputWithArrows";
import PlayerSegments from "./PlayerSegments";
import { Kpis } from "./types";

interface GrowthSegmentsWrapperProps {
  className?: string;
  cpiValue?: number;
  eCpiValue?: number;
}
const GrowthSegmentsWrapper = ({className, cpiValue, eCpiValue }: GrowthSegmentsWrapperProps) => {

  const generateLtv = (payingUsersConversion: string, averagePayment: number, paymentsPerPlayer: number) => {
    const payingUsersConversionNumber = parseFloat(payingUsersConversion) / 100;
    const formula = (payingUsersConversionNumber * averagePayment * paymentsPerPlayer);
    return Object.is(NaN, formula) ? '0.00' : formula.toFixed(2);
  }

  const formik = useFormik({
    initialValues: {
      web2PlayersSegments: {
        whales: {
          avgPurchase: 0,
          payments: 0,
          proportion: '0%'
        },
        dolphins: {
          avgPurchase: 0,
          payments: 0,
          proportion: '0%'
        },
        fish: {
          avgPurchase: 0,
          payments: 0,
          proportion: '0%'
        }
      },
      kpis: {
        paymentsPerPlayer: 0,
        payingUsersConversion: '0%',
        averagePayment: 0.00, 
        ltv: 0.00,
        roi: '0%',
        eroi: '0%'
      }
    },
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  const [ averagePayment, setAveragePayment ] = useState(0);
  const { values, setFieldValue } = formik;

  useEffect(() => {
    const currentObject = formik.values.web2PlayersSegments;
    //whaels
    const whaleProportion = parseFloat(currentObject.whales.proportion) / 100;
    const whaleAvgPurchase = currentObject.whales.avgPurchase;
    const whalePayments = Number(currentObject.whales.payments);
    //dolphins
    const dolphinsAvgPurchase = currentObject.dolphins.avgPurchase;
    const dolphinsProportion = parseFloat(currentObject.dolphins.proportion) / 100;
    const dolphinsPayments = Number(currentObject.dolphins.payments);
    //fish
    const fishAvgPurchase = currentObject.fish.avgPurchase;
    const fishProportion = parseFloat(currentObject.fish.proportion) / 100;
    const fishPayments = Number(currentObject.fish.payments);

    const formula =
      ((whaleProportion * whaleAvgPurchase * whalePayments)
      + (dolphinsAvgPurchase * dolphinsProportion * dolphinsPayments)
      + (fishAvgPurchase * fishPayments * fishProportion)) / formik.values.kpis.paymentsPerPlayer;
      setFieldValue('kpis.averagePayment', Object.is(NaN, formula) ? 0.00 : Number(formula.toFixed(2)));
    
  }, [formik.values.kpis.paymentsPerPlayer, formik.values.web2PlayersSegments, setFieldValue]);

  useEffect(() => {
    const currentObject = formik.values.web2PlayersSegments;
    const whaleProportion = parseFloat(currentObject.whales.proportion) / 100;
    const whalePayments = Number(currentObject.whales.payments);
    const dolphinsProportion = parseFloat(currentObject.dolphins.proportion) / 100;
    const dolphinsPayments = Number(currentObject.dolphins.payments);
    const fishProportion = parseFloat(currentObject.fish.proportion) / 100;
    const fishPayments = Number(currentObject.fish.payments);
    const formula = (whalePayments * whaleProportion) + (dolphinsPayments * dolphinsProportion) + ( fishPayments * fishProportion);

    setFieldValue('kpis.paymentsPerPlayer', Number(formula.toFixed(2)));
  }, [formik.values.web2PlayersSegments, setFieldValue]);
  //ROI calculation
  useEffect(() => {
    console.log(cpiValue)
    if (cpiValue && values.kpis.ltv) {
      const result = Number(((values.kpis.ltv / cpiValue) * 100).toFixed(0));
      setFieldValue('kpis.roi', `${result}%`);
    }
  }, [cpiValue, values.kpis.ltv, setFieldValue]);

  //EROI calculation
  useEffect(() => {
    if (eCpiValue && values.kpis.ltv) {
      const result = Number(((values.kpis.ltv / eCpiValue) * 100).toFixed(0));
      setFieldValue('kpis.eroi', `${result}%`);
    }
  }, [eCpiValue, values.kpis.ltv, setFieldValue]);

  useEffect(() => {
    if (values.kpis.payingUsersConversion
      && values.kpis.averagePayment
      && values.kpis.paymentsPerPlayer
      ) {
        const result = generateLtv(
          values.kpis.payingUsersConversion,
          values.kpis.averagePayment,
          values.kpis.paymentsPerPlayer
        )

        setFieldValue('kpis.ltv', Number(result));
    }
  }, [values.kpis.payingUsersConversion,  values.kpis.averagePayment, values.kpis.paymentsPerPlayer, setFieldValue]);


  return (
    <div className={className}>
      <PlayerSegments formikValues={formik.values.web2PlayersSegments} formikHandler={formik.setFieldValue}/>
      <GrowthKpis formikValues={formik.values.kpis as Kpis} formikHandler={formik.setFieldValue} averagePayment={averagePayment}/>
    </div>
  );
}

export default styled(GrowthSegmentsWrapper)`
  display: grid;
  background-color: #43445F;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 15px;
  border-radius: 15px;
  padding: 20px;
`
