import cn from 'classnames';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Tooltip from '../../components/Tooltip/Tooltip';

export interface ITableProps {
  className?: string;
  tableData: { [key: string]: any }[];
  tooltips?: { [key:string] : string }
}


const StyledTableHeader = styled.header`
  background: transparent linear-gradient(180deg, #212134 0%, #2C2C41 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000042;

  margin: 10px;
  margin-right: 30px;
`

const StyledTableHeaderContainer = styled.ul<{ count: number }>`
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: ${({ count }) => `repeat(${count}, minmax(calc(3 * 1rem), 1fr))`};
    grid-template-rows: minmax(calc(3 * 1rem), 1fr);
    grid-column-gap: 5px;
`

const StyledTableHeaderContainerItem = styled.li<{isTooltip: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  background: rgb(64, 64, 82);
  cursor: ${({isTooltip}) => isTooltip ? 'pointer': 'auto'};
  font-size: 0.8rem;
  position: relative;
  &:hover {
    .html-tooltip{
      opacity: 1;
      visibility: visible;
      
    }
  }
`

const StyledTableBody = styled.div`
  margin: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 228px;
  padding-right: 5px;
  &::-webkit-scrollbar {
      width: 15px;              
    }

  &::-webkit-scrollbar-track {
    background: #424252;        
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777782;    
    border: 1px solid #424252; 
  }
`

const StyledTableRow = styled.ul<{ count: number }>`
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: ${({ count }) => `repeat(${count}, minmax(calc(3 * 1rem), 1fr))`};
    grid-template-rows: minmax(calc(3 * 1rem), 1fr);

    grid-column-gap: 5px;

    margin-bottom: 5px;

    font-size: 0.8rem;
`

const StyledTableRowItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  background-color: #2C2C3E;
`

const capitalize = (text: string): string => {
  return text[0].toUpperCase() + text.slice(1);
}

const transformTableHeader = (text: string): string => {
  return capitalize(text.split('_').join(' '))
}

const Table = ({ className, tableData, tooltips, ...props }: ITableProps) => {
  
  if (!tableData || !tableData.length) {
    return null;
  }
  
  const [item] = tableData;

  const columns = Object.keys(item);
 
  const lowerCaseKeys = tooltips && Object.fromEntries(Object.entries(tooltips).map(([k, v]) => [k.toLowerCase().split(/[ _]/).join('_'), v]))
  return <section className={cn('table', className)}>
    <StyledTableHeader>
      <StyledTableHeaderContainer count={columns.length}>
        {columns.map((x, i) => <StyledTableHeaderContainerItem key={uuidv4()} isTooltip={!!lowerCaseKeys?.[x]}>{transformTableHeader(x)}<Tooltip text={lowerCaseKeys?.[x]} /></StyledTableHeaderContainerItem>)}
      </StyledTableHeaderContainer>
    </StyledTableHeader>
    <StyledTableBody>
      {tableData.map(d => {
        return <StyledTableRow count={columns.length} key={uuidv4()}>
          {columns.map(c => {
            if(d[c as keyof typeof d]?.['custom']) {
              return props[c as keyof typeof props];
            }

            return <StyledTableRowItem key={uuidv4()}>
              {d[c as keyof typeof d] as string}
            </StyledTableRowItem>
          })}
        </StyledTableRow>
      })}
    </StyledTableBody>
  </section>
};

export default styled(Table)`
  color: rgb(231, 231, 231);
  background-color: #212134;
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
      width: 15px;              
    }

  &::-webkit-scrollbar-track {
    background: #424252;        
  }

  &::-webkit-scrollbar-thumb {
    background-color: #777782;    
    border: 1px solid #424252; 
  }
`;
