import * as fromActions from './onboarding.actions';

import { SerializedError, createSlice } from '@reduxjs/toolkit';
import { IOnboardingState, initialState } from './onboarding.state';

export const onboardingSlice = createSlice({
  name: '[onboarding]',
  initialState,
  reducers: {
    deleteUser: (state: IOnboardingState) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fromActions.getUserOnboarding.fulfilled, (state: IOnboardingState, { payload }) => {
      state.loading.getUserOnboarding = false;
      state.error.getUserOnboarding = null;

      state.user = payload;
    });
    builder.addCase(fromActions.getUserOnboarding.pending, (state: IOnboardingState) => {
      state.loading.getUserOnboarding = true;
      state.error.getUserOnboarding = null;
    });
    builder.addCase(fromActions.getUserOnboarding.rejected, (state: IOnboardingState, { error }) => {
      state.loading.getUserOnboarding = false;
      state.error.getUserOnboarding = error;
    });

    builder.addCase(fromActions.registerUser.fulfilled, (state: IOnboardingState, { payload }) => {
      state.loading.registerUser = false;
      state.error.registerUser = null;
      state.user = payload || null;
    });
    builder.addCase(fromActions.registerUser.pending, (state: IOnboardingState) => {
      state.loading.registerUser = true;
      state.error.registerUser = null;
      state.user = null;
    });
    builder.addCase(fromActions.registerUser.rejected, (state: IOnboardingState, obj) => {
      state.loading.registerUser = false;
      state.error.registerUser = obj.payload as SerializedError;
    });

    builder.addCase(fromActions.loginUser.fulfilled, (state: IOnboardingState, { payload }) => {
      state.loading.loginUser = false;
      state.error.loginUser = null;
      state.user = payload;
    });
    builder.addCase(fromActions.loginUser.pending, (state: IOnboardingState) => {
      state.loading.loginUser = true;
      state.error.loginUser = null;
      state.user = null;
    });
    builder.addCase(fromActions.loginUser.rejected, (state: IOnboardingState, { error }) => {
      state.loading.loginUser = false;
      state.error.loginUser = error;
    });
  },
});

export const { deleteUser } = onboardingSlice.actions;
export default onboardingSlice.reducer;
