import React, { useEffect, useRef, useState } from "react";
import { useResizeDetector } from 'react-resize-detector';
import styled from "styled-components";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { ScenarioItemContainer } from "./ScenarioBuilderFooter";
import cn from 'classnames';

type Props = {
  className?: string;
  children: React.ReactNode,
  tooltip?: string;
}
const ScenarioItemWithTooltip = ({ children, className, tooltip = "Some tooltip" }: Props) => {
  const { width, ref } = useResizeDetector();
  const [showTooltip, setShowTooltip ] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef<any>(null);

  const refHandler = (e:any) => {
    const cellWidth = e.offsetWidth;
    const cellActualWidth = e.scrollWidth;
    const shouldTooltip = cellActualWidth - cellWidth > 0;
    return [ shouldTooltip ];
  }
  
  useEffect(() => {
    if(ref && ref.current) {
      const [ shouldTooltip ] = refHandler(ref.current);
      shouldTooltip ? setShowTooltip(true) : setShowTooltip(false);
    }
  }, [ref, width]);

  let timer: any;
  const handleOpen = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    timer && clearTimeout(timer);
    if(isVisible) {
      return;
    }
    setIsVisible(true);
    timer = setTimeout(() => {
      setIsVisible(false);
      // clearTimeout(timer)
    }, 3000)
  }
  const Child = React.forwardRef<HTMLDivElement>((props, ref) => (<div className="ref-wrapper" ref={ref}>{ children }</div>));
  return (
    <ScenarioItemContainer  className={cn(className, 'tooltip-container', {visible: isVisible})}  onMouseEnter={(e) => handleOpen(e)} ref={wrapperRef}>
      <Child ref={ref}/>
      { (tooltip && tooltip.length > 0 && showTooltip) && <Tooltip text={tooltip} /> }
    </ScenarioItemContainer>
  )
}

export default styled(ScenarioItemWithTooltip)<{cellWidth?: number}>`
  position: relative;
  /* &:hover {
    .html-tooltip {
      visibility: visible;
      opacity:  1;
    }
  } */
  &.visible{
    .html-tooltip {
      visibility: visible;
      opacity:  1;
    }
  }
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
`;

