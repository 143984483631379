import * as actions from "./settings.actions";

import { createSlice } from "@reduxjs/toolkit";
import { initialState, ISettingsSlice } from "./settings.state";


export const settingsSlice = createSlice({
  name: "[settings]",
  initialState,
  reducers: {},
  extraReducers: (e) => {
    e.addCase(
      actions.getSettings.fulfilled,
      (state: ISettingsSlice, { payload }) => {
        state.loading.getSettings = false;
        state.settings = payload;
      }
    );
    e.addCase(actions.getSettings.pending, (state) => {
      state.loading.getSettings = true;
      state.error.getSettings = undefined;
    });
    e.addCase(actions.getSettings.rejected, (state, payload) => {
      state.loading.getSettings = false;
      state.error.getSettings = payload.error;
    });
    e.addCase(actions.updateSettings.fulfilled, (state) => {
      state.loading.updateSettings = false;
      
      
    });
    e.addCase(actions.updateSettings.pending, (state) => {
      state.loading.updateSettings = true;
      state.error.updateSettings = undefined;
    });
    e.addCase(actions.updateSettings.rejected, (state, payload) => {
      state.loading.updateSettings = false;
      state.error.updateSettings = payload.error;
    });
  },
});

export default settingsSlice.reducer;
