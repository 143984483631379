import styled from "@emotion/styled";
import Tooltip, { tooltipClasses,TooltipProps } from "@mui/material/Tooltip";
type Props = {
  zIndexValue?: string;
}
const MUITooltip = styled(({ className, ...props }: TooltipProps & Props) => (
  <Tooltip {...props} classes={{ popper: className }} />
),{
  shouldForwardProp: (prop) => prop !== undefined,
})<StyledTooltipProps>(({ zIndexValue, theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '14px',
    border: '2px solid #ffc301',
    textAlign: 'center',
    borderRadius:'10px',
    lineHeight: '21px',
    backgroundColor: '#303c51',
  },
  [`&.${tooltipClasses.popper}`]: {
    zIndex:  zIndexValue || '1299'
  },
}));

interface StyledTooltipProps extends TooltipProps {
  zIndexValue?: string;
}

export default MUITooltip;