export enum EAddType {
  And = "And",
  Or = "Or",
}

export interface INode {
  id: string;
  addType?: EAddType;
  children: INode[];
  depth: number;

  value?: {[key: string]: string | number | any}[];
  object?: string;
  changes?: {[key: string]: string | number | string[]}[];
  parentTabId?: string;
  description?: string;
}

export interface ICohort {
  simpool_score?: number;
  cohort?: string;
  users: number;
  ltv: number;
  avg_ltv: number;
  apds: number;
  avg_apds: number;
  arppu30: number;
  avg_arppu: number;
  conv: number;
  avg_conv: number;
}
